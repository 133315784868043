import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import NotFoundPage from '../../NotFound';
import PasswordForgetClientPage from '../../../AdminClient/pages/PasswordForget';
import SignInClientPage from '../../../AdminClient/pages/SignIn';
import { SignOut } from '../../../AdminClient/pages/SignOut';

import * as routes from '../../../routes';

function WithoutAuth(props) {
  return (
    <Switch>
      <Route
        exact
        path={`${routes.SIGIN_CLIENT}/:clientAlias`}
        component={() => <SignInClientPage {...props} />}
      />
      <Route
        exact
        path={`${routes.SIGIN_CLIENT}/`}
        component={() => <SignInClientPage {...props} />}
      />
      <Route
        exact
        path={routes.SIGNOUT_CLIENT}
        component={() => <SignOut {...props} />}
      />

      <Route
        exact
        path={routes.PASSWORD_FORGET_CLIENT}
        component={() => <PasswordForgetClientPage {...props} />}
      />

      <Route component={(props) => <NotFoundPage {...props} />} />
    </Switch>
  );
}

export default withRouter(WithoutAuth);
