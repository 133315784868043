import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { ResponsiveLine } from '@nivo/line';

export default class LineGraphs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
      extraPoints: [],
      progress: 0,
      isUploading: false,
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],
      graphData: [
        {
          id: 'japan',
          data: [{ x: 0, y: 0 }],
        },
      ],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen, ...nextProps });

    this.handleGraphChange(nextProps.title, nextProps.amountPerWeekDay);
  }

  handleGraphChange(id, data) {
    const graphData = [{ id, data }];
    this.setState({ graphData });
  }

  updateAwardsList() {
    this.props.updateAwardsList(this.state.extraPoints);
    this.props.closeModal();
  }

  render() {
    const graphMargin = {
      top: 50,
      right: 50,
      bottom: 160,
      left: 70,
    };
    const graphYScale = {
      type: 'linear',
      stacked: true,
      min: 'auto',
      max: 'auto',
    };
    const graphAxisBottom = {
      orient: 'bottom',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: 36,
      legendPosition: 'middle',
    };
    const graphAxisLeft = {
      orient: 'left',
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: '',
      legendOffset: -40,
      legendPosition: 'middle',
    };

    const { title, modalIsOpen, graphData } = this.state;
    return (
      <Modal
        open={modalIsOpen}
        onClose={() => this.props.closeModal()}
        classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid">
          <div className="row graph-modal text-center">
            <span className="oq-card-title">{title}</span>
            <div className="select-wrapper">
              <select
                className="form-control select-transparent range-selecter"
                onChange={(e) =>
                  this.handleGraphChange(title, this.state[e.target.value])
                }
              >
                <option value="amountPerWeekDay">Essa semana</option>
                <option value="amountPerDay">Esse mês</option>
                <option value="amountPerMonth">Esse ano</option>
              </select>
            </div>

            <ResponsiveLine
              data={graphData}
              margin={graphMargin}
              xScale={{ type: 'point' }}
              yScale={graphYScale}
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={graphAxisBottom}
              axisLeft={graphAxisLeft}
              colors="category10"
              dotSize={10}
              dotColor="inherit:darker(0.3)"
              dotBorderWidth={2}
              dotBorderColor="#ffffff"
              enableDotLabel
              dotLabel="y"
              dotLabelYOffset={-12}
              animate
              motionStiffness={90}
              motionDamping={15}
              legends={[]}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
