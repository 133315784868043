// ADMIN MASTER
export const START_ADMIN = '/';
export const SIGN_UP_ADMIN = '/admin/signup';
export const SIGN_IN_ADMIN = '/admin/signin';
export const PASSWORD_FORGET_ADMIN = '/admin/passwordForget';
export const DASHBOARD_ADMIN = '/admin/dashboard';
export const LOGOUT_ADMIN = '/admin/logout';
export const CLIENTS_ADMIN = '/admin/clients';
export const ADMINS = '/admin/administrators';
export const CREATE_CLIENT = '/admin/createClient';
export const REGISTER_CLIENT = '/admin/registerClient';
export const EDIT_CLIENT = '/admin/editClient';
export const EDIT_ADMIN = '/admin/editAdmin';
export const COMERCIAL_ADMIN = '/admin/comercialAdmin';
export const LEADS_ADMIN = '/admin/leads';
export const PROSPECT_LEADS_ADMIN = '/admin/prospectLeads';
export const TERMS_ADMIN = '/admin/termsAdmin';
export const PREVIEW_TEXT_ADMIN = '/admin/previewTextAdmin';
export const EDIT_TEXT_ADMIN = '/admin/editText';
export const EMAILS_ADMIN = '/admin/Emails';
export const NEW_EMAIL_ADMIN = '/admin/NewEmail';
export const WALLETS = '/admin/wallets';
export const HISTORICAL_WALLET_ADMIN = '/admin/historicalWallet';
export const EDIT_WALLET = '/admin/editWallet';
export const DETAILS_BANK_TRANSFER_REQUEST =
  '/admin/detailsBankTransferRequest';
export const PLAYERS_ADMIN = '/admin/players';
export const USERS_ADMIN = '/admin/users';
export const EDIT_USER_ADMIN = '/admin/editUser';
export const JOBS = '/admin/jobs';

// ADMIN CLIENT

export const SIGIN_CLIENT = '/client/signin';
export const SIGNOUT_CLIENT = '/client/signout';

export const WELCOME_CLIENT = '/client/welcome';
export const PROFILE_CLIENT = '/client/profileClient';
export const PROFILE_USER = '/client/profileUser';
export const PASSWORD_FORGET_CLIENT = '/client/passwordForget';

export const PLAYER_RANKING = '/client/playerRanking';
export const PONTUATION_RULES = '/client/pontuationRules';
export const RANKING_INDICATIONS = '/client/rankingIndications';
export const RANKING_CORRECTORS = '/client/rankingCorrectors';

export const WALLET_CLIENT = '/client/wallet';
export const HISTORICAL_WALLET_CLIENT = '/client/historicalWallet';

export const WITHOUT_PERMISSION = 'client/withoutPermission';

// Rotas de dashboard
export const DASHBOARD_CLIENT = '/client/dashboard';
export const DASHBOARD_QUIZ_CLIENT = '/client/dashboardQuiz';
export const DASHBOARD_RESEARCH_AWARD = '/client/dashboardResearchAward';
export const DASHBOARD_GAMIFICATION = '/client/DashboardGamification';

// Rotas de publicação
export const ACTIONS_BUSINESS = '/client/action/actionsBusiness';
export const ACTIONS_BUSINESS_HOME = '/client/action/actionsBusinessHome';
export const ACTIONS_BUSINESS_RESULTS = '/client/action/actionsBusinessResults';

export const ACTIONS_VIRTUALS = '/client/action/actionsVirtuals';
export const CREATE_ACTION_VIRTUAL = '/client/action/createActionVirtual';
export const EDIT_ACTION_VIRTUAL = '/client/action/editActionVirtual';
export const DETAILS_ACTION_VIRTUAL = '/client/action/detailsActionVirtual';
export const PREVIEW_ACTION_VIRTUAL = '/client/action/previewActionVirtual';

export const ACTIONS_QUIZ = '/client/action/actionsQuiz';
export const CREATE_ACTION_QUIZ = '/client/action/createActionQuiz';
export const EDIT_ACTION_QUIZ = '/client/action/editActionQuiz';
export const DETAILS_ACTION_QUIZ = '/client/action/detailsActionQuiz';

export const ACTIONS_OMNICHANNELS = '/client/action/actionsOmnichannels';
export const CREATE_ACTION_OMNICHANNEL =
  '/client/action/createActionOmnichannel';
export const EDIT_ACTION_OMNICHANNEL = '/client/action/editActionOmnichannel';
export const DETAILS_ACTION_OMNICHANNEL =
  '/client/action/detailsActionOmnichannel';

export const ACTIONS_INFORMATIONALS = '/client/action/actionsInformationals';
export const CREATE_ACTION_INFORMATIONAL =
  '/client/action/createActionInformational';
export const EDIT_ACTION_INFORMATIONAL =
  '/client/action/editActionInformational';
export const DETAILS_ACTION_INFORMATIONAL =
  '/client/action/detailsActionInformational';

export const ACTIONS_INVITES = '/client/action/actionsInvites';
export const CREATE_ACTION_INVITE = '/client/action/createActionInvite';
export const EDIT_ACTION_INVITE = '/client/action/editActionInvite';
export const DETAILS_ACTION_INVITE = '/client/action/detailsActionInvite';

export const RESEARCHAWARD_CLIENT = '/client/researchAward';
export const CREATE_RESEARCHAWARD_CLIENT = '/client/createResearchAward';
export const EDIT_RESEARCH_AWARD = '/client/editResearchAward';

// Rotas de benefícios
export const BENEFITS_CLIENT = '/client/benefits';

export const CREATE_BENEFIT = '/client/createBenefit';
export const EDIT_BENEFIT = '/client/editBenefit';
export const DETAILS_BENEFIT = '/client/detailsBenefit';

// Rota de gamificação
export const GAMIFICATION_CLIENT = '/client/gamification';

// Rotas de comunicação
export const MESSAGES = '/client/messages';
export const NEW_MESSAGE = '/client/newMessage';

export const EMAILS = '/client/emails';
export const NEW_EMAIL = '/client/newEmail';

export const BANNERS = '/client/banners';
export const NEW_BANNER = '/client/newBanner';
export const EDIT_BANNER = '/client/editBanner';
export const DETAILS_BANNER = '/client/detailsBanner';

// Rota de pontos extras
export const CONVERSIONS = '/client/conversions';

// Rotas de resgate de prêmios
export const PREMIUMS = '/client/premiums';
export const NEW_PREMIUM = '/client/newPremium';
export const EDIT_PREMIUM = '/client/editPremium';
export const DETAILS_PREMIUM = '/client/detailsPremium';

export const PURCHASE_UPLOADS = '/client/purchaseUploads';
export const VERIFY_PURCHASE = '/client/verifyPurchase';

export const RESCUES = '/client/rescues';
export const RESCUE_DETAILS = '/client/rescueDetails';

// Rotas de players
export const PLAYERS = '/client/players';
export const CREATE_PLAYER = '/client/createPlayer';
export const EDIT_PLAYER = '/client/editPlayer';
export const DETAILS_PLAYER = '/client/detailsPlayer';

// Rota de  leads
export const PUBLIC_TARGET = '/client/publicTarget';

// Rotas de temporadas
export const SEASON_ACTIONS = '/client/seasonActions';
export const SEASONS = '/client/seasons';
export const NEW_SEASON = '/client/newSeason';
export const EDIT_SEASON = '/client/editSeason';
export const DETAILS_SEASON = '/client/detailsSeason';

// Rota de condições gerais
export const TERMS_CLIENT = '/client/termsClient';
export const PREVIEW_TEXT = '/client/previewText';
export const EDIT_TEXT = '/client/editText';

// Rota de email de boas vindas
export const EMAIL_TEMPLATES = '/client/emailTemplates';

// Rotas de landing page
export const LANDING_PAGE_LOGIN = '/client/landingPageLogin';
export const PREVIEW_LANDING_PAGE_LOGIN = '/client/previewLandingPageLogin';
export const LANDING_PAGE = '/client/landingPage';
export const LANDING_PAGE_EDITOR = '/client/landingPage/editor';

// Rotas de influencer
export const INFLUENCERS = '/client/influencers';
export const WELLCOME_INFLUENCER = '/client/wellcomeInfluencer';

// Rotas de detalhes do quiz por player
export const PLAYER_DETAIL_QUIZ = '/client/playerDetailQuiz';
// Rotas de detalhes da pesquisa premiada por player
export const PLAYER_DETAIL_RESEARCH_AWARD = '/client/playerDetailResearchAward';

// Rotas de configuração
export const CONFIG = '/client/config';

// Rota de grupos de acesso
export const ACCESS_GROUPS = '/client/accessGroups';
export const CREATE_ACCESS_GROUP = '/client/createAccessGroup';
export const EDIT_ACCESS_GROUP = '/client/editAccessGroup';
export const DETAILS_ACCESS_GROUP = '/client/detailsAccessGroup';

// Rota de usuários
export const USERS = '/client/users';
export const CREATE_USER = '/client/createUser';
export const EDIT_USER = '/client/editUser';
export const DETAILS_USER = '/client/detailsUser';

//
export const COMUNICATION_CLIENT = '/client/comunicationClient';
export const REPORTS_CLIENT = '/client/reportsClient';
export const CRM_CLIENT = '/client/crmClient';

//
export const WHATSAPP_BUSINESS = '/client/whatsapp-business';
export const LEAD_REGISTER = '/client/leadRegister';

//
export const HISTORICAL_POINTS_CLIENT = '/client/historicalPoints';
export const TUTORIALS = '/client/tutorials';
export const INTEGRATIONS = '/client/integrations';

// Rotas de processamento de planilha
export const WORKSHEET_POINTS = '/client/worksheetPoints';
