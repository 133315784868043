import React, { useState } from 'react';
import ReactPlayer from 'react-player';

export default function VideoBg() {
  const [isPlayng, setIsPlayng] = useState(false);
  const videoBg =
    'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/videoBg.mp4?alt=media&token=8d6a3861-8d76-4554-85aa-5adde29a2aa7';

  return (
    <ReactPlayer
      id="playerContent"
      url={videoBg}
      onReady={() => {
        let videoR = document.querySelector('#playerContent > video');
        setTimeout(() => {
          if (!isPlayng) {
            videoR.play();
          }
          setIsPlayng(true);
        }, 1000);
      }}
      muted={true}
      height="auto"
    />
  );
}
