import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import loadingImage from '../Images/loading.gif';
import { NavBar } from '../NavBar';
import { SideBar } from '../SideBar';
import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class EditTextPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      editorState: null,
      adminText: '',
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ adminText: params.adminText });
    this.getClientText(params.adminText);
  }

  getClientText(adminText) {
    const parameters = {
      searchFunctionality: 'getText',
      userType: 'administrator',
      adminText,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          this.setState({
            htmlPreview: null,
            isLoading: false,
          });
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }

        const contentBlocks = htmlToDraft(result.data.text);
        const contentState = ContentState.createFromBlockArray(contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        Notify(result.message, result.success ? 'success' : 'error');

        this.setState({
          editorState,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log('getClientText error:', error);
      });
  }

  onEditorStateChange(editorState) {
    this.setState({ editorState });
  }

  saveText() {
    this.toastId = toast.info('Salvando texto...', { autoClose: false });

    const { adminText, editorState } = this.state;
    const htmlString = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const parameters = {
      updateFunctionality: 'updateText',
      userType: 'administrator',
      adminText,
      htmlString,
    };

    api
      .post(`/update`, parameters)
      .then((response) => {
        if (toast.isActive(this.toastId)) {
          toast.update(this.toastId, {
            render: response.data.message,
            type: response.data.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
            autoClose: 5000,
          });
        } else {
          Notify(
            response.data.message,
            response.data.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR
          );
        }
      })
      .catch((error) => {
        console.log('updateClient error:', error);
      });
  }

  renderLoading() {
    return (
      <img
        className="loading_image center-block"
        src={loadingImage}
        width="5%"
        alt="Loading"
      />
    );
  }

  renderSaveButton() {
    const { isLoading } = this.state;
    return (
      <div className="oq-padding-vertical">
        <button
          className="btn btn-oq btn-oq-lg"
          disabled={isLoading}
          onClick={() => this.saveText()}
        >
          <i className="fa fa-check" /> Salvar
        </button>
      </div>
    );
  }

  renderEditor() {
    const { editorState, isLoading } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card-secondary">
              <div className="card-secondary-container">
                {isLoading ? (
                  this.renderLoading()
                ) : (
                  <div className="row-fluid oq-padding-vertical">
                    <Editor
                      editorState={editorState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      onEditorStateChange={(eState) =>
                        this.onEditorStateChange(eState)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.renderSaveButton()}
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <div className="container-fluid">
            {this.renderBackButton()}
            <span className="oq-filter-title">
              <i className="fa fa-pencil-square-o" />
              <span>&nbsp; Editor de Texto</span>
            </span>
          </div>
          {this.renderEditor()}
        </div>
      </div>
    );
  }
}

export default EditTextPage;
