import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Markup } from 'interweave';

import './style.css';

export default class OqModalTermsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      termsHtml: this.props.termsHtml,
      termsImage: this.props.termsImage,
      termsType: this.props.termsType,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ ...props });
  }

  render() {
    const { isOpen, termsHtml, termsType } = this.state;

    return (
      <Modal
        open={isOpen}
        onClose={() => this.props.toggleTermsModal()}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <div className="oq-wrapper-contents">
          {termsType == 'policy' ? (
            <iframe
              src="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPOLITICA%20DE%20PRIVACIDADE%20.pdf?alt=media&token=36efa8dc-c5b2-490a-8185-02c940c684fe"
              frameBorder="0"
              className="policy-custom-box"
            />
          ) : (
            <>
              <h1 className="title-alert title-normal">Termos de uso</h1>
              <Markup className="content-alert" content={termsHtml} />
            </>
          )}
        </div>
        <div className="oq-group-horizontal">
          <button
            onClick={() => this.props.toggleTermsModal()}
            className="oq-btn btn-confirm"
          >
            Concordo
          </button>
        </div>
      </Modal>
    );
  }
}
