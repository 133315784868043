import React from 'react';

export function Button({ text, icon, onClick }) {
  return (
    <>
      <button type="button" className="btn btn-primary" onClick={onClick}>
        <div className="d-flex align-items-center">
          <span className="me-2">{text}</span>
          {icon && (
            <i
              className="material-symbols-outlined"
              style={{ fontSize: '12px' }}
            >
              {icon}
            </i>
          )}
        </div>
      </button>
    </>
  );
}
