import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  AreaChart,
} from 'recharts';

import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class ShareInAction extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      loadingShareInAction,
      shareInAction,
      primaryColor,
      PrimaryLighten,
      total,
    } = this.props.dataReport;
    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12 p-0">
              {loadingShareInAction && !shareInAction ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <span className="mb-1 bold">
                    {/* <i className="fa fa-trophy" /> */}
                    <svg
                      className="icon-evolution"
                      width="16"
                      height="16"
                      viewBox="0 0 29 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.2 11C20.9614 11 23.2 8.76142 23.2 6C23.2 3.23858 20.9614 1 18.2 1C15.4386 1 13.2 3.23858 13.2 6C13.2 8.76142 15.4386 11 18.2 11Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.7 14H23.2C26 14 28.2 16.2 28.2 19C28.2 21.8 26 24 23.2 24H13.7C10.9 24 8.70001 21.8 8.70001 19C8.70001 16.2 10.9 14 13.7 14Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.3 10.5C9.12254 10.5 10.6 9.02255 10.6 7.20001C10.6 5.37747 9.12254 3.90002 7.3 3.90002C5.47746 3.90002 4 5.37747 4 7.20001C4 9.02255 5.47746 10.5 7.3 10.5Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.20001 19H4.3C2.5 19 1 17.5 1 15.7C1 13.9 2.5 12.4 4.3 12.4H9.60001"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    &nbsp; Compartilhamentos por Publicação
                  </span>
                  <div className="box-chart">
                    <ResponsiveContainer>
                      <AreaChart
                        data={shareInAction}
                        margin={{
                          top: 10,
                          right: 60,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="color1"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={primaryColor}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={primaryColor}
                              stopOpacity={0}
                            />
                          </linearGradient>
                          <linearGradient
                            id="color2"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0}
                            />
                          </linearGradient>
                          <linearGradient
                            id="color3"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0}
                            />
                          </linearGradient>
                          <linearGradient
                            id="color4"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="name" hide="true" />
                        <YAxis dataKey="total" width={30} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="total"
                          name="Compartilhamentos totais"
                          stroke={primaryColor}
                          fillOpacity={1}
                          fill="url(#color2)"
                          wrapperStyle={total}
                          id="total"
                        />

                        <Area
                          type="monotone"
                          dataKey="facebook"
                          stroke="none"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />

                        <Area
                          type="monotone"
                          dataKey="messenger"
                          stroke="none"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />

                        <Area
                          type="monotone"
                          dataKey="whatsapp"
                          stroke="none"
                          fill={primaryColor}
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                        />
                        <Area
                          type="monotone"
                          dataKey="linkedin"
                          stroke="none"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />
                        <Area
                          type="monotone"
                          dataKey="telegram"
                          stroke="none"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />
                        <Area
                          type="monotone"
                          dataKey="email"
                          stroke="none"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />

                        <Area
                          type="monotone"
                          dataKey="link"
                          stroke="none"
                          name="link copiado"
                          activeDot={{ fill: primaryColor }}
                          fillOpacity={0}
                          fill={primaryColor}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ShareInAction;
