import React, { useState, useRef } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

function Collapsible(props) {
  const [isOpen, setIsOpen] = useState(false);

  const parentRef = useRef();

  return (
    <>
      {props.isVisible && (
        <>
          <li
            className={props.isActive}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <a>
              <div className="content-left">
                <i>{props.icon}</i>
                <span>{props.label}</span>
              </div>
              <div className="content-right">
                {isOpen || props.isActive === 'active' ? (
                  <MdKeyboardArrowUp />
                ) : (
                  <MdKeyboardArrowDown />
                )}
              </div>
            </a>
          </li>
          <div
            className="oq-collapsible-content-parent"
            ref={parentRef}
            style={
              isOpen || props.isActive === 'active'
                ? {
                    height: parentRef.current
                      ? `${parentRef.current.scrollHeight}px`
                      : 'auto',
                  }
                : { height: '0' }
            }
          >
            <div className="oq-collapsible-content">{props.children}</div>
          </div>
        </>
      )}
    </>
  );
}

export default Collapsible;
