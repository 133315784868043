import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
} from 'recharts';

import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class EngagementPlayers extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      loadingEngagementPlayers,
      engagementPlayers,
      primaryColor,
      PrimaryLighten,
    } = this.props.dataReport;
    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12 mx-auto p-0">
              {loadingEngagementPlayers && !engagementPlayers ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <span className="mb-1 bold">
                    <svg
                      className="icon-copy"
                      width="16"
                      height="16"
                      viewBox="0 0 19 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.1 17.2V22.7C13.1 23.5 12.4 24.2 11.6 24.2H2.5C1.7 24.2 1 23.5 1 22.7V9.40002C1 8.60002 1.7 7.90002 2.5 7.90002H6"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14 1V3.79999C14 4.59999 14.7 5.29999 15.5 5.29999H18.1"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.1 15.8C18.1 16.6 17.4 17.3 16.6 17.3H7.5C6.7 17.3 6 16.6 6 15.8V2.5C6 1.7 6.7 1 7.5 1H15C17 1 18.1 3 18.1 5V15.8Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    &nbsp; Engajamentos por publicação
                  </span>
                  <div className="box-chart">
                    <ResponsiveContainer>
                      <AreaChart data={engagementPlayers}>
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={primaryColor}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={primaryColor}
                              stopOpacity={0}
                            />
                          </linearGradient>
                          <linearGradient
                            id="colorPv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor={PrimaryLighten}
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="action" hide="true" />
                        <YAxis dataKey="engajamento" width={30} />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="playerInAction"
                          name="Número de players"
                          stroke={primaryColor}
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                        <Area
                          type="monotone"
                          dataKey="engajamento"
                          name="Engajamento"
                          unit="%"
                          stroke={PrimaryLighten}
                          fillOpacity={1}
                          fill="url(#colorPv)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EngagementPlayers;
