import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import ChartPie from '../../AdminClient/components/ChartPie';
import * as config from '../../Config';
import Linegraphs from '../../components/Modals/Linegraphs';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class DashboardAdminPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      chartDataPie: [],
      stateLeadsData: [],
      pontuationData: [],
      shareData: [],
      loadingGrapichs: [],
      loadingStats: [],
      playersCount: 0,
      leadsCount: 0,
      pageViewCount: 0,
      conversionsCount: 0,
      totalPoints: 0,
      redeemPoints: 0,
      modalIsOpen: false,
      modalTitle: '',
      amountPerWeekDay: [{ x: 0, y: 0 }],
      amountPerDay: [{ x: 0, y: 0 }],
      amountPerMonth: [{ x: 0, y: 0 }],
      amountOfPlayersPerWeekDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerDay: [{ x: 0, y: 0 }],
      amountOfPlayersPerMonth: [{ x: 0, y: 0 }],
      amountOfLeadsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerDay: [{ x: 0, y: 0 }],
      amountOfLeadsPerMonth: [{ x: 0, y: 0 }],
      amountOfConversionsPerWeekDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerDay: [{ x: 0, y: 0 }],
      amountOfConversionsPerMonth: [{ x: 0, y: 0 }],
      pageViewsPerWeekDay: [{ x: 0, y: 0 }],
      pageViewsPerDay: [{ x: 0, y: 0 }],
      pageViewsPerMonth: [{ x: 0, y: 0 }],
      generatedPointsPerWeekDay: [{ x: 0, y: 0 }],
      generatedPointsPerDay: [{ x: 0, y: 0 }],
      generatedPointsPerMonth: [{ x: 0, y: 0 }],
      redeemPointsPerWeekDay: [{ x: 0, y: 0 }],
      redeemPointsPerDay: [{ x: 0, y: 0 }],
      redeemPointsPerMonth: [{ x: 0, y: 0 }],
    };
    return initialState;
  }

  getStats() {
    this.getPlayersCount();
    this.getPageViewCount();
    this.getLeadsCount();
    this.getConversionCount();
    this.getTotalPoints();
    this.getRedeemPoints();
  }

  getGraphics() {
    this.getLeadsPerActionGraphic();
    this.getLeadsPerStateGraphic();
    this.getScoreDistributionGraphic();
    this.getShareChannelsGraphic();
  }

  getShareChannelsGraphic() {
    this.handleLoadingGraphics('shareChannel', true);
    const parameters = {
      graphicsFunctionality: 'loadGraphics',
      userType: 'administrator',
      graphicsName: 'shareChannel',
    };

    api
      .post(`/graphics`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ shareData: result });
        this.handleLoadingGraphics('shareChannel', false);
      })
      .catch((error) => {
        console.log('getShareChannelsGraphic error:', error);
        this.handleLoadingGraphics('shareChannel', false);
      });
  }

  getLeadsPerStateGraphic() {
    this.handleLoadingGraphics('leadsPerState', true);

    const parameters = {
      graphicsFunctionality: 'loadGraphics',
      userType: 'administrator',
      graphicsName: 'leadsPerState',
    };

    api
      .post(`/graphics`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ stateLeadsData: result });
        this.handleLoadingGraphics('leadsPerState', false);
      })
      .catch((error) => {
        console.log('getLeadsPerStateGraphic error:', error);
        this.handleLoadingGraphics('leadsPerState', false);
      });
  }

  getLeadsPerActionGraphic() {
    this.handleLoadingGraphics('leadsPerAction', true);

    const parameters = {
      graphicsFunctionality: 'loadGraphics',
      userType: 'administrator',
      graphicsName: 'leadsPerAction',
    };

    api
      .post(`/graphics`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ chartDataPie: result });
        this.handleLoadingGraphics('leadsPerAction', false);
      })
      .catch((error) => {
        console.log('getLeadsPerActionGraphic error:', error);
        this.handleLoadingGraphics('leadsPerAction', false);
      });
  }

  getScoreDistributionGraphic() {
    this.handleLoadingGraphics('scoreDistribution', true);

    const parameters = {
      graphicsFunctionality: 'loadGraphics',
      userType: 'administrator',
      graphicsName: 'scoreDistribution',
    };

    api
      .post(`/graphics`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ pontuationData: result });
        this.handleLoadingGraphics('scoreDistribution', false);
      })
      .catch((error) => {
        console.log('getScoreDistributionGraphic error:', error);
        this.handleLoadingGraphics('scoreDistribution', false);
      });
  }

  getPlayersCount() {
    this.handleLoadingStats('playersCount', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'playersCount',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          amountOfPlayersPerWeekDay,
          amountOfPlayersPerDay,
          amountOfPlayersPerMonth,
          playersCount,
        } = res.data;

        this.setState({
          amountOfPlayersPerWeekDay,
          amountOfPlayersPerDay,
          amountOfPlayersPerMonth,
          playersCount,
        });
        this.handleLoadingStats('playersCount', false);
      })
      .catch((error) => {
        console.log('getPlayersCount error:', error);
        this.handleLoadingStats('playersCount', false);
      });
  }

  getPageViewCount() {
    this.handleLoadingStats('pageViewCount', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'pageViewCount',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          pageViewsPerWeekDay,
          pageViewsPerDay,
          pageViewsPerMonth,
          pageViewCount,
        } = res.data;

        this.setState({
          pageViewsPerWeekDay,
          pageViewsPerDay,
          pageViewsPerMonth,
          pageViewCount,
        });
        this.handleLoadingStats('pageViewCount', false);
      })
      .catch((error) => {
        console.log('getPageViewCount error:', error);
        this.handleLoadingStats('pageViewCount', false);
      });
  }

  getLeadsCount() {
    this.handleLoadingStats('leadsCount', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'leadsCount',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          amountOfLeadsPerWeekDay,
          amountOfLeadsPerDay,
          amountOfLeadsPerMonth,
          leadsCount,
        } = res.data;

        this.setState({
          amountOfLeadsPerWeekDay,
          amountOfLeadsPerDay,
          amountOfLeadsPerMonth,
          leadsCount,
        });

        this.handleLoadingStats('leadsCount', false);
      })
      .catch((error) => {
        console.log('getLeadsCount error:', error);
        this.handleLoadingStats('leadsCount', false);
      });
  }

  getConversionCount() {
    this.handleLoadingStats('conversionsCount', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'conversionsCount',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          amountOfConversionsPerWeekDay,
          amountOfConversionsPerDay,
          amountOfConversionsPerMonth,
          conversionsCount,
        } = res.data;

        this.setState({
          amountOfConversionsPerWeekDay,
          amountOfConversionsPerDay,
          amountOfConversionsPerMonth,
          conversionsCount,
        });

        this.handleLoadingStats('conversionsCount', false);
      })
      .catch((error) => {
        console.log('getConversionCount error:', error);
        this.handleLoadingStats('conversionsCount', false);
      });
  }

  getTotalPoints() {
    this.handleLoadingStats('totalPoints', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'totalPoints',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          generatedPointsPerWeekDay,
          generatedPointsPerDay,
          generatedPointsPerMonth,
          totalPoints,
        } = res.data;

        this.setState({
          generatedPointsPerWeekDay,
          generatedPointsPerDay,
          generatedPointsPerMonth,
          totalPoints,
        });

        this.handleLoadingStats('totalPoints', false);
      })
      .catch((error) => {
        console.log('getPlayersCount error:', error);
        this.handleLoadingStats('totalPoints', false);
      });
  }

  getRedeemPoints() {
    this.handleLoadingStats('redeemPoints', true);

    const parameters = {
      statsFunctionality: 'loadStats',
      userType: 'administrator',
      statsName: 'redeemPoints',
    };

    api
      .post(`/stats`, parameters)
      .then((res) => {
        const {
          redeemPointsPerWeekDay,
          redeemPointsPerDay,
          redeemPointsPerMonth,
          redeemPoints,
        } = res.data;

        this.setState({
          redeemPointsPerWeekDay,
          redeemPointsPerDay,
          redeemPointsPerMonth,
          redeemPoints,
        });

        this.handleLoadingStats('redeemPoints', false);
      })
      .catch((error) => {
        console.log('getRedeemPoints error:', error);
        this.handleLoadingStats('redeemPoints', false);
      });
  }

  handleLoadingStats(stats, loading) {
    this.setState((prevState) => ({
      loadingStats: {
        ...prevState.loadingStats,
        [stats]: loading,
      },
    }));
  }

  handleLoadingGraphics(graphic, loading) {
    this.setState((prevState) => ({
      loadingGrapichs: {
        ...prevState.loadingGrapichs,
        [graphic]: loading,
      },
    }));
  }

  renderLoading() {
    return (
      <div className="d-flex justify-content-center vertical-center">
        <i className="fa-circle-notch fa-spin fas oq-primary-color" />
      </div>
    );
  }

  render() {
    const {
      modalIsOpen,
      modalTitle,
      amountPerWeekDay,
      amountPerDay,
      amountPerMonth,
      amountOfPlayersPerWeekDay,
      amountOfPlayersPerDay,
      amountOfPlayersPerMonth,
      amountOfLeadsPerWeekDay,
      amountOfLeadsPerDay,
      amountOfLeadsPerMonth,
      pageViewsPerWeekDay,
      pageViewsPerDay,
      pageViewsPerMonth,
      amountOfConversionsPerWeekDay,
      amountOfConversionsPerDay,
      amountOfConversionsPerMonth,
      generatedPointsPerWeekDay,
      generatedPointsPerDay,
      generatedPointsPerMonth,
      redeemPointsPerWeekDay,
      redeemPointsPerDay,
      redeemPointsPerMonth,
    } = this.state;

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid  text-center"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              <br />
              <br />
              <div className="col-12">
                <h4>Bem Vindo!</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const DashboardPageLink = () => (
  <Link className="menu-link" to={routes.DASHBOARD_ADMIN}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">Dashboard</span>
  </Link>
);

export default DashboardAdminPage;

export { DashboardPageLink };
