import React, { Component } from 'react';
import moment from 'moment';
// import locale from "moment/locale/pt-br";
import logoImg from '../Images/logo.png';
import { SignOut } from '../SignOut';
import { UserDataContext } from '../../hooks/useUserData';

class NavBar extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      userName: '',
      sidebarStatus: false,
    };

    return initialState;
  }

  componentDidMount() {
    const { clientData } = this.context;
    const sidebarStatus = localStorage.getItem('sideBarStatus') === 'true';

    this.setState({ sidebarStatus, userName: clientData.username });
  }

  render() {
    moment.locale('pt-br');
    const { sidebarStatus, userName } = this.state;
    const time = moment().format('HH:mm');
    const day = moment().format('DD');
    const year = moment().format('MMM YYYY');
    return (
      <div id="navbar" className={sidebarStatus ? 'active' : ''}>
        <nav className="navbar navbar-default">
          <div className="container-fluid">
            <div className="navbar-left">
              <div
                id="sidebarCollapse"
                className="navbar-brand clickable"
                onClick={() => this.setState({ sidebarStatus: !sidebarStatus })}
              >
                <i className="glyphicon glyphicon-custom-icon" />
              </div>
              <span className="navbar-text nav-text-blue ">{userName}</span>
            </div>
            <div
              id="nav-right"
              className="collapse float-right navbar-collapse"
            >
              <span className="time navbar-text nav-text-blue">{time}</span>
              <span className="vl-small navbar-text" />
              <span className="time navbar-text nav-text-blue">
                {day}
                &nbsp;&nbsp;
                <span className="nav-text-grey">{year}</span>
              </span>
              <div className="navbar-text">
                <img className="main-img" src={logoImg} alt="OQ" width="40px" />
              </div>
              <div className="navbar-text" id="logout">
                <SignOut />
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavBar;

export { NavBar };
