import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';
import { Notify } from '../../../../components/Notify';
import { api } from '../../../../Config';
import * as routes from '../../../../routes';
import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

class DetailsWallet extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.translate = props.t;

    this.state = {
      month: '',
      year: '',
      client: {},
      playersRescuesClient: [],
      formData: {
        regional: null,
        totalEntries: 0,
        totalExits: 0,
        balance: 0,
        entrance: 0,
      },
      isLoading: false,
      isLoadingWallet: false,
      isLoadingTransferRequest: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.month !== this.state.month) {
      this.handleSearchInputChange(this.state.month, this.state.year);
    }
    if (prevState.year !== this.state.year) {
      this.handleSearchInputChange(this.state.month, this.state.year);
    }
  }

  componentDidMount() {
    const { clientData } = this.context;
    this.setState({ clientId: clientData.uid });
    if (
      !clientData.entrancePaymentByRegional ||
      (clientData.entrancePaymentByRegional &&
        clientData.entrancePaymentByRegional !== 'true')
    ) {
      // this.getWalletClient(clientData.uid);
      this.getTransferenceRequest(clientData.uid);
      this.getWalletClient(clientData.uid);
    }
  }

  getWalletClient(clientId, regional = null) {
    this.setState({ isLoadingWallet: true }, () => {
      const parameters = {
        searchFunctionality: 'getWalletClient',
        userType: 'client',
        clientId,
        regional,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data.data;

          if (!res.data.success) {
            this.setState(
              {
                isLoadingWallet: false,
                formData: {
                  regional: null,
                  totalEntries: 0,
                  totalExits: 0,
                  balance: 0,
                },
              }
              // () => {
              //   Notify(
              //     res.data.message,
              //     res.data.success ? 'success' : 'warn'
              //   );
              // }
            );
            return;
          }
          this.setState(
            {
              isLoadingWallet: false,
              formData: {
                totalEntries: result.totalEntries,
                totalExits: result.totalExits,
                balance: result.balance,
                // regional:
                //   !result.client.entrancePaymentByRegional ||
                //   (result.client.entrancePaymentByRegional &&
                //     result.client.entrancePaymentByRegional !== 'true')
                //     ? ''
                //     : result.regional,
              },
            }
            // Notify(res.data.message, res.data.success ? 'success' : 'warn')
          );
        })
        .catch((error) => {
          console.log('getWalletClient error:', error);
          this.setState({
            isLoadingWallet: false,
            formData: {
              totalEntries: 0,
              totalExits: 0,
              balance: 0,
            },
          });
        });
    });
  }

  getTransferenceRequest(clientId, month = null, year = null, regional = null) {
    this.setState({ isLoadingTransferRequest: true }, () => {
      const parameters = {
        searchFunctionality: 'getTransferenceRequest',
        userType: 'client',
        clientId,
        month,
        year,
        regional,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data.data;
          // return;
          if (!res.data.success) {
            this.setState(
              {
                isLoadingTransferRequest: false,
                client: {},
                playersRescuesClient: [],
              },
              () => {
                Notify(res.data.message, res.data.success ? 'success' : 'warn');
              }
            );
            return;
          }
          this.setState(
            {
              isLoadingTransferRequest: false,
              client: result.client,
              playersRescuesClient: result.playersRescuesClient,
            },
            Notify(res.data.message, res.data.success ? 'success' : 'warn')
          );
        })
        .catch((error) => {
          console.log('getWalletClient error:', error);
          this.setState({
            isLoadingTransferRequest: false,
            client: {},
            playersRescuesClient: [],
          });
        });
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  handleFormDataChange(name, value, regional = null) {
    const { formData } = this.state;
    // if (name === 'regional' && !value) {
    //   this.setState({
    //     playersRescuesClient: [],
    //     formData: {
    //       totalEntries: 0,
    //       totalExits: 0,
    //       balance: 0,
    //     },
    //   });
    //   return Notify('Selecione a regional', 'warn');
    // }

    if (regional) {
      const { clientId } = this.state;
      this.getWalletClient(clientId, value);
      this.getTransferenceRequest(
        clientId,
        formData.month,
        formData.year,
        value
      );
    }
  }

  formatMoney(amount) {
    if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  renderDetailsWalletByRegional() {
    const { clientData } = this.context;
    const { client, formData } = this.state;
    if (!client) return;

    const scopeFieldName =
      this.state.clientData &&
      this.state.clientData.scopeFieldName &&
      this.state.clientData.scopeFieldName !== ''
        ? this.state.clientData.scopeFieldName
        : 'Segmentação';

    return (
      <div className="col-md-12">
        <div className="row col-md-4">
          <h4 className="oq-font-book oq-padding-vertical">
            Detalhes da carteira
          </h4>
        </div>
        <div className="col-md-6">
          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="regional">
                {scopeFieldName}
              </label>
              <select
                required={true}
                className="form-control input-oq rounded"
                name="regional"
                onChange={(e) =>
                  this.handleFormDataChange(
                    e.target.name,
                    e.target.value,
                    'byRegional'
                  )
                }
                disabled={this.state.isLoading}
                // value={formData && normalizeLowerCase(formData.regional)}
              >
                <option value="" disabled selected>
                  {this.state.isLoading ? 'Carregando ...' : 'Escolha ...'}
                </option>
                <option value="">Tudo</option>
                {clientData.regional &&
                  _.map(clientData.regional, (regional) => {
                    return (
                      <option
                        value={
                          regional ? normalizeLowerCase(regional) : regional
                        }
                      >
                        {regional}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="form-group  col-md-4"></div>

            <div className="form-group  col-md-4"></div>
          </div>
        </div>
        <div className="row">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyName">
                Nome da empresa
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyName"
                name="companyName"
                defaultValue={clientData && clientData.companyName}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyInscription">
                CNPJ
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyInscription"
                name="companyInscription"
                defaultValue={clientData && clientData.companyInscription}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="accountableName">
                Responsável
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="accountableName"
                name="accountableName"
                defaultValue={clientData && clientData.accountableName}
                readOnly={true}
              />
            </div>
          </div>

          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalEntries">
                Total entradas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalEntries"
                name="totalEntries"
                readOnly={true}
                placeholder="Total entradas"
                type="text"
                value={
                  formData
                    ? this.formatMoney(formData.totalEntries) || '0'
                    : '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalExits">
                Total saídas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalExits"
                name="totalExits"
                readOnly={true}
                placeholder="Total saídas"
                type="text"
                value={
                  (formData && this.formatMoney(formData.totalExits)) || '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="balance">
                Saldo
              </label>
              <input
                className="form-control input-oq rounded"
                id="balance"
                name="balance"
                readOnly={true}
                placeholder="Saldo"
                type="text"
                value={(formData && this.formatMoney(formData.balance)) || '0'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDetailsWalletWithoutRegional() {
    const { clientData } = this.context;
    const { client, formData } = this.state;
    if (!client) return;

    return (
      <div className="col-md-12">
        <div className="row col-md-4">
          <h4 className="oq-font-book oq-padding-vertical">
            Detalhes da carteira
          </h4>
        </div>
        <div className="col-md-6"></div>
        <div className="row">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyName">
                Nome da empresa
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyName"
                name="companyName"
                defaultValue={clientData && clientData.companyName}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="companyInscription">
                CNPJ
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="companyInscription"
                name="companyInscription"
                defaultValue={clientData && clientData.companyInscription}
                readOnly={true}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="accountableName">
                Responsável
              </label>
              <input
                type="text"
                className="form-control input-oq rounded"
                id="accountableName"
                name="accountableName"
                defaultValue={clientData && clientData.accountableName}
                readOnly={true}
              />
            </div>
          </div>

          <div className="row col-md-12">
            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalEntries">
                Total entradas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalEntries"
                name="totalEntries"
                readOnly={true}
                placeholder="Total entradas"
                type="text"
                value={
                  formData
                    ? this.formatMoney(formData.totalEntries) || '0'
                    : '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="totalExits">
                Total saídas
              </label>
              <input
                className="form-control input-oq rounded"
                id="totalExits"
                name="totalExits"
                readOnly={true}
                placeholder="Total saídas"
                type="text"
                value={
                  (formData && this.formatMoney(formData.totalExits)) || '0'
                }
              />
            </div>

            <div className="form-group  col-md-4">
              <label className="input-oq-label" htmlFor="balance">
                Saldo
              </label>
              <input
                className="form-control input-oq rounded"
                id="balance"
                name="balance"
                readOnly={true}
                placeholder="Saldo"
                type="text"
                value={(formData && this.formatMoney(formData.balance)) || '0'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDetailsWallet() {
    const { clientData } = this.context;
    if (
      !clientData.entrancePaymentByRegional ||
      (clientData.entrancePaymentByRegional &&
        clientData.entrancePaymentByRegional !== 'true')
    ) {
      return this.renderDetailsWalletWithoutRegional();
    } else {
      return this.renderDetailsWalletByRegional();
    }
  }

  exportWallets() {
    const { playersRescuesClient } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(playersRescuesClient, (client) => {
        const ArrTypeAccount = [
          {
            label: 'Conta Corrente',
            value: 'CONTA_CORRENTE',
          },
          {
            label: 'Conta Poupança',
            value: 'CONTA_POUPANCA',
          },
          {
            label: 'Conta Fácil',
            value: 'CONTA_FACIL',
          },
          {
            label: 'Entidades Públicas',
            value: 'ENTIDADES_PUBLICAS',
          },
        ];

        const arrTypePix = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'TELEFONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'CHAVE_ALEATORIA',
          },
        ];

        let isPaymentMethodTed = true;
        let typePix = {
          label: '',
          value: '',
        };

        if (
          client.rescue &&
          client.rescue.accountData &&
          client.rescue.accountData.paymentMethod &&
          client.rescue.accountData.paymentMethod.label === 'pix' &&
          client.rescue.accountData.paymentMethod.value
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePix.find(
            (type) => type.value === client.rescue.accountData.typePix
          );
        }

        const labelTypeAccount =
          client.rescue &&
          ArrTypeAccount.find(
            (type) => type.value === client.rescue.accountData.accountType
          );

        return {
          'Status da transferência': client.rescue.statusTransfer
            ? client.rescue.statusTransfer
            : 'N/A',
          Processado: client.rescue.closeProccess ? 'Sim' : 'Não',
          'Data do resgate': client.rescue
            ? moment(client.rescue.createdAt).format('DD/MM/YYYY')
            : '--',
          'Valor a ser pago':
            this.formatMoney(client.rescue.totalPaymentValue) || '0',

          'Qtd. de prêmios': client.rescue
            ? client.rescue.awards.length
            : 'N/A',
          'Nome do player': client.playerActive
            ? client.player.name
            : 'Deletado',
          'Método de pagamento':
            client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod &&
            client.rescue.accountData.paymentMethod.label
              ? client.rescue.accountData.paymentMethod.label
              : 'ted',
          'Tipo de conta':
            client.rescue && labelTypeAccount ? labelTypeAccount.label : 'N/A',
          'Tipo de chave pix':
            client.rescue &&
            client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod &&
            client.rescue.accountData.paymentMethod.label === 'pix'
              ? typePix.label
              : 'N/A',
          'Chave pix':
            client.rescue &&
            client.rescue &&
            client.rescue.accountData &&
            client.rescue.accountData.paymentMethod &&
            client.rescue.accountData.paymentMethod.label === 'pix'
              ? client.rescue.accountData.pixkey
              : 'N/A',
          Agencia: client.rescue ? client.rescue.accountData.agency : 'N/A',
          'Digito da agencia': client.rescue
            ? client.rescue.accountData.digitAgency
            : '',
          Banco: client.rescue ? client.rescue.accountData.bank : 'N/A',
          Conta: client.rescue ? client.rescue.accountData.conta : 'N/A',
          'Digito da conta': client.rescue
            ? client.rescue.accountData.digitCount
            : 'N/A',
          CPF: client.rescue ? client.rescue.accountData.cpf : 'N/A',
          Nome: client.rescue ? client.rescue.accountData.name : 'N/A',
          'Pontos de resgate': client.rescue
            ? client.rescue.redeemedPoints
            : 'N/A',

          'Id da transferencia Transfeera': client.rescue
            ? client.rescue.transferId
            : 'N/A',
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Carteira');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Carteira.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  handleSearchInputChange(month = null, year = null) {
    const { clientData } = this.context;
    const { formData } = this.state;
    // const { value } = e.target;
    // const label = e.target.options[e.target.selectedIndex].text;

    this.getTransferenceRequest(clientData.uid, month, year, formData.regional);
  }

  renderRescuesList() {
    const { playersRescuesClient } = this.state;

    playersRescuesClient
      .sort(
        (a, b) => new Date(a.rescue.createdAt) - new Date(b.rescue.createdAt)
      )
      .reverse();

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="user">
            {this.translate('Player')}
            <ReactTooltip
              id="user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Player')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.playerActive ? d.player.name : 'Deletado'),
        id: 'user',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="closeProccess">
            {this.translate('Processado')}
            <ReactTooltip
              id="closeProccess"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Processado')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.rescue.closeProccess ? 'Sim' : 'Não'),
        id: 'closeProccess',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="statusTransfer">
            {this.translate('Status da Transf.')}
            <ReactTooltip
              id="statusTransfer"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Status da Transferência')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.rescue.statusTransfer ? d.rescue.statusTransfer : 'N/A',
        id: 'statusTransfer',
      },
      {
        id: 'rescueData',
        Header: (
          <span data-tip="React-tooltip" data-for="date">
            {this.translate('Data do resgate')}
            <ReactTooltip
              id="date"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Data do resgate')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.rescue ? moment(d.rescue.createdAt).format('DD/MM/YYYY') : '--',
      },
      {
        Header: (
          <span
            data-tip="React-tooltip"
            data-for="nfNumber"
            className="center-cell"
          >
            {this.translate('Valor a ser pago')}
            <ReactTooltip
              id="redeemedPoints"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Valor a ser pago')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'redeemedPoints',
        accessor: (d) => {
          if (d.rescue.totalPaymentValue !== 0 && !d.rescue.totalPaymentValue)
            return 'N/A';
          if (d.rescue.totalPaymentValue === 1)
            return `${this.formatMoney(d.rescue.totalPaymentValue)}`;
          if (d.rescue.totalPaymentValue > 1)
            return `${this.formatMoney(d.rescue.totalPaymentValue)}`;

          return '--';
        },
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            <span
              data-tip="React-tooltip"
              data-for="awards"
              className="center-cell"
            >
              {this.translate('Qtd de prêmios')}
              <ReactTooltip
                id="awards"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>
                  {this.translate('OrderBy') +
                    this.translate('Qtd. de Prêmios')}
                </span>
              </ReactTooltip>
            </span>
          </div>
        ),
        id: 'awards',
        accessor: (d) =>
          d.rescue && d.rescue.awards ? d.rescue.awards.length : 'N/A',
        className: 'center-cell',
      },
      {
        id: 'id',
        Header: '',
        accessor: 'id',
        Cell: (props) => (
          <span className="text-center clickable">
            <Link
              to={`${routes.RESCUE_DETAILS}/${props.original.rescue.id}`}
              className="btn btn-oq btn-sm btn-block"
            >
              Visualizar
            </Link>
          </span>
        ),
      },
    ];

    const months = [
      {
        label: 'Janeiro',
        value: 0,
      },
      {
        label: 'Fevereiro',
        value: 1,
      },
      {
        label: 'Março',
        value: 2,
      },
      {
        label: 'Abril',
        value: 3,
      },
      {
        label: 'Maio',
        value: 4,
      },
      {
        label: 'Junho',
        value: 5,
      },
      {
        label: 'Julho',
        value: 6,
      },
      {
        label: 'Agosto',
        value: 7,
      },
      {
        label: 'Setembro',
        value: 8,
      },
      {
        label: 'Outubro',
        value: 9,
      },
      {
        label: 'Novembro',
        value: 10,
      },
      {
        label: 'Dezembro',
        value: 11,
      },
    ];

    const years = [
      {
        label: '2024',
        value: 2024,
      },
      {
        label: '2023',
        value: 2023,
      },
      {
        label: '2022',
        value: 2022,
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title">
            <i className="fa fa-gift" />
            &nbsp; Pedidos de transferências
          </span>
          <button
            type="button"
            className="btn btn-oq pull-right"
            onClick={() => this.exportWallets()}
            disabled={_.size(playersRescuesClient) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>

          <div className="col-md-3 form-group pull-right">
            <select
              className="form-control input-oq"
              placeholder="Ano"
              name="year"
              onChange={(e) => this.setState({ year: e.target.value })}
            >
              <option value="">Selecione o ano</option>
              {_.size(years) > 0 &&
                _.map(years, (month, idx) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-3 form-group pull-right">
            <select
              className="form-control input-oq"
              placeholder="Mês"
              name="month"
              onChange={(e) => this.setState({ month: e.target.value })}
            >
              <option value="">Selecione o mês</option>
              {_.size(months) > 0 &&
                _.map(months, (month, idx) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
            </select>
          </div>
          <div className="oq-line" />
          <div className="oq-padding-bottom" />
          <ReactTable
            data={playersRescuesClient}
            defaultPageSize={5}
            columns={columns}
            loading={this.state.isLoadingTransferRequest}
            previousText="Anterior"
            nextText="Próxima"
            loadingText="Carregando..."
            noDataText="Nenhum resgate para ser exibido"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </div>
      </div>
    );
  }

  goToHistoricalWalletPage() {
    const { history } = this.props;
    history.push(routes.HISTORICAL_WALLET_CLIENT);
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Carteira do cliente
                </span>

                <button
                  style={{ marginLeft: '10px' }}
                  type="button"
                  className="btn btn-oq"
                  onClick={() => this.goToHistoricalWalletPage()}
                  disabled={_.size(this.state.playersRescuesClient) === 0}
                >
                  <i className="fa fa-history" aria-hidden="true"></i>
                  {this.translate('Histórico da carteira')}
                </button>
              </h1>
            </div>
            <form
            // onSubmit={e => this.handleFormSubmit(e)}
            >
              {this.renderDetailsWallet()}
            </form>
            {this.renderRescuesList()}
          </div>
        </div>
      </div>
    );
  }
}

const DetailsWalletPageLink = () => (
  <Link className="menu-link" to={routes.WALLET_CLIENT}>
    <FontAwesomeIcon icon={faWallet} />
    <span>Financeiro</span>
  </Link>
);

export default DetailsWallet;

export { DetailsWalletPageLink };
