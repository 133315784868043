async function getClientData() {
  let type = '';
  let link = '';

  // Pegando a url atual
  const url = window.location.origin;

  // Verificando se a URL é default
  const isDefaultURL =
    url.includes('oqdigital') ||
    url.includes('web.app') ||
    url.includes('localhost') ||
    url.includes('firebaseapp');

  if (isDefaultURL) {
    const clientAliasLocalStorage = localStorage.getItem('clientAlias');
    const clientAliasURL = window.location.pathname.includes('/client/signin/')
      ? window.location.pathname.replace('/client/signin/', '')
      : '';
    const teste = localStorage.getItem('');

    // primeiro, verificar o localstorage
    if (clientAliasLocalStorage && clientAliasLocalStorage !== 'not-found') {
      // verifica se o alias do localstorage é o mesmo da url
      if (clientAliasLocalStorage === clientAliasURL) {
        localStorage.setItem('clientAlias', clientAliasLocalStorage);
        localStorage.removeItem('activeDomain');

        return { type: 'clientAlias', link: clientAliasLocalStorage };
      }
      if (clientAliasLocalStorage && !clientAliasURL) {
        return { type: 'clientAlias', link: clientAliasLocalStorage };
      }
      // se for diferente sobrescreve pelo que tá no url

      localStorage.setItem('clientAlias', clientAliasURL);
      localStorage.removeItem('activeDomain');

      return { type: 'clientAlias', link: clientAliasURL };
    }

    // se não existir o clientAlias no localstorage, verifica pela URL
    if (clientAliasURL.trim() !== '') {
      localStorage.setItem('clientAlias', clientAliasURL);
      localStorage.removeItem('activeDomain');

      return { type: 'clientAlias', link: clientAliasURL };
    }

    // A URL é default, mas não tem o ALIAS na url e nem no localstorage, redireciona para /not-found
    if (!clientAliasURL.trim() !== '' && !clientAliasLocalStorage) {
      localStorage.removeItem('activeDomain');
      localStorage.removeItem('clientAlias');

      // window.location.href = '/client/signin/not-found';
      window.location.replace(`/client/not-found`);
      return;
    }
  }

  // Entrou aqui, buscar pelo activeDomain
  const activeDomain = window.location.hostname;
  type = 'activeDomain';
  link = activeDomain;
  localStorage.setItem('activeDomain', activeDomain);
  localStorage.removeItem('clientAlias');

  return { type, link };
}

export default getClientData;
