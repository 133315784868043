import React from 'react';
import * as GoogleIcons from 'react-icons/md';
import { Link } from 'react-router-dom';

import * as routes from '../../routes';
import { useUserData } from '../../hooks/useUserData';

export function SignOut() {
  const { signOut } = useUserData();
  async function logOff() {
    signOut();
  }

  return (
    <Link id="oq-logout" to={routes.SIGN_IN_ADMIN} onClick={logOff}>
      <i>
        <GoogleIcons.MdExitToApp />
      </i>
      <span>Sair</span>
    </Link>
  );
}
