import React from 'react';
import Modal from 'react-responsive-modal';

function ModalReadTerms(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Você concorda com os Termos de Uso da plataforma?
        </h2>
        <p>
          Ao clicar em <strong>Concordo</strong> você concorda com os
          <strong>
            {' '}
            <a
              className="clickable useTerms"
              href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FOQUseTerms.pdf?alt=media&token=0d55dbd3-d83c-4c12-8738-4294c47cd3e5"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termos de Uso
            </a>{' '}
          </strong>
          e
          <strong>
            {' '}
            <a
              className="clickable useTerms"
              href="https://firebasestorage.googleapis.com/v0/b/oq20-139d9.appspot.com/o/TermsAndPolitics%2FOQPrivacyPolitics.pdf?alt=media&token=f9fdf509-5373-498d-9d26-8d3f86957670"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de Privacidade
            </a>{' '}
          </strong>
          da plataforma.
        </p>
        <p>Essa exclusão não poderá ser desfeita após a confirmação.</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button type="button" className="oq-btn" onClick={props.close}>
          Cancelar
        </button>
        <button
          type="button"
          className="oq-btn btn-confirm"
          onClick={() => {
            props.confirmReadTerms();
            props.close();
          }}
        >
          Concordo
        </button>
      </div>
    </Modal>
  );
}

export default ModalReadTerms;
