import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsivePieCanvas } from '@nivo/pie';
import _ from 'lodash';

import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class ShareData extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loadingShareChannel, shareData } = this.props.dataReport;
    const chartDataPie = shareData;

    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12">
              {loadingShareChannel && !chartDataPie ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <div className="icon-send">
                    <svg
                      width="16"
                      height="16"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512.001 512.001"
                    >
                      <g>
                        <g>
                          <path
                            d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
    c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
    c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
    C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
    l-74.979-154.599l228.03-228.027L302.883,459.907z"
                          />
                        </g>
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                    &nbsp; Canais de compartilhamento
                  </div>

                  <div className="box-chart">
                    <div className="w-100 h-100">
                      <div
                        className="graph-wrapper"
                        style={{ marginBottom: '20px' }}
                      >
                        <ResponsivePieCanvas
                          data={chartDataPie}
                          pixelRatio={1}
                          sortByValue
                          padAngle={0.7}
                          cornerRadius={0}
                          colorBy={(e) => e.color}
                          borderColor="white"
                          innerRadius={0.6}
                          slicesLabelsSkipAngle={20}
                          slicesLabelsTextColor="#fff"
                          animate
                          motionStiffness={90}
                          motionDamping={15}
                          enableRadialLabels={false}
                          theme={{
                            tooltip: { container: { fontSize: '13px' } },
                            labels: { textColor: '#555' },
                          }}
                          legends={[]}
                        />
                      </div>
                      <div className="legends-wrapper">
                        {chartDataPie &&
                          chartDataPie.length > 0 &&
                          chartDataPie.map(({ label, id, color }, index) => {
                            if (index < 16) {
                              return (
                                <div className="label-wrapper" key={index}>
                                  <div
                                    className="label-icon"
                                    style={{ backgroundColor: color }}
                                  />
                                  <p className="label-text">
                                    {label && label.length > 25
                                      ? `${label.substring(0, 12)}...`
                                      : label}
                                  </p>
                                </div>
                              );
                            }
                            return '';
                          })}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

ShareData.contextTypes = {
  clientData: PropTypes.object,
};

export default ShareData;
