import React, { useEffect } from 'react';

import { Switch, Route } from 'react-router-dom';
import RoutesAdminMaster from './RoutesAdminMaster';
import RoutesClient from './RoutesClient';
import { useUserData } from '../../hooks/useUserData';
import * as routes from '../../routes';

function AppRouter(props) {
  const { signed } = useUserData();

  const isClientRoute = window.location.pathname.includes('/client/');
  const isAdminRoute = window.location.pathname.includes('/admin/');

  useEffect(() => {
    const isRootPage = window.location.pathname === '/';
    const isLoginClientPage =
      window.location.pathname.match(/client\/signin\//g);
    const isLoginClientPageSecondary =
      window.location.pathname.match(/client\/signin/g);
    const isLoginAdminPage = window.location.pathname.match(/admin\/signin\//g);
    const isLoginAdminPageSecondary =
      window.location.pathname.match(/admin\/signin/g);

    if (
      (signed && isRootPage) ||
      (signed && isLoginClientPage) ||
      (signed && isLoginClientPageSecondary)
    ) {
      window.location.href = routes.WELCOME_CLIENT;
      return;
    }

    if ((signed && isLoginAdminPage) || (signed && isLoginAdminPageSecondary)) {
      window.location.href = routes.DASHBOARD_ADMIN;
    }
  }, [signed]);

  return (
    <Switch>
      {/* Renderiza rotas do cliente se a URL contém "/client/" */}
      {isClientRoute && <Route path="/client" component={RoutesClient} />}

      {/* Renderiza rotas do administrador master se a URL contém "/admin/" */}
      {isAdminRoute && <Route path="/admin" component={RoutesAdminMaster} />}
    </Switch>
  );
}

export default AppRouter;
