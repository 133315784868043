import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import WithoutPermission from '../../../AdminClient/pages/WithoutPermission';

import { useUserData } from '../../../hooks/useUserData';

function PrivateRoute({ isPermission, history, ...props }) {
  const { signed } = useUserData();

  useEffect(() => {
    if (!signed) return history.push('/admin/signin');
  }, [signed, history]);

  if (isPermission) {
    return <Route {...props} />;
  }
  return <WithoutPermission />;
}

export default withRouter(PrivateRoute);
