import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ResponsiveContainer, FunnelChart, Funnel } from 'recharts';

import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class FunnelChartSale extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loadingFunnelChart, funnelChart } = this.props.dataReport;
    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12">
              {loadingFunnelChart && !funnelChart ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <span className="mb-1 bold">
                    {/* <i className="fa fa-trophy" /> */}
                    <svg
                      className="icon-funil"
                      width="16"
                      height="16"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M23.561 0.75H0.960999L10.261 11.45V23.75L14.261 20.75V11.45L23.561 0.75Z"
                          stroke="#000000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.26099 10.75V23.75"
                          stroke="#000000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.86099 20.25L4.26099 23.75L0.760986 20.25"
                          stroke="#000000"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="24.3" height="24.5" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    &nbsp; Funil de vendas
                  </span>
                  <div className="box-chart">
                    <ResponsiveContainer>
                      <FunnelChart>
                        <Tooltip />
                        <Funnel
                          dataKey="value"
                          data={funnelChart}
                          isAnimationActive
                        ></Funnel>
                      </FunnelChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FunnelChartSale;
