import React from 'react';
import Modal from 'react-responsive-modal';

function ModalConfirmConversionLead(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Você tem certeza que deseja continuar?
        </h2>
        {!props.dataConvertion ? (
          <p>
            Você tem certeza que deseja dar a pontuação de conversão para o{' '}
            {props && props.player && props.player.name} ?
          </p>
        ) : (
          <p>
            Você tem certeza que deseja remover a pontuação de conversão para o{' '}
            {props && props.player && props.player.name} ?
          </p>
        )}
        <p>Essa ação não poderá ser desfeita após a confirmação.</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button
          className="oq-btn"
          disabled={props.converting}
          onClick={props.close}
        >
          Cancelar
        </button>
        <button
          className="oq-btn btn-confirm"
          disabled={props.converting}
          onClick={() => {
            props.addConversion(
              props.playerId,
              props.actionId,
              props.uid,
              props.action,
              props.player
            );
            props.close();
          }}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  );
}

export default ModalConfirmConversionLead;
