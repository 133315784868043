import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class GeoMap extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loadingGeoMap, geoMap, primaryColor } = this.props.dataReport;
    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12">
              {loadingGeoMap && !geoMap ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <div className="box-chart" style={{ height: '420px' }}>
                    {geoMap.length === 0 ? (
                      <>
                        <div>
                          <i
                            className="fa fa-map-marker"
                            aria-hidden="true"
                          ></i>
                          <span>&nbsp; GeoMap</span>
                        </div>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <span>Sem dados</span>
                        </div>
                      </>
                    ) : (
                      <Chart
                        chartType="GeoChart"
                        data={geoMap}
                        height="400px"
                        options={{
                          sizeAxis: { minValue: 3, maxValue: 100 },
                          region: 'BR',
                          resolution: 'provinces',
                          displayMode: 'markers',
                          legend: 'none',
                          colorAxis: {
                            colors: [primaryColor, primaryColor],
                          },
                          enableRegionInteractivity: 'true',
                          geochartVersion: 11,
                          keepAspectRatio: 'true',
                          magnifyingGlass: {
                            enable: true,
                            zoomFactor: 40.0,
                          },
                          markerOpacity: 0.5,
                          regioncoderVersion: 1,
                        }}
                        mapsApiKey="AIzaSyAXMsV5hoXxReVInFOTZubERbuCVZ_OYVE"
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default GeoMap;
