import React, { Component } from 'react';

import { ToastContainer } from 'react-toastify';

import axios from 'axios';
import Select from 'react-select';
import _ from 'underscore';
import { api } from '../../../../../Config';

import { Notify } from '../../../../../components/Notify';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';

import { ImageDrop } from 'quill-image-drop-module';
import { ImageResize } from 'quill-image-resize-module';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import renderHTML from 'react-render-html';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';
import { UserDataContext } from '../../../../../hooks/useUserData';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class NewMessage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      loadingActionList: false,
      actionsList: [],
      stateList: [],
      cityList: [],
      loadingCityList: false,
      loadingStateList: false,
      loadingRegionalList: false,
      regionalList: [],
      savingMessage: false,
      renderCity: false,
    };

    this.translate = props.t;
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleUserInputCKEditor(editor) {
    this.handleFormDataChange('notificationContent', editor.getData());
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }
  getCityList(estado = 31) {
    this.setState({ loadingCityList: true }, () => {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
        )
        .then((city) => {
          return this.setState({ cityList: city.data, loadingCityList: false });
        })
        .catch((error) => {
          this.setState({ loadingCityList: false });
          console.log('getCityList error:', error);
        });
    });
  }

  // getStateList() {
  //   this.setState({ loadingStateList: true }, () => {
  //     axios
  //       .get('https://spotless-unicorn.glitch.me/estados')
  //       .then(states => {
  //         this.setState({ stateList: states.data, loadingStateList: false });
  //       })
  //       .catch(error => {
  //         this.setState({ loadingStateList: false });

  //       });
  //   });
  // }

  getStateList() {
    this.setState({ loadingStateList: true }, () => {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then((states) => {
          const sortedStates = _.sortBy(states.data, 'nome');
          this.setState({ stateList: sortedStates, loadingStateList: false });
        })
        .catch((error) => {
          this.setState({ loadingStateList: false });
          console.log('getStateList error:', error);
        });
    });
  }

  getClientRegionalList() {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            if (clientData.isGamified && clientData.isGamified === 'true') {
              clientData.regional = [
                ...clientData.regional,
                'Influencers',
                'Membros da equipe',
              ];
            }
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  getActionsList() {
    this.setState({ loadingActionList: true }, () => {
      const parameters = {
        searchFunctionality: 'allActions',
        userType: 'client',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          this.setState({
            loadingActionList: false,
            actionsList: result.data.actions,
          });
        })
        .catch((error) => {
          console.log('getActionsList error:', error);
        });
    });
  }

  handleScopeChange(e) {
    const { value } = e.target;
    this.handleUserInput(e);
    switch (value) {
      case 'state':
        this.setState({ renderCity: true });
        this.getStateList();
        break;
      case 'regional':
        this.setState({ renderCity: false });
        this.getClientRegionalList();
        break;
      case 'players':
        this.setState({ renderPlayers: false });
        this.getPlayers();
        break;
      case 'national':
        this.setState({ renderCity: false });
        this.handleFormDataChange('scopeValue', 'BR');
        break;
      default:
        break;
    }
  }

  handleParticipatingChange(e) {
    const { value } = e.target;
    this.handleUserInput(e);
    if (value === 'yes' || value === 'no') {
      this.getActionsList();
    } else {
      this.setState({ actionsList: [] });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.sendMessage();
  }

  sendMessage() {
    const messageData = this.state.formData;

    messageData.notificationType = 'newMessage';
    messageData.notificationTitle = this.translate('Nova mensagem');

    this.setState({ savingMessage: true }, () => {
      const parameters = {
        updateFunctionality: 'sendCustomMessage',
        userType: 'client',
        messageData,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ savingMessage: false });
          Notify(result.message, result.sucess ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ savingMessage: false });
          console.log('getActionsList error:', error);
        });
    });
  }

  renderMessageForField() {
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="participating">
          {this.translate('Participando da Ação?')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="participating"
          onChange={(e) => this.handleParticipatingChange(e)}
          value={this.state.formData.participating}
          required
        >
          <option value="">{this.translate('Selecione...')}</option>
          <option value="yes">{this.translate('yes')}</option>
          <option value="no">{this.translate('no')}</option>
          <option value="all">{this.translate('Todos')}</option>
        </select>
      </div>
    );
  }

  renderScopeField() {
    const { clientData } = this.context;
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          onChange={(e) => this.handleScopeChange(e)}
          value={this.state.formData.scope}
          required
        >
          <option value="">{this.translate('Selecione...')}</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.isActiveRegisterStateAndCity &&
            clientData.isActiveRegisterStateAndCity === 'true' && (
              <option value="state">{this.translate('Estadual')}</option>
            )}
          {this.state.regionalList && this.state.regionalList.length && (
            <option value="regional">
              Segmentada
              {/* {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')} */}
            </option>
          )}
          <option value="players">Players</option>
        </select>
      </div>
    );
  }

  handleUserState(name, e) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        city: '',
      },
    }));
    this.handleFormDataChange(name, e.label);
  }

  componentDidMount = () => {
    this.getClientRegionalList();
  };

  renderScopeForm() {
    const { scope } = this.state.formData;
    const { stateList, regionalList, loadingRegionalList, loadingStateList } =
      this.state;
    let html;
    switch (scope) {
      case 'state':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('state')}
            </label>
            <Select
              name="scopeValue"
              placeholder={this.translate('Selecione um estado...')}
              isDisabled={loadingStateList}
              options={_.map(stateList, (state) => {
                return {
                  value: state.id,
                  label: state.sigla,
                };
              })}
              isLoading={loadingStateList}
              className="basic-multi-select"
              defaultValue={this.state.formData.scopeValue}
              onChange={(e) => {
                this.handleUserState('state', e);
                this.getCityList(e.value);
              }}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'regional':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('Setor')}
            </label>
            <Select
              isMulti
              name="scopeValue"
              placeholder={this.translate('Selecione um setor...')}
              isDisabled={loadingRegionalList}
              options={_.map(regionalList, (regional) => {
                return {
                  value: normalizeLowerCase(regional),
                  label: regional,
                };
              })}
              isLoading={loadingRegionalList}
              className="basic-multi-select"
              defaultValue={this.state.formData.scopeValue}
              onChange={(e) => this.handleFormDataChange('scopeValues', e)}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'national':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('national')}
            </label>
            <input
              disabled
              value="BR"
              className="form-control profile-placeholder"
            />
          </div>
        );
        break;
      default:
        break;
    }

    return html;
  }

  renderScopeFormCity() {
    const { state } = this.state.formData;
    const { cityList, renderCity, loadingStateList } = this.state;
    let html = '';

    if (renderCity === true) {
      html = (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('city')}
          </label>
          <Select
            name="scopeValue"
            placeholder={this.translate('Selecione uma cidade..')}
            isDisabled={loadingStateList}
            options={_.map(cityList, (city) => {
              return {
                value: city.id,
                label: city.nome,
              };
            })}
            isLoading={loadingStateList}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValue}
            onChange={(e) => this.handleUserState('city', e)}
            classNamePrefix="select"
          />
        </div>
      );
    }

    return html;
  }

  renderActions() {
    const { participating } = this.state.formData;
    const { loadingActionList, actionsList } = this.state;

    if (participating === 'yes' || participating === 'no') {
      return (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="action">
            {this.translate('Ação')}
          </label>
          <select
            className="form-control profile-placeholder"
            name="action"
            disabled={loadingActionList}
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.action}
            required
          >
            <option value="">
              {loadingActionList
                ? this.translate('Carregando ações...')
                : this.translate('Selecione uma ação...')}
            </option>
            {actionsList &&
              _.map(actionsList, (action) => {
                return <option value={action.id}>{action.actionTitle}</option>;
              })}
          </select>
        </div>
      );
    }
  }

  renderTextAreaMessage() {
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="scopeValue">
          {this.translate('Notificação')}
        </label>
        <textarea
          className="form-control profile-placeholder"
          rows={5}
          placeholder={this.translate('Sua mensagem')}
          name="notificationContent"
          onChange={(e) => this.handleUserInput(e)}
          value={this.state.formData.notificationContent}
          required
        />
      </div>
    );
  }

  renderTextAreaMessageNew() {
    const { notificationContent } = this.state.formData;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [
        'blockquote',
        // 'code-block'
      ],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      // [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      [
        'link',
        // 'image'
      ],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      // 'code-block',
      'list',
      'bullet',
      'indent',
      // 'color',
      // 'background',
      'align',
      'link',
      // 'image',
    ];

    return (
      <div className="row">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('Notificação')}
          </label>

          <ReactQuill
            ref={(ref) => (this.quillRef = ref)}
            modules={{
              toolbar: {
                container,
              },
            }}
            formats={format}
            theme="snow"
            onChange={(content, delta, source, editor) => {
              this.handleFormDataChange(
                'notificationContent',
                editor.getHTML()
              );
            }}
          />

          {/* <CKEditor
            editor={ClassicEditor}
            onInit={editor =>
              (editor.plugins.get(
                'FileRepository'
              ).createUploadAdapter = loader => {
                return new MyUploadAdapter(loader);
              })
            }
            onChange={(e, editor) => {
              this.handleUserInputCKEditor(e, editor);
            }}
            data={notificationContent}
          /> */}
        </div>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            Prévia do texto
          </label>
          <p>{notificationContent ? renderHTML(notificationContent) : ''}</p>
        </div>
      </div>
    );
  }

  renderSendMessageBtn() {
    return (
      <div className="pull-right col-md-5">
        <button
          type="submit"
          className="btn btn-oq btn-oq-lg btn-block"
          disabled={this.state.savingMessage}
        >
          {this.translate('Enviar notificação')}
        </button>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical col-md-12">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <form onSubmit={(e) => this.handleFormSubmit(e)}>
                <div className="col-md-8">
                  {this.renderBackButton()}
                  <h4 className="oq-h4 col-md-12">
                    <p>Nova Notificação</p>
                  </h4>
                  {this.renderScopeField()}
                  {this.renderScopeForm()}
                  {this.renderScopeFormCity()}
                  {/* this.renderMessageForField() */}
                  {/* this.renderActions() */}
                  {/* {this.renderTextAreaMessage()} */}
                  {this.renderTextAreaMessageNew()}
                  {this.renderSendMessageBtn()}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       <form onSubmit={e => this.handleFormSubmit(e)}>
      //         <div className="col-md-8">
      //           {this.renderBackButton()}
      //           <h4 className="oq-h4 col-md-12">
      //             <p>Nova Notificação</p>
      //           </h4>
      //           {this.renderScopeField()}
      //           {this.renderScopeForm()}
      //           {this.renderScopeFormCity()}
      //           {/* this.renderMessageForField() */}
      //           {/* this.renderActions() */}
      //           {/* {this.renderTextAreaMessage()} */}
      //           {this.renderTextAreaMessageNew()}
      //           {this.renderSendMessageBtn()}
      //         </div>
      //       </form>
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default NewMessage;
