import React from 'react';
import Modal from 'react-responsive-modal';

function ModalSeasonDefine(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">ATENÇÃO </h2>
        <p>Ainda não existem Temporadas criadas!</p>
        <p>Crie sua temporada e começe a jogar!</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button
          className="oq-btn btn-confirm"
          onClick={() => {
            props.redirectUrlSeasonDefine();
            props.close();
          }}
        >
          <b className="oq-font-medium">CRIAR TEMPORADA</b>
        </button>
      </div>
    </Modal>
  );
}

export default ModalSeasonDefine;
