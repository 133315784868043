import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { api } from '../../../../../Config';

import _ from 'lodash';

import { ToastContainer } from 'react-toastify';

import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

class Banners extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: false,
      search: {},
      banners: {},
      countBanners: [],
      searching: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.loadBanners();
  }

  loadBanners() {
    const { clientData } = this.context;
    const clientId = clientData.uid;

    this.setState({ searching: true }, () => {
      const parameters = {
        searchFunctionality: 'loadBanners',
        userType: 'client',
        clientUid: clientId ? clientId : '',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (!result.success) {
            Notify(result.message, result.success ? 'success' : 'error');
            this.setState({
              banners: {},
              countBanners: [],
              searching: false,
            });
            return;
          }

          this.setState({
            banners: result.data.banners,
            countBanners: result.data.countBanners,
            searching: false,
          });

          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ searching: false });
          console.log('searchBannersError error:', error);
        });
    });
  }

  goToEditBanner(banner) {
    const { history } = this.props;

    if (Array.isArray(banner.original.bannerImage)) {
      return (
        <>
          <Link
            to={() => {}}
            className="btn btn-oq-disabled btn-oq btn-sm btn-block"
          >
            {this.translate('edit')}
          </Link>
        </>
      );
    } else {
      return (
        <Link
          to={`${routes.EDIT_BANNER}/${banner.original.id}`}
          className="btn btn-oq btn-sm btn-block"
        >
          {this.translate('edit')}
        </Link>
      );
    }
  }

  epochDateToHuman(cell, row) {
    return moment(cell).format('DD/MM/YYYY HH:mm').toString().toUpperCase();
  }

  editClient(cell, row) {
    return (
      <div>
        <i
          className="fas fa-pencil-alt"
          aria-hidden="true"
          onClick={() => this.goToEditBanner(cell)}
        />
      </div>
    );
  }

  renderLogoImage(cell, row) {
    if (row.bannerImage) {
      return (
        <div>
          <img
            className="img-responsive img-circle"
            src={row.bannerImage}
            width="50%"
            alt="client banner"
          />
        </div>
      );
    }
  }

  renderBannersTable() {
    const { searching } = this.state;
    const data = _.values(this.state.banners);

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="image">
            {this.translate('image')}
            <ReactTooltip
              id="image"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('image')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'bannerImage',
        width: 120,
        Cell: (props) => {
          var images = props.value;
          if (typeof images === 'object') {
            _.map(images, (image, index) => {
              images = image.url;
            });
          }

          return (
            <img
              src={images}
              className="img-responsive img-rounded"
              style={{ width: '100%', height: 'auto' }}
              alt="client banner"
            />
          );
        },
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'bannerName',
      },
      {
        id: 'bannerType',
        Header: (
          <span data-tip="React-tooltip" data-for="type">
            {this.translate('banner-type')}
            <ReactTooltip
              id="type"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('type')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.isPrimary ? 'Primário' : 'Secundário'),
      },
      {
        id: 'bannerStatus',
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {this.translate('status')}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.active ? 'Ativo' : 'Inativo'),
      },
      {
        id: 'viewSeason',
        Header: '',
        accessor: 'id',
        Cell: (props) => (
          <>
            <span className="text-center clickable">
              <Link
                to={`${routes.EDIT_BANNER}/${props.original.id}`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('edit')}
              </Link>
            </span>
          </>
        ),
        // Cell: props => (
        //   <>
        //     <span className="text-center clickable">
        //       {this.goToEditBanner(props)}
        //     </span>
        //   </>
        // ),
      },
      {
        id: 'detailsBanner',
        Header: '',
        accessor: 'id',
        Cell: (props) => (
          <>
            <span className="text-center clickable">
              <Link
                to={`${routes.DETAILS_BANNER}/${props.original.id}`}
                className="btn btn-oq btn-sm btn-block"
              >
                {this.translate('Visualizar')}
              </Link>
            </span>
          </>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card no-margin">
          <span className="oq-card-title-action">
            <i className="fa fa-flag" />
            &nbsp; {this.translate('banners-list')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              loading={searching}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              noDataText={this.translate('no-banners')}
              pageText={this.translate('pages')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  renderNewBannerButton() {
    return (
      <div className="col-md-12 oq-padding-vertical">
        <div className="col-md-3 p-0">
          <Link
            to={routes.NEW_BANNER}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp; Novo Banner
          </Link>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderNewBannerButton()}
              {this.renderBannersTable()}
            </div>
          </div>
        </div>
      </div>
      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderNewBannerButton()}
      //       {this.renderBannersTable()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Banners;

export { Banners };
