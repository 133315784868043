import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

import CheckRequiredInputs from '../../AdminClient/components/CheckRequiredInputs';
import i18n from '../../i18n.js';
import AppRouter from './AppRouter';

import { UserDataProvider } from '../../hooks/useUserData';

function App(props) {
  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <UserDataProvider>
          <div className="app">
            <ToastContainer />
            <AppRouter />
            <CheckRequiredInputs />
          </div>
        </UserDataProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
