import React, { Component } from 'react';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
// import { BitlyClient } from 'bitly';

import axios from 'axios';
import { api } from '../../../../../Config';

import moment from 'moment';
import Select from 'react-select';
import _ from 'underscore';

import Modal from 'react-responsive-modal';
import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';
// const bitly = new BitlyClient('o_5q6b7qrk66', {});

class DetailsActionVirtual extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      players: [],
      isLoadingPlayers: false,
      readyPdf: {},
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      selectedPlayers: [],
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      playersInAction: {},
      shareURL: null,
      progress: 0,
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      isOpenModalExtraFieldsList: false,
      regionalIsEmpty: false,
      formData: {
        redirectLandingPage: '',
        extraFieldsInputs: [],
        btnRedirectLinkForPlayer: '',
        btnRedirectTextForPlayer: '',
        redirectForPlayer: false,
        redirectWhatsapp: 'false',
        imageSocialMedia: {},
        publicationSocialMedia: 'false',
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        pitchVerb: '',
        membergetmemberMax: '0',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        // qrCodeButtonsVisible: false,
        showPoster: false,
        // startDateVisible: true,
        description: '',
        externalId: '',
        marcaId: '',
        rulesOfAction: '',
        qrCodeShareURL: false,
        landingPagePreviewPoint: 0,
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        redirectDashCallToAction: '/',
        conversionFieldName: '',
        extraFieldName1: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
        socialMediaUncompleted: false,
        phase3Uncompleted: false,
        active: true,
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();

    const {
      match: { params },
    } = this.props;

    this.getInitialState();
    this.setState({ actionId: params.actionId });

    await this.getActionById(params.actionId);
    // await this.getPlayers(params.actionId);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  generateShareURL = async (actionId, playerId, index) => {
    // const { actionId } = this.state;
    const now = moment().valueOf();
    let hostURL = '';
    if (process.env.NODE_ENV == 'development') {
      hostURL = 'https://us-central1-oqhomapi.cloudfunctions.net';
    } else {
      hostURL = 'https://us-central1-oq20-139d9.cloudfunctions.net';
    }
    // Time to try and solve the image share in WhatsApp

    const shareURL = `${hostURL}/actionDetails/${playerId}/${actionId}/${now}`;

    this.setState((prevState) => ({
      shareURL: {
        ...prevState.shareURL,
        [index]: { shareURL, shareURLEncoded: encodeURIComponent(shareURL) },
      },
    }));
  };

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  async getActionById(actionId) {
    const parameters = {
      searchFunctionality: 'getAction',
      userType: 'client',
      actionId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const formData = res.data.data.action;

        this.getCityList(formData.selectedStates);

        if (formData.scope === 'regional') {
          this.getClientRegionalList();
        }

        this.setState({
          isLoading: false,
          formData,
        });
      })
      .catch((error) => {
        console.log('getActionById error:', error);
      });
  }

  async getPlayers(actionId) {
    this.setState({ isLoadingPlayers: true });

    const parameters = {
      searchFunctionality: 'getPlayersInAction',
      userType: 'client',
      actionId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;

        this.setState({ isLoadingPlayers: false });
        if (result.data && _.size(res.data.data) > 0) {
          this.setState({ players: result.data });
        } else {
          this.setState({ isLoadingPlayers: false });
        }
        // this.setState({
        //   players: res.data.data,
        // });
        // if (result && result.data && result.data.length > 0) {
        //   result.data.map(async (player, index) => {
        //     await this.generateShareURL(actionId, player.uid, index);
        //   });
        // }
      })
      .catch((error) => {
        this.setState({ isLoadingPlayers: false });
        console.log('getPlayersInAction error:', error);
      });
  }

  getClientRegionalList() {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }

  renderStateSelection() {
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedStates}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderCitySelection() {
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderSelectActionType() {
    const { formData } = this.state;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="actionType">
          {this.translate('Tipo de Publicação')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="actionType"
          disabled
          value={formData.actionType}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="normal">Normal</option>
          <option value="combo">Combo</option>
          <option value="recurrent">Recorrente</option>
        </select>
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={(option) => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            value={this.state.formData.scopeValues}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  renderPhase1() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Copywriting
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da copy')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                readOnly
                disabled
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('title')}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Responder pesquisa')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                readOnly
                disabled
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                readOnly
                disabled
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                {this.translate('Mensagem de engajamento')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 140 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action2"
                />
                <ReactTooltip
                  id="call-action2"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Texto que o usuário cadastrado na Plataforma vê antes para
                    se engajar na publicação
                  </span>
                </ReactTooltip>
              </label>
              <input
                readOnly
                disabled
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                value={formData.actionSubtitle}
              />
            </div>

            {/* {this.context.clientData.clientUrl === 'emr' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="urlRedirectAction">
                  {this.translate('Url base para página de Checkout')}{' '}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="urlRedirectAction"
                  />
                  <ReactTooltip
                    id="urlRedirectAction"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Link de redirecionamento para a página de checkout do Eu
                      médico residente
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  name="urlRedirectAction"
                  placeholder="https://exemplo.url-redirecionamento"
                  value={formData.urlRedirectAction}
                />
              </div>
            )} */}

            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  required
                  disabled
                >
                  <option value="regional" selected>
                    {formData.season
                      ? formData.season.seasonName
                      : 'Não definido'}
                  </option>
                </select>
              </div>
            )}
            {clientData && clientData.clientUrl === 'emr' && (
              <div className="row" style={{ marginLeft: '4px' }}>
                {this.renderSelectActionType()}
              </div>
            )}
            {/* <div className="row" style={{ marginLeft: '4px' }}>
              {this.renderSelectActionType()}
            </div> */}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' &&
              clientData.segmentationChoice === 'true' &&
              this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.renderStateSelection()}
                  {this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.images
              ? formData.images.map((image, i) => {
                  return (
                    <div key={image.url} className="flex">
                      {image && (
                        <>
                          <div className="delete-banner" key={i} />
                          <img
                            className="img-responsive img-thumbnail oq-margin-bottom"
                            src={image.url}
                          />
                        </>
                      )}
                      {/* {this.renderImagePreview(image.url)} */}
                    </div>
                  );
                })
              : formData.files &&
                formData.files.image1 &&
                this.renderImagePreview(formData.files.image1)}
            <input
              readOnly
              disabled
              type="file"
              accept="image/*"
              id="profilePic"
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  // disabled={_.isEmpty(formData.season)}
                  // required
                  readOnly
                  disabled
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  value={formData.startDate}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  // disabled={_.isEmpty(formData.season)}
                  // required
                  readOnly
                  disabled
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  value={formData.startDateTime}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  // disabled={_.isEmpty(formData.season)}
                  // required
                  readOnly
                  disabled
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  value={formData.endDate}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  // disabled={_.isEmpty(formData.season)}
                  // required
                  readOnly
                  disabled
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('status')} <code>*</code>
                </label>
                <select
                  disabled
                  className="form-control profile-placeholder"
                  name="active"
                  value={formData.active}
                >
                  <option value>{this.translate('active')}</option>
                  <option value={false}>{this.translate('inactive')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    const { formData } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Detalhes da publicação
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          {this.context.clientData &&
            this.context.clientData.clientUrl === 'grupodirecional' && (
              <div
                className="form-group points-by-share col-md-12"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <label className="profile-label" htmlFor="actionClientUrl">
                  Definir cliente para a publicação&nbsp; <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="actionClientUrl"
                  />
                  <ReactTooltip
                    id="actionClientUrl"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Defina para qual cliente irá aparecer a publicação.
                    </span>
                  </ReactTooltip>
                </label>
                <div className="form-group col-md-4">
                  <select
                    disabled
                    className="form-control profile-placeholder"
                    name="actionClientUrl"
                    value={
                      formData.actionClientUrl ? formData.actionClientUrl : ''
                    }
                  >
                    <option value="">Selecione um cliente</option>
                    <option value="riva">Riva</option>
                    <option value="direcional">Direcional</option>
                  </select>
                </div>
              </div>
            )}
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="defaultImagePosterInfo">
              Definir imagem padrão para publicação
              <i
                className="fa fa-info-circle ms-3"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="defaultImagePosterInfo"
              />
            </label>
            <ReactTooltip
              id="defaultImagePosterInfo"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                imagem que irá aparecer por padrão para publicação. Esta
                configuração define também a geração dos cartazes de impressão e
                compartilhamento.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <select
                required
                className="form-control profile-placeholder"
                name="defaultImagePoster"
                disabled
                value={
                  formData.defaultImagePoster ? formData.defaultImagePoster : ''
                }
              >
                <option disabled selected value="">
                  Selecione uma imagem
                </option>
                {formData &&
                  formData.images &&
                  formData.images.map((index) => (
                    <option
                      value={index}
                      key={index}
                    >{`Imagem ${index + 1}`}</option>
                  ))}
              </select>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="countDownVisible">
              {this.translate(
                'Exibir cronômetro regressivo informando o tempo para o término da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="countDownVisible"
              />
            </label>
            <ReactTooltip
              id="countDownVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('countDownVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="countDownVisible"
                    checked={this.state.formData.countDownVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="countDownVisible"
                    checked={this.state.formData.countDownVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="isAutoIndicateEnabled">
              Habilitar indicação direta?
            </label>
            <br />
            <p className="tiny-text">
              Na Indicação Direta o prospector/usuário pode fazer a indicação
              preenchendo ele <br />
              mesmo os dados do lead, sem necessidade de compartilhar o link com
              o indicado
            </p>
            <br />
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    disabled
                    value
                    name="isAutoIndicateEnabled"
                    checked={
                      this.state.formData &&
                      this.state.formData.isAutoIndicateEnabled &&
                      this.state.formData.isAutoIndicateEnabled !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    disabled
                    value={false}
                    name="isAutoIndicateEnabled"
                    checked={
                      this.state.formData &&
                      this.state.formData.isAutoIndicateEnabled &&
                      this.state.formData.isAutoIndicateEnabled === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="shareButtonsVisible">
              {this.translate(
                'Exibir botões de compartilhamento com terceiros?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="shareButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="shareButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('shareButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="shareButtonsVisible"
                    checked={this.state.formData.shareButtonsVisible !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="shareButtonsVisible"
                    checked={this.state.formData.shareButtonsVisible === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="qrCodeButtonsVisible">
              Exibir QRCode para usuários e terceiros? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="qrCodeButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="qrCodeButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Você pode anexar à publicação um QR Code de descontos, vouchers
                <br />
                ou vale-presentes para os usuários e também para eles
                <br />
                compartilharem com os seus contatos. A cada validação, o usuário
                <br />
                que usou ou compartilhou o código pode ganhar pontos a seu
                <br />
                critério. Obs: para validar, há necessidade de integração com
                <br />
                seu e-commerce, site ou sistema de gestão de vendas.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="qrCodeButtonsVisible"
                    checked={this.state.formData.qrCodeButtonsVisible !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="qrCodeButtonsVisible"
                    checked={this.state.formData.qrCodeButtonsVisible === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}
          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="qrCodeShareURL">
              Exibir QR Code da publicação para o usuário? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="qrCodeShareURL"
              />
            </label>
            <ReactTooltip
              id="qrCodeShareURL"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                Com este recursos, o usuário pode imprimir ou exibir o QR Code
                na tela do celular para captar novos usuários/leads sem a
                necessidade de compartilhamentos nas redes sociais ou
                aplicativos de mensagens.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="qrCodeShareURL"
                    checked={this.state.formData.qrCodeShareURL !== false}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="qrCodeShareURL"
                    checked={this.state.formData.qrCodeShareURL === false}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="showPoster">
              Exibir poster da publicação para o usuário? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="showPoster"
              />
            </label>
            <ReactTooltip id="showPoster" type="dark" effect="solid" multiline>
              <span>
                Com este recursos, o usuário pode imprimir ou exibir o poster{' '}
                <br />
                para captar novos usuários/leads sem a necessidade de
                compartilhamentos <br />
                nas redes sociais ou aplicativos de mensagens.
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="showPoster"
                    checked={this.state.formData.showPoster}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="showPoster"
                    checked={!this.state.formData.showPoster}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="startDateVisible">
              {this.translate(
                'Data de início visível para o usuário no card da publicação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="startDateVisible"
              />
            </label>
            <ReactTooltip
              id="startDateVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('startDateVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="startDateVisible"
                    checked={this.state.formData.startDateVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="startDateVisible"
                    checked={this.state.formData.startDateVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}
          {/* <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="publicationSocialMedia">
              {this.translate(
                'Deseja ativar o compartilhamento por Instagram/TikTok?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="publicationSocialMedia"
              />
            </label>
            <ReactTooltip
              id="publicationSocialMedia"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Publicação que permite fazer upload de imagem para ser compatilhada no formato das mídias sociais.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="publicationSocialMedia"
                    checked={
                      this.state.formData.publicationSocialMedia !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="publicationSocialMedia"
                    checked={
                      this.state.formData.publicationSocialMedia === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div> */}
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="redirectWhatsapp">
              {this.translate(
                'Habilitar o contato direto do lead com o vendedor?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="call-action"
              />
            </label>
            <ReactTooltip id="call-action" type="dark" effect="solid" multiline>
              <span>
                {this.translate(
                  'Ao habilitar esta função, o lead terá a opção de falar, via WhatsApp, direto com o vendedor que compartilhou o conteúdo, agilizando o primeiro contato.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    disabled
                    readOnly
                    name="redirectWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="redirectWhatsapp"
                    disabled
                    readOnly
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="redirectForPlayer">
              {this.translate(
                'Habilitar botão de redirecionamento dentro da publicação para o player?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="redirectForPlayer"
              />
            </label>
            <ReactTooltip
              id="redirectForPlayer"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Ao habilitar essa função, o player terá um botão de redirecionamento dentro da publicação'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    disabled
                    readOnly
                    name="redirectForPlayer"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectForPlayer
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    disabled
                    readOnly
                    name="redirectForPlayer"
                    onChange={(e) => this.onRadioChange(e)}
                    checked={
                      this.state.formData &&
                      !this.state.formData.redirectForPlayer
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          {this.state.formData && this.state.formData.redirectForPlayer && (
            <div>
              <div className="form-group col-md-12">
                <label
                  className="profile-label col-md-6"
                  htmlFor="btnRedirectTextForPlayer"
                >
                  {this.translate('Texto do botão de redirecionamento')}{' '}
                  <code>*</code> &nbsp;
                  <span style={{ fontSize: '10px', display: 'contents' }}>
                    (Até 38 caracteres)
                  </span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="btnRedirectTextForPlayer"
                  />
                  <ReactTooltip
                    id="btnRedirectTextForPlayer"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>
                      {this.translate(
                        'Esse será o nome do seu botão de redirecionamento'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <div className="col-md-6">
                  <input
                    required
                    type="text"
                    disabled
                    readOnly
                    className="form-control profile-placeholder "
                    name="btnRedirectTextForPlayer"
                    onChange={(e) => this.handleUserInput(e)}
                    value={this.state.formData.btnRedirectTextForPlayer}
                    placeholder="Redirecionar"
                  />
                </div>
              </div>
              {/* <div className="form-group col-md-12">
                <label
                  className="profile-label col-md-6"
                  htmlFor="btnRedirectLinkForPlayer"
                >
                  {this.translate('Link de redirecionamento')}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="btnRedirectLinkForPlayer"
                  />
                  <ReactTooltip
                    id="btnRedirectLinkForPlayer"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Link que será vinculado ao botão configurado anteriormente. Ao'
                      )}
                      <br />
                      {this.translate(
                        'clicar o player será redirecionado para o link informado nesse'
                      )}
                      <br />
                      {this.translate('campo.')}
                      <br />
                    </span>
                  </ReactTooltip>
                </label>
                <div className="col-md-6">
                  <input
                    required
                    type="text"
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="btnRedirectLinkForPlayer"
                    onChange={e => this.handleUserInput(e)}
                    value={this.state.formData.btnRedirectLinkForPlayer}
                    placeholder="Link de redirecionamento"
                  />
                </div>
              </div> */}
            </div>
          )}

          {this.context &&
            this.context.clientData &&
            this.context.clientData.showCTAIndication &&
            this.state &&
            this.state.redirectLandingPage && (
              <div className="form-group col-md-12">
                <label
                  style={{ marginRight: 5 }}
                  className="profile-label"
                  htmlFor="redirectLandingPage"
                >
                  Link de redirecionamento para indicados.
                </label>

                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="redirectLandingPage"
                />
                <ReactTooltip
                  id="redirectLandingPage"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>Link de redirecionamento para o indicado</span>
                </ReactTooltip>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  placeholder="Link de redirecionamento"
                  name="redirectLandingPage"
                  value={formData.redirectLandingPage}
                />
              </div>
            )}

          {/* <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              <label className="profile-label" htmlFor="description">
                Frase de engajamento (para o usuário) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="pitchVerbPlayer"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="pitchVerbPlayer"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Coloque aqui uma frase para levar o usuário a mais
                    informações da publicação. Ex.: “Por que indicar?” “Veja os
                    detalhes!” “Mais informações”. Etc.
                  </span>
                </ReactTooltip>
              </label>

              <input
                disabled
                readOnly
                className="input-pitch"
                placeholder="Frase"
                name="pitchVerbPlayer"
                value={formData.pitchVerbPlayer}
                style={{ marginLeft: 10 }}
              />
            </div>
          </div> */}
          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              <label className="profile-label" htmlFor="pitch">
                Por que... (para o público-alvo) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  Coloque aqui um verbo para complementar. Ex.: Por que...
                  comprar?; Por que... contratar?; Por que... se associar?
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="input-pitch"
                placeholder="Verbo"
                name="pitchVerb"
                value={formData.pitchVerb}
                style={{ marginLeft: 10 }}
              />
            </div>
            <textarea
              disabled
              readOnly
              className="form-control profile-placeholder"
              // placeholder={this.translate('Descrição')}
              placeholder={this.translate('Texto com argumento de venda')}
              rows="5"
              name="description"
              value={formData.description}
            />
          </div>
          {formData.shareButtonsVisible === true && (
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="rulesOfAction">
                {this.translate('Regras da publicação')} <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="RulesOfAction"
                />
              </label>
              <ReactTooltip
                id="RulesOfAction"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('rules-of-action-text1')} &nbsp;</span>
              </ReactTooltip>
              <textarea
                disabled
                readOnly
                className="form-control profile-placeholder"
                placeholder={this.translate('Regras da publicação')}
                rows="5"
                name="rulesOfAction"
                value={formData.rulesOfAction}
              />
            </div>
          )}
          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="dataSheet">
                {this.translate('Tipo da Ficha Técnica')} &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="dataSheet"
                />
                <ReactTooltip
                  id="dataSheet"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('dataSheet-text1')} &nbsp;</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="conversionFieldName"
                placeholder="Ex: Regulamento"
                value={formData.conversionFieldName}
              />
            </div>
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="dataSheet">
              {formData.conversionFieldName
                ? formData.conversionFieldName
                : this.translate('Ficha Técnica')}{' '}
              <code>*</code>&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="technicalFix"
              />
              <ReactTooltip
                id="technicalFix"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('technical-fix-text1')} &nbsp;</span>
              </ReactTooltip>
            </label>
          </div>
          <div className="form-group col-md-12">
            <label
              style={{ marginRight: 5 }}
              className="profile-label"
              htmlFor="externalId"
            >
              ID Externa
            </label>

            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="externalID"
            />
            <ReactTooltip
              id="externalID"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>Id usado para integrações customizadas</span>
            </ReactTooltip>
            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              name="externalId"
              placeholder="ID de identificação externa"
              value={formData.externalId}
            />
          </div>
          {this.context.clientData.clientUrl === 'umuarama' && (
            <>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="marcaId">
                  Categoria
                </label>
                <select
                  disabled
                  className="form-control profile-placeholder"
                  name="marcaId"
                  placeholder="ID de identificação externa"
                  value={formData.marcaId}
                >
                  <option value="">Selecione a marca</option>
                  <option value="b54e8890e455df3d7eddff24291009bc">
                    Motos
                  </option>
                  <option value="629fc8a198550ddb8b6f0c0c0a0c91f6">
                    Seminovos
                  </option>
                  <option value="7c6e89ae3632f07022026a1e9eab5ac0">
                    Novos
                  </option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="marca">
                  Marca
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="marca"
                  placeholder="Marca"
                  value={formData.marca}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="modelo">
                  Modelo
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="modelo"
                  placeholder="Modelo"
                  value={formData.modelo}
                />
              </div>
            </>
          )}
        </div>

        <hr />
      </div>
    );
  }

  renderSocialMedia() {
    const { formData, socialMediaUncompleted, formButtonPressed } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#socialMedia"
          aria-expanded="false"
        >
          Publicação Instagram/TikTok
          {socialMediaUncompleted && this.renderUncompletedText()}
        </h4>
        <div id="socialMedia" aria-expanded="false" className="collapse">
          <div className="col-md-12 form-group">
            <div className="form-group col-md-5 imgpv">
              <label className="profile-label" htmlFor="imageSocialMedia">
                {this.translate('image')} <code>*</code>
                ( 1080x1920)&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="imageSocialMedia"
                />
                <ReactTooltip
                  id="imageSocialMedia"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span className="span-oq-create-action">
                    Imagem em modo retrato.
                  </span>
                </ReactTooltip>
                {this.state.isUploading && <span>{this.state.progress}%</span>}
              </label>
              <div className="flex">
                {formData.imageSocialMedia &&
                  formData.imageSocialMedia.image && (
                    <>
                      <div className="delete-banner">
                        <IconDelete
                          className="clickable"
                          onClick={() => {
                            this.setState((oldState) => ({
                              formData: {
                                ...oldState.formData,
                                fileIndexSocialMedia: '',
                                imageSocialMedia: {
                                  image: '',
                                },
                              },
                              isUploading: false,
                            }));
                          }}
                        />
                      </div>
                      <img
                        className="img-responsive img-thumbnail oq-margin-bottom"
                        src={formData.imageSocialMedia.image}
                      />
                    </>
                  )}
              </div>
              <input type="file" accept="image/*" id="profilePic" />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase3() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Regras de Pontuação
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-12 row">
            {clientData.gamificationType === 'goalsLevels'
              ? this.renderGoalLevelsFields()
              : this.renderActionPointsFields(formData)}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGoalLevelsFields() {
    return (
      <div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectText">
            {this.translate('Call to Action1')} <code>*</code> &nbsp;
            <span style={{ fontSize: '10px', display: 'contents' }}>
              (Até 38 caracteres)
            </span>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="callToAction"
            />
            <ReactTooltip
              id="callToAction"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('call-to-action2-text1')}</span>
            </ReactTooltip>
          </label>
          <input
            disabled
            readOnly
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectText"
            value={this.state.formData.btnDirectText}
            placeholder="Call to action 1"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectRedirectLink">
            {this.translate('Call to action 1 (Link de redirecionamento)')}
            <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="btnDirectRedirectLink"
            />
            <ReactTooltip
              id="btnDirectRedirectLink"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Link que será vinculado ao botão configurado anteriormente. Ao'
                )}
                <br />
                {this.translate(
                  'clicar o lead será redirecionado para o link informado nesse'
                )}
                <br />
                {this.translate('campo.')}
                <br />
              </span>
            </ReactTooltip>
          </label>
          <input
            disabled
            readOnly
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectRedirectLink"
            value={this.state.formData.btnDirectRedirectLink}
            placeholder="Link de redirecionamento"
          />
        </div>
      </div>
    );
  }

  generatePdf(index) {
    this.setState(
      (prevState) => ({
        readyPdf: {
          ...prevState.readyPdf,
          [index]: {
            readyPDF: false,
          },
        },
      }),
      () => {
        // THIS IS THE HACK
        setTimeout(() => {
          this.setState((prevState) => ({
            readyPdf: {
              ...prevState.readyPdf,
              [index]: {
                readyPDF: true,
              },
            },
          }));
        }, 1);
      }
    );
  }

  handleChangePrint(e, props, index, qrCode) {
    const { checked, value } = e.target;
    const { name, cpf } = props;
    if (checked === false) {
      this.setState((prevState) => ({
        ...prevState,
        selectedPlayers: _.omit(this.state.selectedPlayers, index),
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        selectedPlayers: {
          ...prevState.selectedPlayers,
          [index]: {
            name,
            cpf,
            checked,
            qrCode,
          },
        },
      }));
    }
  }

  renderActionsTable() {
    // await this.filterList();
    const data = _.values(this.state.players);
    let columns = [];
    columns = [
      // this.state.formData.qrCodeShareURL &&
      // this.state.formData.qrCodeShareURL === true
      //   ? {
      //       Header: (
      //         <span
      //           data-tip="React-tooltip"
      //           data-for="converted"
      //           className="center-block text-center"
      //         >
      //           Imprimir
      //           <ReactTooltip
      //             id="converted"
      //             type="dark"
      //             effect="solid"
      //             multiline
      //             className="tool-tip"
      //           >
      //             <span>{this.translate('OrderBy')}</span>
      //           </ReactTooltip>
      //         </span>
      //       ),
      //       accessor: 'converted',
      //       filterable: false,
      //       width: 100,
      //       Cell: props => {
      //         const qrCodeCanvas = document.getElementById(
      //           `canvas-qrcode-${props.row._index}`
      //         );
      //         const qrCodeDataUri =
      //           qrCodeCanvas && qrCodeCanvas.toDataURL('image/jpg', 0.3);
      //         return (
      //           <div className="center-block">
      //             <input
      //               type="checkbox"
      //               className="oq-checkbox"
      //               onChange={e =>
      //                 this.handleChangePrint(
      //                   e,
      //                   props.original,
      //                   props.row._index,
      //                   qrCodeDataUri
      //                 )
      //               }
      //               checked={
      //                 this.state.selectedPlayers[props.row._index] &&
      //                 this.state.selectedPlayers[props.row._index].checked
      //               }
      //             />
      //           </div>
      //         );
      //       },
      //     }
      //   : { Header: '', filterable: false },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="email">
            {this.translate('email')}
            <ReactTooltip
              id="email"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('email')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'email',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="CPF">
            {this.translate('CPF')}
            <ReactTooltip
              id="CPF"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('CPF')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'cpf',
        className: 'center-cell',
      },

      // this.state.formData.qrCodeShareURL &&
      // this.state.formData.qrCodeShareURL === true
      //   ? {
      //       Header: '',
      //       accessor: 'uid',
      //       filterable: false,
      //       width: 210,
      //       Cell: props => {
      //         const qrCodeCanvas = document.getElementById(
      //           `canvas-qrcode-${props.row._index}`
      //         );
      //         const qrCodeDataUri =
      //           qrCodeCanvas && qrCodeCanvas.toDataURL('image/jpg', 0.3);

      //         // this.setState({
      //         //   dataImage: {
      //         //     [props.row._index]: qrCodeDataUri
      //         //   }
      //         // })
      //         return (
      //           <>
      //             {this.state.shareURL &&
      //               this.state.shareURL[props.row._index] &&
      //               this.state.shareURL[props.row._index].shareURL && (
      //                 <QRCode
      //                   style={{ display: 'none' }}
      //                   id={`canvas-qrcode-${props.row._index}`}
      //                   value={this.state.shareURL[props.row._index].shareURL}
      //                   size={600}
      //                   className="text-center qrcode"
      //                 />
      //               )}
      //             {this.state.readyPdf &&
      //               this.state.readyPdf[props.row._index] &&
      //               this.state.readyPdf[props.row._index].readyPDF && (
      //                 <PDFDownloadLink
      //                   document={
      //                     <DownloadQrcodeSingle
      //                       qrCode={qrCodeDataUri && qrCodeDataUri}
      //                       name={props.original.name}
      //                       cpf={props.original.cpf}
      //                     />
      //                   }
      //                   fileName="qrCode.pdf"
      //                 >
      //                   {({ blob, url, loading, error, index }) =>
      //                     loading[props.row._index] ? (
      //                       <button className="btn btn-oq btn-download-pdf">
      //                         Carregando...
      //                       </button>
      //                     ) : (
      //                       <button
      //                         className="btn btn-oq btn-download-pdf"
      //                         onClick={() =>
      //                           this.setState(prevState => ({
      //                             readyPdf: {
      //                               ...prevState.readyPdf,
      //                               [props.row._index]: {
      //                                 readyPDF: true,
      //                               },
      //                             },
      //                           }))
      //                         }
      //                       >
      //                         Clique para baixar
      //                       </button>
      //                     )
      //                   }
      //                 </PDFDownloadLink>
      //               )}
      //             {
      //               <button
      //                 className="btn btn-oq btn-download-pdf"
      //                 onClick={() => this.generatePdf(props.row._index)}
      //               >
      //                 Gerar QR Code
      //               </button>
      //             }
      //           </>
      //         );
      //       },
      //     }
      //   : { Header: '', filterable: false },
    ];

    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de players')}
          </span>

          {/* {this.state.formData.qrCodeShareURL &&
          this.state.formData.qrCodeShareURL === true &&
          this.state.selectedPlayers &&
          Object.keys(this.state.selectedPlayers).length > 0 ? (
            <PDFDownloadLink
              document={
                <DownloadQrcodeMultiple props={this.state.selectedPlayers} />
              }
              fileName="qrCode.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <button className="btn btn-oq pull-right">
                    Carregando...
                  </button>
                ) : (
                  <button className="btn btn-oq pull-right">
                    Clique para baixar
                  </button>
                )
              }
            </PDFDownloadLink>
          ) : (
            this.state.formData.qrCodeShareURL &&
            this.state.formData.qrCodeShareURL === true && (
              <button className="btn btn-oq pull-right" disabled>
                <i className="fa fa-file-excel-o" aria-hidden="true" />
                Baixar QR Codes
              </button>
            )
          )} */}
          <button
            style={{ marginRight: '10px' }}
            className="btn btn-oq pull-right"
            onClick={() => this.getPlayers(this.state.formData.id)}
            disabled={this.state.isLoadingPlayers}
          >
            <i className="fa fa-search" aria-hidden="true" />
            Buscar Players
          </button>
          {/* {this.state.players.length > 0 && (
            <>
              {this.state.formData.qrCodeShareURL &&
                this.state.formData.qrCodeShareURL === true && (
                  <>
                    <div className="oq-line" />
                    <div className="background px-2 py-2 flex">
                      <div className="flex" style={{ width: '100%' }}>
                        <span className="oq-color-white">
                          <strong className="oq-color-white">
                            {' '}
                            IMPORTANTE:
                          </strong>{' '}
                          O QR Code só estará disponível para o usuário que
                          estiver participando da publicação. <br />
                          Para isso, basta ele logar na Plataforma e
                          compartilhar a publicação pelo menos uma vez.
                        </span>
                      </div>
                      <div className="flex" style={{ margin: 'auto' }}>
                        <img src={qrCodeImage} alt="" width={50} height={50} />
                      </div>
                    </div>
                  </>
                )}
            </>
          )} */}
          {this.state.players.length > 0 && (
            <>
              <div className="oq-line" />
              <div style={{ paddingTop: 10 }}>
                <ReactTable
                  data={data}
                  defaultPageSize={5}
                  columns={columns && columns}
                  previousText={this.translate('prev')}
                  nextText={this.translate('next')}
                  filterable
                  /* nextText={this.translate('loading')} */
                  noDataText={this.translate('Nenhum player para ser exibido')}
                  pageText={this.translate('page')}
                  ofText={this.translate('of')}
                  rowsText={this.translate('lines')}
                  defaultFilterMethod={(filter, row) => {
                    const filterText = String(filter.value).toLowerCase();
                    const text = String(row[filter.id]).toLowerCase();
                    return text.indexOf(filterText) > -1;
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  renderMemberGetMemberFields(formData) {
    return (
      <div>
        <div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmember">
              {this.translate('Pontuação para o player anfitrião')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmember"
              />
              <ReactTooltip
                id="membergetmember"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Pontuação que o player ganhará caso outro player convidado se cadastre a partir dessa publicação.'
                  )}
                </span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="number"
              className="form-control profile-placeholder"
              name="membergetmember"
              value={formData.membergetmember}
              placeholder={this.translate('Member Get Member Anfitrião')}
            />
          </div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberguest">
              {this.translate('Pontuação para o player convidado')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberguest"
              />
              <ReactTooltip
                id="membergetmemberguest"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Pontuação que o player convidado ganhará ao se cadastrar na plataforma através do link da publicação.'
                  )}
                </span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="number"
              className="form-control profile-placeholder"
              name="membergetmemberguest"
              value={formData.membergetmemberguest}
              placeholder={this.translate('Member Get Member Convidado')}
            />
          </div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberMax">
              Número máximo de pessoas que poderão se cadastrar <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberMax"
              />
              <ReactTooltip
                id="membergetmemberMax"
                type="dark"
                effect="solid"
                multiline
              >
                <span>Número máximo de pessoas que poderão se cadastrar.</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="number"
              className="form-control profile-placeholder"
              name="membergetmemberMax"
              value={formData.membergetmemberMax}
              placeholder="Número máximo de pessoas que poderão se cadastrar. "
            />
          </div>
        </div>
        <div>
          <div className="form-group col-md-4 p-0-right">
            <label className="profile-label" htmlFor="membergetmemberCTA">
              {this.translate('Nome do CTA')} <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="membergetmemberCTA"
              />
              <ReactTooltip
                id="membergetmemberCTA"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('***')}</span>
              </ReactTooltip>
            </label>
            <input
              required
              type="text"
              className="form-control profile-placeholder"
              name="membergetmemberCta"
              onChange={(e) => this.handleUserInput(e)}
              value={
                formData.membergetmemberCta ? formData.membergetmemberCta : ''
              }
              placeholder={this.translate('Nome do CTA')}
            />
          </div>
        </div>
      </div>
    );
  }

  renderActionPointsFields(formData) {
    const { clientData } = this.context;
    return (
      <div>
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="conversionType">
              {this.translate('Geração de Pontos')}&nbsp; <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="conversionType"
              />
              <ReactTooltip
                id="conversionType"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('conversionType-tooltip-text')}</span>
              </ReactTooltip>
            </label>
            <select
              disabled
              className="form-control profile-placeholder"
              name="conversionType"
              value={formData.conversionType}
            >
              <option value="">{this.translate('select')}...</option>
              <option value="direct">{this.translate('Direta')}</option>
              <option value="form">{this.translate('Formulário')}</option>
              <option value="none">
                {this.translate('Nenhum (Apenas Link)')}
              </option>
              <option value="membergetmember">
                {this.translate('Member Get Member')}
              </option>
            </select>
          </div>
        </div>

        {this.renderConversionTypeForm()}

        <div className="col-md-12 row p-0-right">
          {formData.conversionType !== 'membergetmember' && (
            <div className="form-group col-md-4 hidden">
              <label
                className="profile-label"
                htmlFor="landingPagePreviewPointLabel"
              >
                {this.translate('Visualização de Landing Page')}&nbsp;
                <code>*</code>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="landingPagePreviewPointLabel"
                />
                <ReactTooltip
                  id="landingPagePreviewPointLabel"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    {this.translate(
                      'Esta função está temporariamente desabilitada!'
                    )}
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="number"
                className="form-control profile-placeholder"
                name="landingPagePreviewPoint"
                value={formData.landingPagePreviewPoint}
                placeholder="Visualização de Landing Page"
              />
            </div>
          )}

          {formData.conversionType === 'form' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="leadRegistration">
                  {this.translate('Cadastro de Lead')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="leadRegistration"
                  />
                  <ReactTooltip
                    id="leadRegistration"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Pontuação dada ao player quando algum lead informa seus'
                      )}
                      <br />
                      {this.translate(
                        'dados no cadastro vindo pelo link de compartilhamento'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  type="number"
                  className="form-control profile-placeholder"
                  name="leadRegistration"
                  value={formData.leadRegistration}
                  placeholder="Cadastro de Lead"
                />
              </div>
            </>
          )}

          {(formData.conversionType === 'direct' ||
            formData.conversionType === 'form') && (
            <>
              <div className="form-group col-md-4 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Conversão de Vendas')} <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="salesConversion"
                  />
                  <ReactTooltip
                    id="salesConversion"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      {this.translate(
                        'Pontuação dada ao player quando algum lead é convertido'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  type="number"
                  className="form-control profile-placeholder"
                  name="salesConversion"
                  value={formData.salesConversion}
                  placeholder={this.translate('Conversão de Vendas')}
                />
              </div>
            </>
          )}

          {formData.conversionType === 'form' &&
            this.context.clientData &&
            this.context.clientData.redirectWhatsapp &&
            this.context.clientData.redirectWhatsapp === 'true' && (
              <div className="form-group col-md-6">
                <div className="profile-placeholder">
                  <label
                    className="profile-label"
                    htmlFor="contactPlayerWhatsapp"
                  >
                    {this.translate(
                      'Habilitar o WhatsApp para o lead entrar em contato direto com o vendedor?'
                    )}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="contactPlayerWhatsapp"
                      checked={
                        this.state.formData.contactPlayerWhatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="contactPlayerWhatsapp"
                      checked={
                        this.state.formData.contactPlayerWhatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            )}

          {formData.conversionType === 'membergetmember' &&
            this.renderMemberGetMemberFields(formData)}
        </div>
        <div className="col-md-12 row points-by-share p-0-right">
          <div className="form-group col-md-6 p-0-right">
            <div className="profile-placeholder">
              <label className="profile-label" htmlFor="comment">
                {this.translate('Pontuar pelo primeiro compartilhamento?')}
              </label>
              <label className="checkbox-inline">
                <input
                  disabled
                  readOnly
                  type="radio"
                  value
                  name="playerSharePoints"
                  checked={formData.playerSharePoints === 'true'}
                />
                &nbsp;{this.translate('yes')}
              </label>
              <label className="checkbox-inline">
                <input
                  disabled
                  readOnly
                  type="radio"
                  value={false}
                  name="playerSharePoints"
                  checked={formData.playerSharePoints !== 'true'}
                />
                &nbsp;{this.translate('no')}
                <ReactTooltip
                  id="leadRegistration"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>{this.translate('lead-registration-text1')}</span>
                </ReactTooltip>
              </label>
            </div>
          </div>

          {formData.playerSharePoints === 'true' && (
            <>
              <div className="form-group col-md-6 p-0-right">
                <label className="profile-label" htmlFor="salesConversion">
                  {this.translate('Pontuação pelo primeiro compartilhamento')}{' '}
                  <code>*</code>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="pointsByShare"
                  />
                  <ReactTooltip
                    id="pointsByShare"
                    type="dark"
                    effect="solid"
                    multiline
                    className="tool-tip"
                  >
                    <span>
                      {this.translate(
                        'Esses pontos são importantes pois estimulam o engajamento dos usuários'
                      )}
                    </span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  type="number"
                  className="form-control profile-placeholder"
                  name="pointsByShare"
                  value={formData.pointsByShare}
                  placeholder={this.translate(
                    'Pontuação pelo primeiro compartilhamento'
                  )}
                />
              </div>
            </>
          )}
        </div>
        {clientData.isGamified === 'true' && (
          <div className="col-md-12">
            <div className="row">
              <div className="col-xs-12 row">
                <div className="form-group col-md-4">
                  <div className="profile-placeholder">
                    <label className="profile-label" htmlFor="comment">
                      {this.translate('has-awards')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value
                        name="hasAwards"
                        checked={formData.hasAwards === 'true'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        disabled
                        readOnly
                        type="radio"
                        value={false}
                        name="hasAwards"
                        checked={formData.hasAwards !== 'true'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('awardsModalIsOpen')}
            >
              {this.translate('awards')}
            </button>
          </div>
        )}
        {clientData.isGamified === 'true' && (
          <div className="form-group col-md-4">
            <button
              type="button"
              className="btn btn-oq-black w-100"
              disabled={formData.hasAwards !== 'true'}
              onClick={() => this.handleModalToggle('extraPointsModalIsOpen')}
            >
              {this.translate('extra-points')}
            </button>
          </div>
        )}
      </div>
    );
  }

  renderIconsShare() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#inconsShare"
          aria-expanded="false"
        >
          Ícones de compartilhamento
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="inconsShareI"
            style={{ marginLeft: 8 }}
          />
          <ReactTooltip
            id="inconsShareI"
            type="dark"
            effect="solid"
            multiline
            className="tool-tip"
          >
            <span>
              Você deve selecionar os canais de distribuição do conteúdo a cada
              publicação de acordo com sua estratégia e perfil de público
            </span>
          </ReactTooltip>
        </h4>
        <div
          id="inconsShare"
          aria-expanded="false"
          className="collapse"
          style={{ marginLeft: -60 }}
        >
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="whatsapp">
                Whatsapp &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.whatsapp &&
                  formData.iconsShare.whatsapp === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="whatsapp"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.whatsapp &&
                formData.iconsShare.whatsapp === 'true' && (
                  <ReactTooltip
                    id="whatsapp"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="whatsapp"
                      checked={
                        this.state.formData &&
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.whatsapp &&
                        this.state.formData.iconsShare.whatsapp === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="whatsapp"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.whatsapp &&
                        this.state.formData.iconsShare.whatsapp !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="facebook">
                Facebook &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.facebook &&
                  formData.iconsShare.facebook === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="facebook"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.facebook &&
                formData.iconsShare.facebook === 'true' && (
                  <ReactTooltip
                    id="facebook"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="facebook"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.facebook &&
                        this.state.formData.iconsShare.facebook === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="facebook"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.facebook &&
                        this.state.formData.iconsShare.facebook !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="telegram">
                Telegram &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.telegram &&
                  formData.iconsShare.telegram === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="telegram"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.telegram &&
                formData.iconsShare.telegram === 'true' && (
                  <ReactTooltip
                    id="telegram"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="telegram"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.telegram &&
                        this.state.formData.iconsShare.telegram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="telegram"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.telegram &&
                        this.state.formData.iconsShare.telegram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="twitter">
                Twitter &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.twitter &&
                  formData.iconsShare.twitter === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="twitter"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.twitter &&
                formData.iconsShare.twitter === 'true' && (
                  <ReactTooltip
                    id="twitter"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="twitter"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.twitter &&
                        this.state.formData.iconsShare.twitter === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="twitter"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.twitter &&
                        this.state.formData.iconsShare.twitter !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="pinterest">
                Pinterest &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.pinterest &&
                  formData.iconsShare.pinterest === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="pinterest"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.pinterest &&
                formData.iconsShare.pinterest === 'true' && (
                  <ReactTooltip
                    id="pinterest"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="pinterest"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.pinterest &&
                        this.state.formData.iconsShare.pinterest === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="pinterest"
                      onChange={(e) => this.handleIconsShare(e)}
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.pinterest &&
                        this.state.formData.iconsShare.pinterest !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="copyLink">
                Copiar link &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.copyLink &&
                  formData.iconsShare.copyLink === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="copyLink"
                    />
                  )}
              </label>
              {formData.iconsShare &&
                formData.iconsShare.copyLink &&
                formData.iconsShare.copyLink === 'true' && (
                  <ReactTooltip
                    id="copyLink"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>
                      Você deve selecionar os canais de distribuição do conteúdo
                      a cada publicação de acordo com sua estratégia e perfil de
                      público
                    </span>
                  </ReactTooltip>
                )}
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="copyLink"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.copyLink &&
                        this.state.formData.iconsShare.copyLink === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="copyLink"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.copyLink &&
                        this.state.formData.iconsShare.copyLink !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="instagram">
                Instagram &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.instagram &&
                  formData.iconsShare.instagram === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="instagram"
                    />
                  )}
              </label>
              <ReactTooltip id="instagram" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="instagram"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.instagram &&
                        this.state.formData.iconsShare.instagram === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="instagram"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.instagram &&
                        this.state.formData.iconsShare.instagram !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="email">
                Email &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.email &&
                  formData.iconsShare.email === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="email"
                    />
                  )}
              </label>
              <ReactTooltip id="email" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="email"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.email &&
                        this.state.formData.iconsShare.email === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="email"
                      checked={
                        this.state.formData.iconsShare &&
                        this.state.formData.iconsShare.email &&
                        this.state.formData.iconsShare.email !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="linkedin">
                Linkedin &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.linkedin &&
                  formData.iconsShare.linkedin === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="linkedin"
                    />
                  )}
              </label>
              <ReactTooltip id="linkedin" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="linkedin"
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.linkedin &&
                        this.state.formData.iconsShare.linkedin === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="linkedin"
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.linkedin &&
                        this.state.formData.iconsShare.linkedin !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <div className="wpp-social d-flex w-100">
              <label className="profile-label" htmlFor="messenger">
                Messenger &nbsp;
                {formData.iconsShare &&
                  formData.iconsShare.messenger &&
                  formData.iconsShare.messenger === 'true' && (
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="messenger"
                    />
                  )}
              </label>
              <ReactTooltip id="messenger" type="dark" effect="solid" multiline>
                <span>
                  Você deve selecionar os canais de distribuição do conteúdo a
                  cada publicação de acordo com sua estratégia e perfil de
                  público
                </span>
              </ReactTooltip>
              <div className="form-group col-md-8">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="messenger"
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.messenger &&
                        this.state.formData.iconsShare.messenger === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={false}
                      name="messenger"
                      checked={
                        formData.iconsShare &&
                        formData.iconsShare.messenger &&
                        this.state.formData.iconsShare.messenger !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible, publicationSocialMedia } = this.state.formData;
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          {this.renderPhase1()}
          {this.renderPhase2()}
          {publicationSocialMedia &&
            publicationSocialMedia === 'true' &&
            this.renderSocialMedia()}
          {this.renderPhase3()}
          {/* {this.renderIconsShare()} */}
        </div>
        <div
          className="col-md-12"
          style={{
            justifyContent: 'center',
            textAlign: 'center',
            display: 'flex',
          }}
        />
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive form-group"
        alt="action banner"
      />
    );
  }

  toggleModalExtraFieldsList() {
    this.setState({
      isOpenModalExtraFieldsList: !this.state.isOpenModalExtraFieldsList,
    });
  }

  modalExtraFieldsList() {
    return (
      <Modal
        open={this.state.isOpenModalExtraFieldsList}
        onClose={this.toggleModalExtraFieldsList.bind(this)}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <div className="oq-wrapper-contents">
          <h1 className="title-alert title-normal">
            Listagem de campos extras
          </h1>
          <p className="content-alert">
            Confira abaixo todos campos extras ativos nessa publicação.
            <br />
            Caso queria remover algum campo, clique no icone de <b>lixeira</b>.
          </p>
          <br />
          <div className="oq-input-field">
            {this.state.formData.extraFieldsInputs &&
              this.state.formData.extraFieldsInputs.map((input, index) => (
                <>
                  <div
                    className="oq-group-horizontal"
                    style={{ marginBottom: '10px' }}
                  >
                    <input
                      type="text"
                      placeholder={input.name}
                      value={input.name}
                      disabled
                      readOnly
                    />
                    <button
                      disabled
                      readOnly
                      data-index-remove={index}
                      className="btn-action-extra"
                    >
                      <i
                        className="material-icons-outlined"
                        style={{ marginRight: '0' }}
                      >
                        delete
                      </i>
                    </button>
                  </div>
                </>
              ))}
            {this.state.formData.extraFieldsInputs &&
              this.state.formData.extraFieldsInputs.length === 0 && (
                <>
                  <p className="content-alert">Nenhum campo extra criado.</p>
                </>
              )}
          </div>
        </div>
      </Modal>
    );
  }

  renderConversionTypeForm() {
    const { conversionType } = this.state.formData;
    let html;

    if (conversionType === 'direct') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 1"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 1 (Link de redirecionamento)')}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectRedirectLink"
              />
              <ReactTooltip
                id="btnDirectRedirectLink"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Link que será vinculado ao botão configurado anteriormente. Ao'
                  )}
                  <br />
                  {this.translate(
                    'clicar o lead será redirecionado para o link informado nesse'
                  )}
                  <br />
                  campo.
                  <br />
                </span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'none') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Link de redirecionamento')}
              <code>*</code>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="redirectDashCallToAction"
              value={this.state.formData.redirectDashCallToAction}
              placeholder="Link de redirecionamento"
            />
          </div>
        </div>
      );
    } else if (conversionType === 'form') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnFormText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnFormText"
              value={this.state.formData.btnFormText}
              placeholder="Call to action 1 texto do botão"
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
            </label>
            <div className="group-buttons-horizontal">
              {this.state.formData.extraFieldsInputs &&
                this.state.formData.extraFieldsInputs.length > 0 && (
                  <>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        this.toggleModalExtraFieldsList();
                      }}
                      className="btn-action-extra"
                    >
                      <i className="material-icons-outlined">view_list</i>
                      Ver campos extras
                    </button>
                  </>
                )}
            </div>
            {this.modalExtraFieldsList()}
          </div>
        </div>
      );
    } else if (conversionType === 'both') {
      html = (
        <div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnFormText">
              {this.translate('Call to Action 1')} <code>*</code> &nbsp;
              <span style={{ fontSize: '10px', display: 'contents' }}>
                (Até 38 caracteres)
              </span>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="callToAction1"
              />
              <ReactTooltip
                id="callToAction1"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('call-to-action-text1')}</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnFormText"
              value={this.state.formData.btnFormText}
              placeholder="Call to action 1 texto do botão"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectText">
              {this.translate('Call to action 2 (Texto do botão)')}{' '}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectText"
              />
              <ReactTooltip
                id="btnDirectText"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Texto que será exibido no botão de redirecionamento para um link'
                  )}
                  <br />
                  {this.translate('configurado em seguida.')}
                </span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectText"
              value={this.state.formData.btnDirectText}
              placeholder="Call to action 2 texto do botão"
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="btnDirectRedirectLink">
              {this.translate('Call to action 2 (Link de redirecionamento) ')}
              <code>*</code>
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="btnDirectRedirectLink"
              />
              <ReactTooltip
                id="btnDirectRedirectLink"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate(
                    'Link que será vinculado ao botão configurado anteriormente. Ao'
                  )}
                  <br />
                  {this.translate(
                    'clicar o lead será redirecionado para o link informado nesse'
                  )}
                  <br />
                  campo.
                  <br />
                </span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="text"
              className="form-control profile-placeholder"
              name="btnDirectRedirectLink"
              value={this.state.formData.btnDirectRedirectLink}
              placeholder="Call to action 2 (Link de redirecionamento)"
            />
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-text')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Interesse, Profissão, Região, Bairro..."
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraFieldSheet1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-text')}</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
            />
          </div>
        </div>
      );
    }

    return html;
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Detalhes da publicação
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>

            {this.renderActionsTable()}
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={(awardsList) =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={(extraPointsList) =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

export default DetailsActionVirtual;
