import React from 'react';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faInfo,
  faExclamationTriangle,
  faTimesCircle,
  faCircleNotch,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';

export const NotifyUpdate = (msg, type, id, autoClose = false) => {
  toast.update(id, {
    render: Notify(msg, type),
    type,
    autoClose,
    className: css({
      transform: 'rotateX(360deg)',
      transition: 'transform 0.6s',
    }),
  });
};

const Notify = (msg, type, autoClose = true) => {
  switch (type) {
    case 'success':
      toast.success(
        <div>
          <FontAwesomeIcon icon={faCheckCircle} className="faa-tada animated" />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            backgroundColor: ' #39D36A',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
    case 'info':
      toast.info(
        <div>
          <FontAwesomeIcon icon={faInfo} className="faa-tada animated" />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            backgroundColor: '#3FBBF8',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
    case 'warn':
      toast.warn(
        <div>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="faa-tada animated"
          />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            backgroundColor: '#FFC42C',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
    case 'error':
      toast.error(
        <div>
          <FontAwesomeIcon icon={faTimesCircle} className="faa-tada animated" />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            backgroundColor: '#F95455',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
    case 'loading':
      toast.info(
        <div>
          <FontAwesomeIcon icon={faCircleNotch} className="faa-tada animated" />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            backgroundColor: '#3FBBF8',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
    default:
      toast(
        <div>
          <FontAwesomeIcon icon={faCommentDots} className="faa-tada animated" />
          <br />
          <span>{msg}</span>
        </div>,
        {
          className: css({
            minHeight: '66px',
            textAlign: 'center',
          }),
          autoClose,
        }
      );
      break;
  }
};

export { Notify };
