import React from 'react';
import { Link } from 'react-router-dom';

function SideBarItem(props) {
  return (
    <>
      {props.isVisible && (
        <li className={props.isActive}>
          <Link to={props.link}>
            {props.icon && <i>{props.icon}</i>}
            <span>{props.label}</span>
          </Link>
        </li>
      )}
    </>
  );
}

export default SideBarItem;
