import '../styles.css';
import React, { useEffect, useState } from 'react';
import tagManangerLogo from '../../../../../../Assets/Images/tag-manager-logo.png';
import analyticsLogo from '../../../../../../Assets/Images/analytics-logo.png';
import pixelLogo from '../../../../../../Assets/Images/pixel-logo.png';
import cancelLogo from '../../../../../../Assets/Images/cancel-logo.png';

export function Integrations({
  handleIntegration,
  onChange,
  onChangeSecondary,
  selected,
  value,
  valueSecondary,
}) {
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [valueCodeIntegration, setValueCodeIntegration] = useState();
  const [valueCodeIntegrationBody, setValueCodeIntegrationBody] = useState();
  useEffect(() => {
    setSelectedIntegration(selected);
  }, []);
  useEffect(() => {
    if (value) {
      setValueCodeIntegration(value);
    }
  }, [value]);
  useEffect(() => {
    if (valueSecondary) {
      setValueCodeIntegrationBody(valueSecondary);
    }
  }, [valueSecondary]);
  return (
    <>
      <h4>Passo 1: Escolha uma das integrações</h4>
      <br />
      <div className="row">
        <div className="col-md-3">
          <div
            onClick={() => {
              setSelectedIntegration(0);
              handleIntegration(0);
            }}
            className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
              0 && 'selected'}`}
          >
            <div className="card-body center-all">
              <img src={pixelLogo} alt="" width={230} />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            onClick={() => {
              setSelectedIntegration(1);
              handleIntegration(1);
            }}
            className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
              1 && 'selected'}`}
          >
            <div className="card-body center-all">
              <img src={analyticsLogo} alt="" width={150} />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            onClick={() => {
              setSelectedIntegration(2);
              handleIntegration(2);
            }}
            className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
              2 && 'selected'}`}
          >
            <div className="card-body center-all">
              <img src={tagManangerLogo} alt="" width={150} />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div
            onClick={() => {
              setSelectedIntegration(3);
              handleIntegration(false);
            }}
            className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
              3 && 'selected'}`}
          >
            <div className="card-body center-all">
              <img src={cancelLogo} alt="" width={50} />
            </div>
          </div>
        </div>
      </div>
      <br />
      <h4>
        Passo 2: Cole aqui o código que ficará na tag <strong>head</strong>
      </h4>
      <br />
      <div className="form-group">
        <textarea
          className="form-control"
          rows="10"
          value={valueCodeIntegration}
          onChange={e => {
            onChange(e);
            setValueCodeIntegration(e.target.value);
          }}
        ></textarea>
      </div>
      {selectedIntegration === 2 && (
        <>
          <br />
          <h4>
            Passo 3: Cole aqui o código que ficará após a tag{' '}
            <strong>body</strong>
          </h4>
          <br />
          <div className="form-group">
            <textarea
              className="form-control"
              rows="10"
              value={valueCodeIntegrationBody}
              onChange={e => {
                onChangeSecondary(e);
                setValueCodeIntegrationBody(e.target.value);
              }}
            ></textarea>
          </div>
        </>
      )}
    </>
  );
}
