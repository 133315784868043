import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import { api } from '../../../Config';

import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class PlayerRanking extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      playersRanking: [],
      loadingRanking: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.getPlayersRanking();
  }

  getPlayersRanking() {
    this.setState(
      {
        loadingRanking: true,
      },
      () => {
        const parameters = {
          searchFunctionality: 'getPlayersRanking',
          userType: 'client',
        };

        api
          .post(`/search`, parameters)
          .then((response) => {
            if (!response.data.success) {
              this.setState({
                playersRanking: [],
                loadingRanking: false,
              });
            } else {
              this.setState({
                playersRanking: response.data.data.playersRankingOrder,
                loadingRanking: false,
              });
            }
          })
          .catch((err) => {
            console.log('getPlayersRanking error: ', err);
          });
      }
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  renderActionRank() {
    const columns = [
      {
        Header: '',
        accessor: 'avatar',
        width: 50,
        Cell: (props) => (
          <img
            src={props.value ? props.value : avatarGeneric}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px', border: '1px solid grey' }}
            alt="player logo"
          />
        ),
      },
      {
        Header: '',
        id: 'rankingIcon',
        accessor: 'ranking',
        width: 50,
        Cell: (props) => {
          const now = moment().valueOf();
          const actionEndAt = moment(props.original.endDateEpoch).valueOf();
          const finished = props.original.finished || now > actionEndAt;

          if (props.value <= 2 && finished) {
            return (
              <div className="text-center">
                <i
                  className="fa fa-trophy"
                  style={{
                    fontSize: '1.3em',
                    color: 'gold',
                    verticalAlign: 'middle',
                  }}
                  aria-hidden="true"
                />
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: this.translate('userName'),
        accessor: 'name',
        Cell: (props) => <span className="text-center">{props.value}</span>,
      },
      {
        Header: this.translate('totalPontuation'),
        accessor: 'totalPoints',
        Cell: (props) => (
          <span className="text-center">{props.value} pontos</span>
        ),
      },
      {
        id: 'position',
        Header: this.translate('position'),
        accessor: (d) => (d.position ? `${d.position}°` : '--'),
        Cell: (props) => <span className="text-center">{props.value}</span>,
      },
    ];

    return (
      <div className="oq-card action-ranking">
        <span className="oq-card-title">
          <i className="fa fa-trophy" />
          &nbsp; Ranking da publicação
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.playersRanking}
            defaultPageSize={10}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingRanking}
            loadingText={this.translate('loadingActionRanking')}
            noDataText={this.translate('noPlayersInActionRaking')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="col-md-12">{this.renderBackButton()}</div>
            {this.renderActionRank()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderActionRank()}
      //   </div>
      // </div>
    );
  }
}

const PlayersPageLink = (props) => (
  <Link className="menu-link" to={routes.PLAYERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('players')}</span>
  </Link>
);

export default PlayerRanking;

export { PlayersPageLink };
