import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { LoadingDashboard } from '../../components/LoadingDashboard';

export default function ConversionsLine(props) {
  const [graphData, setGraphData] = useState([
    {
      id: 'japan',
      data: [{ x: 0, y: 0 }],
    },
  ]);

  const graphMargin = {
    top: 50,
    right: 10,
    bottom: 50,
    left: 10,
  };

  const graphYScale = {
    type: 'linear',
    stacked: true,
    min: 'auto',
    max: 'auto',
  };

  const graphAxisBottom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: 36,
    legendPosition: 'middle',
  };

  const graphAxisLeft = {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: '',
    legendOffset: -40,
    legendPosition: 'middle',
  };

  useEffect(() => {
    setGraphData([
      { id: 'Conversões', data: props.amountOfConversionsPerMonth },
    ]);
  }, [props]);

  if (props.loadingConversionsCount) {
    return <LoadingDashboard size="4x" />;
  }

  return (
    <>
      <span
        style={{
          display: 'flex',
          display: '-webkit-flex',
          fontSize: '18px',
          alignItems: 'center',
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        <i
          style={{
            marginRight: '10px',
          }}
          className="material-icons-outlined"
        >
          receipt
        </i>
        Conversões mês a mês
      </span>
      <ResponsiveLine
        data={graphData}
        margin={graphMargin}
        xScale={{ type: 'point' }}
        yScale={graphYScale}
        curve="catmullRom"
        axisTop={null}
        axisRight={null}
        axisBottom={graphAxisBottom}
        axisLeft={graphAxisLeft}
        colors="category10"
        dotSize={10}
        dotColor="inherit:darker(0.3)"
        dotBorderWidth={2}
        dotBorderColor="#ffffff"
        enableDotLabel
        dotLabel="y"
        dotLabelYOffset={-12}
        animate
        motionStiffness={90}
        motionDamping={15}
        legends={[]}
      />
    </>
  );
}
