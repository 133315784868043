import axios from 'axios';
import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-responsive-modal';

import { Notify } from '../../../components/Notify';
import * as config from '../../../Config';
import { auth } from '../../../firebase';
import { api } from '../../../Config';

class RescueDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingCloseLote: false,
    };
  }

  renderPersonData(isPaymentMethodTed) {
    if (this.props.rescueData) {
      var { rescue, player } = this.props.rescueData;
    }

    return (
      <div className="col-md-12">
        <h4>Dados pessoais</h4>
        <div id="phase1" aria-expanded="false" className="collapse in">
          {isPaymentMethodTed ? (
            <div className="row col-md-12">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  Nome do recebedor
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder="Nome do recebedor"
                  value={
                    rescue && rescue.accountData && rescue.accountData.name
                  }
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  CPF/CNPJ
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder="CPF/CNPJ"
                  value={rescue && rescue.accountData && rescue.accountData.cpf}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  E-mail
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder="E-mail"
                  value={rescue && rescue.playerEmail}
                />
              </div>
            </div>
          ) : (
            <div className="row col-md-12">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  Nome do recebedor
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder="Nome do recebedor"
                  value={player && player.name}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  CPF/CNPJ
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="cpf"
                  placeholder="CPF/CNPJ"
                  value={player && player.cpf}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  E-mail
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="email"
                  placeholder="E-mail"
                  value={player && player.email}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
      </div>
    );
  }

  renderBankData(isPaymentMethodTed, accountType, typePix) {
    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
    }

    return (
      <div className="col-md-12">
        <h4>Dados para Transferência</h4>
        <div>
          {isPaymentMethodTed ? (
            <>
              <div className="row col-md-12">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    Banco do recebedor
                    <span style={{ fontSize: '10px', display: 'contents' }} />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="labelBank"
                    placeholder="Banco do recebedor"
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.labelBank &&
                      rescue.accountData.labelBank
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    Agência
                    <span style={{ fontSize: '10px', display: 'contents' }} />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="agency"
                    placeholder="Agência"
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.agency &&
                      rescue.accountData.agency
                    }
                  />
                </div>
                <div className="form-group col-md-4" />
              </div>

              <div className="row col-md-12">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    Tipo de conta
                    <span style={{ fontSize: '10px', display: 'contents' }} />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="typeAccount"
                    placeholder="Tipo de conta"
                    value={
                      accountType && accountType.label ? accountType.label : ''
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {accountType && accountType.label ? accountType.label : ''}
                    <span style={{ fontSize: '10px', display: 'contents' }} />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="conta"
                    placeholder={
                      accountType && accountType.label ? accountType.label : ''
                    }
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.conta &&
                      rescue.accountData.conta
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    Dígito
                    <span style={{ fontSize: '10px', display: 'contents' }} />
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="digitCount"
                    placeholder="Dígito"
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.digitCount &&
                      rescue.accountData.digitCount
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row col-md-12">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  Tipo de chave pix
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="typePix"
                  placeholder="Tipo de chave pix"
                  value={typePix && typePix.label}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  Chave pix
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="pixkey"
                  placeholder="Chave pix"
                  value={
                    rescue &&
                    rescue.accountData &&
                    rescue.accountData.pixkey &&
                    rescue.accountData.pixkey
                  }
                />
              </div>
              <div className="form-group col-md-4" />
            </div>
          )}
        </div>
        <hr />
      </div>
    );
  }

  renderPaymentMethod() {
    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
    }

    let isPaymentMethodTed = true;
    if (
      (rescue &&
        rescue.accountData &&
        rescue.accountData.paymentMethod &&
        rescue.accountData.paymentMethod.label === 'pix' &&
        rescue.accountData.paymentMethod.value) ||
      (rescue &&
        rescue.accountData &&
        rescue.accountData.paymentMethod &&
        rescue.accountData.paymentMethod === 'pix')
    ) {
      isPaymentMethodTed = false;
    }

    return (
      <div className="col-md-12">
        <h4>Método de pagamento</h4>
        <div>
          <div className="row col-md-12">
            {isPaymentMethodTed ? (
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="ted"
                      checked
                    />
                    &nbsp; Transferência Bancária
                  </label>
                </div>
              </div>
            ) : (
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value
                      name="pix"
                      checked
                    />
                    &nbsp; Transferência PIX
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  formatMoney(amount) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  renderValueTransfer() {
    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
    }

    return (
      <div className="col-md-12">
        <h4>Valor a ser transferido</h4>
        <div>
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="actionTitle">
                Valor a ser transferido
                <span style={{ fontSize: '10px', display: 'contents' }} />
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="name"
                placeholder="Valor a ser transferido"
                value={rescue && this.formatMoney(rescue.totalPaymentValue)}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderStatusTransfer() {
    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
    }

    const statusTransfer =
      rescue && rescue.statusTransfer ? rescue.statusTransfer : '';

    const statusTransferDescription =
      rescue && rescue.statusTransferDescription
        ? rescue.statusTransferDescription
        : '';

    return (
      <div className="col-md-12">
        <h4>Status da Transferência</h4>
        <div>
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="actionTitle">
                Status da Transferência
                <span style={{ fontSize: '10px', display: 'contents' }} />
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="name"
                placeholder="Status"
                value={statusTransfer}
              />
            </div>
            {statusTransferDescription && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  Descrição
                  <span style={{ fontSize: '10px', display: 'contents' }} />
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder="Descrição"
                  value={statusTransferDescription}
                />
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  async handleFormSubmit(e, typePix, accountType) {
    e.preventDefault();

    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
      var { client } = this.props;
    }

    let updateFunctionality = '';

    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }

    if (
      client &&
      client.integration &&
      client.integration.transfeera &&
      client.integration.transfeera.active === 'true'
    ) {
      updateFunctionality = 'closeLoteIntegrationTransfeera';
    } else {
      updateFunctionality = 'closeLoteIntegrationBs2';
    }

    this.setState({ loadingCloseLote: true });
    Notify('Processando lote. Aguarde...', 'loading', {
      autoClose: true,
    });

    const parameters = {
      // updateFunctionality: 'closeLoteIntegrationBs2',
      // updateFunctionality: 'closeLoteIntegrationTransfeera',
      updateFunctionality,
      userType: 'administrator',
      clientId: rescue.clientId,
      rescueId: rescue.id,
      typeRescue: this.props.typeRescue,
    };

    const res = await api.post(`/update`, parameters);

    const result = res.data;

    if (result.success === true) {
      this.setState({ loadingCloseLote: false }, () => {
        Notify(result.message, 'success');
      });
    } else {
      this.setState({ loadingCloseLote: false }, () => {
        Notify(result.message, 'error');
      });
    }

    await this.props.getWalletClient(rescue.clientId);
    await this.props.getTransferenceRequest(rescue.clientId);
    this.props.closeModal();
  }

  render() {
    if (this.props.rescueData) {
      var { rescue } = this.props.rescueData;
      var { client } = this.props;
    }

    let isPaymentMethodTed = true;
    let accountType = {
      label: '',
      value: '',
    };
    let typePix = {
      label: '',
      value: '',
    };

    if (rescue) {
      var isCloseProccess = rescue.closeProccess ? rescue.closeProccess : false;

      var statusTransfer = rescue.statusTransfer ? rescue.statusTransfer : '';

      if (
        client &&
        client.integration &&
        client.integration.transfeera &&
        client.integration.transfeera.active === 'true'
      ) {
        const arrTypeAccountTransfeera = [
          {
            label: 'Conta Corrente',
            value: 'CONTA_CORRENTE',
          },
          {
            label: 'Conta Poupança',
            value: 'CONTA_POUPANCA',
          },
          {
            label: 'Conta Fácil',
            value: 'CONTA_FACIL',
          },
          {
            label: 'Entidades Públicas',
            value: 'ENTIDADES_PUBLICAS',
          },
        ];

        accountType = arrTypeAccountTransfeera.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
        const arrTypePixTransfeera = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'TELEFONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'CHAVE_ALEATORIA',
          },
        ];

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePixTransfeera.find(
            (type) => type.value === rescue.accountData.typePix
          );
        }
      } else {
        const arrTypeAccountBs2 = [
          {
            label: 'Conta Corrente',
            value: '2',
          },
          {
            label: 'Conta Poupança',
            value: '3',
          },
          {
            label: 'Conta de Pagamento',
            value: '1',
          },
        ];

        accountType = arrTypeAccountBs2.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        // CPF / CNPJ / PHONE / EMAIL / EVP
        const arrTypePixBs2 = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'PHONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'EVP',
          },
        ];

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePixBs2.find(
            (type) => type.value === rescue.accountData.typePix
          );
        }
      }
    }

    return (
      <Modal
        open={this.props.modalIsOpenDetailsRescue}
        onClose={() => this.props.closeModal()}
      >
        {/* <form onSubmit={e => this.handleFormSubmit(e)}> */}
        <div>
          <div className="container-fluid">
            <div className="row">
              <span className="oq-card-title">
                <i className="fa fa-info-circle" aria-hidden="true" />
                &nbsp; Detalhes do resgate
              </span>
              {this.renderPersonData(isPaymentMethodTed)}
              {this.renderBankData(isPaymentMethodTed, accountType, typePix)}
              {this.renderPaymentMethod()}
              {this.renderValueTransfer()}
              {statusTransfer && this.renderStatusTransfer()}

              <div
                className="row"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className="col-md-6" style={{ paddingBottom: '3%' }}>
                  <button
                    // type="submit"
                    type="button"
                    onClick={(e) =>
                      this.handleFormSubmit(e, typePix, accountType)
                    }
                    disabled={this.state.loadingCloseLote || isCloseProccess}
                    className="btn btn-oq btn-oq-lg btn-block"
                  >
                    Processar transferência
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </form> */}
      </Modal>
    );
  }
}

export default withNamespaces()(RescueDetails);
