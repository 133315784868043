import React from 'react';
import Modal from 'react-responsive-modal';

function ModalConversions(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.toggleModalConversion}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Você tem certeza que deseja continuar?
        </h2>
        <br />

        <p>
          Você tem certeza que deseja dar a pontuação de conversão para esse
          player?
        </p>

        <p>Essa ação não poderá ser desfeita após a confirmação.</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button
          className="oq-btn"
          disabled={props.converting}
          onClick={props.toggleModalConversion}
        >
          Cancelar
        </button>
        <button
          className="oq-btn btn-confirm"
          disabled={props.converting}
          onClick={() => {
            props.addConversion(props.playerId, props.action);
            props.toggleModalConversion();
          }}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  );
}

export default ModalConversions;
