import React, { Component } from 'react';

import _ from 'lodash';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { api } from '../../../../Config';
import ModalDeleteEditAccessGroup from '../../../components/Modals/EditAccessGroup';

import { Notify } from '../../../../components/Notify';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

class EditAccessGroup extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      informationsGroupUncompleted: false,
      formErrors: {},
      formValid: false,
      formData: {
        usersGroupRemoved: {},
        name: '',
        description: '',
        permissions: {
          actions: {},
          reedemAwards: {},
          comunications: {},
          generalConditions: {},
          leads: {},
        },
      },
      modalDeleteEditAccessGroupIsOpen: false,
    };

    return initialState;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    await this.getGroupById(params.groupId);
  }

  async getUsers(groupId) {
    const { formData } = this.state;
    this.setState({ loadingUsersList: true }, () => {
      const parameters = {
        searchFunctionality: 'getUsersClient',
        userType: 'client',
        searchObject: { groupId },
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState({
              loadingUsersList: false,
              usersList: [],
              usersGroupRemoved: {},
            });
            return;
          }
          this.setState({
            loadingUsersList: false,
            usersList: result.users,
            formData: {
              ...formData,
              usersGroup: result.usersGroup,
              usersGroupRemoved: {},
            },
          });
        })
        .catch((error) => {
          console.log('getUsersClient error:', error);
          this.setState({
            loadingUsersList: false,
            usersList: [],
            usersGroupRemoved: {},
          });
        });
    });
  }

  async getGroupById(groupId) {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getGroupById',
        userType: 'client',
        groupId,
      };

      api
        .post(`/search`, parameters)
        .then(async (res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                formData: {},
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }
          this.setState({
            isLoading: false,
            formData: result.group,
          });
          await this.getUsers(groupId);
          // Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('getGroupsClient error:', error);
          this.setState({
            isLoading: false,
            formData: {},
          });
        });
    });
  }

  informationsIsUncompleted() {
    const { formData } = this.state;

    return !formData.name || !formData.description;
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'description') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleFormDataChange(name, value, context) {
    let getRemovedUsers = { ...this.state.formData.usersGroupRemoved };
    let getusersGroup = [...this.state.formData.usersGroup];

    if (context.action === 'remove-value') {
      if (Object.keys(getRemovedUsers).length === 0) {
        getRemovedUsers[context.removedValue.value] = context.removedValue;
      } else {
        getRemovedUsers = {
          ...this.state.formData.usersGroupRemoved,
          [context.removedValue.value]: context.removedValue,
        };
      }
    }

    if (context.action === 'select-option') {
      const findUser = _.find(
        getRemovedUsers,
        (user) => user.value === context.option.value
      );
      if (findUser) {
        delete getRemovedUsers[findUser.value];
      }
    }

    if (context.action === 'clear') {
      if (getusersGroup.length > 0) {
        getusersGroup.map((user) => {
          getRemovedUsers[user.value] = user;
        });
      }
    }

    this.setState((prevState) => {
      return {
        formData: {
          ...prevState.formData,
          [name]: value,
          usersGroupRemoved: getRemovedUsers,
        },
      };
    });
  }

  handleInformationInput(e) {
    const { name, value } = e.target;
    this.handleFormDataInformationChange(name, value);
  }

  handleFormDataInformationChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          informationsGroupUncompleted: this.informationsIsUncompleted(),
        });
      }
    );
  }

  handlePermissionInput(e, namePermission) {
    const { name, checked } = e.target;
    this.handleFormDataPermissionChange(name, checked, namePermission);
  }

  handleFormDataPermissionChange(name, checked, namePermission) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [namePermission]: {
            ...prevState.formData.permissions[namePermission],
            [name]: checked,
          },
        },
      },
    }));
  }

  handlePermissionGroupInput(e, groupPermission, namePermission) {
    const { name, checked } = e.target;
    this.handleFormDataPermissionGroupChange(
      name,
      checked,
      groupPermission,
      namePermission
    );
  }
  handleFormDataPermissionGroupChange(
    name,
    checked,
    groupPermission,
    namePermission
  ) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [groupPermission]: {
            ...prevState.formData.permissions[groupPermission],
            [namePermission]: {
              ...prevState.formData.permissions[groupPermission][
                namePermission
              ],
              [name]: checked,
            },
          },
        },
      },
    }));
  }

  handleTeste(name, checked, groupPermission, namePermission) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [groupPermission]: {
            ...prevState.formData.permissions[groupPermission],
            [namePermission]: {
              ...prevState.formData.permissions[groupPermission][
                namePermission
              ],
              [name]: checked,
            },
          },
        },
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderInformationsGroup() {
    const { formData, informationsGroupUncompleted, formButtonPressed } =
      this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do grupo
          {informationsGroupUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Grupo de acesso')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  {/* (Até 72 caracteres) */}
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Grupo de acesso')}
                onChange={(e) => this.handleInformationInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Descrição')}
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  {/* (Até 72 caracteres) */}
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Descrição do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <textarea
                className="form-control profile-placeholder"
                placeholder={this.translate('Descrição')}
                rows="5"
                name="description"
                onChange={(e) => this.handleInformationInput(e)}
                value={formData.description}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPermissionDashboard() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionDashboard">
          {this.translate('Dashboard')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionDashboard"
          />
        </label>
        <ReactTooltip
          id="permissionDashboard"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'dashboard')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.dashboard &&
                  formData.permissions.dashboard.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionCompany() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionCompany">
          {this.translate('Empresa')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionCompany"
          />
        </label>
        <ReactTooltip
          id="permissionCompany"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'company')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.company &&
                  formData.permissions.company.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'company')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.company &&
                  formData.permissions.company.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAccessGroups() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAccessGroup">
          {this.translate('Grupos de acesso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAccessGroup"
          />
        </label>
        <ReactTooltip
          id="permissionAccessGroup"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.accessGroup &&
                  formData.permissions.accessGroup.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionUsers() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionUsers">
          {this.translate('Usuários')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionUsers"
          />
        </label>
        <ReactTooltip id="permissionUsers" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.users &&
                  formData.permissions.users.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLandingPage() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLandingPage">
          {this.translate('Landing Page')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLandingPage"
          />
        </label>
        <ReactTooltip
          id="permissionLandingPage"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.landingPage &&
                  formData.permissions.landingPage.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.landingPage &&
                  formData.permissions.landingPage.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  formData && formData.landingPage && formData.landingPage.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGamification() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionGamification">
          {this.translate('Gamification')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionGamification"
          />
        </label>
        <ReactTooltip
          id="permissionGamification"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e => this.handlePermissionInput(e, 'gamification')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'gamification')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'gamification')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.gamification &&
                  formData.permissions.gamification.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAwards() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAwards">
          {this.translate('Prêmios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAwards"
          />
        </label>
        <ReactTooltip
          id="permissionAwards"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.awards &&
                  formData.permissions.reedemAwards.awards.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionRescue() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionRescues">
          {this.translate('Resgates')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionRescues"
          />
        </label>
        <ReactTooltip
          id="permissionRescues"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.rescues &&
                  formData.permissions.reedemAwards.rescues.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="export"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.reedemAwards &&
                  formData.permissions.reedemAwards.rescues &&
                  formData.permissions.reedemAwards.rescues.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionReedemAwards() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Resgate de Prêmios
        </h5>
        {this.renderPermissionAwards()}
        {this.renderPermissionRescue()}
      </>
    );
  }

  renderPermissionSeasons() {
    const { formData } = this.state;
    // checked={
    //   formData &&
    //   formData.permissions &&
    //   formData.permissions.players &&
    //   formData.permissions.players.delete
    // }
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionSeasons">
          {this.translate('Temporadas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionSeasons"
          />
        </label>
        <ReactTooltip
          id="permissionSeasons"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.seasons &&
                  formData.permissions.seasons.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionVirtual() {
    const { formData } = this.state;
    // checked={
    //   formData &&
    //   formData.permissions &&
    //   formData.permissions.reedemAwards &&
    //   formData.permissions.reedemAwards.awards &&
    //   formData.permissions.reedemAwards.awards.visualize
    // }
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionVirtualActions">
          {this.translate('Virtuais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionVirtualActions"
          />
        </label>
        <ReactTooltip
          id="permissionVirtualActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.virtual &&
                  formData.permissions.actions.virtual.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionOmnichannel() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionOmnichannelActions">
          {this.translate('Presenciais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionOmnichannelActions"
          />
        </label>
        <ReactTooltip
          id="permissionOmnichannelActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.omnichannel &&
                  formData.permissions.actions.omnichannel.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionQuiz() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionQuizActions">
          {this.translate('Quiz')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionQuizActions"
          />
        </label>
        <ReactTooltip
          id="permissionQuizActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.quiz &&
                  formData.permissions.actions.quiz.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInformational() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label
          className="profile-label"
          htmlFor="permissionInformationalActions"
        >
          {this.translate('Informativas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInformationalActions"
          />
        </label>
        <ReactTooltip
          id="permissionInformationalActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.informational &&
                  formData.permissions.actions.informational.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInvite() {
    const { formData } = this.state;
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionInviteActions">
          {this.translate('Convite')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInviteActions"
          />
        </label>
        <ReactTooltip
          id="permissionInviteActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.actions &&
                  formData.permissions.actions.invite &&
                  formData.permissions.actions.invite.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionActions() {
    const { clientData } = this.context;

    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Publicações
        </h5>
        {this.renderPermissionVirtual()}
        {this.renderPermissionOmnichannel()}
        {this.renderPermissionQuiz()}
        {this.renderPermissionInformational()}
        {clientData &&
          clientData.isTeamCompetition &&
          clientData.isTeamCompetition === 'true' &&
          this.renderPermissionInvite()}
      </>
    );
  }

  renderPermissionExtraPoints() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '3rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionExtraPoints">
          {this.translate('Pontos Extras')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionExtraPoints"
          />
        </label>
        <ReactTooltip
          id="permissionExtraPoints"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="attribute"
                onChange={(e) => this.handlePermissionInput(e, 'extraPoints')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.extraPoints &&
                  formData.permissions.extraPoints.attribute
                }
              />
              &nbsp;{this.translate('Atribuir')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="convert"
                onChange={(e) => this.handlePermissionInput(e, 'extraPoints')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.extraPoints &&
                  formData.permissions.extraPoints.convert
                }
              />
              &nbsp;{this.translate('Converter')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionNotifications() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionMessages">
          {this.translate('Notificações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionMessages"
          />
        </label>
        <ReactTooltip
          id="permissionMessages"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.message &&
                  formData.permissions.comunications.message.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionEmails() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionEmails">
          {this.translate('E-mails')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionEmails"
          />
        </label>
        <ReactTooltip
          id="permissionEmails"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.email &&
                  formData.permissions.comunications.email.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionBanners() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBanners">
          {this.translate('Banners')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBanners"
          />
        </label>
        <ReactTooltip
          id="permissionBanners"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.comunications &&
                  formData.permissions.comunications.banner &&
                  formData.permissions.comunications.banner.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionComunication() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Comunicação
        </h5>
        {this.renderPermissionNotifications()}
        {this.renderPermissionEmails()}
        {this.renderPermissionBanners()}
      </>
    );
  }

  renderPermissionBenefits() {
    const { formData } = this.state;
    // checked={
    //   formData &&
    //   formData.permissions &&
    //   formData.permissions.comunications &&
    //   formData.permissions.comunications.message &&
    //   formData.permissions.comunications.message.visualize
    // }
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBenefits">
          {this.translate('Benefícios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBenefits"
          />
        </label>
        <ReactTooltip
          id="permissionBenefits"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.benefits &&
                  formData.permissions.benefits.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLeadsConversions() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLeadsConversions">
          {this.translate('Leads / Conversões')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLeadsConversions"
          />
        </label>
        <ReactTooltip
          id="permissionLeadsConversions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                // checked={leads.visualize}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.leads &&
                  formData.permissions.leads.visualize
                }
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                // checked={leads.convert}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.leads &&
                  formData.permissions.leads.convert
                }
                name="convert"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Converter')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                // checked={leads.export}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.leads &&
                  formData.permissions.leads.export
                }
                name="export"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Exportar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="stepAdvance"
                onChange={e => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Avançar Step')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={e => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Excluir')}
            </label> */}
          </div>

          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                // checked={leads.stepAdvance}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.leads &&
                  formData.permissions.leads.stepAdvance
                }
                name="stepAdvance"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Step Manual')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                // checked={leads.delete}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.leads &&
                  formData.permissions.leads.delete
                }
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionPlayers() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionPlayers">
          {this.translate('Players')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionPlayers"
          />
        </label>
        <ReactTooltip
          id="permissionPlayers"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="export"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.players &&
                  formData.permissions.players.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionConfigurations() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionConfigurations">
          {this.translate('Configurações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionConfigurations"
          />
        </label>
        <ReactTooltip
          id="permissionConfigurations"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={e => this.handlePermissionInput(e, 'configurations')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.configurations &&
                  formData.permissions.configurations.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label> */}
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionInput(e, 'configurations')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.configurations &&
                  formData.permissions.configurations.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionTermsOfUse() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionTermsOfUse">
          {this.translate('Termos de uso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionTermsOfUse"
          />
        </label>
        <ReactTooltip
          id="permissionTermsOfUse"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.termsOfUse &&
                  formData.permissions.generalConditions.termsOfUse.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionFaq() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionFaq">
          {this.translate('FAQ')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionFaq"
          />
        </label>
        <ReactTooltip id="permissionFaq" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionHowItWorks() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionHowItWorks">
          {this.translate('Como Funciona')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionHowItWorks"
          />
        </label>
        <ReactTooltip
          id="permissionHowItWorks"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.howItWorks &&
                  formData.permissions.generalConditions.howItWorks.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGeneralConditions() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Condições Gerais
        </h5>
        {this.renderPermissionTermsOfUse()}
        {this.renderPermissionFaq()}
        {this.renderPermissionHowItWorks()}
      </>
    );
  }

  renderPermissionWelcomeEmail() {
    const { formData } = this.state;

    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionWelcomeEmail">
          {this.translate('E-mail de Boas Vindas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionWelcomeEmail"
          />
        </label>
        <ReactTooltip
          id="permissionWelcomeEmail"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.welcomeEmail &&
                  formData.permissions.welcomeEmail.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionsGroup() {
    const { clientData } = this.context;
    const { formData, phase1Uncompleted } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Permissões do grupo
          {/* {phase1Uncompleted &&
            this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          {this.renderPermissionDashboard()}
          {this.renderPermissionCompany()}
          {this.renderPermissionAccessGroups()}
          {this.renderPermissionUsers()}
          {this.renderPermissionLandingPage()}
          {this.renderPermissionGamification()}
          {this.renderPermissionReedemAwards()}
          {this.renderPermissionActions()}
          {this.renderPermissionExtraPoints()}
          {this.renderPermissionComunication()}
          {this.renderPermissionBenefits()}
          {this.renderPermissionLeadsConversions()}
          {this.renderPermissionPlayers()}
          {this.renderPermissionConfigurations()}
          {this.renderPermissionGeneralConditions()}
          {this.renderPermissionWelcomeEmail()}
          {clientData &&
            clientData.isGamified &&
            clientData.isGamified === 'true' &&
            this.renderPermissionSeasons()}
        </div>
        <hr />
      </div>
    );
  }

  renderUsersGroup() {
    const { loadingUsersList, usersList } = this.state;

    const copyUsersList = usersList && [...usersList];
    const filterUsersNoMaster =
      copyUsersList && copyUsersList.filter((user) => !user.master);

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Usuários do grupo
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="usersGroup">
              Usuários
            </label>
            <Select
              isMulti
              name="usersGroup"
              placeholder="Selecione os usuários..."
              isDisabled={loadingUsersList}
              options={_.map(filterUsersNoMaster, (user) => {
                return {
                  value: user.uid,
                  label: user.name,
                };
              })}
              isLoading={loadingUsersList}
              className="basic-multi-select"
              value={this.state.formData.usersGroup}
              onChange={(e, context) =>
                this.handleFormDataChange('usersGroup', e, context)
              }
              classNamePrefix="select"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  updateAccessGroup() {
    const { formData } = this.state;
    const { clientData, userData, getUserData } = this.context;
    const clientId = clientData.uid;

    this.setState({ updatingAccessGroup: true }, () => {
      this.toastId = toast.info('Atualizando grupo. Aguarde...', {
        autoClose: false,
      });

      const parameters = {
        userType: 'client',
        searchFunctionality: 'updateAccessGroup',
        accessGroupData: formData,
        clientId: clientId ? clientId : '',
      };

      api
        .post(`/accessGroup`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            this.setState({ updatingAccessGroup: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              // this.setState(this.getInitialState());
              this.setState(
                {
                  isLoading: false,
                  formData: res.data.data,
                },
                async () => {
                  this.validateForm();
                  await getUserData(userData.uid);
                }
              );
            });
          } else {
            this.setState({ updatingAccessGroup: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({ updatingAccessGroup: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar o grupo de acesso!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('updateAccessGroup error:', error);
        });
    });
  }

  deleteSelectedGroup() {
    const { userData } = this.context;
    const clientId = userData.clientId;
    const groupId = this.state.formData.id;
    this.setState({ deletingAction: true }, () => {
      this.toastId = toast.info('Excluindo grupo. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deleteGroup',
        userType: 'client',
        groupId,
        clientId: clientId ? clientId : '',
      };

      api
        .post(`/accessGroup`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            Notify('Grupo excluido', 'success');
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          this.setState({ deletingAction: false });
          console.log('deleteSelectedGroup error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.updateAccessGroup();
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderInformationsGroup()}
        {this.renderPermissionsGroup()}
        {this.renderUsersGroup()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={this.state.updatingAccessGroup}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
        {this.renderRemoveGroup()}
      </div>
    );
  }

  renderRemoveGroup() {
    const { groupPermissions, userData } = this.context;
    return (
      <div className="col-12 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            (groupPermissions &&
              groupPermissions.permissions &&
              groupPermissions.permissions.accessGroup &&
              groupPermissions.permissions.accessGroup.delete) ||
            (userData && userData.master && userData.master)
              ? this.toggleModalDeleteAccessGroup()
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir grupo')}
        </span>
        {this.deleteGroup()}
      </div>
    );
  }

  toggleModalDeleteAccessGroup() {
    this.setState({
      modalDeleteEditAccessGroupIsOpen:
        !this.state.modalDeleteEditAccessGroupIsOpen,
    });
  }

  deleteGroup() {
    return (
      <ModalDeleteEditAccessGroup
        open={this.state.modalDeleteEditAccessGroupIsOpen}
        close={this.toggleModalDeleteAccessGroup.bind(this)}
        deleteSelectedGroup={this.deleteSelectedGroup.bind(this)}
      />
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Editar Grupo')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAccessGroup;
