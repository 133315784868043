import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import { auth } from '../../../firebase';

import secureImage from '../../../Assets/Images/secure.png';
import { UserDataContext } from '../../../hooks/useUserData';

class WithoutPermission extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {
      currentUser: auth.getAuthUser(),
      authUser: {},
    };
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="oq-permission-wrapper">
              <div className="oq-permission-content">
                <img src={secureImage} alt="" />
                <p>
                  Desculpe, seu plano atual não inclui acesso a este recurso.
                  <br />
                  Para explorar ou ativar esta função, por favor, entre em
                  contato com nossa equipe.
                </p>
                <button onClick={() => this.props.history.goBack()}>
                  Voltar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WithoutPermission;
