import React from 'react';
import Modal from 'react-responsive-modal';

function ModalShowIncompletFormDialog(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">Formulário Incompleto</h2>
        <p>Você deve preencher todos os campos antes de atualizar as regras.</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button type="button" className="oq-btn" onClick={props.close}>
          Concluir
        </button>
      </div>
    </Modal>
  );
}

export default ModalShowIncompletFormDialog;
