import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import ReactTable from 'react-table';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class ClientsPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      clients: [],
      countClients: 0,
      isLoading: false,
      loadingStateList: false,
      loadingCityList: false,
      stateList: [],
      cityList: [],
      searchObject: {
        companyName: null,
        companyInscription: null,
        accountableName: null,
        city: null,
        state: null,
      },
    };

    return initialState;
  }

  componentDidMount() {
    this.getStateList();
    // this.getClients();
  }

  getClients() {
    const parameters = {
      searchFunctionality: 'allClients',
      userType: 'administrator',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          this.setState({ isLoading: false }, () => {
            Notify(result.message, result.success ? 'success' : 'error');
          });
          return;
        }

        this.setState(
          {
            isLoading: false,
            countClients: result.data.clientsCount,
            clients: result.data.clients,
          },
          Notify(result.message, result.success ? 'success' : 'error')
        );
      })
      .catch((error) => {
        console.log('getClients error:', error);
      });
  }

  getCityList(estado = 'MG') {
    this.setState({ loadingCityList: true }, () => {
      axios
        .get(`https://spotless-unicorn.glitch.me/estados/${estado}/cidades`)
        .then((city) => {
          this.setState({ cityList: city.data, loadingCityList: false });
        })
        .catch((error) => {
          this.setState({ loadingCityList: false });
          console.log('getCityList error:', error);
        });
    });
  }

  getStateList() {
    this.setState({ loadingStateList: true }, () => {
      axios
        .get('https://spotless-unicorn.glitch.me/estados')
        .then((states) => {
          this.setState({ stateList: states.data, loadingStateList: false });
        })
        .catch((error) => {
          this.setState({ loadingStateList: false });
          console.log('getStateList error:', error);
        });
    });
  }

  filterClients() {
    const { searchObject } = this.state;
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'filterClients',
        userType: 'administrator',
        searchObject,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                countClients: 0,
                clients: [],
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }
          this.setState(
            {
              isLoading: false,
              countClients: result.data.clientsCount,
              clients: result.data.clients,
            },
            Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('filterClients error:', error);
        });
    });
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  exportClients() {
    const { clients } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(clients, (client) => {
        return {
          Nome: client.companyName,
          Responsável: client.accountableName,
          'E-mail': client.accountableEmail,
          City: client.city,
          URL: client.clientUrl,
          Moeda: client.coinName ? client.coinName : 'Sem Gamification',
          Players: client.amountOfPlayers,
          Ações: client.amountOfActions,
          Conversões: client.convertions,
          Leads: client.leads,
          Pageviews: client.pageViews,
          Telefone: client.phoneNumber,
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Clientes');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Clientes.xlsx'
    );
  }

  goToEditClient(clientId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_CLIENT}/${clientId}/`);
  }

  goToNewClientScreen() {
    const { history } = this.props;
    history.push(routes.CREATE_CLIENT);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  renderUpdateButton() {
    if (!this.state.isLoading) {
      return (
        <div>
          <div className="col-md-4 text-center p-2-top p-2-bottom">
            <button
              className="btn-oq btn-block text-center"
              onClick={() => this.getClients()}
            >
              <i className="fa fa-refresh fa-padding" aria-hidden="true" />
              <span>Atualizar</span>
            </button>
          </div>
        </div>
      );
    }
  }

  renderNewClientButton() {
    if (!this.state.isLoading) {
      return (
        <div>
          <div className="col-md-12 oq-padding-top">
            <div className="form-group col-md-4">
              <button
                onClick={() => this.goToNewClientScreen()}
                type="button"
                className="btn btn-block btn-oq btn-oq-lg"
              >
                <i className="fa fa-plus icon-small" aria-hidden="true" />
                &nbsp; Novo Cliente
              </button>
            </div>
          </div>
        </div>
      );
    }
  }

  renderSearchButton() {
    return (
      <div>
        <div className="col-md-4 text-center p-2-top p-2-bottom">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.isLoading}
            onClick={() => this.filterClients()}
          >
            <i className="fa fa-search fa-padding" aria-hidden="true" />
            <span>{this.state.isLoading ? 'Pesquisando...' : 'Pesquisar'}</span>
          </button>
        </div>
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; Filtros Avançados
            </span>
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isLoading}
                  onChange={(event) => this.handleSearchChange(event)}
                  name="companyName"
                  placeholder="Nome"
                />
              </div>

              <div className="form-group col-md-4">
                <select
                  className="form-control"
                  disabled={this.state.loadingStateList || this.state.isLoading}
                  onChange={(event) => {
                    this.handleSearchChange(event);
                    this.getCityList(event.target.value);
                  }}
                  id="state"
                  name="state"
                  aria-describedby="stateHelp"
                >
                  <option value="">
                    {this.state.loadingStateList
                      ? 'Carregando...'
                      : 'Selecione um estado...'}
                  </option>
                  {this.state.stateList &&
                    _.map(this.state.stateList, (state) => {
                      return <option value={state.id}>{state.estado}</option>;
                    })}
                </select>
              </div>

              <div className="form-group col-md-4">
                <select
                  className="form-control"
                  disabled={
                    this.state.loadingCityList ||
                    !this.state.searchObject.state ||
                    this.state.isLoading
                  }
                  onChange={(event) => this.handleSearchChange(event)}
                  id="city"
                  name="city"
                  aria-describedby="cityHelp"
                >
                  <option value="">
                    {this.state.loadingCityList
                      ? 'Carregando ...'
                      : 'Selecione uma cidade...'}
                  </option>
                  {this.state.cityList &&
                    _.map(this.state.cityList, (city) => {
                      return <option value={city.cidade}>{city.cidade}</option>;
                    })}
                </select>
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="companyInscription"
                  disabled={this.state.isLoading}
                  onChange={(event) => this.handleSearchChange(event)}
                  placeholder="CNPJ"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  onChange={(event) => this.handleSearchChange(event)}
                  name="accountableName"
                  disabled={this.state.isLoading}
                  className="form-control"
                  placeholder="Nome do responsável"
                />
              </div>
              {this.renderSearchButton()}
            </div>
          </div>
        </div>
        {this.renderNewClientButton()}
      </div>
    );
  }

  renderClientsTable() {
    const { clients } = this.state;

    const columns = [
      {
        Header: 'Nome',
        accessor: 'companyName',
      },
      {
        Header: 'Responsável',
        accessor: 'accountableName',
      },
      {
        Header: 'E-mail',
        accessor: 'accountableEmail',
      },
      {
        Header: 'Pageviews',
        accessor: 'pageViews',
      },
      {
        Header: 'Leads',
        accessor: 'leads',
      },
      {
        Header: 'Conversões',
        accessor: 'convertions',
      },
      {
        id: 'editClient',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <Link
              to={`${routes.EDIT_CLIENT}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              Editar Cliente
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-user" />
            &nbsp; Lista de clientes
          </span>

          <button
            type="button"
            className="btn btn-oq pull-right"
            onClick={() => this.exportClients()}
            disabled={_.size(this.state.clients) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={_.values(clients)}
              defaultPageSize={5}
              loading={this.state.isLoading}
              columns={columns}
              previousText="prev"
              nextText="next"
              noDataText="Nenhum cliente encontrado"
              pageText="page"
              ofText="of"
              rowsText="lines"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          {this.renderSearchFields()}
          <hr />
          <hr />
          {this.renderClientsTable()}
        </div>
      </div>
    );
  }
}

const ClientsPageLink = () => (
  <Link className="menu-link" to={routes.CLIENTS_ADMIN}>
    <FontAwesomeIcon icon={faUsers} />
    <span>Clientes</span>
  </Link>
);

export default ClientsPage;

export { ClientsPageLink };
