import React from 'react';
import SideBar from '../../../components/SideBar';
import NavBar from '../../../components/NavBar';

import mcmvImage from '../../../Images/Image_1.png';
import podeEntrarImage from '../../../Images/Image_2.png';
import fgtsImage from '../../../Images/Image_3.png';
import cvaImage from '../../../Images/Image_4.png';

export function Business() {
  return (
    <>
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="container">
              <form>
                <div className="row">
                  <div className="col-md-6">
                    <b>
                      <h3>Informações Gerais</h3>
                    </b>
                    <br />
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        Nome do empreendimento
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Nome"
                      />
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">Cidade</label>
                          <select class="form-control" id="exampleInputEmail1">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">Estado</label>
                          <select class="form-control" id="exampleInputEmail1">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label for="exampleInputEmail1">Região</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Região"
                      />
                    </div>
                    <div className="form-group">
                      <label for="exampleInputEmail1">
                        Diferenciais/Descrição/Copy
                      </label>
                      <textarea class="form-control" rows="3"></textarea>
                    </div>
                    <h4>Condições de pagamento</h4>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Sim
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Não
                              </label>
                            </div>
                          </div>
                          <img
                            src={mcmvImage}
                            className="ms-3 iconsLogos"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Sim
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Não
                              </label>
                            </div>
                          </div>
                          <img
                            src={cvaImage}
                            className="ms-3 iconsLogos"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Sim
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Não
                              </label>
                            </div>
                          </div>
                          <img
                            src={fgtsImage}
                            className="ms-3 iconsLogos"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex align-items-center">
                          <div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Sim
                              </label>
                            </div>
                            <div class="checkbox">
                              <label>
                                <input type="checkbox" /> Não
                              </label>
                            </div>
                          </div>
                          <img
                            src={podeEntrarImage}
                            className="ms-3 iconsLogos"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            Subsídio de até
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            placeholder="R$ 1.000"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="exampleInputEmail1">
                            Renda familiar a partir de
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            placeholder="R$ 1.000"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <span
                      data-toggle="collapse"
                      href="#collapseExample"
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 className="me-3">Detalhes do empreendimento</h3>
                        <i className="material-icons">add</i>
                      </div>
                    </span>
                    <br />
                    <div class="collapse" id="collapseExample">
                      <br />
                      <h4>Condições de pagamento</h4>
                      <br />
                      <div className="form-group">
                        <label for="exampleInputEmail1">Link Google Maps</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Link Google Maps"
                        />
                      </div>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Região</label>
                        <input
                          type="text"
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="Região. Ex.: Zona Norte"
                        />
                      </div>
                      <div className="form-group">
                        <label for="exampleInputEmail1">
                          Diferenciais (até 200 caracteres)
                        </label>
                        <textarea class="form-control" rows="3"></textarea>
                      </div>
                      <h4>O apartamento</h4>
                      <br />
                      <div className="form-group">
                        <label for="exampleInputEmail1">A partir de M²</label>
                        <input
                          type="number"
                          class="form-control"
                          id="exampleInputEmail1"
                          placeholder="30"
                        />
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <div class="checkbox me-3">
                              <label>
                                <input type="checkbox" /> Quartos
                              </label>
                            </div>
                            <div className="form-group m-0">
                              <select
                                class="form-control"
                                id="exampleInputEmail1"
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <div class="checkbox me-3">
                              <label>
                                <input type="checkbox" /> Suítes
                              </label>
                            </div>
                            <div className="form-group m-0">
                              <select
                                class="form-control"
                                id="exampleInputEmail1"
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <div class="checkbox me-3">
                              <label>
                                <input type="checkbox" /> Garagem
                              </label>
                            </div>
                            <div className="form-group m-0">
                              <select
                                class="form-control"
                                id="exampleInputEmail1"
                              >
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Condomínio fechado
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Acabamento diferenciado
                        </label>
                      </div>
                      <br />
                      <h4>A vizinhança</h4>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Supermercado
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Sacolão
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Academia
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Escola
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Metrô
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Ponto de ônibus
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Farmácia
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Hospital
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Creche
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Delegacia
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Petshop
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Fácil de acesso
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="exampleInputEmail1">Outros</label>
                            <textarea
                              class="form-control"
                              placeholder="Rua de fácil acesso, localização privilegiada e sem trânsito."
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h4>O Condomínio</h4>
                      <br />
                      <div className="row">
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Piscina
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Salão de festas
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Churrasqueira
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Garagem coberta
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Academia
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Minimercado
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> PetPoint
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Espaço Kids
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Quadra
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Sauna
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Coworking
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Lavanderia
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Bicicletário
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Portaria 24 horas
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Churrasqueira
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Garagem coberta
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Salão de beleza
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div class="checkbox">
                            <label>
                              <input type="checkbox" /> Espaço multiuso
                            </label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="exampleInputEmail1">Outros</label>
                            <textarea
                              class="form-control"
                              placeholder="Rua de fácil acesso, localização privilegiada e sem trânsito."
                              rows="3"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <br />
                      <h4>Estágio</h4>
                      <br />
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Na planta
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Breve lançamento
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Lançamento
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Em Obras
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Obras avançadas
                        </label>
                      </div>
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" /> Pronto para morar
                        </label>
                      </div>
                      <div className="form-group">
                        <label for="exampleInputEmail1">Outros</label>
                        <textarea
                          class="form-control"
                          placeholder="Rua de fácil acesso, localização privilegiada e sem trânsito."
                          rows="3"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h3>Medias e links</h3>
                    <br />
                    <h4>Imagens do apartamento</h4>
                    <br />
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                    </div>
                    <h4>Imagens do condomínio</h4>
                    <br />
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                      <div className="col-md-3 mb-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                    </div>

                    <h4>Imagem da planta</h4>
                    <br />
                    <div className="row">
                      <div className="col-md-3">
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/11919/11919093.png"
                          className="img-responsive"
                          alt=""
                        />
                      </div>
                    </div>
                    <br />
                    <h4>Link do vídeo</h4>
                    <br />
                    <div className="form-group">
                      <label for="exampleInputEmail1">URL do vídeo</label>
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        placeholder="Vídeo do Youtube, Media, etc..."
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
