import { Notify } from '../../../../../../components/Notify';
import '../styles.css';
import React, { useEffect, useState } from 'react';

export function Widget({ action, client }) {
  const [scriptCapture, setScriptCapture] = useState('oap');
  const [scriptRegister, setScriptRegister] = useState('oap2');

  useEffect(() => {
    let scriptRegisterContent = `<iframe style="width: 100%; height: 430px" src="https://oqdigital.com/iframe-register/?player={playerID}action={actionID}&theme=light&client=${client.uid}" frameborder="0" ></iframe>`;
    let scriptCaptureContent = `<iframe style="width: 100%; height: 430px" src="https://oqdigital.com/iframe/?player={playerID}action={actionID}&theme=light&client=${client.uid}" frameborder="0" ></iframe>`;
    setScriptCapture(scriptCaptureContent);
    setScriptRegister(scriptRegisterContent);
  }, []);

  return (
    <>
      <h4>Escale seu programa sem qualquer fricção!</h4>
      <br />
      <p>
        Integre o formulário de cadastro de usuários da Plataforma diretamente
        no site ou na LP do seu programa. Automatize o processo e ganhe novos
        usuário com apenas clique.
      </p>
      <br />
      <h4>Widget de captura de Leads</h4>
      <br />
      <div className="form-group">
        <textarea
          className="form-control"
          readOnly
          rows="4"
          value={scriptCapture}
        ></textarea>
        <button
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(scriptCapture);
            Notify('Código copiado com sucesso!', 'success');
          }}
        >
          Copiar código
        </button>
      </div>
      <h4>Widget de registro de usuários</h4>
      <br />
      <div className="form-group">
        <textarea
          className="form-control"
          readOnly
          rows="4"
          value={scriptRegister}
        ></textarea>
        <button
          className="btn"
          onClick={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText(scriptRegister);
            Notify('Código copiado com sucesso!', 'success');
          }}
        >
          Copiar código
        </button>
      </div>
    </>
  );
}
