import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { storage } from '../../../firebase';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactJson from 'react-json-view';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';
import { api, getAPIURL } from '../../../Config';

import ModalBeforeUpdateAuthorizedUsers from '../../components/Modals/ConfigModals/BeforeUpdateAuthorizedUsers';
import ModalBeforeUploadInfluencers from '../../components/Modals/ConfigModals/BeforeUploadInfluencers';
import ModalBeforeUploadRoles from '../../components/Modals/ConfigModals/BeforeUploadRoles';
import ModalBeforeUploadVoucher from '../../components/Modals/ConfigModals/BeforeUploadVoucher';
import ModalUpdateAuthorizedUsers from '../../components/Modals/ConfigModals/UpdateAuthorizedUsers';
import ModalUpdateRoles from '../../components/Modals/ConfigModals/UpdateRoles';
import ModalUpdateVouchers from '../../components/Modals/ConfigModals/UpdateVouchers';
import ModalUploadInfluencers from '../../components/Modals/ConfigModals/UploadInfluencers';

import { Notify, NotifyUpdate } from '../../../components/Notify';
import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import loginDesktop from '../../Images/login-d.png';
import loginMobile from '../../Images/login-m.png';
import { UserDataContext } from '../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../utils/normalizeLowerCase';

class Config extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.toastId = null;
    this.fileSelector = null;
    this.inputVoucher = null;
    this.inputInfluencers = null;

    this.state = {
      startOfMonth: null,
      endOfMonth: null,
      modalBeforeUpdateAuthorizedUsersIsOpen: false,
      modalBeforeUploadVoucherIsOpen: false,
      modalBeforeUploadRolesIsOpen: false,
      modalUpdateAuthorizedUsersIsOpen: false,
      modalBeforeUploadInfluencersIsOpen: false,
      modalUploadInfluencersIsOpen: false,
      modalUpdateVouchersIsOpen: false,
      modalUpdateRolesIsOpen: false,
      clientId: '',
      updating: false,
      formValid: true,
      formErrors: {},
      loadingSeasons: false,
      countSeasons: 0,
      tableRoles: [],
      awards: {},
      loadingNewToken: false,
      fields: [],
      selects: [],
      loadingClientCustomForm: false,
      search: '',
      seasons: {},
      videosTutorialDefault:
        'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/completo.mp4?alt=media&token=1ef9b918-7b25-44d3-a61c-62eccb1268b5',
      formData: {
        mgmVinculated: 'false',
        pointAppIsActive: 'false',
        isActivePointsStepOne: 'false',
        isActiveRegisterButton: 'false',
        pointsDownloadpwa: 0,
        videoTurorialUrlProspecto: '',
        videoTurorialUrlHost: '',
        activePlatformAwards: 'false',
        integration: {
          customLead: {
            name: 'customLead',
            crmSelected: '',
            active: 'false',
            configuration: {
              url: '',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            },
            paypal: {
              active: 'false',
              clientId: '',
              secret: '',
            },
            parameters: {},
          },
        },
        isFilterDashboard: false,
        segmentationChoiceVideoTutorial: 'false',
        usersGroup: '',
        urlVideoLogin: '',
        redirectUrlLead: 'false',
        urlRedirect: '',
        isActiveIara: 'false',
        isActiveWhatsapp: 'false',
        numberOfDaysToRedeem: 0,
        sessionsEnable: 'false',
        enableStepManual: 'false',
        useTutorial: 'true',
        redirectWhatsapp: 'false',
        phoneEnable: 'false',
        regional: [],
        vouchers: [],
        goal: [],
        premiumOfGoals: [],
        benefitLogo: '',
        regionalBenefitName: [],
        regionalBenefit: [],
        segmentationChoice: 'false',
        sendMessageClient: '',
        scopeFieldName: props.t('Unidade'),
        systemFont: 'Gotham Rounded',
        readTerms: '',
        token: '',
        scaleEnable: 'false',
        scaleStartDate: '',
        scaleEndDate: '',
        scaleDescription: '',
        scalePreset: '',
        scaleCurrent: '',
        steps: [],
        season: {},
        roles: [],
        loginEmail: 'true',
        counterNames: {},
        sendEmail: 'true',
        customFormLead: {
          cpf: 'true',
          phone: 'true',
          email: 'true',
          cityAndState: 'true',
        },
        wellcomeText: '',
        useIntegration: '',
        exchangeWindow: 'true',
        useRegionalizationBenefit: 'true',
        scaleEnableRegional: 'false',
        scaleRegional: [],
        indicationEnable: 'false',
        localizationEnable: 'false',
        amountOfInputs: 0,
        amountOfSelects: {},
        enableIconsShareDashboard: false,
        showCTAIndication: false,
        iconsShareDashboard: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'false',
          copyLink: 'true',
          telegram: 'false',
          linkedin: 'false',
          instagram: 'true',
          emailDashboard: 'false',
          messenger: 'false',
        },
        useTutorialResults: 'false',
        videoTutorials: [
          {
            label: 'Como Ganhar',
            name: 'howToWin',
          },
          {
            label: 'Como Resgatar',
            name: 'howtoRedeem',
          },
          {
            label: 'Depoimentos',
            name: 'testimonials',
          },
        ],
        enableRankingRange: 'false',
        startRankingInterval: '',
        endRankingInterval: '',
      },
    };
    this.handleConverted = this.handleConverted.bind(this);

    this.translate = props.t;
  }

  componentDidMount() {
    const month = new Date().getMonth();
    const startOfMonth = moment().startOf('month').month(month).valueOf();
    const endOfMonth = moment().endOf('month').month(month).valueOf();
    this.setState({ startOfMonth, endOfMonth });
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.getClientByUid(clientId);
    this.fileSelector = this.buildFileSelector();
    this.inputVoucher = this.buildVoucherInput();
    this.inputRoles = this.buildRolesInput();
    this.inputInfluencers = this.buildInfluencerInput();

    this.loadClientCustomForm();
    this.getAwards();

    if (
      this.context.clientData &&
      this.context.clientData.isGamified !== 'false'
    ) {
      this.searchSeasons();
    }
  }

  searchSeasons() {
    this.setState({ loadingSeasons: true }, () => {
      const parameters = {
        searchFunctionality: 'filterSeasons',
        userType: 'client',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.data.seasons && result.data.countSeasons) {
            this.setState({
              seasons: result.data.seasons,
              countSeasons: result.data.countSeasons,
              loadingSeasons: false,
            });
          }
          this.setState({ loadingSeasons: false });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  getAwards() {
    const search = {};
    const seasonId = false;
    this.setState({ loadingGetAwards: true }, () => {
      const parameters = {
        userType: 'client',
        searchFunctionality: 'searchPremium',
        seasonId,
        search,
      };

      api
        .post(`/search`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({
            loadingGetAwards: false,
            awards: result.data ? result.data : [],
          });

          // if (result.data.length > 0 && result.success === false) {
          //   this.listWrongUsers();
          // }
          // Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateVoucher error: ', error);
          this.setState({ loadingGetAwards: false });
          Notify(error.message, error.success ? 'success' : 'error');
        });
    });
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;

    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
  }

  buildVoucherInput() {
    const voucherInput = document.createElement('input');
    voucherInput.setAttribute('type', 'file');
    voucherInput.setAttribute('className', 'hidden');
    voucherInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    voucherInput.onchange = (e) => this.fileChanged(e.target.files, 'voucher');
    return voucherInput;
  }

  buildInfluencerInput() {
    const inputInfluencers = document.createElement('input');
    inputInfluencers.setAttribute('type', 'file');
    inputInfluencers.setAttribute('className', 'hidden');
    inputInfluencers.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    inputInfluencers.onchange = (e) =>
      this.fileChanged(e.target.files, 'influencers');
    return inputInfluencers;
  }

  buildRolesInput() {
    const voucherInput = document.createElement('input');
    voucherInput.setAttribute('type', 'file');
    voucherInput.setAttribute('className', 'hidden');
    voucherInput.setAttribute(
      'accept',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    voucherInput.onchange = (e) => this.fileChanged(e.target.files, 'roles');
    return voucherInput;
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('name', 'authorizedUsers');
    fileSelector.onchange = (e) =>
      this.fileChanged(e.target.files, 'authorizedUsers');
    return fileSelector;
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();

      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      } else {
        Notify(
          'Formato de arquivo inválido. Permitido apenas xls/xlsx.',
          'error'
        );
      }
    }
  };

  parseFile(file, field) {
    if (field === 'regional') {
      this.setState({ parsingRegionals: true });
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: true });
    } else if (field === 'role') {
      this.setState((prevState) => ({
        loadingAuthorizedUsers: true,
        formData: {
          ...prevState.formData,
          roles: file.name,
        },
      }));
    } else if (field === 'voucher') {
      if (
        this.context.clientData &&
        this.context.clientData.isGamified === 'false'
      ) {
        this.handleFormDataChange('season', false);
      }
      this.setState({ parsingVouchers: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          vouchers: [file.name],
        },
      }));
    } else if (field === 'influencers') {
      this.setState({ parsingInfluencers: true });
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          influencers: [file.name],
        },
      }));
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });

      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });

        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);

      this.setState({
        tableRoles: result,
      });
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'authorizedUsers') {
          return {
            name: item[0],
            cpf: item[1],
            clientId,
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'regional') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, items);
    } else if (field === 'voucher') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, xlsxParsed);
      this.toggleModalBeforeUploadVoucher();
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: false, authorizedUsers: items });
      this.toggleModalBeforeUpdateAuthorizedUsers();
    } else if (field === 'roles') {
      this.setState({ loadingAuthorizedUsers: false });
      this.toggleModalBeforeUploadRoles();
    } else if (field === 'influencers') {
      this.setState({ parsingInfluencers: false });
      this.toggleModalBeforeUploadInfluencers();
    }
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }

  toggleModalBeforeUpdateAuthorizedUsers() {
    this.setState({
      modalBeforeUpdateAuthorizedUsersIsOpen:
        !this.state.modalBeforeUpdateAuthorizedUsersIsOpen,
    });
  }

  confirmBeforeUpdateAuthorizedUsers() {
    return (
      <ModalBeforeUpdateAuthorizedUsers
        open={this.state.modalBeforeUpdateAuthorizedUsersIsOpen}
        close={this.toggleModalBeforeUpdateAuthorizedUsers.bind(this)}
        updateAuthorizedUsers={this.updateAuthorizedUsers.bind(this)}
      />
    );
  }

  toggleModalBeforeUploadVoucher() {
    this.setState({
      modalBeforeUploadVoucherIsOpen:
        !this.state.modalBeforeUploadVoucherIsOpen,
    });
  }

  confirmBeforeUploadVoucher() {
    return (
      <ModalBeforeUploadVoucher
        open={this.state.modalBeforeUploadVoucherIsOpen}
        close={this.toggleModalBeforeUploadVoucher.bind(this)}
        uploadVouchers={this.uploadVouchers.bind(this)}
      />
    );
  }

  toggleModalBeforeUploadInfluencers() {
    this.setState({
      modalBeforeUploadInfluencersIsOpen:
        !this.state.modalBeforeUploadInfluencersIsOpen,
    });
  }

  confirmBeforeUploadInfluencers() {
    return (
      <ModalBeforeUploadInfluencers
        open={this.state.modalBeforeUploadInfluencersIsOpen}
        close={this.toggleModalBeforeUploadInfluencers.bind(this)}
        uploadInfluencers={this.uploadInfluencers.bind(this)}
      />
    );
  }

  toggleModalBeforeUploadRoles() {
    this.setState({
      modalBeforeUploadRolesIsOpen: !this.state.modalBeforeUploadRolesIsOpen,
    });
  }

  confirmBeforeUploadRoles() {
    return (
      <ModalBeforeUploadRoles
        open={this.state.modalBeforeUploadRolesIsOpen}
        close={this.toggleModalBeforeUploadRoles.bind(this)}
        uploadRoles={this.uploadRoles.bind(this)}
      />
    );
  }

  uploadVouchers() {
    const client = this.state.formData;
    Notify('Fazendo upload de vouchers...', 'loading');
    this.setState({ loadingUploadVoucher: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'uploadVouchers',
        client,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({
            loadingUploadVoucher: false,
            wrongUsers: result.data ? result.data : [],
          });
          // if (result.data.length > 0 && result.success === false) {
          //   this.listWrongUsers();
          // }
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateVoucher error: ', error);
          this.setState({ loadingUploadVoucher: false });
          Notify(error.message, error.success ? 'success' : 'error');
        });
    });
  }

  updateAuthorizedUsers() {
    const { authorizedUsers } = this.state;
    Notify('Salvando usuários autorizados...', 'loading');
    this.setState({ loadingAuthorizedUsers: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'updateAuthorizedUsers',
        authorizedUsers,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({
            loadingAuthorizedUsers: false,
            wrongUsers: result.data ? result.data : [],
          });
          // if (result.data.length > 0 && result.success === false) {
          //   this.listWrongUsers();
          // }
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateAuthorizedUsers error:', error);
          this.setState({ loadingAuthorizedUsers: false });
          Notify(error.message, error.success ? 'success' : 'error');
        });
    });
  }

  // listWrongUsers() {
  //   const { wrongUsers } = this.state;
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="oq-confirm-modal">
  //           <h4 className="oq-font-medium">
  //             Lista de CPF atualizada, porém {wrongUsers.length} destes não
  //             foram cadastrados devido o CPF estar no formato errado! Abaixo a
  //             relação deste(s):
  //           </h4>
  //           {_.map(wrongUsers, user => {
  //             return (
  //               <p className="oq-font-book" key={user.cpf}>
  //                 <b>Nome: </b>
  //                 {user.name}
  //                 <b> - CPF: </b>
  //                 {user.cpf}
  //               </p>
  //             );
  //           })}
  //           <div>
  //             <button
  //               className="btn btn-oq-black btn-oq-lg oq-margin-right"
  //               onClick={() => onClose()}
  //             >
  //               FECHAR
  //             </button>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // }

  getClientByUid(clientId) {
    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'administrator',
      clientId,
    };

    // busca cliente
    api
      .post(`/search`, parameters)
      .then(async (res) => {
        const parameters = {
          searchFunctionality: 'allActions',
          userType: 'client',
        };

        const result = await api.post(`/search`, parameters);
        const { actions } = result.data.data;

        await this.getGroupsClient();

        this.setState(
          {
            isLoading: false,
            clientId,
            actions,
            formData: {
              ...this.state.formData,
              ...res.data.data,
              redirectWhatsapp:
                res.data && res.data.data && res.data.data.redirectWhatsapp
                  ? 'false'
                  : '',
              regionalBenefit:
                res.data.data.clientUrl === 'perplan'
                  ? [
                      'Ribeirão Preto',
                      'Franca',
                      'Piracicaba',
                      'Presidente Prudente',
                      'Mococa',
                      'Pirassununga',
                      'Uberlândia',
                      'Monte Alto',
                    ]
                  : res.data.data.regionalBenefit
                    ? res.data.data.regionalBenefit
                    : [],
            },
          },
          () => {
            Notify(res.data.message, res.data.success ? 'success' : 'error');
            // this.validateForm();
          }
        );
      })
      .catch((error) => {
        console.log('getClientByUid error:', error);
      });
  }

  getGroupsClient() {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getGroupsClient',
        userType: 'client',
        searchObject: {},
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState({
              isLoading: false,
              groups: [],
            });
            return;
          }
          this.setState({
            isLoading: false,
            groups: result.groups,
          });
        })
        .catch((error) => {
          console.log('getGroupsClient error:', error);
          this.setState({
            isLoading: false,
            groups: [],
          });
        });
    });
  }

  handleDateChange(e) {
    const { name, value } = e.target;

    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epoch = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epoch,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleDateChangeRegional(e, index) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epoch = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          scaleRegional: {
            ...prevState.formData.scaleRegional,
            [index]: {
              ...prevState.formData.scaleRegional[index],
              [name]: value,
              [`${name}Epoch`]: epoch,
            },
          },
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleIsFilterDashboard(e) {
    const { name, value } = e.target;

    if (this.state.formData.isFilterDashboard) {
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            isFilterDashboard: false,
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            isFilterDashboard: true,
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    }
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    if (name === 'scaleEnable' && value === 'false') {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          scaleStartDate: '',
          scaleEndDate: '',
          scaleDescription: '',
          scalePreset: '',
          scaleCurrent: '',
        },
      }));
    } else if (name === 'playerRegisterPoints') {
      return this.handleFormDataChange(name, Number(value));
    } else if (name === 'numberOfDaysToRedeem') {
      return this.handleFormDataChange(name, Number(value));
    }

    this.handleFormDataChange(name, value);
  }

  handleAmountSelect(e, indexField) {
    const { name, value } = e.target;

    this.handleFormAmountSelectChange(name, value, indexField);
  }

  handleUserSelect(e) {
    const { name, value } = e.target;

    this.handleFormDataChange(name, value);
  }

  handleFieldsForGoals(e, index) {
    const { name, value } = e.target;
    const newValue = value.split(',');

    this.changeFieldsSelectGoals(name, newValue[0], newValue[1], index);
  }

  changeFieldsSelectGoals(name, id, price, index) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        premiumOfGoals: {
          ...prevState.formData.premiumOfGoals,
          [index]: {
            ...prevState.formData.premiumOfGoals[index],
            id,
            price,
          },
        },
      },
    }));
  }

  handleFieldsChange(e, index) {
    const { name, value } = e.target;
    this.changeFieldsState(index, name, value);
  }

  handleFieldsChangeSelect(e, index, positionField) {
    const { name, value } = e.target;
    this.changeFieldsSelectState(index, name, value, positionField);
  }

  changeFieldsSelectState(index, name, value, positionField) {
    this.setState((prevState) => ({
      fields: {
        ...prevState.fields,
        [positionField]: {
          ...prevState.fields[positionField],
          [index]: {
            ...prevState.fields[positionField][index],
            [name]: value,
            /** se o usuario digitar o titulo do texto, já preenche o name com o normalize */
            nameFieldSelect: normalizeLowerCase(value),
          },
        },
      },
    }));
  }

  changeFieldsState = (index, name, value) => {
    // const { fields } = this.state;

    /** se o usuario digitar o titulo do texto, já preenche o name com o normalize */
    if (name === 'titleField') {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [index]: {
            ...prevState.fields[index],
            [name]: value,
            nameField: normalizeLowerCase(value),
          },
        },
      }));
    }
    if (name === 'titleListField') {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [index]: {
            ...prevState.fields[index],
            [name]: value,
            normalizeTitleListField: normalizeLowerCase(value),
          },
        },
      }));
    }
    if (value === 'select') {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [index]: {
            ...prevState.fields[index],
            typeField: value,
          },
        },
      }));
    }
    if (value !== 'select') {
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          [index]: {
            ...prevState.fields[index],
            [name]: value,
          },
        },
      }));
    }
  };

  handleUserInputRegional(e, index) {
    const { name, value } = e.target;

    if (name === 'scaleEnableRegional' && value === 'false') {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          scaleRegional: {
            ...prevState.formData.scaleRegional,
            [index]: {
              ...prevState.formData.scaleRegional[index],
              [name]: value,
            },
          },
        },
      }));
    } else if (name === 'playerRegisterPoints') {
      return this.handleFormDataChangeRegional(name, Number(value), index);
    }

    this.handleFormDataChangeRegional(name, value, index);
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  clickAmountSelect(e, indexField) {
    const { value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        amountOfSelects: {
          ...prevState.formData.amountOfSelects,
          [indexField]: {
            ...prevState.formData.amountOfSelects[indexField],
            amountOfSelects: value,
            indexField,
          },
        },
      },
    }));
  }

  handleFormAmountSelectChange(name, value, indexField) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        amountOfSelects: {
          ...prevState.formData.amountOfSelects,
          [indexField]: {
            ...prevState.formData.amountOfSelects[indexField],
            amountOfSelects: value,
            indexField,
          },
        },
      },
    }));
  }

  handleFormDataChangeRegional(name, value, index) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        scaleRegional: {
          ...prevState.formData.scaleRegional,
          [index]: {
            ...prevState.formData.scaleRegional[index],
            [name]: value,
          },
        },
      },
    }));
  }

  handleIntegration(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            [name]: value,
            name: 'customLead',
            configuration: {
              url: '',
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
            },
            parameters: {},
          },
          paypal: {
            ...prevState.formData.integration.paypal,
            [name]: value,
          },
        },
      },
    }));
  }

  handleChangeConfigurationIntegration(value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            configuration: value.updated_src,
            name: 'customLead',
          },
        },
      },
    }));
  }

  handleChangeParametersIntegration(value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        integration: {
          ...prevState.formData.integration,
          customLead: {
            ...prevState.formData.integration.customLead,
            parameters: value.updated_src,
            name: 'customLead',
          },
        },
      },
    }));
  }

  handleCustomFormLead(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        customFormLead: {
          ...prevState.formData.customFormLead,

          [name]: value,
        },
      },
    }));
  }

  handleSteps = (e, i) => {
    const { name, value, type } = e.target;

    const oldSteps = this.state.formData.steps;

    oldSteps[i] = {
      ...this.state.formData.steps[i],
      [name]: type === 'number' ? Number(value) : value,
    };
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        steps: oldSteps,
      },
    }));
  };

  handleConverted = (e, i) => {
    const { name, value } = e.target;

    const oldSteps = this.state.formData.steps;
    oldSteps[i] = {
      ...this.state.formData.steps[i],
      [name]: typeof value === 'string' ? Boolean(value) : Boolean(value),
    };
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        steps: oldSteps,
      },
    }));
  };

  getState(item) {
    return _.get(this.state, item, '');
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  renderAuthorizedUsersButton() {
    const { loadingAuthorizedUsers } = this.state;
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className="col-md-6 form-group"
        >
          <button
            type="button"
            htmlFor="authorizedUsers"
            className="btn btn-oq-black"
            disabled={loadingAuthorizedUsers}
            onClick={() => this.toggleUpdateAuthorizedUsers()}
          >
            {this.translate('confirm-users-update')}
          </button>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_plataforma_fechada_-_meus_colaboradores.xlsx?alt=media&token=3cb1bbf9-8a99-4711-a070-53e2f9b34d54"
            style={{
              marginTop: '.5rem',
            }}
            className="menu-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Exemplo planilha de meus colaboradores
          </a>
        </div>
      </>
    );
  }

  toggleUpdateAuthorizedUsers = () =>
    this.setState({
      modalUpdateAuthorizedUsersIsOpen:
        !this.state.modalUpdateAuthorizedUsersIsOpen,
    });

  confirmUpdateAuthorizedUsers() {
    return (
      <ModalUpdateAuthorizedUsers
        open={this.state.modalUpdateAuthorizedUsersIsOpen}
        close={this.toggleUpdateAuthorizedUsers.bind(this)}
        fileSelector={this.fileSelector}
      />
    );
  }

  toggleUploadInfluencers() {
    this.setState({
      modalUploadInfluencersIsOpen: !this.state.modalUploadInfluencersIsOpen,
    });
  }

  confirmUploadInfluencers() {
    return (
      <ModalUploadInfluencers
        open={this.state.modalUploadInfluencersIsOpen}
        close={this.toggleUploadInfluencers.bind(this)}
        inputInfluencers={this.inputInfluencers}
      />
    );
  }

  toggleUpdateVouchers() {
    this.setState({
      modalUpdateVouchersIsOpen: !this.state.modalUpdateVouchersIsOpen,
    });
  }

  confirmUpdateVouchers() {
    return (
      <ModalUpdateVouchers
        open={this.state.modalUpdateVouchersIsOpen}
        close={this.toggleUpdateVouchers.bind(this)}
        inputVoucher={this.inputVoucher}
      />
    );
  }

  toggleUpdateRoles() {
    this.setState({
      modalUpdateRolesIsOpen: !this.state.modalUpdateRolesIsOpen,
    });
  }

  confirmUpdateRoles() {
    return (
      <ModalUpdateRoles
        open={this.state.modalUpdateRolesIsOpen}
        close={this.toggleUpdateRoles.bind(this)}
        inputRoles={this.inputRoles}
      />
    );
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (this.inputIsNotRequired(inputKey) && !input) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  inputIsNotRequired(inputKey) {
    return (
      inputKey !== 'regional' &&
      inputKey !== 'buttonFilterQuiz' &&
      inputKey !== 'buttonFilterAction' &&
      inputKey !== 'scopeFieldText' &&
      inputKey !== 'scopeFieldNameButton' &&
      inputKey !== 'scopeFieldButton' &&
      inputKey !== 'playerRegisterPoints' &&
      inputKey !== 'levelAmount' &&
      inputKey !== 'token' &&
      inputKey !== 'scaleDescription' &&
      inputKey !== 'scalePreset' &&
      inputKey !== 'scaleCurrent' &&
      inputKey !== 'scaleStartDate' &&
      inputKey !== 'nameSecondarySegmentation' &&
      inputKey !== 'readTerms' &&
      inputKey !== 'steps' &&
      inputKey !== 'regionalBenefit' &&
      inputKey !== 'scaleEndDate' &&
      inputKey !== 'benefitLogo' &&
      inputKey !== 'loginImage' &&
      inputKey !== 'roles' &&
      inputKey !== 'benefitLogo' &&
      inputKey !== 'loginImage' &&
      inputKey !== 'amountOfInputs' &&
      inputKey !== 'vouchers' &&
      inputKey !== 'amountOfHierarchy' &&
      inputKey !== 'tableRoles' &&
      inputKey !== 'wellcomeText' &&
      inputKey !== 'numberOfDaysToRedeem' &&
      inputKey !== 'enableIconsShareDashboard' &&
      inputKey !== 'showCTAIndication' &&
      inputKey !== 'isActiveIara' &&
      inputKey !== 'isAdvanceInstallments' &&
      inputKey !== 'isActiveWhatsapp' &&
      inputKey !== 'useIntegration' &&
      inputKey !== 'sendMessageClient'
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;
    // fieldName é o campo que está a sofre alterações em seu valores.
    switch (fieldName) {
      default:
        if (value && value.length <= 0) {
          fieldValidationErrors[fieldName] = this.translate(
            'Você precisa preencher esse campo.'
          );
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      // this.validateForm();
    });
  }

  handleTagsChangeRegionalBenefit(tags) {
    this.updateFormData('regionalBenefit', tags);
  }

  handleTagsChange(tags) {
    this.updateFormData('regional', tags);
  }

  handleTagsChangeVouchers(tags) {
    this.updateFormData('vouchers', tags);
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  createToken() {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.setState({ loadingNewToken: true });

    const parameters = {
      createFunctionality: 'createToken',
      userType: 'client',
      clientId,
    };

    api.post(`/create`, parameters).then(async (res) => {
      this.setState({
        loadingNewToken: false,
        formData: {
          ...this.state.formData,
          token: res.data.data,
        },
      });
      Notify(res.data.message, res.data.success ? 'success' : 'error');
    });
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  uploadInfluencers() {
    const client = this.state.formData;

    // const { clientId, fields } = this.state;

    const { tableRoles } = this.state;

    const parameters = {
      userType: 'client',
      updateFunctionality: 'uploadInfluencers',
      tableRoles,
      client,
    };

    api
      .post(`/update`, parameters)
      .then((response) => {
        const result = response.data;

        Notify(result.message, result.success ? 'success' : 'error');
      })
      .catch((error) => {
        console.log('updateInfluencers error:', error);
        this.setState({ loadingInfluencers: false });
        Notify(error.message, error.success ? 'success' : 'error');
      });
  }

  uploadRoles() {
    const client = this.state.formData;

    // const { clientId, fields } = this.state;

    const { tableRoles } = this.state;

    const parameters = {
      userType: 'client',
      updateFunctionality: 'updateRoles',
      tableRoles,
      client,
    };

    api
      .post(`/update`, parameters)
      .then((response) => {
        const result = response.data;

        Notify(result.message, result.success ? 'success' : 'error');
        if (result.success)
          NotifyUpdate(
            'Baixando arquivo de usuários não atualizados...',
            result.success ? 'info_sad' : 'error',
            true
          );
        axios({
          url: result.fileURL,
          method: 'GET',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'cpfsNaoAtualizados.txt'); // or any other extension
          document.body.appendChild(link);
          link.click();
          storage
            .ref()
            .child(`${result.destination}/${result.fileName}`)
            .delete()
            .catch((error) =>
              console.log('Erro ao excluir arquivo do storage', error)
            );
        });
      })
      .catch((error) => {
        console.log('updateAuthorizedUsers error:', error);
        this.setState({ loadingAuthorizedUsers: false });
        Notify(error.message, error.success ? 'success' : 'error');
      });
  }

  validateIconsShare(iconsShareDashboard) {
    const objIconsShare = {};

    if (iconsShareDashboard.whatsapp === 'true') {
      objIconsShare.whatsapp = iconsShareDashboard.whatsapp;
    }
    if (iconsShareDashboard.facebook === 'true') {
      objIconsShare.facebook = iconsShareDashboard.facebook;
    }
    if (iconsShareDashboard.telegram === 'true') {
      objIconsShare.telegram = iconsShareDashboard.telegram;
    }
    if (iconsShareDashboard.twitter === 'true') {
      objIconsShare.twitter = iconsShareDashboard.twitter;
    }
    if (iconsShareDashboard.pinterest === 'true') {
      objIconsShare.pinterest = iconsShareDashboard.pinterest;
    }
    if (iconsShareDashboard.copyLink === 'true') {
      objIconsShare.copyLink = iconsShareDashboard.copyLink;
    }
    if (iconsShareDashboard.instagram === 'true') {
      objIconsShare.instagram = iconsShareDashboard.instagram;
    }
    if (iconsShareDashboard.emailDashboard === 'true') {
      objIconsShare.emailDashboard = iconsShareDashboard.emailDashboard;
    }
    if (iconsShareDashboard.linkedin === 'true') {
      objIconsShare.linkedin = iconsShareDashboard.linkedin;
    }
    if (iconsShareDashboard.messenger === 'true') {
      objIconsShare.messenger = iconsShareDashboard.messenger;
    }

    return {
      objIconsShareLength: Object.keys(objIconsShare).length,
      objIconsShare,
    };
  }

  updateClient() {
    const client = this.state.formData;

    const { clientId, fields } = this.state;

    const { tableRoles } = this.state;

    if (
      client.scaleEnable === 'true' &&
      client.scaleEnableRegional === 'true'
    ) {
      return Notify('Apenas uma das metas devem estar habilitadas', 'error');
    }

    // this.validateForm();
    if (!this.state.formValid) {
      Notify(
        'Você precisa preencher todos os dados antes de continuar.',
        'warn'
      );
      return;
    }

    const { objIconsShare, objIconsShareLength } = this.validateIconsShare(
      client.iconsShareDashboard
    );

    if (objIconsShareLength > 5) {
      Notify('Você só pode selecionar 5 ícones de compartilhamento.', 'warn');
      return;
    }

    client.iconsShareDashboard = objIconsShare;

    // if (
    //   this.state.formData &&
    //   this.state.formData.integration &&
    //   this.state.formData.integration.customLead &&
    //   this.state.formData.integration.customLead.active &&
    //   this.state.formData.integration.customLead.active === 'true'
    // ) {
    //   if (!this.state.formData.integration.customLead.crmSelected  ) {
    //     return Notify('Você precisa selecionar o CRM.', 'warn');
    //   }
    // }
    this.setState({ updating: true }, async () => {
      const parameters = {
        updateFunctionality: 'updateClient',
        userType: 'administrator',
        clientId,
        client,
      };

      const params = {
        updateFunctionality: 'updateCustomForm',
        userType: 'client',
        clientId,
        fields,
      };
      await api
        .post(`/update`, params)
        .then((response) => {
          this.setState({ updating: false });

          if (response.data.succes) {
            console.log('Formulário personalizado salvo com sucesso');
          } else {
            console.log('Erro ao salvar formulário');
          }
        })
        .catch((error) => {
          this.setState({ updating: false });
          Notify('Erro ao salvar formulário', 'error');
          console.log('updateForm error:', error);
        });

      await api
        .post(`/update`, parameters)
        .then((response) => {
          this.setState({ updating: false });
          if (toast.isActive(this.toastId)) {
            toast.update(this.toastId, {
              render: response.data.message,
              type: response.data.success
                ? toast.TYPE.SUCCESS
                : toast.TYPE.ERROR,
              autoClose: 5000,
            });
          } else {
            Notify(
              response.data.message,
              response.data.success ? 'success' : 'error'
            );
          }
        })
        .catch((error) => {
          this.setState({ updating: false });
          Notify('Erro ao salvar informações do cliente', 'error');
          console.log('updateClient error:', error);
        });

      if (this.context.clientData.secondarySegmentationChoice === 'true') {
        if (tableRoles && tableRoles.length > 0) {
          const parameters = {
            userType: 'client',
            updateFunctionality: 'updateRoles',
            tableRoles,
            client,
          };

          api
            .post(`/update`, parameters)
            .then((response) => {
              const result = response.data;
              Notify(result.message, result.success ? 'success' : 'error');
            })
            .catch((error) => {
              console.log('updateAuthorizedUsers error:', error);
              this.setState({ loadingAuthorizedUsers: false });
              Notify(error.message, error.success ? 'success' : 'error');
            });
        }
      }
    });
  }

  loadClientCustomForm() {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.setState({ loadingClientCustomForm: true }, () => {
      const parameters = {
        searchFunctionality: 'getClientCustomForm',
        userType: 'client',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({
            fields: result.data || [],
            loadingClientCustomForm: false,
          });
        })
        .catch((error) => {
          this.setState({ loadingClientCustomForm: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  renderUpdateButton() {
    const { groupPermissions, userData } = this.context;

    let isPermissionCreateEdit = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionCreateEdit =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.configurations &&
          groupPermissions.permissions.configurations.create_edit) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (
      userData &&
      userData.group &&
      userData.group.value &&
      !userData.master
    ) {
      isPermissionCreateEdit =
        (groupPermissions &&
          groupPermissions[userData.group.value].permissions &&
          groupPermissions[userData.group.value].permissions.configurations &&
          groupPermissions[userData.group.value].permissions.configurations
            .create_edit) ||
        (userData && userData.master && userData.master);
    }

    return (
      <div className="col-md-5 col-md-offset-3 form-group oq-padding-vertical">
        <button
          disabled={
            this.state.loadingAuthorizedUsers ||
            this.state.parsingRegionals ||
            this.state.updating
          }
          className="btn btn-block btn-oq btn-oq-lg"
          onClick={() =>
            isPermissionCreateEdit
              ? this.updateClient()
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          {this.state.updating || this.state.loadingAuthorizedUsers
            ? this.translate('saving-data')
            : this.translate('save-data')}
        </button>
      </div>
    );
  }

  renderConfig() {
    const { formData } = this.state;
    const { scaleRegional } = this.state.formData;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#configScreen"
          aria-expanded="false"
        >
          Meta Geral
        </h4>
        <div id="configScreen" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="scaleEnable">
              {this.translate('scale-enable')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="scaleEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="scaleEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.scaleEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="scaleEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.scaleEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          {formData.scaleEnable === 'true' && (
            <>
              <div className="row">
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="scaleStartDate">
                    {this.translate('start-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="scaleStartDate"
                    name="scaleStartDate"
                    value={this.getState('formData.scaleStartDate')}
                    onChange={(e) => this.handleDateChange(e)}
                    min={moment(this.state.startOfMonth).format('YYYY-MM-DD')}
                    max={moment(this.state.endOfMonth).format('YYYY-MM-DD')}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="scaleEndDate">
                    {this.translate('end-date')}
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="scaleEndDate"
                    name="scaleEndDate"
                    value={this.getState('formData.scaleEndDate')}
                    onChange={(e) => this.handleDateChange(e)}
                    min={moment(this.state.startOfMonth).format('YYYY-MM-DD')}
                    max={moment(this.state.endOfMonth).format('YYYY-MM-DD')}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="scaleDescription">
                    {this.translate('scale-Description')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => this.handleUserInput(event)}
                    id="scaleDescription"
                    name="scaleDescription"
                    required
                    value={this.getState('formData.scaleDescription')}
                    placeholder={this.translate('scale-Description')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="scale-preset">
                    Numero de conversões no mês
                    {}
                    &nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="scale-preset"
                    />
                  </label>
                  <ReactTooltip
                    id="scale-preset"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>Vendas do período selecionado</span>
                  </ReactTooltip>

                  <input
                    className="form-control profile-placeholder"
                    type="number"
                    onChange={(event) => this.handleUserInput(event)}
                    id="scalePreset"
                    name="scalePreset"
                    required
                    value={this.getState('formData.scalePreset')}
                    placeholder={this.translate('scale-preset')}
                  />
                </div>
              </div>

              {/* <div className="row">
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="scale-current">
                    {this.translate('scale-current')}
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    onChange={event => this.handleUserInput(event)}
                    id="scaleCurrent"
                    name="scaleCurrent"
                    required
                    value={this.getState('formData.scaleCurrent')}
                    placeholder={this.translate('scale-current')}
                  />
                </div>
              </div> */}
            </>
          )}

          {/* início meta por segmentação */}

          {/* <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="scaleEnableRegional">
              {this.translate('Habilitar meta por segmentação?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="scaleEnableRegional"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="scaleEnableRegional"
                    onChange={e => this.handleUserInput(e)}
                    checked={formData.scaleEnableRegional !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="scaleEnableRegional"
                    onChange={e => this.handleUserInput(e)}
                    checked={formData.scaleEnableRegional === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div> */}

          {formData.scaleEnableRegional === 'true' && (
            <>
              {formData.regional &&
                formData.regional.map((regional, index) => {
                  scaleRegional[index] = scaleRegional[index]
                    ? scaleRegional[index]
                    : {};
                  return (
                    <>
                      <div key={index}>
                        <h4 style={{ marginBottom: 18 }}>
                          {' '}
                          <strong
                            style={{
                              color: '#4f4f4f',
                              fontFamily: 'var(--oq-font-medium)',
                            }}
                          >{`Segmentação ${regional}`}</strong>{' '}
                        </h4>
                        <div className="row">
                          <div className="form-group col-md-3">
                            <label
                              className="profile-label"
                              htmlFor="scaleStartDateRegional"
                            >
                              {/* {this.translate('start-date-regional')} */}
                              {this.translate(`Data de início`)}
                            </label>
                            <input
                              type="date"
                              className="form-control profile-placeholder"
                              id="scaleStartDateRegional"
                              name="scaleStartDateRegional"
                              value={
                                scaleRegional[index].scaleStartDateRegional
                              }
                              onChange={(e) =>
                                this.handleDateChangeRegional(e, index)
                              }
                            />
                          </div>
                          <div className="form-group col-md-3">
                            <label
                              className="profile-label"
                              htmlFor="scaleEndDateRegional"
                            >
                              {/* {this.translate('end-date-regional')} */}
                              {this.translate(`Data do fim`)}
                            </label>
                            <input
                              type="date"
                              className="form-control profile-placeholder"
                              id="scaleEndDateRegional"
                              name="scaleEndDateRegional"
                              value={scaleRegional[index].scaleEndDateRegional}
                              onChange={(e) =>
                                this.handleDateChangeRegional(e, index)
                              }
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label
                              className="profile-label"
                              htmlFor="scaleDescriptionRegional"
                            >
                              {/* {this.translate('scale-Description-regional')} */}
                              {this.translate(`Descrição da meta`)}
                            </label>
                            <input
                              className="form-control profile-placeholder"
                              onChange={(event) =>
                                this.handleUserInputRegional(event, index)
                              }
                              id="scaleDescriptionRegional"
                              name="scaleDescriptionRegional"
                              required
                              value={
                                scaleRegional[index].scaleDescriptionRegional
                              }
                              // placeholder={this.translate('scale-Description-regional')}
                              placeholder={this.translate(`Descrição da meta`)}
                            />
                            <input
                              type="hidden"
                              value={(scaleRegional[index].regional = regional)}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label
                              className="profile-label"
                              htmlFor="scalePresetRegional"
                            >
                              {/* {this.translate('scale-preset')} */}
                              {this.translate(`Valor da meta`)}
                            </label>

                            <input
                              className="form-control profile-placeholder"
                              onChange={(event) =>
                                this.handleUserInputRegional(event, index)
                              }
                              id="scalePresetRegional"
                              name="scalePresetRegional"
                              required
                              value={scaleRegional[index].scalePresetRegional}
                              // placeholder={this.translate('scale-preset')}
                              placeholder={this.translate(
                                `Valor da meta por segmentação`
                              )}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label
                              className="profile-label"
                              htmlFor="scaleCurrentRegional"
                            >
                              {/* {this.translate('scale-current')} */}
                              {this.translate(
                                `Valor da meta da regional atual`
                              )}
                            </label>
                            <input
                              className="form-control profile-placeholder"
                              onChange={(event) =>
                                this.handleUserInputRegional(event, index)
                              }
                              id="scaleCurrentRegional"
                              name="scaleCurrentRegional"
                              required
                              value={scaleRegional[index].scaleCurrentRegional}
                              // placeholder={this.translate('scale-current')}
                              placeholder={this.translate(
                                `Valor da meta atual`
                              )}
                            />
                            {/* <hr /> */}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </>
          )}

          {/* fim meta por segmentação */}
        </div>
        <hr />
      </div>
    );
  }

  renderConfigRanking() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#configRanking"
          aria-expanded="false"
        >
          Ranking
        </h4>
        <div id="configRanking" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="enableRankingRange">
              Habilitar intervalo do ranking &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="enableRankingRange"
              />
            </label>
            <ReactTooltip
              id="enableRankingRange"
              type="dark"
              effect="solid"
              multiline
            >
              <span>Ranking do período selecionado</span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="enableRankingRange"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableRankingRange !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="enableRankingRange"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableRankingRange === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          {formData.enableRankingRange === 'true' && (
            <>
              <div className="row">
                <div className="form-group col-md-3">
                  <label
                    className="profile-label"
                    htmlFor="startRankingInterval"
                  >
                    Data de início
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="startRankingInterval"
                    name="startRankingInterval"
                    value={this.getState('formData.startRankingInterval')}
                    onChange={(e) => this.handleDateChange(e)}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label className="profile-label" htmlFor="endRankingInterval">
                    Data de fim
                  </label>
                  <input
                    type="date"
                    className="form-control profile-placeholder"
                    id="endRankingInterval"
                    name="endRankingInterval"
                    value={this.getState('formData.endRankingInterval')}
                    onChange={(e) => this.handleDateChange(e)}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <hr />
      </div>
    );
  }

  renderPhase3 = () => {
    const { parsingRegionals, phase3Uncompleted, formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Dados Complementares{' '}
          {phase3Uncompleted && this.renderUncompletedText()}
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          {/* AQUI ESTÁ A OPÇÃO TRUE OU FALSE DO BOTAO DE FILTRO/ */}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="filterChoice">
              {this.translate('Deseja segmentar os filtros?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="filterChoice"
              />
            </label>
            <ReactTooltip
              id="filterChoice"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="filterChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.filterChoice !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="filterChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.filterChoice === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="isFilterDashboard">
              {this.translate(
                'Deseja ativar os filtros no dashboard do player?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isFilterDashboard"
              />
            </label>
            <ReactTooltip
              id="isFilterDashboard"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Ativar filtros no dashboard do player, para que seja selecionado por tipo de publicação a ser exibida.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isFilterDashboard"
                    onChange={(e) => this.handleIsFilterDashboard(e)}
                    checked={!this.state.formData.isFilterDashboard}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isFilterDashboard"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.isFilterDashboard}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {/* <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="showCTAIndication">
              {this.translate(
                'Deseja ativar o redirecionamento na publicação para indicados?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="showCTAIndication"
              />
            </label>
            <ReactTooltip
              id="showCTAIndication"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Ativar botão para que indicado cadastre-se como usuário.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="showCTAIndication"
                    onChange={e => this.handleIsFilterDashboard(e)}
                    checked={!this.state.formData.showCTAIndication}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="showCTAIndication"
                    onChange={e => this.handleUserInput(e)}
                    checked={this.state.formData.showCTAIndication}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div> */}

          {this.state.formData.filterChoice === 'true' && (
            <>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="buttonFilterQuiz">
                    {this.translate('Filter Name Quiz')} <code>*</code>
                  </label>
                  <input
                    required
                    className="form-control profile-placeholder"
                    name="buttonFilterQuiz"
                    placeholder={this.translate('Filter Name Quiz')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.buttonFilterQuiz}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="buttonFilterAction">
                    {this.translate('Filter Name Action')} <code>*</code>
                  </label>
                  <input
                    required
                    className="form-control profile-placeholder"
                    name="buttonFilterAction"
                    placeholder={this.translate('Filter Name Action')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.buttonFilterAction}
                  />
                </div>
              </div>
            </>
          )}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="segmentationChoice">
              {this.translate('Deseja segmentar os usuários?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="segmentationInfo"
              />
            </label>
            <ReactTooltip
              id="segmentationInfo"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="segmentationChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.segmentationChoice !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="segmentationChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.segmentationChoice === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData.segmentationChoice === 'true' && (
            <div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="scopeFieldName">
                    {this.translate('scope-field-name')}&nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="regionalInfo"
                    />
                  </label>
                  <ReactTooltip
                    id="regionalInfo"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>{this.translate('scope-field-name-info')}</span>
                  </ReactTooltip>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => {
                      this.handleUserInput(event);
                    }}
                    id="scopeFieldName"
                    name="scopeFieldName"
                    aria-describedby="scopeFieldNameHelp"
                    value={this.getState('formData.scopeFieldName')}
                    placeholder={this.translate('scope-field-name')}
                  />
                  {this.getError('scopeFieldName') && (
                    <small
                      id="scopeFieldNameHelp"
                      className="form-text text-muted"
                    >
                      {this.getError('scopeFieldName')}
                    </small>
                  )}
                </div>
              </div>
              <div className="row align-items-flex-end">
                <div className="form-group col-md-8">
                  <label className="profile-label" htmlFor="regional">
                    {this.state.formData.scopeFieldName || 'Unidades'}
                    &nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="scopeFieldNameInfo"
                    />
                  </label>
                  <ReactTooltip
                    id="scopeFieldNameInfo"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Você pode colocar cada unidade escrevendo e pressionando
                      enter ou importar uma planilha de excel com todos os nomes
                      das unidades.
                    </span>
                  </ReactTooltip>
                  <TagsInput
                    inputProps={{
                      placeholder:
                        this.state.formData.scopeFieldName || 'Unidades',
                    }}
                    value={this.getState('formData.regional')}
                    onChange={this.handleTagsChange.bind(this)}
                  />
                  {this.getError('regional') && (
                    <small id="regionalHelp" className="form-text text-muted">
                      {this.getError('regional')}
                    </small>
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className="form-group w-auto"
                >
                  <label
                    htmlFor="file-upload"
                    type="button"
                    className="btn btn-oq-black"
                    disabled={parsingRegionals}
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                    &nbsp;{' '}
                    {parsingRegionals
                      ? `${this.translate('importing')} ${
                          this.getState('formData.scopeFieldName') ||
                          'segmentações'
                        }...`
                      : `${this.translate('import')} ${
                          this.getState('formData.scopeFieldName') ||
                          'segmentações'
                        }`}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) =>
                      this.fileChanged(e.target.files, 'regional')
                    }
                  />
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_regional.xlsx?alt=media&token=ccbf840b-b0b3-4a20-bc15-af6dd090ef57"
                    style={{
                      marginTop: '.5rem',
                    }}
                    className="menu-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Exemplo planilha de moradias
                  </a>
                </div>
              </div>
            </div>
          )}

          {/* SE O USUARIO TEM A PRIMEIRA SEGMENTAÇÃO E DESEJA ABRIR MODAL INICIAL */}
          {this.state.formData.segmentationChoice === 'true' && (
            <div className="row points-by-share col-md-12">
              <label
                className="profile-label"
                htmlFor="segmentationModalChoice"
              >
                Deseja abrir Popup inicial para escolher segmentação do usuário?
                &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="segmentationInfo"
                />
              </label>
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="segmentationModalChoice"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={
                        this.state.formData.segmentationModalChoice !== 'true'
                      }
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="segmentationModalChoice"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={
                        this.state.formData.segmentationModalChoice === 'true'
                      }
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                </div>
              </div>
            </div>
          )}

          {/* SE O USUARIO HABILITAR MODAL DA SEGMENTAÇÃO */}
          {this.state.formData.segmentationModalChoice === 'true' && (
            <div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="scopeFieldText">
                    Texto do popup
                  </label>
                  <textarea
                    className="form-control profile-placeholder"
                    onChange={(event) => {
                      this.handleUserInput(event);
                    }}
                    id="scopeFieldText"
                    name="scopeFieldText"
                    aria-describedby="scopeFieldText"
                    value={this.getState('formData.scopeFieldText')}
                    placeholder="Texto que você deseja exibir para o usuário escolher a segmentação"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label
                    htmlFor="scopeFieldNameButton"
                    className="profile-label"
                  >
                    Nome do botão
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => {
                      this.handleUserInput(event);
                    }}
                    name="scopeFieldNameButton"
                    id="scopeFieldNameButton"
                    value={this.getState('formData.scopeFieldNameButton')}
                    placeholder="Nome do botão para visualizar conteúdo antes de decidir segmentação"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="scopeFieldButton" className="profile-label">
                    URL
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => {
                      this.handleUserInput(event);
                    }}
                    name="scopeFieldButton"
                    id="scopeFieldButton"
                    value={this.getState('formData.scopeFieldButton')}
                    placeholder="URL do conteúdo"
                  />
                </div>
              </div>
            </div>
          )}

          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="targetPublic">
                Pontos para o cadastro do player
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="leadRegistration"
                />
                <ReactTooltip
                  id="leadRegistration"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Defina quantos pontos cada player irá ganhar para se
                    cadastrar na sua plataforma
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                type="number"
                className="form-control profile-placeholder"
                name="playerRegisterPoints"
                onChange={(e) => this.handleUserInput(e)}
                value={formData.playerRegisterPoints}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="targetPublic">
                Qual a forma de autenticação do usuário na plataforma?
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="leadRegistration"
                />
                <ReactTooltip
                  id="leadRegistration"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Defina quantos pontos cada player irá ganhar para se
                    cadastrar na sua plataforma
                  </span>
                </ReactTooltip>
              </label>
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="loginEmail"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.loginEmail === 'true'}
                  />
                  &nbsp;Email
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="loginEmail"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.loginEmail !== 'true'}
                  />
                  &nbsp;CPF
                </label>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="targetPublic">
                {this.translate('public-type')}
              </label>
              <select
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="targetPublic"
                name="targetPublic"
                aria-describedby="targetPublicHelp"
                value={this.getState('formData.targetPublic')}
              >
                <option value="">{this.translate('select-the-otion')}</option>
                <option value="generalPublic">
                  {this.translate('general-public')}
                </option>
                <option value="myCollaborators">
                  {this.translate('my-collaborators')}
                </option>
              </select>
              {this.getError('targetPublic') && (
                <small id="targetPublicHelp" className="form-text text-muted">
                  {this.getError('targetPublic')}
                </small>
              )}
            </div>
            {this.getState('formData.targetPublic') === 'myCollaborators' &&
              this.renderAuthorizedUsersButton()}
          </div>
        </div>
        <hr />
      </div>
    );
  };

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  renderSecondSegmentation() {
    // const { loadingNewToken } = this.state;
    const { secondarySegmentation } = this.state.formData;

    const flattenSecondarySegmentationClient = this.flatten(
      secondarySegmentation
    );
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase4"
          aria-expanded="false"
        >
          Segunda Segmentação
        </h4>
        <div id="phase4" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              {_.map(flattenSecondarySegmentationClient, (segmentation) => {
                return (
                  <>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label className="profile-label" htmlFor="targetPublic">
                          {segmentation.text}&nbsp;
                          <i
                            className="fa fa-info-circle"
                            aria-hidden="true"
                            data-tip="React-tooltip"
                            data-for="leadRegistration"
                          />
                          <ReactTooltip
                            id="leadRegistration"
                            type="dark"
                            effect="solid"
                            multiline
                          >
                            <span>
                              Defina a porcentagem da pontuação que cada cargo
                              irá receber
                            </span>
                          </ReactTooltip>
                        </label>
                        <input
                          // required={true}
                          type="number"
                          className="form-control profile-placeholder"
                          name="playerRegisterPoints"
                          onChange={(e) => this.handleUserInput(e)}
                          // value={formData.playerRegisterPoints}
                        />
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderIntegration() {
    const { loadingNewToken } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase4"
          aria-expanded="false"
        >
          Integração
        </h4>
        <div id="phase4" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="clientId">
                {this.translate('integrationId')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="clientId-i"
                />
              </label>
              <ReactTooltip
                id="clientId-i"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('integrationId-info')}</span>
              </ReactTooltip>
              <div className="form-inline">
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  id="clientId"
                  name="clientId"
                  required
                  value={this.state.clientId}
                  style={{ width: 260 }}
                />

                <CopyToClipboard
                  className="btn btn-oq-black oq-margin-left"
                  text={this.state.clientId}
                  onCopy={() => {
                    Notify('ID copiado!', 'success');
                  }}
                >
                  <div className="btn btn-oq-back text-center form-group">
                    Copiar ID
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-8">
              <label className="profile-label" htmlFor="integrationToken">
                {this.translate('integrationToken')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="integrationId-i"
                />
              </label>
              <ReactTooltip
                id="integrationId-i"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('integrationToken-info')}</span>
              </ReactTooltip>
              <div className="form-inline">
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  id="integrationToken"
                  name="integrationToken"
                  value={this.state.formData.token}
                  style={{ width: 260 }}
                />
                {'   '}
                <CopyToClipboard
                  className="btn btn-oq-black oq-margin-left"
                  text={this.getState('formData.token')}
                  onCopy={() => {
                    Notify('Token copiado!', 'success');
                  }}
                >
                  <div className="text-center form-group">Copiar Token</div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <button
                className="btn btn-oq-back text-center form-group"
                onClick={() => this.createToken()}
                disabled={loadingNewToken}
              >
                {loadingNewToken ? 'Aguarde...' : 'Gerar novo token'}
              </button>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  removeSteps(e) {
    const indexFromRemove = Number(e.currentTarget.getAttribute('data-index'));
    const newSteps = this.state.formData.steps;

    if (this.state.formData.steps.length > 2) {
      newSteps.splice(indexFromRemove, 1);
      this.setState({ formData: this.state.formData });
    } else {
      Notify('É necessário pelo menos 2 steps', 'warn');
    }
  }

  addSteps() {
    const newSteps = this.state.formData.steps;
    newSteps.push({ name: 'Novo step', value: 0 });
    this.setState({ formData: this.state.formData });
  }

  renderSteps() {
    // const { loadingNewToken } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase5"
          aria-expanded="false"
        >
          Steps das Indicações
        </h4>
        <div id="phase5" aria-expanded="false" className="collapse">
          <div className="">
            {_.map(this.state.formData.steps, (step, i) => {
              return (
                <>
                  <div className="col-12" style={{ display: 'flex' }}>
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="stepName">
                        Nome do step {i + 1}&nbsp;
                      </label>
                      <input
                        type="text"
                        className="form-control profile-placeholder"
                        name="name"
                        aria-describedby="stepName"
                        onChange={(e) => {
                          this.handleSteps(e, i);
                        }}
                        value={step.name}
                        key={i}
                        placeholder={`Nome do step ${i + 1}`}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="stepValue">
                        Valor do step {i + 1}&nbsp;
                      </label>
                      <input
                        type="number"
                        className="form-control profile-placeholder"
                        name="value"
                        onChange={(e) => this.handleSteps(e, i)}
                        key={i}
                        value={step.value}
                        placeholder="Pontuação a ser liberada no step"
                      />
                    </div>
                    <button
                      className="btn"
                      style={{ backgroundColor: 'transparent' }}
                      data-index={i}
                      onClick={(e) => this.removeSteps(e)}
                    >
                      <i className="material-icons">delete</i>
                    </button>
                  </div>
                </>
              );
            })}
            {this.state.formData.steps.length < 4 && (
              <>
                <div className="col-12" style={{ display: 'flex' }}>
                  <button
                    className="btn btn-block btn-oq"
                    onClick={(e) => this.addSteps()}
                  >
                    Adicionar step
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderSendEmails() {
    // const { loadingNewToken } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase7"
          aria-expanded="false"
        >
          Emails
        </h4>
        <div id="phase7" aria-expanded="false" className="collapse">
          <div className="">
            <div className="col-12" style={{ display: 'flex' }}>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="sendEmail">
                  Ativar envio de emails para usuários?
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="sendEmail"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.sendEmail !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="sendEmail"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.sendEmail === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>

              {/* <div className="form-group col-md-4">
                      <label className="profile-label" htmlFor="converted">
                        Convertido &nbsp;
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          name="converted"
                          key={i}
                          onChange={e => this.handleConverted(e, i)}
                          checked={step.converted === true}
                        />
                      </label>
                    </div> */}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderInfluencers() {
    const { formData } = this.state;

    formData.amountOfHierarchy = formData.amountOfHierarchy
      ? Number(formData.amountOfHierarchy)
      : 0;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfHierarchy; i++) {
      auxArray[i] = i;
    }
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase12"
          aria-expanded="false"
        >
          Influencers
        </h4>
        <div id="phase12" aria-expanded="false" className="collapse">
          <div className="">
            <div className="col-12" style={{ display: 'flex' }}>
              <div className="form-group col-md-12">
                <label className="profile-label m-0" htmlFor="number">
                  Numero de hierarquia:
                </label>
                <select
                  className="form-control profile-placeholder m-10 w-25"
                  onChange={(e) => this.handleUserInput(e)}
                  id="amountOfHierarchy"
                  name="amountOfHierarchy"
                  value={formData.amountOfHierarchy}
                  disabled={formData.amountOfHierarchy}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                  <option value={13}>13</option>
                  <option value={14}>14</option>
                </select>
              </div>
            </div>
            <div className="form-group col-md-12">
              <button
                type="button"
                // htmlFor="authorizedUsers"
                className="btn btn-oq-black"
                disabled={this.state.loadingUploadVoucher}
                onClick={() => this.toggleUploadInfluencers()}
              >
                {this.translate('Fazer upload de Influencers')}
              </button>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_influencers_exemplo.xlsx?alt=media&token=83481bd3-f03b-498b-a435-7f9266d9e14a"
                style={{
                  marginLeft: '.5rem',
                }}
                className="menu-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Exemplo planilha de influencers
              </a>
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="wellcomeText">
                Texto de boas vindas&nbsp;
              </label>

              <textarea
                type="text"
                className="form-control profile-placeholder"
                name="wellcomeText"
                onChange={(e) => this.handleUserInput(e)}
                value={formData.wellcomeText}
              />
            </div>

            <div className="form-group col-md-12">
              {/* <div className="form-group col-md-6"> */}
              <label
                className="profile-label"
                htmlFor="percentageOverallInfluencer"
              >
                Porcentagem padrão de pontuação do influencer
              </label>
              <input
                type="number"
                className="form-control profile-placeholder"
                name="percentageOverallInfluencer"
                aria-describedby="percentageOverallInfluencer"
                onChange={(e) => {
                  this.handleUserInput(e);
                }}
                value={this.state.formData.percentageOverallInfluencer}
                placeholder="Porcentagem de acordo com a pontuação do player"
              />
              {/* </div> */}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderVouchers() {
    const {
      // parsingRegionals,
      seasons,
    } = this.state;
    const { clientData } = this.context;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase9"
          aria-expanded="false"
        >
          Vouchers
        </h4>
        <div id="phase9" aria-expanded="false" className="collapse">
          <div className="">
            <div className="col-12" style={{ display: 'flex' }}>
              <div className="form-group col-md-12">
                <div className="form-group col-md-12">
                  {clientData && clientData.isGamified !== 'false' && (
                    <>
                      <label className="profile-label" htmlFor="season">
                        {this.translate('Season')} <code>*</code>
                      </label>
                      <select
                        className="form-control profile-placeholder"
                        name="season"
                        disabled={this.state.loadingSeasons}
                        placeholder={this.translate('loading')}
                        required
                        onChange={(e) => this.handleSeasonChange(e)}
                      >
                        <option value="">
                          {this.translate(
                            'Selecione a qual Temporada essa publicação pertence...'
                          )}
                        </option>
                        {seasons &&
                          _.map(seasons, (season) => {
                            return (
                              <option value={season.id}>
                                {season.seasonName}
                              </option>
                            );
                          })}
                      </select>
                    </>
                  )}
                </div>
                {/* <TagsInput
                  className="hidden"
                  inputProps={{
                    placeholder: 'Vouchers',
                  }}
                  value={this.getState('formData.vouchers')}
                  onChange={this.handleTagsChange.bind(this)}
                /> */}
                {/* <label
                  htmlFor="voucher-upload"
                  type="button"
                  className="btn btn-oq-black"
                  disabled={parsingRegionals}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  &nbsp;{' '}
                  {parsingRegionals
                    ? `${this.translate('importing')} voucher...`
                    : `${this.translate('import')} voucher`}
                </label> */}
                <button
                  type="button"
                  // htmlFor="authorizedUsers"
                  className="btn btn-oq-black"
                  disabled={this.state.loadingUploadVoucher}
                  onClick={() => this.toggleUpdateVouchers()}
                >
                  {this.translate('Fazer upload de vouchers')}
                </button>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_vouchers_exemplo.xlsx?alt=media&token=15592f87-46ba-4f72-9f8c-ff1f3f50452e"
                  style={{
                    marginLeft: '.5rem',
                  }}
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Exemplo planilha de vouchers
                </a>
                {/* <input
                  id="voucher-upload"
                  type="file"
                  className="hidden"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={e => this.fileChanged(e.target.files, 'voucher')}
                /> */}
              </div>

              {/* <div className="form-group col-md-4">
                      <label className="profile-label" htmlFor="converted">
                        Convertido &nbsp;
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="checkbox"
                          name="converted"
                          key={i}
                          onChange={e => this.handleConverted(e, i)}
                          checked={step.converted === true}
                        />
                      </label>
                    </div> */}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  /** Adicionei agora */

  handleChangeCounter(e, i) {
    const { formData } = this.state;
    const { name, value } = e.target;

    const oldCounts = formData.counterNames;

    oldCounts[i] = {
      ...this.state.formData.counterNames[i],
      [name]: value,
    };
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        counterNames: oldCounts,
      },
    }));
  }

  renderNamesCounts(index) {
    switch (index) {
      case 0:
        return 'Título do contador do step 1';

      case 1:
        return 'Título do contador do step 2';

      case 2:
        return 'Título do contador do step 3';

      case 3:
        return 'Título do contador do step 4';

      case 4:
        return 'Título do contador do step 5';

      case 5:
        return 'Título do contador do step 6';

      case 6:
        return 'Título do contador do step 7';

      case 7:
        return 'Título do contador do step 8';
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleFormDataChange(fileIndex, data.data);
  }

  async handleUploadSuccessResults(file, filename, nameInput, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    let copyVideoTutorials = [...this.state.formData.videoTutorials];
    let objTutorial;
    if (nameInput === 'howToWin') {
      objTutorial = {
        label: 'Como Ganhar',
        name: nameInput,
        url: data.data,
      };
      copyVideoTutorials[0] = objTutorial;
    }
    if (nameInput === 'howtoRedeem') {
      objTutorial = {
        label: 'Como Resgatar',
        name: nameInput,
        url: data.data,
      };
      copyVideoTutorials[1] = objTutorial;
    }
    if (nameInput === 'testimonials') {
      objTutorial = {
        label: 'Depoimentos',
        name: nameInput,
        url: data.data,
      };
      copyVideoTutorials[2] = objTutorial;
    }

    return this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        videoTutorials: copyVideoTutorials,
      },
    }));
  }

  async handleBenefitsLogo(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;

    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          benefitLogo: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  renderRoleConfig() {
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase10"
          aria-expanded="false"
        >
          Cargos
        </h4>
        <div id="phase10" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              <div className="form-group w-auto">
                <div
                  style={{
                    marginTop: '.5rem',
                    flexDirection: 'column',
                  }}
                  className="d-flex"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="profile-label" htmlFor="useIntegration">
                        Habilitar segunda segmentação?
                      </label>
                      <select
                        className="form-control profile-placeholder"
                        onChange={(event) => this.handleUserInput(event)}
                        id="secondarySegmentationChoice"
                        name="secondarySegmentationChoice"
                        value={this.getState(
                          'formData.secondarySegmentationChoice'
                        )}
                      >
                        <option value="">Selecione uma opção...</option>
                        <option value="true">Sim</option>
                        <option value="false">Não</option>
                      </select>
                    </div>
                  </div>
                  {this.state.formData.secondarySegmentationChoice ===
                    'true' && (
                    <div className="row">
                      <div className="form-group col-md-6">
                        <button
                          type="button"
                          // htmlFor="authorizedUsers"
                          className="btn btn-oq-black"
                          disabled={this.state.loadingUploadVoucher}
                          onClick={() => this.toggleUpdateRoles()}
                        >
                          {this.translate('Fazer upload de cargos')}
                        </button>
                        <a
                          href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_cargos.xlsx?alt=media&token=7767384a-4726-437a-bf10-4394a61dd1e3"
                          style={{
                            marginTop: '.5rem',
                            marginLeft: '.5rem',
                            display: 'block',
                          }}
                          className="menu-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Exemplo planilha de cargos
                        </a>
                      </div>
                    </div>
                  )}
                  {/* <button
                    type="button"
                    //htmlFor="authorizedUsers"
                    className="btn btn-oq-black"
                    disabled={this.state.loadingUploadVoucher}
                    onClick={() => this.confirmUpdateRoles()}
                  >
                    {this.translate('Fazer upload de cargos')}
                  </button>
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/Excel%2FPlanilha_exemplo_cargos.xlsx?alt=media&token=7767384a-4726-437a-bf10-4394a61dd1e3"
                    style={{
                      marginTop: '.5rem',
                      marginLeft: '.5rem',
                    }}
                    className="menu-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Exemplo planilha de cargos
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ display: 'flex' }} />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderRescueConfig() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase11"
          aria-expanded="false"
        >
          Resgate por metas
        </h4>

        <div id="phase11" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="rescueByGoals">
              Ativar resgate por metas ? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="rescueByGoals"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="rescueByGoals"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.rescueByGoals !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="rescueByGoals"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.rescueByGoals === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {formData.rescueByGoals === 'true' && this.renderAwardsForm()}
        </div>
        <hr />
      </div>
    );
  }

  renderStepManualConfig() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#enableStepManual"
          aria-expanded="false"
        >
          Step manual
        </h4>

        <div id="enableStepManual" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="enableStepManual">
              Ativar step manual ? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="enableStepManual"
              />
              <ReactTooltip
                id="enableStepManual"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>
                  Ativar botão para importar planilha para avançar os steps do
                  lead manualmente
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="enableStepManual"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableStepManual !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="enableStepManual"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableStepManual === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  handleVideoTutorials(event, ref, nameInput) {
    const { name, size } = event.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;

    if (size < 10000000) {
      this.handleUploadSuccess(
        event.target.files[0],
        randomizeName,
        nameInput,
        ref
      );
    } else {
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (10MB)!');
    }
  }

  handleVideoTutorial(event) {
    const { authClient } = this.state;
    const { name } = event.target;
    this.handleVideoTutorials(
      event,
      `VideosClient/${authClient.uid}/Tutorials/`,
      name
    );
  }

  async handleVideoTutorialResults(event) {
    const { authClient } = this.state;
    const { name: nameInput } = event.target;

    const { name, size } = event.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;

    if (size < 10000000) {
      await this.handleUploadSuccessResults(
        event.target.files[0],
        randomizeName,
        nameInput,
        `Clients/${authClient.uid}/videoTutorials/`
      );
    } else {
      return Notify('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (10MB)!');
    }
  }

  handleRemoveVideoTutorialResults(index) {
    let copyVideoTutorials = [...this.state.formData.videoTutorials];
    let objVideoTutorials = copyVideoTutorials[index];
    copyVideoTutorials[index].label = objVideoTutorials.label;
    copyVideoTutorials[index].name = objVideoTutorials.name;
    copyVideoTutorials[index].url = '';

    return this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        videoTutorials: copyVideoTutorials,
      },
    }));
  }

  renderTutorialConfig() {
    const { formData, videosTutorialDefault } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#useTutorial"
          aria-expanded="false"
        >
          Ativar/Desativar tutorial
        </h4>

        <div id="useTutorial" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="useTutorial">
              Ativar tutorial Onboarding? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="useTutorial"
              />
              <ReactTooltip
                id="useTutorial"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>Ativar Tutorial.</span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="useTutorial"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      formData.useTutorial && formData.useTutorial !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="useTutorial"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      formData.useTutorial && formData.useTutorial === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          {(formData.useTutorial === 'false' &&
            formData.segmentationChoiceVideoTutorial === 'false') ||
          (formData.useTutorial !== 'false' &&
            formData.segmentationChoiceVideoTutorial === 'false') ||
          (formData.useTutorial === 'false' &&
            formData.segmentationChoiceVideoTutorial === 'true') ? (
            <div className="row col-md-12">
              <div className="form-group col-md-6">
                <div className="profile-placeholder d-flex justify-content-between">
                  <input
                    accept="video/*"
                    onChange={(event) => this.handleVideoTutorial(event)}
                    type="file"
                    id="videoTurorialUrlHost"
                    name="videoTurorialUrlHost"
                    placeholder="Vídeo para exibição no tutorial"
                  />
                </div>
              </div>
              <div className="form-group col-md-12">
                <input
                  disabled
                  readOnly
                  value={formData.videoTurorialUrlHost}
                  type="text"
                  name="videoTurorialUrlHost"
                  placeholder="Url de video tutorial do cliente"
                />
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', padding: '0' }}
              >
                <a
                  href={
                    formData && formData.videoTurorialUrlHost
                      ? formData.videoTurorialUrlHost
                      : videosTutorialDefault
                  }
                  style={{
                    marginTop: '.6rem',
                  }}
                  className="menu-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Exemplo do vídeo tutorial
                </a>
              </div>
            </div>
          ) : (
            ''
          )}

          {this.state.formData.useTutorial &&
            this.state.formData.useTutorial === 'true' && (
              <div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label
                      className="profile-label"
                      htmlFor="segmentationChoiceVideoTutorial"
                    >
                      Ativar segmentação do tutorial ? &nbsp;
                      <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="segmentationChoiceVideoTutorial"
                      />
                      <ReactTooltip
                        id="segmentationChoiceVideoTutorial"
                        type="dark"
                        effect="solid"
                        className="tool-tip"
                        multiline
                      >
                        <span>Ativar tutorial por regional.</span>
                      </ReactTooltip>
                    </label>
                    <select
                      className="form-control profile-placeholder"
                      onChange={(event) => this.handleUserInput(event)}
                      id="segmentationChoiceVideoTutorial"
                      name="segmentationChoiceVideoTutorial"
                      value={this.getState(
                        'formData.segmentationChoiceVideoTutorial'
                      )}
                    >
                      <option value="true">Sim</option>
                      <option value="false">Não</option>
                    </select>
                  </div>
                </div>
                {this.state.formData.segmentationChoiceVideoTutorial &&
                  this.state.formData.segmentationChoiceVideoTutorial ===
                    'true' && (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group col-md-6">
                          <div className="profile-placeholder d-flex justify-content-between">
                            <input
                              accept="video/*"
                              onChange={(event) =>
                                this.handleVideoTutorial(event)
                              }
                              type="file"
                              id="videoTurorialUrlHost"
                              name="videoTurorialUrlHost"
                            />
                          </div>
                        </div>
                        <div className="form-group col-md-6">
                          <input
                            type="text"
                            className="form-control profile-placeholder"
                            value={
                              formData && formData.videoTurorialUrlHost
                                ? formData.videoTurorialUrlHost
                                : ''
                            }
                            readOnly
                          />
                        </div>
                        <a
                          href={videosTutorialDefault}
                          style={{
                            marginTop: '-1.4rem',
                          }}
                          className="form-group col-md-6 menu-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Exemplo do vídeo tutorial (Regional: sim)
                        </a>
                      </div>
                      <br />
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <div className="profile-placeholder d-flex justify-content-between">
                            <input
                              accept="video/*"
                              onChange={(event) =>
                                this.handleVideoTutorial(event)
                              }
                              type="file"
                              id="videoTurorialUrlProspecto"
                              name="videoTurorialUrlProspecto"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control profile-placeholder"
                            value={
                              formData && formData.videoTurorialUrlProspecto
                                ? formData.videoTurorialUrlProspecto
                                : ''
                            }
                            readOnly
                          />
                        </div>
                        <a
                          href={videosTutorialDefault}
                          style={{
                            marginTop: '.6rem',
                          }}
                          className="form-group col-md-6 menu-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Exemplo do vídeo tutorial (Regional: não)
                        </a>
                      </div>
                    </div>
                  )}
              </div>
            )}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="useTutorialResults">
              Ativar tutorial de Resultados ? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="useTutorialResults"
              />
              <ReactTooltip
                id="useTutorialResults"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>Ativar Tutorial da página de resultados</span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="useTutorialResults"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      formData.useTutorialResults &&
                      formData.useTutorialResults !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="useTutorialResults"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      formData.useTutorialResults &&
                      formData.useTutorialResults === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData.useTutorialResults &&
            this.state.formData.useTutorialResults === 'true' && (
              <div className="row col-md-12">
                <div className="row">
                  {formData.videoTutorials.map((tutorial, index) => (
                    <div className="form-group col-md-12" key={index}>
                      <label className="profile-label" htmlFor={tutorial.name}>
                        {tutorial.label} &nbsp;
                        <input
                          accept="video/*"
                          onChange={async (event) =>
                            await this.handleVideoTutorialResults(event)
                          }
                          type="file"
                          name={tutorial.name}
                        />
                        {tutorial.url && (
                          <input
                            type="text"
                            className="form-control profile-placeholder"
                            value={tutorial.url}
                            readOnly
                          />
                        )}
                      </label>
                      {tutorial.url && (
                        <span
                          style={{ marginLeft: '12px' }}
                          className="oq-primary-color clickable"
                          onClick={() =>
                            this.handleRemoveVideoTutorialResults(index)
                          }
                        >
                          <i className="far fa-trash-alt" aria-hidden="true" />
                          &nbsp; Remover
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
        <hr />
      </div>
    );
  }

  renderEnableSessionsLandingPageLogin() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#enableSessionsScreen"
          aria-expanded="false"
        >
          Habilitar Sessões Landing Page
        </h4>
        <div
          id="enableSessionsScreen"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="sessionsEnable">
              {this.translate('Habilitar sessões ?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="sessionsEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="sessionsEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.sessionsEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="sessionsEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.sessionsEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderNumberOfDaysToRedeem() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#numberOfDaysToRedeem"
          aria-expanded="false"
        >
          Dias para realizar o resgate
        </h4>
        <div
          id="numberOfDaysToRedeem"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row">
            <div className="form-group col-md-6">
              <label
                style={{ marginTop: '0.5rem' }}
                className="profile-label"
                htmlFor="targetPublic"
              >
                Dias para o resgate
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="numberOfDaysToRedeem"
                />
                <ReactTooltip
                  id="numberOfDaysToRedeem"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Defina quanto tempo (em dias) o player irá poder fazer o
                    resgate na sua plataforma.
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                type="number"
                className="form-control profile-placeholder"
                name="numberOfDaysToRedeem"
                onChange={(e) => this.handleUserInput(e)}
                value={formData.numberOfDaysToRedeem}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderActiveIara() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#isActiveIara"
          aria-expanded="false"
        >
          Ativar o Bot
        </h4>
        <div id="isActiveIara" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar o Bot IARA ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isActiveIara"
              />
              <ReactTooltip
                id="isActiveIara"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  INTELIGENCIA ARTIFICIAL PARA RELACIONAMENTO E ATENDIMENTO
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveIara"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveIara !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveIara"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveIara === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderActiveInstallmentsAdvence() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#isAdvanceInstallments"
          aria-expanded="false"
        >
          Ativar/Desativar adiantamento de parcelas
        </h4>
        <div
          id="isAdvanceInstallments"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar adiantamento de parcelas ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isAdvanceInstallments"
              />
              <ReactTooltip
                id="isAdvanceInstallments"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Ofereça pontos extras para os usuários anteciparem parcelas.
                  Estabeleça as regras e premiê aqueles que pagarem antes.
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isAdvanceInstallments"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isAdvanceInstallments !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isAdvanceInstallments"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isAdvanceInstallments === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderActiveWhatsapp() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#isActiveWhatsapp"
          aria-expanded="false"
        >
          Ativar o Bot WhatsApp Business?
        </h4>
        <div id="isActiveWhatsapp" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar o Whatsapp ?{' '}
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveWhatsapp !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveWhatsapp === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderFormBackgroundImageMobile() {
    return <div className="row form-group col-md-12" />;
  }

  renderFormBackgroundImage() {
    return (
      <div className="row form-group col-md-12">
        <div>
          <label
            className="profile-label"
            htmlFor="backgrounBrokersDesktop"
            style={{ marginTop: '12px' }}
          >
            {`${this.translate('Imagem da Tela de Login - Versão desktop')}`}
            <br />
            <p className="tipText">
              O box de login já é uma área pré-estabelecida. Toda a área ao
              fundo e ao redor dele pode ser trabalhada com imagem e textos.
            </p>
            <img src={loginDesktop} style={{ height: 150 }} />
          </label>
          <p className="tipText">
            {`${this.translate('recommended')}: 1280x720`}
          </p>
          {this.getState('formData.backgrounBrokersDesktop') &&
            this.renderImagePreview(
              this.getState('formData.backgrounBrokersDesktop'),
              'backgrounBrokersDesktop'
            )}
          <input
            type="file"
            accept="image/*"
            id="backgrounBrokersDesktop"
            onChange={(event) => {
              this.handleUserImageLogin(
                event,
                'ClientsFavIcon/default/',
                'backgrounBrokersDesktop'
              );
            }}
          />
        </div>

        <div>
          <label
            className="profile-label"
            htmlFor="backgroundBrokersMobile"
            style={{ marginTop: '12px' }}
          >
            {`${this.translate('Imagem da Tela de Login - Versão mobile')}`}
            <br />
            <p className="tipText">
              O box de login já é uma área pré-estabelecida. Toda a área ao
              fundo e ao redor dele pode ser trabalhada com imagem e textos.
            </p>
            <img src={loginMobile} style={{ height: 150 }} />
          </label>
          <p className="tipText">
            {`${this.translate('recommended')}: 1280x720`}
          </p>
          {this.getState('formData.backgroundBrokersMobile') &&
            this.renderImagePreview(
              this.getState('formData.backgroundBrokersMobile'),
              'backgroundBrokersMobile'
            )}
          <input
            type="file"
            accept="image/*"
            id="backgroundBrokersMobile"
            onChange={(event) => {
              this.handleUserImageLogin(
                event,
                'ClientsFavIcon/default/',
                'backgroundBrokersMobile'
              );
            }}
          />
        </div>
      </div>
    );
  }

  handleUserImageLogin(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName = `${Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')}.${name.split('.')[1]}`;

    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  imageIsSquare(id) {
    return (
      this.imgEl &&
      this.imgEl.naturalHeight &&
      id === 'favIcon' &&
      this.imgEl.naturalHeight !== this.imgEl.naturalWidth
    );
  }

  renderImagePreview(img, id) {
    return (
      <img
        src={img}
        id={id}
        alt="client favicon"
        ref={(el) => (this.imgEl = el)}
        onLoad={() => {
          if (this.imageIsSquare(id)) {
            // Notify('O ícone da aba precisa ser quadrado.', 'warn');
            this.imgEl = null;
            // this.handleFormDataChange('favIcon', null);
          }
        }}
        className="col-xs-12 img-thumbnail img-responsive form-group"
      />
    );
  }

  renderMGMVinculated() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#mgmVinculated"
          aria-expanded="false"
        >
          MGM Vinculativo
        </h4>
        <div id="mgmVinculated" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="targetPublic">
              Ativar MGM vinculativo ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="mgmVinculated"
              />
              <ReactTooltip
                id="mgmVinculated"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Ativar o MGM vinculativo? Esta opção habilita a vinculação do
                  player indicado ao player anfitrião
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="mgmVinculated"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.mgmVinculated !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="mgmVinculated"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.mgmVinculated === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData.mgmVinculated === 'true' && (
            <div className="row points-by-share col-md-12">
              <label
                style={{ marginTop: '0.5rem' }}
                className="profile-label"
                htmlFor="differentUrlForBrokers"
              >
                Ativar URL distintas?{' '}
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="differentUrlForBrokers"
                />
                <ReactTooltip
                  id="differentUrlForBrokers"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Cada um delas leva a uma tela de cadastro/login com
                    instruções de uso diferentes: uma para o líder das equipes e
                    outra para os prospectores.
                  </span>
                </ReactTooltip>
              </label>
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="differentUrlForBrokers"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={formData.differentUrlForBrokers !== 'true'}
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="differentUrlForBrokers"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={formData.differentUrlForBrokers === 'true'}
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                </div>
              </div>
            </div>
          )}

          {this.state.formData.mgmVinculated === 'true' && (
            <div className="row points-by-share col-md-12">
              <label
                style={{ marginTop: '0.5rem' }}
                className="profile-label"
                htmlFor="activePlatformAwards"
              >
                Ativar premiação para anfitrião?{' '}
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="activePlatformAwards"
                />
                <ReactTooltip
                  id="activePlatformAwards"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Selecione se deseja ativar ou não, a função onde concede
                    acesso as premiações da plataforma.
                  </span>
                </ReactTooltip>
              </label>
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value={false}
                      name="activePlatformAwards"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={formData.activePlatformAwards !== 'true'}
                    />
                    &nbsp;{this.translate('no')}
                  </label>
                  <label className="checkbox-inline">
                    <input
                      type="radio"
                      value
                      name="activePlatformAwards"
                      onChange={(e) => this.handleUserInput(e)}
                      checked={formData.activePlatformAwards === 'true'}
                    />
                    &nbsp;{this.translate('yes')}
                  </label>
                </div>
              </div>
            </div>
          )}

          {this.state.formData.mgmVinculated === 'true' &&
            this.state.formData.differentUrlForBrokers === 'true' &&
            this.renderFormBackgroundImage()}
        </div>
        <hr />
      </div>
    );
  }

  renderActiveRegisterStateAndCity() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#registerPlayer"
          aria-expanded="false"
        >
          Cadastro de Player
        </h4>
        <div id="registerPlayer" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar Cadastro Estado/Cidade ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isActiveRegisterStateAndCity"
              />
              <ReactTooltip
                id="isActiveRegisterStateAndCity"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Esta opção habilita o cadastro de estado e cidade no registro
                  do player
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveRegisterStateAndCity"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterStateAndCity !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveRegisterStateAndCity"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterStateAndCity === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar Cadastro Data de nascimento ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isActiveRegisterBirthDate"
              />
              <ReactTooltip
                id="isActiveRegisterBirthDate"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Esta opção habilita o cadastro de data de nascimento no
                  registro do player
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveRegisterBirthDate"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterBirthDate !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveRegisterBirthDate"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterBirthDate === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Ativar Cadastro Telefone ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isActiveRegisterPhoneNumber"
              />
              <ReactTooltip
                id="isActiveRegisterPhoneNumber"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Esta opção habilita o cadastro de número de telefone no
                  registro do player
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveRegisterPhoneNumber"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterPhoneNumber !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveRegisterPhoneNumber"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterPhoneNumber === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderOptionsInputsForLeads() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#isOptionsInputsForLeads"
          aria-expanded="false"
        >
          Formulário de leads para imobiliárias
        </h4>
        <div
          id="isOptionsInputsForLeads"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Habilitar campos adicionais?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="isOptionsInputsForLeads"
              />
              <ReactTooltip
                id="isOptionsInputsForLeads"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Ativar campos para captura de leads voltados para o mercado
                  imobiliário.
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isOptionsInputsForLeads"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isOptionsInputsForLeads !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isOptionsInputsForLeads"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isOptionsInputsForLeads === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderEntrancePaymentByRegional() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#entrancePaymentByRegional"
          aria-expanded="false"
        >
          Pagamento por regional
        </h4>
        <div
          id="entrancePaymentByRegional"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="targetPublic"
            >
              Pagamento da entrada da carteira por regional ?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="entrancePaymentByRegional"
              />
              <ReactTooltip
                id="entrancePaymentByRegional"
                type="dark"
                effect="solid"
                multiline
              >
                <span>Regionaliza as entradas da carteira</span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="entrancePaymentByRegional"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.entrancePaymentByRegional !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="entrancePaymentByRegional"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.entrancePaymentByRegional === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderEmailToMasterBySegment() {
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#EmailToMaster"
          aria-expanded="false"
        >
          Notificar grupos de acesso
        </h4>
        <div id="EmailToMaster" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              <div className="form-group w-auto">
                <div
                  style={{
                    marginTop: '.5rem',
                    flexDirection: 'column',
                  }}
                  className="d-flex"
                >
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label
                        className="profile-label"
                        htmlFor="sendEmailToAdminMaster"
                        style={{ marginBottom: '16px' }}
                      >
                        Escolher grupo(s) de acesso para envio de e-mail{' '}
                        <i
                          className="fa fa-info-circle"
                          aria-hidden="true"
                          data-tip="React-tooltip"
                          data-for="sendEmailToAdminMaster"
                        />
                        <ReactTooltip
                          id="sendEmailToAdminMaster"
                          type="dark"
                          effect="solid"
                          multiline
                        >
                          <span>
                            Escolher grupo para que ao converter de leads seja
                            enviado e-mail para Administrador da OQ Digital.
                          </span>
                        </ReactTooltip>
                      </label>
                      <Select
                        isMulti
                        name="usersGroup"
                        placeholder="Selecione os usuários..."
                        options={_.map(this.state.groups, (user) => {
                          return {
                            value: user.id,
                            label: user.name,
                          };
                        })}
                        className="basic-multi-select"
                        onChange={(e, context) =>
                          this.handleFormSelectDataChange(
                            'usersGroup',
                            e,
                            context
                          )
                        }
                        value={this.state.formData.usersGroup}
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ display: 'flex' }} />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPointsFromFirstStep() {
    const { formData } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#pointsStepOneConfig"
          aria-expanded="false"
        >
          Pontuação no primeiro step
        </h4>
        <div
          id="pointsStepOneConfig"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="pointInstall"
            >
              Ativar pontuação no primeiro step?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="pointsStepOneConfig"
              />
              <ReactTooltip
                id="pointsStepOneConfig"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  Essa configuração permite que seja atribuída uma pontuação no
                  stágio inicial da entrada do lead
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActivePointsStepOne"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActivePointsStepOne !== 'true'}
                  />
                  Não
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActivePointsStepOne"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActivePointsStepOne === 'true'}
                  />
                  Sim
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderEnabledRegisterButton() {
    const { formData } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#registerButton"
          aria-expanded="false"
        >
          Botão de cadastro
        </h4>
        <div id="registerButton" aria-expanded="false" className="collapse">
          <br />
          <p className="tiny-text">
            Quando esta opção está ativa, será permitido que qualquer pessoa com
            o link do site ou aplicativo programa se cadastre.
          </p>
          <br />
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="pointInstall"
            >
              Ativar botão de cadastro?
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="isActiveRegisterButton"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterButton !== 'true'}
                  />
                  Não
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="isActiveRegisterButton"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.isActiveRegisterButton === 'true'}
                  />
                  Sim
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderAppConfig() {
    const { formData } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#appConfig"
          aria-expanded="false"
        >
          App
        </h4>
        <div id="appConfig" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="pointInstall"
            >
              Ativar pontuação por instalar o app?{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="appConfig"
              />
              <ReactTooltip id="appConfig" type="dark" effect="solid" multiline>
                <span>Ativar pontuação quando o usuário instalar o app.</span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="pointAppIsActive"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.pointAppIsActive !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="pointAppIsActive"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.pointAppIsActive === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label
              style={{ marginTop: '0.5rem' }}
              className="profile-label"
              htmlFor="pointInstall"
            >
              Quantidade de pontos{' '}
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="appConfig"
              />
              <ReactTooltip id="appConfig" type="dark" effect="solid" multiline>
                <span>
                  Quantidade de pontos para o usuário por instalar o app.
                </span>
              </ReactTooltip>
            </label>
            <input
              type="number"
              className="form-control profile-placeholder"
              name="pointsDownloadpwa"
              onChange={(e) => this.handleUserInput(e)}
              value={formData.pointsDownloadpwa}
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  handleFormSelectDataChange(name, value, context) {
    let getRemovedUsers = { ...this.state.formData.usersGroupRemoved };
    const getusersGroup = [...this.state.formData.usersGroup];

    if (context.action === 'remove-value') {
      if (Object.keys(getRemovedUsers).length === 0) {
        getRemovedUsers[context.removedValue.value] = context.removedValue;
      } else {
        getRemovedUsers = {
          ...this.state.formData.usersGroupRemoved,
          [context.removedValue.value]: context.removedValue,
        };
      }
    }

    if (context.action === 'select-option') {
      const findUser = _.find(
        getRemovedUsers,
        (user) => user.value === context.option.value
      );
      if (findUser) {
        delete getRemovedUsers[findUser.value];
      }
    }

    if (context.action === 'clear') {
      if (getusersGroup.length > 0) {
        getusersGroup.map((user) => {
          getRemovedUsers[user.value] = user;
        });
      }
    }

    this.setState((prevState) => {
      return {
        formData: {
          ...prevState.formData,
          [name]: value,
          usersGroupRemoved: getRemovedUsers,
        },
      };
    });
  }

  renderMessageClient() {
    const { formData } = this.state;
    return (
      <div
        id="sendMessage"
        className="form-group col-md-12"
        style={{ padding: '0' }}
      >
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#sendMessageClient"
          aria-expanded="false"
        >
          Mensagem do cliente
        </h4>
        <div id="sendMessageClient" aria-expanded="false" className="collapse">
          <div className="col-12" style={{ display: 'block' }}>
            <div className="form-group col-md-12">
              <label className="profile-label m-0" htmlFor="sendMessageClient">
                Texto para compartilhamento: &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="sendMessageClient"
                />
                <ReactTooltip
                  id="sendMessageClient"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Esta opção personaliza o texto ao compartilhar a publicação
                    no Whatsapp, Facebook, etc. <br /> Ex: &quot;Patrícia tem
                    uma indicação para você&quot;. O nome já busca de forma
                    automática, portanto não precisa colocá-lo no texto.
                  </span>
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                name="sendMessageClient"
                onChange={(e) => this.handleUserInput(e)}
                value={formData.sendMessageClient}
              />
            </div>
          </div>
          <div className="col-12" style={{ display: 'block' }}>
            <div className="form-group col-md-12">
              <label className="profile-label m-0" htmlFor="textIndication">
                Texto da indicação: &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="textIndication"
                />
                <ReactTooltip
                  id="textIndication"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Esta opção personaliza o texto da indicação na landing page
                    do lead. <br />
                    Ex: &quot;Esta é uma indicação especialmente para você&quot;{' '}
                    <br />
                    Para inserir o nome do usuário dinâmico, utilizar a variável
                    $name
                  </span>
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                name="textIndication"
                onChange={(e) => this.handleUserInput(e)}
                value={formData.textIndication}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderExchangeWindow() {
    const { formData } = this.state;
    const { clientData } = this.context;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase18"
          aria-expanded="false"
        >
          Janela de Exchange
        </h4>

        <div id="phase18" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="exchangeWindow">
              Ativar Janela de Exchange? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="exchangeWindow"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="exchangeWindow"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.exchangeWindow !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="exchangeWindow"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.exchangeWindow === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderAwardsForm() {
    const { formData } = this.state;
    const { awards } = this.state;

    formData.amountOfGoals = formData.amountOfGoals
      ? Number(formData.amountOfGoals)
      : 0;

    const auxArray = [];
    for (let i = 0; i < formData.amountOfGoals; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-12 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de Metas:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              id="amountOfGoals"
              name="amountOfGoals"
              value={formData.amountOfGoals}
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
        </div>
        {formData.amountOfGoals && formData.amountOfGoals > 0
          ? auxArray.map((item, index) => {
              return this.renderGoalsInput(index);
            })
          : console.log('Nenhum prêmio cadastrado')}
      </div>
    );
  }

  renderGoalsInput(index) {
    const { formErrors, fields, formData } = this.state;
    const { awards } = this.state;
    const { clientData } = this.context;
    return (
      <div>
        <div className="col-md-12">
          <label
            htmlFor="levelName profile-placeholder"
            className="profile-label"
          />
          <h6
            className="arrow-toggle clickable collapsed m-top-0 "
            data-toggle="collapse"
            data-target={`#input${index}`}
            aria-expanded="false"
          >
            Meta {Number(index) + 1}
          </h6>
          <div id={`input${index}`} aria-expanded="false" className="collapse">
            <div className="row">
              <div className="form-group col-md-12">
                <label
                  className="form__field oq-margin-right profile-label"
                  htmlFor="premiumOfGoals"
                >
                  Selecione o premio
                </label>
                <select
                  name="premiumOfGoals"
                  id="premiumOfGoals"
                  className="form-control profile-placeholder m-10 w-50"
                  required
                  onChange={(e) => this.handleFieldsForGoals(e, index)}
                  value={
                    formData.premiumOfGoals[index] && [
                      formData.premiumOfGoals[index].id,
                      formData.premiumOfGoals[index].price,
                    ]
                  }
                  disabled={
                    !!(
                      clientData.premiumOfGoals &&
                      clientData.premiumOfGoals[index]
                    )
                  }
                >
                  <option value="">Selecione um prêmio</option>
                  {awards.length > 0 &&
                    awards.map((award) => {
                      let i = 0;
                      let exist = false;
                      while (i < formData.amountOfGoals) {
                        if (
                          formData.premiumOfGoals[i] &&
                          award.premiumId === formData.premiumOfGoals[i].id
                        ) {
                          exist = true;
                        }
                        i++;
                      }
                      return (
                        <option
                          value={[award.premiumId, award.premiumPrice]}
                          disabled={exist}
                          key={award.premiumName}
                        >
                          {award.premiumName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBenefitsConfig() {
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase8"
          aria-expanded="false"
        >
          Benefícios
        </h4>
        <div id="phase8" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="buttonNameBenefit">
                Nome do botão de benefícios<code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="buttonNameBenefit"
                placeholder="Nome do botão de benefícios"
                onChange={(e) => {
                  this.handleUserInput(e);
                }}
                value={this.state.formData.buttonNameBenefit}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12" style={{ display: 'flex' }}>
              <div className="form-group col-md-12">
                <label
                  className="profile-label"
                  htmlFor="useRegionalizationBenefit"
                >
                  Ativar segmentação dos benefícios?
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="useRegionalizationBenefit"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.useRegionalizationBenefit !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="useRegionalizationBenefit"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.useRegionalizationBenefit === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>

            {this.state.formData.useRegionalizationBenefit === 'true' ? (
              <div className="form-group w-100 col-md-6">
                <label className="profile-label" htmlFor="regionalBenefitName">
                  {this.translate('Segmentar por')} *
                </label>
                <input
                  type="text"
                  name="regionalBenefitName"
                  value={this.state.formData.regionalBenefitName}
                  onChange={(e) => {
                    this.handleUserInput(e);
                  }}
                  className="form-control profile-placeholder"
                />
                <br />
                <label className="profile-label" htmlFor="regionalBenefit">
                  {this.translate('Tags para segmentação')} *
                </label>
                <TagsInput
                  required
                  inputProps={{
                    placeholder:
                      this.state.formData.regionalBenefitName === '' &&
                      'Cidades',
                  }}
                  name="regionalBenefit"
                  value={this.state.formData.regionalBenefit}
                  onChange={this.handleTagsChangeRegionalBenefit.bind(this)}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="col-12" style={{ display: 'flex' }}>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="regionalBenefit">
                {this.translate('Logo para benefícios')}
              </label>
              <br />
              <input
                type="file"
                accept="image/*"
                name="benefitLogo"
                // value={this.state.formData.benefitLogo}
                onChange={async (e) =>
                  await this.handleBenefitsLogo(
                    e,
                    'ClientsLogo/default',
                    'benefitLogo'
                  )
                }
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderInputsAmountSelect(
    item,
    index,
    positionField
    // amountOfSelectsIndexField,
    // auxArray
  ) {
    const { fields, selects } = this.state;

    const selectsIndex = selects || [];

    selectsIndex[index] = selectsIndex[index] ? selectsIndex[index] : {};

    return (
      <div>
        <div className="col-md-12">
          <label htmlFor="levelName" className="profile-label" />
          <h6
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#select${index}-${positionField}`}
            aria-expanded="false"
          >
            <strong>Opção {Number(index) + 1}</strong>
          </h6>
          <div
            id={`select${index}-${positionField}`}
            aria-expanded="false"
            className="collapse"
          >
            <div className="row">
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="titleFieldSelect">
                  Título da opção
                </label>

                <input
                  className="form-control profile-placeholder"
                  id="titleFieldSelect"
                  name="titleFieldSelect"
                  required
                  placeholder="Título do campo"
                  onChange={(e) =>
                    this.handleFieldsChangeSelect(e, index, positionField)
                  }
                  value={
                    fields[positionField][index] &&
                    fields[positionField][index].titleFieldSelect
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="nameFieldSelect">
                  Nome da opção
                </label>

                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  id="nameFieldSelect"
                  name="nameFieldSelect"
                  required
                  placeholder="Nome do campo"
                  value={
                    fields[positionField][index] &&
                    fields[positionField][index].nameFieldSelect
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAmountOfSelect(indexField, fieldsIndex) {
    const { formData, fields } = this.state;
    const positionField = fields[indexField];

    const amountSelect =
      formData.amountOfSelects[indexField] &&
      formData.amountOfSelects[indexField].amountOfSelects
        ? Number(formData.amountOfSelects[indexField].amountOfSelects)
        : 0;

    const auxArray = {};
    for (let i = 0; i < amountSelect; i++) {
      auxArray[i] = i;
    }
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="titleListField">
              Título da Lista
            </label>

            <input
              className="form-control profile-placeholder"
              id="titleListField"
              name="titleListField"
              required
              placeholder="Título da Lista"
              onChange={(e) => this.handleFieldsChange(e, indexField)}
              value={fields[indexField] && fields[indexField].titleListField}
            />
            <input
              className="form-control profile-placeholder"
              id="normalizeTitleListField"
              name="normalizeTitleListField"
              required
              type="hidden"
              // value={fields[indexField] && fields[indexField].normalizeTitleListField}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <label
              className="form__field oq-margin-right profile-label"
              htmlFor="amountOfSelects"
            >
              Quantidade de opções
            </label>
            <select
              name="amountOfSelects"
              id="amountOfSelects"
              className="form-control profile-placeholder m-10 w-25"
              required
              onChange={(e) => this.handleAmountSelect(e, indexField)}
              value={
                formData.amountOfSelects[indexField] &&
                formData.amountOfSelects[indexField].amountOfSelects
              }
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) =>
          this.renderInputsAmountSelect(
            item,
            index,
            indexField
            // formData.amountOfSelects[indexField].indexField,
            // auxArray
          )
        )}
      </>
    );
  }

  renderAmountOfInputs(indexField, index) {
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="titleField">
              Título do campo
            </label>

            <input
              className="form-control profile-placeholder"
              id="titleField"
              name="titleField"
              required
              placeholder="Título do campo"
              // onChange={event => this.handleUserInput(event)}
              onChange={(e) => this.handleFieldsChange(e, index)}
              value={indexField.titleField}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="nameField">
              Nome do campo
            </label>

            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              id="nameField"
              name="nameField"
              required
              placeholder="Nome do campo"
              // onChange={e => this.handleFieldsChange(e, index)}
              value={indexField.nameField}
            />
          </div>
        </div>
      </>
    );
  }

  renderAmountInput(item, index, auxArray) {
    const { fields } = this.state;

    const fieldIndex = fields || [];

    fieldIndex[index] = fieldIndex[index] ? fieldIndex[index] : {};

    return (
      <div>
        <div className="col-md-12">
          <label
            htmlFor="levelName profile-placeholder"
            className="profile-label"
          />
          <h6
            className="arrow-toggle clickable collapsed m-top-0 "
            data-toggle="collapse"
            data-target={`#input${index}`}
            aria-expanded="false"
          >
            Campo {Number(index) + 1}
          </h6>
          <div id={`input${index}`} aria-expanded="false" className="collapse">
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  className="form__field oq-margin-right profile-label"
                  htmlFor="typeField"
                >
                  Tipo do campo
                </label>
                <select
                  name="typeField"
                  id="typeField"
                  className="form-control profile-placeholder m-10 w-50"
                  required
                  onChange={(e) => this.handleFieldsChange(e, index)}
                  value={fieldIndex[index].typeField}
                >
                  <option value="">Selecione</option>
                  <option value="text">Texto</option>
                  <option value="date">Data</option>
                  <option value="number">Número</option>
                  <option value="select">Lista de opções</option>
                </select>
              </div>
            </div>

            {fieldIndex[index].typeField === 'select'
              ? this.renderAmountOfSelect(index, fieldIndex[index])
              : this.renderAmountOfInputs(fieldIndex[index], index)}
          </div>
        </div>
      </div>
    );
  }

  renderIndicationForm() {
    const { formData } = this.state;

    formData.amountOfInputs = formData.amountOfInputs
      ? Number(formData.amountOfInputs)
      : 0;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfInputs; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-12 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de Campos:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              id="amountOfInputs"
              name="amountOfInputs"
              value={formData.amountOfInputs}
            >
              <option value={0}>0</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={11}>11</option>
              <option value={12}>12</option>
              <option value={13}>13</option>
              <option value={14}>14</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) =>
          this.renderAmountInput(item, index, auxArray)
        )}
      </div>
    );
  }

  renderInfluencerForm() {
    const { formData } = this.state;

    formData.amountOfHierarchy = formData.amountOfHierarchy
      ? Number(formData.amountOfHierarchy)
      : 0;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfHierarchy; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <label className="profile-label m-0" htmlFor="number">
          Numero de hierarquia:
        </label>
        <select
          className="form-control profile-placeholder m-10 w-25"
          onChange={(e) => this.handleUserInput(e)}
          id="amountOfHierarchy"
          name="amountOfHierarchy"
          value={formData.amountOfHierarchy}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
          <option value={11}>11</option>
          <option value={12}>12</option>
          <option value={13}>13</option>
          <option value={14}>14</option>
        </select>

        {_.map(auxArray, (item, index) =>
          this.renderAmountHierarchy(item, index, auxArray)
        )}
      </div>
    );
  }

  renderAmountHierarchy(item, index, auxArray) {
    const { formErrors, fields, formData } = this.state;

    const fieldIndex = fields || [];

    fieldIndex[index] = fieldIndex[index] ? fieldIndex[index] : {};

    return (
      <div>
        <div className="col-md-12">
          <label
            htmlFor="levelName profile-placeholder"
            className="profile-label"
          />
          <h6
            className="arrow-toggle clickable collapsed m-top-0 "
            data-toggle="collapse"
            data-target={`#input${index}`}
            aria-expanded="false"
          >
            Campo {Number(index) + 1}
          </h6>
          <div id={`input${index}`} aria-expanded="false" className="collapse">
            <div className="row">
              <div className="form-group col-md-6">
                <label
                  className="form__field oq-margin-right profile-label"
                  htmlFor="typeField"
                >
                  Tipo do campo
                </label>
                <select
                  name="typeField"
                  id="typeField"
                  className="form-control profile-placeholder m-10 w-50"
                  required
                  onChange={(e) => this.handleFieldsChange(e, index)}
                  value={fieldIndex[index].typeField}
                >
                  <option value="">Selecione</option>
                  <option value="text">Texto</option>
                  <option value="date">Data</option>
                  <option value="number">Número</option>
                  <option value="select">Lista de opções</option>
                </select>
              </div>
            </div>

            {this.renderAmountOfInputs(fieldIndex[index], index)}
          </div>
        </div>
      </div>
    );
  }

  renderIndicationsConfig() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#indicationConfig"
          aria-expanded="false"
        >
          Indicações
        </h4>
        <div id="indicationConfig" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="indicationEnable">
              Ativar formulário personalizado do lead ? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="indicationEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="indicationEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.indicationEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="indicationEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.indicationEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="localizationEnable">
              Exibir campos de localização? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="localizationEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="localizationEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.localizationEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="localizationEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.localizationEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="phoneEnable">
              Exibir telefone? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="phoneEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="phoneEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.phoneEnable !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="phoneEnable"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.phoneEnable === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="phoneEnableHost">
              Exibir telefone para o player anfitrião? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="phoneEnableHost"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="phoneEnableHost"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.phoneEnableHost !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="phoneEnableHost"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.phoneEnableHost === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          {formData.indicationEnable === 'true' && this.renderIndicationForm()}
        </div>
        <hr />
      </div>
    );
  }

  renderCounts() {
    const { loadingNewToken, formData } = this.state;
    let i = formData.counterNames.length;

    const oldCounts = formData.counterNames;
    const oldCountsArray = [];

    if (typeof formData.counterNames === 'object') {
      i = Object.keys(formData.counterNames).length;
    } else {
      i = formData.counterNames.length;
    }

    _.map(oldCounts, (count) => {
      oldCountsArray.push(count);
    });

    if (i <= 8) {
      for (i; i < 8; i++) {
        let teste = i;
        teste = {};
        oldCountsArray.push(teste);
      }
    }

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase6"
          aria-expanded="false"
        >
          Engajamentos
        </h4>
        <div id="phase6" aria-expanded="false" className="collapse">
          <div className="">
            {_.map(formData.steps, (step, index) => (
              <div className="col-12" key={index}>
                <div className="form-group col-md-6">
                  <label className="profile-label" htmlFor="count">
                    {this.renderNamesCounts(index)}
                  </label>
                  <input
                    type="text"
                    className="form-control profile-placeholder"
                    name="name"
                    onChange={(e) => {
                      this.handleChangeCounter(e, index);
                    }}
                    // key={i}
                    value={
                      formData.counterNames[index]
                        ? formData.counterNames[index].name
                        : ''
                    }
                    placeholder="Digite nome a sua escolha para o contador"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  /* Adicionei agora FIM */

  renderCustomFormLeads() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#CustomFormLeads"
          aria-expanded="false"
        >
          Formulário de Leads
        </h4>
        <div id="CustomFormLeads" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="cpfEnable">
              Exibir o campo &quot;CPF&quot; ao cadastrar lead? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="cpfEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="cpf"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.cpf &&
                      this.state.formData.customFormLead.cpf !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="cpf"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.cpf &&
                      this.state.formData.customFormLead.cpf === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="emailEnable">
              Exibir o campo &quot;Email&quot; ao cadastrar lead? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="emailEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="email"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.email &&
                      this.state.formData.customFormLead.email !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="email"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.email &&
                      this.state.formData.customFormLead.email === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="phoneEnable">
              Exibir o campo &quot;Telefone&quot; ao cadastrar lead? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="phoneEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="phone"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.phone &&
                      this.state.formData.customFormLead.phone !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="phone"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.phone &&
                      this.state.formData.customFormLead.phone === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="cityEnable">
              Exibir o campo Cidade e Estado ao cadastrar lead? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="cityEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="cityAndState"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.cityAndState &&
                      this.state.formData.customFormLead.cityAndState !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="cityAndState"
                    onChange={(e) => this.handleCustomFormLead(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.customFormLead &&
                      this.state.formData.customFormLead.cityAndState &&
                      this.state.formData.customFormLead.cityAndState === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-12">
            <div
              className="col-md-12"
              style={{ padding: '0', marginBottom: '12px' }}
            >
              <label className="profile-label m-0" htmlFor="privateTermsClient">
                Texto para termos de privacidade: &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="privateTermsClient"
                />
                <ReactTooltip
                  id="privateTermsClient"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>
                    Você pode editar o texto de aceite de acordo com a
                    orientação do Jurídico da sua empresa em relação a LGPD.
                  </span>
                </ReactTooltip>
              </label>
              <input
                type="text"
                className="form-control profile-placeholder"
                name="privateTermsClient"
                onChange={(e) => this.handleCustomFormLead(e)}
                value={
                  formData.customFormLead &&
                  formData.customFormLead.privateTermsClient
                    ? formData.customFormLead.privateTermsClient
                    : `Li e aceito a Política de Privacidade e concordo que a empresa entre em contato comigo.`
                }
              />
              <span style={{ marginTop: '16px' }}>
                <a
                  className="clickable"
                  href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPOLITICA%20DE%20PRIVACIDADE%20.pdf?alt=media&token=36efa8dc-c5b2-490a-8185-02c940c684fe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b> Política de Privacidade </b>
                </a>
              </span>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderCustomRedirectUrlLeads() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#customRedirectUrlLeads"
          aria-expanded="false"
        >
          Redirecionamento
        </h4>
        <div
          id="customRedirectUrlLeads"
          aria-expanded="false"
          className="collapse"
        >
          {/* <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="redirectWhatsapp">
              Habilitar o contato direto do lead com o vendedor? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="call-action"
              />
              <ReactTooltip
                id="call-action"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>
                  Ao habilitar esta função, o lead terá a opção de falar, via
                  WhatsApp, direto com o vendedor que compartilhou o conteúdo,
                  agilizando o primeiro contato.
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="redirectWhatsapp"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="redirectWhatsapp"
                    onChange={e => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div> */}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="redirectUrl">
              Habilitar o redirecionamento de url ao cadastrar leads? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="redirectUrl"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="redirectUrlLead"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectUrlLead &&
                      this.state.formData.redirectUrlLead !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="redirectUrlLead"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectUrlLead &&
                      this.state.formData.redirectUrlLead === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="urlRedirect">
              Url de redirecionamento
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="urlRedirect"
              name="urlRedirect"
              required
              value={this.getState('formData.urlRedirect')}
              placeholder="Url de redirecionamento"
            />
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderCustomIntegration() {
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#customIntegration"
          aria-expanded="false"
        >
          Integração Customizada
        </h4>
        <div id="customIntegration" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="scaleEnable">
              Habilitar o envio de novos leads? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="scaleEnable"
              />
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="active"
                    onChange={(e) => this.handleIntegration(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.integration &&
                      (!this.state.formData.integration.customLead ||
                        (this.state.formData.integration.customLead &&
                          this.state.formData.integration.customLead.active &&
                          this.state.formData.integration.customLead.active !==
                            'true'))
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="active"
                    onChange={(e) => this.handleIntegration(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.integration &&
                      this.state.formData.integration.customLead &&
                      this.state.formData.integration.customLead.active &&
                      this.state.formData.integration.customLead.active ===
                        'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>
          {/**
           *
           * aqui começa
           */}
          {this.state.formData &&
            this.state.formData.integration &&
            this.state.formData.integration.customLead &&
            this.state.formData.integration.customLead.active &&
            this.state.formData.integration.customLead.active === 'true' && (
              <>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="crmSelected">
                      CRM
                    </label>
                    <select
                      className="form-control profile-placeholder"
                      onChange={(event) => this.handleIntegration(event)}
                      id="crmSelected"
                      name="crmSelected"
                      aria-describedby="crmSelectedHelp"
                      value={this.getState(
                        'formData.integration.customLead.crmSelected'
                      )}
                    >
                      <option value="">
                        {this.translate('customIntegration')}
                      </option>
                      <option value="construtorvendas">
                        {this.translate('Construtor de Vendas')}
                      </option>
                      <option value="hypnobox">
                        {this.translate('Hypnobox')}
                      </option>
                      <option value="anapro">{this.translate('Anapro')}</option>
                      <option value="followize">
                        {this.translate('Followize')}
                      </option>

                      {/* <option value="rdstation">
                        {this.translate('RD Station')}
                      </option> */}
                    </select>
                    {/* {this.getError('crmSelected') && (
                    <small
                      id="crmSelectedHelp"
                      className="form-text text-muted"
                    >
                      {this.getError('crmSelected')}
                    </small>
                  )} */}
                  </div>
                  {this.state.formData.integration.customLead.crmSelected &&
                    this.state.formData.integration.customLead.crmSelected !==
                      '' && (
                      <>
                        <div className="form-group col-md-12">
                          <label
                            className="profile-label"
                            htmlFor="scale-current"
                          >
                            Configurações
                          </label>
                          <ReactJson
                            style={{
                              padding: '15px',
                              borderRadius: '3px',
                              margin: '10px 0px',
                            }}
                            name={false}
                            src={
                              this.state.formData.integration.customLead
                                .configuration
                            }
                            theme="monokai"
                            iconStyle="triangle"
                            collapseStringsAfterLength={50}
                            indentWidth={4}
                            collapsed={false}
                            displayDataTypes={false}
                            enableClipboard={false}
                            onEdit={(edit) =>
                              this.handleChangeConfigurationIntegration(edit)
                            }
                            onAdd={(add) =>
                              this.handleChangeConfigurationIntegration(add)
                            }
                            onDelete={(del) =>
                              this.handleChangeConfigurationIntegration(del)
                            }
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <label
                            className="profile-label"
                            htmlFor="scale-current"
                          >
                            Corpo da Requisição (Somente campos fixos)
                            {/* <i
                        className="fa fa-info-circle"
                        aria-hidden="true"
                        data-tip="React-tooltip"
                        data-for="bodyParams"
                      />
                      <ReactTooltip
                        id="bodyParams"
                        type="dark"
                        effect="solid"
                        multiline
                      >
                        <span>Campos fixos</span>
                      </ReactTooltip> */}
                          </label>
                          <ReactJson
                            style={{
                              padding: '15px',
                              borderRadius: '3px',
                              margin: '10px 0px',
                            }}
                            name={false}
                            src={
                              this.state.formData.integration.customLead
                                .parameters
                            }
                            theme="monokai"
                            iconStyle="triangle"
                            collapseStringsAfterLength={50}
                            indentWidth={4}
                            collapsed={false}
                            displayDataTypes={false}
                            enableClipboard={false}
                            onEdit={(edit) =>
                              this.handleChangeParametersIntegration(edit)
                            }
                            onAdd={(add) =>
                              this.handleChangeParametersIntegration(add)
                            }
                            onDelete={(del) =>
                              this.handleChangeParametersIntegration(del)
                            }
                          />
                        </div>
                      </>
                    )}
                </div>

                {/* <div className="row points-by-share col-md-12">
                  <label className="profile-label" htmlFor="scaleEnable">
                    Método de envio:
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="scaleEnable"
                    />
                  </label>
                  <div className="form-group col-md-4">
                    <div className="profile-placeholder d-flex justify-content-between">
                      <label className="checkbox-inline">
                        <input
                          type="radio"
                          value="GET"
                          name="method"
                          onChange={e => this.handleIntegration(e)}
                          checked={
                            this.state.formData.integration.customLead
                              .method === 'GET'
                          }
                        />
                        GET
                      </label>
                      <label className="checkbox-inline">
                        <input
                          type="radio"
                          value="POST"
                          name="method"
                          onChange={e => this.handleIntegration(e)}
                          checked={
                            this.state.formData.integration.customLead
                              .method === 'POST'
                          }
                        />
                        POST
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="scale-current">
                      Endereço do endpoint
                    </label>
                    <input
                      className="form-control profile-placeholder"
                      onChange={e => this.handleIntegration(e)}
                      id="uri"
                      name="uri"
                      required
                      value={this.getState(
                        'formData.integration.customLead.uri'
                      )}
                      placeholder="URI"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="scale-current">
                      Cabeçalho token
                    </label>
                    <input
                      className="form-control profile-placeholder"
                      onChange={e => this.handleIntegration(e)}
                      id="token"
                      name="token"
                      required
                      value={this.getState(
                        'formData.integration.customLead.token'
                      )}
                      placeholder="Token"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label className="profile-label" htmlFor="scale-current">
                      Body
                    </label>
                    <textarea
                      rows={10}
                      className="form-control profile-placeholder"
                      onChange={e => this.handleIntegration(e)}
                      id="body"
                      name="body"
                      required
                      value={this.getState(
                        'formData.integration.customLead.body'
                      )}
                      placeholder="URI"
                    />
                  </div>
                </div> */}
              </>
            )}
          {/**
           *
           * aqui termina
           */}
        </div>
        <hr />
      </div>
    );
  }

  handleIconsShareDashboard(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        iconsShareDashboard: {
          ...prevState.formData.iconsShareDashboard,
          [name]: value,
        },
      },
    }));
  }

  // renderIntegrationPayPal() {
  //   const { formData } = this.state;
  //   return (
  //     <div>
  //       <h4
  //         className="arrow-toggle clickable collapsed"
  //         data-toggle="collapse"
  //         data-target="#integrationPaypal"
  //         aria-expanded="false"
  //       >
  //         Integração Paypal
  //       </h4>
  //       <div id="integrationPaypal" aria-expanded="false" className="collapse">
  //         {/**
  //          *
  //          * aqui começa
  //          */}
  //         {this.state.formData &&
  //           this.state.formData.integration &&
  //           this.state.formData.integration.paypal &&
  //           this.state.formData.integration.paypal.active &&
  //           this.state.formData.integration.paypal.active === 'true' && (
  //             <>
  //               <div className="row">
  //                 <div className="form-group col-md-6">
  //                   <label className="profile-label" htmlFor="scale-current">
  //                     Client ID
  //                   </label>
  //                   <input
  //                     className="form-control profile-placeholder"
  //                     onChange={e => this.handleIntegration(e)}
  //                     id="clientId"
  //                     name="clientId"
  //                     required
  //                     value={this.getState(
  //                       'formData.integration.paypal.clientId'
  //                     )}
  //                     placeholder="clientId"
  //                   />
  //                 </div>
  //               </div>
  //               <div className="row">
  //                 <div className="form-group col-md-6">
  //                   <label className="profile-label" htmlFor="scale-current">
  //                     Secret
  //                   </label>
  //                   <input
  //                     className="form-control profile-placeholder"
  //                     onChange={e => this.handleIntegration(e)}
  //                     id="secret"
  //                     name="secret"
  //                     required
  //                     value={this.getState(
  //                       'formData.integration.paypal.secret'
  //                     )}
  //                     placeholder="Secret"
  //                   />
  //                 </div>
  //               </div>
  //             </>
  //           )}
  //         {/**
  //          *
  //          * aqui termina
  //          */}
  //       </div>
  //       <hr />
  //     </div>
  //   );
  // }+

  renderIconsShareDashboard() {
    const { formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#enableIconsShareDashboardCollapsed"
          aria-expanded="false"
        >
          One click to share
        </h4>

        <div
          id="enableIconsShareDashboardCollapsed"
          aria-expanded="false"
          className="collapse"
        >
          <div className="row points-by-share col-md-12">
            <label
              className="profile-label"
              htmlFor="enableIconsShareDashboard"
            >
              Ativar one click to share? &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="call-action"
              />
              <ReactTooltip
                id="call-action"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>
                  Esta é uma forma de encurtar o caminho e facilitar o
                  compartilhamento do conteúdo. Os ícones de compartilhamento
                  ficarão no card e o usuário não precisa abrir os detalhes e
                  regras da publicação para viralizar o post. Escolha até 5
                  ícones.
                </span>
              </ReactTooltip>
            </label>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    name="enableIconsShareDashboard"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableIconsShareDashboard !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="enableIconsShareDashboard"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={formData.enableIconsShareDashboard === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {formData.enableIconsShareDashboard === 'true' && (
            <>
              <div className="col-md-12">
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="whatsapp">
                      Whatsapp &nbsp;
                    </label>

                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="whatsapp"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .whatsapp === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="whatsapp"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .whatsapp !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="facebook">
                      Facebook &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="facebook"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .facebook === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="facebook"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .facebook !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="telegram">
                      Telegram &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="telegram"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .telegram === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="telegram"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .telegram !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="twitter">
                      Twitter &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="twitter"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .twitter === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="twitter"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .twitter !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="pinterest">
                      Pinterest &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="pinterest"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .pinterest === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="pinterest"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .pinterest !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="copyLink">
                      Copiar link &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="copyLink"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .copyLink === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="copyLink"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .copyLink !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="instagram">
                      Instagram &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="instagram"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .instagram === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="instagram"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .instagram !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="emailDashboard">
                      Email &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="emailDashboard"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .emailDashboard === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="emailDashboard"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .emailDashboard !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="linkedin">
                      Linkedin &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="linkedin"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .linkedin === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="linkedin"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .linkedin !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row points-by-share col-md-6">
                  <div className="wpp-social d-flex w-100">
                    <label className="profile-label" htmlFor="messenger">
                      Messenger &nbsp;
                    </label>
                    <div className="form-group col-md-8">
                      <div className="profile-placeholder d-flex justify-content-between">
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value
                            name="messenger"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .messenger === 'true'
                            }
                          />
                          &nbsp;{this.translate('yes')}
                        </label>
                        <label className="checkbox-inline">
                          <input
                            type="radio"
                            value={false}
                            name="messenger"
                            onChange={(e) => this.handleIconsShareDashboard(e)}
                            checked={
                              this.state.formData.iconsShareDashboard
                                .messenger !== 'true'
                            }
                          />
                          &nbsp;{this.translate('no')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-10 col-md-offset-1">
        {this.renderConfig()}
        {clientData.mgmVinculated &&
          clientData.mgmVinculated === 'true' &&
          this.renderConfigRanking()}

        {this.renderPhase3()}
        {/* {this.renderSecondSegmentation()} */}
        {formData.useIntegration === 'enabled' && this.renderIntegration()}
        {formData.useIntegration === 'enabled' &&
          this.renderCustomIntegration()}

        {formData.useIntegration === 'enabled' && formData.steps.length > 0
          ? this.renderSteps()
          : null}
        {this.renderPointsFromFirstStep()}
        {this.renderEnabledRegisterButton()}
        {formData.moduleEngagements === 'true' && this.renderCounts()}

        {formData.moduleBenefits === 'true' && this.renderBenefitsConfig()}

        {/* {this.renderVouchers()} */}

        {/* {this.renderIndicationsConfig()} */}
        {formData.useIntegration === 'enabled' &&
          this.renderIndicationsConfig()}

        {this.renderSendEmails()}
        {clientData.isTeamCompetition &&
          clientData.isTeamCompetition === 'true' &&
          this.renderInfluencers()}

        {
          // formData.secondarySegmentationChoice === 'true' &&
          //this.renderRoleConfig()
        }

        {this.renderCustomFormLeads()}

        {this.renderCustomRedirectUrlLeads()}

        {this.renderRescueConfig()}

        {this.context.clientData.isGamified === 'true' &&
          this.renderExchangeWindow()}

        {/* {this.renderIconsShareDashboard()} */}

        {this.renderStepManualConfig()}

        {this.renderTutorialConfig()}

        {this.renderEnableSessionsLandingPageLogin()}

        {this.renderNumberOfDaysToRedeem()}

        {this.renderActiveIara()}

        {this.renderActiveWhatsapp()}

        {this.renderMessageClient()}

        {this.renderMGMVinculated()}

        {this.renderActiveInstallmentsAdvence()}

        {this.renderActiveRegisterStateAndCity()}

        {this.renderOptionsInputsForLeads()}

        {this.renderEmailToMasterBySegment()}

        {this.renderAppConfig()}

        {this.state.formData.integration &&
          this.state.formData.integration.transfeera &&
          this.state.formData.integration.transfeera.active === 'true' &&
          this.renderEntrancePaymentByRegional()}

        <div className="row oq-padding-vertical">
          {this.renderUpdateButton()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              {this.renderBackButton()}
              <h1
                className="oq-filter-title"
                style={{ marginBottom: '5%', color: '#000' }}
              >
                <i className="fa fa-edit" />
                &nbsp;
                <span>&nbsp; {this.translate('Configurações')}</span>
              </h1>
              <div className="row">
                <div className="col-md-12">{this.renderForm()}</div>
              </div>
              {this.confirmBeforeUpdateAuthorizedUsers()}
              {this.confirmBeforeUploadVoucher()}
              {this.confirmBeforeUploadRoles()}
              {this.confirmBeforeUploadInfluencers()}
              {this.confirmUpdateVouchers()}
              {this.confirmUploadInfluencers()}
              {this.confirmUpdateAuthorizedUsers()}
              {this.confirmUpdateRoles()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ConfigLink = (props) => (
  <Link className="menu-link collapsed list-unstyled" to={routes.CONFIG}>
    <FontAwesomeIcon icon={faTools} />
    &nbsp;
    <span className="sidebarText">{props.translate('Configurações')}</span>
  </Link>
);

export default Config;

export { ConfigLink };
