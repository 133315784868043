import React from 'react';

import { Switch, withRouter, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import NotFoundPage from '../../NotFound';

import PlayerDetailsQuiz from '../../../AdminClient/pages/Actions/Quiz/PlayerDetailsQuiz';
import PlayerDetailsResearchAward from '../../../AdminClient/pages/Actions/Research/PlayerDetailsResearchAward';
import Company from '../../../AdminClient/pages/Company';
import LeadRegister from '../../../AdminClient/pages/LeadRegister';
import Profile from '../../../AdminClient/pages/Profile';

import Tutorials from '../../../AdminClient/pages/Tutorials';
import Welcome from '../../../AdminClient/pages/Welcome';

import PlayerRankingPage from '../../../AdminClient/pages/PlayerRanking';
import PontuationRulesPage from '../../../AdminClient/pages/PontuationRules';
import RankingCorrectors from '../../../AdminClient/pages/RankingCorrectors';
import RankingIndications from '../../../AdminClient/pages/RankingIndications';

import DetailsWallet from '../../../AdminClient/pages/Wallet/DetailsWallet';
import HistoricalWallet from '../../../AdminClient/pages/Wallet/HistoricalWallet';

// Rotas de dashboard
import Dashboard from '../../../AdminClient/pages/Dashboards/Dashboard';
import DashboardQuiz from '../../../AdminClient/pages/Dashboards/DashboardQuiz';
import DashboardResearchAward from '../../../AdminClient/pages/Dashboards/DashboardResearchAward';
import DashboardGamification from '../../../AdminClient/pages/Dashboards/DashboardGamification';

// Rotas de publicação
import ActionsVirtuals from '../../../AdminClient/pages/Actions/Virtual/ActionsVirtuals';
import CreateActionVirtual from '../../../AdminClient/pages/Actions/Virtual/CreateActionVirtual';
import DetailsActionVirtual from '../../../AdminClient/pages/Actions/Virtual/DetailsActionVirtual';
import EditActionVirtual from '../../../AdminClient/pages/Actions/Virtual/EditActionVirtual';
import PreviewActionVirtual from '../../../AdminClient/pages/Actions/Virtual/PreviewActionVirtual';

import ActionsQuiz from '../../../AdminClient/pages/Actions/Quiz/ActionsQuiz';
import CreateActionQuiz from '../../../AdminClient/pages/Actions/Quiz/CreateActionQuiz';
import DetailsActionQuiz from '../../../AdminClient/pages/Actions/Quiz/DetailsActionQuiz';
import EditActionQuiz from '../../../AdminClient/pages/Actions/Quiz/EditActionQuiz';

import ActionsOmnichannels from '../../../AdminClient/pages/Actions/Omnichannel/ActionsOmnichannels';
import CreateActionOmnichannel from '../../../AdminClient/pages/Actions/Omnichannel/CreateActionOmnichannel';
import DetailsActionOmnichannel from '../../../AdminClient/pages/Actions/Omnichannel/DetailsActionOmnichannel';
import EditActionOmnichannel from '../../../AdminClient/pages/Actions/Omnichannel/EditActionOmnichannel';

import ActionsInformationals from '../../../AdminClient/pages/Actions/Informational/ActionsInformationals';
import CreateActionInformational from '../../../AdminClient/pages/Actions/Informational/CreateActionInformational';
import DetailsActionInformational from '../../../AdminClient/pages/Actions/Informational/DetailsActionInformational';
import EditActionInformational from '../../../AdminClient/pages/Actions/Informational/EditActionInformational';

import ActionsInvites from '../../../AdminClient/pages/Actions/Invite/ActionsInvites';
import CreateActionInvite from '../../../AdminClient/pages/Actions/Invite/CreateActionInvite';
import DetailsActionInvite from '../../../AdminClient/pages/Actions/Invite/DetailsActionInvite';
import EditActionInvite from '../../../AdminClient/pages/Actions/Invite/EditActionInvite';

import CreateResearchAward from '../../../AdminClient/pages/Actions/Research/CreateResearchAward';
import EditResearchAward from '../../../AdminClient/pages/Actions/Research/EditResearchAward';
import ResearchAward from '../../../AdminClient/pages/Actions/Research/ResearchAward';

// Rotas de benefícios
import Benefits from '../../../AdminClient/pages/Benefit/Benefits';
import CreateBenefit from '../../../AdminClient/pages/Benefit/CreateBenefit';
import DetailsBenefit from '../../../AdminClient/pages/Benefit/DetailsBenefit';
import EditBenefit from '../../../AdminClient/pages/Benefit/EditBenefit';

// Rota de gamificação
import Gamification from '../../../AdminClient/pages/Gamification';

// Rotas de comunicação
import Messages from '../../../AdminClient/pages/Comunication/Notifications/Messages';
import NewMessage from '../../../AdminClient/pages/Comunication/Notifications/NewMessage';

import Emails from '../../../AdminClient/pages/Comunication/E-mails/Emails';
import NewEmail from '../../../AdminClient/pages/Comunication/E-mails/NewEmail';

import DetailsBanner from '../../../AdminClient/pages/Comunication/Banners/DetailsBanner';
import Banners from '../../../AdminClient/pages/Comunication/Banners/Banners';
import EditBanner from '../../../AdminClient/pages/Comunication/Banners/EditBanner';
import NewBanner from '../../../AdminClient/pages/Comunication/Banners/NewBanner';

// Rota de pontos extras
import Conversion from '../../../AdminClient/pages/Conversion';

// Rotas de resgate de prêmios
import DetailsPremium from '../../../AdminClient/pages/Premium/DetailsPremium';
import EditPremium from '../../../AdminClient/pages/Premium/EditPremium';
import NewPremium from '../../../AdminClient/pages/Premium/NewPremium';
import Premiums from '../../../AdminClient/pages/Premium/Premiums';

import DetailsRescue from '../../../AdminClient/pages/Rescue/DetailsRescue';
import Rescues from '../../../AdminClient/pages/Rescue/Rescues';

// Rotas de players
import CreatePlayer from '../../../AdminClient/pages/Player/CreatePlayer';
import DetailsPlayer from '../../../AdminClient/pages/Player/DetailsPlayer';
import EditPlayer from '../../../AdminClient/pages/Player/EditPlayer';
import Players from '../../../AdminClient/pages/Player/Players';

// Rota de  leads
import PublicTarget from '../../../AdminClient/pages/PublicTarget';

// Rotas de temporadas
import DetailsSeason from '../../../AdminClient/pages/Season/DetailsSeason';
import EditSeason from '../../../AdminClient/pages/Season/EditSeason';
import NewSeason from '../../../AdminClient/pages/Season/NewSeason';
import SeasonActions from '../../../AdminClient/pages/Season/SeasonActions';
import Seasons from '../../../AdminClient/pages/Season/Seasons';

// Rota de condições gerais
import EditText from '../../../AdminClient/pages/GeneralConditions/EditText';
import PreviewText from '../../../AdminClient/pages/GeneralConditions/PreviewText';
import Terms from '../../../AdminClient/pages/GeneralConditions/Terms';

// Rota de email de boas vindas
import EmailTemplates from '../../../AdminClient/pages/EmailTemplates';

// Rotas de influencer
import Influencers from '../../../AdminClient/pages/Influencers';

// Rotas de configuração
import Config from '../../../AdminClient/pages/Config';
// rotas de grupos de acesso
import AccessGroups from '../../../AdminClient/pages/Access/AccessGroups';
import CreateAccessGroup from '../../../AdminClient/pages/Access/CreateAccessGroup';
import DetailsAccessGroup from '../../../AdminClient/pages/Access/DetailsAccessGroup';
import EditAccessGroup from '../../../AdminClient/pages/Access/EditAccessGroup';
// rotas de usuários
import CreateUser from '../../../AdminClient/pages/Users/CreateUser';
import DetailsUser from '../../../AdminClient/pages/Users/DetailsUser';
import EditUser from '../../../AdminClient/pages/Users/EditUser';
import Users from '../../../AdminClient/pages/Users/Users';
import HistoricalPoints from '../../../AdminClient/pages/HistoricalPoints';

//rotas Landing
import { LandingHome } from '../../../AdminClient/pages/LandingHome';
import { LandingEditor } from '../../../AdminClient/pages/LandingEditor';

import { SignOut } from '../../../AdminClient/pages/SignOut';

import { useUserData } from '../../../hooks/useUserData';
import * as routes from '../../../routes';
import { Business } from '../../../AdminClient/pages/Actions/Business';
import { BusinessHome } from '../../../AdminClient/pages/Actions/BusinessHome';
import { BusinessResults } from '../../../AdminClient/pages/Actions/BusinessResults';
import { WorksheetPoints } from '../../../AdminClient/pages/WorksheetPoints';

function WithAuth(props) {
  const { groupPermissions, userData, signed } = useUserData();

  // Adicionando suporte ao Bootstrap 5
  const isLandingFeature =
    window.location.pathname === routes.LANDING_PAGE ||
    window.location.pathname === routes.LANDING_PAGE_EDITOR;

  if (isLandingFeature) {
    let bootstrapLink = document.createElement('link');
    let iconsLink = document.createElement('link');
    let bootstrapScript = document.createElement('script');

    bootstrapScript.src =
      'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js';
    bootstrapScript.id = 'bootstrap-script';

    bootstrapLink.rel = 'stylesheet';
    bootstrapLink.href =
      'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css';
    bootstrapLink.id = 'bootstrap-5';

    iconsLink.rel = 'stylesheet';
    iconsLink.href =
      'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined';
    iconsLink.id = 'material-icons-lp';

    document.head.appendChild(bootstrapLink);
    document.head.appendChild(iconsLink);
    document.body.appendChild(bootstrapScript);
  } else {
    let bootstrapLinkToRemove = document.getElementById('bootstrap-5');
    let iconsLinkToRemove = document.getElementById('material-icons-lp');
    let bootstrapScriptToRemove = document.getElementById('bootstrap-script');

    if (bootstrapLinkToRemove) {
      bootstrapLinkToRemove.parentNode.removeChild(bootstrapLinkToRemove);
    }

    if (iconsLinkToRemove) {
      iconsLinkToRemove.parentNode.removeChild(iconsLinkToRemove);
    }

    if (bootstrapScriptToRemove) {
      bootstrapScriptToRemove.parentNode.removeChild(bootstrapScriptToRemove);
    }
  }
  // Adicionando suporte ao Bootstrap 5

  let isPermissionEdit = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEdit =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.players &&
        groupPermissions.permissions.players.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEdit =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.players &&
      groupPermissions[userData.group.value].permissions.players.edit;
  }

  let isPermissionVisualize = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionVisualize =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.leads &&
        groupPermissions.permissions.leads.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionVisualize =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.leads &&
      groupPermissions[userData.group.value].permissions.leads.visualize;
  }

  let isPermissionEditPremium = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditPremium =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.reedemAwards &&
        groupPermissions.permissions.reedemAwards.awards &&
        groupPermissions.permissions.reedemAwards.awards.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditPremium =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.reedemAwards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards
        .edit;
  }

  let isPermissionVisualizePremium = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionVisualizePremium =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.reedemAwards &&
        groupPermissions.permissions.reedemAwards.awards &&
        groupPermissions.permissions.reedemAwards.awards.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionVisualizePremium =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.reedemAwards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards
        .visualize;
  }

  let isPermissionNewPremium = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionNewPremium =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.reedemAwards &&
        groupPermissions.permissions.reedemAwards.awards &&
        groupPermissions.permissions.reedemAwards.awards.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionNewPremium =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.reedemAwards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.awards
        .create;
  }

  let isPermissionCreateActionVirtual = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionCreateActionVirtual =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.virtual &&
        groupPermissions.permissions.actions.virtual.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionCreateActionVirtual =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.virtual &&
      groupPermissions[userData.group.value].permissions.actions.virtual.create;
  }

  let isPermissionEditActionVirtual = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditActionVirtual =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.virtual &&
        groupPermissions.permissions.actions.virtual.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditActionVirtual =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.virtual &&
      groupPermissions[userData.group.value].permissions.actions.virtual.edit;
  }

  let isPermissionDetailsActionVirtual = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsActionVirtual =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.virtual &&
        groupPermissions.permissions.actions.virtual.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsActionVirtual =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.virtual &&
      groupPermissions[userData.group.value].permissions.actions.virtual
        .visualize;
  }

  let isPermissionCreateUser = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionCreateUser =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.users &&
        groupPermissions.permissions.users.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionCreateUser =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.users &&
      groupPermissions[userData.group.value].permissions.users.create;
  }

  let isPermissionEditUser = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditUser =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.users &&
        groupPermissions.permissions.users.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditUser =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.users &&
      groupPermissions[userData.group.value].permissions.users.edit;
  }

  let isPermissionDetailsUser = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsUser =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.users &&
        groupPermissions.permissions.users.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsUser =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.users &&
      groupPermissions[userData.group.value].permissions.users.visualize;
  }

  let isPermissionCreateAccessGroup = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionCreateAccessGroup =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.accessGroup &&
        groupPermissions.permissions.accessGroup.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionCreateAccessGroup =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.accessGroup &&
      groupPermissions[userData.group.value].permissions.accessGroup.create;
  }

  let isPermissionEditAccessGroup = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditAccessGroup =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.accessGroup &&
        groupPermissions.permissions.accessGroup.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditAccessGroup =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.accessGroup &&
      groupPermissions[userData.group.value].permissions.accessGroup.edit;
  }

  let isPermissionDetailsAccessGroups = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsAccessGroups =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.accessGroup &&
        groupPermissions.permissions.accessGroup.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsAccessGroups =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.accessGroup &&
      groupPermissions[userData.group.value].permissions.accessGroup.visualize;
  }

  let isPermissionDetailsRescue = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsRescue =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.reedemAwards &&
        groupPermissions.permissions.reedemAwards.rescues &&
        groupPermissions.permissions.reedemAwards.rescues.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsRescue =
      groupPermissions &&
      groupPermissions[userData.group.value] &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.reedemAwards &&
      groupPermissions[userData.group.value].permissions.reedemAwards.rescues &&
      groupPermissions[userData.group.value].permissions.reedemAwards.rescues
        .visualize;
  }

  let isPermissionDashboard = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDashboard =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.dashboard &&
        groupPermissions.permissions.dashboard.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDashboard =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.dashboard &&
      groupPermissions[userData.group.value].permissions.dashboard.visualize;
  }

  let isPermissionConfig = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionConfig =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.configurations &&
        groupPermissions.permissions.configurations.create_edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionConfig =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.configurations &&
      groupPermissions[userData.group.value].permissions.configurations
        .create_edit;
  }

  let isPermissionNewBanner = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionNewBanner =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.banner &&
        groupPermissions.permissions.comunications.banner.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionNewBanner =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.comunications &&
      groupPermissions[userData.group.value].permissions.comunications.banner &&
      groupPermissions[userData.group.value].permissions.comunications.banner
        .create;
  }

  let isPermissionPreviewTerms = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionPreviewTerms =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.termsOfUse &&
        groupPermissions.permissions.generalConditions.termsOfUse.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionPreviewTerms =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .termsOfUse &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .termsOfUse.visualize;
  }

  let isPermissionEditTerms = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditTerms =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.termsOfUse &&
        groupPermissions.permissions.generalConditions.termsOfUse
          .create_edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditTerms =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .termsOfUse &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .termsOfUse.create_edit;
  }

  let isPermissionEditBanner = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditBanner =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.banner &&
        groupPermissions.permissions.comunications.banner.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditBanner =
      (groupPermissions &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.comunications &&
        groupPermissions[userData.group.value].permissions.comunications
          .banner &&
        groupPermissions[userData.group.value].permissions.comunications.banner
          .edit) ||
      (userData && userData.master && userData.master);
  }

  let isPermissionDetailsBanner = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsBanner =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.banner &&
        groupPermissions.permissions.comunications.banner.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsBanner =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.comunications &&
      groupPermissions[userData.group.value].permissions.comunications.banner &&
      groupPermissions[userData.group.value].permissions.comunications.banner
        .visualize;
  }

  let isPermissionPreviewFaq = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionPreviewFaq =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.faq &&
        groupPermissions.permissions.generalConditions.faq.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionPreviewFaq =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .faq &&
      groupPermissions[userData.group.value].permissions.generalConditions.faq
        .visualize;
  }

  let isPermissionEditFaq = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditFaq =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.faq &&
        groupPermissions.permissions.generalConditions.faq.create_edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditFaq =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .faq &&
      groupPermissions[userData.group.value].permissions.generalConditions.faq
        .create_edit;
  }

  let isPermissionProfileClient = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionProfileClient =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.company &&
        groupPermissions.permissions.company.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionProfileClient =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.company &&
      groupPermissions[userData.group.value].permissions.company.visualize;
  }

  let isPermissionHowItWorks = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionHowItWorks =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.howItWorks &&
        groupPermissions.permissions.generalConditions.howItWorks.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionHowItWorks =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .howItWorks &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .howItWorks.visualize;
  }

  let isPermissionEditHowItWorks = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditHowItWorks =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.generalConditions &&
        groupPermissions.permissions.generalConditions.howItWorks &&
        groupPermissions.permissions.generalConditions.howItWorks
          .create_edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditHowItWorks =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.generalConditions &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .howItWorks &&
      groupPermissions[userData.group.value].permissions.generalConditions
        .howItWorks.create_edit;
  }

  let isPermissionCreateQuiz = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionCreateQuiz =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.quiz &&
        groupPermissions.permissions.actions.quiz.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionCreateQuiz =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.quiz &&
      groupPermissions[userData.group.value].permissions.actions.quiz.create;
  }

  let isPermissionEditQuiz = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEditQuiz =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.quiz &&
        groupPermissions.permissions.actions.quiz.edit) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEditQuiz =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.quiz &&
      groupPermissions[userData.group.value].permissions.actions.quiz.edit;
  }

  let isPermissionDetailsQuiz = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionDetailsQuiz =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.actions &&
        groupPermissions.permissions.actions.quiz &&
        groupPermissions.permissions.actions.quiz.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionDetailsQuiz =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.actions &&
      groupPermissions[userData.group.value].permissions.actions.quiz &&
      groupPermissions[userData.group.value].permissions.actions.quiz.visualize;
  }

  let isPermissionCreateMessage = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionCreateMessage =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.message &&
        groupPermissions.permissions.comunications.message.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionCreateMessage =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.comunications &&
      groupPermissions[userData.group.value].permissions.comunications
        .message &&
      groupPermissions[userData.group.value].permissions.comunications.message
        .create;
  }

  let isPermissionNewEmail = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionNewEmail =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.email &&
        groupPermissions.permissions.comunications.email.create) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionNewEmail =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.comunications &&
      groupPermissions[userData.group.value].permissions.comunications.email &&
      groupPermissions[userData.group.value].permissions.comunications.email
        .create;
  }

  let isPermissionEmailTemplates = null;

  // quando for login do grupo master
  if (userData && userData.master) {
    isPermissionEmailTemplates =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.welcomeEmail &&
        groupPermissions.permissions.welcomeEmail.visualize) ||
      (userData && userData.master && userData.master);
  }

  // quando for login de qualquer outro grupo
  if (userData && userData.group && userData.group.value && !userData.master) {
    isPermissionEmailTemplates =
      groupPermissions &&
      groupPermissions[userData.group.value].permissions &&
      groupPermissions[userData.group.value].permissions.welcomeEmail &&
      groupPermissions[userData.group.value].permissions.welcomeEmail.visualize;
  }

  return (
    <Switch>
      {/* <PrivateRoute
        isPermission={true}
        exact
        path="/"
        render={() => {
          return signed && <Redirect to={routes.WELCOME_CLIENT} />;
        }}
      /> */}
      <PrivateRoute
        isPermission
        exact
        path={routes.SIGNOUT_CLIENT}
        component={(propsPage) => <SignOut {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.LANDING_PAGE}
        component={(propsPage) => <LandingHome {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_BUSINESS}
        component={(propsPage) => <Business {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_BUSINESS_HOME}
        component={(propsPage) => <BusinessHome {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_BUSINESS_RESULTS}
        component={(propsPage) => <BusinessResults {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.WORKSHEET_POINTS}
        component={(propsPage) => <WorksheetPoints {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.LANDING_PAGE_EDITOR}
        component={(propsPage) => <LandingEditor {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.WELCOME_CLIENT}
        component={(propsPage) => <Welcome {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionProfileClient}
        exact
        path={routes.PROFILE_CLIENT}
        component={(propsPage) => <Company {...props} {...propsPage} />}
      />

      {/* Rotas de dashboard */}
      <PrivateRoute
        isPermission={isPermissionDashboard}
        exact
        path={routes.DASHBOARD_CLIENT}
        component={(propsPage) => <Dashboard {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission={isPermissionDashboard}
        exact
        path={routes.DASHBOARD_QUIZ_CLIENT}
        component={(propsPage) => <DashboardQuiz {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDashboard}
        exact
        path={routes.DASHBOARD_RESEARCH_AWARD}
        component={(propsPage) => (
          <DashboardResearchAward {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={isPermissionDashboard}
        exact
        path={routes.DASHBOARD_GAMIFICATION}
        component={(propsPage) => (
          <DashboardGamification {...props} {...propsPage} />
        )}
      />

      {/* Rotas de publicação */}
      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_VIRTUALS}
        component={(propsPage) => <ActionsVirtuals {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.virtual &&
            groupPermissions.permissions.actions.virtual.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={routes.PREVIEW_ACTION_VIRTUAL}
        component={(propsPage) => (
          <PreviewActionVirtual {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission={isPermissionCreateActionVirtual}
        exact
        path={routes.CREATE_ACTION_VIRTUAL}
        component={(propsPage) => (
          <CreateActionVirtual {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission={isPermissionEditActionVirtual}
        exact
        path={`${routes.EDIT_ACTION_VIRTUAL}/:actionId`}
        component={(propsPage) => (
          <EditActionVirtual {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission={isPermissionDetailsActionVirtual}
        exact
        path={`${routes.DETAILS_ACTION_VIRTUAL}/:actionId`}
        component={(propsPage) => (
          <DetailsActionVirtual {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_QUIZ}
        component={(propsPage) => <ActionsQuiz {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionCreateQuiz}
        exact
        path={routes.CREATE_ACTION_QUIZ}
        component={(propsPage) => (
          <CreateActionQuiz {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={isPermissionEditQuiz}
        exact
        path={`${routes.EDIT_ACTION_QUIZ}/:actionId`}
        component={(propsPage) => <EditActionQuiz {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDetailsQuiz}
        exact
        path={`${routes.DETAILS_ACTION_QUIZ}/:actionId`}
        component={(propsPage) => (
          <DetailsActionQuiz {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.ACTIONS_OMNICHANNELS}
        component={(propsPage) => (
          <ActionsOmnichannels {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.omnichannel &&
            groupPermissions.permissions.actions.omnichannel.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={routes.CREATE_ACTION_OMNICHANNEL}
        component={(propsPage) => (
          <CreateActionOmnichannel {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.omnichannel &&
            groupPermissions.permissions.actions.omnichannel.edit) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.EDIT_ACTION_OMNICHANNEL}/:actionId`}
        component={(propsPage) => (
          <EditActionOmnichannel {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.omnichannel &&
            groupPermissions.permissions.actions.omnichannel.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.DETAILS_ACTION_OMNICHANNEL}/:actionId`}
        component={(propsPage) => (
          <DetailsActionOmnichannel {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.ACTIONS_INFORMATIONALS}/`}
        component={(propsPage) => (
          <ActionsInformationals {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.informational &&
            groupPermissions.permissions.actions.informational.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.CREATE_ACTION_INFORMATIONAL}/`}
        component={(propsPage) => (
          <CreateActionInformational {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.informational &&
            groupPermissions.permissions.actions.informational.edit) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.EDIT_ACTION_INFORMATIONAL}/:actionId`}
        component={(propsPage) => (
          <EditActionInformational {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.informational &&
            groupPermissions.permissions.actions.informational.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.DETAILS_ACTION_INFORMATIONAL}/:actionId`}
        component={(propsPage) => (
          <DetailsActionInformational {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.ACTIONS_INVITES}/`}
        component={(propsPage) => <ActionsInvites {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.invite &&
            groupPermissions.permissions.actions.invite.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.CREATE_ACTION_INVITE}/`}
        component={(propsPage) => (
          <CreateActionInvite {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.invite &&
            groupPermissions.permissions.actions.invite.edit) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.EDIT_ACTION_INVITE}/:actionId`}
        component={(propsPage) => (
          <EditActionInvite {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.actions &&
            groupPermissions.permissions.actions.invite &&
            groupPermissions.permissions.actions.invite.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.DETAILS_ACTION_INVITE}/:actionId`}
        component={(propsPage) => (
          <DetailsActionInvite {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.RESEARCHAWARD_CLIENT}
        component={(propsPage) => <ResearchAward {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.CREATE_RESEARCHAWARD_CLIENT}
        component={(propsPage) => (
          <CreateResearchAward {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.EDIT_RESEARCH_AWARD}/:actionId`}
        component={(propsPage) => (
          <EditResearchAward {...props} {...propsPage} />
        )}
      />

      {/* Rotas de benefícios */}

      <PrivateRoute
        isPermission
        exact
        path={routes.BENEFITS_CLIENT}
        component={(propsPage) => <Benefits {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.benefits &&
            groupPermissions.permissions.benefits.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={routes.CREATE_BENEFIT}
        component={(propsPage) => <CreateBenefit {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.benefits &&
            groupPermissions.permissions.benefits.edit) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.EDIT_BENEFIT}/:benefitId`}
        component={(propsPage) => <EditBenefit {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.benefits &&
            groupPermissions.permissions.benefits.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.DETAILS_BENEFIT}/:benefitId`}
        component={(propsPage) => <DetailsBenefit {...props} {...propsPage} />}
      />

      {/* Rota de gamificação */}
      <PrivateRoute
        isPermission
        exact
        path={routes.GAMIFICATION_CLIENT}
        component={(propsPage) => <Gamification {...props} {...propsPage} />}
      />

      {/* Rotas de comunicação */}
      <PrivateRoute
        isPermission
        exact
        path={routes.MESSAGES}
        component={(propsPage) => <Messages {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionCreateMessage}
        exact
        path={routes.NEW_MESSAGE}
        component={(propsPage) => <NewMessage {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.EMAILS}
        component={(propsPage) => <Emails {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionNewEmail}
        exact
        path={routes.NEW_EMAIL}
        component={(propsPage) => <NewEmail {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.BANNERS}
        component={(propsPage) => <Banners {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionNewBanner}
        exact
        path={routes.NEW_BANNER}
        component={(propsPage) => <NewBanner {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionEditBanner}
        exact
        path={`${routes.EDIT_BANNER}/:bannerId`}
        component={(propsPage) => <EditBanner {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDetailsBanner}
        exact
        path={`${routes.DETAILS_BANNER}/:bannerId`}
        component={(propsPage) => <DetailsBanner {...props} {...propsPage} />}
      />

      {/* Rota de pontos extras */}
      <PrivateRoute
        isPermission
        exact
        path={routes.CONVERSIONS}
        component={(propsPage) => <Conversion {...props} {...propsPage} />}
      />

      {/* Rotas de resgate de prêmios */}
      <PrivateRoute
        isPermission
        exact
        path={`${routes.PREMIUMS}`}
        component={(propsPage) => <Premiums {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.PREMIUMS}/:seasonId`}
        component={(propsPage) => <Premiums {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionNewPremium}
        exact
        path={`${routes.NEW_PREMIUM}`}
        component={(propsPage) => <NewPremium {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionEditPremium}
        exact
        path={`${routes.EDIT_PREMIUM}/:premiumId/:seasonId`}
        component={(propsPage) => <EditPremium {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionVisualizePremium}
        exact
        path={`${routes.DETAILS_PREMIUM}/:premiumId/:seasonId`}
        component={(propsPage) => <DetailsPremium {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.reedemAwards &&
            groupPermissions.permissions.reedemAwards.awards &&
            groupPermissions.permissions.reedemAwards.awards.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.NEW_PREMIUM}/:seasonId`}
        component={(propsPage) => <NewPremium {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.RESCUES}
        component={(propsPage) => <Rescues {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDetailsRescue}
        exact
        path={`${routes.RESCUE_DETAILS}/:rescueId`}
        component={(propsPage) => <DetailsRescue {...props} {...propsPage} />}
      />

      {/* Rotas de players */}
      <PrivateRoute
        isPermission
        exact
        path={routes.PLAYERS}
        component={(propsPage) => <Players {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionEdit}
        exact
        path={`${routes.EDIT_PLAYER}/:playerId`}
        component={(propsPage) => <EditPlayer {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionVisualize}
        exact
        path={`${routes.DETAILS_PLAYER}/:playerId`}
        component={(propsPage) => <DetailsPlayer {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.CREATE_PLAYER}`}
        component={(propsPage) => <CreatePlayer {...props} {...propsPage} />}
      />

      {/* Rota de  leads */}
      <PrivateRoute
        isPermission
        exact
        path={routes.PUBLIC_TARGET}
        component={(propsPage) => <PublicTarget {...props} {...propsPage} />}
      />

      {/* Rotas de temporadas */}
      <PrivateRoute
        isPermission
        exact
        path={routes.SEASONS}
        component={(propsPage) => <Seasons {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        path={`${routes.SEASON_ACTIONS}/:seasonId`}
        component={(propsPage) => <SeasonActions {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.seasons &&
            groupPermissions.permissions.seasons.create) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={routes.NEW_SEASON}
        component={(propsPage) => <NewSeason {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.seasons &&
            groupPermissions.permissions.seasons.edit) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.EDIT_SEASON}/:seasonId`}
        component={(propsPage) => <EditSeason {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={
          (groupPermissions &&
            groupPermissions.permissions &&
            groupPermissions.permissions.seasons &&
            groupPermissions.permissions.seasons.visualize) ||
          (userData && userData.master && userData.master)
        }
        exact
        path={`${routes.DETAILS_SEASON}/:seasonId`}
        component={(propsPage) => <DetailsSeason {...props} {...propsPage} />}
      />

      {/* Rota de condições gerais */}
      <PrivateRoute
        isPermission
        exact
        path={routes.TERMS_CLIENT}
        component={(propsPage) => <Terms {...props} {...propsPage} />}
      />

      {props.location.pathname.includes('terms') && (
        <PrivateRoute
          isPermission={isPermissionPreviewTerms}
          exact
          path={`${routes.PREVIEW_TEXT}/:clientText`}
          component={(propsPage) => <PreviewText {...props} {...propsPage} />}
        />
      )}
      {props.location.pathname.includes('terms') && (
        <PrivateRoute
          isPermission={isPermissionEditTerms}
          exact
          path={`${routes.EDIT_TEXT}/:clientText`}
          component={(propsPage) => <EditText {...props} {...propsPage} />}
        />
      )}

      {props.location.pathname.includes('faq') && (
        <PrivateRoute
          isPermission={isPermissionPreviewFaq}
          exact
          path={`${routes.PREVIEW_TEXT}/:clientText`}
          component={(propsPage) => <PreviewText {...props} {...propsPage} />}
        />
      )}
      {props.location.pathname.includes('faq') && (
        <PrivateRoute
          isPermission={isPermissionEditFaq}
          exact
          path={`${routes.EDIT_TEXT}/:clientText`}
          component={(propsPage) => <EditText {...props} {...propsPage} />}
        />
      )}

      {props.location.pathname.includes('howItWorks') && (
        <PrivateRoute
          isPermission={isPermissionHowItWorks}
          exact
          path={`${routes.PREVIEW_TEXT}/:clientText`}
          component={(propsPage) => <PreviewText {...props} {...propsPage} />}
        />
      )}
      {props.location.pathname.includes('howItWorks') && (
        <PrivateRoute
          isPermission={isPermissionEditHowItWorks}
          exact
          path={`${routes.EDIT_TEXT}/:clientText/:index`}
          component={(propsPage) => <EditText {...props} {...propsPage} />}
        />
      )}

      {/* Rota de email de boas vindas */}
      <PrivateRoute
        isPermission={isPermissionEmailTemplates}
        exact
        path={routes.EMAIL_TEMPLATES}
        component={(propsPage) => <EmailTemplates {...props} {...propsPage} />}
      />

      {/* Rotas de influencer */}
      <PrivateRoute
        isPermission
        exact
        path={`${routes.INFLUENCERS}/`}
        component={(propsPage) => <Influencers {...props} {...propsPage} />}
      />

      {/* Rotas de configuração */}
      <PrivateRoute
        isPermission={isPermissionConfig}
        exact
        path={routes.CONFIG}
        component={(propsPage) => <Config {...props} {...propsPage} />}
      />

      {/* Rota de grupos de acesso */}
      <PrivateRoute
        isPermission
        exact
        path={`${routes.ACCESS_GROUPS}/`}
        component={(propsPage) => <AccessGroups {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionCreateAccessGroup}
        exact
        path={`${routes.CREATE_ACCESS_GROUP}/`}
        component={(propsPage) => (
          <CreateAccessGroup {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission={isPermissionEditAccessGroup}
        exact
        path={`${routes.EDIT_ACCESS_GROUP}/:groupId`}
        component={(propsPage) => <EditAccessGroup {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDetailsAccessGroups}
        exact
        path={`${routes.DETAILS_ACCESS_GROUP}/:groupId`}
        component={(propsPage) => (
          <DetailsAccessGroup {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.TUTORIALS}
        component={(propsPage) => <Tutorials {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.LEAD_REGISTER}
        component={(propsPage) => <LeadRegister {...props} {...propsPage} />}
      />

      {/* Rota de usuários */}
      <PrivateRoute
        isPermission
        exact
        path={`${routes.USERS}/`}
        component={(propsPage) => <Users {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionCreateUser}
        exact
        path={`${routes.CREATE_USER}/`}
        component={(propsPage) => <CreateUser {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionEditUser}
        exact
        path={`${routes.EDIT_USER}/:userId`}
        component={(propsPage) => <EditUser {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission={isPermissionDetailsUser}
        exact
        path={`${routes.DETAILS_USER}/:userId`}
        component={(propsPage) => <DetailsUser {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.PROFILE_USER}
        component={(propsPage) => <Profile {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.PONTUATION_RULES}
        component={(propsPage) => (
          <PontuationRulesPage {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.RANKING_INDICATIONS}/`}
        component={(propsPage) => (
          <RankingIndications {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.RANKING_CORRECTORS}/`}
        component={(propsPage) => (
          <RankingCorrectors {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.WALLET_CLIENT}/`}
        component={(propsPage) => <DetailsWallet {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.HISTORICAL_WALLET_CLIENT}/`}
        component={(propsPage) => (
          <HistoricalWallet {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.PLAYER_RANKING}
        component={(propsPage) => (
          <PlayerRankingPage {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.PLAYER_DETAIL_QUIZ}/:playerId/:actionId`}
        component={(propsPage) => (
          <PlayerDetailsQuiz {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.PLAYER_DETAIL_RESEARCH_AWARD}/:playerId/:actionId`}
        component={(propsPage) => (
          <PlayerDetailsResearchAward {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={`${routes.HISTORICAL_POINTS_CLIENT}/`}
        component={(propsPage) => (
          <HistoricalPoints {...props} {...propsPage} />
        )}
      />

      {/* NOT FOUND */}
      <PrivateRoute
        isPermission
        component={(propsPage) => <NotFoundPage {...props} {...propsPage} />}
      />
    </Switch>
  );
}

export default withRouter(WithAuth);
