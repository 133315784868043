import moment from 'moment';
import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import Modal from 'react-responsive-modal';
import _ from 'underscore';

import { api } from '../../../../Config';
import { Notify } from '../../../../components/Notify';
import { Images } from '../../../../Assets/Images';

class NotificationModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isMobile: false,
      dataScopeValue: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  closeModal() {
    this.props.toggleNotificationModal();
  }

  componentWillMount() {
    this.detectDevice();
  }

  detectDevice() {
    let dimensions = {
      height: window.innerHeight,
      width: window.innerWidth,
    };

    this.toggleMobileDevice(dimensions.width);

    window.addEventListener('resize', evt => {
      dimensions = {
        height: (evt.srcElement || evt.currentTarget).innerHeight,
        width: (evt.srcElement || evt.currentTarget).innerWidth,
      };

      this.toggleMobileDevice(dimensions.width);
    });
  }

  getScopeValues(notification) {
    switch (notification.scope) {
      case 'regional':
        if (notification.scopeValues) {
          this.setState({ dataScopeValue: notification.scopeValues });
        }
        return 'city';
        break;
      case 'state':
        if (notification.scopeValues) {
          let arrayData = [notification.scopeValues.state];
          if (notification.scopeValues.city) {
            arrayData.push(notification.scopeValues.city);
          }
          this.setState({ dataScopeValue: arrayData });
        }
    }
  }

  toggleMobileDevice(width) {
    if (width < 992) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }

  deleteEmail(idEmail, clientId) {
    console.log('dibsivbad', idEmail);

    const parameters = {
      searchFunctionality: 'deleteEmail',
      userType: 'client',
      idEmail,
      clientId,
    };

    api
      .post(`/search`, parameters)
      .then(res => {
        const result = res.data;

        if (!result.success) {
          this.setState({ isLoading: false, actions: [] });
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }
        Notify(result.message, result.success ? 'success' : 'error');
        this.closeModal();

        this.setState({
          isLoading: false,
        });
        /* Notify(result.message, result.success ? "success" : "error"); */
      })
      .catch(error => {
        console.log('getNotifications error:', error);
      });
  }

  deleteNotification(idNotification) {
    console.log('dibsivbad', idNotification);

    const parameters = {
      searchFunctionality: 'deleteNotifications',
      userType: 'client',
      idNotification,
    };

    api
      .post(`/search`, parameters)
      .then(res => {
        const result = res.data;

        if (!result.success) {
          this.setState({ isLoading: false, actions: [] });
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }
        Notify(result.message, result.success ? 'success' : 'error');
        this.closeModal();

        this.setState({
          isLoading: false,
        });
        /* Notify(result.message, result.success ? "success" : "error"); */
      })
      .catch(error => {
        console.log('getNotifications error:', error);
      });
  }

  render() {
    let { notification, clientId } = this.props;
    let { isMobile, dataScopeValue } = this.state;
    return (
      <Modal
        showCloseIcon={false}
        open={this.state.open}
        onClose={() => this.closeModal()}
        styles={{
          overlay: {
            backdropFilter: 'blur(2px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            width: '100%',
          },
          modal: {
            borderRadius: 10,
            width: isMobile ? '100%' : '30%',
          },
        }}
      >
        {notification.notificationType === 'newMessage' && (
          <div>
            <div
              className="row"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className="col-12 text-center">
                <img className="img-fluid" src={Images.notifyEmail} alt="" />
              </div>
            </div>
            <div
              className="row"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className="col-12 text-center mt-3">
                <h4>Nova mensagem para você!</h4>
                <hr />
                <h4>
                  {notification.notificationContent
                    ? renderHTML(notification.notificationContent)
                    : ''}
                </h4>
                <span className="oq-points-desc">
                  Postada em:{' '}
                  {moment(notification.createDate).format(
                    'DD MMMM YYYY - HH:mm'
                  )}
                </span>
              </div>
            </div>
            <br />
            <div
              className="col-6 text-center"
              style={{ justifyContent: 'center' }}
            >
              <br />
              <button
                className="btn btn-block btn-oq btn-oq-lg"
                onClick={() => this.closeModal()}
              >
                Fechar
              </button>
              <div className="delete-container">
                <div
                  className="clickable delete-text"
                  style={{ marginTop: 15 }}
                  onClick={() => {
                    this.deleteNotification(notification.id);
                  }}
                >
                  {' '}
                  Excluir notificação{' '}
                </div>
              </div>
            </div>
          </div>
        )}
        {notification.notificationType &&
          notification.notificationType !== 'newMessage' && (
            <div>
              <div className="row">
                <div className="col-12 text-center">
                  <img src={Images.notifyTarget} alt="" />
                </div>
              </div>
              <div
                className="row"
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
              >
                <div className="col-12 text-center mt-3">
                  <h4>
                    {notification.notificationContent
                      ? renderHTML(notification.notificationContent)
                      : ''}
                  </h4>
                  <hr />
                </div>
              </div>
              <div
                className="row"
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
              >
                <div className="col-12 text-center">
                  {notification.action && (
                    <img
                      src={notification.action.files.image1}
                      width="100%"
                      alt=""
                    />
                  )}
                </div>
                <div className="col-12 text-center">
                  <br />
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <h4>
                      <strong>{notification.actionTitle}</strong>
                    </h4>
                    {notification.action && (
                      <h5>{notification.action.description}</h5>
                    )}
                  </div>
                </div>

                <div className="col-12 text-center">
                  <span className="oq-points-date">
                    Inicia em{' '}
                    {moment(notification.startDate).format(
                      'DD MMMM YYYY - HH:mm'
                    )}
                  </span>
                  <br />
                  <span className="oq-points-date">
                    Finaliza em{' '}
                    {moment(notification.endDate).format(
                      'DD MMMM YYYY - HH:mm'
                    )}
                  </span>
                </div>
              </div>
              <div
                className="row"
                style={{ paddingLeft: '30px', paddingRight: '30px' }}
              >
                <div className="col-6 text-center">
                  <br />
                  <button
                    className="btn btn-block btn-oq btn-oq-lg"
                    onClick={() => this.closeModal()}
                  >
                    Fechar
                  </button>
                  <div
                    className="clickable delete-text"
                    style={{ marginTop: 15 }}
                    onClick={() =>
                      this.props.isPermissionDelete
                        ? this.deleteNotification(notification.id)
                        : Notify('Você não tem permissão!', 'warn')
                    }
                  >
                    {' '}
                    Excluir notificação{' '}
                  </div>
                </div>
              </div>
            </div>
          )}
        {!notification.notificationType && (
          <div>
            <div className="row">
              <div className="col-12 text-center">
                <img src={Images.notifyEmail} alt="" />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <h4>
                  <strong>{notification.subject}</strong>
                </h4>
              </div>
            </div>
            <div
              className="row"
              style={{ paddingLeft: '30px', paddingRight: '30px' }}
            >
              <div className="col-12 text-center mt-3">
                <h4>
                  {notification.body
                    ? renderHTML(notification.body)
                    : '...Carregando'}
                </h4>
                <hr />
              </div>
              <div className="col-12 text-center">
                <h4>
                  <strong>
                    {notification.scope == 'regional'
                      ? 'Segmentação'
                      : notification.scope == 'state'
                        ? 'Estado'
                        : notification.scope == 'national'
                          ? 'Nacional'
                          : ''}
                  </strong>
                </h4>

                {notification.scope == 'regional' ? (
                  _.map(notification.scopeValues, scopeValue => {
                    return <h5>{scopeValue.label}</h5>;
                  })
                ) : notification.scope == 'state' && notification.city ? (
                  <h5>
                    {notification.city}
                    <br />
                    {notification.scopeValues}
                  </h5>
                ) : notification.scope == 'state' ? (
                  <h5>{notification.scopeValues}</h5>
                ) : notification.scope == 'national' ? (
                  <h5>Brasil</h5>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div
              className="row"
              style={{ paddingLeft: '30px', paddingRight: '30px' }}
            >
              <div className="col-12 text-center">
                {notification.action && (
                  <img
                    src={notification.action.files.image1}
                    width="100%"
                    alt=""
                  />
                )}
              </div>
              <div className="col-12 text-center">
                <br />
              </div>
            </div>
            <div
              className="row"
              style={{ paddingLeft: '30px', paddingRight: '30px' }}
            >
              <div className="col-6 text-center">
                <br />
                <button
                  className="btn btn-block btn-oq btn-oq-lg"
                  onClick={() => this.closeModal()}
                >
                  Fechar
                </button>
                <div
                  className="clickable delete-text"
                  style={{ marginTop: 15 }}
                  onClick={() => {
                    this.deleteEmail(notification.uid, clientId);
                  }}
                >
                  {' '}
                  Excluir E-mail{' '}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default NotificationModal;
