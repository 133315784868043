import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileSaver from 'file-saver';
import _ from 'lodash';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';
import { api } from '../../../Config';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as mask from '../../../utils/Mascaras';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

class Influencers extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      isLoading: false,
      searchObj: {
        name: '',
        actionId: '',
        email: '',
        regional: '',
        cpf: '',
      },
      players: [],
      actions: [],
      regionalList: [],
      loadingPlayers: false,
      loadingRegionalList: false,
      searching: false,
    };

    return initialState;
  }

  componentDidMount() {
    this.loadActionList();
    this.getClientRegionalList();
    //this.getConversionCount();
  }

  exportPlayers() {
    const { players } = this.state;
    const ws = XLSX.utils.json_to_sheet(_.map(players));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Players');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Players.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  getClientRegionalList() {
    const { clientData } = this.context;
    const clientId = clientData.uid;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  loadActionList() {
    const parameters = {
      searchFunctionality: 'allActions',
      userType: 'client',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        this.setState({ ...result.data });
      })
      .catch((error) => {
        console.log('loadActionList error:', error);
      });
  }

  filterList() {
    const { searchObj } = this.state;

    this.setState({ searching: true }, () => {
      const parameters = {
        searchFunctionality: 'getInfluencers',
        userType: 'client',
      };

      if (
        searchObj.name ||
        searchObj.actionId ||
        searchObj.email ||
        searchObj.regional ||
        searchObj.cpf
      ) {
        parameters.filter = searchObj;
      }

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ ...result.data, searching: false });
        })
        .catch((error) => {
          this.setState({ searching: false });
          console.log('getActionById error:', error);
        });
    });
  }

  handleSearchInputChange = async (e) => {
    let { name, value } = e.target;
    if (name === 'regional') {
      value = value
        .trim()
        .replace(/ /gi, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    }
    await this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };
  handleSearchInputChangeCpf = async (name, value) => {
    await this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  };

  renderSearchForm() {
    return (
      <div>
        <div>
          <div className="oq-filter-bg">
            <div className="container-fluid">
              <span className="form-group oq-filter-title">
                <i className="fa fa-sliders" aria-hidden="true" />
                &nbsp; {this.translate('advanced-filters')}
              </span>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder={this.translate('name')}
                    name="name"
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="CPF"
                    name="cpf"
                    type="tel"
                    value={this.state.searchObj.cpf}
                    onChange={(event) => {
                      const input = event.target;
                      let maskCPF = mask.maskCPF(input.value);
                      this.handleSearchInputChangeCpf(input.name, maskCPF);
                    }}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Ações"
                    name="actionId"
                    disabled={this.state.loadingPlayers}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingPlayers
                        ? this.translate('loading')
                        : this.translate('Selecione uma ação...')}
                    </option>
                    {_.size(this.state.actions) > 0 &&
                      _.map(this.state.actions, (action, idx) => (
                        <option key={idx} value={action.id}>
                          {action.actionTitle}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-3 form-group">
                  <button
                    className="btn btn-block btn-oq pull-right"
                    style={{ width: '75%' }}
                    disabled={this.state.searching}
                    onClick={() => this.filterList()}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                    &nbsp;
                    {this.state.searching
                      ? this.translate('Pesquisando...')
                      : this.translate('Pesquisar')}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 form-group">
                  <input
                    className="form-control input-oq"
                    placeholder="E-mail"
                    name="email"
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-3 form-group">
                  <select
                    className="form-control input-oq"
                    placeholder="Regional"
                    name="regional"
                    disabled={this.state.loadingPlayers}
                    onChange={(e) => this.handleSearchInputChange(e)}
                  >
                    <option value="">
                      {this.state.loadingRegionalList
                        ? this.translate('loading')
                        : this.translate('Selecione uma unidade...')}
                    </option>
                    {this.state.regionalList &&
                      _.size(this.state.regionalList) > 0 &&
                      _.map(this.state.regionalList, (division, idx) => (
                        <option key={idx} value={division}>
                          {division}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  resendEmail(player) {
    const { clientData } = this.context;
    const clientId = clientData.uid;

    const parameters = {
      searchFunctionality: 'sendEmailAndCode',
      userType: 'client',
      clientId,
      email: player.email,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        Notify(result.message, result.success ? 'success' : 'warn');
      })
      .catch((error) => {
        console.log('resendEmail error:', error);
      });
  }

  renderActionsTable() {
    // await this.filterList();
    const data = _.values(this.state.players);
    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <>
            <div style={{ textAlign: 'center' }}>
              {this.translate('email')}
              <span data-tip="React-tooltip" data-for="email">
                <ReactTooltip
                  id="email"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('OrderBy') + this.translate('email')}
                  </span>
                </ReactTooltip>
              </span>
            </div>
          </>
        ),
        accessor: 'email',
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Status</div>,
        id: 'influencer',
        accessor: (d) =>
          d.influencer && d.influencer === true
            ? 'Cadastrado'
            : 'Não cadastrado',
        className: 'center-cell',
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>
            {this.translate('CPF')}
            <span data-tip="React-tooltip" data-for="CPF">
              <ReactTooltip
                id="CPF"
                type="dark"
                effect="solid"
                multiline
                className="tool-tip"
              >
                <span>{this.translate('OrderBy') + this.translate('CPF')}</span>
              </ReactTooltip>
            </span>
          </div>
        ),
        accessor: 'cpf',
        className: 'center-cell',
      },
      {
        Header: <div style={{ textAlign: 'center' }}>Leads</div>,
        id: 'leads',
        accessor: (d) => (d.leads ? d.leads : 0),
        className: 'center-cell',
      },
      {
        Header: (
          <div style={{ textAlign: 'center' }}>{this.translate('Código')}</div>
        ),
        id: 'validationCode',
        accessor: (d) =>
          d.validationCode && d.validationCode ? d.validationCode : '-',
        className: 'center-cell',
      },

      {
        Header: () => (
          <div style={{ textAlign: 'center' }}>
            {this.translate('Pontos Totais')}
          </div>
        ),
        id: 'totalPoints',
        accessor: (d) => (d.totalPoints ? parseInt(d.totalPoints) : 0),
        className: 'center-cell',
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: (props) => {
          return (
            <button
              className="btn btn-oq"
              style={{ fontSize: '12px', whiteSpace: 'normal', padding: '0' }}
              // disabled={
              //   !props.original.influencer ||
              //     props.original.influencer === false
              //     ? true
              //     : false
              // }
              onClick={() => this.resendEmail(props.original)}
            >
              {this.translate('Reenviar código')}
            </button>
          );
        },
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: (props) => {
          return (
            <Link to={`${routes.EDIT_PLAYER}/${props.value}/`}>
              <button
                className="btn btn-oq"
                style={{ fontSize: '14px' }}
                disabled={
                  !props.original.influencer ||
                  props.original.influencer === false
                    ? true
                    : false
                }
              >
                {this.translate('Detalhes')}
              </button>
            </Link>
          );
        },
      },
    ];

    const { clientData } = this.context;
    let adjustedColumns = [];
    if (clientData.isGamified === 'false') {
      adjustedColumns = _.filter(columns, (obj, index) => {
        return obj.id !== 'coins';
      });
    } else adjustedColumns = columns;
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de líderes de equipe')}
          </span>
          <button
            className="btn btn-oq pull-right"
            onClick={() => this.exportPlayers()}
            disabled={_.size(this.state.players) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              columns={adjustedColumns}
              previousText={this.translate('prev')}
              getThProps={() => {}}
              nextText={this.translate('next')}
              noDataText={this.translate('Nenhuma publicação para ser exibida')}
              pageText={this.translate('page')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
          <div className="row">
            <span className="col-md-12 gray-text text-right">
              Ao filtrar por publicação o sistema exibe os players que já
              pontuaram no mesmo.
            </span>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderActionsTable()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     {this.renderSearchForm()}
      //     {this.renderActionsTable()}
      //   </div>
      // </div>
    );
  }
}

const InfluencersLink = (props) => (
  <Link className="menu-link" to={routes.PLAYERS}>
    <FontAwesomeIcon icon={faUsers} />
    <span className="sidebarText">{props.translate('players')}</span>
  </Link>
);

export default Influencers;

export { InfluencersLink };
