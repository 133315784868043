import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import SideBarLogo from '../Images/sideLogo.png';
import * as routes from '../../routes';
import { DashboardPageLink } from '../Dashboard';
import { EmailsPageLink } from '../Emails';
import { ClientsPageLink } from '../Clients';
import { PlayersPageLink } from '../Players';
import { ProspectLeadsPageLink } from '../ProspectLeads';
import { LeadsLink } from '../Leads';
import { SignOut } from '../SignOut';
import { AdministratorsPageLink } from '../Administrators';
import { TermsPageLink } from '../Terms';
import { WalletsPageLink } from '../Wallets';
import { UsersPageLink } from '../Users';
import { JobsPageLink } from '../Jobs';

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const inititalState = {
      currentPage: '',
      loadingLogo: true,
    };

    return inititalState;
  }

  componentDidMount() {
    // var sidebarStatus = localStorage.getItem('sideBarStatus');
    /* if (sidebarStatus) {
            $("#sidebar").addClass("active");
        } else {
            $("#sidebar").removeClass("active");
        } */

    $(document).ready(() => {
      let pathname = window.location.hash;
      pathname = pathname.replace('#/', '/');

      $('#sidebarCollapse').on('click', () => {
        $('#sidebar').toggleClass('active');
        localStorage.setItem('sideBarStatus', $('#sidebar').hasClass('active'));
      });

      this.setState({ currentPage: pathname });
    });
  }

  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  render() {
    const { currentPage } = this.state;
    return (
      <nav id="sidebar">
        <div className="sidebar-header text-center">
          <Link to={routes.DASHBOARD_ADMIN} className="menu-link">
            <img
              src={SideBarLogo}
              className="hidden-small text-center img-responsive img-logo-transaction"
              alt="OQ"
            />
          </Link>
          <strong>
            <img
              src={SideBarLogo}
              className="img-responsive hidden-large text-center img-logo-transaction"
              alt="OQ"
            />
          </strong>
        </div>
        <ul className="list-unstyled components">
          <li
            id="dashboard"
            className={currentPage === routes.DASHBOARD_ADMIN ? 'active' : ''}
          >
            <DashboardPageLink />
          </li>
          <li
            id="emails-admin"
            className={currentPage === routes.EMAILS_ADMIN ? 'active' : ''}
          >
            <EmailsPageLink />
          </li>
          <li
            id="users"
            className={
              this.getIsActive(routes.CLIENTS_ADMIN) ||
              this.getIsActive(routes.CREATE_CLIENT) ||
              this.getIsActive(routes.EDIT_CLIENT)
                ? 'active'
                : ''
            }
          >
            <ClientsPageLink />
          </li>
          <li
            id="users"
            className={
              this.getIsActive(routes.USERS_ADMIN)
                ? // ||
                  // this.getIsActive(routes.CREATE_CLIENT) ||
                  // this.getIsActive(routes.EDIT_CLIENT)
                  'active'
                : ''
            }
          >
            <UsersPageLink />
          </li>
          <li className={currentPage === routes.LEADS_ADMIN ? 'active' : ''}>
            <LeadsLink />
          </li>
          <li
            id="players"
            className={
              this.getIsActive(routes.PLAYERS_ADMIN) ||
              this.getIsActive(routes.DETAILS_PLAYER)
                ? 'active'
                : ''
            }
          >
            <PlayersPageLink />
          </li>
          <li
            className={
              currentPage === routes.PROSPECT_LEADS_ADMIN ? 'active' : ''
            }
          >
            <ProspectLeadsPageLink />
          </li>
          <li
            id="signUp"
            className={
              currentPage === routes.ADMINS ||
              currentPage === routes.SIGN_UP_ADMIN
                ? 'active'
                : ''
            }
          >
            <AdministratorsPageLink />
          </li>
          <li
            id="signUp"
            className={currentPage === routes.TERMS_ADMIN ? 'active' : ''}
          >
            <TermsPageLink />
          </li>
          <li
            id="wallets"
            className={currentPage === routes.WALLETS ? 'active' : ''}
          >
            <WalletsPageLink />
          </li>
          <li id="jobs" className={currentPage === routes.JOBS ? 'active' : ''}>
            <JobsPageLink />
          </li>
          <li id="logout">
            <SignOut />
          </li>
        </ul>
      </nav>
    );
  }
}

export default SideBar;

export { SideBar };
