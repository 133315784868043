import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { SideBar } from '../../components/SideBar';
import { NavBar } from '../../components/NavBar';
import * as routes from '../../../routes';
import { auth } from '../../../firebase';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCookieConsentValue } from 'react-cookie-consent';
import i18next from 'i18next';
import {
  faTachometerAlt,
  faChartLine,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons';

import { UserDataContext } from '../../../hooks/useUserData';

class Welcome extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);
    this.state = {
      currentUser: auth.getAuthUser(),
      authUser: {},
    };
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        {getCookieConsentValue() !== 'true' && (
          <div
            style={{
              display: '-webkit-flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              zIndex: '1001',
              position: 'fixed',
              bottom: 0,
            }}
          ></div>
        )}
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid  text-center"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              <br />
              <br />
              <div className="col-12">
                <h4>Bem Vindo!</h4>
                <img
                  src={
                    this.context.clientData && this.context.clientData.logoURL
                  }
                  alt=""
                  style={{ width: '50%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WelcomePageLink = (props) => (
  <Link className="menu-link" to={routes.WELCOME_CLIENT}>
    <FontAwesomeIcon icon={faTachometerAlt} />
    <span className="sidebarText">{i18next.t('welcome')}</span>
  </Link>
);

export default withNamespaces()(withRouter(Welcome));

export { WelcomePageLink };
