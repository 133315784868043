import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  RadialBarChart,
  Tooltip,
  RadialBar,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

class LeadsFunil extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loadingLeadsFunil, leadsFunil, style } = this.props.dataReport;

    const CustomTooltip = ({ payload }) => {
      const newPayload = payload ? payload[0] : null;
      const payloadNew = newPayload ? newPayload.payload : null;
      return (
        <div className="custom-tooltip">
          <p>
            {payloadNew ? payloadNew.name : 'Nada aqui'}:{' '}
            {newPayload ? newPayload.value : 'nada aqui'}{' '}
          </p>
        </div>
      );
    };

    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12">
              {loadingLeadsFunil && !leadsFunil ? (
                <LoadingDashboard size="4x" />
              ) : (
                <>
                  <span className="mb-1 bold">
                    {/* <i className="fa fa-trophy" /> */}
                    <svg
                      style={{
                        position: 'relative',
                        top: '2px',
                      }}
                      className="icon-trophy"
                      width="16"
                      height="16"
                      viewBox="0 0 23 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M11.5 15.9V18.8"
                        stroke="#000000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    &nbsp; Performances
                  </span>
                  <div className="box-chart">
                    <ResponsiveContainer>
                      <RadialBarChart
                        barSize={20}
                        data={leadsFunil}
                        startAngle={180}
                        endAngle={0}
                        cy={150}
                        innerRadius={20}
                        outerRadius={140}
                      >
                        <RadialBar
                          minAngle={20}
                          label={{
                            position: 'insideStart',
                            fill: '#666',
                          }}
                          background={{
                            color: '#000',
                          }}
                          clockWise={true}
                          dataKey="leads"
                        />

                        <Legend
                          iconSize={8}
                          width={470}
                          height={30}
                          verticalAlign="top"
                          align="left"
                          dataKey="name"
                          iconType="circle"
                          wrapperStyle={style}
                        />
                        <Tooltip content={<CustomTooltip />} />
                      </RadialBarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

LeadsFunil.contextTypes = {
  clientData: PropTypes.object,
};

export default LeadsFunil;
