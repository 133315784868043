import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import Modal from 'react-responsive-modal';
import _ from 'underscore';
import { api } from '../../../../Config';
import ModalRemoveLead from '../ModalRemoveLead';

import { Notify } from '../../../../components/Notify';
import { UserDataContext } from '../../../../hooks/useUserData';

class LeadDetails extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.translate = props.t;

    this.state = {
      modalIsOpen: false,
      lead: {},
      client: null,
      player: null,
      action: {},
      loadingPlayer: true,
      loadingAction: true,
      modalRemoveLeadIsOpen: false,
      leadIdSelected: '',
      authUser: '',
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { userData, userAuth, clientData } = this.context;
    if (nextProps.modalIsOpen) {
      this.getAllActionsForPlayer();
      this.handleLeadChange(nextProps.lead);
      this.setState({ authUser: nextProps.authUser });
    }

    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
  }

  handleLeadChange(lead) {
    this.setState({ lead });
  }

  renderLeadForm() {
    const { clientData } = this.context;
    const { lead, actionsOfPlayer } = this.state;
    const { player, action } = lead;
    let extraFieldName1;
    let extraFieldValue1;

    actionsOfPlayer &&
      actionsOfPlayer.map((action) => {
        let validNameExtraField =
          action &&
          action.extraFieldName1 &&
          action.extraFieldName1.toLowerCase().replace(/ /g, '_');

        if (lead[validNameExtraField] && !_.isEmpty(lead)) {
          extraFieldName1 =
            action && action.extraFieldName1
              ? action.extraFieldName1
              : 'Não Consta';
          extraFieldValue1 = lead[validNameExtraField];
        }
      });

    const renderPreferenceInputs = (preferences) => {
      return preferences
        .filter((item) => item !== null && item !== undefined)
        .map((item, index) => (
          <div className="form-group" key={index}>
            <label className="profile-label">Imobiliária</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={item.label}
            />
          </div>
        ));
    };

    return (
      <form className="col-md-12">
        <div className="row">
          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Geolocalização</label>
              <iframe
                src={`https://maps.google.com/maps?q=${
                  lead && lead.geoReference && lead.geoReference.lat
                },${
                  lead && lead.geoReference && lead.geoReference.lng
                }&hl=pt&z=17&output=embed`}
                style={{ width: '100%', height: '300px', border: '0' }}
              ></iframe>
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">Nome</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.name}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">E-mail</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.email}
            />
          </div>

          <div className="form-group">
            <label className="profile-label">Cidade</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                lead.city ||
                (lead && lead.geoReference && lead.geoReference.city)
              }
            />
          </div>

          <div className="form-group">
            <label className="profile-label">Estado</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                lead.state ||
                (lead && lead.geoReference && lead.geoReference.state)
              }
            />
          </div>

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Endereço</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.geoReference && lead.geoReference.address}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Bairro</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.geoReference && lead.geoReference.district}
              />
            </div>
          )}

          <div className="form-group">
            <label className="profile-label">Telefone</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                lead.phone || (lead && lead.player && lead.player.phoneNumber)
              }
            />
          </div>

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Solicitação</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonTypeAction}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Tipo do imóvel</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonType}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">CEP</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonCep}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Faixa de preço</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonPrice}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Valor do condomínio</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonPriceCondominium}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Imóvel com exclusividade?</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonUnique ? 'Sim' : 'Não'}
              />
            </div>
          )}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">
                Preferência por alguma imobiliária?
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonPreference ? 'Sim' : 'Não'}
              />
            </div>
          )}

          {lead &&
            clientData &&
            clientData.clientUrl === 'bomnegocio' &&
            lead.patrimonPreferenceNames &&
            renderPreferenceInputs(lead && lead.patrimonPreferenceNames)}

          {clientData && clientData.clientUrl === 'bomnegocio' && (
            <div className="form-group">
              <label className="profile-label">Sugestão de imobiliária</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead && lead.patrimonPreferenceNameOther}
              />
            </div>
          )}

          <div className="form-group">
            <label className="profile-label">Cliente</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                // player && player.clientName
                //   ? player.clientName
                //   : 'Cliente não encontrado'
                clientData && clientData.companyName
                  ? clientData.companyName
                  : 'Cliente não encontrado'
              }
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Player</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                player && player.name ? player.name : 'Player não encontrado'

                // this.state.player && this.state.player.name
                //   ? this.state.player.name
                //   : 'Player não encontrado'
              }
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Publicação</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={
                action && action.actionTitle
                  ? action.actionTitle
                  : 'Publicação não encontrada'
              }
            />
          </div>
          {lead && lead.businessName && lead.businessName !== '' && (
            <div className="form-group">
              <label className="profile-label">Corretor</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.businessName}
              />
            </div>
          )}
          {extraFieldName1 && extraFieldValue1 && (
            <div className="form-group">
              <label className="profile-label">{extraFieldName1}</label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={extraFieldValue1}
              />
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">
              {this.translate('Convertido')}
            </label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.converted ? 'Sim' : 'Não'}
            />
          </div>
          {lead.hostName && (
            <div className="form-group">
              <label className="profile-label">
                {this.translate('Player Anfitrião')}
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.hostName}
              />
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">{this.translate('Ação')}</label>
            <select
              className="form-control profile-placeholder"
              onChange={(e) => {
                let titleAction = actionsOfPlayer.find(
                  (action) => action.id === e.target.value
                ).actionTitle;

                this.setState({
                  lead: {
                    ...lead,
                    action: {
                      actionTitle: titleAction,
                    },
                    actionId: e.target.value,
                  },
                });
              }}
              value={lead && lead.actionId}
            >
              {actionsOfPlayer &&
                actionsOfPlayer.map((action) => {
                  return (
                    <option value={action.id} key={action.id}>
                      {action.actionTitle}
                    </option>
                  );
                })}
            </select>
          </div>
          {lead.hostEmail && (
            <div className="form-group">
              <label className="profile-label">
                {this.translate('Email do player anfitrião')}
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.hostEmail}
              />
            </div>
          )}
          <div className="form-group">
            <label className="profile-label">Id do Lead</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={lead.uid}
            />
          </div>
          {lead.idCustomer && (
            <div className="form-group">
              <label className="profile-label">
                {this.translate('ID Customizado')}
              </label>
              <input
                className="form-control profile-placeholder"
                readOnly
                value={lead.idCustomer}
              />
            </div>
          )}
        </div>
      </form>
    );
  }

  updateActionIdLead(lead) {
    this.setState({ creatingAction: true }, () => {
      const parameters = {
        searchFunctionality: 'updateLead',
        userType: 'client',
        lead,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          if (res.data.success) {
            this.props.updateList();
            this.props.closeModal();
            Notify('Lead atualizado', 'success');
          }
        })
        .catch((error) => {
          console.log('updateLead error:', error);
        });
    });
  }

  deleteSelectedLead(leadId) {
    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo Lead. Aguarde...', 'info', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deleteLead',
        userType: 'client',
        leadId,
        authUser: this.state.authUser,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          Notify('Lead excluido', 'success');
          this.props.updateList();
          this.props.closeModal();
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  toggleRemoveLead(leadId) {
    if (leadId) this.setState({ leadIdSelected: leadId });

    this.setState({ modalRemoveLeadIsOpen: !this.state.modalRemoveLeadIsOpen });
  }

  deleteLead() {
    return (
      <ModalRemoveLead
        open={this.state.modalRemoveLeadIsOpen}
        close={this.toggleRemoveLead.bind(this)}
        deleteSelectedLead={this.deleteSelectedLead.bind(this)}
        leadId={this.state.leadIdSelected}
      />
    );
  }

  getAllActionsForPlayer() {
    const parameters = {
      searchFunctionality: 'allActionsForPlayer',
      userType: 'client',
      playerId: this.state.lead && this.state.lead.playerId,
      clientId: this.state.lead && this.state.lead.clientId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (result && result.data) {
          const actions = result.data.filter((action) => action && action);
          this.setState({ actionsOfPlayer: actions });
        }
        this.setState({ loadingActions: false });
      })
      .catch((error) => {
        this.setState({ loadingActions: false });
        console.log('allActionsForPlayer error:', error);
      });
  }

  renderUpdateLead() {
    const { lead } = this.state;
    return (
      <div className="text-center">
        <button
          className="btn btn-oq"
          style={{ minWidth: '50%' }}
          onClick={(e) => {
            e.preventDefault();
            this.updateActionIdLead(lead);
          }}
        >
          Salvar
        </button>
      </div>
    );
  }

  renderRemoveLead() {
    const { lead } = this.state;
    return (
      <div className="col-6 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() =>
            this.props.isPermissionDelete
              ? this.toggleRemoveLead(lead.uid)
              : Notify('Você não tem permissão!', 'warn')
          }
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir Lead')}
        </span>
        {this.deleteLead()}
      </div>
    );
  }

  render() {
    return (
      <Modal
        open={this.props.modalIsOpen}
        onClose={() => this.props.closeModal()}
      >
        <div className="container-fluid">
          <div className="row">
            <span className="oq-card-title">
              <i className="fa fa-bullseye" />
              &nbsp; Detalhes do lead
            </span>
            {this.renderLeadForm()}
            {this.renderUpdateLead()}
            {this.renderRemoveLead()}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withNamespaces()(LeadDetails);
