import React, { Component } from 'react';

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  render() {
    if (!this.props.items) {
      return null;
    }

    return (
      <ul className="pagination">
        <li
          className={`${
            this.props.currentPage === 0 ? 'disabled' : ''
          } clickable`}
        >
          <a onClick={() => this.props.handlePreviousPage()}>Anterior</a>
        </li>

        <li className={`${this.props.isNext ? '' : 'disabled'} clickable`}>
          <a
            onClick={() =>
              this.props.isNext ? this.props.handleNextPage() : null
            }
          >
            Próxima
          </a>
        </li>
      </ul>
    );
  }
}

export default Pagination;
