import React from 'react';
import $ from 'jquery';

export default function CheckRequiredInputs() {
  {
    let timeSearch = null;

    timeSearch = setInterval(() => {

      let inputsRequired = $('input[required]');

      if (inputsRequired.length > 0) {

        clearInterval(timeSearch);

        $.each(inputsRequired, (i, val) => {
          $(val).on('focusout', (e) => {
            let isEmptyInput = $(e.target).val() == '' ? true : false;
            if (isEmptyInput) {
              $(e.target).addClass('required');
            } else {
              $(e.target).removeClass('required');
            }
          });
        });
      }
    }, 1000);
  }
  return '';
}

