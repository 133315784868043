import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { api } from '../../../../Config';
import ModalGamificationRule from '../../../components/Modals/ActionsModals/GamificationRuleModal';

import { Notify } from '../../../../components/Notify';
import * as routes from '../../../../routes';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';

import { faSmile, faTicketAlt } from '@fortawesome/free-solid-svg-icons';

import i18next from 'i18next';
import { UserDataContext } from '../../../../hooks/useUserData';

class Benefits extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      benefits: [],
      isLoading: false,
      loadingBenefits: false,
      searchObj: {},
      dataUser: [],
      modalGamificationRuleIsOpen: false,
    };

    return initialState;
  }

  openPreviewPage(d) {
    localStorage.setItem('actionPreview', JSON.stringify(d));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  async componentDidMount() {
    const { clientData } = this.context;
    this.setState({ dataUser: clientData });
    this.loadBenefits();
  }

  loadBenefits() {
    const { search } = this.state;

    this.setState({ loadingBenefits: true }, () => {
      const parameters = {
        searchFunctionality: 'loadBenefits',
        userType: 'client',
        search,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({
            benefits: result.data,
            loadingBenefits: false,
          });
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  goToNewBenefitsPage() {
    const { history } = this.props;
    history.push(routes.CREATE_BENEFIT);
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }

  renderModalsAndGo() {
    const { countSeasons, dataUser } = this.state;

    if (!dataUser.gamificationType) {
      this.toggleModalGamificationRule();
    } else this.goToNewBenefitsPage();
  }

  toggleModalGamificationRule() {
    this.setState({
      modalGamificationRuleIsOpen: !this.state.modalGamificationRuleIsOpen,
    });
  }

  redirectUrlGamificationRule() {
    this.props.history.push(routes.GAMIFICATION_CLIENT);
  }

  renderGamificationRuleModal() {
    return (
      <ModalGamificationRule
        open={this.state.modalGamificationRuleIsOpen}
        close={this.toggleModalGamificationRule.bind(this)}
        redirectUrlGamificationRule={this.redirectUrlGamificationRule.bind(
          this
        )}
      />
    );
  }

  renderSearchForm() {
    return (
      <div>
        {/* <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  type="date"
                  id="startDate"
                  name="startDate"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={this.state.isLoading}
                  onClick={() => this.searchActions()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {this.state.isLoading
                    ? this.translate('searching')
                    : this.translate('search')}
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  type="date"
                  id="endDate"
                  name="endDate"
                  onChange={e => this.handleSearchInputChange(e)}
                />
              </div>
            </div>
          </div>
        </div> */}
        {this.renderNewActionBtn()}
      </div>
    );
  }

  renderNewActionBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.loadingSeasons}
            onClick={() => this.renderModalsAndGo()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.loadingSeasons
              ? this.translate('loading')
              : this.translate('CRIAR NOVO BENEFÍCIO')}
          </button>
        </div>
        {this.renderGamificationRuleModal()}
      </div>
    );
  }

  actionDoesntHaveAwards = (d) => {
    return (
      !d.awardsList ||
      (_.size(d.awardsList) === 1 && !d.awardsList.premiumDescription)
    );
  };

  renderActionsTable() {
    let data = _.values(this.state.benefits);

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="image">
            {this.translate('image')}
            <ReactTooltip
              id="image"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('image')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'benefitImage',
        Cell: (props) => {
          var images = props.value;
          return (
            <img
              src={images}
              className="img-responsive img-rounded"
              style={{ width: 120, height: 'auto' }}
              alt="benefit banner"
            />
          );
        },
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="start">
            {this.translate('name')}
            <ReactTooltip
              id="start"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('start')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'Nome',
        accessor: 'benefitName',
      },
      // {
      //   Header: (
      //     <span data-tip='React-tooltip' data-for='number-of-awards'>
      //       {this.translate('number-of-awards')}
      //       <ReactTooltip
      //         id='number-of-awards'
      //         type='dark'
      //         effect='solid'
      //         multiline={true}
      //         className='tool-tip'
      //       >
      //         <span>
      //           {this.translate('OrderBy') + this.translate('number-of-awards')}
      //         </span>
      //       </ReactTooltip>
      //     </span>
      //   ),
      //   id: 'physicalAwards',
      //   accessor: d =>
      //     this.actionDoesntHaveAwards(d) ? '0' : _.size(d.awardsList),
      //   className: 'center-cell',
      //   minWidth: 150
      // },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {this.translate('status')}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'status',
        accessor: (d) => {
          if (d.benefitStatus) {
            return d.benefitStatus.value;
          }
        },
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {'Descrição'}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'benefitDescription',
        accessor: 'benefitDescription',
        style: { whiteSpace: 'unset' },
      },
      {
        Header: '',
        accessor: 'uid',
        className: 'center-cell',
        Cell: (props) => {
          return (
            <div
              className="btn-view"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Link to={`${routes.EDIT_BENEFIT}/${props.value}/`}>
                <button className="btn btn-oq">{this.translate('edit')}</button>
                <br></br>
              </Link>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'uid',
        className: 'center-cell',
        Cell: (props) => {
          return (
            <div
              className="btn-view"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Link to={`${routes.DETAILS_BENEFIT}/${props.value}/`}>
                <button className="btn btn-oq">
                  {this.translate('Visualizar')}
                </button>
                <br></br>
              </Link>
            </div>
          );
        },
      },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de benefícios')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText={this.translate('Nenhum benefício para ser exibido')}
              pageText={this.translate('next')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  searchActions() {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: this.filterActions()
          ? 'filterActions'
          : 'allActions',
        userType: 'client',
        actionType: 'quiz',
        searchObj: this.state.searchObj,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (!result.success) {
            this.setState({
              isLoading: false,
              countActions: 0,
              actions: [],
            });
            Notify(result.message, result.success ? 'success' : 'error');
            return;
          }
          this.setState({
            isLoading: false,
            countActions: result.data.actionsCount,
            actions: result.data.actions,
          });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ isLoading: true });
          console.log('searchQuiz error:', error);
        });
    });
  }

  filterActions() {
    const { searchObj } = this.state;

    return searchObj.name || searchObj.startDate || searchObj.endDate;
  }

  renderNotAuthorization() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: 220,
        }}
      >
        <h4>
          Módulo não autorizado. Favor entrar em contato com o administrador.
        </h4>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />

            {this.state.dataUser &&
            this.state.dataUser.moduleBenefits &&
            this.state.dataUser.moduleBenefits !== 'true' ? (
              this.renderNotAuthorization()
            ) : (
              <>
                {this.renderSearchForm()}
                {this.renderActionsTable()}
              </>
            )}
          </div>
        </div>
      </div>
      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />

      //     {this.state.dataUser.moduleBenefits &&
      //     this.state.dataUser.moduleBenefits !== 'true' ? (
      //       this.renderNotAuthorization()
      //     ) : (
      //       <>
      //         {this.renderSearchForm()}
      //         {this.renderActionsTable()}
      //       </>
      //     )}
      //     {/* {this.renderSearchForm()}
      //     {this.renderActionsTable()} */}
      //     {/* <div className="col-md-12 row-observation">
      //       <p className="oq-obersvation">
      //         * O número de participantes dos desafios também leva em
      //         consideração os players que foram excluídos do sistema.
      //       </p>
      //     </div> */}
      //   </div>
      // </div>
    );
  }
}

const BenefitsLink = (props) => (
  <>
    {props.showModuleBenefits && props.showModuleBenefits !== 'true' ? (
      <Link
        onClick={(e) =>
          props.showModuleBenefits &&
          props.showModuleBenefits !== 'true' &&
          e.preventDefault()
        }
        style={{
          cursor:
            props.showModuleBenefits &&
            props.showModuleBenefits !== 'true' &&
            'not-allowed',
          opacity:
            props.showModuleBenefits &&
            props.showModuleBenefits !== 'true' &&
            0.5,
        }}
        className="menu-link"
        to={routes.WELCOME_CLIENT}
      >
        <FontAwesomeIcon icon={faSmile} />
        <span className="sidebarText">{i18next.t('Benefícios')}</span>
      </Link>
    ) : (
      <Link
        style={{
          cursor:
            props.showModuleBenefits &&
            props.showModuleBenefits !== 'true' &&
            'not-allowed',
          opacity:
            props.showModuleBenefits &&
            props.showModuleBenefits !== 'true' &&
            0.5,
        }}
        className="menu-link"
        to={routes.BENEFITS_CLIENT}
      >
        <FontAwesomeIcon
          icon={faTicketAlt}
          style={{ transform: 'rotate(-45deg)' }}
        />
        <span className="sidebarText">{i18next.t('Benefícios')}</span>
      </Link>
    )}
  </>
);

export default Benefits;

export { BenefitsLink };
