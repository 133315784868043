import React, { Component } from 'react';

import _ from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { api } from '../../../../Config';

import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

class CreateAccessGroup extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      informationsGroupUncompleted: false,
      formErrors: {},
      formValid: false,
      isSelectedAll: false,
      formData: {
        name: '',
        description: '',
        permissions: {
          actions: {
            informational: {
              create: false,
              delete: false,
              edit: false,
              visualize: false,
            },
            omnichannel: {
              create: false,
              delete: false,
              edit: false,
              visualize: false,
            },
            quiz: {
              create: false,
              delete: false,
              edit: false,
              visualize: false,
            },
            virtual: {
              create: false,
              delete: false,
              edit: false,
              visualize: false,
            },
          },
          reedemAwards: {
            awards: {
              create: false,
              edit: false,
              visualize: false,
            },
            rescues: {
              create: false,
              edit: false,
              visualize: false,
            },
          },
          comunications: {
            banner: {
              create: false,
              delete: false,
              edit: false,
              visualize: false,
            },
            email: {
              create: false,
              delete: false,
              visualize: false,
            },
            message: {
              create: false,
              delete: false,
              visualize: false,
            },
          },
          generalConditions: {
            faq: {
              create: false,
              edit: false,
              visualize: false,
            },
            howItWorks: {
              create: false,
              edit: false,
              visualize: false,
            },
            privacyPolicies: {
              visualize: false,
            },
            termsOfUse: {
              create: false,
              edit: false,
              visualize: false,
            },
          },
        },
      },
    };

    return initialState;
  }

  informationsIsUncompleted() {
    const { formData } = this.state;

    return !formData.name || !formData.description;
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (!input && inputKey !== 'description') {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleInformationInput(e) {
    const { name, value } = e.target;
    this.handleFormDataInformationChange(name, value);
  }

  handleFormDataInformationChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          informationsGroupUncompleted: this.informationsIsUncompleted(),
        });
      }
    );
  }

  handlePermissionInput(e, namePermission) {
    const { name, checked } = e.target;
    this.handleFormDataPermissionChange(name, checked, namePermission);
  }

  handleFormDataPermissionChange(name, checked, namePermission) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [namePermission]: {
            ...prevState.formData.permissions[namePermission],
            [name]: checked,
          },
        },
      },
    }));
  }

  handlePermissionGroupInput(e, groupPermission, namePermission) {
    const { name, checked } = e.target;
    this.handleFormDataPermissionGroupChange(
      name,
      checked,
      groupPermission,
      namePermission
    );
  }
  handleFormDataPermissionGroupChange(
    name,
    checked,
    groupPermission,
    namePermission
  ) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [groupPermission]: {
            ...prevState.formData.permissions[groupPermission],
            [namePermission]: {
              ...prevState.formData.permissions[groupPermission][
                namePermission
              ],
              [name]: checked,
            },
          },
        },
      },
    }));
  }

  handleTeste(name, checked, groupPermission, namePermission) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          [groupPermission]: {
            ...prevState.formData.permissions[groupPermission],
            [namePermission]: {
              ...prevState.formData.permissions[groupPermission][
                namePermission
              ],
              [name]: checked,
            },
          },
        },
      },
    }));
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderInformationsGroup() {
    const { formData, informationsGroupUncompleted, formButtonPressed } =
      this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações do grupo
          {informationsGroupUncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Grupo de acesso')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  {/* (Até 72 caracteres) */}
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Nome do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Grupo de acesso')}
                onChange={(e) => this.handleInformationInput(e)}
                value={formData.name}
              />
            </div>
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Descrição')}
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  {/* (Até 72 caracteres) */}
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Descrição do grupo de acesso.</span>
                </ReactTooltip>
              </label>
              <textarea
                className="form-control profile-placeholder"
                placeholder={this.translate('Descrição')}
                rows="5"
                name="description"
                onChange={(e) => this.handleInformationInput(e)}
                value={formData.description}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPermissionDashboard() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionDashboard">
          {this.translate('Dashboard')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionDashboard"
          />
        </label>
        <ReactTooltip
          id="permissionDashboard"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'dashboard')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.dashboard &&
                  this.state.formData.permissions.dashboard.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionCompany() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionCompany">
          {this.translate('Empresa')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionCompany"
          />
        </label>
        <ReactTooltip
          id="permissionCompany"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'company')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.company &&
                  this.state.formData.permissions.company.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'company')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.company &&
                  this.state.formData.permissions.company.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAccessGroups() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAccessGroup">
          {this.translate('Grupos de acesso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAccessGroup"
          />
        </label>
        <ReactTooltip
          id="permissionAccessGroup"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.accessGroup &&
                  this.state.formData.permissions.accessGroup.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.accessGroup &&
                  this.state.formData.permissions.accessGroup.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.accessGroup &&
                  this.state.formData.permissions.accessGroup.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'accessGroup')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.accessGroup &&
                  this.state.formData.permissions.accessGroup.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionUsers() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionUsers">
          {this.translate('Usuários')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionUsers"
          />
        </label>
        <ReactTooltip id="permissionUsers" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.users &&
                  this.state.formData.permissions.users.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.users &&
                  this.state.formData.permissions.users.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.users &&
                  this.state.formData.permissions.users.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'users')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.users &&
                  this.state.formData.permissions.users.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLandingPage() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLandingPage">
          {this.translate('Landing Page')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLandingPage"
          />
        </label>
        <ReactTooltip
          id="permissionLandingPage"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.landingPage &&
                  this.state.formData.permissions.landingPage.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'landingPage')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.landingPage &&
                  this.state.formData.permissions.landingPage.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGamification() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionGamification">
          {this.translate('Gamification')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionGamification"
          />
        </label>
        <ReactTooltip
          id="permissionGamification"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'gamification')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.gamification &&
                  this.state.formData.permissions.gamification.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionAwards() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionAwards">
          {this.translate('Prêmios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionAwards"
          />
        </label>
        <ReactTooltip
          id="permissionAwards"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.awards &&
                  this.state.formData.permissions.reedemAwards.awards.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.awards &&
                  this.state.formData.permissions.reedemAwards.awards.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'awards')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.awards &&
                  this.state.formData.permissions.reedemAwards.awards.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionRescue() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '0.5rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionRescues">
          {this.translate('Resgates')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionRescues"
          />
        </label>
        <ReactTooltip
          id="permissionRescues"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.rescues &&
                  this.state.formData.permissions.reedemAwards.rescues.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.rescues &&
                  this.state.formData.permissions.reedemAwards.rescues.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.rescues &&
                  this.state.formData.permissions.reedemAwards.rescues.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="export"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'reedemAwards', 'rescues')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.reedemAwards &&
                  this.state.formData.permissions.reedemAwards.rescues &&
                  this.state.formData.permissions.reedemAwards.rescues.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionReedemAwards() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Resgate de Prêmios
        </h5>
        {this.renderPermissionAwards()}
        {this.renderPermissionRescue()}
      </>
    );
  }

  renderPermissionSeasons() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionSeasons">
          {this.translate('Temporadas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionSeasons"
          />
        </label>
        <ReactTooltip
          id="permissionSeasons"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.seasons &&
                  this.state.formData.permissions.seasons.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.seasons &&
                  this.state.formData.permissions.seasons.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.seasons &&
                  this.state.formData.permissions.seasons.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'seasons')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.seasons &&
                  this.state.formData.permissions.seasons.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionVirtual() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionVirtualActions">
          {this.translate('Virtuais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionVirtualActions"
          />
        </label>
        <ReactTooltip
          id="permissionVirtualActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.virtual &&
                  this.state.formData.permissions.actions.virtual.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.virtual.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.virtual.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'virtual')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.virtual.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionOmnichannel() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionOmnichannelActions">
          {this.translate('Presenciais')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionOmnichannelActions"
          />
        </label>
        <ReactTooltip
          id="permissionOmnichannelActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.omnichannel &&
                  this.state.formData.permissions.actions.omnichannel.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.omnichannel &&
                  this.state.formData.permissions.actions.omnichannel.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.omnichannel &&
                  this.state.formData.permissions.actions.omnichannel.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'omnichannel')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.omnichannel &&
                  this.state.formData.permissions.actions.omnichannel.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionQuiz() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionQuizActions">
          {this.translate('Quiz')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionQuizActions"
          />
        </label>
        <ReactTooltip
          id="permissionQuizActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.quiz &&
                  this.state.formData.permissions.actions.quiz.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.quiz &&
                  this.state.formData.permissions.actions.quiz.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.quiz &&
                  this.state.formData.permissions.actions.quiz.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'quiz')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.quiz &&
                  this.state.formData.permissions.actions.quiz.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInformational() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label
          className="profile-label"
          htmlFor="permissionInformationalActions"
        >
          {this.translate('Informativas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInformationalActions"
          />
        </label>
        <ReactTooltip
          id="permissionInformationalActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.informational &&
                  this.state.formData.permissions.actions.informational
                    .visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.informational &&
                  this.state.formData.permissions.actions.informational.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.informational &&
                  this.state.formData.permissions.actions.informational.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'informational')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.informational &&
                  this.state.formData.permissions.actions.informational.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionInvite() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionInviteActions">
          {this.translate('Convite')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionInviteActions"
          />
        </label>
        <ReactTooltip
          id="permissionInviteActions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.invite &&
                  this.state.formData.permissions.actions.invite.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.invite &&
                  this.state.formData.permissions.actions.invite.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.invite &&
                  this.state.formData.permissions.actions.invite.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'actions', 'invite')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.actions &&
                  this.state.formData.permissions.actions.invite &&
                  this.state.formData.permissions.actions.invite.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionActions() {
    const { clientData } = this.context;

    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Publicações
        </h5>
        {this.renderPermissionVirtual()}
        {this.renderPermissionOmnichannel()}
        {this.renderPermissionQuiz()}
        {this.renderPermissionInformational()}
        {clientData &&
          clientData.isTeamCompetition &&
          clientData.isTeamCompetition === 'true' &&
          this.renderPermissionInvite()}
      </>
    );
  }

  renderPermissionExtraPoints() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '3rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionExtraPoints">
          {this.translate('Pontos Extras')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionExtraPoints"
          />
        </label>
        <ReactTooltip
          id="permissionExtraPoints"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="attribute"
                onChange={(e) => this.handlePermissionInput(e, 'extraPoints')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.extraPoints &&
                  this.state.formData.permissions.extraPoints.attribute
                }
              />
              &nbsp;{this.translate('Atribuir')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="convert"
                onChange={(e) => this.handlePermissionInput(e, 'extraPoints')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.extraPoints &&
                  this.state.formData.permissions.extraPoints.convert
                }
              />
              &nbsp;{this.translate('Converter')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionNotifications() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionMessages">
          {this.translate('Notificações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionMessages"
          />
        </label>
        <ReactTooltip
          id="permissionMessages"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.message &&
                  this.state.formData.permissions.comunications.message
                    .visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.message &&
                  this.state.formData.permissions.comunications.message.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'message')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.message &&
                  this.state.formData.permissions.comunications.message.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionEmails() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionEmails">
          {this.translate('E-mails')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionEmails"
          />
        </label>
        <ReactTooltip
          id="permissionEmails"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.email &&
                  this.state.formData.permissions.comunications.email.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.email &&
                  this.state.formData.permissions.comunications.email.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'email')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.email &&
                  this.state.formData.permissions.comunications.email.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionBanners() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBanners">
          {this.translate('Banners')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBanners"
          />
        </label>
        <ReactTooltip
          id="permissionBanners"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.banner &&
                  this.state.formData.permissions.comunications.banner.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.banner &&
                  this.state.formData.permissions.comunications.banner.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.banner &&
                  this.state.formData.permissions.comunications.banner.edit
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'comunications', 'banner')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.comunications &&
                  this.state.formData.permissions.comunications.banner &&
                  this.state.formData.permissions.comunications.banner.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionComunication() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Comunicação
        </h5>
        {this.renderPermissionNotifications()}
        {this.renderPermissionEmails()}
        {this.renderPermissionBanners()}
      </>
    );
  }

  renderPermissionBenefits() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionBenefits">
          {this.translate('Benefícios')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionBenefits"
          />
        </label>
        <ReactTooltip
          id="permissionBenefits"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.benefits &&
                  this.state.formData.permissions.benefits.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.benefits &&
                  this.state.formData.permissions.benefits.create
                }
              />
              &nbsp;{this.translate('Criar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.benefits &&
                  this.state.formData.permissions.benefits.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'benefits')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.benefits &&
                  this.state.formData.permissions.benefits.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionLeadsConversions() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionLeadsConversions">
          {this.translate('Leads / Conversões')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionLeadsConversions"
          />
        </label>
        <ReactTooltip
          id="permissionLeadsConversions"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="convert"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.convert
                }
              />
              &nbsp;{this.translate('Converter')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="export"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
          </div>

          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="stepAdvance"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.stepAdvance
                }
              />
              &nbsp;{this.translate('Step Manual')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'leads')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.leads &&
                  this.state.formData.permissions.leads.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionPlayers() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionPlayers">
          {this.translate('Players')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionPlayers"
          />
        </label>
        <ReactTooltip
          id="permissionPlayers"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.players &&
                  this.state.formData.permissions.players.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.players &&
                  this.state.formData.permissions.players.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="export"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.players &&
                  this.state.formData.permissions.players.export
                }
              />
              &nbsp;{this.translate('Exportar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="delete"
                onChange={(e) => this.handlePermissionInput(e, 'players')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.players &&
                  this.state.formData.permissions.players.delete
                }
              />
              &nbsp;{this.translate('Excluir')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionConfigurations() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionConfigurations">
          {this.translate('Configurações')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionConfigurations"
          />
        </label>
        <ReactTooltip
          id="permissionConfigurations"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionInput(e, 'configurations')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.configurations &&
                  this.state.formData.permissions.configurations.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionTermsOfUse() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionTermsOfUse">
          {this.translate('Termos de uso')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionTermsOfUse"
          />
        </label>
        <ReactTooltip
          id="permissionTermsOfUse"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions
                    .termsOfUse &&
                  this.state.formData.permissions.generalConditions.termsOfUse
                    .visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'termsOfUse'
                  )
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions
                    .termsOfUse &&
                  this.state.formData.permissions.generalConditions.termsOfUse
                    .create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionFaq() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionFaq">
          {this.translate('FAQ')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionFaq"
          />
        </label>
        <ReactTooltip id="permissionFaq" type="dark" effect="solid" multiline>
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions.faq &&
                  this.state.formData.permissions.generalConditions.faq
                    .visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions.faq &&
                  this.state.formData.permissions.generalConditions.faq
                    .create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
            {/* <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="edit"
                onChange={e =>
                  this.handlePermissionGroupInput(e, 'generalConditions', 'faq')
                }
                checked={
                  formData &&
                  formData.permissions &&
                  formData.permissions.generalConditions &&
                  formData.permissions.generalConditions.faq &&
                  formData.permissions.generalConditions.faq.edit
                }
              />
              &nbsp;{this.translate('Editar')}
            </label> */}
          </div>
        </div>
      </div>
    );
  }

  renderPermissionHowItWorks() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionHowItWorks">
          {this.translate('Como Funciona')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionHowItWorks"
          />
        </label>
        <ReactTooltip
          id="permissionHowItWorks"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions
                    .howItWorks &&
                  this.state.formData.permissions.generalConditions.howItWorks
                    .visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) =>
                  this.handlePermissionGroupInput(
                    e,
                    'generalConditions',
                    'howItWorks'
                  )
                }
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.generalConditions &&
                  this.state.formData.permissions.generalConditions
                    .howItWorks &&
                  this.state.formData.permissions.generalConditions.howItWorks
                    .create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderPermissionGeneralConditions() {
    return (
      <>
        <h5
          // className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target=""
          aria-expanded="false"
        >
          Condições Gerais
        </h5>
        {this.renderPermissionTermsOfUse()}
        {this.renderPermissionFaq()}
        {this.renderPermissionHowItWorks()}
      </>
    );
  }

  renderPermissionWelcomeEmail() {
    return (
      <div
        className="row points-by-share col-md-6"
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '1rem',
        }}
      >
        <label className="profile-label" htmlFor="permissionWelcomeEmail">
          {this.translate('E-mail de Boas Vindas')}
          &nbsp;
          <i
            className="fa fa-info-circle"
            aria-hidden="true"
            data-tip="React-tooltip"
            data-for="permissionWelcomeEmail"
          />
        </label>
        <ReactTooltip
          id="permissionWelcomeEmail"
          type="dark"
          effect="solid"
          multiline
        >
          <span>
            {this.translate('Conceda persmissões para essa funcionalidade')}
          </span>
        </ReactTooltip>
        <div className="form-group col-md-5">
          <div className="profile-placeholder d-flex justify-content-between">
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="visualize"
                onChange={(e) => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.welcomeEmail &&
                  this.state.formData.permissions.welcomeEmail.visualize
                }
              />
              &nbsp;{this.translate('Visualizar')}
            </label>
            <label className="checkbox-inline">
              <input
                className="oq-checkbox"
                type="checkbox"
                name="create_edit"
                onChange={(e) => this.handlePermissionInput(e, 'welcomeEmail')}
                checked={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.permissions &&
                  this.state.formData.permissions.welcomeEmail &&
                  this.state.formData.permissions.welcomeEmail.create_edit
                }
              />
              &nbsp;{this.translate('Criar/Editar')}
            </label>
          </div>
        </div>
      </div>
    );
  }

  selectAllPermissions(e) {
    const { isSelectedAll } = this.state;

    this.setState({ isSelectedAll: !this.state.isSelectedAll });

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        permissions: {
          ...prevState.formData.permissions,
          accessGroup: {
            create: !isSelectedAll,
            delete: !isSelectedAll,
            edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          actions: {
            informational: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            omnichannel: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            quiz: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            virtual: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
          },
          benefits: {
            create: !isSelectedAll,
            delete: !isSelectedAll,
            edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          company: {
            edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          comunications: {
            banner: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            email: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            message: {
              create: !isSelectedAll,
              delete: !isSelectedAll,
              visualize: !isSelectedAll,
            },
          },
          configurations: {
            create_edit: !isSelectedAll,
          },
          dashboard: {
            visualize: !isSelectedAll,
          },
          extraPoints: {
            attribute: !isSelectedAll,
            convert: !isSelectedAll,
          },
          gamification: {
            create_edit: !isSelectedAll,
          },
          generalConditions: {
            faq: {
              create_edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            howItWorks: {
              create_edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            privacyPolicies: {
              visualize: !isSelectedAll,
            },
            termsOfUse: {
              visualize: !isSelectedAll,
              create_edit: !isSelectedAll,
            },
          },
          landingPage: {
            create_edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          leads: {
            convert: !isSelectedAll,
            delete: !isSelectedAll,
            export: !isSelectedAll,
            stepAdvance: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          players: {
            delete: !isSelectedAll,
            edit: !isSelectedAll,
            export: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          reedemAwards: {
            awards: {
              create: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
            },
            rescues: {
              create: !isSelectedAll,
              edit: !isSelectedAll,
              visualize: !isSelectedAll,
              export: !isSelectedAll,
            },
          },
          users: {
            create: !isSelectedAll,
            delete: !isSelectedAll,
            edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
          welcomeEmail: {
            create_edit: !isSelectedAll,
            visualize: !isSelectedAll,
          },
        },
      },
    }));
  }

  renderPermissionsGroup() {
    const { clientData } = this.context;
    const { formData, phase1Uncompleted } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Permissões do grupo
          {/* {phase1Uncompleted &&
            this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="form-group col-md-12" style={{ marginTop: '30px' }}>
            <div className="profile-placeholder d-flex justify-content-between">
              <label className="checkbox-inline">
                <input
                  className="oq-checkbox"
                  type="checkbox"
                  name="selectAllPermissions"
                  onChange={(e) => this.selectAllPermissions(e)}
                />
                Selecionar todas as permissões
              </label>
            </div>
          </div>

          {this.renderPermissionDashboard()}
          {this.renderPermissionCompany()}
          {this.renderPermissionAccessGroups()}
          {this.renderPermissionUsers()}
          {this.renderPermissionLandingPage()}
          {this.renderPermissionGamification()}
          {this.renderPermissionReedemAwards()}
          {this.renderPermissionActions()}
          {this.renderPermissionExtraPoints()}
          {this.renderPermissionComunication()}
          {this.renderPermissionBenefits()}
          {this.renderPermissionLeadsConversions()}
          {this.renderPermissionPlayers()}
          {this.renderPermissionConfigurations()}
          {this.renderPermissionGeneralConditions()}
          {this.renderPermissionWelcomeEmail()}
          {clientData &&
            clientData.isGamified &&
            clientData.isGamified === 'true' &&
            this.renderPermissionSeasons()}
        </div>
        <hr />
      </div>
    );
  }

  createAccessGroup() {
    const { formData } = this.state;
    const { userData } = this.context;
    const clientId = userData && userData.clientId;

    this.setState({ creatingAccessGroup: true }, () => {
      this.toastId = toast.info('Criando grupo de acesso. Aguarde...', {
        autoClose: false,
      });

      const parameters = {
        userType: 'client',
        searchFunctionality: 'createAccessGroup',
        accessGroupData: formData,
        clientId: clientId ? clientId : '',
      };

      api
        .post(`/accessGroup`, parameters)
        .then((res) => {
          const response = res.data;

          if (response.success) {
            this.setState({ creatingAccessGroup: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
              this.setState(this.getInitialState());
            });
          } else {
            this.setState({ creatingAccessGroup: false }, () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.ERROR,
                autoClose: true,
              });

              // this.setState(this.getInitialState());
              // this.props.history.goBack();
            });
          }
        })
        .catch((error) => {
          this.setState({ creatingAccessGroup: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel criar o grupo de acesso!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
          console.log('createAccessGroup error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.createAccessGroup();
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderInformationsGroup()}
        {this.renderPermissionsGroup()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.creatingAccessGroup}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  informationsGroupUncompleted:
                    this.informationsIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Novo Grupo')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateAccessGroup;
