import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import { Notify } from '../../components/Notify';
import * as routes from '../../routes';

import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class HistoricalWallet extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      walletClient: [],
      isLoadingHistoricalWallet: false,
    };
    this.translate = props.t;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ clientId: params.clientId });
    this.getHistoricalWalletClient(params.clientId);
  }

  async getHistoricalWalletClient(clientId) {
    try {
      this.setState({ isLoadingHistoricalWallet: true });

      const parameters = {
        searchFunctionality: 'getHistoricalWalletClient',
        userType: 'administrator',
        clientId,
      };

      const res = await api.post(`/search`, parameters);

      const response = res.data;

      if (response.success) {
        this.setState({
          isLoadingHistoricalWallet: false,
          walletClient: response.data.walletClient,
        });
        Notify(response.message, 'success');
      } else {
        this.setState({ isLoadingHistoricalWallet: false, walletClient: [] });
        Notify(response.message, 'warn');
      }
    } catch (error) {
      console.log('getHistoricalWalletClient error:', error);
      this.setState({ isLoadingHistoricalWallet: false });
    }
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  formatMoney(amount) {
    // if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  exportWallets() {
    const { walletClient } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(walletClient, (wallet) => {
        return {
          Operação:
            !wallet.operationType || wallet.operationType !== 'exit'
              ? 'Entrada'
              : 'Saída',
          Valor: wallet.entrance
            ? this.formatMoney(wallet.entrance)
            : this.formatMoney(wallet.exit),
          'Data da movimentação': wallet.createdAt
            ? moment(wallet.createdAt).format('DD/MM/YYYY HH:mm:ss')
            : moment(wallet.createdA).format('DD/MM/YYYY HH:mm:ss'),
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Historico');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Historico.xlsx'
    );
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  renderHistorical() {
    const { walletClient } = this.state;

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="operationType">
            {this.translate('Operação')}
            <ReactTooltip
              id="operationType"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Operação')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          !d.operationType || d.operationType !== 'exit' ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>Entrada</p>{' '}
              <i
                style={{
                  marginLeft: '10px',
                  marginBottom: '5px',
                  color: 'green',
                }}
                className="fa fa-arrow-up"
                aria-hidden="true"
              ></i>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p>Saída</p>{' '}
              <i
                style={{
                  marginLeft: '10px',
                  marginBottom: '5px',
                  color: 'red',
                }}
                className="fa fa-arrow-down"
                aria-hidden="true"
              ></i>
            </div>
          ),
        id: 'operationType',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="value">
            {this.translate('Valor')}
            <ReactTooltip
              id="value"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Valor')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.entrance ? this.formatMoney(d.entrance) : this.formatMoney(d.exit),
        id: 'value',
      },
      {
        id: 'createdAt',
        Header: (
          <span data-tip="React-tooltip" data-for="date">
            {this.translate('Data da movimentação')}
            <ReactTooltip
              id="date"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') +
                  this.translate('Data da movimentação')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.createdAt
            ? moment(d.createdAt).format('DD/MM/YYYY HH:mm:ss')
            : moment(d.createdA).format('DD/MM/YYYY HH:mm:ss'),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title">
            <i className="fa fa-gift" />
            &nbsp; Movimentação Financeira
          </span>
          <button
            type="button"
            className="btn btn-oq pull-right"
            onClick={() => this.exportWallets()}
            disabled={_.size(walletClient) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div className="oq-padding-bottom" />
          <ReactTable
            data={walletClient}
            defaultPageSize={10}
            columns={columns}
            loading={this.state.isLoadingHistoricalWallet}
            previousText="Anterior"
            nextText="Próxima"
            loadingText="Carregando..."
            noDataText="Nenhuma movimentação para ser exibido"
            pageText="Página"
            ofText="de"
            rowsText="linhas"
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Histórico da carteira
                </span>
              </h1>
            </div>
            {/* <form
            >
              {this.renderHistoricalWallet()}
            </form> */}
            {this.renderHistorical()}
          </div>
        </div>
      </div>
    );
  }
}

const HistoricalWalletPageLink = () => (
  <Link className="menu-link" to={routes.HISTORICAL_WALLET_ADMIN}>
    <FontAwesomeIcon icon={faWallet} />
    <span>Histórico Financeiro</span>
  </Link>
);

export default HistoricalWallet;

export { HistoricalWalletPageLink };
