import React from 'react';
import Modal from 'react-responsive-modal';

function ModalRemoveLead(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Você tem certeza que deseja continuar?
        </h2>
        <p>Você tem certeza que deseja realmente excluir esse lead ?</p>
        <p>Essa exclusão não poderá ser desfeita após a confirmação.</p>
        <br />
        <div className="oq-group-horizontal">
          <button className="oq-btn" onClick={props.close}>
            Cancelar
          </button>
          <button
            className="oq-btn btn-confirm"
            onClick={() => {
              props.deleteSelectedLead(props.leadId);
              props.close();
            }}
          >
            Confirmar
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalRemoveLead;
