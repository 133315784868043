import logo from '../../Images/logo-lp.png';
import frameHome from '../../Images/frame-home.png';
import frameCards from '../../Images/frame-home-cards.png';
import { Button } from '../../components/Button';
import { Link } from 'react-router-dom';
import * as routes from '../../../routes';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import axios from 'axios';
import SideBar from '../../components/SideBar';
import NavBar from '../../components/NavBar';
import { api, getAPIURL } from '../../../Config';

import { useUserData } from '../../../hooks/useUserData';

export function LandingHome() {
  // const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const { clientData, userData, userAuth } = useUserData();
  const [template, setTemplate] = useState();
  let isCheckTerms = false;

  useState(() => {
    getClientSite();
  }, []);

  function transformData(data) {
    const steps = data.map((item, index) => {
      let icon = '';
      switch (index) {
        case 0:
          icon = 'share';
          break;
        case 1:
          icon = 'co_present';
          break;
        case 2:
          icon = 'task';
          break;
        case 3:
          icon = 'paid';
          break;
        default:
          icon = 'paid'; // Adicione um ícone padrão caso não haja correspondência
      }

      return {
        icon: icon,
        position: index + 1,
        description:
          item.value > 0
            ? `Aqui você já ganha até ${item.value} pontos`
            : `Este é Passo ${index + 1}`,
        title: item.name,
      };
    });

    return steps;
  }

  async function createNewSite() {
    try {
      let params = {
        client_name: clientData && clientData.clientUrl,
        logo_URL: clientData.originalLogoClient
          ? clientData.originalLogoClient
          : clientData.logoURL,
        fav_icon: clientData && clientData.favIcon,
        primary_color: clientData && clientData.primaryColor,
        secondary_color: '#000000',
        text_color: '#ffffff',
        text_cta_color: '#ffffff',
        template_name: 'default',
        home_link: '0',
        regulation_link: '0',
        terms_of_use_link: '0',
        privacy_policy_link: '0',
        menu_links: [
          { title: 'Regulamento', link: '0' },
          { title: 'Privacidade', link: '0' },
          { title: 'Termos de uso', link: '0' },
          { title: 'Sobre a empresa', link: '0' },
        ],
        meta_tags: [{ name: 'description', content: 'Descrição aqui' }],
        client_program_logo_url: '1',
        assets: {
          fold_1_avatar_image: 'https://iili.io/J4xpDFV.png',
          fold_2_avatar_image: 'https://iili.io/J4z9rhu.png',
          fold_3_avatar_image: 'https://iili.io/J4z94Lb.png',
        },
        texts: {
          fold_1_paragraph:
            'Seu texto personalizado aqui para a primeira sessão.',
          max_award: 1000,
        },

        steps: transformData(clientData && clientData.steps),
        social_links: {
          instagram: '0',
          facebook: '0',
          whatsapp: '0',
        },
        facebook_pixel_id: '0',
        google_tag_id: '0',
        register: {
          url: `https://app.oqdigital.com/_/${
            clientData && clientData.clientUrl
          }`,
          action_id: '0',
          player_id: '0',
          client_id: clientData && clientData.uid,
        },
      };

      let result = await api.post(
        `clients/${
          clientData && clientData.clientUrl
        }/indique-e-ganhe/template-props`,
        params
      );

      let resultActivate = await api.post(
        `clients/${
          clientData && clientData.clientUrl
        }/indique-e-ganhe/activate`,
        { active: true }
      );

      let resultUse = await api.post(
        `clients/${
          clientData && clientData.clientUrl
        }/indique-e-ganhe/template`,
        { template: 'default' }
      );

      getClientSite();
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async function getClientSite() {
    try {
      let result = await api.get(
        `clients/${
          clientData && clientData.clientUrl
        }/indique-e-ganhe/template-props`
      );

      if (result && result.data) {
        setTemplate(result.data);
      }
    } catch (error) {
      console.log('error: ', error);
    }
  }

  return (
    <>
      <div className="oq-dash-wrapper" style={{ fontSize: '14px' }}>
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col">
                  <div className="d-flex flex-column align-items-center">
                    <div className="mt-5 mb-4">
                      {/* <img src={logo} width={300} alt="" /> */}
                      <h1 className="fw-bold mb-0">Landing Pages</h1>
                    </div>
                    <p className="text-center">
                      Siga as instruções e publique com só clique!
                    </p>
                    <br />
                    {!template && (
                      <>
                        <div className="mt-4">
                          <Button
                            text="Criar novo site"
                            icon="add"
                            onClick={() => {
                              if (isCheckTerms) {
                                createNewSite();
                              } else {
                                toast.info('Aceite os termos!');
                              }
                            }}
                          />
                        </div>
                        <div className="mt-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="terms"
                              onChange={() => {
                                isCheckTerms = !isCheckTerms;
                              }}
                            />
                            <label
                              className="form-check-label text-black-50 text-center"
                              htmlFor="terms"
                              style={{ fontSize: '12px' }}
                            >
                              Aceito os termos de uso e privacidade do programa
                              <br />e da OQ Digital
                            </label>
                          </div>
                        </div>
                      </>
                    )}
                    {template && (
                      <>
                        <a
                          className="fs-3"
                          target="blank"
                          href={`https://indicacaopremiada.me/${template.client_name}`}
                        >{`https://indicacaopremiada.me/${template.client_name}`}</a>
                        <div className="mt-4 w-100">
                          <div className="border rounded-3 p-3 d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <div
                                className="rounded-circle bg-success d-flex align-items-center justify-content-center"
                                style={{ width: '40px', height: '40px' }}
                              >
                                <i className="material-symbols-outlined text-white">
                                  check
                                </i>
                              </div>
                              <span className="ms-3">Seu site está online</span>
                            </div>
                            <div className="d-flex align-items-center">
                              <a
                                className="fs-3"
                                target="blank"
                                href={`https://indicacaopremiada.me/${template.client_name}`}
                              >
                                <button className="btn border me-3">
                                  Acessar
                                </button>
                              </a>
                              <Link
                                to={`${routes.LANDING_PAGE_EDITOR}`}
                                className="first-step"
                              >
                                <Button text="Personalize aqui" icon="edit" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <img
                  src={frameHome}
                  className="position-fixed bottom-0 shadow rounded-4 animate__animated animate__slideInUp"
                  alt=""
                />
                <img
                  src={frameCards}
                  className="position-fixed bottom-0 animate__animated animate__slideInUp animate__delay-1s"
                  alt=""
                  width="60%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
