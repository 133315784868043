import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../../../components/SideBar';
import { NavBar } from '../../../components/NavBar';
import * as routes from '../../../../routes';
import DocumentsModal from '../../../components/Modals/DocumentsModal';
import { Notify } from '../../../../components/Notify';
import { UserDataContext } from '../../../../hooks/useUserData';

class Terms extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      users: [],
      name: '',
      email: '',
      city: '',
      countUsers: 0,
      isLoading: false,
      openModal: false,
    };

    return initialState;
  }

  goToPreviewText(editorPage) {
    const { history } = this.props;
    history.push(`${routes.PREVIEW_TEXT}/${editorPage}`);
  }

  renderTermsItems() {
    const { clientData, groupPermissions, userData } = this.context;

    let isPermissionPrivacy = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionPrivacy =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.gamification &&
          groupPermissions.permissions.gamification.create_edit) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (
      userData &&
      userData.group &&
      userData.group.value &&
      !userData.master
    ) {
      isPermissionPrivacy =
        groupPermissions &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.gamification &&
        groupPermissions[userData.group.value].permissions.gamification
          .create_edit;
    }

    return (
      <div>
        <div
          className="oq-card d-flex col-md-12"
          style={{ width: '97%', height: '11vh', padding: 0 }}
        >
          <div
            className="d-flex col-md-1 col-xs-3 border-right align-items-center justify-content-center"
            style={{ width: '12%' }}
          >
            <img
              src={require('../../../Images/termos-de-uso-icon.png')}
              className="img-responsive terms_img"
              alt="icon terms of use"
            />
          </div>
          <div
            className="col-md-10 col-xs-9 clickable"
            onClick={() => this.goToPreviewText('terms')}
          >
            <span className="notification col-md-12 vertical-center">
              <span className="oq-card-span oq-font-medium h4-oq">
                {this.translate('Termos de uso')}
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div
            className="col-md-1 hidden-xs vertical-center clickable"
            onClick={() => this.goToPreviewText('terms')}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              size="2x"
              style={{ color: 'rgb(163, 168, 173)' }}
            />
          </div>
        </div>

        <div
          className="oq-card d-flex col-md-12"
          style={{ width: '97%', height: '11vh', padding: 0 }}
        >
          <div
            className="d-flex col-md-1 col-xs-3 border-right align-items-center justify-content-center"
            style={{ width: '12%' }}
          >
            <img
              src={require('../../../Images/Icons/faqIcon.svg')}
              className="img-responsive terms_img"
              alt="icon about information"
            />
          </div>
          <div
            className="col-md-10 col-xs-9 clickable"
            onClick={() => this.goToPreviewText('faq')}
          >
            <span className="notification col-md-12 vertical-center">
              <span className="oq-card-span oq-font-medium h4-oq">
                {this.translate('FAQ')}
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div
            className="col-md-1 hidden-xs vertical-center clickable"
            onClick={() => this.goToPreviewText('faq')}
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              size="2x"
              style={{ color: 'rgb(163, 168, 173)' }}
            />
          </div>
        </div>

        {clientData && clientData.useTutorial !== 'true' && (
          <>
            <div
              className="oq-card d-flex col-md-12"
              style={{ width: '97%', height: '11vh', padding: 0 }}
            >
              <div
                className="d-flex col-md-1 col-xs-3 border-right align-items-center justify-content-center"
                style={{ width: '12%' }}
              >
                <img
                  src={require('../../../Images/como-funciona-icon.png')}
                  className="img-responsive terms_img"
                  alt="icon how it works"
                />
              </div>

              <div
                className="col-md-10 col-xs-9 clickable"
                onClick={() => this.goToPreviewText('howItWorks')}
              >
                <span className="notification col-md-12 vertical-center">
                  <span className="oq-card-span oq-font-medium h4-oq">
                    {this.translate('Como funciona')}
                  </span>
                </span>
                <p className="notification col-md-12" />
              </div>

              <div
                className="col-md-1 hidden-xs vertical-center clickable"
                onClick={() => this.goToPreviewText('howItWorks')}
              >
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="2x"
                  style={{ color: 'rgb(163, 168, 173)' }}
                />
              </div>
            </div>
          </>
        )}

        <div
          className="oq-card d-flex col-md-12"
          style={{ width: '97%', height: '11vh', padding: 0 }}
        >
          <div
            className="d-flex col-md-1 col-xs-3 border-right align-items-center justify-content-center"
            style={{ width: '12%' }}
          >
            <img
              src={require('../../../Images/politica-de-privacidade-icon.png')}
              className="img-responsive terms_img"
              alt="privacy politics icon"
            />
          </div>
          <div className="col-md-10 col-xs-9 clickable">
            <span
              className="notification col-md-12 vertical-center"
              onClick={() =>
                isPermissionPrivacy
                  ? this.handleModalToggle('openModal')
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              <span className="oq-card-span  oq-font-medium h4-oq">
                {this.translate('Politicas de privacidade')}
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div
            className="col-md-1 hidden-xs vertical-center clickable"
            onClick={() => this.handleModalToggle('openModal')}
          >
            <div className="col-md-1 hidden-xs vertical-center">
              <FontAwesomeIcon
                icon={faAngleRight}
                size="2x"
                style={{ color: 'rgb(163, 168, 173)' }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid oq-padding-horizontal">
              {this.renderTermsItems()}
            </div>
          </div>
          <DocumentsModal
            modalIsOpen={this.state.openModal}
            closeModal={() => this.handleModalToggle('openModal')}
          />
        </div>
      </div>
    );
  }
}

const TermsLink = (props) => (
  <Link className="menu-link" to={routes.TERMS_CLIENT}>
    <FontAwesomeIcon icon={faBook} />
    <span className="sidebarText">{props.translate('terms')}</span>
  </Link>
);

export default Terms;

export { TermsLink };
