import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { api } from '../../../../../Config';

import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import NotificationModal from '../../../../components/Modals/Notifications/NotificationModal';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';

import { Images } from '../../../../../Assets/Images';

import renderHTML from 'react-render-html';

import { UserDataContext } from '../../../../../hooks/useUserData';

class Messages extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
      isLoading: false,
      modalIsOpen: false,
      notification: {},
    };
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getNotifications',
        userType: 'client',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState({ isLoading: false, actions: [] });
            Notify(result.message, result.success ? 'success' : 'error');
            return;
          }

          let notificationsFiltered = result.data.notifications.filter(
            (notifications) => {
              return notifications.scope !== 'direct';
            }
          );

          this.setState({
            isLoading: false,
            notifications: notificationsFiltered,
          });
          /* Notify(result.message, result.success ? "success" : "error"); */
        })
        .catch((error) => {
          console.log('getNotifications error:', error);
        });
    });
  }

  getNotificationIcon(type) {
    switch (type) {
      case 'newAction':
        return require('../../../../Images/Play Icon.png');
      case 'newMessage':
        return require('../../../../Images/EmailIcon.png');
      case 'endAction':
        return require('../../../../Images/Flag Icon.png');
      default:
        return require('../../../../Images/@ Icon.png');
    }
  }

  toggleNotificationModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
    this.getNotifications();
  }

  openNotificationModal(notification) {
    const { modalIsOpen } = this.state;
    if (!modalIsOpen) {
      this.setState({ notification });
    }
    this.toggleNotificationModal();
  }

  renderModal(isPermissionDelete) {
    return (
      <NotificationModal
        isPermissionDelete={isPermissionDelete}
        open={this.state.modalIsOpen}
        notification={this.state.notification}
        toggleNotificationModal={() => this.toggleNotificationModal()}
      />
    );
  }

  renderNewMessageBtn() {
    const { isLoading } = this.state;
    return (
      <div className="col-md-12 oq-padding-vertical">
        <div className="col-md-3 p-0">
          <Link
            to={routes.NEW_MESSAGE}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;
            {isLoading ? 'Carregando...' : 'Nova Notificação'}
          </Link>
        </div>
      </div>
    );
  }

  renderNotifications() {
    const { groupPermissions, userData } = this.context;
    const { notifications, isLoading } = this.state;

    const isPermissionDelete =
      (groupPermissions &&
        groupPermissions.permissions &&
        groupPermissions.permissions.comunications &&
        groupPermissions.permissions.comunications.message &&
        groupPermissions.permissions.comunications.message.delete) ||
      (userData && userData.master && userData.master);

    return (
      <div>
        {notifications && !isLoading && _.size(notifications) <= 0 && (
          <div className="col-12 text-center">
            <img
              className="img-fluid"
              src={Images.noNotify}
              alt="Nenhuma notificação para você."
            />
            <div className="col-12 text-center">
              <h3>Ooops...</h3>
              <h4>Nenhuma notificação.</h4>
            </div>
          </div>
        )}
        {_.size(notifications) > 0 &&
          _.sortBy(notifications, 'createDate')
            .reverse()
            .map((notification) => {
              return (
                <>
                  <div
                    className="row"
                    id="notification-view"
                    key={notification.id}
                    onClick={() => this.openNotificationModal(notification)}
                  >
                    <div className="oq-card d-flex row">
                      <div
                        className="col-md-1 col-3 border-right text-center"
                        style={{ paddingTop: '12px' }}
                      >
                        <svg
                          width="23"
                          className="oq-svg-primary-color mt5"
                          style={{ marginLeft: -15 }}
                          height="20"
                          viewBox="0 0 23 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 8.89657V8.59657C0.1 8.39657 0.3 8.19658 0.6 8.09657C2.7 7.29657 4.7 6.49657 6.8 5.69657L21.4 0.0965745C21.9 -0.103425 22.2 -0.00342545 22.4 0.496575V0.796575C22.4 0.796575 22.4 0.896574 22.3 0.896574C22 2.39657 21.7 3.79657 21.4 5.29657C20.8 8.09657 20.2 10.8966 19.7 13.5966C19.3 15.3966 19 17.0966 18.6 18.8966C18.5 19.2966 18.3 19.4966 18 19.4966C17.8 19.3966 17.6 19.3966 17.5 19.1966C15.9 17.9966 14.3 16.8966 12.7 15.6966C12.3 15.2966 11.7 15.2966 11.4 15.5966L11.3 15.6966C10.4 16.3966 9.6 17.0966 8.7 17.7966C8.5 18.0966 8.1 18.0966 7.8 17.7966C7.7 17.6966 7.6 17.5966 7.6 17.3966C7.6 17.2966 7.5 17.1966 7.5 17.0966C6.9 15.1966 6.3 13.1966 5.7 11.2966C5.6 11.1966 5.5 10.9966 5.4 10.9966C3.8 10.5966 2.2 9.99657 0.6 9.39657C0.3 9.39657 0.1 9.19657 0 8.89657ZM6.8 11.1966C6.8 11.2966 6.8 11.3966 6.8 11.4966C7.2 12.7966 7.5 13.9966 7.9 15.2966C7.9 15.3966 8.1 15.4966 8.2 15.5966C8.3 15.4966 8.4 15.3966 8.4 15.2966C8.5 14.6966 8.6 14.0966 8.7 13.4966C8.8 12.9966 9 12.4966 9.4 12.0966C11.9 9.69658 14.5 7.39657 17 4.99657C17.2 4.79657 17.4 4.69657 17.6 4.49657V4.29657C17.5 4.29657 17.4 4.29657 17.4 4.29657C17.3 4.29657 17.2 4.39657 17.2 4.39657L11.8 7.79657C10.4 8.69657 9 9.49657 7.5 10.3966C7 10.3966 6.8 10.6966 6.8 11.1966Z"
                            fill={this.context.clientData.primaryColor}
                          />
                        </svg>
                      </div>
                      <div className="col-md-11 col-9">
                        <span className="notification col-md-12">
                          <div>
                            <span className="oq-card-span">
                              {notification.actionTitle}
                            </span>
                            <span className="oq-card-span oq-card-text-muted pull-right oq-font-light">
                              {moment(notification.createDate).fromNow()}
                            </span>
                          </div>
                        </span>
                        <p className="notification col-md-12">
                          <span className="oq-card-span oq-font-light">
                            {notification.notificationContent
                              ? renderHTML(notification.notificationContent)
                              : ''}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        <div>{this.renderModal(isPermissionDelete)}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderNewMessageBtn()}
              {this.renderNotifications()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderNewMessageBtn()}
      //       {this.renderNotifications()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default Messages;
