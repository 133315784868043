import '../styles.css';
import React, { useEffect, useState } from 'react';
import tagManangerLogo from '../../../../../../Assets/Images/tag-manager-logo.png';
import analyticsLogo from '../../../../../../Assets/Images/analytics-logo.png';
import pixelLogo from '../../../../../../Assets/Images/pixel-logo.png';
import cancelLogo from '../../../../../../Assets/Images/cancel-logo.png';

export function Integrations({
  handleIntegration,
  onChange,
  onChangeSecondary,
  selected,
  value,
  valueSecondary,
}) {
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [valueCodeIntegration, setValueCodeIntegration] = useState();
  const [valueCodeIntegrationBody, setValueCodeIntegrationBody] = useState();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (selected !== 3) {
      setIsSaved(true);
    }
  }, []);

  useEffect(() => {
    
    setSelectedIntegration(selected);
  }, [selected]);

  useEffect(() => {
    
    if (value) {
      setValueCodeIntegration(value);
    }
  }, [value]);

  useEffect(() => {
    
    if (valueSecondary) {
      setValueCodeIntegrationBody(valueSecondary);
    }
  }, [valueSecondary]);

  return (
    <>
      {isSaved && (
        <>
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading">Integração feita!</h4>
            <p>
              {`Esta campanha/publicação está integrada com o ${(selected ===
                0 &&
                'Pixel do Facebook') ||
                (selected === 1 && 'Google Analytics') ||
                (selected === 2 && 'Google Tag Manager')}.`}
            </p>
          </div>
          <hr />
          <div className="alert alert-danger" role="alert">
            <h4 className="alert-heading">Remover integração</h4>
            <p>
              {`Se remover a integração com o ${(selected === 0 &&
                'Pixel do Facebook') ||
                (selected === 1 && 'Google Analytics') ||
                (selected === 2 &&
                  'Google Tag Manager')} você não receberá mais dados desta campanha/publicação!`}
            </p>
            <hr />
            <p className="mb-0">
              <button
                className="btn btn-danger"
                onClick={e => {
                  e.preventDefault();
                  handleIntegration(false);
                  setSelectedIntegration(3);
                  setValueCodeIntegration('');
                  setValueCodeIntegrationBody('');
                  setIsSaved(false);
                }}
              >
                Remover integração
              </button>
            </p>
          </div>
        </>
      )}

      {!isSaved && (
        <>
          <h4>Passo 1: Escolha uma das integrações</h4>
          <br />
          <div className="row">
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(0);
                  handleIntegration(0);
                }}
                className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
                  0 && 'selected'}`}
              >
                <div className="card-body center-all">
                  <img src={pixelLogo} alt="" width={230} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(1);
                  handleIntegration(1);
                  setValueCodeIntegration('');
                  setValueCodeIntegrationBody('');
                }}
                className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
                  1 && 'selected'}`}
              >
                <div className="card-body center-all">
                  <img src={analyticsLogo} alt="" width={150} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(2);
                  handleIntegration(2);
                  setValueCodeIntegration('');
                  setValueCodeIntegrationBody('');
                }}
                className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
                  2 && 'selected'}`}
              >
                <div className="card-body center-all">
                  <img src={tagManangerLogo} alt="" width={150} />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div
                onClick={() => {
                  setSelectedIntegration(3);
                  handleIntegration(false);
                  setValueCodeIntegration('');
                  setValueCodeIntegrationBody('');
                }}
                className={`card p-3 trigger border bg-white rounded ${selectedIntegration ===
                  3 && 'selected'}`}
              >
                <div className="card-body center-all">
                  <img src={cancelLogo} alt="" width={50} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!isSaved && (
        <>
          <br />
          <h4>
            Passo 2: Cole aqui o código que ficará na tag <strong>head</strong>
          </h4>
          <br />
          <div className="form-group">
            <textarea
              className="form-control"
              rows="10"
              value={valueCodeIntegration}
              onChange={e => {
                onChange(e);
                setValueCodeIntegration(e.target.value);
              }}
            ></textarea>
          </div>
          {selectedIntegration === 2 && (
            <>
              <br />
              <h4>
                Passo 3: Cole aqui o código que ficará após a tag{' '}
                <strong>body</strong>
              </h4>
              <br />
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="10"
                  value={valueCodeIntegrationBody}
                  onChange={e => {
                    onChangeSecondary(e);
                    setValueCodeIntegrationBody(e.target.value);
                  }}
                ></textarea>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
