import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'underscore';
import { withNamespaces } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { Notify } from '../../../components/Notify';
import ChangePassword from '../ChangePassword';
import { api } from '../../../Config';

class PlayerDetails extends Component {
  constructor(props) {
    super(props);

    // this.translate = props.t;

    this.state = {
      modalIsOpen: false,
      modalPasswordIsOpen: false,
      player: {},
      loadingPlayer: true,
      // loadingAction: true,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.player) {
      this.handlePlayerChange(nextProps.player);
    }

    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
  }

  handlePlayerChange(player) {
    this.setState({ player });
  }

  renderPlayerForm() {
    const { player } = this.state;
    return (
      <form className="col-md-12">
        <div className="row">
          <div className="form-group">
            <label className="profile-label">Nome</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={player.name}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">E-mail</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={player.email}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">CPF</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={player.cpf}
            />
          </div>
          <div className="form-group">
            <label className="profile-label">Cliente</label>
            <input
              className="form-control profile-placeholder"
              readOnly
              value={player.clientName}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '2% 0 2% 0',
            }}
          >
            {this.renderUpdatePasswordPlayer()}
            {this.renderRemovePlayer()}
          </div>
        </div>
      </form>
    );
  }

  deleteSelectedPlayer(playerId) {
    const { player } = this.state;

    this.setState({ creatingAction: true }, () => {
      Notify('Excluindo Player. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deletePlayer',
        userType: 'administrator',
        playerId,
        clientId: player.clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          Notify('Player excluido', 'success');
          this.props.filterPlayers();
          this.props.closeModal();
        })
        .catch((error) => {
          console.log('searchSeasons error:', error);
        });
    });
  }

  deletePlayer(playerId) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="oq-confirm-modal">
            <h1 className="oq-font-medium">
              Você tem certeza que deseja continuar?
            </h1>
            <p className="oq-font-book">
              Você tem certeza que deseja realmente excluir essa ação ?
            </p>
            <p>
              <b className="oq-font-medium">
                Essa exclusão não poderá ser desfeita após a confirmação.
              </b>
            </p>
            <div>
              <button
                className="btn btn-oq-black btn-oq-lg oq-margin-right"
                onClick={() => onClose()}
              >
                CANCELAR EXCLUSÃO
              </button>
              <button
                className="btn btn-oq btn-oq-lg"
                onClick={() => {
                  this.deleteSelectedPlayer(playerId);
                  onClose();
                }}
              >
                CONFIRMAR EXCLUSÃO
              </button>
            </div>
          </div>
        );
      },
    });
  }

  renderRemovePlayer() {
    const { player } = this.state;
    return (
      <div>
        <label
          className="btn btn-oq-back"
          onClick={() => this.deletePlayer(player.uid)}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; Excluir Player
        </label>
      </div>
    );
  }

  renderUpdatePasswordPlayer() {
    return (
      <div>
        <label
          className="btn btn-oq-back"
          onClick={() => this.openPasswordModal()}
        >
          <i className="fa fa-key" aria-hidden="true" />
          &nbsp; Alterar senha
        </label>
      </div>
    );
  }

  openPasswordModal() {
    this.props.closeModal();
    this.toggleModal();
  }

  toggleModal() {
    this.setState({ modalPasswordIsOpen: !this.state.modalPasswordIsOpen });
  }

  renderModal() {
    const { player } = this.state;
    return (
      <ChangePassword
        open={this.state.modalPasswordIsOpen}
        playerData={player}
        toggleModal={() => this.toggleModal()}
      />
    );
  }

  render() {
    return (
      <>
        <Modal
          open={this.state.modalIsOpen}
          onClose={() => this.props.closeModal()}
        >
          <div className="container-fluid">
            <div className="row">
              <span className="oq-card-title">
                <i className="fa fa-bullseye" />
                &nbsp; Detalhes do player
              </span>
              {this.renderPlayerForm()}
              {/* <div className="col-12 col-md-12">
                {this.renderUpdatePasswordPlayer()}
                {this.renderRemovePlayer()}
              </div> */}
            </div>
          </div>
        </Modal>
        {this.renderModal()}
      </>
    );
  }
}

export default withNamespaces()(PlayerDetails);
