import React from 'react';
import Modal from 'react-responsive-modal';

function ModalGamificationRule(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">ATENÇÃO</h2>
        <p>As regras de gamificação ainda não foram criadas.</p>
        <p>Faça a definição e começe o jogo!</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button
          className="oq-btn btn-confirm"
          onClick={() => {
            props.redirectUrlGamificationRule();
            props.close();
          }}
        >
          Definir Agora
        </button>
      </div>
    </Modal>
  );
}

export default ModalGamificationRule;
