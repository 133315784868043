import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';
import modalLogo from '../../Images/modal-logo.png';
import { api } from '../../../Config';

export default class EditPassword extends Component {
  constructor(props) {
    super(props);

    this.toastId = null;

    this.state = {
      email: '',
      modalIsOpen: false,
      loading: false,
      newPassword: '',
      confirmNewPassword: '',
      client: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
    if (nextProps.client) {
      this.setState({ client: nextProps.client });
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();
    const { newPassword } = this.state;
    const { confirmPassword } = this.state;
    if (newPassword === confirmPassword) {
      this.setState({ loading: true });

      const parameters = {
        updateFunctionality: 'changeClientPassword',
        userType: 'administrator',
        newPassword,
        clientId: this.state.client.uid,
        clientEmail: this.state.client.accountableEmail,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          const result = res.data;

          toast.success('Senha alterada com sucesso!', {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ stateLeadsData: result, loading: false });
          this.props.closeModal();
        })
        .catch((error) => {
          console.log('getLeadsPerStateGraphic error:', error);
          toast.error('Erro ao alterar senha.', {
            position: toast.POSITION.TOP_RIGHT,
          });
          this.setState({ loading: false });
        });
    } else {
      toast.warn('As senhas não conferem.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  passwordsAreEqual(newPassword, confirmPassword) {
    const isDiferentPasswords = newPassword !== confirmPassword;
    if (isDiferentPasswords) {
      return false;
    }
    return true;
  }

  renderFields() {
    return (
      <div>
        <div className="col-xs-12">
          <div className="col-md-12 form-group">
            <label className="profile-label">Nova Senha</label>
            <input
              className="form-control profile-placeholder"
              type="password"
              name="newPassword"
              required
              minLength={6}
              onChange={(e) => this.setState({ newPassword: e.target.value })}
            />
          </div>
          <div className="col-md-12 form-group">
            <label className="profile-label">Confirmar Nova Senha</label>
            <input
              className="form-control profile-placeholder"
              type="password"
              name="confirmPassword"
              required
              minLength={6}
              onChange={(e) =>
                this.setState({ confirmPassword: e.target.value })
              }
            />
          </div>
          <hr />
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    return (
      <Modal
        open={this.state.modalIsOpen}
        onClose={() => this.props.closeModal()}
        classNames={{ modal: 'custom-modal' }}
      >
        <div className="container-fluid modal-password">
          <div className="row">
            <div>
              <img src={modalLogo} width="40px" alt="logo client" /> &nbsp;
              <span className="oq-card-title">Alterar Senha</span>
              <br />
              <div className="edit-password-and-delete">
                <p>
                  Ao trocar a senha do cliente será executada uma ação que não
                  poderá ser desfeita. Informe abaixo a nova senha desejada.
                </p>
              </div>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              <div className="col-md-12">{this.renderFields()}</div>
              <div className="col-4">
                <button
                  type="submit"
                  className="btn btn-oq w-100"
                  disabled={this.state.loading}
                >
                  {loading ? 'SALVANDO NOVA SENHA' : 'SALVAR NOVA SENHA'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
