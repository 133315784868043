import * as firebase from 'firebase';

let config;

if (process.env.REACT_APP_VERSION === 'production') {
  config = {
    apiKey: 'AIzaSyAYIhRAV-sytvWujn_GG73qR2LFctsYnEI',
    authDomain: 'oq20-139d9.firebaseapp.com',
    databaseURL: 'https://oq20-139d9.firebaseio.com',
    projectId: 'oq20-139d9',
    storageBucket: 'oq20-139d9.appspot.com',
    messagingSenderId: '704244044101',
    appId: '1:704244044101:web:cb886ef81cdcd2e548ee2c',
  };
} else {
  config = {
    apiKey: 'AIzaSyATjqL1cAnThrLhQ6YAv3MUx8CWTiEvan4',
    authDomain: 'oqhomapi.firebaseapp.com',
    databaseURL: 'https://oqhomapi.firebaseio.com',
    projectId: 'oqhomapi',
    storageBucket: 'oqhomapi.appspot.com',
    messagingSenderId: '565668799670',
    appId: '1:565668799670:web:a36f4778fb16ba236a3217',
  };
}

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const storage = firebase.storage();

export { auth, storage };
