import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import Swal from 'sweetalert2';
import _ from 'lodash';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import 'react-tagsinput/react-tagsinput.css';
import { Notify } from '../../components/Notify';

import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class EditAdminPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
  }

  getInitialState() {
    const initialState = {
      formErrors: {},
      formValid: false,
      isLoading: true,
      formData: {
        username: '',
        password: '',
        confirmPassword: '',
        active: true,
      },
    };
    return initialState;
  }

  componentDidMount() {
    const { adminId } = this.props.match.params;

    const parameters = {
      searchFunctionality: 'searchAdmin',
      userType: 'administrator',
      adminId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        this.setState(
          {
            isLoading: false,
            adminId,
            formData: {
              ...this.state.formData,
              ...res.data.data,
            },
          },
          () => {
            Notify(res.data.message, res.data.success ? 'success' : 'error');
          }
        );
      })
      .catch((error) => {
        console.log('getClientByUid error:', error);
      });
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  getState(item) {
    return _.get(this.state, item, '');
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  renderForm() {
    return (
      <div className="col-md-10">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="username">
              Nome
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="username"
              name="username"
              aria-describedby="usernameHelp"
              value={this.getState('formData.username')}
              placeholder="Nome"
            />
            {this.getError('username') && (
              <small id="usernameHelp" className="form-text text-muted">
                {this.getError('username')}
              </small>
            )}
          </div>
        </div>
        {this.renderUpdateButton()}
      </div>
    );
  }

  renderUpdateButton() {
    return (
      <div className="d-flex justify-content-center">
        <div className="col-md-5 form-group">
          <button
            type="button"
            onClick={() => this.deleteAdm()}
            className="btn btn-oq-black btn-block btn-oq-lg"
          >
            EXCLUIR ADMINISTRADOR
          </button>
        </div>
        <div className="col-md-5 form-group">
          <button
            type="button"
            onClick={() => this.updateAdm()}
            className="btn btn-oq btn-block btn-oq-lg"
            disabled={!this.state.formData.username || this.state.loading}
          >
            ATUALIZAR ADMINISTRADOR
          </button>
        </div>
      </div>
    );
  }

  updateAdm() {
    const parameters = {
      updateFunctionality: 'updateAdmin',
      userType: 'administrator',
      admin: this.state.formData,
    };

    this.setState({ loading: true });
    api
      .post(`/update`, parameters)
      .then((res) => {
        Notify(res.data.message, res.data.success ? 'success' : 'error');
        this.setState({ loading: false });
      })
      .catch((error) => {
        console.log('updateAdmin error:', error);
        Notify(
          'Erro ao deletar administrador. Atualize a página e tente novamente.',
          'error'
        );
        this.setState({ loading: false });
      });
  }

  // TODO: Remove swal lib
  async deleteAdm() {
    const { value } = await Swal.fire({
      title: 'Tem certeza?',
      text: 'Você não poderá desfazer isso!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
    });
    if (value) {
      const parameters = {
        updateFunctionality: 'deleteAdmin',
        userType: 'administrator',
        adminId: this.state.adminId,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          Notify(res.data.message, res.data.success ? 'success' : 'error');

          if (res.data.success) {
            this.props.history.goBack();
          }
        })
        .catch((error) => {
          console.log('updateAdmin error:', error);
          Notify(
            'Erro ao atualizar. Atualize a página e tente novamente.',
            'error'
          );
        });
    }
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <SideBar />
        <div id="content">
          <NavBar />
          <div className="container-fluid">
            <div className="col-md-12">{this.renderBackButton()}</div>

            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <h1 className="oq-filter-title">
                    <i className="fa fa-user-plus" aria-hidden="true" />
                    <span>&nbsp; Novo Administrador</span>
                  </h1>
                </div>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditAdminPage;

export { EditAdminPage };
