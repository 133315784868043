import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import { Notify } from '../../components/Notify';
import UserDetails from '../Modals/UserDetails';
import * as mask from '../../utils/Mascaras';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class UsersPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      users: [],
      user: {},
      isLoading: false,
      modalIsOpen: false,
      searchObject: {
        userName: null,
        userEmail: null,
        userCpf: null,
        clientUrl: null,
      },
    };
    return initialState;
  }

  async getAllUsers() {
    const { searchObject } = this.state;

    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getAllUsers',
        userType: 'administrator',
        searchObject,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                users: [],
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
          } else {
            this.setState(
              {
                isLoading: false,
                users: result.users,
              },
              Notify(result.message, result.success ? 'success' : 'error')
            );
          }
        })
        .catch((error) => {
          console.log('getUsersClient error:', error);
          this.setState({
            isLoading: false,
            users: [],
          });
        });
    });
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  exportUsers() {
    const { users } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(users, (user) => {
        return {
          Nome: user.name && user.name,
          Cliente: user.companyName && user.companyName,
          'E-mail': user.email && user.email,
          CPF: user.cpf && user.cpf,
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Usuarios');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Usuarios.xlsx'
    );
  }

  handleSearchChange(name, value) {
    // const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  renderSearchButton() {
    return (
      <div>
        <div className="col-md-4 text-center p-2-top p-2-bottom">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.isLoading}
            onClick={() => this.getAllUsers()}
          >
            <i className="fa fa-search fa-padding" aria-hidden="true" />
            <span>{this.state.isLoading ? 'Pesquisando...' : 'Pesquisar'}</span>
          </button>
        </div>
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; Filtros Avançados
            </span>
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isLoading}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="userName"
                  placeholder="Nome"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="userCpf"
                  disabled={this.state.isLoading}
                  onChange={(event) => {
                    const input = event.target;
                    if (input.value.length <= 14) {
                      let maskCPF = mask.maskCPF(input.value);
                      this.handleSearchChange(input.name, maskCPF);
                    } else if (
                      input.value.length > 14 &&
                      input.value.length <= 18
                    ) {
                      let maskCNPJ = mask.maskCNPJ(input.value);

                      this.handleSearchChange(input.name, maskCNPJ);
                    } else {
                      let maskLimited = mask.maskLimited(input.value);

                      this.handleSearchChange(input.name, maskLimited);
                    }
                  }}
                  placeholder="CPF ou CNPJ"
                  value={this.state.searchObject.userCpf || ''}
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="userEmail"
                  disabled={this.state.isLoading}
                  className="form-control"
                  placeholder="E-mail"
                />
              </div>
              <div className="form-group col-md-4">
                <input
                  type="text"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="clientUrl"
                  disabled={this.state.isLoading}
                  className="form-control"
                  placeholder="URL do cliente sem '/_/'"
                />
              </div>
              <div className="form-group col-md-4"></div>
              {this.renderSearchButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUsersTable() {
    const { users } = this.state;

    const columns = [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Cliente',
        accessor: 'companyName',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        id: 'editUser',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <div
              className="btn btn-oq btn-sm btn-block"
              onClick={() => this.openModalUser(props.original)}
            >
              Detalhes
            </div>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-user" />
            &nbsp; Lista de Usuários
          </span>

          <button
            type="button"
            className="btn btn-oq pull-right"
            onClick={() => this.exportUsers()}
            disabled={_.size(users) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={_.values(users)}
              defaultPageSize={5}
              loading={this.state.isLoading}
              columns={columns}
              previousText="prev"
              nextText="next"
              noDataText="Nenhum usuário encontrado"
              pageText="page"
              ofText="of"
              rowsText="lines"
            />
          </div>
        </div>
      </div>
    );
  }

  openModalUser(user) {
    this.setState({ user }, () => this.toggleModal());
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <UserDetails
            closeModal={() => this.toggleModal()}
            user={this.state.user}
            modalIsOpen={this.state.modalIsOpen}
            getAllUsers={() => this.getAllUsers()}
          />
          {this.renderSearchFields()}
          <hr />
          <hr />
          {this.renderUsersTable()}
        </div>
      </div>
    );
  }
}

const UsersPageLink = () => (
  <Link className="menu-link" to={routes.USERS_ADMIN}>
    <FontAwesomeIcon icon={faUsers} />
    <span>Usuários</span>
  </Link>
);

export default UsersPage;

export { UsersPageLink };
