import React from 'react';
import Modal from 'react-responsive-modal';

function ModalConversionsExtraPoints(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.toggleModalConversion}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Quantos pontos você deseja atribuir ao player?
        </h2>
        <p className="content-alert">
          Essa ação não poderá ser desfeita após a confirmação.
        </p>{' '}
        <br />
        <div>
          {props.client.clientUrl === 'reisdopedaco' ? (
            <select name="type" onChange={(e) => props.handleType(e)}>
              <option>Selecione um tipo</option>
              <option value="extraPointsdigital">digital</option>
              <option value="extraPointsfisico">fisico</option>
              <option value="extraPointsbs2">bs2</option>
            </select>
          ) : (
            ''
          )}
          <div>
            <input
              type="number"
              min={1}
              name="extraPointsType"
              className="form-control profile-placeholder input-oq"
              placeholder="Quantos pontos você deseja atribuir ao player?"
              onChange={(e) => props.handleExtraPointsChange(e)}
            />
          </div>
        </div>
        {props.actionSelected.season &&
          props.actionSelected.season.secondRule === 'true' && (
            <div>
              <input
                type="number"
                min={1}
                name="secondaryRule"
                className="form-control profile-placeholder input-oq"
                placeholder="Qual o valor para a regra secundária?"
                onChange={(e) => props.handleExtraPointsChange(e)}
              />
            </div>
          )}
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button
          className="oq-btn"
          disabled={props.converting}
          onClick={props.toggleModalConversion}
        >
          Cancelar
        </button>
        <button
          className="oq-btn btn-confirm"
          disabled={props.converting}
          onClick={() => {
            if (props.client.clientUrl === 'reisdopedaco') {
              props.addExtraPointsType(props.playerId);
            } else {
              props.addExtraPoints(props.playerId);
            }
            props.toggleModalConversion();
          }}
        >
          Adicionar
        </button>
      </div>
    </Modal>
  );
}

export default ModalConversionsExtraPoints;
