import React from 'react';
import styles from './style.module.css';

export default function Card({ shadow, isLarge, children, ...rest }) {
  return (
    <>
      {shadow && isLarge && (
        <div
          {...rest}
          className={`${styles.card} ${styles.shadow} ${styles.large}`}
        >
          {children}
        </div>
      )}
      {!shadow && isLarge && (
        <div {...rest} className={`${styles.card} ${styles.large}`}>
          {children}
        </div>
      )}
      {shadow && !isLarge && (
        <div {...rest} className={`${styles.card} ${styles.shadow}`}>
          {children}
        </div>
      )}
      {!shadow && !isLarge && (
        <div {...rest} className={styles.card}>
          {children}
        </div>
      )}
    </>
  );
}
