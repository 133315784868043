import React, { useState } from 'react';
import './style.css'; // Importa o arquivo CSS

const CustomFileInput = ({ onFileChange }) => {
  const [fileName, setFileName] = useState('Nenhum arquivo selecionado');

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
    } else {
      setFileName('Nenhum arquivo selecionado');
    }

    // Chama a função passada via props com o e.target
    onFileChange(event.target);
  };

  return (
    <div>
      <label className="custom-file-label" htmlFor="file-input">
        SUBIR LOGO
      </label>
      <input
        type="file"
        id="file-input"
        className="custom-file-input"
        onChange={handleFileChange}
      />
      <span className="file-name">{fileName}</span>
    </div>
  );
};

export default CustomFileInput;
