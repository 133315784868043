import React, { Component } from 'react';
import Linegraphs from '../../../../../../components/Modals/Linegraphs';
import { LoadingDashboard } from '../../LoadingDashboard';
import { UserDataContext } from '../../../../../../hooks/useUserData';

class SharesCount extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
    };
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ modalIsOpen: !!nextProps.modalIsOpen });
  }

  render() {
    return (
      <>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            onClick={() => this.props.closeModal()}
          >
            <div className="col-md-12 col-xs-12 text-center">
              <span className="card-dashboard-title">Compartilhamentos</span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.props.dataAmount.loadingSharesCount ? (
                  <LoadingDashboard />
                ) : (
                  this.props.dataAmount.sharesCount
                )}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>

        <Linegraphs
          modalIsOpen={this.state.modalIsOpen}
          title={this.props.modalTitle}
          amountPerDay={this.props.dataAmount.amountPerDay}
          amountPerWeekDay={this.props.dataAmount.amountPerWeekDay}
          amountPerMonth={this.props.dataAmount.amountPerMonth}
          closeModal={() => this.props.closeModal()}
        />
      </>
    );
  }
}

export default SharesCount;
