import React from 'react';
import Card from '../Card';
import { useUserData } from '../../../../../../hooks/useUserData';

export default function GoalData({ goalData }) {
  const { clientData, userData, userAuth } = useUserData();

  const getMonth = new Date(
    clientData && clientData.scaleStartDateEpoch
  ).toLocaleString('pt-BR', { month: 'long' });
  const getYear = new Date(
    clientData && clientData.scaleStartDateEpoch
  ).getFullYear();

  return (
    <>
      <Card shadow>
        <>
          <p className="fw-semibold m-0">Meta</p>
          <p className="text-capitalize">
            {getMonth}, {getYear}
          </p>
          <div className="mt-3">
            <div className="row">
              <div className="col">
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className="circular-chart primary">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${goalData}, 100`}
                      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <text
                      x="50%"
                      y="17"
                      style={{
                        dominantBaseline: 'middle',
                        textAnchor: 'middle',
                      }}
                      className="percentage"
                    >
                      {Math.round(goalData)}
                    </text>
                    <text
                      y="22"
                      x="50%"
                      style={{
                        dominantBaseline: 'middle',
                        textAnchor: 'middle',
                      }}
                      className="percentage-symbol"
                    >
                      por cento
                    </text>
                  </svg>
                </div>
              </div>
              <div className="col">
                <div
                  style={{ flexDirection: 'column' }}
                  className="d-flex align-items-center mt-4"
                >
                  <p className="m-0 tiny-text">Meta do mês</p>
                  <p
                    style={{ fontSize: '28px', fontWeight: 'bold' }}
                    className="fs-1 m-0 color-text-primary"
                  >
                    {' '}
                    {clientData && clientData.scalePreset}
                  </p>
                  <p className="m-0 tiny-text">vendas</p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <p>{clientData && clientData.scaleDescription}</p>
          </div>
        </>
      </Card>
    </>
  );
}
