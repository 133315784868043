import React, { Component } from 'react';
import renderHTML from 'react-render-html';
import { ToastContainer } from 'react-toastify';
import { Notify } from '../../../../components/Notify';
import { api } from '../../../../Config';
import * as routes from '../../../../routes';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import './style.css';

import loadingImage from '../../../Images/loading.gif';
import { UserDataContext } from '../../../../hooks/useUserData';

class PreviewText extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      howItWorks: [],
      authUser: {},
      isLoading: true,
      htmlPreview: null,
      termsImage: null,
      clientText: '',
      questions: null,
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.setState({ clientText: params.clientText });
    this.getClientText(params.clientText);
  }

  addHowIfWorks() {
    const { howItWorks } = this.state;

    howItWorks.push({
      htmlString: '',
      lastUpdate: '',
      termsImage: '',
    });

    this.setState({
      howItWorks: howItWorks,
    });
  }

  getClientText(clientText) {
    const { userData } = this.context;
    const clientId = userData.clientId;

    const parameters = {
      searchFunctionality: 'getText',
      userType: 'client',
      clientId: clientId ? clientId : '',
      clientText,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          this.setState({
            howItWorks: result.data.howItWork,
            htmlPreview: null,
            text: null,
            isLoading: false,
            questions: null,
          });
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }
        this.setState({
          howItWorks: result.data.howItWorks,
          termsImage: result.data.termsImage,
          htmlPreview: result.data.text,
          isLoading: false,
          questions: result.data.questions ? result.data.questions : null,
        });
      })
      .catch((error) => {
        console.log('getClientText error:', error);
      });
  }

  goToEditPage(index) {
    const { history } = this.props;
    const { clientText } = this.state;

    if (clientText !== 'howItWorks') {
      history.push(`${routes.EDIT_TEXT}/${clientText}`);
    }

    if (clientText === 'howItWorks') {
      history.push(`${routes.EDIT_TEXT}/${clientText}/${index}`);
    }
  }

  renderLoading() {
    return (
      <img
        className="loading_image center-block"
        src={loadingImage}
        width="5%"
        alt="Loading"
      />
    );
  }
  renderNotFound() {
    return <h3>Texto não cadastrado ainda.</h3>;
  }

  renderEditButton(index) {
    const { howItWorks, isLoading, questions, htmlPreview } = this.state;
    return (
      <button
        className="btn btn-oq pull-right clickable oq-margin-bottom"
        disabled={isLoading}
        onClick={() => this.goToEditPage(index)}
      >
        <i className="fa fa-pencil-square-o" />{' '}
        {(howItWorks &&
          howItWorks.length > 0 &&
          howItWorks[index] &&
          ((howItWorks[index].termsImage && howItWorks[index].termsImage) ||
            (howItWorks[howItWorks.length - 1].htmlString &&
              howItWorks[howItWorks.length - 1].htmlString))) ||
        questions ||
        htmlPreview
          ? 'Editar'
          : 'Criar'}
      </button>
    );
  }

  renderPreviewHTML() {
    const {
      htmlPreview,
      isLoading,
      clientText,
      questions,
      indexPreview,
      howItWorks,
      termsImage,
    } = this.state;

    if (clientText === 'faq') {
      if (questions) {
        return (
          <div className="container" style={{ width: 'unset' }}>
            {this.renderEditButton()}
            <div className="row">
              <div className="col-md-12">
                {questions &&
                  questions.map((question, i) => {
                    return (
                      <>
                        <div className="group-question-title-prev">
                          <h2>{`${i + 1} - ${
                            question.question && question.question
                          }`}</h2>
                        </div>
                        <div className="group-question-content-prev">
                          {question.answer && renderHTML(question.answer)}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        );
      }
    }

    if (clientText === 'howItWorks') {
      return (
        howItWorks &&
        howItWorks.length > 0 &&
        howItWorks.map((howItWork) => {
          const index = howItWorks.indexOf(howItWork);

          return (
            <div
              className="container-fluid"
              style={{ marginBottom: '16px' }}
              key={howItWork}
            >
              <div className="row">
                <div className="col-md-12">
                  <div className="card-secondary">
                    <div className="card-secondary-container">
                      {this.renderEditButton(index)}
                      {this.renderImage(howItWork.termsImage)}
                      {isLoading
                        ? this.renderLoading()
                        : howItWork.htmlString
                          ? renderHTML(howItWork.htmlString)
                          : this.renderNotFound()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      );
    }

    return (
      <div className="container-fluid" style={{ marginBottom: '16px' }}>
        <div className="row">
          <div className="col-md-12">
            <div className="card-secondary">
              <div className="card-secondary-container">
                {this.renderEditButton()}
                {this.renderImage(termsImage)}
                {isLoading
                  ? this.renderLoading()
                  : htmlPreview
                    ? renderHTML(htmlPreview)
                    : this.renderNotFound()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  renderAddHowItWorkButton() {
    return (
      <>
        <button
          className="btn btn-oq pull-left"
          id="btn-add"
          onClick={() => this.addHowIfWorks()}
        >
          <i className="fa fa-plus icon-small" /> Adicionar
          {/* {htmlPreview || questions ? 'Editar' : 'Criar'} */}
        </button>
      </>
    );
  }

  renderImage(image) {
    const { termsImage } = this.state;
    if (image) {
      return (
        <div className="text-center">
          <img
            className="oq-margin-top oq-margin-bottom"
            src={image}
            style={{ maxWidth: '100%' }}
          />
        </div>
      );
    }
  }

  render() {
    const { clientText } = this.state;

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              <div
                className="faq-prev"
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {clientText &&
                  clientText === 'howItWorks' &&
                  this.renderAddHowItWorkButton()}
                {clientText === 'faq' ? (
                  <span className="oq-filter-title faq">
                    <i className="fa fa-eye" />
                    <span>&nbsp; FAQ Pré-Visualização </span>
                  </span>
                ) : (
                  <span className="oq-filter-title">
                    <i className="fa fa-eye" />
                    <span>&nbsp; Pré-Visualização</span>
                  </span>
                )}
              </div>
            </div>

            {this.renderPreviewHTML()}
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewText;
