import React from 'react';
import Modal from 'react-responsive-modal';

function ModalUpdateAuthorizedUsers(props) {
  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h2 className="title-alert title-normal">
          Você tem certeza que deseja continuar?
        </h2>
        <p>
          Ao adicionar uma nova lista de usuários autorizados, a lista anterios,
          se cadastrada, será substituida pela nova lista!
        </p>
        <p>Essa ação não poderá ser desfeita após a confirmação.</p>
        <br />
      </div>
      <div className="oq-group-horizontal">
        <button className="oq-btn" onClick={props.close}>
          Cancelar
        </button>
        <button
          className="oq-btn btn-confirm"
          onClick={() => {
            props.fileSelector.click();
            props.close();
          }}
        >
          Confirmar
        </button>
      </div>
    </Modal>
  );
}

export default ModalUpdateAuthorizedUsers;
