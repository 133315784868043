import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';

import { Notify } from '../../components/Notify';
import { NavBar } from '../NavBar';
import { SideBar } from '../SideBar';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class DetailsBankTransferRequest extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      loadingRescue: false,
    };
    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;
    var typeRescue = null;
    if (
      this.props.location.query &&
      this.props.location.query.typeRescue &&
      this.props.location.query.typeRescue === 'rescueLead'
    ) {
      typeRescue = this.props.location.query.typeRescue;
      this.setState({ typeRescue });
    }
    await this.getRescue(params.clientId, params.rescueId, typeRescue);
  }

  async getRescue(clientId, rescueId, typeRescue = null) {
    this.setState({ loadingRescue: true });

    let parameters = {
      searchFunctionality: 'getRescueById',
      userType: 'administrator',
      clientId,
      rescueId,
      typeRescue,
    };

    let res = await api.post(`/search`, parameters);

    const result = res.data;

    if (result.success === true) {
      this.setState(
        {
          rescue: result.data.rescue,
          player: result.data.player,
          client: result.data.clientSnap,
          loadingRescue: false,
        },
        () => {
          Notify('Pedido de transferência encontrado', 'success');
        }
      );
    } else {
      this.setState({ loadingRescue: false }, () => {
        Notify('Erro ao pesquisar pedido de transferência', 'error');
      });
    }
  }

  async handleFormSubmit(e, typePix, accountType) {
    e.preventDefault();

    const { rescue, client } = this.state;
    // const rescue = this.state.rescue;

    let updateFunctionality = '';

    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'ted'
    ) {
      if (!accountType) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'object' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.label &&
      rescue.accountData.paymentMethod.label.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }
    if (
      typeof rescue.accountData.paymentMethod === 'string' &&
      rescue.accountData.paymentMethod &&
      rescue.accountData.paymentMethod.toLowerCase() === 'pix'
    ) {
      if (!typePix) {
        return Notify('Atualize seus dados bancários', 'warn');
      }
    }

    if (
      client &&
      client.integration &&
      client.integration.transfeera &&
      client.integration.transfeera.active === 'true'
    ) {
      updateFunctionality = 'closeLoteIntegrationTransfeera';
    } else {
      updateFunctionality = 'closeLoteIntegrationBs2';
    }

    this.setState({ loadingCloseLote: true });

    Notify('Processando lote. Aguarde...', 'loading', {
      autoClose: true,
    });

    let parameters = {
      // updateFunctionality: 'closeLoteIntegrationTransfeera',
      // updateFunctionality: 'closeLoteIntegrationBs2',
      updateFunctionality,
      userType: 'administrator',
      clientId: rescue.clientId,
      rescueId: rescue.id,
      typeRescue: this.state.typeRescue,
    };

    let res = await api.post(`/update`, parameters);

    const result = res.data;

    if (result.success === true) {
      this.setState({ loadingCloseLote: false }, () => {
        Notify(result.message, 'success');
      });

      this.getRescue(rescue.clientId, rescue.id);
    } else {
      this.setState({ loadingCloseLote: false }, () => {
        Notify(result.message, 'error');
      });
    }
  }

  formatMoney(amount) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }
  renderPersonData(isPaymentMethodTed) {
    const { rescue, player } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Dados pessoais
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse in">
          {isPaymentMethodTed ? (
            <>
              <div className="row col-md-12">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('Nome do recebedor')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="name"
                    />
                    <ReactTooltip
                      id="name"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Nome do recebedor</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="name"
                    placeholder={this.translate('Nome do recebedor')}
                    value={
                      rescue && rescue.accountData && rescue.accountData.name
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('CPF/CNPJ')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="cpf"
                    />
                    <ReactTooltip
                      id="cpf"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>CPF/CNPJ</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="name"
                    placeholder={this.translate('CPF/CNPJ')}
                    value={
                      rescue && rescue.accountData && rescue.accountData.cpf
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('E-mail')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="email"
                    />
                    <ReactTooltip
                      id="email"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>E-mail</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="name"
                    placeholder={this.translate('E-mail')}
                    value={rescue && rescue.playerEmail}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row col-md-12">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('Nome do recebedor')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="name"
                  />
                  <ReactTooltip
                    id="name"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>Nome do recebedor</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder={this.translate('Nome do recebedor')}
                  value={player && player.name}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('CPF/CNPJ')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="cpf"
                  />
                  <ReactTooltip
                    id="cpf"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>CPF/CNPJ</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="cpf"
                  placeholder={this.translate('CPF/CNPJ')}
                  value={player && player.cpf}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('E-mail')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="email"
                  />
                  <ReactTooltip
                    id="email"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>E-mail</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="email"
                  placeholder={this.translate('E-mail')}
                  value={player && player.email}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
      </div>
    );
  }

  renderPaymentMethod(isPaymentMethodTed) {
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Método de pagamento
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse in">
          <div className="row col-md-12">
            {isPaymentMethodTed ? (
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={true}
                      name="ted"
                      checked
                    />
                    &nbsp;{this.translate('Transferência Bancária')}
                  </label>
                </div>
              </div>
            ) : (
              <div className="form-group col-md-4">
                <div className="profile-placeholder d-flex justify-content-between">
                  <label className="checkbox-inline">
                    <input
                      disabled
                      readOnly
                      type="radio"
                      value={true}
                      name="pix"
                      checked
                    />
                    &nbsp;{this.translate('Transferência PIX')}
                  </label>
                </div>
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderValueTransfer() {
    const { rescue } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase4"
          aria-expanded="false"
        >
          Valor a ser transferido
        </h4>
        <div id="phase4" aria-expanded="false" className="collapse in">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Valor a ser transferido')}
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="totalPaymentValue"
                />
                <ReactTooltip
                  id="totalPaymentValue"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Valor a ser transferido</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Valor a ser transferido')}
                value={rescue && this.formatMoney(rescue.totalPaymentValue)}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderStatusTransfer() {
    const { rescue } = this.state;
    const statusTransfer =
      rescue && rescue.statusTransfer ? rescue.statusTransfer : '';

    const statusTransferDescription =
      rescue && rescue.statusTransferDescription
        ? rescue.statusTransferDescription
        : '';

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase5"
          aria-expanded="false"
        >
          Status da Transferência
        </h4>
        <div id="phase5" aria-expanded="false" className="collapse in">
          <div className="row col-md-12">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Status da Transferência')}
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="statusTransfer"
                />
                <ReactTooltip
                  id="statusTransfer"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>Status da Transferência</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="name"
                placeholder={this.translate('Status')}
                value={statusTransfer}
              />
            </div>
            {statusTransferDescription && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('Descrição')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="statusTransferDescription"
                  />
                  <ReactTooltip
                    id="statusTransferDescription"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>Descrição</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="name"
                  placeholder={this.translate('Descrição')}
                  value={statusTransferDescription}
                />
              </div>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  }
  renderBankData(isPaymentMethodTed, accountType, typePix) {
    const { rescue, player } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Dados para Transferência
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse in">
          {isPaymentMethodTed ? (
            <>
              <div className="row col-md-12">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('Banco do recebedor')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="bank"
                    />
                    <ReactTooltip
                      id="bank"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Banco do recebedor</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="labelBank"
                    placeholder={this.translate('Banco do recebedor')}
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.labelBank &&
                      rescue.accountData.labelBank
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('Agência')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="agency"
                    />
                    <ReactTooltip
                      id="agency"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Agência</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="agency"
                    placeholder={this.translate('Agência')}
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.agency &&
                      rescue.accountData.agency
                    }
                  />
                </div>
                <div className="form-group col-md-4"></div>
              </div>

              <div className="row col-md-12">
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('Tipo de conta')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="typeAccount"
                    />
                    <ReactTooltip
                      id="typeAccount"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Tipo de conta</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="typeAccount"
                    placeholder={this.translate('Tipo de conta')}
                    value={
                      accountType && accountType.label ? accountType.label : ''
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate(
                      `${
                        accountType && accountType.label
                          ? accountType.label
                          : ''
                      }`
                    )}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="conta"
                    />
                    <ReactTooltip
                      id="conta"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>
                        {accountType && accountType.label
                          ? accountType.label
                          : ''}
                      </span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="conta"
                    placeholder={this.translate(
                      `${
                        accountType && accountType.label
                          ? accountType.label
                          : ''
                      }`
                    )}
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.conta &&
                      rescue.accountData.conta
                    }
                  />
                </div>
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="actionTitle">
                    {this.translate('Dígito')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="digitCount"
                    />
                    <ReactTooltip
                      id="digitCount"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline={true}
                    >
                      <span>Dígito</span>
                    </ReactTooltip>
                  </label>
                  <input
                    disabled
                    readOnly
                    className="form-control profile-placeholder"
                    name="digitCount"
                    placeholder={this.translate('Dígito')}
                    value={
                      rescue &&
                      rescue.accountData &&
                      rescue.accountData.digitCount &&
                      rescue.accountData.digitCount
                    }
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row col-md-12">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('Tipo de chave pix')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="typePix"
                  />
                  <ReactTooltip
                    id="typePix"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>Tipo de chave pix</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="typePix"
                  placeholder={this.translate('Tipo de chave pix')}
                  value={typePix && typePix.label}
                />
              </div>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="actionTitle">
                  {this.translate('Chave pix')}
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="pixkey"
                  />
                  <ReactTooltip
                    id="pixkey"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline={true}
                  >
                    <span>Chave pix</span>
                  </ReactTooltip>
                </label>
                <input
                  disabled
                  readOnly
                  className="form-control profile-placeholder"
                  name="pixkey"
                  placeholder={this.translate('Chave pix')}
                  value={
                    rescue &&
                    rescue.accountData &&
                    rescue.accountData.pixkey &&
                    rescue.accountData.pixkey
                  }
                />
              </div>
              <div className="form-group col-md-4"></div>
            </div>
          )}
        </div>
        <hr />
      </div>
    );
  }
  renderForm() {
    const { rescue, client } = this.state;

    let isPaymentMethodTed = true;
    let accountType = {
      label: '',
      value: '',
    };
    let typePix = {
      label: '',
      value: '',
    };

    if (rescue) {
      var isCloseProccess = rescue.closeProccess ? rescue.closeProccess : false;

      var statusTransfer = rescue.statusTransfer ? rescue.statusTransfer : '';

      if (
        client &&
        client.integration &&
        client.integration.transfeera &&
        client.integration.transfeera.active === 'true'
      ) {
        const arrTypeAccountTransfeera = [
          {
            label: 'Conta Corrente',
            value: 'CONTA_CORRENTE',
          },
          {
            label: 'Conta Poupança',
            value: 'CONTA_POUPANCA',
          },
          {
            label: 'Conta Fácil',
            value: 'CONTA_FACIL',
          },
          {
            label: 'Entidades Públicas',
            value: 'ENTIDADES_PUBLICAS',
          },
        ];

        accountType = arrTypeAccountTransfeera.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        // CPF / CNPJ / TELEFONE / EMAIL / CHAVE_ALEATORIA
        const arrTypePixTransfeera = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'TELEFONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'CHAVE_ALEATORIA',
          },
        ];

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePixTransfeera.find(
            (type) => type.value === rescue.accountData.typePix
          );
        }
      } else {
        const arrTypeAccountBs2 = [
          {
            label: 'Conta Corrente',
            value: '2',
          },
          {
            label: 'Conta Poupança',
            value: '3',
          },
          {
            label: 'Conta de Pagamento',
            value: '1',
          },
        ];

        accountType = arrTypeAccountBs2.find((bankAccount) => {
          if (rescue) {
            return bankAccount.value === rescue.accountData.accountType;
          }
        });

        // CPF / CNPJ / PHONE / EMAIL / EVP
        const arrTypePixBs2 = [
          {
            label: 'CPF',
            value: 'CPF',
          },
          {
            label: 'CNPJ',
            value: 'CNPJ',
          },
          {
            label: 'Telefone',
            value: 'PHONE',
          },
          {
            label: 'E-mail',
            value: 'EMAIL',
          },
          {
            label: 'Chave aleatória',
            value: 'EVP',
          },
        ];

        if (
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod.label === 'pix' &&
            rescue.accountData.paymentMethod.value) ||
          (rescue &&
            rescue.accountData &&
            rescue.accountData.paymentMethod &&
            rescue.accountData.paymentMethod === 'pix')
        ) {
          isPaymentMethodTed = false;
          typePix = arrTypePixBs2.find(
            (type) => type.value === rescue.accountData.typePix
          );
        }
      }
    }

    return (
      <div className="container-fluid ">
        {this.renderPersonData(isPaymentMethodTed)}
        {this.renderBankData(isPaymentMethodTed, accountType, typePix)}
        {this.renderPaymentMethod()}
        {this.renderValueTransfer()}
        {statusTransfer && this.renderStatusTransfer()}

        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              // type="submit"
              type="button"
              onClick={(e) => this.handleFormSubmit(e, typePix, accountType)}
              disabled={this.state.loadingCloseLote || isCloseProccess}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Processar transferência')}
            </button>
          </div>
        </div>
        {/* {this.renderRemoveGroup()} */}
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Detalhes')}
                </span>

                {/* <span className="filds-mandatory">Campos obrigatórios</span> */}
              </h1>
            </div>
            {/* <form onSubmit={e => this.handleFormSubmit(e)}> */}
            <div>{this.renderForm()}</div>
            {/* </form> */}
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsBankTransferRequest;
