import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as Halogenium from 'halogenium';

import * as routes from '../../../routes';
import { auth } from '../../../firebase';

import logoImg from '../../Images/logo.png';
import logoBlueImg from '../../Images/logo_blue.png';
import Bg from '../../Images/bg-pass-blue.png';

import './style.css';

const PasswordForgetClientPage = () => <PasswordForgetForm />;

const INITIAL_STATE = {
  email: '',
  error: null,
  result: null,
  processing: false,
};

class PasswordForgetForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email } = this.state;
    this.setState({ processing: true });
    auth
      .doPasswordReset(email)
      .then(() => {
        this.setState({
          processing: false,
          result:
            'Enviamos um email com as instruções para recuperar a sua senha.',
        });
      })
      .catch((error) => {
        this.setState({ processing: false, error });
      });

    event.preventDefault();
  };

  render() {
    const { email, error, result } = this.state;

    const isInvalid = email === '';

    return (
      <>
        <div className="pass-wrapper" style={{ backgroundImage: `url(${Bg})` }}>
          <div className="box-form-pass">
            <div className="box-form-title">
              <img src={logoBlueImg} alt="" />
              <h1>Recuperar senha</h1>
            </div>
            <div className="box-form-subtitle">
              <p>
                Por favor, Informe o e-mail associado a sua conta e nós
                enviaremos um link para recuperação/definição de uma nova senha.
              </p>
            </div>
            {result && (
              <div className="alert alert-success text-center">
                <b>Feito ! </b>
                {result} Se o email não chegou, verifique sua caixa de spam! ;)
              </div>
            )}
            {error && (
              <div className="alert alert-danger text-center">
                <b>Erro ! </b>
                {error.message}
              </div>
            )}
            <form className="form-forgotPassword" onSubmit={this.onSubmit}>
              <input
                className="form-pass-input"
                value={this.state.email}
                id="inputEmail"
                onChange={(event) =>
                  this.setState({
                    email: event.target.value,
                  })
                }
                type="email"
                placeholder="Email"
                required="required"
              />
              <br />
              <button
                disabled={isInvalid}
                type="submit"
                className="form-pass-btn"
              >
                Recuperar a senha
              </button>
            </form>
          </div>
          <br />
          <Link to={routes.SIGIN_CLIENT}>
            <i className="fas fa-arrow-left backIcon" />
            &nbsp; Voltar para o login
          </Link>
        </div>
      </>
    );
  }
}

const PasswordForgetLink = () => (
  <div className="row marginTopLogin">
    <div className="col-md-12 col-xs-12 text-center">
      <p>
        <Link id="password-forget-text" to={routes.PASSWORD_FORGET_CLIENT}>
          Esqueceu a senha?
        </Link>
      </p>
    </div>
  </div>
);

export default PasswordForgetClientPage;

export { PasswordForgetForm, PasswordForgetLink };
