import React, { PureComponent } from 'react';
import $ from 'jquery';
import { withNamespaces } from 'react-i18next';

import { css } from 'glamor';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Color from 'color';

import * as routes from '../../../routes';
import { SignOut } from '../../pages/SignOut';

import * as GoogleIcons from 'react-icons/md';
import Collapsible from './Collapsible';
import SideBarItem from './SideBarItem';
import LogoOQ from '../../Images/oq-logo-mono.png';
import SideBarLogo from '../../Images/sideLogo.png';

import { UserDataContext } from '../../../hooks/useUserData';

class SideBar extends PureComponent {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const inititalState = {
      companyName: '',
      companyEmail: '',
      currentPage: '',
      loadingLogo: true,
      // logoURL: SideBarLogo,
      logoURL: '',
      sidebarStatus: false,
      primaryColor: '#000000',
      secondaryColor: '#fff',
      systemFont: 'Gotham Rounded',
      isGamified: false,
      gamificationType: 'points',
    };

    return inititalState;
  }

  componentDidMount() {
    this.getClient();

    let pathname = window.location.pathname;
    pathname = pathname.replace('/', '/');

    const sidebarStatus = localStorage.getItem('sideBarStatus') === 'true';
    this.setState({ sidebarStatus });

    $('#sidebarCollapse').on('click', () => {
      this.toggleSidebar();
    });

    this.setState({ currentPage: pathname });

    // Cria um elemento script
    const script = document.createElement('script');

    // Define o ID do script
    script.id = 'ze-snippet';

    // Define a URL do script
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=27820121-ceb9-47b8-94fb-e10e4d9403a3';

    // Define o atributo async
    script.async = true;

    // Adiciona o script ao body
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    // Remove o script ao desmontar o componente
    if (window.location.pathname.match(/signin/g)) {
      const script = document.getElementById('ze-snippet');
      const launcher = document.getElementById('launcher');
      if (script) {
        document.body.removeChild(script);
      }
      if (launcher) {
        launcher.remove();
      }
    }
  }

  toggleSidebar() {
    const newSidebarState = !this.state.sidebarStatus;
    localStorage.setItem('sideBarStatus', newSidebarState);
    this.setState({ sidebarStatus: newSidebarState });
  }

  getClient() {
    const { clientData } = this.context;
    this.setState((prevState) => ({
      primaryColor: clientData.primaryColor
        ? clientData.primaryColor
        : prevState.primaryColor,
      secondaryColor: clientData.secondaryColor
        ? clientData.secondaryColor
        : prevState.secondaryColor,
      loadingLogo: false,
      favIcon: clientData.favIcon
        ? clientData.favIcon
        : clientData.logoURL
          ? clientData.logoURL
          : SideBarLogo,
      logoURL: clientData.logoURL ? clientData.logoURL : '',
      systemFont: clientData.systemFont
        ? clientData.systemFont
        : prevState.systemFont,
      isTeamCompetition: clientData.isTeamCompetition
        ? clientData.isTeamCompetition
        : '',
      isGamified: clientData.isGamified ? clientData.isGamified : false,
      gamificationType: clientData.gamificationType
        ? clientData.gamificationType
        : 'points',
      companyName: clientData.companyName,
      companyEmail: clientData.contactEmail,
    }));

    localStorage.setItem('primaryColor', clientData.primaryColor);
    localStorage.setItem('secondaryColor', clientData.secondaryColor);
  }

  getIsActive(route) {
    const { currentPage } = this.state;
    return String(currentPage).includes(route);
  }

  getSidebarListCSS() {
    const { primaryColor, secondaryColor } = this.state;
    const rule = css({
      ':hover': {
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
      '.active': {
        borderRight: `10px solid ${primaryColor} !important`,
        background: `${secondaryColor} !important`,
        color: `${primaryColor} !important`,
      },
    });

    return rule;
  }

  shortString(str, lengthStr) {
    // correção de verificação
    if (str && str.length > lengthStr) {
      return str.substr(0, lengthStr) + '...';
    }
    return str;
  }

  render() {
    const { clientData, userAuth } = this.context;

    const { currentPage, favIcon, systemFont, primaryColor, secondaryColor } =
      this.state;

    const crmIsOpened = !!(
      this.getIsActive(routes.NEW_BANNER) ||
      this.getIsActive(routes.BANNERS) ||
      this.getIsActive(routes.NEW_MESSAGE) ||
      this.getIsActive(routes.MESSAGES) ||
      this.getIsActive(routes.EMAILS) ||
      this.getIsActive(routes.NEW_EMAIL) ||
      this.getIsActive(routes.WHATSAPP_BUSINESS)
    );

    const playerOpened = !!(
      this.getIsActive(routes.PLAYERS) ||
      this.getIsActive(routes.EDIT_PLAYER) ||
      this.getIsActive(routes.INFLUENCERS)
    );

    const challengesIsOpened = !!(
      this.getIsActive(routes.ACTIONS_BUSINESS) ||
      this.getIsActive(routes.ACTIONS_VIRTUALS) ||
      this.getIsActive(routes.EDIT_ACTION_VIRTUAL) ||
      this.getIsActive(routes.CREATE_ACTION_VIRTUAL) ||
      this.getIsActive(routes.ACTIONS_OMNICHANNELS) ||
      this.getIsActive(routes.EDIT_ACTION_OMNICHANNEL) ||
      this.getIsActive(routes.CREATE_ACTION_OMNICHANNEL) ||
      this.getIsActive(routes.CREATE_ACTION_QUIZ) ||
      this.getIsActive(routes.EDIT_ACTION_QUIZ) ||
      this.getIsActive(routes.CREATE_ACTION_INFORMATIONAL) ||
      this.getIsActive(routes.EDIT_ACTION_INFORMATIONAL) ||
      this.getIsActive(routes.ACTIONS_INFORMATIONALS)
    );

    const premiumsIsOpened = !!(
      this.getIsActive(routes.PREMIUMS) ||
      this.getIsActive(routes.NEW_PREMIUM) ||
      this.getIsActive(routes.EDIT_PREMIUM) ||
      this.getIsActive(routes.RESCUES) ||
      this.getIsActive(routes.PURCHASE_UPLOADS)
    );

    const dashboardIsOpened = !!(
      this.getIsActive(routes.DASHBOARD_CLIENT) ||
      this.getIsActive(routes.DASHBOARD_QUIZ_CLIENT) ||
      this.getIsActive(routes.DASHBOARD_RESEARCH_AWARD)
    );

    const { isGamified, gamificationType } = this.state;

    const clientsLandingPermissions = [
      'meuvivaz',
      'vivazvendas',
      'rev3',
      'crescer',
      'indiqueeganhe',
    ];

    return (
      <>
        <Helmet>
          <style type="text/css">
            {`
              :root{
                --primary-color: ${primaryColor};
                --primary-color-dark: ${Color(primaryColor).darken(0.45)};
                --secondary-color: ${secondaryColor};
                --oq-font-regular: '${systemFont} Regular';
                --oq-font-bold: '${systemFont} Bold';
                --oq-font-book: '${systemFont} Book';
                --oq-font-medium: '${systemFont} Medium';
                --oq-font-light: '${systemFont} Light';
              }
          `}
          </style>
          <link rel="shortcut icon" href={favIcon} />
        </Helmet>
        <div className="oq-sidebar-area oq-z-depth-1">
          <div className="oq-sidebar-business-info">
            <img src={favIcon} alt="" />
            <div className="content">
              <span id="company-name">
                {this.shortString(this.state.companyName, 18)}
              </span>
              <span id="company-email">
                {this.shortString(userAuth.email, 25)}
              </span>
              <SignOut />
            </div>
          </div>
          <ul className="oq-sidebar-menu">
            <SideBarItem
              isActive={dashboardIsOpened ? 'active' : ''}
              isVisible={true}
              link={routes.DASHBOARD_CLIENT}
              icon={<GoogleIcons.MdDashboard />}
              label="Dashboard"
            />
            <SideBarItem
              isActive={currentPage === routes.ACCESS_GROUPS ? 'active' : ''}
              isVisible={true}
              link={routes.ACCESS_GROUPS}
              icon={<GoogleIcons.MdGroupWork />}
              label="Grupos de acesso"
            />
            <SideBarItem
              isActive={currentPage === routes.USERS ? 'active' : ''}
              isVisible={true}
              link={routes.USERS}
              icon={<GoogleIcons.MdGroup />}
              label="Administradores"
            />

            <SideBarItem
              isActive={currentPage === routes.PROFILE_CLIENT ? 'active' : ''}
              isVisible={true}
              link={routes.PROFILE_CLIENT}
              icon={<GoogleIcons.MdBusiness />}
              label="Empresa"
            />
            <SideBarItem
              isActive={currentPage === routes.PROFILE_USER ? 'active' : ''}
              isVisible={true}
              link={routes.PROFILE_USER}
              icon={<GoogleIcons.MdAccountCircle />}
              label="Meu Perfil"
            />
            {clientData &&
              clientsLandingPermissions.includes(clientData.clientUrl) && (
                <SideBarItem
                  isActive={
                    currentPage === routes.LANDING_PAGE ||
                    currentPage === routes.LANDING_PAGE_EDITOR
                      ? 'active'
                      : ''
                  }
                  isVisible={true}
                  link={routes.LANDING_PAGE}
                  icon={<GoogleIcons.MdWeb />}
                  label="LP/KV"
                />
              )}
            <SideBarItem
              isActive={
                currentPage === routes.GAMIFICATION_CLIENT ? 'active' : ''
              }
              isVisible={true}
              link={routes.GAMIFICATION_CLIENT}
              icon={<GoogleIcons.MdGrade />}
              label="Gamification"
            />
            <Collapsible
              isVisible={
                isGamified &&
                gamificationType &&
                gamificationType !== 'goalsLevels'
              }
              isActive={premiumsIsOpened ? 'active' : ''}
              label="Resgate de prêmios"
              icon={<GoogleIcons.MdCardGiftcard />}
            >
              <ul>
                <SideBarItem
                  isActive={
                    this.getIsActive(routes.PREMIUMS) ||
                    this.getIsActive(routes.NEW_PREMIUM) ||
                    this.getIsActive(routes.EDIT_PREMIUM)
                      ? 'active'
                      : ''
                  }
                  isVisible={
                    isGamified &&
                    gamificationType &&
                    isGamified === 'false' &&
                    gamificationType !== 'goalsLevels'
                  }
                  link={routes.PREMIUMS}
                  label="Prêmios"
                />
                <SideBarItem
                  isActive={this.getIsActive(routes.RESCUES) ? 'active' : ''}
                  isVisible={true}
                  link={routes.RESCUES}
                  label="Resgates"
                />
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={
                isGamified &&
                gamificationType &&
                isGamified === 'true' &&
                gamificationType !== 'goalsLevels'
              }
              isActive={this.getIsActive(routes.SEASONS) ? 'active' : ''}
              link={routes.SEASONS}
              icon={<GoogleIcons.MdDonutLarge />}
              label="Temporadas"
            />
            <Collapsible
              isVisible={true}
              isActive={challengesIsOpened ? 'active' : ''}
              label="Publicações"
              icon={<GoogleIcons.MdFlag />}
            >
              <ul>
                {clientData && clientData.clientUrl === 'meuvivaz' && (
                  <SideBarItem
                    isVisible={
                      gamificationType && gamificationType !== 'goalsLevels'
                    }
                    isActive={
                      this.getIsActive(routes.ACTIONS_BUSINESS_HOME)
                        ? 'active'
                        : ''
                    }
                    link={routes.ACTIONS_BUSINESS_HOME}
                    label="Incorporadoras"
                  />
                )}
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_VIRTUALS) ||
                    this.getIsActive(routes.EDIT_ACTION_VIRTUAL) ||
                    this.getIsActive(routes.CREATE_ACTION_VIRTUAL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_VIRTUALS}
                  label="Virtuais"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_OMNICHANNELS) ||
                    this.getIsActive(routes.EDIT_ACTION_OMNICHANNEL) ||
                    this.getIsActive(routes.CREATE_ACTION_OMNICHANNEL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_OMNICHANNELS}
                  label="Presenciais"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.EDIT_ACTION_QUIZ) ||
                    this.getIsActive(routes.CREATE_ACTION_QUIZ)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_QUIZ}
                  label="Quiz"
                />
                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_INFORMATIONALS) ||
                    this.getIsActive(routes.CREATE_ACTION_INFORMATIONAL)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_INFORMATIONALS}
                  label="Venda direta"
                />

                <SideBarItem
                  isVisible={
                    gamificationType && gamificationType !== 'goalsLevels'
                  }
                  isActive={
                    this.getIsActive(routes.RESEARCHAWARD_CLIENT) ||
                    this.getIsActive(routes.CREATE_RESEARCHAWARD_CLIENT)
                      ? 'active'
                      : ''
                  }
                  link={routes.RESEARCHAWARD_CLIENT}
                  label="Pesquisas/NPS"
                />
                <SideBarItem
                  isVisible={
                    gamificationType &&
                    gamificationType !== 'goalsLevels' &&
                    this.state &&
                    this.state.isTeamCompetition === 'true' &&
                    this.state.isTeamCompetition !== undefined
                  }
                  isActive={
                    this.getIsActive(routes.ACTIONS_INVITES) ||
                    this.getIsActive(routes.ACTIONS_INVITES)
                      ? 'active'
                      : ''
                  }
                  link={routes.ACTIONS_INVITES}
                  label="Convite"
                />
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={this.getIsActive(routes.CONVERSIONS) ? 'active' : ''}
              icon={<GoogleIcons.MdExposurePlus1 />}
              link={routes.CONVERSIONS}
              label="Atribuição de pontos"
            />
            <Collapsible
              isVisible={true}
              isActive={crmIsOpened ? 'active' : ''}
              label="Comunicação"
              icon={<GoogleIcons.MdAddAlert />}
            >
              <ul>
                <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.MESSAGES) ||
                    this.getIsActive(routes.NEW_MESSAGE)
                      ? 'active'
                      : ''
                  }
                  link={routes.MESSAGES}
                  label="Notificações"
                />
                <SideBarItem
                  isVisible={clientData.clientUrl !== 'perplan'}
                  isActive={
                    this.getIsActive(routes.EMAILS) ||
                    this.getIsActive(routes.NEW_EMAIL)
                      ? 'active'
                      : ''
                  }
                  link={routes.EMAILS}
                  label="E-mails"
                />

                <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.BANNERS) ||
                    this.getIsActive(routes.NEW_BANNER)
                      ? 'active'
                      : ''
                  }
                  link={routes.BANNERS}
                  label="Banners"
                />
                {/* <SideBarItem
                  isVisible={true}
                  isActive={
                    this.getIsActive(routes.WHATSAPP_BUSINESS) ? 'active' : ''
                  }
                  link={routes.WHATSAPP_BUSINESS}
                  label="WhatsApp Business"
                /> */}
              </ul>
            </Collapsible>
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.BENEFITS_CLIENT ? 'active' : ''}
              icon={<GoogleIcons.MdLocalPlay />}
              link={routes.BENEFITS_CLIENT}
              label="Benefícios"
            />
            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={currentPage === routes.PUBLIC_TARGET ? 'active' : ''}
              icon={<GoogleIcons.MdSync />}
              link={routes.PUBLIC_TARGET}
              label="Leads/Conversões"
            />

            {this.state &&
            this.state.isTeamCompetition &&
            this.state.isTeamCompetition === 'true' ? (
              <Collapsible
                isVisible={
                  this.state &&
                  this.state.isTeamCompetition &&
                  this.state.isTeamCompetition === 'true'
                }
                isActive={playerOpened ? 'active' : ''}
                label="Usuários"
                icon={<GoogleIcons.MdList />}
              >
                <ul>
                  <SideBarItem
                    isVisible={true}
                    isActive={
                      this.getIsActive(routes.PLAYERS) ||
                      this.getIsActive(routes.EDIT_PLAYER)
                        ? 'active'
                        : ''
                    }
                    link={routes.PLAYERS}
                    label="Membros das Equipes"
                  />
                  <SideBarItem
                    isVisible={true}
                    isActive={
                      this.getIsActive(routes.INFLUENCERS) ? 'active' : ''
                    }
                    link={routes.INFLUENCERS}
                    label="Líderes de Equipe"
                  />
                </ul>
              </Collapsible>
            ) : (
              <SideBarItem
                isVisible={true}
                isActive={
                  this.getIsActive(routes.PLAYERS) ||
                  this.getIsActive(routes.EDIT_PLAYER)
                    ? 'active'
                    : ''
                }
                link={routes.PLAYERS}
                label="Usuários"
                icon={<GoogleIcons.MdList />}
              />
            )}

            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.WALLET_CLIENT ? 'active' : ''}
              link={routes.WALLET_CLIENT}
              label="Financeiro"
              icon={<GoogleIcons.MdAccountBalanceWallet />}
            />

            <SideBarItem
              isVisible={gamificationType && gamificationType !== 'goalsLevels'}
              isActive={
                currentPage === routes.HISTORICAL_POINTS_CLIENT ? 'active' : ''
              }
              icon={<GoogleIcons.MdSync />}
              link={routes.HISTORICAL_POINTS_CLIENT}
              label="Histórico de pontos"
            />

            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.CONFIG ? 'active' : ''}
              link={routes.CONFIG}
              label="Configurações"
              icon={<GoogleIcons.MdSettings />}
            />
            <SideBarItem
              isVisible={true}
              isActive={
                this.getIsActive(routes.TERMS_CLIENT) ||
                this.getIsActive(routes.PREVIEW_TEXT) ||
                this.getIsActive(routes.EDIT_TEXT)
                  ? 'active'
                  : ''
              }
              link={routes.TERMS_CLIENT}
              label="Condições gerais"
              icon={<GoogleIcons.MdGavel />}
            />
            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.EMAIL_TEMPLATES ? 'active' : ''}
              link={routes.EMAIL_TEMPLATES}
              label="E-mail de boas vindas"
              icon={<GoogleIcons.MdEmail />}
            />
            <SideBarItem
              isVisible={true}
              isActive={currentPage === routes.TUTORIALS ? 'active' : ''}
              link={routes.TUTORIALS}
              label="Tutoriais"
              icon={<GoogleIcons.MdInfo />}
            />
            <li>
              <a
                // href={`mailto:suporte@oqdigital8477.zendesk.com?subject=Solicitar Suporte e abertura de ticket -  ${this.context.clientData.clientUrl}&body=Descreva aqui sua solicitação`}
                href={`mailto:suporte@oqdigital8477.zendesk.com?subject=Solicitar Suporte e abertura de ticket -  ${this.context.clientData.clientUrl}&body=Descreva%20aqui%20sua%20solicitação:%0D%0A%0D%0ADetalhe%20aqui%20abaixo%20a%20sua%20solicitação%20ou%20problema%20apresentado:%0D%0A%0D%0AColoque%20abaixo%20prints%20da%20tela%20inteira%20com%20o%20bug%20ou%20erro%20apresentado:%0D%0A%0D%0AInforme%20email%20do%20lead%20ou%20usuário%20que%20está%20tendo%20o%20problema:%0D%0A%0D%0AColoque%20abaixo%20informações%20complementares:`}
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <GoogleIcons.Md18UpRating />
                </i>
                <span>Solicitar suporte</span>
              </a>
            </li>
            <li>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPoli%CC%81tica%20de%20Privacidade_OQ%20MIDIA%20DIGITAL%20JULHO%202020.docx.pdf?alt=media&token=73c99224-963a-40c8-b385-618bb2cc5122"
                target="_blank"
                rel="noreferrer"
              >
                <i>
                  <GoogleIcons.MdHttps />
                </i>
                <span>Política de privacidade</span>
              </a>
            </li>
            {/* <li>
              <a
                href="https://oqdigital.atlassian.net/servicedesk/customer/portal/1"
                target="_blank"
              >
                <i>
                  <GoogleIcons.MdSmsFailed />
                </i>
                <span>Suporte OQ Digital</span>
              </a>
            </li> */}
            <li className="oq-sidebar-footer">
              <div>
                <img src={LogoOQ} />
              </div>
              <span>Todos os direitos reservados</span>
              <a href="https://oqdigital.com/" target="_blank" rel="noreferrer">
                oqdigital.com
              </a>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

SideBar.contextTypes = {
  authUser: PropTypes.object,
  clientData: PropTypes.object,
  userData: PropTypes.object,
};

export default withNamespaces()(SideBar);

export { SideBar };
