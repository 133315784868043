import React, { Component } from 'react';

import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { api } from '../../../Config';

import { Notify } from '../../../components/Notify';
import * as mask from '../../../utils/Mascaras';
import ModalTerms from '../../components/Modals/ModalTerms';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../utils/normalizeLowerCase';

import './style.css';

class LeadRegister extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      formButtonPressed: false,
      dataUserUncompleted: false,
      pageInit: 0,
      formErrors: {},
      formValid: false,
      currentPage: 0,
      defaultOptionsSelectPlayers: [],
      formData: {
        name: '',
        phone: '',
        email: '',
        cpf: '',
        state: '',
        city: '',
        leadToken: '',
        converted: false,
        isVerified: false,
        actionId: '',
        playerId: '',
        acceptedTerms: false,
        acceptedTermsEmcamp: false,
        formCompleted: false,
        urlOrigem: window.location.href,
        streetCep: '',
        neighborhoodCep: '',
        cityCep: '',
        stateCep: '',
        inputCep: '',
        playerSelected: null,
      },
      loadingStateList: false,
      loadingCityList: false,
      cityList: [],
      stateList: [],
      players: [],
      actionsPlayer: [],
      savingLead: false,
      validatingLead: false,
      searchingPlayers: false,
      searchingActionsPlayer: false,
      // correctors: [],
    };

    return initialState;
  }

  componentDidMount() {
    this.getStateList();
    this.getPlayers();
  }

  getValidationCode(playerId, actionId) {
    let { clientData } = this.context;
    let parameters = {
      actionId,
      playerId,
      clientId: clientData.uid,
      userType: 'client',
      searchFunctionality: 'validationCode',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        let result = res.data;

        if (result.data[0]) {
          this.setState({ validationCode: result.data[0].validationCode });
        }
      })
      .catch((error) => {
        console.log('Falha validationCode:', error);
      });
  }

  getPlayers() {
    this.setState({ searchingPlayers: true });
    // Notify('Aguarde, estamos listando os players', 'info_sad');

    const parameters = {
      searchFunctionality: 'getPlayersForRegisterLeadAdmin',
      userType: 'client',
      paginateOnSelect: true,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;

        const options = [];
        for (let i = 0; i < result.data.players.length; i++) {
          options.push({
            value: result.data.players[i].uid,
            label: result.data.players[i].name,
          });
        }
        this.setState({
          playersOptions: options,
          players: result.data.players,
          searchingPlayers: false,
        });
      })
      .catch((error) => {
        this.setState({ searchingPlayers: false });
        console.log('getPlayers error:', error);
      });
  }

  getActionsPlayer(playerId) {
    this.setState({ searchingActionsPlayer: true });

    const parameters = {
      searchFunctionality: 'getActionsPlayer',
      userType: 'client',
      leadRegister: 'leadRegister',
      playerId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;

        if (result.success)
          this.setState({ ...result.data, searchingActionsPlayer: false });
      })
      .catch((error) => {
        this.setState({ searchingActionsPlayer: false });
        // Notify('Erro ao buscar publicações', 'error');
        console.log('getPlayers error:', error);
      });
  }

  getStateList() {
    this.setState({ loadingStateList: true }, () => {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then((states) => {
          const sortedStates = _.sortBy(states.data, 'nome');
          this.setState({ stateList: sortedStates, loadingStateList: false });
        })
        .catch((error) => {
          this.setState({ loadingStateList: false });
          console.log('getStateList error:', error);
        });
    });
  }

  getCityList(estado = 31) {
    this.setState({ loadingCityList: true }, () => {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
        )
        .then((city) => {
          this.setState({ cityList: city.data, loadingCityList: false });
        })
        .catch((error) => {
          this.setState({ loadingCityList: false });
          console.log('getCityList error:', error);
        });
    });
  }

  getAddress(e) {
    e.preventDefault();
    const value =
      this.state.formData.inputCep &&
      this.state.formData.inputCep.replace('-', '');

    this.setState({ isLoadingCep: true });

    axios
      .get(`https://viacep.com.br/ws/${value}/json`)
      .then((res) => {
        if (res.data.erro) {
          this.setState({ isLoadingCep: false });
          Notify('CEP Inválido.', 'error');
          return;
        }

        if (res.status === 200) {
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              streetCep: res.data.logradouro,
              neighborhoodCep: res.data.bairro,
              cityCep: res.data.localidade,
              stateCep: res.data.uf,
            },
          }));

          this.setState({ isLoadingCep: false });
        }
      })
      .catch((e) => {
        Notify('Erro ao buscar o CEP.', 'error');
      });
  }

  dataUserIsUncompleted() {
    const { formData } = this.state;

    if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.cityAndState &&
      this.context.clientData.customFormLead.cityAndState === 'true'
    ) {
      return !formData.city || !formData.state;
    } else if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.cpf &&
      this.context.clientData.customFormLead.cpf === 'true'
    ) {
      return !formData.cpf;
    } else if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.email &&
      this.context.clientData.customFormLead.email === 'true'
    ) {
      return !formData.email;
    } else if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.phone &&
      this.context.clientData.customFormLead.phone === 'true'
    ) {
      return !formData.phone;
    } else if (
      this.context.clientData.isOptionsInputsForLeads &&
      this.context.clientData.isOptionsInputsForLeads === 'true'
    ) {
      return (
        !formData.streetCep ||
        !formData.neighborhoodCep ||
        !formData.cityCep ||
        !formData.stateCep ||
        !formData.inputCep
      );
    } else {
      return (
        !formData.name ||
        !formData.acceptedTerms ||
        !formData.actionId ||
        !formData.playerId
        // !formData.cpf ||
        // !formData.email ||
        // !formData.city ||
        // !formData.state ||
      );
    }
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'leadToken' &&
        inputKey !== 'acceptedTermsEmcamp' &&
        inputKey !== 'formCompleted' &&
        inputKey !== 'isVerified' &&
        inputKey !== 'leadToken' &&
        inputKey !== 'converted' &&
        inputKey !== 'city' &&
        inputKey !== 'state' &&
        inputKey !== 'cpf' &&
        inputKey !== 'email' &&
        inputKey !== 'phone' &&
        inputKey !== 'streetCep' &&
        inputKey !== 'neighborhoodCep' &&
        inputKey !== 'cityCep' &&
        inputKey !== 'stateCep' &&
        inputKey !== 'playerSelected' &&
        inputKey !== 'inputCep'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  handleCpfCnpj(e) {
    let input = e.target;

    if (input.value.length <= 14) {
      let maskCPF = mask.maskCPF(input.value);
      this.handleFormDataUserChange(input.name, maskCPF);
    } else if (input.value.length > 14 && input.value.length <= 18) {
      let maskCNPJ = mask.maskCNPJ(input.value);
      this.handleFormDataUserChange(input.name, maskCNPJ);
    } else {
      let maskLimited = mask.maskLimited(input.value);
      this.handleFormDataUserChange(input.name, maskLimited);
    }
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataUserChange(name, value);
  }

  handleFormDataUserChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          dataUserUncompleted: this.dataUserIsUncompleted(),
        });
      }
    );
  }

  handleFormDataChange(name, value) {
    const { action } = this.state;

    if (name === 'state') {
      const { stateList } = this.state;

      let chosenState = _.findWhere(stateList, { id: value.value });

      if (name === 'state' && value) {
        this.getCityList(value.value);
        this.handleFormDataUserChange(name, chosenState.sigla);
        return;
      } else if (name === 'state' && !value) {
        this.setState({ cityList: [] });
      }

      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value.label,
        },
      }));
    } else if (action) {
      if (
        normalizeLowerCase(name) === normalizeLowerCase(action.extraFieldName1)
      ) {
        const normalizedExtraFieldName = normalizeLowerCase(name);

        this.setState((prevState) => ({
          formData: {
            ...prevState.formData,
            [normalizedExtraFieldName]: value.label,
          },
        }));
      }
    } else if (name === 'city') {
      this.handleFormDataUserChange(name, value.value);
    } else if (name === 'playerId') {
      //fazer requisição pras actions que esse player participa
      this.getActionsPlayer(value.value);
      this.handleFormDataUserChange(name, value.value);
    } else if (name === 'actionId') {
      const { playerId } = this.state.formData;
      const { clientData } = this.context;
      const action = _.find(
        this.state.actionsPlayer,
        (action) => action.id === value.value
      );

      if (clientData.useIntegration === 'enabled') {
        this.getValidationCode(playerId, value.value);
      }
      this.handleFormDataUserChange(name, value.value);
      if (action) {
        this.setState((prevState) => ({
          ...prevState,
          action: action,
        }));
      }
    }
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  acceptedTermsOnChange(e) {
    const { checked } = e.target;
    this.handleFormDataUserChange('acceptedTerms', checked);
  }

  toggleTermsModal(typeModalTerms = 'terms') {
    const { clientData } = this.context;
    const clientId = clientData.uid;

    this.setState({
      modalTermsIsOpen: !this.state.modalTermsIsOpen,
      typeModalTerms: typeModalTerms,
    });
    this.getTermsFromClient(clientId);
  }

  getTermsFromClient(clientId) {
    let parameters = {
      searchFunctionality: 'getTermsUsePlayer',
      userType: 'client',
      clientId: clientId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        let result = res.data.data;
        this.setState((prevState) => ({
          termsClientHtml: result
            ? result.htmlString
            : prevState.termsClientHtml,
          termsClientImage: result
            ? result.termsImage
            : prevState.termsClienImage,
        }));
      })
      .catch((error) => {
        console.log('getTermsUsePlayer error:', error);
      });
  }

  async loadOptions(search, prevOptions) {
    let filteredOptions;
    if (!search) {
      filteredOptions = this.state.playersOptions;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = this.state.playersOptions.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  }

  handleChangePlayers(e) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        playerId: e.value,
        playerSelected: e,
      },
    }));

    //fazer requisição pras actions que esse player participa
    this.getActionsPlayer(e.value);
    // this.handleFormDataUserChange(name, value.value);
  }

  renderLeadData() {
    const { formData, action } = this.state;

    return (
      <div className="col-md-12">
        <div className="col-md-7">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="playerId">
              {this.translate('Selecione um player')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            {/* <AsyncPaginate
              isDisabled={
                this.state.savingLead ||
                this.state.searchingActionsPlayer ||
                this.state.searchingPlayers
              }
              value={this.state.formData.playerSelected}
              loadOptions={(search, prevOptions) =>
                this.loadOptions(search, prevOptions)
              }
              placeholder="Selecione um player..."
              onChange={e => this.handleChangePlayers(e)}
            /> */}
            <Select
              id="inputState"
              required={true}
              name="playerId"
              options={_.map(this.state.players, (action) => {
                return {
                  value: action.uid,
                  label: `${action.name}`,
                };
              })}
              classNamePrefix="select"
              placeholder="Selecione um usuário..."
              onChange={(e) => this.handleFormDataChange('playerId', e)}
              isDisabled={
                this.state.savingLead ||
                this.state.searchingActionsPlayer ||
                this.state.searchingPlayers
              }
              className="custom-select-action"
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="actionId">
              {this.translate('Selecione uma Publicação')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <Select
              id="inputState"
              required={true}
              name="actionId"
              options={_.map(this.state.actionsPlayer, (action) => {
                return {
                  value: action.id,
                  label: action.actionTitle,
                };
              })}
              classNamePrefix="select"
              placeholder="Selecione uma Publicação..."
              onChange={(e) => this.handleFormDataChange('actionId', e)}
              isDisabled={
                this.state.savingLead ||
                this.state.searchingActionsPlayer ||
                this.state.searchingPlayers
              }
              className="custom-select-action"
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="name">
              {this.translate('Nome completo')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>
            <input
              required
              className="form-control profile-placeholder"
              name="name"
              placeholder={this.translate('Nome completo')}
              onChange={(e) => this.handleUserInput(e)}
              value={formData.name}
              disabled={
                this.state.savingLead ||
                this.state.searchingActionsPlayer ||
                this.state.searchingPlayers
              }
            />
          </div>
          {this.context.clientData &&
            this.context.clientData.customFormLead &&
            this.context.clientData.customFormLead.cpf &&
            this.context.clientData.customFormLead.cpf === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="cpf">
                  {this.translate('CPF ou CNPJ')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  name="cpf"
                  placeholder={this.translate('CPF ou CNPJ')}
                  onChange={(e) => this.handleCpfCnpj(e)}
                  value={formData.cpf}
                  maxLength={18}
                  disabled={
                    this.state.savingLead ||
                    this.state.searchingActionsPlayer ||
                    this.state.searchingPlayers
                  }
                />
              </div>
            )}

          {this.context.clientData &&
            this.context.clientData.customFormLead &&
            this.context.clientData.customFormLead.phone &&
            this.context.clientData.customFormLead.phone === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="phone">
                  {this.translate('Celular')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <input
                  required
                  className="form-control profile-placeholder"
                  name="phone"
                  type="phone"
                  placeholder="(99) 99999-9999"
                  disabled={
                    this.state.savingLead ||
                    this.state.searchingActionsPlayer ||
                    this.state.searchingPlayers
                  }
                  onChange={(e) => {
                    let input = e.target;
                    mask.maskTEL(input.value).then((maskedValue) => {
                      this.handleFormDataUserChange(input.name, maskedValue);
                    });
                  }}
                  maxLength={15}
                  value={formData.phone}
                />
              </div>
            )}

          {this.context.clientData &&
            this.context.clientData.customFormLead &&
            this.context.clientData.customFormLead.email &&
            this.context.clientData.customFormLead.email === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="email">
                  {this.translate('E-mail')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <input
                  required
                  disabled={
                    this.state.savingLead ||
                    this.state.searchingActionsPlayer ||
                    this.state.searchingPlayers
                  }
                  className="form-control profile-placeholder"
                  name="email"
                  type="email"
                  placeholder={this.translate('E-mail')}
                  onChange={(e) => this.handleUserInput(e)}
                  value={formData.email}
                />
              </div>
            )}

          {this.context.clientData &&
            this.context.clientData.customFormLead &&
            this.context.clientData.customFormLead.cityAndState &&
            this.context.clientData.customFormLead.cityAndState === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="state">
                  {this.translate('Estado')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <Select
                  id="inputState"
                  required={true}
                  name="state"
                  options={
                    // this.state.stateList &&
                    _.map(this.state.stateList, (state) => {
                      return {
                        value: state.id,
                        label: state.nome,
                      };
                    })
                  }
                  classNamePrefix="select"
                  placeholder="Selecione um estado..."
                  onChange={(e) => this.handleFormDataChange('state', e)}
                  disabled={this.state.loadingStateList}
                  className="custom-select-action"
                />
              </div>
            )}

          {this.context.clientData &&
            this.context.clientData.customFormLead &&
            this.context.clientData.customFormLead.cityAndState &&
            this.context.clientData.customFormLead.cityAndState === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="state">
                  {this.translate('Cidade')} <code>*</code>
                  <span
                    style={{ fontSize: '10px', display: 'contents' }}
                  ></span>
                </label>
                <Select
                  id="inputState"
                  required={true}
                  name="city"
                  options={_.map(this.state.cityList, (city) => {
                    return {
                      value: city.id,
                      label: city.nome,
                    };
                  })}
                  classNamePrefix="select"
                  placeholder="Selecione uma cidade..."
                  onChange={(e) => this.handleFormDataChange('city', e)}
                  disabled={
                    this.state.loadingCityList || !this.state.formData.state
                  }
                  className="custom-select-action"
                />
              </div>
            )}

          {action && action.extraFieldName1 && action.extraFieldSheet1 && (
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="state">
                {action.extraFieldName1} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}></span>
              </label>
              <Select
                id="inputState"
                required={true}
                name={action.extraFieldName1}
                options={
                  action.extraFieldName1 &&
                  action.extraFieldSheet1 &&
                  _.map(action.extraFieldSheet1, (group) => {
                    return {
                      value: normalizeLowerCase(group.class),
                      label: group.class,
                    };
                  })
                }
                classNamePrefix="select"
                placeholder="Selecione uma cidade..."
                onChange={(e) =>
                  this.handleFormDataChange(
                    action.extraFieldName1.toLowerCase(),
                    e
                  )
                }
                className="custom-select-action"
              />
            </div>
          )}

          {action &&
            action.extraFieldName1 &&
            action.extraFieldSheet1 &&
            this.context.clientData.isOptionsInputsForLeads &&
            this.context.clientData.isOptionsInputsForLeads === 'true' && (
              <div className="row">
                <div
                  className="form-group col-md-12"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'end',
                    paddingLeft: '0px',
                  }}
                >
                  <div className="form-group col-md-8">
                    <label className="profile-label" htmlFor="state">
                      {this.translate('Buscar CEP')} <code>*</code>
                      <span
                        style={{ fontSize: '10px', display: 'contents' }}
                      ></span>
                    </label>
                    <input
                      required
                      className="form-control profile-placeholder"
                      name="inputCep"
                      type="text"
                      placeholder={this.translate('Seu CEP')}
                      onChange={(e) => this.handleUserInput(e)}
                      value={formData.inputCep}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <button
                      style={{
                        backgroundColor: this.context.clientData.primaryColor,
                        color: this.context.clientData.secondaryColor,
                      }}
                      disabled={this.state.isLoadingCep}
                      className="btn btn-block btn-oq-color"
                      onClick={(e) => this.getAddress(e)}
                    >
                      Buscar CEP
                    </button>
                  </div>
                </div>

                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="stateCep">
                    {this.translate('Estado')} <code>*</code>
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    required
                    className="form-control profile-placeholder"
                    name="stateCep"
                    type="text"
                    placeholder={this.translate('Estado')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.stateCep}
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="cityCep">
                    {this.translate('Cidade')} <code>*</code>
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    required
                    className="form-control profile-placeholder"
                    name="cityCep"
                    type="text"
                    placeholder={this.translate('Cidade')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.cityCep}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="neighborhoodCep">
                    {this.translate('Bairro')} <code>*</code>
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    required
                    className="form-control profile-placeholder"
                    name="neighborhoodCep"
                    type="text"
                    placeholder={this.translate('Bairro')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.neighborhoodCep}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="streetCep">
                    {this.translate('Rua/Avenida')} <code>*</code>
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    required
                    className="form-control profile-placeholder"
                    name="streetCep"
                    type="text"
                    placeholder={this.translate('Rua/Avenida')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.streetCep}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="numberCEP">
                    {this.translate('Número')} <code>*</code>
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    required
                    className="form-control profile-placeholder"
                    name="numberCEP"
                    type="text"
                    placeholder={this.translate('Número')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.numberCEP}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="complementCEP">
                    {this.translate('Complemento')}
                    <span
                      style={{ fontSize: '10px', display: 'contents' }}
                    ></span>
                  </label>
                  <input
                    disabled={this.state.isLoadingCep}
                    className="form-control profile-placeholder"
                    name="complementCEP"
                    type="text"
                    placeholder={this.translate('Complemento')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.complementCEP}
                  />
                </div>
              </div>
            )}

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="acceptedTerms">
              {this.translate('Aceitar Termos')} <code>*</code>
              <span style={{ fontSize: '10px', display: 'contents' }}></span>
            </label>

            <div style={{ display: 'flex' }} className="form-group col-md-12">
              <input
                style={{ marginRight: '10px' }}
                type="checkbox"
                // className="form-control profile-placeholder"
                name="acceptedTerms"
                placeholder={this.translate('Aceitar Termos')}
                className="oq-checkbox oq-padding-right"
                onChange={(e) => this.acceptedTermsOnChange(e)}
                checked={this.state.formData.acceptedTerms}
              />
              <span className="oq-font-book">
                Li e concordo com a{' '}
                <a
                  className="custom-link"
                  href="https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/TermsAndPolitics%2FPOLITICA%20DE%20PRIVACIDADE%20.pdf?alt=media&token=36efa8dc-c5b2-490a-8185-02c940c684fe"
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de Privacidade
                </a>{' '}
                e concordo que a empresa entre em contato comigo.
                {/* e{' '}
                <a
                  className="custom-link"
                  onClick={() => this.toggleTermsModal()}
                >
                  Termos de uso.
                </a>{' '}
                Autorizo também a usar meus dados para enviar informações do meu
                interesse. */}
              </span>
              <ModalTerms
                isOpen={this.state.modalTermsIsOpen}
                termsHtml={this.state.termsClientHtml}
                termsImage={this.state.termsClientImage}
                termsType={this.state.typeModalTerms}
                toggleTermsModal={() => this.toggleTermsModal()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  saveLead = async () => {
    let lead = this.state.formData;

    const { clientData } = this.context;

    const playerData = _.find(
      this.state.players,
      (player) => player.uid === lead.playerId
    );
    const playerId = playerData.uid;
    const action = _.find(
      this.state.actionsPlayer,
      (action) => action.id === lead.actionId
    );

    lead.clientId = clientData.uid;

    let now = moment().valueOf();
    lead.createdAt = now;

    let rand = () => {
      return (
        Math.random().toString(36).substr(2, 9) +
        Math.random().toString(36).substring(2, 9)
      );
    };
    let token = rand();

    // this.handleFormDataUserChange('leadToken', token);
    lead.leadToken = token;

    this.setState({ savingLead: true }, async () => {
      this.toastId = toast.info('Criando Lead. Aguarde...', {
        autoClose: false,
      });

      const parameters = {
        createFunctionality: 'registerLead',
        userType: 'consumer',
        lead,
        clientId: clientData.uid,
        playerId,
        validationCode: this.state.validationCode,
      };

      api
        .post(`/create`, parameters)
        .then((res) => {
          let result = res.data;

          if (result.success) {
            if (action.leadRegistration) {
              if (
                clientData.isTeamCompetition &&
                clientData.isTeamCompetition === 'true' &&
                (!playerData.influencer || playerData.influencer === false)
              ) {
                this.addPointsInfluencer();
              }
              this.addPointsToPlayer();
            }

            this.validateLead(result.token ? result.token : '');
            toast.update(this.toastId, {
              render: 'Lead criado com sucesso',
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            this.setState(this.getInitialState());
            // this.setState({ savingLead: false, validatingLead: false });
          } else {
            if (result.message === 'Nenhum parâmetro de envio encontrado.') {
              this.setState({ savingLead: false, validatingLead: false });
              return Notify(result.message, 'info_sad');
            }

            this.setState({ savingLead: false, validatingLead: false });

            if (result.message === 'Esse cadastro já é um aluno Pravaler') {
              this.setState({ message: result.message });
              return Notify(result.message, 'info_sad');
            }

            toast.update(this.toastId, {
              render: result.message,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
        })
        .catch((error) => {
          this.setState({ savingLead: false });
          console.log('saveLead error:', error);
          toast.update(this.toastId, {
            render: 'Falha na criação do Lead',
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        });
    });
  };

  validateLead = (token = '') => {
    const { clientData } = this.context;
    const lead = this.state.formData;

    const playerData = _.find(
      this.state.players,
      (player) => player.uid === lead.playerId
    );

    const playerId = playerData.uid;

    const action = _.find(
      this.state.actionsPlayer,
      (action) => action.id === lead.actionId
    );

    this.setState({ validatingLead: true }, () => {
      // if (clientData.clientUrl === 'bild') {
      // ReactPixel.track('Contact');
      // ReactPixel.track('Lead');
      // }
      // if (clientData.clientUrl === 'indiqueeganhe') {
      //   ReactPixel.track('PageView');
      // }
      let now = moment().valueOf();
      let host = window.location.host;

      let link = `${host}/action/${playerId}/${action.id}/${now}/?token=${lead.leadToken}`;

      let parameters = {
        searchFunctionality: 'verifyLead',
        userType: 'consumer',
        data: {
          lead,
          player: playerData,
          action,
          client: clientData,
          link,
        },
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          let result = res.data;

          if (result.success) {
            this.setState({ validatingLead: false, savingLead: false });
          }
        })
        .catch((e) => {
          this.setState({ validatingLead: false, savingLead: false });
          console.log('validatingLead error', e);
        });
    });
  };

  addPointsToPlayer() {
    const { clientData } = this.context;
    const lead = this.state.formData;

    const playerData = _.find(
      this.state.players,
      (player) => player.uid === lead.playerId
    );

    const playerId = playerData.uid;

    const action = _.find(
      this.state.actionsPlayer,
      (action) => action.id === lead.actionId
    );

    const { leadRegistration } = action;

    this.setState({ savingLead: true }, () => {
      let parameters = {
        userType: 'consumer',
        updateFunctionality: 'addActionPoints',
        playerId: playerId,
        pointsNode: 'leadRegistration',
        points: leadRegistration ? parseInt(leadRegistration) : 0,
        actionId: lead.actionId,
        clientId: clientData.uid,
      };

      let endPoint = `/update`;

      api
        .post(endPoint, parameters)
        .then((response) => {
          this.setState({ savingLead: false });

          // this.props.closeModal();
        })
        .catch((error) => {
          Notify('Não foi possivel atribuir a pontuação!', 'info_sad');
          console.log('addPointsToPlayer error:', error);
        });
    });
  }

  addPointsInfluencer() {
    const lead = this.state.formData;

    const { clientData } = this.context;

    const playerData = _.find(
      this.state.players,
      (player) => player.uid === lead.playerId
    );
    const action = _.find(
      this.state.actionsPlayer,
      (action) => action.id === lead.actionId
    );

    const { leadRegistration } = action;

    let pointsPercentage = Number(clientData.percentageOverallInfluencer) / 100;
    try {
      let parameters = {
        userType: 'consumer',
        updateFunctionality: 'verifyInfluencerInAction',
        playerId: playerData.influencerId ? playerData.influencerId : '',
        actionId: action.id,
        clientId: clientData.uid,
        pointsNode: 'leadRegistration',
        points: leadRegistration
          ? parseInt(leadRegistration * pointsPercentage)
          : 0,
      };

      let endPoint = `/update`;
      api.post(endPoint, parameters).then((response) => {});
      //Notify(message, result.success ? "success" : "error");
    } catch (error) {
      //Notify("Não foi possivel atribuir a pontuação!", "info_sad");
      console.log('addPointsToPlayer error:', error);
    }
    this.setState({ loading: false });
  }

  leadRegister() {
    const { formData } = this.state;
    const nameSplit = formData.name.split(' ');
    const lastName =
      nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : '';

    if (!lastName) {
      Notify('O sobrenome é necessário', 'warn');
      return;
    }

    if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.cpf &&
      this.context.clientData.customFormLead.cpf === 'true' &&
      this.state.formData.cpf.length === 14
    ) {
      if (
        this.context.clientData.customFormLead.cpf === 'true' &&
        this.validateCPF(this.state.formData.cpf) === false
      ) {
        Notify('Insira um CPF válido', 'warn');
      } else {
        this.saveLead();
      }
    } else if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.cpf &&
      this.context.clientData.customFormLead.cpf === 'true' &&
      this.state.formData.cpf.length === 18
    ) {
      if (
        this.context.clientData.customFormLead.cpf === 'true' &&
        this.validateCNPJ(this.state.formData.cpf) === false
      ) {
        Notify('Insira um CNPJ válido', 'warn');
      } else {
        this.saveLead();
      }
    } else if (
      this.context.clientData &&
      this.context.clientData.customFormLead &&
      this.context.clientData.customFormLead.cpf &&
      this.context.clientData.customFormLead.cpf !== 'true'
    ) {
      this.saveLead();
    }
  }

  validateCPF(cpf) {
    //separa o CPF pelos pontos
    const cutCPF = cpf.split('.');
    //pega a parte que tem o - e salva numa variavel
    const cpfLastPart = cutCPF[2];
    //separa a parte que tem o -
    const cutCpfLastPart = cpfLastPart.split('-');
    //junta tudo numa const só
    const cpfJoin =
      cutCPF[0] + cutCPF[1] + cutCpfLastPart[0] + cutCpfLastPart[1];

    var Soma;
    var Resto;
    let i;
    Resto = 0;
    Soma = 0;

    if (
      cpfJoin == '00000000000' ||
      cpfJoin == '11111111111' ||
      cpfJoin == '22222222222' ||
      cpfJoin == '33333333333' ||
      cpfJoin == '44444444444' ||
      cpfJoin == '55555555555' ||
      cpfJoin == '66666666666' ||
      cpfJoin == '77777777777' ||
      cpfJoin == '88888888888' ||
      cpfJoin == '99999999999'
    ) {
      return false;
    }

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(cpfJoin.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(cpfJoin.substring(9, 10))) {
      return false;
    }

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(cpfJoin.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(cpfJoin.substring(10, 11))) {
      return false;
    } else {
      return true;
    }

    //fonte https://ogeradordecpf.com.br/validar-javascript/
  }

  validateCNPJ(cnpj) {
    const cutCnpj = cnpj.split('.');
    const cutCnpjPenulimatePart = cutCnpj[2].split('/');

    const cutCnpjLastPart = cutCnpjPenulimatePart[1].split('-');

    const joinCnpj =
      cutCnpj[0] +
      cutCnpj[1] +
      cutCnpjPenulimatePart[0] +
      cutCnpjLastPart[0] +
      cutCnpjLastPart[1];

    if (joinCnpj == '') return false;

    if (joinCnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      joinCnpj == '00000000000000' ||
      joinCnpj == '11111111111111' ||
      joinCnpj == '22222222222222' ||
      joinCnpj == '33333333333333' ||
      joinCnpj == '44444444444444' ||
      joinCnpj == '55555555555555' ||
      joinCnpj == '66666666666666' ||
      joinCnpj == '77777777777777' ||
      joinCnpj == '88888888888888' ||
      joinCnpj == '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = joinCnpj.length - 2;
    let numeros = joinCnpj.substring(0, tamanho);
    let digitos = joinCnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    let i;
    let resultado;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) {
      return false;
    }

    tamanho = tamanho + 1;
    numeros = joinCnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) {
      return false;
    } else {
      return true;
    }

    //fonte: https://www.geradorcnpj.com/javascript-validar-cnpj.htm
  }

  handleFormSubmit(e) {
    e.preventDefault();

    this.leadRegister();
  }

  renderForm() {
    return (
      <div className="container-fluid ">
        {this.renderLeadData()}
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={!this.state.formValid || this.state.savingLead}
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  dataUserUncompleted: this.dataUserIsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              {this.translate('Salvar')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />

          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title" style={{ fontWeight: 'bold' }}>
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; {this.translate('Novo Lead')}
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadRegister;
