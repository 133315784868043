import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import SideBar from '../../../components/SideBar';
import styles from './styles.module.css';
import React from 'react';

export function BusinessHome() {
  return (
    <>
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="container">
              <br />
              <br />
              <h2 className="text-center">Meus empreendimentos</h2>
              <p className="text-center">
                Lorem ipsum, dolor sit amet consectetur.
              </p>
              <br />
              <br />
              <div className="row">
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardImage}>
                      <img
                        src="https://pointer.com.br/blog/wp-content/uploads/2021/02/5a8c590ea936140d7f6def44.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className={styles.cardBody}>
                      <p>Titulo do empreendimento</p>
                      <br />
                      <Link
                        to="/client/action/actionsBusiness"
                        className="mb-3"
                      >
                        <button class="btn btn-default btn-block" type="submit">
                          Configurar publicação
                        </button>
                      </Link>
                      <Link to="/client/action/actionsBusinessResults">
                        <button class="btn btn-default btn-block" type="submit">
                          Resultados da publicação
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardImage}>
                      <img
                        src="https://swellconstrucoes.com.br/wp-content/uploads/2020/11/SWELL_SILVA_duplex_v04_01-1.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className={styles.cardBody}>
                      <p>Titulo do empreendimento</p>
                      <br />
                      <Link
                        to="/client/action/actionsBusiness"
                        className="mb-3"
                      >
                        <button class="btn btn-default btn-block" type="submit">
                          Configurar publicação
                        </button>
                      </Link>
                      <Link to="/client/action/actionsBusinessResults">
                        <button class="btn btn-default btn-block" type="submit">
                          Resultados da publicação
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardImage}>
                      <img
                        src="https://www.tpsimoveis.com.br/uploads/properties/2020/08/apartamento-petropolis-porto-alegre194-7-1596495959mtu5njq5ntk1oq.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className={styles.cardBody}>
                      <p>Titulo do empreendimento</p>
                      <br />
                      <Link
                        to="/client/action/actionsBusiness"
                        className="mb-3"
                      >
                        <button class="btn btn-default btn-block" type="submit">
                          Configurar publicação
                        </button>
                      </Link>
                      <Link to="/client/action/actionsBusinessResults">
                        <button class="btn btn-default btn-block" type="submit">
                          Resultados da publicação
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className={styles.card}>
                    <div className={styles.cardImage}>
                      <img
                        src="https://emccamp.com.br//box/uploads/2022/07/1006x615-piscinaraia.jpg"
                        alt=""
                        className="img-responsive"
                      />
                    </div>
                    <div className={styles.cardBody}>
                      <p>Titulo do empreendimento</p>
                      <br />
                      <Link
                        to="/client/action/actionsBusiness"
                        className="mb-3"
                      >
                        <button class="btn btn-default btn-block" type="submit">
                          Configurar publicação
                        </button>
                      </Link>
                      <Link to="/client/action/actionsBusinessResults">
                        <button class="btn btn-default btn-block" type="submit">
                          Resultados da publicação
                        </button>
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
