import moment from 'moment';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';

import { api } from '../../../Config';
import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class RankingCorrectors extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      rankingCorrectorsData: [],
      loadingRankingCorrectors: false,
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getRankingCorrectors();
  }

  async getRankingCorrectors() {
    this.setState({
      loadingRankingCorrectors: true,
    });

    try {
      const parameters = {
        searchFunctionality: 'getRankingCorrectors',
        userType: 'client',
        clientId: this.context.clientData && this.context.clientData.uid,
      };

      const { data } = await api.post(`/search`, parameters);

      if (data.success) {
        this.setState({
          loadingRankingCorrectors: false,
          rankingCorrectorsData: data.data,
        });
      }
    } catch (error) {
      console.log('getRankingCorrectors error: ', error);
      this.setState({
        loadingRankingCorrectors: false,
      });
    }
  }

  renderActionRank() {
    let index = 1;
    let rankingCorrectorsData = [
      {
        name: 'sergio',
        amountPointsConverted: 200,
        uid: '1',
      },
      {
        name: 'lucas',
        amountPointsConverted: 150,
        uid: '2',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '3',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '4',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '5',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '6',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '7',
      },
      {
        name: 'sergio',
        amountPointsConverted: 120,
        uid: '8',
      },
    ];
    const columns = [
      {
        Header: '',
        accessor: 'photoURL',
        width: 50,
        Cell: (props) => (
          <img
            src={props.value ? props.value : avatarGeneric}
            className="img-fluid rounded-circle"
            style={{ width: '40px', height: '40px', border: '1px solid grey' }}
            alt="player logo"
          />
        ),
      },
      {
        Header: '',
        id: 'rankingIcon',
        accessor: 'ranking',
        width: 50,
        Cell: (props) => {
          const now = moment().valueOf();
          const actionEndAt = moment(props.original.endDateEpoch).valueOf();
          const finished = props.original.finished || now > actionEndAt;

          if (props.value <= 2 && finished) {
            return (
              <div className="text-center">
                <i
                  className="fa fa-trophy"
                  style={{
                    fontSize: '1.3em',
                    color: 'gold',
                    verticalAlign: 'middle',
                  }}
                  aria-hidden="true"
                />
              </div>
            );
          }
          return null;
        },
      },
      {
        Header: this.translate('userName'),
        accessor: 'name',
        Cell: (props) => <span className="text-center">{props.value}</span>,
      },
      {
        Header: this.translate('totalPontuation'),
        accessor: 'amountPointsConversion',
        Cell: (props) => {
          if (props.value > 1 || props.value == 0) {
            return <span className="text-center">{props.value} pontos</span>;
          } else {
            return <span className="text-center">{props.value} ponto</span>;
          }
        },
      },
      {
        id: 'position',
        Header: this.translate('position'),
        accessor: (d) => (d ? `${index++}°` : '--'),
        Cell: (props) => <span className="text-center">{props.value}</span>,
      },
      {
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <Link
              to={`${routes.EDIT_PLAYER}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              {this.translate('DETALHES')}
            </Link>
          </span>
        ),
      },
    ];

    const starRanking =
      this.context.clientData && this.context.clientData.startRankingInterval
        ? this.context.clientData.startRankingIntervalEpoch
        : null;
    const endRanking =
      this.context.clientData && this.context.clientData.endRankingInterval
        ? this.context.clientData.endRankingIntervalEpoch
        : null;

    return (
      <div className="oq-card action-ranking">
        <span className="oq-card-title">
          <i className="fa fa-trophy" />
          &nbsp; Ranking de Corretores
        </span>{' '}
        <span style={{ fontSize: '18px' }} className="oq-card-title">
          &nbsp; - período de {moment(starRanking).format('DD/MM/YYYY')} até{' '}
          {moment(endRanking).format('DD/MM/YYYY')}
        </span>
        <div className="oq-line" />
        <div style={{ paddingTop: 10 }}>
          <ReactTable
            data={this.state.rankingCorrectorsData}
            defaultPageSize={10}
            columns={columns}
            previousText={this.translate('prev')}
            nextText={this.translate('next')}
            loading={this.state.loadingRanking}
            loadingText={this.translate('loadingRanking')}
            noDataText={this.translate('noPlayersInIndicationRaking')}
            ofText={this.translate('of')}
            rowsText={this.translate('lines')}
          />
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back oq-margin-horizontal"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="col-md-12">
              <div className="row">{this.renderBackButton()}</div>
              {this.renderActionRank()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const PlayersPageLink = props => (
//   <Link className="menu-link" to={routes.PLAYERS}>
//     <FontAwesomeIcon icon={faUsers} />
//     <span className="sidebarText">{props.translate('players')}</span>
//   </Link>
// );

export default RankingCorrectors;

// export { PlayersPageLink };
