import './style.css';

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';

import logoImg from '../../Images/logo-oq-light.png';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import getClientData from '../../utils/Func/getClientData';

import { api } from '../../../Config';
import VideoBg from '../../components/VideoBg';
import { UserDataContext } from '../../../hooks/useUserData';

const SignInClientPage = ({ history }) => <SignInForm history={history} />;

class SignInForm extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
  }

  getInitialState() {
    const initialState = {
      email: '',
      password: '',
      error: null,
      result: null,
      loginStatus: 'ENTRAR',
      processing: false,
      isLoadingClient: false,
      clientActive: true,
    };
    return initialState;
  }

  async componentDidMount() {
    const methodGet = await getClientData();

    const isUrl = methodGet.type === 'activeDomain';

    await this.getClientDataByAlias(methodGet.link, isUrl);
  }

  async getClientDataByAlias(clientAlias, isUrl) {
    this.setState({ isLoadingClient: true });
    const parameters = {
      searchFunctionality: 'getClientByAlias',
      userType: 'client',
      clientAlias,
      isUrl,
    };

    try {
      const res = await api.post(`/search`, parameters);
      const result = res.data.data;

      if (res.data.success) {
        this.setState((prevState) => ({
          clientActive: result.clientActive,
          companyName: result.companyName,
          primaryColor: result.primaryColor
            ? result.primaryColor
            : prevState.primaryColor,
          secondaryColor: result.secondaryColor
            ? result.secondaryColor
            : prevState.secondaryColor,
          favIcon: result.favIcon
            ? result.favIcon
            : result.logoURL
              ? result.logoURL
              : prevState.leftLogo,
          logoURL: result.logoURL ? result.logoURL : prevState.logoURL,
          leftLogo: result.logoURL ? result.logoURL : prevState.leftLogo,

          // loginEmail: result.loginEmail,
          clientId: result.clientId,

          clientUrl: result.clientUrl ? result.clientUrl : prevState.clientUrl,
        }));
        this.setState({ isLoadingClient: false });
      }

      this.setState({ isLoadingClient: false });
    } catch (error) {
      this.setState({ isLoadingClient: false });
    }
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setState({ processing: true, loginStatus: 'Entrando ...' });

    const { email, password, clientId } = this.state;

    const { history } = this.props;

    const { signIn } = this.context;

    try {
      await signIn({ email, password, clientId });

      this.setState({
        signingIn: false,
        isLoadingClient: false,
        processing: false,
      });

      history.push(routes.WELCOME_CLIENT);
    } catch (error) {
      console.log('onSubmit error', error.message);

      let errorMsg = '';
      if (error.message === 'auth/wrong-password') {
        errorMsg = 'E-mail ou senha incorretos.';
      } else if (
        error.message === 'auth/account-exists-with-different-credential'
      ) {
        errorMsg = 'Já existe uma conta com o e-mail informado.';
      } else if (
        error.message ===
        'Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações.'
      ) {
        errorMsg = 'E-mail não cadastrado para este cliente.';
      } else {
        errorMsg = 'E-mail ou senha incorretos.';
      }

      Notify(errorMsg, 'error');
      this.setState({
        processing: false,
        loginStatus: 'ENTRAR',
        signingIn: false,
        isLoadingClient: false,
      });
    }
  }

  shortString(str, lengthStr) {
    if (str.length > lengthStr) {
      return str.substr(0, lengthStr) + '...';
    }
    return str;
  }

  render() {
    const { email, password, loginStatus, processing } = this.state;

    const animationDuration = '5s';
    const animationDelay = ['5s', '10s', '20s'];

    const company = this.state.companyName ? this.state.companyName : 'Empresa';
    const logoCompany = this.state.logoURL ? this.state.favIcon : '';

    return (
      <>
        <ToastContainer />

        <div className="oq-login">
          <VideoBg />
          <div className="oq-login-opacity">
            <div className="content-left center">
              <div className="oq-login-content">
                <div className="oq-login-container oq-center">
                  <img
                    className="animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDelay: animationDelay[0],
                      animationDuration: animationDuration,
                    }}
                    src={logoImg}
                    alt="OQ"
                  />
                  <br />
                  <br />
                  <h1
                    className="oq-font-medium animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDuration: animationDuration,
                      animationDelay: animationDelay[1],
                    }}
                  >
                    Bem-vindo à Plataforma OQ!
                  </h1>
                  <p
                    className="oq-font-book animate__animated animate__fadeIn animate__delay-2s"
                    style={{
                      animationDuration: animationDuration,
                      animationDelay: animationDelay[2],
                    }}
                  >
                    Para uma experiência melhor, faça o login <br />
                    no seu laptop ou desktop.
                  </p>
                </div>
              </div>
            </div>
            <div className="content-right center">
              <div className="oq-login-content">
                {this.state.clientActive ? (
                  <form onSubmit={(event) => this.onSubmit(event)}>
                    <div
                      className="oq-login-card animate__animated animate__fadeIn"
                      style={{
                        animationDuration: animationDuration,
                      }}
                    >
                      <div className="oq-login-card-header">
                        <div
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <p className="oq-font-book">Authorized for</p>
                        </div>
                        <div id="oq-header-login">
                          {this.state.isLoadingClient ? (
                            <h2 className="oq-font-medium">Aguarde...</h2>
                          ) : (
                            <>
                              <img src={logoCompany} alt="" />
                              <h2 className="oq-font-medium">
                                {this.shortString(company, 15)}
                              </h2>
                            </>
                          )}
                        </div>
                        <p className="oq-font-book">
                          Faça login na Plataforma OQ Digital usando os dados da
                          sua conta cadastrada.
                        </p>
                      </div>
                      <div className="oq-login-card-content">
                        <div className="oq-input-field">
                          <label htmlFor="inputEmail">E-mail da conta</label>
                          <input
                            disabled={this.state.isLoadingClient}
                            type="email"
                            id="inputEmail"
                            className="login-placeholder form-control"
                            placeholder="Entre com seu e-mail"
                            value={email}
                            required
                            onChange={(event) =>
                              this.setState({
                                email: event.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="oq-input-field">
                          <label htmlFor="inputPassword">Sua senha</label>
                          <input
                            disabled={this.state.isLoadingClient}
                            type="password"
                            id="inputPassword"
                            className="login-placeholder form-control"
                            placeholder="Senha OQ Digital"
                            value={password}
                            required
                            minLength={6}
                            onChange={(event) =>
                              this.setState({
                                password: event.target.value,
                              })
                            }
                          />
                          <br />
                          {this.state.clientActive && (
                            <Link to={routes.PASSWORD_FORGET_CLIENT}>
                              Esqueceu a senha?
                            </Link>
                          )}
                        </div>
                      </div>
                      <div className="oq-login-card-action">
                        <button
                          disabled={this.state.isLoadingClient}
                          className="oq-login-btn"
                          type="submit"
                        >
                          {loginStatus}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="inactive-box">
                    <i className="material-icons-outlined icon-logo">
                      domain_disabled
                    </i>
                    <p>Cliente inativo</p>
                    <a
                      href="https://oqdigital.com"
                      className="btn-inactive-outlined"
                    >
                      <i className="material-icons-outlined">arrow_back</i>
                      Voltar ao site
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SignInClientPage);

export { SignInForm };
