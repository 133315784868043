import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import * as Halogenium from 'halogenium';
import { ToastContainer } from 'react-toastify';
import * as routes from '../../routes';

import { PasswordForgetLink } from '../PasswordForget';
import logoImg from '../Images/logo.png';

import { Notify } from '../../components/Notify';
import { UserDataContext } from '../../hooks/useUserData';

const SignInAdminPage = ({ history }) => <SignInForm history={history} />;

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  result: null,
  loginStatus: 'Entrar',
  processing: false,
};

class SignInForm extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  async onSubmit(event) {
    event.preventDefault();
    this.setState({ processing: true, loginStatus: 'Entrando ...' });

    const { email, password } = this.state;

    const { history } = this.props;

    const { signInMaster } = this.context;

    try {
      await signInMaster({ email, password });

      this.setState({
        signingIn: false,
        isLoadingClient: false,
        processing: false,
      });

      history.push(routes.DASHBOARD_ADMIN);
    } catch (error) {
      console.log('onSubmit error: ', error.message);

      let errorMsg = '';
      if (error.message === 'auth/wrong-password') {
        errorMsg = 'E-mail ou senha incorretos.';
      } else if (
        error.message === 'auth/account-exists-with-different-credential'
      ) {
        errorMsg = 'Já existe uma conta com o e-mail informado.';
      } else if (
        error.message ===
        'Você não está autorizado a fazer login nesse momento. Contate o administrador para mais informações.'
      ) {
        errorMsg = 'E-mail não cadastrado para este cliente.';
      } else {
        errorMsg = 'E-mail ou senha incorretos.';
      }

      Notify(errorMsg, 'error');
      this.setState({
        processing: false,
        loginStatus: 'ENTRAR',
        signingIn: false,
        isLoadingClient: false,
      });
    }
  }

  render() {
    const { email, password, loginStatus, processing } = this.state;

    // const isInvalid = password === "" || password.length < 6 || email === "";

    const color = '#010C28';

    const style = {
      display: 'flex',
      WebkitFlex: '0 1 auto',
      flex: '0 1 auto',
      WebkitFlexDirection: 'column',
      flexDirection: 'column',
      WebkitFlexGrow: 1,
      flexGrow: 1,
      WebkitFlexShrink: 0,
      flexShrink: 0,
      WebkitAlignItems: 'center',
      alignItems: 'center',
      WebkitJustifyContent: 'center',
      justifyContent: 'center',
      marginBottom: '6%',
    };

    return (
      <div className="background-gradient">
        <ToastContainer />
        <div className="row oq-min-v-h-100 d-flex">
          <div className="col-md-6 col-xs-12 align-self-center">
            <img
              className="img-responsive center-block"
              src={logoImg}
              alt="OQ"
              width="50%"
            />
            <h2 className="text-center oq-primary-color">Administrador</h2>
          </div>
          <div className="vertical-line col-md-1 align-self-center" />
          <div className="col-md-5 col-xs-12 align-self-center">
            <div className="col-md-11">
              <form
                className="form-signin"
                onSubmit={(event) => this.onSubmit(event)}
              >
                <div className="row">
                  <div className="form-group col-md-12 row">
                    <label htmlFor="inputEmail" className="login-label">
                      EMAIL
                    </label>
                    <input
                      type="email"
                      id="inputEmail"
                      className="login-placeholder form-control"
                      placeholder="email@email.com.br"
                      value={email}
                      required
                      onChange={(event) =>
                        this.setState({
                          email: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: '4%' }}>
                  <div className="form-group col-md-12 row">
                    <label htmlFor="inputPassword" className="login-label">
                      SENHA
                    </label>
                    <input
                      type="password"
                      id="inputPassword"
                      className="login-placeholder form-control"
                      placeholder="* * * * * * * *"
                      value={password}
                      required
                      onChange={(event) =>
                        this.setState({
                          password: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  {processing && (
                    <div style={style}>
                      <Halogenium.PulseLoader color={color} />
                    </div>
                  )}
                </div>
                <div className="row oq-padding-vertical">
                  <div className="col-md-2 col-xs-2 text-center" />
                  <div className="col-md-8 col-xs-8 text-center">
                    <button className="btn btn-block loginButton" type="submit">
                      {loginStatus}
                    </button>
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 text-center" />
              </form>
              <PasswordForgetLink />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(SignInAdminPage);

export { SignInForm };
