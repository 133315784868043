import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import { UserDataContext } from '../../hooks/useUserData';

class TermsPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      users: [],
      name: '',
      email: '',
      city: '',
      countUsers: 0,
      isLoading: false,
    };

    return initialState;
  }

  goToPreviewText(editorPage) {
    const { history } = this.props;
    history.push(`${routes.PREVIEW_TEXT_ADMIN}/${editorPage}`);
  }

  renderTermsItems() {
    return (
      <div>
        <div
          className="oq-card d-flex col-md-12"
          style={{ width: '97%', height: '11vh', padding: 0 }}
        >
          <div
            className="d-flex col-md-1 col-xs-3 border-right align-items-center justify-content-center"
            style={{ width: '12%' }}
          >
            <img
              src={require('../Images/termos-de-uso-icon.png')}
              className="img-responsive terms_img"
              alt="use terms icon"
            />
          </div>
          <div className="col-md-10 col-xs-9">
            <span className="notification col-md-12 vertical-center">
              <span className="oq-card-span oq-font-medium h4-oq">
                Termos de uso
              </span>
            </span>
            <p className="notification col-md-12" />
          </div>
          <div
            className="col-md-1 hidden-xs vertical-center clickable"
            onClick={() => this.goToPreviewText('terms')}
          >
            <i
              className="fa fa-angle-right fa-2x"
              style={{ fontFamily: 'FontAwesome', color: 'rgb(163, 168, 173)' }}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <div className="container-fluid oq-padding-horizontal">
            {this.renderTermsItems()}
          </div>
        </div>
      </div>
    );
  }
}

const TermsPageLink = (props) => (
  <Link className="menu-link" to={routes.TERMS_ADMIN}>
    <FontAwesomeIcon icon={faBook} />
    &nbsp;<span className="sidebarText">Condições Gerais</span>
  </Link>
);

export default TermsPage;

export { TermsPageLink };
