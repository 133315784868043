import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as routes from '../../../../../../../routes';
import { LoadingDashboard } from '../../../../components/LoadingDashboard';
import { UserDataContext } from '../../../../../../../hooks/useUserData';

const avatarGeneric =
  'https://firebasestorage.googleapis.com/v0/b/oqhomapi.appspot.com/o/playerProfile%2Favatar.png?alt=media&token=4960beef-5a9d-4cf1-9ffa-3a20136a3d1d';

class RankingCorrectors extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loadingRankingCorrectors, rankingCorrectorsData } =
      this.props.dataReport;

    const starRanking =
      this.context.clientData && this.context.clientData.startRankingInterval
        ? this.context.clientData.startRankingIntervalEpoch
        : null;
    const endRanking =
      this.context.clientData && this.context.clientData.endRankingInterval
        ? this.context.clientData.endRankingIntervalEpoch
        : null;

    return (
      <>
        <div className="col-sm-6">
          <div className="card-chart">
            <div className="col-md-12 col-xs-12 p-0">
              <div className="box-chat">
                {loadingRankingCorrectors && !rankingCorrectorsData ? (
                  <LoadingDashboard size="4x" />
                ) : (
                  <>
                    <div>
                      <span className="mb-1 bold">
                        <svg
                          className="icon-trophy"
                          width="16"
                          height="16"
                          viewBox="0 0 23 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.5 24.6H5.39999C4.89999 24.6 4.39999 24.2 4.39999 23.6C4.39999 23.3 4.50001 23.1 4.70001 22.9L6.39999 21.3C7.79999 20 9.59999 19.3 11.4 19.3C13.3 19.3 15.1 20 16.4 21.3L18.1 22.9C18.5 23.3 18.5 23.9 18.1 24.3C18 24.5 17.8 24.6 17.5 24.6Z"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5 14.9C7.3 14.9 3.89999 11.5 3.89999 7.29999V1H19.1V7.29999C19.1 11.5 15.7 14.9 11.5 14.9Z"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M3.29999 8.29999C1.99999 8.29999 1 7.3 1 6C1 4.7 1.99999 3.70001 3.29999 3.70001"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.7 3.70001C21 3.70001 22 4.7 22 6C22 7.3 21 8.29999 19.7 8.29999"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5 15.9V18.8"
                            stroke="#000000"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        &nbsp; Ranking de Corretores &nbsp; - período de{' '}
                        {moment(starRanking).format('DD/MM/YYYY')} até{' '}
                        {moment(endRanking).format('DD/MM/YYYY')}
                      </span>
                      <Link
                        class="ranking-players"
                        to={routes.RANKING_CORRECTORS}
                      >
                        Ver mais
                      </Link>
                    </div>

                    <div className="oq-line" />
                    <div className="box-chart">
                      {rankingCorrectorsData &&
                        rankingCorrectorsData.map((player, index) => {
                          if (index <= 4) {
                            return (
                              <div style={{ paddingTop: 10 }} key={index}>
                                <div
                                  className="col-12 col-md-12"
                                  key={player.name}
                                  style={{ marginTop: '6px' }}
                                >
                                  <div className="col-1 col-md-1 img-fluid ">
                                    {index + 1}º
                                  </div>
                                  <div
                                    className="col-1 col-md-1 img-fluid rounded-circle photo-player p-0"
                                    style={{ top: '-8px' }}
                                  >
                                    <div
                                      id="photo-share"
                                      className="profile-pic oq-border-secondary-contrast-color mx-auto"
                                      style={{
                                        width: '35px',
                                        height: '35px',
                                        border: '5px solid #fff',
                                        backgroundImage: `url(${
                                          player.photoURL
                                            ? player.photoURL
                                            : avatarGeneric
                                        })`,
                                      }}
                                    />
                                  </div>
                                  <div className="col-4 col-md-4 text-left m-0 name-palyer-ranking">
                                    {player.name}
                                  </div>
                                  <div className="col-md-3 col-3 p-0 text-left img-fluid">
                                    {player.amountPointsConversion}{' '}
                                    {player.amountPointsConversion > 1
                                      ? 'pontos'
                                      : 'ponto'}
                                  </div>
                                  <Link
                                    to={`${routes.EDIT_PLAYER}/${player.uid}`}
                                    className="col-3 col-md-3 btn btn-oq p-0 text-right"
                                    style={{
                                      fontSize: '1.2rem',
                                      textTransform: 'none',
                                    }}
                                  >
                                    DETALHES
                                  </Link>

                                  <div
                                    className="line"
                                    style={{
                                      border: '0.5px solid #ddd',
                                      margin: '27px 0 0px 0px',
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RankingCorrectors;
