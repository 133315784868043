import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';

import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ReactTable from 'react-table';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { api, getAPIURL } from '../../../../../Config';
import ModalDeleteAction from '../../../../components/Modals/ActionsModals/EditAction';

import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

class EditResearchAwardPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      questions: [],
      questionErrors: [],
      questionFormIsComplete: false,
      loadingPlayersInAction: false,
      modalDeleteActionIsOpen: false,
      playersInAction: [],
      formData: {
        actionTitle: '',
        callToAction: '',
        startDate: '',
        quizChoice: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        callActionText: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        // startDateVisible: true,
        rulesOfAction: '',
        landingPagePreviewPoint: '',
        leadRegistration: '',
        salesConversion: '',
        conversionType: '',
        scopeFieldName: '',
        selectedStates: [],
        selectedCities: [],
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
    // await this.getPlayers(params.actionId);
  }

  async getPlayers(actionId) {
    this.setState({ loadingPlayersInAction: true, isLoading: true });

    const parameters = {
      searchFunctionality: 'getPlayersInAction',
      userType: 'client',
      actionId,
    };

    try {
      const response = await api.post(`/search`, parameters);

      if (response.data.success) {
        this.setState({
          playersInAction: response.data.data,
          loadingPlayersInAction: false,
          isLoading: false,
        });
      } else {
        this.setState({
          playersInAction: [],
          loadingPlayersInAction: false,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log('errorGetPlayers: ', error);
      this.setState({
        playersInAction: [],
        loadingPlayersInAction: false,
        isLoading: false,
      });
    }
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    const parameters = {
      searchFunctionality: 'getAction',
      userType: 'client',
      actionId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const formData = res.data.data.action;

        if (formData.scope === 'regional') {
          this.getClientRegionalList();
        }
        // else if (formData.scope === "state") {
        // 	this.getStateList();
        // }

        this.setState(
          {
            isLoading: false,
            formData,
            questions: formData.questions,
          },
          () => {
            this.validateForm();
          }
        );
      })
      .catch((error) => {
        console.log('getActionById error:', error);
      });
  }

  updateAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info(
        this.translate('Atualizando ação. Aguarde...'),
        {
          autoClose: false,
        }
      );
      this.setState({ isLoading: true });

      const { formData, questions } = this.state;

      const { startDate, startDateTime, endDate, endDateTime } = formData;

      formData.startDateEpoch = moment(
        `${startDate} ${startDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();
      formData.endDateEpoch = moment(
        `${endDate} ${endDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();

      formData.questions = questions;
      const parameters = {
        userType: 'client',
        searchFunctionality: 'updateAction',
        actionData: formData,
      };

      api
        .post(`/actions`, parameters)
        .then((res) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Ação atualizada com sucesso!',
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            this.setState(
              {
                isLoading: false,
                formData: res.data.data,
              },
              () => {
                this.validateForm();
              }
            );
          });
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            toast.update(this.toastId, {
              render: 'Não foi possivel atualizar a ação!',
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
            this.setState({ isLoading: false });
          });
          console.log('updateAction error:', error);
        });
    });
  }

  deleteSelectedAction() {
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Excluindo ação. Aguarde...', {
        autoClose: true,
      });

      const parameters = {
        searchFunctionality: 'deleteAction',
        userType: 'client',
        actionId: this.state.formData.id,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          Notify('Publicação excluida', 'success');
          this.props.history.goBack();
        })
        .catch((error) => {
          this.setState({ loadingBenefits: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  toggleModalDeleteAction() {
    this.setState({
      modalDeleteActionIsOpen: !this.state.modalDeleteActionIsOpen,
    });
  }

  deleteAction() {
    return (
      <ModalDeleteAction
        open={this.state.modalDeleteActionIsOpen}
        close={this.toggleModalDeleteAction.bind(this)}
        deleteSelectedAction={this.deleteSelectedAction.bind(this)}
      />
    );
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    // this.setState({ formErrors: fieldValidationErrors }, () => {
    //   this.validateForm();
    // });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (!input && inputKey !== 'usersCount' && inputKey !== 'hasAwards') {
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  getClientRegionalList() {
    const { clientData } = this.context;
    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId: clientData.uid,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleDateChangeValid() {
    const { startDate, endDate, startDateTime, endDateTime, season } =
      this.state.formData;

    // Travamento entre datas da temporada e do exchange
    if (startDate !== '' && endDate !== '' && startDate > endDate) {
      this.setState({
        errorForm1: true,
        msg1: 'Início do desafio deve ser antes do fim.',
      });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block';
    } else {
      this.setState({ errorForm1: false });
      document.getElementById('handleDateChangeValid-1').className =
        'help-block hidden';
    }

    // Validando se ação começa e termina entre a temporada
    if (season.id !== false) {
      if (
        startDate !== '' &&
        season.startDate !== '' &&
        startDate <= season.startDate
      ) {
        this.setState({
          errorForm1: true,
          msg1: 'O Desafio deve começar pelo menos 1 dia após o início da Temporada.',
        });
        document.getElementById('handleDateChangeValid-1').className =
          'help-block';
      }

      if (
        endDate !== '' &&
        season.endDate !== '' &&
        endDate >= season.endDate
      ) {
        this.setState({
          errorForm1: true,
          msg3: 'O Desafio deve terminar pelo menos 1 dia antes do fim da Temporada.',
        });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block';
      } else {
        this.setState({ errorForm1: false });
        document.getElementById('handleDateChangeValid-3').className =
          'help-block hidden';
      }
    }

    // Validando Horas de Início e Fim da temporada
    if (
      startDate >= endDate &&
      startDate !== '' &&
      startDateTime !== '' &&
      endDateTime !== ''
    ) {
      const startHour = startDateTime.split(':');
      const endtHour = endDateTime.split(':');

      // Datas iguais
      switch (startDate === endDate) {
        case true:
          if (startHour[0] > endtHour[0]) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1: 'Hora final não pode ser inferior a hora inicial.',
            });
          } else if (
            startHour[0] === endtHour[0] &&
            startHour[1] >= endtHour[1]
          ) {
            this.setState({
              errorTime1: true,
              errorTimeMsg1:
                'Horário inicial deve ser inferior ao horário final.',
            });
          } else {
            this.setState({ errorTime1: false, errorTimeMsg1: '' });
          }
          break;
        default:
      }
    } else {
      this.setState({ errorTime1: false, errorTimeMsg1: '' });
    }
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epochDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epochDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      async () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: await this.phase2IsUncompleted(),
        });
      }
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={
            clientData.segmentationChoice === 'false'
              ? 'national'
              : formData.scope
          }
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">Segmentada</option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && scopeValues && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  async handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const { formData } = this.state;
    const { files } = formData;

    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    const newFiles = {
      ...files,
      [fileIndex]: data.data,
    };
    this.handleFormDataChange('files', newFiles);
    this.setState({ isUploading: false });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  veriffyIfQuestionIsIncomplete = (question) => {
    return (
      !question.answerA ||
      !question.answerB ||
      !question.answerC ||
      !question.answerD ||
      !question.ponctuation ||
      !question.question ||
      !question.rightAnswer
    );
  };

  // handleQuestionChange(e, index) {
  // 	const { name, value } = e.target;
  // 	this.changeQuestionState(index, name, value);
  // }

  handleQuestionsChange(e, index) {
    const { name, value } = e.target;
    this.changeQuestionsState(index, name, value);
  }

  changeQuestionsState = (index, name, value) => {
    this.setState(
      (prevState) => ({
        questions: {
          ...prevState.questions,
          [index]: {
            ...prevState.questions[index],
            [name]: value,
          },
        },
      }),
      () => {
        this.phase2IsUncompleted(this.state.questions);
      }
    );
  };
  renderMultipleChoice(index) {
    const { questions } = this.state;
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')} <code>*</code>
          </label>
          <input
            required
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            onChange={(e) => this.handleQuestionsChange(e, index)}
            onBlur={(e) => this.phase2IsUncompleted()}
            value={questions[index].question}
          />
        </div>
        {this.renderOption(index, 'A')}
        {this.renderOption(index, 'B')}
        {this.renderOption(index, 'C')}
        {this.renderOption(index, 'D')}
      </>
    );
  }
  renderCheckboxChoice(index) {
    const { questions } = this.state;
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')} <code>*</code>
          </label>
          <input
            required
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            onChange={(e) => this.handleQuestionsChange(e, index)}
            onBlur={(e) => this.phase2IsUncompleted()}
            value={questions[index].question}
          />
        </div>
        {this.renderOption(index, 'A')}
        {this.renderOption(index, 'B')}
        {this.renderOption(index, 'C')}
        {this.renderOption(index, 'D')}
      </>
    );
  }
  renderTextChoice(index) {
    const { questions } = this.state;
    return (
      <>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="question">
            {this.translate('Pergunta')} <code>*</code>
          </label>
          <input
            required
            className="form-control profile-placeholder"
            name="question"
            placeholder={this.translate('Escreva sua pergunta aqui...')}
            onChange={(e) => this.handleQuestionsChange(e, index)}
            onBlur={(e) => this.phase2IsUncompleted()}
            value={questions[index].question}
          />
        </div>
      </>
    );
  }

  renderQuestion = (item, index) => {
    const { formErrors, questions } = this.state;

    questions[index] = questions[index] ? questions[index] : {};
    formErrors[index] = formErrors[index] ? formErrors[index] : {};

    return (
      <form>
        <div className="col-md-9">
          <label htmlFor="levelName" className="profile-label" />
          <h4
            className="arrow-toggle clickable collapsed m-top-0"
            data-toggle="collapse"
            data-target={`#question${index}`}
            aria-expanded="false"
          >
            Pergunta {Number(index) + 1}
            {this.veriffyIfQuestionIsIncomplete(questions[index]) && (
              <span className="uncompleted-form">
                Existem campos vazios nesta seção
              </span>
            )}
          </h4>
          <div
            id={`question${index}`}
            aria-expanded="false"
            className="collapse"
          >
            <label htmlFor="">Selecione o tipo da pergunta</label>
            <select
              className="form-group profile-placeholder col-md-4"
              onChange={(e) => this.handleQuestionsChange(e, index)}
              id="choiceQuestion"
              name="choiceQuestion"
              aria-describedby="choiceQuestionHelp"
              value={questions[index].choiceQuestion}
            >
              <option value="">Selecione...</option>
              <option value="radio">Multipla escolha</option>
              <option value="text">Texto aberto</option>
              <option value="checkbox">Caixa de seleção</option>
            </select>

            {questions[index].choiceQuestion === 'radio' &&
              this.renderMultipleChoice(index)}
            {questions[index].choiceQuestion === 'checkbox' &&
              this.renderCheckboxChoice(index)}
            {questions[index].choiceQuestion === 'text' &&
              this.renderTextChoice(index)}

            <br />
            <br />
            {/* <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="question">
                {this.translate('Pergunta')} <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="question"
                placeholder={this.translate('Escreva sua pergunta aqui...')}
                onChange={e => this.handleQuestionsChange(e, index)}
                onBlur={e => this.phase2IsUncompleted()}
                value={questions[index].question}
              />
            </div>

            {this.renderOption(index, 'A')}
            {this.renderOption(index, 'B')}
            {this.renderOption(index, 'C')}
            {this.renderOption(index, 'D')}

            <label className="profile-label col-md-12" htmlFor="question">
              {this.translate('Alternativa certa')} <code>*</code>
            </label>

            <div className="col-md-12 d-flex p-0">
              {this.renderRightAnswerRadio(index, 'A')}
              {this.renderRightAnswerRadio(index, 'B')}
              {this.renderRightAnswerRadio(index, 'C')}
              {this.renderRightAnswerRadio(index, 'D')}
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="ponctuation">
                {this.translate('Pontuação')} <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="ponctuation"
                type="number"
                placeholder={this.translate('Pontuação')}
                onChange={e => {
                  this.handleQuestionsChange(e, index);
                }}
                value={questions[index].ponctuation}
              />
            </div> */}
          </div>
        </div>
      </form>
    );
  };

  renderRightAnswerRadio(questionIndex, answer) {
    const { questions } = this.state;
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          name="rightAnswer"
          className="custom-control-input"
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          onBlur={(e) => this.phase2IsUncompleted()}
          checked={questions[questionIndex].rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderQuestionForm = () => {
    const { formData } = this.state;

    formData.amountOfQuestions = formData.amountOfQuestions
      ? Number(formData.amountOfQuestions)
      : 1;

    const auxArray = {};
    for (let i = 0; i < formData.amountOfQuestions; i++) {
      auxArray[i] = i;
    }

    return (
      <div>
        <div className="row">
          <div className="form-group col-md-4 d-flex align-items-center m-0">
            <label className="profile-label m-0" htmlFor="state">
              Quantidade de questões:
            </label>
            <select
              className="form-control profile-placeholder m-10 w-25"
              onChange={(e) => this.handleUserInput(e)}
              onBlur={(e) => this.phase2IsUncompleted()}
              id="amountOfQuestions"
              name="amountOfQuestions"
              value={formData.amountOfQuestions}
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>

        {_.map(auxArray, (item, index) => this.renderQuestion(item, index))}
      </div>
    );
  };

  renderOption(questionIndex, option) {
    const { questions } = this.state;
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          required
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          onChange={(e) => this.handleQuestionsChange(e, questionIndex)}
          value={questions[questionIndex][`answer${option}`]}
          onBlur={(e) => this.phase2IsUncompleted()}
        />
      </div>
    );
  }

  renderPhase1() {
    const { formData, phase1Uncompleted, formButtonPressed } = this.state;
    const { clientData } = this.context;

    const seasonSelected = formData.season;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da publicação')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline={true}
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Título da publicação')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Responder pesquisa')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                onChange={(e) => this.handleUserInput(e)}
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                required={false}
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.videoURL}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="ponctuation">
                {this.translate('Pontuação')} <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="ponctuation"
                type="number"
                placeholder={this.translate('Pontuação')}
                onChange={(e) => {
                  this.handleUserInput(e);
                }}
                value={this.state.formData.ponctuation}
              />
            </div>

            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  disabled
                  placeholder={this.translate('loading')}
                  required
                >
                  <option value="regional" selected>
                    {formData.season
                      ? formData.season.seasonName
                      : 'Não definido'}
                  </option>
                </select>
                {seasonSelected && (
                  <strong className="help-block" id="showDateSeason">
                    {`Início: ${moment(seasonSelected.startDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )} - Fim: ${moment(seasonSelected.endDateEpoch).format(
                      'DD/MM/YYYY HH:mm'
                    )}`}
                  </strong>
                )}
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' && this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.renderStateSelection()}
                  {this.renderCitySelection()}
                </>
              )}
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.files &&
              formData.files.image1 &&
              this.renderImagePreview(formData.files.image1)}
            <input
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={async (event) => {
                await this.handleUserImage(event, `Actions/`, 'image1');
              }}
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled={
                    _.isEmpty(formData.season) &&
                    clientData.isGamified !== 'false'
                  }
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDate}
                />

                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled={
                    _.isEmpty(formData.season) &&
                    clientData.isGamified !== 'false'
                  }
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.startDateTime}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled={
                    _.isEmpty(formData.season) &&
                    clientData.isGamified !== 'false'
                  }
                  required
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  onChange={(e) => this.handleDateChange(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDate}
                />
                <span
                  style={{ color: 'red' }}
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled={
                    _.isEmpty(formData.season) &&
                    clientData.isGamified !== 'false'
                  }
                  required
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  onChange={(e) => this.handleUserInput(e)}
                  onBlur={() => this.handleDateChangeValid()}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>

              <div className="form-group points-by-share col-md-12 mt-5">
                <label className="profile-label" htmlFor="countDownVisible">
                  {this.translate(
                    'Exibir cronômetro regressivo para o término do desafio?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="countDownVisible"
                  />
                </label>
                <ReactTooltip
                  id="countDownVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('countDownVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="countDownVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.countDownVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="countDownVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.countDownVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="shareButtonsVisible">
                  {this.translate(
                    'Exibir botões de compartilhamento com terceiros?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="shareButtonsVisible"
                  />
                </label>
                <ReactTooltip
                  id="shareButtonsVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="shareButtonsVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.shareButtonsVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="shareButtonsVisible"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={
                          this.state.formData.shareButtonsVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>
              {/* AQUI ESTA A OPÇÃO PARA SABER SE O USUARIO PODE RESPONDER O QUIZ SOMENTE UMA VEZ OU NAO */}
              <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="quizChoice">
                  A pesquisa poderá ser respondida apenas uma vez? &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="quizChoice"
                  />
                </label>
                <ReactTooltip
                  id="quizChoice"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('shareButtonsVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="quizChoice"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={this.state.formData.quizChoice !== 'false'}
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="quizChoice"
                        onChange={(e) => this.handleUserInput(e)}
                        checked={this.state.formData.quizChoice === 'false'}
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className="form-group points-by-share col-md-12">
                <label className="profile-label" htmlFor="startDateVisible">
                  {this.translate(
                    'Data de início visível para o usuário no card da ação?'
                  )}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="startDateVisible"
                  />
                </label>
                <ReactTooltip
                  id="startDateVisible"
                  type="dark"
                  effect="solid"
                  multiline
                >
                  <span>{this.translate('startDateVisible-info')}</span>
                </ReactTooltip>
                <div className="form-group col-md-3">
                  <div className="profile-placeholder d-flex justify-content-between">
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value
                        name="startDateVisible"
                        onChange={e => this.handleUserInput(e)}
                        checked={
                          this.state.formData.startDateVisible !== 'false'
                        }
                      />
                      &nbsp;{this.translate('yes')}
                    </label>
                    <label className="checkbox-inline">
                      <input
                        type="radio"
                        value={false}
                        name="startDateVisible"
                        onChange={e => this.handleUserInput(e)}
                        checked={
                          this.state.formData.startDateVisible === 'false'
                        }
                      />
                      &nbsp;{this.translate('no')}
                    </label>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* <div className="form-group col-md-6">
						<label className="profile-label" htmlFor="scope">
							{this.translate("comprehensiveness")} <code>*</code>
						</label>
						<select
							className="form-control profile-placeholder"
							name="scope"
							required
							onChange={e => this.handleScopeChange(e)}
							value={formData.scope}>
							<option value="">{this.translate("select")}...</option>
							<option value="national">{this.translate("national")}</option>
							<option value="state">{this.translate("Estadual")}</option>
							<option value="regional">
								{clientData && clientData.scopeFieldName
									? clientData.scopeFieldName
									: this.translate("Unidade")}
							</option>
						</select>
					</div> */}
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    // const { phase2Uncompleted, formButtonPressed } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Questões
          {/* {phase2Uncompleted && formButtonPressed && this.renderUncompletedText()} */}
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            {this.renderQuestionForm()}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        {this.renderPhase1()}
        {this.renderPhase2()}
        <div className="row col-md-12 justify-content-center d-flex">
          <div className="col-md-6">
            <button
              type="submit"
              disabled={
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.isLoading
                // this.state.errorForm1 ||
                // this.state.errorTime1 ||
                // this.state.questionFormIsComplete
              }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  // phase1Uncompleted: this.phase1IsUncompleted(),
                  // phase2Uncompleted: this.phase2IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
              style={{ marginBottom: '36px' }}
            >
              {this.translate('Salvar publicação')}
            </button>
          </div>
        </div>
        {this.renderRemoveAction()}
      </div>
    );
  }

  renderRemoveAction() {
    return (
      <div className="col-6 text-center oq-padding-vertical">
        <span
          className="oq-primary-color clickable"
          onClick={() => {
            this.toggleModalDeleteAction();
          }}
        >
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate('Excluir publicação')}
        </span>
        {this.deleteAction()}
      </div>
    );
  }

  /*
  renderRemoveAction() {
    return (
      <div className="col-6 text-center oq-padding-vertical">
        <span className="oq-primary-color clickable" onClick={() => this.deleteAction()}>
          <i className="far fa-trash-alt" aria-hidden="true" />
          &nbsp; {this.translate("Excluir Ação")}
        </span>
      </div>
    );
  }
  */
  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive form-group actim"
        alt="action banner"
      />
    );
  }

  // renderScopeForm() {
  // 	const { scope } = this.state.formData;
  // 	const {
  // 		stateList,
  // 		regionalList,
  // 		loadingRegionalList,
  // 		loadingStateList,
  // 		regionalIsEmpty,
  // 	} = this.state;
  // 	let html;
  // 	switch (scope) {
  // 		case "state":
  // 			html = (
  // 				<div className="form-group col-md-3">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("state")} <code>*</code>
  // 					</label>
  // 					<select
  // 						className="form-control profile-placeholder"
  // 						name="scopeValue"
  // 						required
  // 						disabled={loadingStateList}
  // 						onChange={e => this.handleUserInput(e)}
  // 						value={this.state.formData.scopeValue}>
  // 						<option value="">
  // 							{loadingStateList
  // 								? this.translate("Carregando estados") + "..."
  // 								: this.translate("Selecione um estado") + "..."}
  // 						</option>
  // 						{stateList &&
  // 							_.map(stateList, state => {
  // 								return <option value={state.id}>{state.estado}</option>;
  // 							})}
  // 					</select>
  // 				</div>
  // 			);
  // 			break;
  // 		case "regional":
  // 			html = (
  // 				<div className="form-group col-md-9">
  // 					<label className="profile-label" htmlFor="scopeValue">
  // 						{this.translate("Regional")} <code>*</code>
  // 					</label>
  // 					<Select
  // 						isMulti
  // 						name="scopeValue"
  // 						placeholder={this.translate("Selecione uma regional...")}
  // 						isDisabled={loadingRegionalList}
  // 						options={_.map(regionalList, regional => {
  // 							return { value: this.normalizeString(regional), label: regional };
  // 						})}
  // 						isLoading={loadingRegionalList}
  // 						className="basic-multi-select"
  // 						defaultValue={this.state.formData.scopeValues}
  // 						onChange={e => this.handleFormDataChange("scopeValues", e)}
  // 						classNamePrefix="select"
  // 					/>
  // 					{regionalIsEmpty && (
  // 						<small id="companyNameHelp" className="form-text text-muted">
  // 							{this.translate("Este campo é obrigatório")}
  // 						</small>
  // 					)}
  // 				</div>
  // 			);
  // 			break;
  // 		default:
  // 			break;
  // 	}

  // 	return html;
  // }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return (
      !formData.actionTitle ||
      !formData.files ||
      !formData.files.image1 ||
      !formData.startDate ||
      !formData.startDateTime ||
      !formData.startDateTime ||
      !formData.endDate ||
      !formData.season
    );
  }

  phase2IsUncompleted = async (questions) => {
    // const { questions } = this.state;
    const { amountOfQuestions } = this.state.formData;

    if (_.size(questions) > amountOfQuestions) {
      // let diff = _.size(questions) - amountOfQuestions;
      // ajustedQuestions = Object.keys(questions).slice(amountOfQuestions, _.size(questions))
      // let adjustedQuestions = questions.slice(0, amountOfQuestions)
      // for (let i=diff; i<_.size(questions); i++) {
      // 	delete questions[i];
      // }
      const adjustedQuestions = [];
      _.each(questions, (question, key) => {
        if (key < amountOfQuestions) adjustedQuestions.push(question);
      });
      // this.handleFormDataChange('questions', this.state.questions)
      await this.setState({ questions: adjustedQuestions });
    }

    let questionFormIsComplete = true;
    _.each(this.state.questions, (question) => {
      if (this.veriffyIfQuestionIsIncomplete(question)) {
        questionFormIsComplete = false;
      }
    });

    this.setState({ questionFormIsComplete: !questionFormIsComplete });

    return !questionFormIsComplete;
  };

  validateLengthCallToAction(callToAction, callActionText) {
    if (callToAction && callToAction.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    if (callActionText && callActionText.length > 38) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to Action para Acessar Conteúdo : Limite de 38 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    return true;
  }

  warningLengthTitleAndSubtitle(actionTitle) {
    if (actionTitle && actionTitle.length > 72) {
      toast.warn(
        'Título da publicação: Você ultrapassou o limite de 72 caracteres.',
        {
          type: toast.TYPE.WARNING,
          autoClose: true,
        }
      );
    }
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (
      !this.validateLengthCallToAction(
        formData.callToAction,
        formData.callActionText
      )
    ) {
      return;
    }

    this.warningLengthTitleAndSubtitle(formData.actionTitle);

    // if (this.validateScope()) {
    this.updateAction();
    // }
  }

  validateScope() {
    const { scope, scopeValues } = this.state.formData;
    if (scope === 'regional' && (!scopeValues || !scopeValues.length)) {
      this.handleFormDataChange('scopeValue', null);
      this.setState({ regionalIsEmpty: true });
      return false;
    }

    return true;
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderPlayersTable() {
    const { loadingPlayersInAction, formData } = this.state;
    const data = _.values(this.state.playersInAction);
    const actionId = formData && formData.id ? formData.id : '';

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="image">
            {this.translate('image')}
            <ReactTooltip
              id="image"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('image')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'photoURL',
        accessor: 'photoURL',
        width: 80,
        Cell: (props) => (
          <img
            src={props.value}
            className="img-responsive img-rounded"
            // style={{ width: '100%', height: 'auto' }}
            style={{
              width: '40px',
              height: '40px',
              border: '1px solid grey',
              borderRadius: '20px',
              objectFit: 'cover',
            }}
            alt="photo player"
          />
        ),
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'name',
        accessor: 'name',
        Cell: (props) => <span className="text-center">{props.value}</span>,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('Nota NPS')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Nota NPS')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'voteNps',
        accessor: 'voteNps',
        Cell: (props) => (
          <span className="text-center">
            {props.value ? Number(props.value) : 0} pontos
          </span>
        ),
      },
      {
        id: 'viewDetails',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <Link
              to={`${routes.PLAYER_DETAIL_RESEARCH_AWARD}/${props.value}/${actionId}`}
              className="btn btn-oq btn-sm btn-block"
            >
              {this.translate('Mais informações')}
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-calendar-o" />
            &nbsp; {this.translate('Players')}
          </span>

          <button
            style={{ marginRight: '10px' }}
            className="btn btn-oq pull-right"
            onClick={() => this.getPlayers(this.state.formData.id)}
            disabled={
              loadingPlayersInAction ||
              this.state.isUploading ||
              this.state.isLoading
            }
          >
            <i className="fa fa-search" aria-hidden="true"></i>
            Buscar Players
          </button>

          {this.state.playersInAction.length > 0 && (
            <>
              <div className="oq-line" />
              <div style={{ paddingTop: 10 }}>
                <ReactTable
                  data={data}
                  defaultPageSize={5}
                  loading={loadingPlayersInAction}
                  columns={columns && columns}
                  previousText={this.translate('prev')}
                  nextText={this.translate('next')}
                  loadingText={this.translate('loading')}
                  noDataText="Nenhum player a ser exibido"
                  pageText={this.translate('pages')}
                  ofText={this.translate('of')}
                  rowsText={this.translate('lines')}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Editar Pesquisa Premiada
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
            {this.renderPlayersTable()}
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={(awardsList) =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={(extraPointsList) =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

export default EditResearchAwardPage;
