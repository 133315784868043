import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import XLSX from 'xlsx';
import { api, getAPIURL } from '../../../../../Config';

import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';
import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

class CreateActionInvite extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      flag: false,
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingSeasons: false,
      search: {},
      countSeasons: 0,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      images: [],
      formData: {
        actionTitle: '',
        actionSubtitle: '',
        files: [],
        images: [],
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },
        active: true,
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    const { clientData } = this.context;

    const season = {
      id: false,
      seasonName: 'defaultSeason',
    };
    this.handleFormDataChange('season', season);
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        scope: 'national',
      },
    }));
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      const parameters = {
        searchFunctionality: 'filterSeasons',
        userType: 'client',
        search,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.data.seasons && result.data.countSeasons) {
            this.setState({
              seasons: result.data.seasons,
              countSeasons: result.data.countSeasons,
              loadingSeasons: false,
            });
          }
          this.setState({ loadingSeasons: false });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: true }, () => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          const result = [];

          let binary = '';
          const bytes = new Uint8Array(e.target.result);
          const length = bytes.byteLength;
          for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          const oFile = XLSX.read(binary, {
            type: 'binary',
            cellDates: true,
            cellStyles: true,
          });
          oFile.SheetNames.forEach(function (sheetName) {
            const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
              header: 1,
            });
            if (roa.length) {
              result.push(roa);
            }
          });

          this.parseData(result, field);
        };

        fileReader.readAsArrayBuffer(file);
      });
      this.setState({ parsingExtraField1: false });
    }
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'extraFieldSheet1') {
          return {
            class: item[0],
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'extraFieldSheet1') {
      this.setState({ parsingExtraField1: false });
      this.handleFormDataChange(field, items);
    }
  }

  goToEditPage(actionId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_ACTION_INVITE}/${actionId}`);
  }

  createAction() {
    const { formData } = this.state;
    this.setState({ creatingAction: true }, () => {
      this.toastId = toast.info('Criando publicação. Aguarde...', {
        autoClose: false,
      });

      const { startDate, startDateTime, endDate, endDateTime } = formData;

      formData.startDateEpoch = moment(
        `${startDate} ${startDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();
      formData.endDateEpoch = moment(
        `${endDate} ${endDateTime}`,
        'YYYY-MM-DD HH:mm'
      ).valueOf();

      const { clientData } = this.context;
      if (clientData.gamificationType === 'goalsLevels') {
        //* Se a gamificação do cliente for por METAS as ações, necessariamente,
        //* o método de conversão será "Direta"
        formData.conversionType = 'direct';

        //* as conversões terão o valor fixo de 100 pontos
        formData.leadConversion = 100;
      }
      formData.actionType = 'invite';

      const parameters = {
        userType: 'client',
        searchFunctionality: 'createAction',
        actionData: formData,
      };

      api
        .post(`/actions`, parameters)
        .then((res) => {
          this.setState({ creatingAction: false }, () => {
            Notify(
              res.data[0].message,
              res.data[0].success ? 'success' : 'error'
            );
          });
          setTimeout(() => {
            this.props.history.goBack();
          }, 3000);
        })
        .catch((error) => {
          this.setState({ creatingAction: false }, () => {
            Notify(
              error.data[0].message,
              error.data[0].success ? 'success' : 'error'
            );
          });
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value && value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    const emptyFields = [];
    const { formData } = this.state;

    let noRequired = '';

    _.each(formData, (input, inputKey) => {
      if (
        !input &&
        inputKey !== 'usersCount' &&
        inputKey !== 'pointsAward' &&
        inputKey !== 'physicalAwards' &&
        inputKey !== 'callToAction' &&
        inputKey !== 'numberOfWinners' &&
        // inputKey !== 'videoURL' &&
        inputKey !== noRequired &&
        inputKey !== 'membergetmember' &&
        inputKey !== 'conversionFieldName' &&
        inputKey !== 'extraFieldName1' &&
        inputKey !== 'leadRegistration' &&
        inputKey !== 'salesConversion' &&
        inputKey !== 'scope' &&
        inputKey !== 'shareButtonsVisible' &&
        inputKey !== 'qrCodeButtonsVisible' &&
        inputKey !== 'conversionType' &&
        inputKey !== 'externalId' &&
        inputKey !== 'marcaId' &&
        inputKey !== 'rulesOfAction' &&
        inputKey !== 'scopeValues' &&
        inputKey !== 'membergetmemberTotalSignUp'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'salesConversion' &&
        (formData.conversionType === 'form' ||
          formData.conversionType === 'direct')
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }

      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.conversionType === ''
      ) {
        emptyFields.push('conversionType');
        emptyFieldsCount++;
      }
      if (
        inputKey === 'shareButtonsVisible' &&
        formData.shareButtonsVisible === true &&
        formData.rulesOfAction === ''
      ) {
        emptyFields.push('rulesOfAction');
        emptyFieldsCount++;
      }

      if (
        !input &&
        inputKey === 'leadRegistration' &&
        formData.conversionType === 'form'
      ) {
        emptyFields.push(inputKey);
        emptyFieldsCount++;
      }
    });

    this.setState({ formValid: emptyFieldsCount === 0 ? true : false });
  }

  str2bool(value) {
    if (value && typeof value === 'string') {
      if (value.toLowerCase() === 'true') return true;
      if (value.toLowerCase() === 'false') return false;
    }
    return value;
  }
  onRadioChange(e) {
    this.handleFormDataChange(e.target.name, this.str2bool(e.target.value));
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleIconsShare(e) {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        iconsShare: {
          ...prevState.formData.iconsShare,
          [name]: value,
        },
      },
    }));
  }

  // HERE

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
        });
      }
    );
  }

  handleSeasonChange(e) {
    const { value } = e.target;
    const { seasons } = this.state;
    const {
      endDate,
      endDateEpoch,
      startDate,
      startDateEpoch,
      startDateTime,
      endDateTime,
    } = this.state.formData;
    const actionSeason = _.find(seasons, (season) => {
      return season.id === value;
    });

    this.handleFormDataChange('season', actionSeason);
    if (
      endDate !== '' ||
      endDateEpoch !== '' ||
      startDate !== '' ||
      startDateEpoch !== '' ||
      startDateTime !== '' ||
      endDateTime !== ''
    ) {
      this.handleFormDataChange('endDate', '');
      this.handleFormDataChange('endDateEpoch', '');
      this.handleFormDataChange('startDate', '');
      this.handleFormDataChange('startDateEpoch', '');
      this.handleFormDataChange('startDateTime', '');
      this.handleFormDataChange('endDateTime', '');

      this.setState({ errorForm1: false });
    }
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  /*Para adicionar o input com verificação de tamanho INICIO */
  async handleUserImage(e, ref, fileIndex) {
    const { name, size } =
      e.target.files && e.target.files.length > 0 && e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));
      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccessImage(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccessImage(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.setState((oldState) => ({
      formData: {
        ...oldState.formData,
        images: [{ url: data.data }],
      },
      isUploading: false,
    }));
  }
  /*Para adicionar o input com verificação de tamanho FIM */

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderPhase1() {
    const { formData, phase1Uncompleted, formButtonPressed, seasons } =
      this.state;
    const { clientData } = this.context;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable "
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="true"
        >
          Informações básicas
          {phase1Uncompleted &&
            formButtonPressed &&
            this.renderUncompletedText()}
        </h4>
        <div id="phase1" aria-expanded="true" className="collapse show">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                Título do convite <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Product Name')}
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                Subtítulo do convite <code>*</code>
              </label>
              <input
                required
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                onChange={(e) => this.handleUserInput(e)}
                value={formData.actionSubtitle}
              />
            </div>
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="startDate">
                {this.translate('status')} <code>*</code>
              </label>
              <select
                className="form-control profile-placeholder"
                name="active"
                onChange={(e) => this.handleScopeChange(e)}
                value={formData.active}
              >
                <option value>{this.translate('active')}</option>
                <option value={false}>{this.translate('inactive')}</option>
              </select>
            </div>
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label w-100" htmlFor="image1">
              {this.translate('image')}
              {formData.videoURL ? '' : <code>*</code>}
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate('ideal-resolution')} 680 píxeis x 340 píxeis.
                  <br />
                  {this.translate('keeping-proportion')}
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.images
              ? formData.images.map((image) =>
                  this.renderImagePreview(image.url)
                )
              : null}
            <input
              required
              type="file"
              accept="image/*"
              id="profilePic"
              onChange={async (event) => {
                await this.handleUserImage(event, `Actions/`, 'images');
              }}
            />

            <span className="span-oq">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderIconsShare() {
    const { formData } = this.state;
    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#inconsShare"
          aria-expanded="false"
        >
          Ícones de compartilhamento
        </h4>
        <div id="inconsShare" aria-expanded="false" className="collapse">
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="whatsapp">
              Whatsapp &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="whatsapp"
              />
            </label>
            <ReactTooltip id="whatsapp" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="whatsapp"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.whatsapp === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="whatsapp"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.whatsapp !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="facebook">
              Facebook &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="facebook"
              />
            </label>
            <ReactTooltip id="facebook" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="facebook"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.facebook === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="facebook"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.facebook !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="telegram">
              Telegram &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="telegram"
              />
            </label>
            <ReactTooltip id="telegram" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="telegram"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.telegram === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="telegram"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.telegram !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="twitter">
              Twitter &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="twitter"
              />
            </label>
            <ReactTooltip id="twitter" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="twitter"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.twitter === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="twitter"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.twitter !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="pinterest">
              Pinterest &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="pinterest"
              />
            </label>
            <ReactTooltip id="pinterest" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="pinterest"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.pinterest === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="pinterest"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.pinterest !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="copyLink">
              Copiar link &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="copyLink"
              />
            </label>
            <ReactTooltip id="copyLink" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="copyLink"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.copyLink === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="copyLink"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.copyLink !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="instagram">
              Instagram &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="instagram"
              />
            </label>
            <ReactTooltip id="instagram" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="instagram"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.instagram === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="instagram"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.instagram !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="email">
              Email &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="email"
              />
            </label>
            <ReactTooltip id="email" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="email"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.email === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="email"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.email !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="linkedin">
              Linkedin &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="linkedin"
              />
            </label>
            <ReactTooltip id="linkedin" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="linkedin"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.linkedin === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="linkedin"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={this.state.formData.iconsShare.linkedin !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div className="row points-by-share col-md-6">
            <label className="profile-label" htmlFor="messenger">
              Messenger &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="messenger"
              />
            </label>
            <ReactTooltip id="messenger" type="dark" effect="solid" multiline>
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-8">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="messenger"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.messenger === 'true'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="messenger"
                    onChange={(e) => this.handleIconsShare(e)}
                    checked={
                      this.state.formData.iconsShare.messenger !== 'true'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderGoalLevelsFields() {
    return (
      <div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectText">
            {this.translate('Call to Action1')} <code>*</code> &nbsp;
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="callToAction"
            />
            <ReactTooltip
              id="callToAction"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('call-to-action2-text1')}</span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectText"
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.btnDirectText}
            placeholder="Call to action"
          />
        </div>
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="btnDirectRedirectLink">
            {this.translate('Call to action 1 (Link de redirecionamento)')}
            <code>*</code>
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="btnDirectRedirectLink"
            />
            <ReactTooltip
              id="btnDirectRedirectLink"
              type="dark"
              effect="solid"
              multiline
            >
              <span>
                {this.translate(
                  'Link que será vinculado ao botão configurado anteriormente. Ao'
                )}
                <br />
                {this.translate(
                  'clicar o lead será redirecionado para o link informado nesse'
                )}
                <br />
                {this.translate('campo.')}
                <br />
              </span>
            </ReactTooltip>
          </label>
          <input
            required
            type="text"
            className="form-control profile-placeholder"
            name="btnDirectRedirectLink"
            onChange={(e) => this.handleUserInput(e)}
            value={this.state.formData.btnDirectRedirectLink}
            placeholder="Link de redirecionamento"
          />
        </div>
      </div>
    );
  }

  renderForm() {
    const { shareButtonsVisible } = this.state.formData;
    return (
      <div className="container-fluid ">
        {this.renderPhase1()}

        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="button"
              className="btn btn-oq-black btn-block btn-oq-lg"
              onClick={() => this.openPreviewPage()}
              disabled={this.state.creatingAction || this.state.isUploading}
            >
              {this.translate('Preview da publicação')}
            </button>
          </div> */}
          <div className="col-md-6" style={{ paddingBottom: '3%' }}>
            <button
              type="submit"
              disabled={
                !this.state.formValid ||
                this.state.creatingAction ||
                this.state.isUploading ||
                this.state.errorForm1 ||
                this.state.errorTime1
              }
              onClick={() => {
                this.setState({
                  formButtonPressed: true,
                  phase1Uncompleted: this.phase1IsUncompleted(),
                });
              }}
              className="btn btn-oq btn-oq-lg btn-block"
            >
              Salvar convite
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        key={img}
        src={img}
        className="img-thumbnail img-responsive "
        alt="action banner"
      />
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  handleStateChange(e) {
    this.handleFormDataChange('selectedStates', e);

    this.setState({ cityList: [] }, () => this.getCityList(e));
  }

  handleCityChange(e) {
    this.handleFormDataChange('selectedCities', e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          defaultValue={selectedCities}
          onChange={(e) => this.handleCityChange(e)}
          classNamePrefix="select"
        />
        {/* {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )} */}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  phase1IsUncompleted() {
    const { formData } = this.state;

    return !formData.actionTitle || !formData.files;
  }

  validateLengthCallToAction(callToAction, btnDirectText) {
    if (callToAction && callToAction.length > 18) {
      this.setState({ creatingAction: false }, () => {
        toast.error(
          'Call to action para participação da publicação: Limite de 18 caracteres.',
          {
            type: toast.TYPE.ERROR,
            autoClose: true,
          }
        );
      });
      return false;
    }

    if (btnDirectText && btnDirectText.length > 18) {
      this.setState({ creatingAction: false }, () => {
        toast.error('Call to Action1: Limite de 18 caracteres.', {
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return false;
    }

    return true;
  }

  handleFormSubmit(e) {
    const { formData } = this.state;
    e.preventDefault();

    if (
      !this.validateLengthCallToAction(
        formData.callToAction,
        formData.btnDirectText
      )
    ) {
      return;
    }

    // if (this.validateScope())
    this.createAction();
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back menu-link oq-margin-bottom"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fas fa-arrow-left" aria-hidden="true" /> &nbsp;
          <span className="sidebarText">Voltar</span>
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;

    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span>&nbsp; {this.translate('Nova publicação')}</span>
              </h1>
            </div>
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              {this.renderForm()}
            </form>
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={(awardsList) =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={(extraPointsList) =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

export default CreateActionInvite;
