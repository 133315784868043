import React from 'react';
import ReactDOM from 'react-dom';

//! REACT TABLE
import 'react-table/react-table.css';

//! Bootstrap imports
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './CSS/oq.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-dynamic-swiper/lib/styles.css';
import './CSS/react-table-custom.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-toastify/dist/ReactToastify.css';
import './CSS/slider-custom.css';
import './CSS/animation.css';
import './CSS/animate.css';
import './CSS/dashboard.css';
import './CSS/login.css';
import './CSS/custom-modal.css';
import './CSS/custom-buttons.css';
import './CSS/custom-default.css';

import App from './Config/App';
import * as registerServiceWorker from './registerServiceWorker';
import swConfig from './swConfig';

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker.register(swConfig);
