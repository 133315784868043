import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import SignInAdminPage from '../../../AdminMaster/SignIn';
import { SignOut } from '../../../AdminMaster/SignOut';
import PasswordForgetAdminPage from '../../../AdminMaster/PasswordForget';
import NotFoundPage from '../../NotFound';

import * as routes from '../../../routes';

function WithoutAuth(props) {
  return (
    <Switch>
      <Route
        exact
        path={routes.LOGOUT_ADMIN}
        component={() => <SignOut {...props} />}
      />
      <Route
        exact
        path={routes.SIGN_IN_ADMIN}
        component={() => <SignInAdminPage {...props} />}
      />
      <Route
        exact
        path={routes.PASSWORD_FORGET_ADMIN}
        component={() => <PasswordForgetAdminPage {...props} />}
      />

      <Route component={(props) => <NotFoundPage {...props} />} />
    </Switch>
  );
}

export default withRouter(WithoutAuth);
