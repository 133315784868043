import React from 'react';
import { ReactNode, useState } from 'react';
import styles from './style.module.css';

export function List({ children }) {
  return (
    <>
      <ul className={styles.list}>{children}</ul>
    </>
  );
}

export function ListItem({
  image,
  icon,
  colorIcon,
  styleIcon,
  content,
  secondaryContent,
  expansible,
  contentExpansible,
  withRange,
  rangeChecked,
  cursorPointer,
  inputType,
  classStyle,
  handleChange,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleExpansible(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  return (
    <li
      className={`${styles.listItem} ${
        cursorPointer ? styles.cursorTrue : ''
      } ${classStyle ? classStyle : ''}`}
      {...rest}
    >
      <div className={styles.content}>
        <span>
          {withRange && (
            <input
              onChange={handleChange}
              checked={rangeChecked}
              className="me-3"
              type={inputType ? inputType : 'radio'}
              name=""
              id=""
            />
          )}
          {image && <img className="me-3" src={image} alt={image} />}
          {icon && (
            <span
              className={`${styles.iconListItem} me-3`}
              style={{ backgroundColor: colorIcon ? colorIcon : '' }}
            >
              <i
                className={`${
                  styleIcon && styleIcon === 'outlined'
                    ? 'material-icons-outlined'
                    : 'material-icons'
                }`}
                style={{ color: colorIcon ? '#fff' : '' }}
              >
                {icon}
              </i>
            </span>
          )}
          {<span>{content}</span>}
        </span>
        {!expansible && <span>{secondaryContent}</span>}
        {expansible && (
          <div className="d-flex align-items-center">
            <span>{secondaryContent}</span>
            <button className="btn rounded-circle" onClick={handleExpansible}>
              <i className="material-icons-outlined ms-3 color-text">
                {!isOpen ? 'expand_more' : 'expand_less'}
              </i>
            </button>
          </div>
        )}
      </div>
      {expansible && isOpen && (
        <div className={`${styles.contentExpansible} mt-3 p-3`}>
          {contentExpansible}
        </div>
      )}
    </li>
  );
}
