import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import ToggleButton from 'react-toggle-button';
import _ from 'underscore';
import { Notify } from '../../../../../components/Notify';
import { api } from '../../../../../Config';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { List, ListItem } from '../../../../components/List';
import { UserDataContext } from '../../../../../hooks/useUserData';

import avatarImg from '../../../../../Assets/Images/avatar.png';

class PlayerDetailsQuiz extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      loadingPlayer: true,
      loadingPlayers: false,
      value: false,
      playerInAction: {},
      player: {},
      actions: [],
      actionsCount: 0,
      playerId: null,
      loadingActions: false,
      updatingPlayer: false,
    };

    this.translate = props.t;
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.setState({ playerId: params.playerId });
    await this.getPlayerById(params.playerId);
    await this.getPlayerDetailsQuiz(params.playerId, params.actionId);
  }

  async getPlayerDetailsQuiz(playerId, actionId) {
    this.setState({ loadingPlayerInAction: true });

    const parameters = {
      searchFunctionality: 'getDetailsPlayerInAction',
      userType: 'client',
      playerId,
      actionId,
    };

    try {
      const response = await api.post(`/search`, parameters);

      if (response.data.success) {
        this.setState({
          playerInAction: response.data.data,
          loadingPlayerInAction: false,
        });
      } else {
        this.setState({ playerInAction: [], loadingPlayerInAction: false });
      }
    } catch (error) {
      console.log('getPlayerDetailsQuiz', error);
      this.setState({ playerInAction: [], loadingPlayerInAction: false });
    }
  }

  async getPlayerById(playerId) {
    const { clientData } = this.context;
    this.setState({ loadingPlayer: true });

    const parameters = {
      searchFunctionality: 'getPlayer',
      userType: 'client',
      playerId,
      clientId: clientData && clientData.uid,
    };

    const res = await api.post(`/search`, parameters);

    const result = res.data;

    if (!result.success) {
      Notify(result.message, result.success ? 'success' : 'error');
      return;
    }

    Notify(result.message, result.success ? 'success' : 'error');

    // this.setState({
    //   formData: {
    //     ...this.state.formData,
    //     ...result.data.player,
    //   },
    // });

    const { player } = result.data;

    this.setState({ player, loadingPlayer: false });
  }

  normalizeString(str) {
    return str
      .replace(/ /g, '_')
      .replace('?', '')
      .normalize('NFD') // Formato de Normalização Canônico de Decomposição.
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  flatten(arr) {
    return arr
      ? arr.reduce(
          (result, item) => [
            ...result,
            { id: item.id, text: item.text },
            ...this.flatten(item.children),
          ],
          []
        )
      : [];
  }

  getPlayerColor(points) {
    const totalPoints =
      points &&
      points.extraPoints +
        points.leadRegistration +
        points.membergetmember +
        points.membergetmembergest +
        points.salesConversion;
    let color = '#000000';

    if (totalPoints < 999) {
      color = 'yellow';
    } else if (totalPoints < 4999) {
      color = 'orange';
    } else if (totalPoints < 9999) {
      color = 'blue';
    } else if (totalPoints < 19999) {
      color = 'green';
    } else if (totalPoints < 49999) {
      color = 'purple';
    } else {
      color = 'darkred';
    }

    return color;
  }

  renderProfilePicDetails() {
    const { player } = this.state;
    return (
      <div>
        <div className="oq-profile">
          <img
            src={(player && player.photoURL) || avatarImg}
            className="oq-profile-picture new-height"
            alt="player logo"
          />
          <div className="teste" style={{ position: 'relative', right: '5%' }}>
            <svg
              className="oq-profile-trophy oq-profile-trophy-player-details"
              width="30px"
              height="30px"
              viewBox="0 0 22.529 25.012"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              data-tip="React-tooltip"
              data-for="pontuationRules"
              style={{
                padding: 4,
                backgroundColor: this.getPlayerColor(player.points),
              }}
            >
              <defs />
              <g transform="translate(-445.444 -679.25)">
                <path
                  className="stroke"
                  fill="#fff"
                  d="M462.77,703.512H450.646a.961.961,0,0,1-.67-1.65l1.687-1.64a7.239,7.239,0,0,1,5.045-2.048h0a7.24,7.24,0,0,1,5.046,2.048l1.686,1.64A.961.961,0,0,1,462.77,703.512Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M456.708,693.871h0a7.606,7.606,0,0,1-7.606-7.607V680h15.213v6.264A7.607,7.607,0,0,1,456.708,693.871Z"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M448.509,687.237a2.315,2.315,0,0,1-2.315-2.315h0a2.314,2.314,0,0,1,2.315-2.315"
                />
                <path
                  className="stroke"
                  fill="#fff"
                  d="M464.908,682.607a2.314,2.314,0,0,1,2.315,2.315h0a2.315,2.315,0,0,1-2.315,2.315"
                />
                <line
                  className="stroke"
                  y2="2.883"
                  transform="translate(456.708 694.863)"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="oq-box">
          <div className="oq-points-box">
            <span className="oq-points">
              {player && player.totalPoints ? player.totalPoints : 0}
            </span>
            <br />
            <span className="oq-points-desc">Pontos</span>
          </div>
        </div>
        <div className="oq-status-box row">
          <div className="col-md-4 col-md-offset-4 oq-status-box-switch">
            <ToggleButton
              disabled
              inactiveLabel={<i className="fa fa-times" aria-hidden="true" />}
              activeLabel={<i className="fa fa-check" aria-hidden="true" />}
              value={player.active}
              trackStyle={{ width: '100%' }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPlayerDetailsQuiz() {
    const { playerInAction } = this.state;
    const questions =
      playerInAction && playerInAction.durationQuizPlayer
        ? playerInAction.durationQuizPlayer
        : [];

    return (
      <div>
        {questions && questions.length > 0 ? (
          _.map(questions, (itemTry, indexTry) =>
            this.renderQuestions(itemTry, indexTry, questions)
          )
        ) : (
          <div>
            <span>Sem dados</span>
          </div>
        )}
      </div>
    );
  }

  renderQuestions(itemTry, indexTry, questions) {
    return (
      <>
        <List>
          <ListItem
            content={`Tentativa ${Number(indexTry) + 1}`}
            expansible
            contentExpansible={_.map(itemTry, (valueQuestion, indexQuestion) =>
              this.renderAnswers(valueQuestion, indexQuestion, indexTry)
            )}
          />
        </List>
      </>
      // <div className="col-md-12">
      //   <label htmlFor="levelName" className="profile-label" />
      //   <h4
      //     className="arrow-toggle clickable collapsed m-top-0"
      //     data-toggle="collapse"
      //     data-target={`#tentativa${indexTry}`}
      //     aria-expanded="false"
      //   >
      //     Tentativa {Number(indexTry) + 1}
      //   </h4>
      //   <div
      //     id={`tentativa${indexTry}`}
      //     aria-expanded="false"
      //     className="collapse"
      //   >
      //     {_.map(itemTry, (valueQuestion, indexQuestion) =>
      //       this.renderAnswers(valueQuestion, indexQuestion, indexTry)
      //     )}
      //   </div>
      // </div>
    );
  }

  renderAnswers(valueQuestion, indexQuestion, indexTry) {
    return (
      <List>
        <ListItem
          content={`Questão ${indexQuestion + 1}`}
          expansible
          contentExpansible={
            <>
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="question">
                  {this.translate('Pergunta')}
                </label>
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  name="question"
                  placeholder={this.translate('Escreva sua pergunta aqui...')}
                  value={valueQuestion.question}
                />
              </div>

              {/* {this.renderOption(value)} */}
              {this.renderOption(valueQuestion, 'A')}
              {this.renderOption(valueQuestion, 'B')}
              {this.renderOption(valueQuestion, 'C')}
              {this.renderOption(valueQuestion, 'D')}

              <label className="profile-label col-md-12" htmlFor="question">
                {this.translate('Alternativa certa')}
              </label>

              <div className="col-md-12 d-flex p-0">
                {/* {this.renderRightAnswerRadio(value)} */}
                {this.renderRightAnswerRadio(valueQuestion, 'A')}
                {this.renderRightAnswerRadio(valueQuestion, 'B')}
                {this.renderRightAnswerRadio(valueQuestion, 'C')}
                {this.renderRightAnswerRadio(valueQuestion, 'D')}
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="ponctuation">
                  {this.translate('Pontuação')}
                </label>
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  name="ponctuation"
                  type="number"
                  placeholder={this.translate('Pontuação')}
                  value={valueQuestion.ponctuation}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="choosenAnswer">
                  {this.translate('Selecionado')}
                </label>
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  name="choosenAnswer"
                  placeholder={this.translate('Alternativa escolhida')}
                  value={valueQuestion.choosenAnswer}
                />
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="duration">
                  {this.translate('Duração')}
                </label>
                <input
                  readOnly
                  className="form-control profile-placeholder"
                  name="duration"
                  placeholder={this.translate('Duração')}
                  value={`${valueQuestion.duration}s`}
                />
              </div>
            </>
          }
        />
      </List>
    );
  }

  // renderOption(value) {
  //   return (
  //     <>
  //       <div className="form-group col-md-10 d-flex">
  //         <div className="answer-square">
  //           <p className="answer-letter">A</p>
  //         </div>
  //         <input
  //           readOnly
  //           className="form-control profile-placeholder"
  //           name={`answerA`}
  //           placeholder={this.translate('Escreva a alternativa aqui...')}
  //           value={value.answerA}
  //         />
  //       </div>

  //       <div className="form-group col-md-10 d-flex">
  //         <div className="answer-square">
  //           <p className="answer-letter">B</p>
  //         </div>
  //         <input
  //           readOnly
  //           className="form-control profile-placeholder"
  //           name={`answerB`}
  //           placeholder={this.translate('Escreva a alternativa aqui...')}
  //           value={value.answerB}
  //         />
  //       </div>

  //       <div className="form-group col-md-10 d-flex">
  //         <div className="answer-square">
  //           <p className="answer-letter">C</p>
  //         </div>
  //         <input
  //           readOnly
  //           className="form-control profile-placeholder"
  //           name={`answerC`}
  //           placeholder={this.translate('Escreva a alternativa aqui...')}
  //           value={value.answerC}
  //         />
  //       </div>

  //       <div className="form-group col-md-10 d-flex">
  //         <div className="answer-square">
  //           <p className="answer-letter">D</p>
  //         </div>
  //         <input
  //           readOnly
  //           className="form-control profile-placeholder"
  //           name={`answerD`}
  //           placeholder={this.translate('Escreva a alternativa aqui...')}
  //           value={value.answerD}
  //         />
  //       </div>
  //     </>
  //   );
  // }

  renderOption(value, option) {
    return (
      <div className="form-group col-md-10 d-flex">
        <div className="answer-square">
          <p className="answer-letter">{option}</p>
        </div>
        <input
          required
          className="form-control profile-placeholder"
          name={`answer${option}`}
          placeholder={this.translate('Escreva a alternativa aqui...')}
          value={value[`answer${option}`]}
        />
      </div>
    );
  }

  renderRightAnswerRadio(value, answer) {
    return (
      <div className="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          id={answer}
          value={answer}
          className="custom-control-input"
          checked={value.rightAnswer === answer}
        />
        <label className="custom-control-label">{answer}</label>
      </div>
    );
  }

  renderBackButton() {
    return (
      <button
        type="button"
        className="btn btn-oq-back"
        onClick={() => this.props.history.goBack()}
      >
        <i className="fa fa-arrow-left" aria-hidden="true" />
        &nbsp; {this.translate('back')}
      </button>
    );
  }

  numberWithCommas(x) {
    const decimalComma = x.replace('.', ',');
    const parts = decimalComma.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return parts.join('.');
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="mainSection">
              <div className="row">{this.renderBackButton()}</div>
              <h1 className="row oq-title">
                {this.translate('Detalhes do usuário')}
              </h1>
              <div className="row">
                <div className="col-md-3">{this.renderProfilePicDetails()}</div>
                <div className="col-md-9">{this.renderPlayerDetailsQuiz()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerDetailsQuiz;
