import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Color from 'color';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { SketchPicker, SwatchesPicker } from 'react-color';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import Select, { components } from 'react-select';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { toast, ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';
import { api, getAPIURL } from '../../../Config';

import ModalBeforeUpdateAuthorizedUsers from '../../components/Modals/ConfigModals/BeforeUpdateAuthorizedUsers';
import ModalUpdateAuthorizedUsers from '../../components/Modals/ConfigModals/UpdateAuthorizedUsers';
import ModalReadTerms from '../../components/Modals/ReadTerms';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import * as mask from '../../../utils/Mascaras';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';

import loginDesktop from '../../Images/login-d.png';
import loginMobile from '../../Images/login-m.png';
import noPhoto from '../../Images/no_photo.png';
import bgCapaPlayerDesktop from '../../Images/profile_desktop.png';
import { UserDataContext } from '../../../hooks/useUserData';
import Compressor from 'compressorjs';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span style={{ fontFamily: `${props.value} Regular` }}>
          <b>({props.label})</b> - Seu texto ficará assim!
        </span>
      </components.Option>
    </div>
  );
};

const data = {
  data: [
    { id: 0, text: 'Andy' },
    {
      id: 1,
      text: 'Harry',
      children: [{ id: 2, text: 'David' }],
    },
    { id: 3, text: 'Lisa' },
  ],
};

class Company extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
    this.toastId = null;
    this.fileSelector = null;

    this.translate = props.t;
  }

  getInitialState(props) {
    const initialState = {
      modalBeforeUpdateAuthorizedUsersIsOpen: false,
      modalUpdateAuthorizedUsersIsOpen: false,
      modalReadTermsIsOpen: false,
      imageFormData: null,
      showLayout: false,
      imageSelected: false,
      formErrors: {},
      clientId: '',
      formValid: false,
      isLoading: true,
      stateList: {},
      cityList: {},
      parsingRegionals: false,
      loadingAuthorizedUsers: false,
      authorizedUsers: [],
      wrongUsers: [],
      updating: false,
      selectedOption: {
        value: 'Gotham Rounded',
        label: 'Gotham Rounded',
      },
      selectedFont: {
        value: 'Gotham Rounded',
        label: 'Gotham Rounded',
      },
      grayScale: [],
      formData: {
        companyName: '',
        buttonFilterQuiz: '',
        buttonFilterAction: '',
        companyInscription: '',
        stateInscription: '',
        cityInscription: '',
        address: '',
        country: '',
        state: '',
        city: '',
        accountableName: '',
        accountablePosition: '',
        clientEmailGestor: '',
        accountableEmail: '',
        phoneNumber: '',
        contactEmail: '',
        active: '',
        loginImage: '',
        targetPublic: '',
        primaryColor: '',
        secondaryColor: '',
        regional: [],
        // emailDomain: '',
        segmentationChoice: 'false',
        segmentationModalChoice: 'false',
        filterChoice: 'false',
        scopeFieldName: props.t('Unidade'),
        // scopeFieldText: '',
        logoURL: '',
        systemFont: 'Gotham Rounded',
        readTerms: '',
        token: '',
        scaleEnable: 'false',
        scaleStartDate: '',
        scaleEndDate: '',
        scaleDescription: '',
        scalePreset: '',
        scaleCurrent: '',
      },
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
      loadingNewToken: false,
      cursor: false,
      data,
      uploadTrue: true,
    };
    return initialState;
  }

  componentDidMount() {
    const { groupPermissions, userData, clientData } = this.context;
    const clientId = clientData.uid;

    this.generateGrayScaleColors();
    this.getStateList();
    this.getClientByUid(clientId);
    this.fileSelector = this.buildFileSelector();
  }

  onToggle = (node, toggled) => {
    const { cursor, data } = this.state;
    if (cursor) {
      this.setState(() => ({ cursor, active: false }));
    }

    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    this.setState(() => ({ cursor: node, data: { ...data } }));
  };

  getClientByUid(clientId) {
    const parameters = {
      searchFunctionality: 'getClient',
      userType: 'administrator',
      clientId: clientId,
    };

    api
      .post(`/search`, parameters)
      .then(async (res) => {
        if (res.data && res.data.data && res.data.data.state) {
          this.getCityList(res.data.data.state);
        }

        this.setState(
          {
            isLoading: false,
            clientId,
            formData: {
              ...this.state.formData,
              ...res.data.data,
            },
          },
          () => {
            Notify(res.data.message, res.data.success ? 'success' : 'error');
            this.validateForm();
          }
        );
      })
      .catch((error) => {
        console.log('getClientByUid error:', error);
      });
  }

  getCityList(estado = 31) {
    axios
      .get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
      )
      .then((city) => {
        this.setState({ cityList: city.data });
      })
      .catch((error) => {
        console.log('getCityList error:', error);
      });
  }

  getStateList() {
    axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  handleFormDataChangeImageLogin(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);

        this.setState({
          phase1Uncompleted: this.phase1IsUncompleted(),
          phase2Uncompleted: this.phase2IsUncompleted(),
          phase3Uncompleted: this.phase3IsUncompleted(),
        });
      }
    );
  }

  validateInputSize = (filename) => {
    if (filename > 1000000) {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      this.setState({
        loginImage: '',
      });
      return false;
    } else {
      return true;
    }
  };

  async handleCompressedUpload(file) {
    return new Promise((resolve, reject) => {
      try {
        let compress = new DataTransfer();
        new Compressor(file, {
          quality: 0.15,
          mimeType: 'image/jpeg',
          success: (result) => {
            compress.items.add(
              new File([result], result.name, { type: result.type })
            );
            resolve(compress.files[0]);
          },
        });
      } catch (error) {
        reject();
      }
    });
  }

  async handleUserImageLogin(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    const compressedFile = await this.handleCompressedUpload(e.target.files[0]);

    if (compressedFile.size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      await this.handleUploadSuccess(compressedFile, fileIndex, ref);
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO COMPRIMIDO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    if (name === 'scaleEnable' && value === 'false') {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          scaleStartDate: '',
          scaleEndDate: '',
          scaleDescription: '',
          scalePreset: '',
          scaleCurrent: '',
        },
      }));
    }

    this.handleFormDataChange(name, value);
  }

  getState(item) {
    return _.get(this.state, item, '');
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    switch (fieldName) {
      // case 'emailDomain':
      //   let regexString = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
      //   fieldValidationErrors.emailDomain = regexString.test(value)
      //     ? ''
      //     : this.translate('Dominio de e-mail inválido.');
      //   break;

      default:
        if (value && value.length <= 0) {
          fieldValidationErrors[fieldName] = this.translate(
            'Você precisa preencher esse campo.'
          );
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    // let emptyFieldsCount = 0;
    // _.each(this.state.formData, (input, inputKey) => {
    //   if (this.inputIsNotRequired(inputKey) && !input) {
    //     emptyFieldsCount++;
    //   }
    // });
    // this.setState({ formValid: emptyFieldsCount === 0 });
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        (inputKey === 'logoURL' && !input) ||
        (inputKey === 'favIcon' && !input) ||
        (inputKey === 'companyName' && !input) ||
        (inputKey === 'companyInscription' && !input) ||
        (inputKey === 'stateInscription' && !input) ||
        (inputKey === 'cityInscription' && !input) ||
        (inputKey === 'cep' && !input) ||
        (inputKey === 'address' && !input) ||
        (inputKey === 'neighborhood' && !input) ||
        (inputKey === 'number' && !input) ||
        // (inputKey === 'complement' && !input) ||
        (inputKey === 'state' && !input) ||
        (inputKey === 'city' && !input) ||
        (inputKey === 'country' && !input) ||
        (inputKey === 'accountableName' && !input) ||
        (inputKey === 'accountablePosition' && !input) ||
        (inputKey === 'accountableEmail' && !input) ||
        (inputKey === 'clientUrl' && !input) ||
        (inputKey === 'phoneNumber' && !input) ||
        (inputKey === 'segmentationChoice' && !input) ||
        (inputKey === 'moduleBenefits' && !input) ||
        (inputKey === 'moduleIndications' && !input) ||
        (inputKey === 'moduleEngagements' && !input) ||
        (inputKey === 'citySegmentationChoice' && !input) ||
        (inputKey === 'targetPublic' && !input) ||
        (inputKey === 'blockchainStatus' && !input) ||
        (inputKey === 'useIntegration' && !input) ||
        (inputKey === 'secondarySegmentationChoice' && !input) ||
        (inputKey === 'activeTransfeera' && !input) ||
        (inputKey === 'primaryColor' && !input) ||
        (inputKey === 'secondaryColor' && !input) ||
        (inputKey === 'contactEmail' && !input) ||
        (inputKey === 'selectedFont' && !input)
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  inputIsNotRequired(inputKey) {
    return (
      inputKey !== 'regional' &&
      inputKey !== 'buttonFilterQuiz' &&
      inputKey !== 'buttonFilterAction' &&
      inputKey !== 'scopeFieldText' &&
      inputKey !== 'scopeFieldNameButton' &&
      inputKey !== 'scopeFieldButton' &&
      inputKey !== 'playerRegisterPoints' &&
      inputKey !== 'levelAmount' &&
      inputKey !== 'token' &&
      inputKey !== 'scaleDescription' &&
      inputKey !== 'scalePreset' &&
      inputKey !== 'scaleCurrent' &&
      inputKey !== 'scaleStartDate' &&
      inputKey !== 'buttonFilterQuiz' &&
      inputKey !== 'nameSecondarySegmentation' &&
      inputKey !== 'scaleEndDate' &&
      inputKey !== 'benefitLogo' &&
      inputKey !== 'amountOfInputs' &&
      inputKey !== 'loginImage' &&
      inputKey !== 'wellcomeText' &&
      inputKey !== 'amountOfHierarchy' &&
      inputKey !== 'numberOfDaysToRedeem' &&
      inputKey !== 'enableIconsShareDashboard'
    );
  }

  updateClient() {
    if (!this.state.formValid) {
      Notify(
        'Você precisa preencher todos os dados antes de continuar.',
        'warn'
      );
      return;
    }
    const client = this.state.formData;
    const { clientId } = this.state;

    this.setState({ updating: true }, () => {
      const parameters = {
        updateFunctionality: 'updateClient',
        userType: 'administrator',
        clientId,
        client,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          this.setState({ updating: false });
          if (toast.isActive(this.toastId)) {
            toast.update(this.toastId, {
              render: response.data.message,
              type: response.data.success
                ? toast.TYPE.SUCCESS
                : toast.TYPE.ERROR,
              autoClose: 5000,
            });
          } else {
            Notify(
              response.data.message,
              response.data.success ? 'success' : 'error'
            );
          }
        })
        .catch((error) => {
          this.setState({ updating: false });
          console.log('updateClient error:', error);
        });
    });
  }

  uploadImageToFirebase() {
    const { imageFormData, imageSelected } = this.state;

    if (!imageSelected) {
      Notify('Você deve escolher uma imagem para ser usada como logo.', 'warn');
      return;
    }

    this.toastId = toast.info('Salvando imagem...', { autoClose: false });

    const parameters = imageFormData;

    api
      .post(`/upload`, parameters)
      .then((response) => {
        if (response.data.success && response.data.data.storageURL) {
          this.setState(
            (prevState) => ({
              imageFormData: null,
              imageSelected: false,
              formData: {
                ...prevState.formData,
                logoURL: response.data.data.storageURL,
              },
            }),
            () => {
              toast.update(this.toastId, {
                render: 'Atualizando usuario...',
                type: toast.TYPE.INFO,
                autoClose: false,
              });
              this.updateClient();
            }
          );
        }
      })
      .catch((error) => {
        console.log('handleUploadFile error:', error);
      });
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const epoch = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          [`${name}Epoch`]: epoch,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleColorChangeComplete = (color, field) => {
    const colorHex = color.hex;
    if (field === 'secondaryColor') {
      const grayscale = Color(colorHex).grayscale().hex();
      this.updateFormData(field, grayscale);
      return;
    }

    this.updateFormData(field, colorHex);
  };

  handleTagsChange(tags) {
    this.updateFormData('regional', tags);
  }

  handleFontChange = (selectedOption) => {
    this.setState({ selectedOption, selectedFont: selectedOption });
    this.updateFormData('systemFont', selectedOption.value);
    this.updateFormData('selectedFont', selectedOption);
  };

  toggleReadTerms() {
    this.setState({
      modalReadTermsIsOpen: !this.state.modalReadTermsIsOpen,
    });
  }

  renderReadTermsModal() {
    return (
      <ModalReadTerms
        open={this.state.modalReadTermsIsOpen}
        close={this.toggleReadTerms.bind(this)}
        confirmReadTerms={this.confirmReadTerms.bind(this)}
      />
    );
  }

  confirmReadTerms = async () => {
    await this.updateFormData('readTerms', true);
    this.updateClient();
  };

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'regional') {
      this.setState({ parsingRegionals: true });
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: true });
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });
      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    const { groupPermissions, userData, clientData } = this.context;
    const clientId = clientData.uid;

    let items = [];

    xlsxParsed.forEach((sheet, i) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'authorizedUsers') {
          return {
            name: item[0],
            cpf: item[1],
            clientId,
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'regional') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, items);
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: false, authorizedUsers: items });
      this.toggleBeforeUpdateAuthorizedUsers();
    }
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('name', 'authorizedUsers');
    fileSelector.onchange = (e) =>
      this.fileChanged(e.target.files, 'authorizedUsers');
    return fileSelector;
  }

  toggleBeforeUpdateAuthorizedUsers() {
    this.setState({
      modalBeforeUpdateAuthorizedUsersIsOpen:
        !this.state.modalBeforeUpdateAuthorizedUsersIsOpen,
    });
  }

  confirmBeforeUpdateAuthorizedUsers() {
    return (
      <ModalBeforeUpdateAuthorizedUsers
        open={this.state.modalBeforeUpdateAuthorizedUsersIsOpen}
        close={this.toggleBeforeUpdateAuthorizedUsers.bind(this)}
        updateAuthorizedUsers={this.updateAuthorizedUsers.bind(this)}
      />
    );
  }

  toggleUpdateAuthorizedUsers() {
    this.setState({
      modalUpdateAuthorizedUsersIsOpen:
        !this.state.modalUpdateAuthorizedUsersIsOpen,
    });
  }

  confirmUpdateAuthorizedUsers() {
    return (
      <ModalUpdateAuthorizedUsers
        open={this.state.modalUpdateAuthorizedUsersIsOpen}
        close={this.toggleUpdateAuthorizedUsers.bind(this)}
        fileSelector={this.fileSelector}
      />
    );
  }

  // listWrongUsers() {
  //   const { wrongUsers } = this.state;
  //   confirmAlert({
  //     customUI: ({ onClose }) => {
  //       return (
  //         <div className="oq-confirm-modal">
  //           <h4 className="oq-font-medium">
  //             Lista de CPF atualizada, porém {wrongUsers.length} destes não
  //             foram cadastrados devido o CPF estar no formato errado! Abaixo a
  //             relação deste(s):
  //           </h4>
  //           {_.map(wrongUsers, user => {
  //             return (
  //               <p className="oq-font-book" key={user.cpf}>
  //                 <b>Nome: </b>
  //                 {user.name}
  //                 <b> - CPF: </b>
  //                 {user.cpf}
  //               </p>
  //             );
  //           })}
  //           <div>
  //             <button
  //               className="btn btn-oq-black btn-oq-lg oq-margin-right"
  //               onClick={() => onClose()}
  //             >
  //               FECHAR
  //             </button>
  //           </div>
  //         </div>
  //       );
  //     },
  //   });
  // }

  updateAuthorizedUsers() {
    const { authorizedUsers } = this.state;
    Notify('Salvando usuários autorizados...', 'loading');
    this.setState({ loadingAuthorizedUsers: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'updateAuthorizedUsers',
        authorizedUsers,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({
            loadingAuthorizedUsers: false,
            wrongUsers: result.data ? result.data : [],
          });
          // if (result.data.length > 0 && result.success === false) {
          //   this.listWrongUsers();
          // }
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateAuthorizedUsers error:', error);
          this.setState({ loadingAuthorizedUsers: false });
          Notify(error.message, error.success ? 'success' : 'error');
        });
    });
  }

  toggleLayout() {
    this.setState({ showLayout: !this.state.showLayout });
  }

  async handleUploadSuccess(file, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleFormDataChangeImageLogin(fileIndex, data.data);
  }

  generateGrayScaleColors() {
    const totalColors = 255;
    const lines = 10;
    const columns = 7;
    const colorsToGen = lines * columns;
    const colorFactor = totalColors / (colorsToGen - 1);
    let colors = [];
    for (let index = 0; index < colorsToGen; index++) {
      const currrentColorIndex = parseInt(index * colorFactor);
      const currentHex = this.rgbToHex(currrentColorIndex);
      colors.push(currentHex);
    }

    // colors.push(this.rgbToHex(totalColors));
    colors = _.chunk(colors, lines);
    this.setState({ grayScale: colors });
  }

  rgbToHex(color) {
    return `#${((1 << 24) + (color << 16) + (color << 8) + color)
      .toString(16)
      .slice(1)}`;
  }

  renderImagePreview(img, id) {
    return (
      <img
        src={img}
        id={id}
        alt="client favicon"
        ref={(el) => (this.imgEl = el)}
        onLoad={() => {
          if (this.imageIsSquare(id)) {
            // Notify('O ícone da aba precisa ser quadrado.', 'warn');
            this.imgEl = null;
            // this.handleFormDataChange('favIcon', null);
          }
        }}
        className="col-xs-12 img-thumbnail img-responsive form-group"
      />
    );
  }

  imageIsSquare(id) {
    return (
      this.imgEl &&
      this.imgEl.naturalHeight &&
      id === 'favIcon' &&
      this.imgEl.naturalHeight !== this.imgEl.naturalWidth
    );
  }

  renderUncompletedText = () => {
    return (
      <span className="uncompleted-form">
        Existem campos vazios nesta seção
      </span>
    );
  };

  renderPhase1 = () => {
    const { phase1Uncompleted } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Informações Básicas
          {phase1Uncompleted && this.renderUncompletedText()}
        </h4>

        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="text-center oq-padding-vertical">
              <div className="col-md-4 col-md-offset-4">
                <label htmlFor="logoURL" className="clickable">
                  <img
                    alt="client logo"
                    src={
                      this.getState('formData.logoURL')
                        ? this.getState('formData.logoURL')
                        : noPhoto
                    }
                    className="img-responsive center-block form-group oq-padding-bottom"
                  />
                  <div className="edit-profile-pic">
                    <i className="fas fa-pencil-alt edit-profile-pic-icon" />
                  </div>
                </label>
                <p className="tipText">{`${this.translate(
                  'recommended'
                )}: 680x240px`}</p>
                <button
                  className="btn btn-oq-back text-center form-group"
                  onClick={() => this.toggleLayout()}
                >
                  {this.translate('edit-layout')}
                </button>
              </div>
            </div>
            <div className="oq-padding-vertical col-md-12">
              {this.state.showLayout && (
                <fieldset>
                  <legend>Customize a sua plataforma</legend>
                  <div className="row">
                    <div className="form-group col-xs-6">
                      <label className="profile-label">
                        Cor primária - Cor principal dos botões
                      </label>
                      <SketchPicker
                        color={this.getState('formData.primaryColor')}
                        width="100%"
                        onChangeComplete={(color) =>
                          this.handleColorChangeComplete(color, 'primaryColor')
                        }
                      />
                    </div>
                    <div className="form-group col-xs-6">
                      <label className="profile-label">
                        Cor secundária - Cor da sua barra superior
                      </label>
                      <SwatchesPicker
                        width="100%"
                        height="100%"
                        onChangeComplete={(color) =>
                          this.handleColorChangeComplete(
                            color,
                            'secondaryColor'
                          )
                        }
                        color={this.getState('formData.secondaryColor')}
                        colors={this.state.grayScale}
                      />
                    </div>
                    <div className="form-group col-xs-6">
                      <label>Escolha uma fonte</label>
                      <Select
                        closeMenuOnSelect
                        value={this.getState('formData.selectedFont')}
                        required
                        components={{ Option }}
                        placeholder="Escolha a fonte do seu sistema..."
                        onChange={(selectedOption) =>
                          this.handleFontChange(selectedOption)
                        }
                        options={[
                          {
                            value: 'Gotham Rounded',
                            label: 'Gotham Rounded',
                          },
                          {
                            value: 'Lato',
                            label: 'Lato',
                          },
                          {
                            value: 'Roboto',
                            label: 'Roboto',
                          },
                          {
                            value: 'OpenSans',
                            label: 'OpenSans',
                          },
                        ]}
                      />
                    </div>
                  </div>
                </fieldset>
              )}
            </div>
            <div className="form-group col-md-6" hidden>
              <input
                type="file"
                accept="image/*"
                id="logoURL"
                onChange={async (event) => {
                  await this.handleUserImageLogin(
                    event,
                    'ClientsLogo/default',
                    'logoURL'
                  );
                }}
              />

              {this.getError('logo') && (
                <small id="logoHelp" className="form-text text-muted">
                  {this.getError('logo')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="logo">
                Icone da aba
              </label>
              <p className="tipText">{`${this.translate(
                'recommended'
              )}: 512x512px`}</p>
              {this.getState('formData.favIcon') &&
                this.renderImagePreview(
                  this.getState('formData.favIcon'),
                  'favIcon'
                )}
              <input
                type="file"
                accept="image/*"
                id="favIcon"
                onChange={async (event) => {
                  await this.handleUserImageLogin(
                    event,
                    'ClientsFavIcon/default/',
                    'favIcon'
                  );
                }}
              />

              {/* Logo do cliente */}
              <br />
              <label className="profile-label" htmlFor="logo">
                Logo original do cliente
              </label>
              <p className="tipText">
                Utilize a logo que melhor se adequa ao programa (Light ou Dark)
              </p>
              {this.getState('formData.originalLogoClient') &&
                this.renderImagePreview(
                  this.getState('formData.originalLogoClient'),
                  'originalLogoClient'
                )}
              <input
                type="file"
                accept="image/*"
                id="originalLogoClient"
                onChange={async (event) => {
                  await this.handleUserImageLogin(
                    event,
                    'ClientsFavIcon/default/',
                    'originalLogoClient'
                  );
                }}
              />

              {/* Ativar Bot WhatsApp */}
              <br />
              <label className="profile-label" htmlFor="isActiveBotWhatsApp">
                Ativar Bot do WhatsApp?
              </label>
              <select
                className="form-control profile-placeholder"
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
                id="isActiveBotWhatsApp"
                name="isActiveBotWhatsApp"
                aria-describedby="isActiveBotWhatsAppHelp"
                value={this.getState('formData.isActiveBotWhatsApp')}
              >
                <option value="">Selecione uma opção...</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>

              {this.getState('formData.isActiveBotWhatsApp') === 'true' && (
                <>
                  {/* Logo da assitente do cliente */}
                  <br />
                  <label className="profile-label" htmlFor="logo">
                    ícone personalizado do bot
                  </label>
                  <p className="tipText">
                    Personalize o ícone do seu Bot conforme desejado.
                  </p>
                  {this.getState('formData.whatsappCustomIconPersona') && (
                    <div className="row">
                      <img
                        src={this.getState(
                          'formData.whatsappCustomIconPersona'
                        )}
                        className="col-xs-12 img-thumbnail img-responsive form-group oq-whatsapp-photo"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/*"
                    id="whatsappCustomIconPersona"
                    onChange={async (event) => {
                      await this.handleUserImageLogin(
                        event,
                        'ClientsFavIcon/default/',
                        'whatsappCustomIconPersona'
                      );
                    }}
                  />

                  {/* Nome do Bot do WhatsApp */}
                  <br />
                  <label className="profile-label" htmlFor="whatsappBotName">
                    Personalize o nome do Bot do WhatsApp
                  </label>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => this.handleUserInput(event)}
                    id="whatsappBotName"
                    name="whatsappBotName"
                    aria-describedby="whatsappBotNameHelp"
                    value={this.getState('formData.whatsappBotName')}
                    placeholder="Informe um nome para seu bot"
                  />
                </>
              )}

              {/**Coloquei isso aqui agora */}
              <div className="row" style={{ marginTop: '9%' }}>
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="logo">
                    {`${this.translate(
                      'Imagem da Tela de Login - Versão desktop'
                    )}`}
                    <br />
                    <p className="tipText">
                      O box de login já é uma área pré-estabelecida. Toda a área
                      ao fundo e ao redor dele pode ser trabalhada com imagem e
                      textos.
                    </p>
                    <img src={loginDesktop} style={{ height: 150 }} />
                  </label>

                  <p className="tipText">{`${this.translate(
                    'recommended'
                  )}: 1280x720`}</p>
                  {this.getState('formData.loginImage') &&
                    this.renderImagePreview(
                      this.getState('formData.loginImage'),
                      'loginImage'
                    )}
                  <input
                    type="file"
                    accept="image/*"
                    id="loginImage"
                    onChange={async (event) => {
                      await this.handleUserImageLogin(
                        event,
                        'ClientsFavIcon/default/',
                        'loginImage'
                      );
                    }}
                  />
                </div>
              </div>
              {/**Coloquei isso aqui agora fim */}
              {this.getError('favIcon') && (
                <small id="favIconHelp" className="form-text text-muted">
                  {this.getError('favIcon')}
                </small>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="logo">
                {`${this.translate('Imagem da Tela de Login - Versão mobile')}`}
                <br />
                <p className="tipText">
                  O box de login já é uma área pré-estabelecida. Toda a área ao
                  fundo e ao redor dele pode ser trabalhada com imagem e textos.
                </p>
                <img src={loginMobile} style={{ height: 150 }} />
              </label>

              <p className="tipText">{`${this.translate(
                'recommended'
              )}: 900x506`}</p>
              {this.getState('formData.loginImageMobile') &&
                this.renderImagePreview(
                  this.getState('formData.loginImageMobile'),
                  'loginImageMobile'
                )}
              <input
                type="file"
                accept="image/*"
                id="loginImageMobile"
                onChange={async (event) => {
                  await this.handleUserImageLogin(
                    event,
                    'ClientsFavIcon/default/',
                    'loginImageMobile'
                  );
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              {/**Coloquei isso aqui agora */}
              <div className="row" style={{ marginTop: '9%' }}>
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="logo">
                    {`${this.translate('Imagem da Capa do Player')}`}
                    <br />
                    <p className="tipText">
                      Essa é a imagem que é colada no fundo do perfil do
                      usuário.
                    </p>
                    <img src={bgCapaPlayerDesktop} style={{ height: 150 }} />
                  </label>

                  <p className="tipText">{`${this.translate(
                    'recommended'
                  )}: 1280x240`}</p>
                  {this.getState('formData.backgroundPlayerImage') &&
                    this.renderImagePreview(
                      this.getState('formData.backgroundPlayerImage'),
                      'backgroundPlayerImage'
                    )}
                  <input
                    type="file"
                    accept="image/*"
                    id="backgroundPlayerImage"
                    onChange={async (event) => {
                      await this.handleUserImageLogin(
                        event,
                        'ClientsFavIcon/default/',
                        'backgroundPlayerImage'
                      );
                    }}
                  />
                </div>
              </div>
              {/**Coloquei isso aqui agora fim */}
              {this.getError('favIcon') && (
                <small id="favIconHelp" className="form-text text-muted">
                  {this.getError('favIcon')}
                </small>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              {/**Coloquei isso aqui agora */}
              <div className="row" style={{ marginTop: '9%' }}>
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="logo">
                    Imagem de capa da Regional
                    <br />
                    <p className="tipText">
                      Essa é a imagem que é colada no fundo do perfil do
                      usuário.
                    </p>
                    <img src={bgCapaPlayerDesktop} style={{ height: 150 }} />
                  </label>

                  <p className="tipText">{`${this.translate(
                    'recommended'
                  )}: 1280x240`}</p>
                  {this.getState('formData.backgroundRegionalImage') &&
                    this.renderImagePreview(
                      this.getState('formData.backgroundRegionalImage'),
                      'backgroundRegionalImage'
                    )}
                  <input
                    type="file"
                    accept="image/*"
                    id="backgroundRegionalImage"
                    onChange={async (event) => {
                      await this.handleUserImageLogin(
                        event,
                        'ClientsFavIcon/default/',
                        'backgroundRegionalImage'
                      );
                    }}
                  />
                </div>
              </div>
              {/**Coloquei isso aqui agora fim */}
              {this.getError('favIcon') && (
                <small id="favIconHelp" className="form-text text-muted">
                  {this.getError('favIcon')}
                </small>
              )}
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="urlVideoLogin">
                Url do Vídeo
                <i
                  style={{ marginLeft: '2px' }}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="urlVideoLoginI"
                />
                <ReactTooltip
                  id="urlVideoLoginI"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Cole aqui o link do vídeo explicando como funciona a
                    Plataforma para o usuário assistir antes de se cadastrar.
                  </span>
                </ReactTooltip>
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) =>
                  this.handleUrlVideoChange(event.target.value)
                }
                id="urlVideoLogin"
                name="urlVideoLogin"
                value={
                  this.state &&
                  this.state.formData &&
                  this.state.formData.urlVideoLogin
                }
                placeholder="Url do vídeo de login"
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="companyName">
                {this.translate('company-name')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="companyName"
                name="companyName"
                required
                maxLength={25}
                aria-describedby="companyNameHelp"
                value={this.getState('formData.companyName')}
                placeholder={this.translate('company-name')}
              />
              {this.getError('companyInscription') && (
                <small id="companyNameHelp" className="form-text text-muted">
                  {this.getError('companyName')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="companyInscription">
                CNPJ
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => {
                  const input = event.target;
                  let maskCNPJ = mask.maskCNPJ(input.value);
                  this.updateFormData(input.name, maskCNPJ);
                }}
                id="companyInscription"
                maxLength={18}
                minLength={18}
                name="companyInscription"
                required
                aria-describedby="companyInscriptionHelp"
                value={this.getState('formData.companyInscription')}
                placeholder="CNPJ"
              />
              {this.getError('companyInscription') && (
                <small
                  id="companyInscriptionHelp"
                  className="form-text text-muted"
                >
                  {this.getError('companyInscription')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="stateInscription">
                {this.translate('state-subscription')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="stateInscription"
                name="stateInscription"
                aria-describedby="stateInscriptionHelp"
                value={this.getState('formData.stateInscription')}
                placeholder={this.translate('state-subscription')}
              />

              <small id="stateInscriptionHelp" className="form-text text-muted">
                {this.getError('stateInscription')}
              </small>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="cityInscription">
                {this.translate('city-subscription')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="cityInscription"
                name="cityInscription"
                aria-describedby="cityInscriptionHelp"
                value={this.getState('formData.cityInscription')}
                placeholder={this.translate('city-subscription')}
              />
              {this.getError('cityInscription') && (
                <small
                  id="cityInscriptionHelp"
                  className="form-text text-muted"
                >
                  {this.getError('cityInscription')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="cep">
                {this.translate('zip-code')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => {
                  const input = event.target;
                  mask.maskCEP(input.value).then((masked) => {
                    this.updateFormData(input.name, masked);
                  });
                }}
                id="cep"
                maxLength={9}
                name="cep"
                required
                aria-describedby="cepHelp"
                value={this.getState('formData.cep')}
                placeholder="00000-000"
              />
              {this.getError('cep') && (
                <small id="cepHelp" className="form-text text-muted">
                  {this.getError('cep')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="address">
                {this.translate('address')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="address"
                name="address"
                aria-describedby="addressHelp"
                value={this.getState('formData.address')}
                placeholder={this.translate('address')}
              />
              {this.getError('address') && (
                <small id="addressHelp" className="form-text text-muted">
                  {this.getError('address')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="neighborhood">
                {this.translate('neighborhood')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="neighborhood"
                name="neighborhood"
                aria-describedby="neighborhoodHelp"
                value={this.getState('formData.neighborhood')}
                placeholder={this.translate('neighborhood')}
              />
              {this.getError('counneighborhoodtry') && (
                <small id="neighborhoodHelp" className="form-text text-muted">
                  {this.getError('neighborhood')}
                </small>
              )}
            </div>
            <div className="form-group col-md-3">
              <label className="profile-label" htmlFor="number">
                {this.translate('number')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="number"
                name="number"
                required
                aria-describedby="numberHelp"
                value={this.getState('formData.number')}
                placeholder={this.translate('number')}
              />
              {this.getError('number') && (
                <small id="numberHelp" className="form-text text-muted">
                  {this.getError('number')}
                </small>
              )}
            </div>
            <div className="form-group col-md-3">
              <label className="profile-label" htmlFor="complement">
                {this.translate('complement')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="complement"
                name="complement"
                aria-describedby="complementHelp"
                value={this.getState('formData.complement')}
                placeholder={this.translate('complement')}
              />
              {this.getError('complement') && (
                <small id="complementHelp" className="form-text text-muted">
                  {this.getError('complement')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="state">
                {this.translate('state')}
              </label>
              <select
                className="form-control profile-placeholder"
                onChange={(event) => {
                  this.handleUserInput(event);
                  this.getCityList(event.target.value);
                }}
                id="state"
                name="state"
                aria-describedby="stateHelp"
                value={this.getState('formData.state')}
              >
                <option value="">Selecione um estado...</option>
                {this.state.stateList &&
                  _.map(this.state.stateList, (state) => {
                    return (
                      <option key={state.id} value={state.id}>
                        {state.nome}
                      </option>
                    );
                  })}
              </select>
              {this.getError('state') && (
                <small id="stateHelp" className="form-text text-muted">
                  {this.getError('state')}
                </small>
              )}
            </div>
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="city">
                {this.translate('city')}
              </label>
              <select
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="city"
                name="city"
                aria-describedby="cityHelp"
                value={this.getState('formData.city')}
              >
                <option value="">{this.translate('select-the-city')}</option>
                {this.state.cityList &&
                  _.map(this.state.cityList, (city) => {
                    return (
                      <option key={city.id} value={city.nome}>
                        {city.nome}
                      </option>
                    );
                  })}
              </select>
              {this.getError('city') && (
                <small id="cityHelp" className="form-text text-muted">
                  {this.getError('city')}
                </small>
              )}
            </div>
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="country">
                {this.translate('country')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="country"
                name="country"
                disabled
                aria-describedby="countryHelp"
                value={this.getState('formData.country')}
                placeholder={this.translate('country')}
              />
              {this.getError('country') && (
                <small id="countryHelp" className="form-text text-muted">
                  {this.getError('country')}
                </small>
              )}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  clientEmailGestor() {
    return (
      <div className="row">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="accountableEmail">
            E-mail do gestor&nbsp;
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="contactEmailGestor-i"
            />
          </label>
          <input
            className="form-control profile-placeholder"
            type="email"
            onChange={(event) => this.handleUserInput(event)}
            disabled={this.state.isLoading}
            id="clientEmailGestor"
            name="clientEmailGestor"
            aria-describedby="clientEmailGestor"
            value={
              this.state.formData && this.state.formData.clientEmailGestor
                ? this.getState('formData.clientEmailGestor')
                : ''
            }
            placeholder="E-mail"
          />
          <ReactTooltip
            id="contactEmailGestor-i"
            type="dark"
            effect="solid"
            multiline
          >
            <span>{this.translate('contact-email-gestor')}</span>
          </ReactTooltip>
          {this.getError('clientEmailGestor') && (
            <small id="clientEmailGestor" className="form-text text-muted">
              {this.getError('clientEmailGestor')}
            </small>
          )}
        </div>
      </div>
    );
  }

  renderPhase2 = () => {
    const { phase2Uncompleted } = this.state;

    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Informações de Acesso
          {phase2Uncompleted && this.renderUncompletedText()}
        </h4>

        <div id="phase2" aria-expanded="false" className="collapse">
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="accountableName">
                {this.translate('responsible-name')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="accountableName"
                name="accountableName"
                required
                aria-describedby="accountableNameHelp"
                value={this.getState('formData.accountableName')}
                placeholder={this.translate('responsible-name')}
              />
              {this.getError('accountableName') && (
                <small
                  id="accountableNameHelp"
                  className="form-text text-muted"
                >
                  {this.getError('accountableName')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="accountablePosition">
                {this.translate('role')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="accountablePosition"
                name="accountablePosition"
                aria-describedby="accountablePositionHelp"
                value={this.getState('formData.accountablePosition')}
                placeholder={this.translate('role')}
              />
              {this.getError('accountablePosition') && (
                <small
                  id="accountablePositionHelp"
                  className="form-text text-muted"
                >
                  {this.getError('accountablePosition')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="accountableEmail">
                E-mail de acesso:
              </label>
              <input
                className="form-control profile-placeholder"
                type="email"
                disabled
                id="accountableEmail"
                name="accountableEmail"
                aria-describedby="accountableEmailHelp"
                value={this.getState('formData.accountableEmail')}
                placeholder="E-mail"
              />
              {this.getError('accountableEmail') && (
                <small
                  id="accountableEmailHelp"
                  className="form-text text-muted"
                >
                  {this.getError('accountableEmail')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="phoneNumber">
                {this.translate('phone')}
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => {
                  const input = event.target;
                  mask.maskTEL(input.value).then((masked) => {
                    this.updateFormData(input.name, masked);
                  });
                }}
                id="phoneNumber"
                name="phoneNumber"
                maxLength={15}
                aria-describedby="phoneNumberHelp"
                value={this.getState('formData.phoneNumber')}
                placeholder={this.translate('phone')}
              />
              {this.getError('phoneNumber') && (
                <small id="phoneNumberHelp" className="form-text text-muted">
                  {this.getError('phoneNumber')}
                </small>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="contactEmail">
                E-mail para contato&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="contactEmail-i"
                />
              </label>
              <ReactTooltip
                id="contactEmail-i"
                type="dark"
                effect="solid"
                multiline
              >
                <span>{this.translate('contact-email-info')}</span>
              </ReactTooltip>
              <input
                className="form-control profile-placeholder"
                onChange={(event) => {
                  this.handleUserInput(event);
                }}
                type="email"
                id="contactEmail"
                name="contactEmail"
                aria-describedby="contactEmailHelp"
                value={this.getState('formData.contactEmail')}
                placeholder="faleconosco@empresa.com.br"
              />
              {this.getError('contactEmail') && (
                <small id="contactEmailHelp" className="form-text text-muted">
                  {this.getError('contactEmail')}
                </small>
              )}
            </div>
          </div>

          {this.clientEmailGestor()}

          <div className="row d-flex ">
            <div className="form-group col-md-5">
              <label className="profile-label" htmlFor="phoneNumber">
                URL
              </label>
              <input
                className="form-control profile-placeholder"
                disabled
                id="productionURL"
                name="productionURL"
                aria-describedby="productionURLHelp"
                value={`https://app.oqdigital.com/_/${this.getState(
                  'formData.clientUrl'
                )}`}
                placeholder="link"
              />
            </div>
            <div className="form-group col-md-3">
              <label className="profile-label" htmlFor="phoneNumber" />
              <CopyToClipboard
                text={`https://app.oqdigital.com/_/${this.getState(
                  'formData.clientUrl'
                )}`}
                onCopy={() => Notify('Link copiado com sucesso!', 'success')}
              >
                <button className="btn btn-oq-black col-md-12 col-12 col-sm-2 oq-margin-right">
                  <i className="fa fa-files-o" aria-hidden="true" />
                  <span>&nbsp; Copiar link</span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  renderPhase3 = () => {
    const { parsingRegionals, phase3Uncompleted, formData } = this.state;
    return (
      <div>
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Dados Complementares{' '}
          {phase3Uncompleted && this.renderUncompletedText()}
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          {/* <div className="row">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="emailDomain">
                URL
              </label>
              <input
                className="form-control profile-placeholder"
                onChange={event => {
                  this.handleUserInput(event);
                }}
                id="emailDomain"
                name="emailDomain"
                aria-describedby="emailDomainHelp"
                value={this.getState('formData.emailDomain')}
                placeholder="@suaempresa.com.br"
              />
              {this.getError('emailDomain') && (
                <small id="emailDomainHelp" className="form-text text-muted">
                  {this.getError('emailDomain')}
                </small>
              )}
            </div>
          </div> */}

          {/* AQUI ESTÁ A OPÇÃO TRUE OU FALSE DO BOTAO DE FILTRO/ */}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="filterChoice">
              {this.translate('Deseja segmentar os filtros?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="filterChoice"
              />
            </label>
            <ReactTooltip
              id="filterChoice"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="filterChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.filterChoice !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="filterChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.filterChoice === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData.filterChoice === 'true' && (
            <>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="buttonFilterQuiz">
                    {this.translate('Filter Name Quiz')} <code>*</code>
                  </label>
                  <input
                    required
                    className="form-control profile-placeholder"
                    name="buttonFilterQuiz"
                    placeholder={this.translate('Filter Name Quiz')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.buttonFilterQuiz}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="buttonFilterAction">
                    {this.translate('Filter Name Action')} <code>*</code>
                  </label>
                  <input
                    required
                    className="form-control profile-placeholder"
                    name="buttonFilterAction"
                    placeholder={this.translate('Filter Name Action')}
                    onChange={(e) => this.handleUserInput(e)}
                    value={formData.buttonFilterAction}
                  />
                </div>
              </div>
            </>
          )}

          <div className="row points-by-share col-md-12">
            <label className="profile-label" htmlFor="segmentationChoice">
              {this.translate('Deseja segmentar os usuários?')}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="segmentationInfo"
              />
            </label>
            <ReactTooltip
              id="segmentationInfo"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('segmentation-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-4">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    name="segmentationChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.segmentationChoice !== 'true'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    name="segmentationChoice"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={this.state.formData.segmentationChoice === 'true'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
              </div>
            </div>
          </div>

          {this.state.formData.segmentationChoice === 'true' && (
            <div>
              <div className="row">
                <div className="form-group col-md-12">
                  <label className="profile-label" htmlFor="scopeFieldName">
                    {this.translate('scope-field-name')}&nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="regionalInfo"
                    />
                  </label>
                  <ReactTooltip
                    id="regionalInfo"
                    type="dark"
                    effect="solid"
                    multiline
                  >
                    <span>{this.translate('scope-field-name-info')}</span>
                  </ReactTooltip>
                  <input
                    className="form-control profile-placeholder"
                    onChange={(event) => {
                      this.handleUserInput(event);
                    }}
                    id="scopeFieldName"
                    name="scopeFieldName"
                    aria-describedby="scopeFieldNameHelp"
                    value={this.getState('formData.scopeFieldName')}
                    placeholder={this.translate('scope-field-name')}
                  />
                  {this.getError('scopeFieldName') && (
                    <small
                      id="scopeFieldNameHelp"
                      className="form-text text-muted"
                    >
                      {this.getError('scopeFieldName')}
                    </small>
                  )}
                </div>
              </div>
              <div className="row align-items-flex-end">
                <div className="form-group col-md-8">
                  <label className="profile-label" htmlFor="regional">
                    {this.state.formData.scopeFieldName || 'Unidades'}
                    &nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="scopeFieldNameInfo"
                    />
                  </label>
                  <ReactTooltip
                    id="scopeFieldNameInfo"
                    type="dark"
                    effect="solid"
                    className="tool-tip"
                    multiline
                  >
                    <span>
                      Você pode colocar cada unidade escrevendo e pressionando
                      enter ou importar uma planilha de excel com todos os nomes
                      das unidades.
                    </span>
                  </ReactTooltip>
                  <TagsInput
                    inputProps={{
                      placeholder:
                        this.state.formData.scopeFieldName || 'Unidades',
                    }}
                    value={this.getState('formData.regional')}
                    onChange={this.handleTagsChange.bind(this)}
                  />
                  {this.getError('regional') && (
                    <small id="regionalHelp" className="form-text text-muted">
                      {this.getError('regional')}
                    </small>
                  )}
                </div>

                <div className="form-group w-auto">
                  <label
                    htmlFor="file-upload"
                    type="button"
                    className="btn btn-oq-black"
                    disabled={parsingRegionals}
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                    &nbsp;{' '}
                    {parsingRegionals
                      ? `${this.translate('importing')} ${
                          this.getState('formData.scopeFieldName') ||
                          'segmentações'
                        }...`
                      : `${this.translate('import')} ${
                          this.getState('formData.scopeFieldName') ||
                          'segmentações'
                        }`}
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) =>
                      this.fileChanged(e.target.files, 'regional')
                    }
                  />
                </div>
              </div>
            </div>
          )}

          <div className="row ">
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="targetPublic">
                {this.translate('public-type')}
              </label>
              <select
                className="form-control profile-placeholder"
                onChange={(event) => this.handleUserInput(event)}
                id="targetPublic"
                name="targetPublic"
                aria-describedby="targetPublicHelp"
                value={this.getState('formData.targetPublic')}
              >
                <option value="">{this.translate('select-the-otion')}</option>
                <option value="generalPublic">
                  {this.translate('general-public')}
                </option>
                <option value="myCollaborators">
                  {this.translate('my-collaborators')}
                </option>
              </select>
              {this.getError('targetPublic') && (
                <small id="targetPublicHelp" className="form-text text-muted">
                  {this.getError('targetPublic')}
                </small>
              )}
            </div>
            {this.getState('formData.targetPublic') === 'myCollaborators' &&
              this.renderAuthorizedUsersButton()}
          </div>
        </div>

        <hr />
      </div>
    );
  };

  renderForm() {
    return (
      <div className="col-md-10 col-md-offset-1">
        {this.renderPhase1()}
        {this.renderPhase2()}
        {/*this.renderPhase3()*/}

        <div className="row oq-padding-vertical">
          {this.renderUpdateButton()}
        </div>
      </div>
    );
  }

  renderAuthorizedUsersButton() {
    const { loadingAuthorizedUsers } = this.state;
    return (
      <div className="col-md-6 form-group">
        <button
          type="button"
          htmlFor="authorizedUsers"
          className="btn btn-oq-black"
          disabled={loadingAuthorizedUsers}
          onClick={() => this.toggleUpdateAuthorizedUsers()}
        >
          {this.translate('confirm-users-update')}
        </button>
      </div>
    );
  }

  phase1IsUncompleted() {
    const { formErrors } = this.state;
    return (
      formErrors.logo ||
      formErrors.companyName ||
      formErrors.companyInscription ||
      formErrors.cityInscription ||
      formErrors.cep ||
      formErrors.address ||
      formErrors.neighborhood ||
      formErrors.number ||
      formErrors.complement ||
      formErrors.state ||
      formErrors.city ||
      formErrors.country
    );
  }

  phase2IsUncompleted() {
    const { formErrors } = this.state;
    return (
      formErrors.accountableName ||
      formErrors.accountablePosition ||
      formErrors.accountableEmail ||
      formErrors.phoneNumber
    );
  }

  phase3IsUncompleted() {
    const { formErrors } = this.state;
    return (
      // formErrors['emailDomain'] ||
      formErrors.scopeFieldName ||
      formErrors.regional ||
      formErrors.targetPublic
    );
  }

  renderUpdateButton() {
    const { groupPermissions, userData } = this.context;

    const { readTerms } = this.state.formData;

    let isPermissionCreateEdit = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionCreateEdit =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.company &&
          groupPermissions.permissions.company.edit) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (
      userData &&
      userData.group &&
      userData.group.value &&
      !userData.master
    ) {
      isPermissionCreateEdit =
        groupPermissions &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.company &&
        groupPermissions[userData.group.value].permissions.company.edit;
    }

    return (
      <div className="col-md-5 col-md-offset-3 form-group oq-padding-vertical">
        <button
          disabled={
            this.state.loadingAuthorizedUsers ||
            this.state.parsingRegionals ||
            this.state.updating
          }
          className="btn btn-block btn-oq btn-oq-lg"
          onClick={() => {
            if (
              !this.phase1IsUncompleted() &&
              !this.phase2IsUncompleted() &&
              !this.phase3IsUncompleted()
            ) {
              this.state.imageSelected
                ? this.uploadImageToFirebase()
                : readTerms
                  ? isPermissionCreateEdit
                    ? this.updateClient()
                    : Notify('Você não tem permissão!', 'warn')
                  : this.toggleReadTerms();
            }
          }}
        >
          {this.state.updating || this.state.loadingAuthorizedUsers
            ? this.translate('saving-data')
            : this.translate('save-data')}
        </button>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  handleUrlVideoChange(video) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        urlVideoLogin: video,
      },
    }));
  }

  render() {
    const { formData } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div
              className="container-fluid"
              style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
            >
              {this.renderBackButton()}

              <h1
                className="oq-filter-title"
                style={{ marginBottom: '5%', color: '#000' }}
              >
                <i className="fa fa-edit" />
                &nbsp;
                <span>{this.translate('Editar dados da empresa')}</span>
              </h1>
              <div className="row">
                <div className="col-md-12">{this.renderForm()}</div>
              </div>
            </div>
            {this.confirmBeforeUpdateAuthorizedUsers()}
            {this.confirmUpdateAuthorizedUsers()}
            {this.renderReadTermsModal()}
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div
      //       className="container-fluid"
      //       style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
      //     >
      //       {this.renderBackButton()}

      //       <h1
      //         className="oq-filter-title"
      //         style={{ marginBottom: '5%', color: '#000' }}
      //       >
      //         <i className="fa fa-edit" />
      //         &nbsp;
      //         <span>{this.translate('edit-profile')}</span>
      //       </h1>
      //       <div className="row">
      //         <div className="col-md-12">{this.renderForm()}</div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  }
}
const CompanyLink = (props) => (
  <Link className="menu-link" to={routes.PROFILE_CLIENT}>
    <FontAwesomeIcon icon={faBuilding} />
    <span className="sidebarText">{props.translate('Empresa')}</span>
  </Link>
);

export default Company;

export { CompanyLink };
