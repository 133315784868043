import React, { Component } from 'react';
import { LoadingDashboard } from '../../LoadingDashboard';
import { UserDataContext } from '../../../../../../hooks/useUserData';

class InstallPwaCount extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="col-md-2 col-xs-6">
          <div
            className="card-dashboard-info"
            style={{ cursor: 'default' }} //Desativando pointer
          >
            <div className="col-md-12 col-xs-12 text-center row">
              <span className="card-dashboard-title">Instalações do App</span>
            </div>
            <div className="col-md-12 col-xs-12 text-center oq-no-padding">
              <h3 className="card-dashboard-count">
                {this.props.dataAmount.loadingInstallPwaCount ? (
                  <LoadingDashboard />
                ) : (
                  `${this.props.dataAmount.installPwaCount}`
                )}
              </h3>
            </div>
            <i className="fa fa-line-chart graph-icon" />
          </div>
        </div>
      </>
    );
  }
}

export default InstallPwaCount;
