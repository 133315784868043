import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import translationEN from './Locales/en/translation.json';
import translationBR from './Locales/br/translation.json';

const resources = {
  'en-US': {
    translation: translationEN,
  },
  'pt-BR': {
    translation: translationBR,
  },
};

const options = {
  // order and from where user language should be detected
  order: [
    'navigator',
    'querystring',
    'cookie',
    'localStorage',
    'htmlTag',
    'path',
    'subdomain',
  ],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,
};

i18n
  .use(detector)
  .use(reactI18nextModule)
  .init({
    resources,
    fallbackLng: ['pt-BR'],
    detection: options,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  });

export default i18n;
