import React, { Component } from 'react';

import Modal from 'react-responsive-modal';
import { toast } from 'react-toastify';

import './style.css';
import { api } from '../../../Config';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      senhaAtual: '',
      senhaNova: '',
      confirmarSenhaNova: '',
      playerData: this.props.player,
      loadingChangePassword: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps });
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ loadingChangePassword: true });
    this.toastId = toast.info('Atualizando senha. Aguarde...', {
      autoClose: false,
    });

    const { senhaNova, confirmarSenhaNova } = this.state;
    const { uid } = this.state.playerData;

    if (senhaNova.length < 6) {
      this.setState({ loadingChangePassword: false }, () => {
        toast.update(this.toastId, {
          render: 'Senha deve conter no mínimo 6 caracteres.',
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return;
    }

    if (senhaNova !== confirmarSenhaNova) {
      this.setState({ loadingChangePassword: false }, () => {
        toast.update(this.toastId, {
          render: 'Senha nova e senha de confirmação não são iguais.',
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      });
      return;
    }

    const parameters = {
      userType: 'administrator',
      searchFunctionality: 'updatePassword',
      newPassword: senhaNova,
      confirmNewPassword: confirmarSenhaNova,
      playerId: uid,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const response = res.data;

        if (response.success) {
          this.setState(
            {
              loadingChangePassword: false,
              senhaAtual: '',
              senhaNova: '',
              confirmarSenhaNova: '',
            },
            () => {
              toast.update(this.toastId, {
                render: res.data.message,
                type: toast.TYPE.SUCCESS,
                autoClose: true,
              });
            }
          );
          this.props.toggleModal();
        } else {
          this.setState({ loadingChangePassword: false }, () => {
            toast.update(this.toastId, {
              render: res.data.message,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          });
        }
      })
      .catch((error) => {
        console.log('updatePasswordPlayer error:', error);
        this.setState({ loadingChangePassword: false }, () => {
          toast.update(this.toastId, {
            render: 'Não foi possivel atualizar a senha o usuário!',
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        });
      });
  }

  handleInputChange(e) {
    const { target } = e;
    const { name, value } = target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => this.props.toggleModal()}
        classNames={{
          overlay: 'oq-overlay',
          modal: 'oq-progress-modal',
          closeButton: 'oq-close-modal',
        }}
      >
        <form onSubmit={(e) => this.handleFormSubmit(e)}>
          <div className="oq-wrapper-contents">
            <h2 className="title-alert title-normal">Alterar senha</h2>
            <div>
              <label htmlFor="contactMessage" className="login-label">
                Senha nova
              </label>
              <input
                className="form-control input-oq"
                placeholder="Senha nova"
                name="senhaNova"
                onChange={(e) => this.handleInputChange(e)}
                value={this.state.senhaNova}
                required
                type="password"
                disabled={this.state.loadingChangePassword}
              />
            </div>
            <div>
              <label htmlFor="contactMessage" className="login-label">
                Confirmar senha nova
              </label>
              <input
                className="form-control input-oq"
                placeholder="Confirmar senha nova"
                name="confirmarSenhaNova"
                onChange={(e) => this.handleInputChange(e)}
                value={this.state.confirmarSenhaNova}
                required
                type="password"
                disabled={this.state.loadingChangePassword}
              />
            </div>
            <br />
          </div>
          <div className="oq-group-horizontal">
            <button
              className="oq-btn"
              disabled={this.state.loadingChangePassword}
              onClick={() => this.props.toggleModal()}
            >
              Cancelar
            </button>
            <button
              className="oq-btn btn-confirm"
              disabled={this.state.loadingChangePassword}
              type="submit"
            >
              {this.state.loadingChangePassword
                ? 'Aguarde...'
                : 'Alterar senha'}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default ChangePassword;
