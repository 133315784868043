import React, { Component } from 'react';

import { ToastContainer } from 'react-toastify';
import _ from 'underscore';
import Select from 'react-select';
import renderHTML from 'react-render-html';

import ReactQuill, { Quill } from 'react-quill';
import { ImageResize } from 'quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';

import { Notify } from '../../components/Notify';
import SideBar from '../SideBar';
import NavBar from '../NavBar';
import { api, getAPIURL } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

class NewEmail extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      formData: {},
      loadingActionList: false,
      actionsList: [],
      stateList: [],
      cityList: [],
      loadingCityList: false,
      loadingStateList: false,
      loadingRegionalList: false,
      regionalList: [],
      savingEmail: false,
      renderCity: false,
    };

    this.translate = props.t;
  }

  getClients() {
    this.setState({ loadingClientList: true }, () => {
      const parameters = {
        searchFunctionality: 'clientList',
        userType: 'administrator',
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data.data.clients;
          if (result) {
            this.setState({
              loadingClientList: false,
              clientsList: result,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingClientList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  handleScopeChange(e) {
    const { value } = e.target;
    this.handleUserInput(e);
    switch (value) {
      case 'clients':
        this.setState({ renderClients: false });
        this.getClients();
        break;
      case 'national':
        this.setState({ renderCity: false });
        this.handleFormDataChange('scopeValue', 'BR');
        break;
      default:
        break;
    }
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  handleFormDataChange(name, value) {
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical col-md-12">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  renderScopeForm() {
    const { scope } = this.state.formData;
    const {
      stateList,
      regionalList,
      loadingRegionalList,
      loadingStateList,
      loadingClientList,
      clientsList,
    } = this.state;

    let html;
    switch (scope) {
      case 'clients':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="players">
              Clientes
            </label>
            <Select
              isMulti
              name="players"
              placeholder="Selecione os clientes..."
              isDisabled={loadingClientList}
              options={_.map(clientsList, (client) => {
                return {
                  value: client.emailDomain,
                  label: client.companyName,
                };
              })}
              isLoading={loadingClientList}
              className="basic-multi-select"
              defaultValue={this.state.formData.clientSelected}
              onChange={(e) => this.handleFormDataChange('scopeValues', e)}
              classNamePrefix="select"
            />
          </div>
        );
        break;
      case 'national':
        html = (
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="scopeValue">
              {this.translate('national')}
            </label>
            <input
              disabled
              value="BR"
              className="form-control profile-placeholder"
            />
          </div>
        );
        break;
      default:
        break;
    }

    return html;
  }

  renderScopeField() {
    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')}
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          onChange={(e) => this.handleScopeChange(e)}
          value={this.state.formData.scope}
          required
        >
          <option value="">{this.translate('Selecione...')}</option>
          <option value="national">{this.translate('national')}</option>

          <option value="clients">Clientes</option>
        </select>
      </div>
    );
  }

  renderSubjectInput() {
    return (
      <div className="row form-group col-md-12">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('Assunto')}
          </label>
          <input
            required
            className={'form-control'}
            id="subject"
            type="text"
            placeholder="Informe o assunto"
            onChange={(e) =>
              this.handleFormDataChange('subject', e.target.value)
            }
          />
        </div>
      </div>
    );
  }

  handleUploadSuccessQuill(file, ref) {
    return new Promise((resolve) => {
      const fileFormData = new FormData();
      fileFormData.append('userType', 'client');
      fileFormData.append('uploadFunctionality', 'uploadImage');
      fileFormData.append('ref', ref);
      fileFormData.append('file', file);
      fileFormData.set('Content-Type', 'multipart/form-data');

      const requestOptions = {
        method: 'POST',
        body: fileFormData,
      };

      const response = fetch(`${getAPIURL()}/upload`, requestOptions);
      const data = response.json();

      const dataFile = new FormData();
      dataFile.append('file', file);

      resolve(data.data);

      return dataFile;
    });
  }

  handleImageQuill(file) {
    let name = file.name;
    let size = file.size;
    let fileImage = file;

    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];

    if (size < 1000000) {
      return this.handleUploadSuccessQuill(
        fileImage,
        randomizeName,
        'emailImage',
        'TermsClientImages/'
      );
    } else {
      alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
      return {
        status: 200,
        success: false,
        message: 'TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!',
      };
    }
  }

  imageHandler = () => {
    this.quillEditor = this.quillRef.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();
    input.onchange = async () => {
      try {
        const file = input.files[0];

        const link = await this.handleImageQuill(file);
        const range = this.quillEditor.getSelection();

        this.quillEditor.insertEmbed(range.index, 'image', link);
      } catch (error) {
        console.log('erro: ', error);
      }
    };
  };

  renderTextAreaMessageNew() {
    const { body } = this.state.formData;

    var container = [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ color: [] }, { background: [] }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link', 'image'],

      ['clean'],
    ];

    const format = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'code-block',
      'list',
      'bullet',
      'indent',
      'color',
      'background',
      'align',
      'link',
      'image',
    ];

    return (
      <div className="row form-group col-md-12">
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {this.translate('E-mail')}
          </label>

          <ReactQuill
            ref={(ref) => (this.quillRef = ref)}
            modules={{
              imageDrop: true,
              imageResize: {
                displaySize: true,
              },
              toolbar: {
                container,
                handlers: {
                  image: this.imageHandler,
                },
              },
            }}
            formats={format}
            theme="snow"
            onChange={(content, delta, source, editor) => {
              this.handleFormDataChange('body', editor.getHTML());
            }}
          />
        </div>
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            Prévia do texto
          </label>
          <p>{body ? renderHTML(body) : ''}</p>
        </div>
      </div>
    );
  }

  renderSendMessageBtn() {
    return (
      <div className="pull-right col-md-5">
        <button
          type="submit"
          className="btn btn-oq btn-oq-lg btn-block"
          disabled={this.state.savingEmail}
        >
          {this.translate('Enviar e-mail')}
        </button>
      </div>
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    this.sendEmail();
  }

  sendEmail() {
    const emailData = this.state.formData;
    /**
     * -- Cadastrado - Boleto enviado
     * -- Documentação - Adesão paga
     * -- Negociação - 3ª Mensalidade Paga
     * -- Venda Efetivada - 6ª Mensalidade Paga
     */
    this.setState({ savingEmail: true }, () => {
      const parameters = {
        updateFunctionality: 'sendEmail',
        userType: 'administrator',
        emailData,
      };

      api
        .post(`/update`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ savingEmail: false });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ savingEmail: false });
          console.log('getActionsList error:', error);
        });
    });
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <div className="container-fluid">
            <form onSubmit={(e) => this.handleFormSubmit(e)}>
              <div className="col-md-8">
                {this.renderBackButton()}
                <h4 className="oq-h4 col-md-12">
                  <p>Novo E-mail</p>
                </h4>
                {this.renderScopeField()}
                {this.renderScopeForm()}
                {/* {this.renderScopeFormCity()} */}
                {this.renderSubjectInput()}
                {this.renderTextAreaMessageNew()}
                {this.renderSendMessageBtn()}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default NewEmail;
