import React, { createContext, useContext, useEffect, useState } from 'react';

import { api } from '../Config';

export const UserDataContext = createContext({});

export function UserDataProvider({ children }) {
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [userData, setUserData] = useState();
  const [userAuth, setUserAuth] = useState();
  const [clientData, setClientData] = useState();
  const [groupPermissions, setGroupPermissions] = useState();

  useEffect(() => {
    const userDataLocal = localStorage.getItem('@admin:userData');
    const userAuthLocal = localStorage.getItem('@admin:userAuth');
    const clientLocal = localStorage.getItem('@admin:client');
    const permissionsLocal = localStorage.getItem('@admin:permissions');

    const userDataparse = userDataLocal && JSON.parse(userDataLocal);
    const userAuthparse = userAuthLocal && JSON.parse(userAuthLocal);
    const clientParse = clientLocal && JSON.parse(clientLocal);
    const permissionParse = permissionsLocal && JSON.parse(permissionsLocal);

    if (userAuthparse) {
      const { accessToken } = userAuthparse.stsTokenManager;
      const { refreshToken } = userAuthparse.stsTokenManager;

      api.defaults.headers.common.Authorization = `OQVCQUER ${accessToken}`;
      api.defaults.headers.common.refresh = refreshToken;

      setUserData(userDataparse);
      setUserAuth(userAuthparse);
      setClientData(clientParse);
      setGroupPermissions(permissionParse);
    }

    setLoadingAuth(false);
  }, []);

  function signOut() {
    setUserData(null);
    setUserAuth(null);
    setClientData(null);
    setGroupPermissions(null);
    localStorage.removeItem('@admin:userData');
    localStorage.removeItem('@admin:userAuth');
    localStorage.removeItem('@admin:client');
    localStorage.removeItem('@admin:permissions');
  }

  async function signIn({ email, password, clientId }) {
    const parameters = {
      searchFunctionality: 'getAdminAuth',
      userType: 'client',
      email,
      password,
      clientId,
    };

    const responseAuth = await api.post('/search', parameters);

    if (!responseAuth.data.success) {
      throw Error(responseAuth.data.data);
    }

    const { user, userSnap, clientSnap, group } = responseAuth.data.data;

    const { accessToken } = user.stsTokenManager;
    const { refreshToken } = user.stsTokenManager;

    api.defaults.headers.common.Authorization = `OQVCQUER ${accessToken}`;
    api.defaults.headers.common.refresh = refreshToken;

    setUserData(userSnap);
    setUserAuth(user);
    setClientData(clientSnap);
    setGroupPermissions(group);

    localStorage.setItem('@admin:userData', JSON.stringify(userSnap));
    localStorage.setItem('@admin:userAuth', JSON.stringify(user));
    localStorage.setItem('@admin:client', JSON.stringify(clientSnap));
    localStorage.setItem('@admin:permissions', JSON.stringify(group));
    setLoadingAuth(false);
  }

  async function signInMaster({ email, password }) {
    const parameters = {
      searchFunctionality: 'getAdminMasterAuth',
      userType: 'administrator',
      email,
      password,
    };

    const responseAuth = await api.post('/search', parameters);

    if (!responseAuth.data.success) {
      throw Error(responseAuth.data.data);
    }

    const { userAuth, adminSnap } = responseAuth.data.data;

    const { accessToken } = userAuth.stsTokenManager;
    const { refreshToken } = userAuth.stsTokenManager;

    api.defaults.headers.common.Authorization = `OQVCQUER ${accessToken}`;
    api.defaults.headers.common.refresh = refreshToken;

    setUserAuth(userAuth);
    setClientData(adminSnap);
    localStorage.setItem('@admin:userAuth', JSON.stringify(userAuth));
    localStorage.setItem('@admin:client', JSON.stringify(adminSnap));
    setLoadingAuth(false);
  }

  async function getUserData(userId) {
    const parameters = {
      userType: 'client',
      searchFunctionality: 'getUserById',
      userId,
    };

    const response = await api.post(`/search`, parameters);

    if (response.data.success) {
      setUserData(response.data.user);
      localStorage.setItem(
        '@admin:userData',
        JSON.stringify(response.data.user)
      );
      await getClientData(response.data.user.clientId);
      await getPermissionsUser(
        response.data.user.group && response.data.user.group.value
          ? response.data.user.group.value
          : ''
      );
      setLoadingAuth(false);
    }
  }

  async function getClientData(clientId) {
    try {
      const parameters = {
        userType: 'client',
        searchFunctionality: 'getClient',
        clientId,
      };

      const response = await api.post(`/search`, parameters);

      if (response.data.success) {
        localStorage.setItem(
          '@admin:client',
          JSON.stringify(response.data.data)
        );
        setClientData(response.data.data);
      }
    } catch (error) {
      console.log('🚀 ~ error getClientData: ', error);
    }
  }

  async function getPermissionsUser(groupId) {
    try {
      const parameters = {
        searchFunctionality: 'getGroupById',
        userType: 'client',
        groupId: groupId || '',
      };

      const response = await api.post(`/search`, parameters);

      if (response.data.success) {
        setGroupPermissions(response.data.group);

        localStorage.setItem(
          '@admin:permissions',
          JSON.stringify(response.data.group)
        );
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: useUserData.js:125 ~ getPermissionsUser ~ error:',
        error
      );
    }
  }

  if (loadingAuth) {
    return <div>loading</div>;
  }

  return (
    <UserDataContext.Provider
      value={{
        signed: Boolean(userAuth),
        userAuth,
        userData,
        clientData,
        groupPermissions,
        getUserData,
        signIn,
        signOut,
        signInMaster,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserData() {
  const context = useContext(UserDataContext);

  return context;
}
