import React from 'react';
import Modal from 'react-responsive-modal';
import _ from 'underscore';
import './style.css';

function ShowSuccessErrorsTransfer(props) {
  const formatMoney = (amount) => {
    if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  };

  const totalValueErrors = _.reduce(
    props.rescuesErrors,
    (total, batch) => {
      return total + Number(batch.rescue.totalPaymentValue);
    },
    0
  );
  const totalValueSuccess = _.reduce(
    props.rescuesSuccess,
    (total, batch) => {
      return total + Number(batch.rescue.totalPaymentValue);
    },
    0
  );

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      classNames={{
        overlay: 'oq-overlay',
        modal: 'oq-progress-modal',
        closeButton: 'oq-close-modal',
      }}
    >
      <div className="oq-wrapper-contents">
        <h1 className="oq-font-medium title-alert">Detalhes dos Lotes</h1>
        <div className="content-wrapper">
          <div className="content-left">
            <span className="titleSlot">
              <i className="material-icons-outlined">check</i>
              Transferências realizadas
            </span>
            <div className="contentSlot">
              <ul className="list-process-batch">
                <li>
                  <h5>Ação</h5>
                </li>
                <li>
                  <h5>Player</h5>
                </li>
                <li>
                  <h5>Valor</h5>
                </li>
              </ul>
              {_.map(props.rescuesSuccess, (batch) => (
                <ul className="list-process-batch">
                  <li>
                    <p>Resgate</p>
                  </li>
                  <li>
                    <p>{batch.player.name}</p>
                  </li>
                  <li>{formatMoney(batch.rescue.totalPaymentValue) || '0'}</li>
                </ul>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '20px',
              }}
            >
              Total: {formatMoney(totalValueSuccess || '0')}
            </div>
          </div>

          <div className="content-right">
            <span className="titleSlot">
              <i className="material-icons-outlined">close</i>
              Transferências não realizadas
            </span>
            <div className="contentSlot">
              <ul className="list-process-batch">
                <li>
                  <h5>Ação</h5>
                </li>
                <li>
                  <h5>Player</h5>
                </li>
                <li>
                  <h5>Valor</h5>
                </li>
              </ul>
              {_.map(props.rescuesErrors, (batch) => (
                <ul className="list-process-batch">
                  <li>
                    <p>Resgate</p>
                  </li>
                  <li>
                    <p>{batch.player.name}</p>
                  </li>
                  <li>{formatMoney(batch.rescue.totalPaymentValue) || '0'}</li>
                </ul>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginRight: '20px',
              }}
            >
              Total: {formatMoney(totalValueErrors || '0')}
            </div>
          </div>
        </div>
      </div>
      <div className="oq-group-horizontal">
        <button className="oq-btn btn-confirm" onClick={props.close}>
          Concluir
        </button>
      </div>
    </Modal>
  );
}

export default ShowSuccessErrorsTransfer;
