import React, { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import WithoutPermission from '../../../AdminClient/pages/WithoutPermission';
// import FixedHelpAssistent from '../../../AdminClient/components/FixedHelpAssistent';

import { useUserData } from '../../../hooks/useUserData';

function PrivateRoute({ isPermission, history, ...props }) {
  const { signed, clientData } = useUserData();

  useEffect(() => {
    if (!signed) return history.push('/client/signin');
  }, [signed, history]);

  if (isPermission) {
    return (
      <>
        <Route {...props} />
        {/* <FixedHelpAssistent
          companyName={clientData && clientData.companyName}
        /> */}
      </>
    );
  }
  return <WithoutPermission />;
}

export default withRouter(PrivateRoute);
