import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import * as routes from '../../routes';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import { ToastContainer } from 'react-toastify';
import { api } from '../../Config';
import { Notify } from '../../components/Notify';

class JobsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: {
        prospectorsMore30DaysWithoutActivity: false,
        prospectorsIncompleteSignUp: false,
        leadMore30DaysWithoutChangeStatus: false,
        leadMore30DaysWithoutDeal: false,
        prospectors2DaysAfterRescue: false,
        prospectors45DaysAfterConvertedLeadWithoutRescue: false,
        prospectorsMoreThan10DaysWithoutIndication: false,
        tenDaysBeforeEndOfMonth: false,
      },
    };
  }

  prospectorsMore30DaysWithoutActivity() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          prospectorsMore30DaysWithoutActivity: true,
        },
      },
      () => {
        api
          .post(`/jobs/prospectors-more-30-days-without-activity`)
          .then((response) => {
            Notify(
              'Job [Prospectores com mais de 30 dias sem Atividade] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores com mais de 30 dias sem Atividade]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                prospectorsMore30DaysWithoutActivity: false,
              },
            });
          });
      }
    );
  }

  prospectorsIncompleteSignUp() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          prospectorsIncompleteSignUp: true,
        },
      },
      () => {
        api
          .post(`/jobs/prospectors-incomplete-sign-up`)
          .then((response) => {
            Notify(
              'Job [Prospectores com Cadastro Incompleto] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores com Cadastro Incompleto]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                prospectorsIncompleteSignUp: false,
              },
            });
          });
      }
    );
  }

  leadMore30DaysWithoutChangeStatus() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          leadMore30DaysWithoutChangeStatus: true,
        },
      },
      () => {
        api
          .post(`/jobs/lead-more-30-days-without-change-status`)
          .then((response) => {
            Notify(
              'Job [Lead com mais de 30 dias sem Movimentação] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Lead com mais de 30 dias sem Movimentação]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                leadMore30DaysWithoutChangeStatus: false,
              },
            });
          });
      }
    );
  }

  leadMore30DaysWithoutDeal() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          leadMore30DaysWithoutDeal: true,
        },
      },
      () => {
        api
          .post(`/jobs/lead-more-30-days-without-deal`)
          .then((response) => {
            Notify(
              'Job [Prospectores 2 dias após Resgate] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores 2 dias após Resgate]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                leadMore30DaysWithoutDeal: false,
              },
            });
          });
      }
    );
  }

  prospectors2DaysAfterRescue() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          prospectors2DaysAfterRescue: true,
        },
      },
      () => {
        api
          .post(`/jobs/prospectors-2-days-after-rescue`)
          .then((response) => {
            Notify(
              'Job [Prospectores 2 dias após Resgate] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores 2 dias após Resgate]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                prospectors2DaysAfterRescue: false,
              },
            });
          });
      }
    );
  }

  prospectors45DaysAfterConvertedLeadWithoutRescue() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          prospectors45DaysAfterConvertedLeadWithoutRescue: true,
        },
      },
      () => {
        api
          .post(`/jobs/prospectors-45-days-after-converted-lead-without-rescue`)
          .then((response) => {
            Notify(
              'Job [Prospectores 45 dias depois de Cenverter Lead e não Resgatar] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores 45 dias depois de Cenverter Lead e não Resgatar]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                prospectors45DaysAfterConvertedLeadWithoutRescue: false,
              },
            });
          });
      }
    );
  }

  prospectorsMoreThan10DaysWithoutIndication() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          prospectorsMoreThan10DaysWithoutIndication: true,
        },
      },
      () => {
        api
          .post(`/jobs/prospectors-more-than-10-days-without-indication`)
          .then((response) => {
            Notify(
              'Job [Prospectores com mais de 10 dias Sem Indicação] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [Prospectores com mais de 10 dias Sem Indicação]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                prospectorsMoreThan10DaysWithoutIndication: false,
              },
            });
          });
      }
    );
  }

  tenDaysBeforeEndOfMonth() {
    this.setState(
      {
        loading: {
          ...this.state.loading,
          tenDaysBeforeEndOfMonth: true,
        },
      },
      () => {
        api
          .post(`/jobs/ten-days-before-end-of-month`)
          .then((response) => {
            Notify(
              'Job [10 Dias antes do fim do Mês (Reta Final da Meta)] executado com sucesso!',
              'success'
            );
          })
          .catch((error) => {
            console.log('error: ', error);
            Notify(
              'Erro ao executar Job [10 Dias antes do fim do Mês (Reta Final da Meta)]',
              'error'
            );
          })
          .finally(() => {
            this.setState({
              loading: {
                ...this.state.loading,
                tenDaysBeforeEndOfMonth: false,
              },
            });
          });
      }
    );
  }

  prospectorsMore30DaysWithoutActivityButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.prospectorsMore30DaysWithoutActivity}
            onClick={() => this.prospectorsMore30DaysWithoutActivity()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.prospectorsMore30DaysWithoutActivity
                ? 'Executando...'
                : 'Prospectores com mais de 30 dias sem Atividade'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  prospectorsIncompleteSignUpButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.prospectorsIncompleteSignUp}
            onClick={() => this.prospectorsIncompleteSignUp()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.prospectorsIncompleteSignUp
                ? 'Executando...'
                : 'Prospectores com Cadastro Incompleto'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  leadMore30DaysWithoutChangeStatusButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.leadMore30DaysWithoutChangeStatus}
            onClick={() => this.leadMore30DaysWithoutChangeStatus()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.leadMore30DaysWithoutChangeStatus
                ? 'Executando...'
                : 'Lead com mais de 30 dias sem Movimentação'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  leadMore30DaysWithoutDealButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.leadMore30DaysWithoutDeal}
            onClick={() => this.leadMore30DaysWithoutDeal()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.leadMore30DaysWithoutDeal
                ? 'Executando...'
                : 'Lead com mais de 30 dias sem Conversão'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  prospectors2DaysAfterRescueButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.prospectors2DaysAfterRescue}
            onClick={() => this.prospectors2DaysAfterRescue()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.prospectors2DaysAfterRescue
                ? 'Executando...'
                : 'Prospectores 2 dias após Resgate'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  prospectors45DaysAfterConvertedLeadWithoutRescueButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={
              this.state.loading
                .prospectors45DaysAfterConvertedLeadWithoutRescue
            }
            onClick={() =>
              this.prospectors45DaysAfterConvertedLeadWithoutRescue()
            }
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading
                .prospectors45DaysAfterConvertedLeadWithoutRescue
                ? 'Executando...'
                : 'Prospectores 45 dias depois de Cenverter Lead e não Resgatar'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  prospectorsMoreThan10DaysWithoutIndicationButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={
              this.state.loading.prospectorsMoreThan10DaysWithoutIndication
            }
            onClick={() => this.prospectorsMoreThan10DaysWithoutIndication()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.prospectorsMoreThan10DaysWithoutIndication
                ? 'Executando...'
                : 'Prospectores com mais de 10 dias Sem Indicação'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  tenDaysBeforeEndOfMonthButton() {
    return (
      <div>
        <div className="text-center p-2-top p-2-bottom m-2-right m-2-left">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.loading.tenDaysBeforeEndOfMonth}
            onClick={() => this.tenDaysBeforeEndOfMonth()}
          >
            <i className="fa fa-gears fa-padding" aria-hidden="true" />
            <span>
              {this.state.loading.tenDaysBeforeEndOfMonth
                ? 'Executando...'
                : '10 Dias antes do fim do Mês (Reta Final da Meta)'}
            </span>
          </button>
        </div>
      </div>
    );
  }

  renderJobsButtons() {
    return (
      <div className="container" style={{ 'margin-top': '16px' }}>
        <div style={{ padding: '8px' }}>
          {this.prospectorsMore30DaysWithoutActivityButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.prospectorsIncompleteSignUpButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.leadMore30DaysWithoutChangeStatusButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.leadMore30DaysWithoutDealButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.prospectors2DaysAfterRescueButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.prospectors45DaysAfterConvertedLeadWithoutRescueButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.prospectorsMoreThan10DaysWithoutIndicationButton()}
        </div>
        <div style={{ padding: '8px' }}>
          {this.tenDaysBeforeEndOfMonthButton()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          {this.renderJobsButtons()}
        </div>
      </div>
    );
  }
}

const JobsPageLink = () => (
  <Link className="menu-link" to={routes.JOBS}>
    <FontAwesomeIcon icon={faGears} />
    <span>Jobs</span>
  </Link>
);

export default JobsPage;

export { JobsPageLink };
