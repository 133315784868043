export function removeNull(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Se o valor for um objeto, chama a função recursivamente
      removeNull(obj[key]);
    } else if (obj[key] === null) {
      // Se o valor for null, deleta a propriedade
      delete obj[key];
    }
  }
  return obj;
}
