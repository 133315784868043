import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import ReactTable from 'react-table';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import { Notify } from '../../components/Notify';
import * as mask from '../../utils/Mascaras';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class WalletsPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      authUser: {},
      clients: [],
      countClients: 0,
      isLoading: false,
      searchObject: {
        companyName: null,
        companyInscription: null,
      },
    };
    this.translate = props.t;
  }

  getAllWalletsClients() {
    const { searchObject } = this.state;
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'getClientsRescues',
        userType: 'administrator',
        search: searchObject,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                countClients: 0,
                clients: [],
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }
          this.setState(
            {
              isLoading: false,
              clients: result.clients,
            },
            Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          console.log('getAllWalletsClients error:', error);
          this.setState({
            isLoading: false,
            countClients: 0,
            clients: [],
          });
        });
    });
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  exportWalletsClients() {
    const { clients } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(clients, (client) => {
        return {
          Nome: client.companyName,
          Responsável: client.accountableName,
          'E-mail': client.accountableEmail,
          City: client.city,
          URL: client.clientUrl,
          Moeda: client.coinName ? client.coinName : 'Sem Gamification',
          Players: client.amountOfPlayers,
          Ações: client.amountOfActions,
          Conversões: client.convertions,
          Leads: client.leads,
          Pageviews: client.pageViews,
          Telefone: client.phoneNumber,
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WalletsClients');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'WalletsClients.xlsx'
    );
  }

  formatMoney(amount) {
    if (!amount) return;
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(amount);
  }

  goToEditWalletClients(clientId) {
    const { history } = this.props;
    history.push(`${routes.EDIT_WALLET}/${clientId}/`);
  }

  handleMask(event) {
    let { name, value } = event.target;
    if (value.length <= 14) {
      value = mask.maskCPF(value);
      this.handleSearchChange(name, value);
    } else if (value.length > 14 && value.length <= 18) {
      value = mask.maskCNPJ(value);
      this.handleSearchChange(name, value);
    } else {
      value = mask.maskLimited(value);
      this.handleSearchChange(name, value);
    }
  }

  handleSearchChange(name, value) {
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  renderSearchButton() {
    return (
      <div>
        <div className="col-md-4 text-center p-2-top p-2-bottom">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.isLoading}
            onClick={() => this.getAllWalletsClients()}
          >
            <i className="fa fa-search fa-padding" aria-hidden="true" />
            <span>{this.state.isLoading ? 'Pesquisando...' : 'Pesquisar'}</span>
          </button>
        </div>
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; Filtros Avançados
            </span>
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isLoading}
                  onChange={(event) => {
                    let { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="companyName"
                  placeholder="Nome"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="companyInscription"
                  disabled={this.state.isLoading}
                  onChange={(event) => this.handleMask(event)}
                  placeholder="CNPJ"
                  value={
                    this.state.searchObject &&
                    this.state.searchObject.companyInscription
                  }
                />
              </div>
              {this.renderSearchButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderWalletsClientsTable() {
    const { clients } = this.state;

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="companyName">
            {this.translate('Nome')}
            <ReactTooltip
              id="companyName"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Nome')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => d.companyName,
        id: 'companyName',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="totalEntries">
            {this.translate('Total entradas')}
            <ReactTooltip
              id="totalEntries"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Total entradas')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.totalEntries
            ? d.id === 'x7G0bb0NEZUEjXRhMCnKQIc18nM2'
              ? this.formatMoney(d.totalEntries - 1000)
              : this.formatMoney(d.totalEntries)
            : '0',
        id: 'totalEntries',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="totalExits">
            {this.translate('Total Saídas')}
            <ReactTooltip
              id="totalExits"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('Total Saídas')}
              </span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) => (d.totalExits ? this.formatMoney(d.totalExits) : '0'),
        id: 'totalExits',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="balance">
            {this.translate('Saldo')}
            <ReactTooltip
              id="balance"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('Saldo')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: (d) =>
          d.balance
            ? d.id === 'x7G0bb0NEZUEjXRhMCnKQIc18nM2'
              ? this.formatMoney(d.balance - 1000)
              : this.formatMoney(d.balance)
            : '0',
        id: 'balance',
      },
      {
        id: 'editWalletClient',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <Link
              to={`${routes.EDIT_WALLET}/${props.value}`}
              className="btn btn-oq btn-sm btn-block"
            >
              Editar Carteira
            </Link>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-user" />
            &nbsp; Lista de carteira dos clientes
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={_.values(clients)}
              defaultPageSize={5}
              loading={this.state.isLoading}
              columns={columns}
              previousText="prev"
              nextText="next"
              noDataText="Nenhum cliente encontrado"
              pageText="page"
              ofText="of"
              rowsText="lines"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          {this.renderSearchFields()}
          <hr />
          <hr />
          {this.renderWalletsClientsTable()}
        </div>
      </div>
    );
  }
}

const WalletsPageLink = () => (
  <Link className="menu-link" to={routes.WALLETS}>
    <FontAwesomeIcon icon={faWallet} />
    <span>Financeiro</span>
  </Link>
);

export default WalletsPage;

export { WalletsPageLink };
