import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { components } from 'react-select';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import { api } from '../../../Config';
import { maskCNPJ, maskLimited } from '../../../utils/Mascaras';
import ModalConversions from '../../components/Modals/Conversions';
import ModalConversionsExtraPoints from '../../components/Modals/ConversionsExtraPoints';

import { Notify } from '../../../components/Notify';
import * as routes from '../../../routes';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.value}</span>
      </components.Option>
    </div>
  );
};

class Conversions extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      actions: [],
      actionSelected: '',
      loadingActions: true,
      players: [],
      loadingPlayers: false,
      formData: {
        actionId: null,
        playerId: null,
      },
      extraPointsTypeSelect: '',
      extraPointsType: 0,
      converting: false,
      extraPoints: 0,
      secondaryRule: 0,
      modalExtraPointIsOpen: false,
      modalConversionIsOpen: false,
      playerSelected: '',
      action: '',
      isAddPointsExtra: false,
    };

    this.translate = props.t;
  }

  componentDidMount() {
    this.getAllActions();
  }

  getAllActions() {
    const parameters = {
      searchFunctionality: 'allActionsExtraPoints',
      userType: 'client',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (result.data && result.data.actions) {
          this.setState({ actions: result.data.actions });
        }
        this.setState({ loadingActions: false });
      })
      .catch((error) => {
        this.setState({ loadingActions: false });
        console.log('getAllActions error:', error);
      });
  }

  getPlayers() {
    const { formData, actions } = this.state;
    const { actionId } = formData;

    let action = actions.filter((action) => {
      return action.id === actionId;
    });

    if (action.length > 0) {
      this.setState({ actionSelected: action[0] });
    }

    if (!actionId) {
      this.setState({ players: [] });
      return;
    }

    this.setState({ loadingPlayers: true }, () => {
      const parameters = {
        searchFunctionality: 'getPlayersOnAction',
        userType: 'client',
        actionId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ loadingPlayers: false });
          if (result.data && _.size(res.data.data) > 0) {
            this.setState({ players: result.data });
          } else {
            this.setState({ loadingPlayers: false });
          }
        })
        .catch((error) => {
          this.setState({ loadingPlayers: false });
          console.log('getPlayers error:', error);
        });
    });
  }

  handleExtraPointsChange(e) {
    const { name, value } = e.target;
    const type = this.state.extraPointsTypeSelect;
    this.setState({ [name]: Number(value) });

    // if (this.context.clientData.clientUrl === 'reisdopedaco') {
    //   this.setState({
    //     extraPointsType: Number(value),
    //   });
    // } else {
    //   this.setState({
    //     extraPoints: Number(value),
    //   });
    // }
  }

  toggleModalConversion(playerId) {
    if (playerId) this.togglePlayerSeleced(playerId);

    this.setState({ modalConversionIsOpen: !this.state.modalConversionIsOpen });
  }

  confirmConversion() {
    return (
      <ModalConversions
        toggleModalConversion={this.toggleModalConversion.bind(this)}
        open={this.state.modalConversionIsOpen}
        converting={this.state.converting}
        playerId={this.state.playerSelected}
        action={this.state.action}
        addConversion={this.addConversion.bind(this)}
      />
    );
  }
  handleType(e) {
    const { value } = e.target;

    this.setState({
      extraPointsTypeSelect: value,
    });
  }

  togglePlayerSeleced(playerId) {
    this.setState({ playerSelected: playerId });
  }

  toggleActionSelected(action) {
    this.setState({ action: action });
  }

  toggleModalConversionExtraPoints(playerId) {
    if (playerId) this.togglePlayerSeleced(playerId);

    this.setState({ modalExtraPointIsOpen: !this.state.modalExtraPointIsOpen });
  }

  confirmddExtraPoints() {
    return (
      <ModalConversionsExtraPoints
        toggleModalConversion={this.toggleModalConversionExtraPoints.bind(this)}
        open={this.state.modalExtraPointIsOpen}
        client={this.context.clientData}
        actionSelected={this.state.actionSelected}
        handleType={this.handleType.bind(this)}
        handleExtraPointsChange={this.handleExtraPointsChange.bind(this)}
        converting={this.state.converting}
        addExtraPointsType={this.addExtraPointsType.bind(this)}
        addExtraPoints={this.addExtraPoints.bind(this)}
        playerId={this.state.playerSelected}
      />
    );
  }

  addExtraPointsType(playerId) {
    const { clientData, userData, userAuth } = this.context;

    const {
      extraPointsType,
      secondaryRule,
      formData,
      actionSelected,
      extraPointsTypeSelect,
    } = this.state;
    const { actionId } = formData;

    const isSecondaryRule = actionSelected.season.secondRule
      ? actionSelected.season.secondRule
      : 'false';

    this.setState({ converting: true }, () => {
      Notify(
        'Aguarde enquanto estamos adicionando os pontos extras...',
        'loading'
      );
      const parameters = {
        playerId,
        clientId: clientData.uid,
        actionId,
        extraPointsType,
        extraPointsTypeSelect,
        typePoints: true,
        isSecondaryRule,
        secondaryRule,
      };

      api
        .post(`/conversions`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ converting: false });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('addConversion error:', error);
        });
    });
  }

  addExtraPoints(playerId) {
    const { clientData, userData, userAuth } = this.context;

    const { extraPointsType, secondaryRule, formData, actionSelected } =
      this.state;
    const { actionId } = formData;

    const isSecondaryRule = actionSelected.season.secondRule
      ? actionSelected.season.secondRule
      : 'false';

    this.setState({ converting: true }, () => {
      Notify(
        'Aguarde enquanto estamos adicionando os pontos extras...',
        'loading'
      );
      const parameters = {
        playerId,
        clientId: clientData.uid,
        actionId,
        points: Number(extraPointsType),
        extraPoints: true,
        isSecondaryRule,
        secondaryRule,
        authUser: userAuth && userAuth.uid,
      };

      api
        .post(`/conversions`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ converting: false });

          Notify(
            result.success
              ? result.message
              : result.error && result.error.message
                ? result.error.message
                : result.message,
            result.success ? 'success' : 'error'
          );
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('addConversion error:', error);
        });
    });
  }

  addConversion(playerId, action) {
    const { clientData, userData, userAuth } = this.context;

    const { formData } = this.state;
    const { actionId } = formData;
    this.setState({ converting: true }, () => {
      Notify('Aguarde enquanto estamos aplicando a conversão.', 'loading');
      const parameters = {
        playerId,
        clientId: clientData.uid,
        actionId,
        action,
        pointsByConvertion: true,
        authUser: userAuth && userAuth.uid,
      };

      api
        .post(`/conversions`, parameters)
        .then((res) => {
          const result = res.data;
          this.setState({ converting: false });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ converting: false });
          console.log('addConversion error:', error);
        });
    });
  }

  handleFormSubmit(e) {
    e.preventDefault();
  }

  async handleUserInput(e) {
    const { name, value } = e.target;
    await this.handleFormDataChange(name, value);
  }

  async handleFormDataChange(name, value) {
    await this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  }

  async handleActionChange(e) {
    await this.handleUserInput(e);
    this.getPlayers();
  }

  renderActionsSelect() {
    const { actions, loadingActions } = this.state;
    return (
      <div className="col-md-12 form-group">
        <select
          className="form-control"
          name="actionId"
          onChange={(e) => this.handleActionChange(e)}
          disabled={loadingActions}
          required
        >
          <option value="">
            {loadingActions
              ? 'Carregando lista de ações...'
              : 'Selecione uma ação...'}
          </option>
          {_.map(actions, (action) => (
            <option key={action.id} value={action.id}>
              {action.actionTitle}
            </option>
          ))}
        </select>
      </div>
    );
  }

  handleSetCpfCnpj(value) {
    if (value.length <= 14) {
      value = String(value);
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{1,2})/, '$1-$2');
    } else if (value.length > 14 && value.length <= 18) {
      value = maskCNPJ(value);
      // value = value.replace(/\D/g, '')
      // value = value.replace(/(\d{2})(\d)/, '$1.$2')
      // value = value.replace(/(\d{3})(\d)/, '$1.$2')
      // value = value.replace(/(\d{3})(\d)/, '$1/$2')
      // value = value.replace(/(\d{4})(\d)/, '$1-$2')
    } else {
      value = maskLimited(value);
    }
    return value;
  }

  renderPlayersTable() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    const { players, loadingPlayers, actions, formData } = this.state;
    const newAction = actions.find((action) => {
      return action.id === formData.actionId;
    });

    const action = newAction && newAction !== undefined ? newAction : {};

    let isPermissionConversion = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionConversion =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.extraPoints &&
          groupPermissions.permissions.extraPoints.convert) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionConversion =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.extraPoints &&
        groupPermissions[userData.group.value].permissions.extraPoints.convert;
    }

    let isPermissionExtraPoints = null;

    // quando for login do grupo master
    if (userData && userData.master) {
      isPermissionExtraPoints =
        (groupPermissions &&
          groupPermissions.permissions &&
          groupPermissions.permissions.extraPoints &&
          groupPermissions.permissions.extraPoints.attribute) ||
        (userData && userData.master && userData.master);
    }

    // quando for login de qualquer outro grupo
    if (userData && !userData.master) {
      isPermissionExtraPoints =
        groupPermissions &&
        groupPermissions[userData.group.value] &&
        groupPermissions[userData.group.value].permissions &&
        groupPermissions[userData.group.value].permissions.extraPoints &&
        groupPermissions[userData.group.value].permissions.extraPoints
          .attribute;
    }

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'name',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="cpf">
            {this.translate('cpf')}
            <ReactTooltip
              id="cpf"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('cpf')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'cpf',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="email">
            {this.translate('email')}
            <ReactTooltip
              id="email"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('email')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'email',
      },
      {
        Header: this.translate('Converter'),
        accessor: 'name',
        id: 'uniqId',
        filterable: false,
        width: 130,
        Cell: (props) => {
          return (
            <button
              className="btn conversion-btn btn-oq"
              disabled={this.state.converting}
              onClick={() =>
                isPermissionConversion
                  ? this.toggleModalConversion(props.original.uid)
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              {this.translate('Converter')}
            </button>
          );
        },
      },
      {
        Header: this.translate('extra-points'),
        accessor: 'name',
        id: 'extraPoints',
        filterable: false,
        width: 170,
        Cell: (props) => {
          return (
            <button
              className="btn conversion-btn btn-oq"
              disabled={this.state.converting}
              onClick={() =>
                isPermissionExtraPoints
                  ? this.toggleModalConversionExtraPoints(props.original.uid)
                  : Notify('Você não tem permissão!', 'warn')
              }
            >
              {this.translate('extra-points')}
            </button>
          );
        },
      },
    ];
    return (
      <div className="col-md-12 p-0">
        <div className="oq-card">
          <ReactTable
            data={players}
            defaultPageSize={5}
            columns={columns}
            previousText={this.translate('prev')}
            loading={loadingPlayers}
            nextText={this.translate('next')}
            loadingText={this.translate('Carregando lista de players...')}
            noDataText={this.translate('Nenhum player para ser exibido!')}
            pageText={this.translate('page')}
            ofText={this.translate('of')}
            filterable
            rowsText="players"
            defaultFilterMethod={(filter, row) => {
              if (filter.id === 'cpf') {
                filter.value = this.handleSetCpfCnpj(filter.value);
              }
              const filterText = String(filter.value).toLowerCase();
              const text = String(row[filter.id]).toLowerCase();
              return text.indexOf(filterText) > -1;
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid oq-padding-vertical">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <h1 className="oq-filter-title" style={{ marginBottom: '1%' }}>
                  <i className="fas fa-coins" />
                  <span>Atribuição de pontos</span>
                </h1>
                <Link to={routes.WORKSHEET_POINTS}>
                  <button
                    className="btn btn-oq pull-right"
                    style={{ marginTop: 10 }}
                  >
                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                    Planilha
                  </button>
                </Link>
              </div>
              <div className="row">
                {this.renderActionsSelect()}
                {this.renderPlayersTable()}
              </div>
            </div>
          </div>
        </div>
        {this.confirmddExtraPoints()}
        {this.confirmConversion()}
      </div>
    );
  }
}

const ConversionsLink = (props) => (
  <Link className="menu-link" to={routes.CONVERSIONS}>
    <FontAwesomeIcon icon={faCoins} />
    <span className="sidebarText">{props.translate('conversions')}</span>
  </Link>
);

export default Conversions;
