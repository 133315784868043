import axios from 'axios';
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { Notify } from '../../../../../components/Notify';
import { api } from '../../../../../Config';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

import { UserDataContext } from '../../../../../hooks/useUserData';

// import { ReactComponent as IconDelete } from '../../../../../Assets/Images/delete.svg';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class DetailsBanner extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      isLoading: true,
      imgUploaded: false,
      imgSelected: false,
      formValid: false,
      formErrors: {},
      imageFormData: null,
      bannerId: null,
      regionalList: [],
      isUploading: false,
      progress: 0,
      formData: {
        bannerImage: {},
        bannerName: '',
        bannerStatus: '',
        bannerType: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        active: false,
        isPrimary: false,
        link: '',
        selectedStates: [],
        selectedCities: [],
      },
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const { params } = this.props.match;
    this.getBannerById(params.bannerId);
  }
  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }
  getClientRegionalList() {
    const { clientData } = this.context;

    const clientId = clientData.uid;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  async getBannerById(bannerId) {
    const parameters = {
      searchFunctionality: 'getBanner',
      userType: 'client',
      bannerId,
    };

    await api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;

        let bannerImage = [];
        if (typeof result.data.banner.bannerImage === 'string') {
          bannerImage.push({ url: result.data.banner.bannerImage });
        } else {
          bannerImage = _.filter(
            result.data.banner.bannerImage,
            (banner) => banner
          );
        }

        if (!result.success) {
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }
        if (result.data.banner && result.data.banner.selectedStates) {
          this.getCityList(result.data.banner.selectedStates);
        }
        if (result.scope === 'regional') {
          this.getClientRegionalList();
        }

        Notify(result.message, result.success ? 'success' : 'error');

        this.setState({
          formData: {
            ...this.state.formData,
            ...result.data.banner,
            bannerImage,
          },
          bannerId,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log('getBannerByiD error:', error);
      });
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { stateList, cityList, formData } = this.state;
    const { selectedCities } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          options={_.map(cityList, (city) => ({
            value: city.city,
            label: city.city,
            state: city.state,
          }))}
          // options={}
          className="basic-multi-select"
          value={selectedCities}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={(option) => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            value={this.state.formData.scopeValues}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderImageLogo() {
    const { bannerImage, formData } = this.state;

    if (!this.state.isLoading && formData.isPrimary) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {_.map(formData.bannerImage, (banner, i) => {
                  if (!banner.isMobile)
                    return (
                      <img
                        className="img-responsive img-thumbnail"
                        key={banner.id}
                        src={banner.url}
                      />
                    );
                })}
              </div>
            </div>
            <div className="col-md-12 form-group text-center">
              <span className="small-oq">Imagem do banner (Desktop)</span>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                {_.map(formData.bannerImage, (banner, i) => {
                  if (banner.isMobile) {
                    return (
                      <img
                        className="img-responsive img-thumbnail"
                        key={banner.id}
                        src={banner.url}
                      />
                    );
                  }
                })}
              </div>
            </div>
            <div className="col-md-12 form-group text-center">
              <span className="small-oq">Imagem do banner (Mobile)</span>
            </div>
          </div>
        </div>
      );
    } else if (!this.state.isLoading && !formData.isPrimary) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {_.map(formData.bannerImage, (banner, i) => {
                  return (
                    <img
                      className="img-responsive img-thumbnail"
                      key={banner.id}
                      src={banner.url}
                    />
                  );
                })}
              </div>
            </div>
            <div className="col-md-12 form-group text-center">
              <span className="small-oq">imagem do banner (Desktop)</span>
              <span className="small-oq text-center">
                {formData.isPrimary
                  ? this.translate('primary-banner-height-width')
                  : this.translate('secondary-banner-height-width')}
              </span>
            </div>
          </div>
        </div>
      );
    }
    return;
  }

  renderInfluencerSegmentation() {
    const { isTeamCompetition } = this.context.clientData;
    const { userType } = this.state.formData;

    return (
      userType &&
      userType.value && (
        <div className="form-group col-md-6">
          <label className="profile-label" htmlFor="userType">
            {'Tipo de usuario'} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={(option) => option.value}
            name="userType"
            placeholder={`Selecione quais usuarios deseja atingir ...`}
            options={[
              {
                value: 'todos',
                label: 'Todos',
              },
              {
                value: 'influencers',
                label: 'Influencers',
              },
              {
                value: 'players',
                label: 'Players',
              },
            ]}
            className="basic-multi-select"
            value={userType.label}
            classNamePrefix="select"
          />
          {!userType && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderBannerForm() {
    const { isLoading, formData } = this.state;
    const { clientData } = this.context;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('name')}
            </label>
            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              required
              id="bannerName"
              name="bannerName"
              placeholder={this.translate('name')}
              value={formData.bannerName}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="bannerLink">
              {this.translate('Redirect link')}
            </label>
            <input
              disabled
              readOnly
              className="form-control profile-placeholder"
              id="bannerLink"
              name="link"
              placeholder={this.translate('Redirect Link')}
              value={formData.link}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('banner-type')}
            </label>
            <Select
              isDisabled
              isOptionDisabled={(option) => option.value}
              closeMenuOnSelect
              value={formData.bannerType}
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_type')}
              options={[
                {
                  value: this.translate('primary'),
                  label: this.translate('primary'),
                },
                {
                  value: this.translate('secondary'),
                  label: this.translate('secondary'),
                },
              ]}
            />
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              isDisabled
              isOptionDisabled={(option) => option.value}
              closeMenuOnSelect
              value={formData.bannerStatus}
              required
              components={{ SelectOption }}
              placeholder={this.translate('choose_banner_status')}
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>
          {this.renderScope()}
          {this.context.clientData.segmentationChoice === 'true' &&
            this.renderScopeForm()}
          {clientData.isActiveRegisterStateAndCity &&
            clientData.isActiveRegisterStateAndCity === 'true' && (
              <>
                {this.renderStateSelection()}
                {this.renderCitySelection()}
              </>
            )}

          {this.context.clientData.isTeamCompetition === 'true' &&
            this.renderInfluencerSegmentation()}
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-9">{this.renderBannerForm()}</div>
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsBanner;
