import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { LoadingDashboard } from '../../components/LoadingDashboard'

export default function UsersTypes(props) {
  const [series, setSeries] = useState();
  const [options, setOptions] = useState();

  return (
    <>
      <span
        style={{
          display: 'flex',
          display: '-webkit-flex',
          fontSize: '18px',
          alignItems: 'center',
          width: '100%',
          fontWeight: 'bold',
        }}
      >
        <i
          style={{
            marginRight: '10px',
          }}
          className="material-icons-outlined"
        >
          receipt
        </i>
        Anfitriões/Convidados
      </span>
      <div
        style={{
          marginTop: '30px',
          height: '100%',
        }}
      >
        {props.loading ? (
          <LoadingDashboard size="4x"/>
        ) : (
          <Chart
             options={{
              //  labels: ['Anfitriões', 'Convidados'],
               labels: ['Convidados', 'Anfitriões'],
               legend: {
                 show: true,
                 position: 'bottom',
               },
               colors: ['#2196f3', props.color],
             }}
             series={[props.totalUsersNormal, props.totalUsersRegional]}
             type="pie"
             height="100%"
           />
        )}
      </div>
    </>
  );
}
