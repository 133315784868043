import React from 'react';
import styles from './style.module.css';
import Card from '../Card';
import { List } from '../List';
import { ListItem } from '../List';
import { Link } from 'react-router-dom';
import * as routes from '../../../../../../routes';

import defaultAvatar from '../../../../../../Assets/Images/avatar.png';

function SvgRanking({ list }) {
  return (
    <div className={styles.boxRanking}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{ isolation: 'isolate' }}
        viewBox="133.5 133.5 813 813"
        width={813}
        height={813}
        className={`${styles.reflect} animate__animated animate__fadeInUp animate__delay-1s animate__slow`}
      >
        <clipPath id="a">
          <path fill="#FFF" d="M133.5 133.5h813v813h-813z" />
        </clipPath>
        <g clipPath="url(#a)">
          <linearGradient
            id="b"
            x1={0.146}
            y1={0.146}
            x2={0.854}
            y2={0.854}
            gradientTransform="matrix(279.503 0 0 389.878 156.669 556.622)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" style={{ stopColor: '#d0d0d0' }} />
            <stop offset="47.826%" style={{ stopColor: '#f7f7f7' }} />
            <stop offset="100%" style={{ stopColor: '#d0d0d0' }} />
          </linearGradient>
          <path
            d="M156.669 556.622h279.504V946.5H186.445l-29.776-389.878Z"
            fill="url(#b)"
          />
          <clipPath id="c">
            <path
              d="M156.669 556.622h279.504V946.5H186.445l-29.776-389.878Z"
              fill="url(#b)"
            />
          </clipPath>
          <g clipPath="url(#c)">
            <path
              d="M241.974 928.42q61.233-40.464 61.233-67.153 0-8.093-4.064-12.742-4.064-4.649-12.734-4.649-17.882 0-18.966 23.245h-24.747q1.084-21.523 13.728-32.63 12.644-11.106 31.972-11.106 19.689 0 30.255 9.729 10.567 9.729 10.567 25.914 0 12.742-7.857 25.57-7.858 12.828-19.96 23.59t-25.469 18.51h55.815V946.5h-89.773v-18.08Z"
              fill="#A1A8B7"
              opacity={0.7}
            />
          </g>
          <linearGradient
            id="d"
            x1={0.854}
            y1={0.854}
            x2={0.146}
            y2={0.146}
            gradientTransform="matrix(279.503 0 0 70.415 156.669 488.425)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" style={{ stopColor: '#b3b3b3' }} />
            <stop offset="100%" style={{ stopColor: '#d1d1d1' }} />
          </linearGradient>
          <path
            d="M221 488.425h186.358l28.815 70.415H156.669L221 488.425Z"
            fill="url(#d)"
          />
          <linearGradient
            id="e"
            x1={0.854}
            y1={0.854}
            x2={0.146}
            y2={0.146}
            gradientTransform="matrix(235.383 0 0 294.378 687.948 652.122)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" style={{ stopColor: '#bb851f' }} />
            <stop offset="53.478%" style={{ stopColor: '#ffe1a9' }} />
            <stop offset="100%" style={{ stopColor: '#bb851f' }} />
          </linearGradient>
          <path
            d="M687.948 652.122h235.383L899.064 946.5H687.948V652.122Z"
            fill="url(#e)"
          />
          <clipPath id="f">
            <path
              d="M687.948 652.122h235.383L899.064 946.5H687.948V652.122Z"
              fill="url(#e)"
            />
          </clipPath>
          <g clipPath="url(#f)">
            <path
              d="M795.244 829.626q11.372 0 19.506 3.948 8.133 3.949 12.319 10.898 4.185 6.949 4.185 15.478 0 10.74-5.844 17.61-5.843 6.87-15.478 8.45v.789q23.375 6.95 23.375 27.482 0 14.372-9.634 23.295-9.634 8.924-27.165 8.924-18.637 0-29.693-9.239-11.055-9.24-12.319-27.876h21.796q.789 8.528 5.606 13.503 4.818 4.975 13.662 4.975 7.581 0 11.845-4.185 4.265-4.185 4.265-10.977 0-8.055-6.16-12.319-6.159-4.264-18.163-4.264h-4.58v-18.163h4.58q22.112.316 22.112-15.32 0-6.792-3.949-10.661-3.948-3.869-10.74-3.869-7.107 0-11.687 4.501-4.58 4.501-5.212 12.556h-21.795q.947-17.057 11.055-26.297 10.108-9.239 28.113-9.239Z"
              fill="#AA6B18"
              opacity={0.7}
            />
          </g>
          <linearGradient
            id="g"
            x1={0.146}
            y1={0.146}
            x2={0.854}
            y2={0.854}
            gradientTransform="matrix(235.383 0 0 47.238 687.948 605.994)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" style={{ stopColor: '#bb851f' }} />
            <stop offset="100%" style={{ stopColor: '#825c16' }} />
          </linearGradient>
          <path
            d="M712.214 605.994h169.833l41.284 47.237H687.948l24.266-47.237Z"
            fill="url(#g)"
          />
          <g filter="url(#h)">
            <linearGradient
              id="i"
              x1={0.854}
              y1={0.854}
              x2={0.146}
              y2={0.146}
              gradientTransform="matrix(322.76 0 0 534.606 401.789 411.894)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="2.174%" style={{ stopColor: '#ffdf81' }} />
              <stop offset="50.435%" style={{ stopColor: '#fff8e2' }} />
              <stop offset="99.13%" style={{ stopColor: '#ffdf81' }} />
            </linearGradient>
            <path
              d="M401.789 411.894h322.76L691.275 946.5H436.173l-34.384-534.606Z"
              fill="url(#i)"
            />
            <linearGradient
              id="j"
              x1={0.5}
              y1={0}
              x2={0.5}
              y2={1}
              gradientTransform="matrix(322.76 0 0 67.658 401.789 345.346)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="2.174%" style={{ stopColor: '#ffdf81' }} />
              <stop offset="100%" style={{ stopColor: '#eec855' }} />
            </linearGradient>
            <path
              d="M435.064 345.346h256.211l33.274 67.657h-322.76l33.275-67.657Z"
              fill="url(#j)"
            />
            <path
              d="M540.361 808.142h-25.55v-31.817h61.224V946.5h-35.674V808.142Z"
              fill="#F2BB28"
              opacity={0.7}
            />
          </g>
          <defs>
            <filter
              id="h"
              x={48.789}
              y={105.346}
              width={1040.76}
              height={1321.154}
              filterUnits="userSpaceOnUse"
            >
              <feGaussianBlur
                xmlns="http://www.w3.org/2000/svg"
                in="SourceGraphic"
                stdDeviation={39.072}
              />
              <feOffset
                xmlns="http://www.w3.org/2000/svg"
                dx={6}
                dy={121}
                result="pf_100_offsetBlur"
              />
              <feFlood
                xmlns="http://www.w3.org/2000/svg"
                floodColor="#000"
                floodOpacity={0.5}
              />
              <feComposite
                xmlns="http://www.w3.org/2000/svg"
                in2="pf_100_offsetBlur"
                operator="in"
                result="pf_100_dropShadow"
              />
              <feBlend
                xmlns="http://www.w3.org/2000/svg"
                in="SourceGraphic"
                in2="pf_100_dropShadow"
              />
            </filter>
          </defs>
          <path
            d="M563.169 154.019h0c49.585 0 89.841 40.256 89.841 89.84h0c0 49.585-40.256 89.841-89.841 89.841h0c-49.584 0-89.84-40.256-89.84-89.841h0c0-49.584 40.256-89.84 89.84-89.84Z"
            style={{
              fill: 'none',
              stroke: '#ffc800',
              strokeWidth: 4,
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <path
            d="M563.169 163.447c44.381 0 80.413 36.032 80.413 80.412 0 44.381-36.032 80.413-80.413 80.413s-80.412-36.032-80.412-80.413c0-44.38 36.031-80.412 80.412-80.412Z"
            style={{
              stroke: 'none',
              fill: '#ebebeb',
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <clipPath id="k">
            <path
              d="M563.169 163.447c44.381 0 80.413 36.032 80.413 80.412 0 44.381-36.032 80.413-80.413 80.413s-80.412-36.032-80.412-80.413c0-44.38 36.031-80.412 80.412-80.412Z"
              style={{
                stroke: 'none',
                fill: '#ebebeb',
                strokeLinecap: 'square',
                strokeMiterlimit: 3,
              }}
            />
          </clipPath>
          <g clipPath="url(#k)">
            <defs>
              <mask id="m">
                <path
                  d="M440.055 162.338h246.229V326.49H440.055V162.338Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
              <mask id="l">
                <path
                  d="M440.055 162.338h246.229V326.49H440.055V162.338Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
            </defs>
            <g mask="url(#m)">
              <image
                width={1125}
                height={750}
                transform="matrix(.219 0 0 .219 440.055 162.338)"
                preserveAspectRatio="xMidYMid meet"
                xlinkHref={
                  list && list[0] && list[0].photoURL
                    ? list[0].photoURL
                    : defaultAvatar
                }
              />
            </g>
          </g>
          <path
            d="M307.762 354.829h0c42.07 0 76.226 34.156 76.226 76.226h0c0 42.07-34.156 76.225-76.226 76.225h0c-42.07 0-76.226-34.155-76.226-76.225h0c0-42.07 34.156-76.226 76.226-76.226Z"
            style={{
              fill: 'none',
              stroke: '#868686',
              strokeWidth: 4,
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <path
            d="M308.178 363.092c37.647 0 68.212 30.565 68.212 68.212 0 37.648-30.565 68.212-68.212 68.212-37.647 0-68.212-30.564-68.212-68.212 0-37.647 30.565-68.212 68.212-68.212Z"
            style={{
              stroke: 'none',
              fill: '#ebebeb',
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <clipPath id="n">
            <path
              d="M308.178 363.092c37.647 0 68.212 30.565 68.212 68.212 0 37.648-30.565 68.212-68.212 68.212-37.647 0-68.212-30.564-68.212-68.212 0-37.647 30.565-68.212 68.212-68.212Z"
              style={{
                stroke: 'none',
                fill: '#ebebeb',
                strokeLinecap: 'square',
                strokeMiterlimit: 3,
              }}
            />
          </clipPath>
          <g clipPath="url(#n)">
            <defs>
              <mask id="p">
                <path
                  d="M203.792 362.353h208.772v139.045H203.792V362.353Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
              <mask id="o">
                <path
                  d="M203.792 362.353h208.772v139.045H203.792V362.353Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
            </defs>
            <g mask="url(#p)">
              <image
                width={509}
                height={339}
                transform="matrix(.41 0 0 .41 203.792 362.353)"
                preserveAspectRatio="xMidYMid meet"
                xlinkHref={
                  list && list[1] && list[1].photoURL
                    ? list[1].photoURL
                    : defaultAvatar
                }
              />
            </g>
          </g>
          <path
            d="M804.23 479.346h0c38.969-.136 70.717 31.391 70.853 70.36h0c.136 38.969-31.391 70.717-70.36 70.853h0c-38.969.136-70.717-31.391-70.853-70.36h0c-.136-38.969 31.391-70.717 70.36-70.853Z"
            style={{
              fill: 'none',
              stroke: '#d08d0f',
              strokeWidth: 4,
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <path
            d="M804.53 489.534c34.519 0 62.544 28.025 62.544 62.544s-28.025 62.544-62.544 62.544-62.544-28.025-62.544-62.544 28.025-62.544 62.544-62.544Z"
            style={{
              stroke: 'none',
              fill: '#ebebeb',
              strokeLinecap: 'square',
              strokeMiterlimit: 3,
            }}
          />
          <clipPath id="q">
            <path
              d="M804.53 489.534c34.519 0 62.544 28.025 62.544 62.544s-28.025 62.544-62.544 62.544-62.544-28.025-62.544-62.544 28.025-62.544 62.544-62.544Z"
              style={{
                stroke: 'none',
                fill: '#ebebeb',
                strokeLinecap: 'square',
                strokeMiterlimit: 3,
              }}
            />
          </clipPath>
          <g clipPath="url(#q)">
            <defs>
              <mask id="s">
                <path
                  d="M709.327 488.671h190.406v126.814H709.327V488.671Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
              <mask id="r">
                <path
                  d="M709.327 488.671h190.406v126.814H709.327V488.671Z"
                  style={{ stroke: '#000', fill: '#fff', strokeMiterlimit: 10 }}
                />
              </mask>
            </defs>
            <g mask="url(#s)">
              <image
                width={509}
                height={339}
                transform="matrix(.374 0 0 .374 709.327 488.671)"
                preserveAspectRatio="xMidYMid meet"
                xlinkHref={
                  list && list[2] && list[2].photoURL
                    ? list[2].photoURL
                    : defaultAvatar
                }
              />
            </g>
          </g>
          <path
            d="M611.195 810.076q-5.767 0-10.426-2.329-4.658-2.33-7.265-6.489-2.606-4.159-2.606-9.372 0-5.102 2.606-9.262 2.607-4.159 7.265-6.488 4.659-2.329 10.426-2.329 5.879 0 10.482 2.329t7.209 6.488q2.606 4.16 2.606 9.262 0 5.213-2.606 9.372t-7.209 6.489q-4.603 2.329-10.482 2.329Zm0-7.875q4.88 0 7.82-2.828 2.939-2.829 2.939-7.487 0-4.548-2.995-7.376-2.994-2.828-7.764-2.828-4.769 0-7.764 2.828-2.994 2.828-2.994 7.376 0 4.658 2.939 7.487 2.939 2.828 7.819 2.828Z"
            fillRule="evenodd"
            fill="#F2BB28"
            opacity={0.7}
          />
          <path
            d="M358.145 859.712q-5.768 0-10.426-2.33-4.659-2.329-7.265-6.488-2.607-4.159-2.607-9.372 0-5.102 2.607-9.262 2.606-4.159 7.265-6.488 4.658-2.329 10.426-2.329 5.878 0 10.481 2.329t7.209 6.488q2.607 4.16 2.607 9.262 0 5.213-2.607 9.372-2.606 4.159-7.209 6.488-4.603 2.33-10.481 2.33Zm0-7.875q4.88 0 7.819-2.829 2.939-2.828 2.939-7.486 0-4.548-2.994-7.376-2.995-2.828-7.764-2.828-4.77 0-7.764 2.828-2.995 2.828-2.995 7.376 0 4.658 2.939 7.486 2.94 2.829 7.82 2.829Z"
            fillRule="evenodd"
            fill="#A1A8B7"
            opacity={0.7}
          />
          <path
            d="M852.655 859.769q-4.727 0-8.546-1.909-3.818-1.91-5.955-5.319-2.136-3.409-2.136-7.682 0-4.182 2.136-7.591 2.137-3.409 5.955-5.319 3.819-1.909 8.546-1.909 4.818 0 8.591 1.909 3.773 1.91 5.91 5.319 2.136 3.409 2.136 7.591 0 4.273-2.136 7.682-2.137 3.409-5.91 5.319-3.773 1.909-8.591 1.909Zm0-6.455q4 0 6.409-2.318 2.41-2.319 2.41-6.137 0-3.727-2.455-6.046-2.455-2.318-6.364-2.318t-6.364 2.318q-2.454 2.319-2.454 6.046 0 3.818 2.409 6.137 2.409 2.318 6.409 2.318Z"
            fillRule="evenodd"
            fill="#AA6B18"
            opacity={0.7}
          />
        </g>
      </svg>
    </div>
  );
}

export default function Ranking({ list }) {
  return (
    <>
      <div className="col-sm-12">
        <div className="mb-3">
          <Card style={{ paddingBottom: '0' }}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="fw-semibold">Ranking geral</span>
              {/* <Button
                data-bs-toggle="modal"
                data-bs-target="#modalranking"
                text="Ver mais"
                color="flat"
                icon="add"
              /> */}
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <SvgRanking list={list && list} />
              </div>
              <div className="col-sm-12 col-md-6">
                <List>
                  {list &&
                    list.slice(0, 6).map((item, index) => (
                      <ListItem
                        image={item.photoURL ? item.photoURL : defaultAvatar}
                        key={index}
                        content={
                          <>
                            <span
                              className="fw-semibold color-text-primary"
                              style={{ marginRight: '5px' }}
                            >{`${index + 1}º`}</span>
                            {item.name}
                          </>
                        }
                        secondaryContent={
                          <>
                            <span className="ms-2">{`${item.totalPoints} pontos`}</span>
                          </>
                        }
                      />
                    ))}
                </List>
                <br />
                <Link to={routes.RANKING_CORRECTORS}>
                  <button className="btn btn-oq">Ver mais</button>
                </Link>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
