import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';
import { Notify } from '../../components/Notify';
import { api } from '../../Config';
import * as mask from '../../utils/Mascaras';
import { UserDataContext } from '../../hooks/useUserData';

class RegisterClientPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
    this.fileSelector = null;
  }

  getInitialState() {
    const initialState = {
      imageFormData: null,
      imageSelected: false,
      formErrors: {},
      clientId: null,
      formValid: false,
      isLoading: true,
      stateList: {},
      cityList: {},
      clientData: {},
      parsingRegionals: false,
      loadingAuthorizedUsers: false,
      authorizedUsers: [],
      formData: {
        companyName: '',
        companyInscription: '',
        stateInscription: '',
        cityInscription: '',
        /* activitySector: "", */
        address: '',
        country: 'Brasil',
        /* state: "", */
        /* city: "", */
        accountableName: '',
        accountablePosition: '',
        accountableEmail: '',
        phoneNumber: '',
        /* plan: "", */
        active: true,
        targetPublic: '',
        /* primaryColor: "",
                secondaryColor: "", */
        regional: [],
        // emailDomain: "",
        // coinName: "",
        /* expirationDate: "",
                expirationDateEpoch: "",
                logoURL: "" */
      },
    };
    return initialState;
  }

  componentDidMount() {
    this.getStateList();
    this.fileSelector = this.buildFileSelector();
  }

  getCityList(estado = 'MG') {
    axios
      .get(`https://spotless-unicorn.glitch.me/estados/${estado}/cidades`)
      .then((city) => {
        this.setState({ cityList: city.data });
      })
      .catch((error) => {
        console.log('getCityList error:', error);
      });
  }

  getStateList() {
    axios
      .get('https://spotless-unicorn.glitch.me/estados')
      .then((states) => {
        this.setState({ stateList: states.data });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  goToEmailTemplates() {}

  handleFormDataChange(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  getState(item) {
    return _.get(this.state, item, '');
  }

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    switch (fieldName) {
      // case "emailDomain":
      // 	let regexString = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
      // 	fieldValidationErrors.emailDomain = regexString.test(value)
      // 		? ""
      // 		: "Dominio de e-mail inválido.";
      // 	break;

      default:
        if (value.length <= 0) {
          fieldValidationErrors[fieldName] =
            'Você precisa preencher esse campo.';
        } else {
          fieldValidationErrors[fieldName] = '';
        }
        break;
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (inputKey !== 'regional' && !input) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  uploadImageToFirebase() {
    const { imageFormData, imageSelected } = this.state;

    if (!imageSelected) {
      Notify('Você deve escolher uma imagem para ser usada como logo.', 'warn');
      return;
    }

    this.toastId = toast.info('Salvando imagem...', { autoClose: false });

    const parameters = imageFormData;

    api
      .post(`/upload`, parameters)
      .then((response) => {
        if (response.data.success && response.data.data.storageURL) {
          this.setState(
            (prevState) => ({
              imageFormData: null,
              imageSelected: false,
              formData: {
                ...prevState.formData,
                logoURL: response.data.data.storageURL,
              },
            }),
            () => {
              toast.update(this.toastId, {
                render: 'Criando usuario...',
                type: toast.TYPE.INFO,
                autoClose: false,
              });
              this.createClient();
            }
          );
        }
      })
      .catch((error) => {
        console.log('handleUploadFile error:', error);
      });
  }

  createClient() {
    if (!this.state.formValid) {
      Notify(
        'Você precisa preencher todos os dados antes de continuar.',
        'warn'
      );
      return;
    }

    const newClient = this.state.formData;
    const { authorizedUsers } = this.state;
    newClient.sendEmailToAdmins = true;

    const parameters = {
      clientData: newClient,
      authorizedUsers,
    };

    api
      .post(`/createClient`, parameters)
      .then((response) => {
        const result = response.data;
        toast.update(this.toastId, {
          render: result.message,
          type: result.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.log('createClient error:', error);
      });
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const expirationDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          expirationDateEpoch: expirationDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleTagsChange(tags) {
    this.updateFormData('regional', tags);
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'regional') {
      this.setState({ parsingRegionals: true });
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: true });
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });
      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'authorizedUsers') {
          return {
            name: item[0],
            cpf: item[1],
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'regional') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, items);
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: false, authorizedUsers: items });
    }
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('name', 'authorizedUsers');
    fileSelector.onchange = (e) =>
      this.fileChanged(e.target.files, 'authorizedUsers');
    return fileSelector;
  }

  renderForm() {
    const { parsingRegionals } = this.state;
    return (
      <div className="col-md-10 col-md-offset-1">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="logo">
              Logomarca
            </label>
            <p className="tipText">Recomendado: 680x240px </p>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUploadFile(event)}
              type="file"
              accept="image/*"
              id="logo"
              name="logo"
              aria-describedby="logoHelp"
            />
            {this.getError('logo') && (
              <small id="logoHelp" className="form-text text-muted">
                {this.getError('logo')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="companyName">
              Nome da empresa
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="companyName"
              name="companyName"
              aria-describedby="companyNameHelp"
              value={this.getState('formData.companyName')}
              placeholder="Nome da Empresa"
            />
            {this.getError('companyInscription') && (
              <small id="companyNameHelp" className="form-text text-muted">
                {this.getError('companyName')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="companyInscription">
              CNPJ
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                let maskCNPJ = mask.maskCNPJ(input.value);
                this.updateFormData(input.name, maskCNPJ);
              }}
              maxLength={18}
              minLength={18}
              id="companyInscription"
              name="companyInscription"
              aria-describedby="companyInscriptionHelp"
              value={this.getState('formData.companyInscription')}
              placeholder="CNPJ"
            />
            {this.getError('companyInscription') && (
              <small
                id="companyInscriptionHelp"
                className="form-text text-muted"
              >
                {this.getError('companyInscription')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="stateInscription">
              Inscrição Estadual
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="stateInscription"
              name="stateInscription"
              aria-describedby="stateInscriptionHelp"
              value={this.getState('formData.stateInscription')}
              placeholder="Inscrição Estadual"
            />

            <small id="stateInscriptionHelp" className="form-text text-muted">
              {this.getError('stateInscription')}
            </small>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="cityInscription">
              Inscrição Municipal
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="cityInscription"
              name="cityInscription"
              aria-describedby="cityInscriptionHelp"
              value={this.getState('formData.cityInscription')}
              placeholder="Inscrição Municipal"
            />
            {this.getError('cityInscription') && (
              <small id="cityInscriptionHelp" className="form-text text-muted">
                {this.getError('cityInscription')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="cep">
              CEP
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                mask.maskCEP(input.value).then((masked) => {
                  this.updateFormData(input.name, masked);
                });
              }}
              id="cep"
              name="cep"
              minLength={9}
              maxLength={9}
              aria-describedby="cepHelp"
              value={this.getState('formData.cep')}
              placeholder="00000-000"
            />
            {this.getError('cep') && (
              <small id="cepHelp" className="form-text text-muted">
                {this.getError('cep')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="address">
              Endereço
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="address"
              name="address"
              aria-describedby="addressHelp"
              value={this.getState('formData.address')}
              placeholder="Endereço"
            />
            {this.getError('address') && (
              <small id="addressHelp" className="form-text text-muted">
                {this.getError('address')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="neighborhood"
              name="neighborhood"
              aria-describedby="neighborhoodHelp"
              value={this.getState('formData.neighborhood')}
              placeholder="Bairro"
            />
            {this.getError('counneighborhoodtry') && (
              <small id="neighborhoodHelp" className="form-text text-muted">
                {this.getError('neighborhood')}
              </small>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="number">
              Nº
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="number"
              name="number"
              aria-describedby="numberHelp"
              value={this.getState('formData.number')}
              placeholder="Nº"
            />
            {this.getError('number') && (
              <small id="numberHelp" className="form-text text-muted">
                {this.getError('number')}
              </small>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="complement">
              Complemento
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="complement"
              name="complement"
              aria-describedby="complementHelp"
              value={this.getState('formData.complement')}
              placeholder="Complemento"
            />
            {this.getError('complement') && (
              <small id="complementHelp" className="form-text text-muted">
                {this.getError('complement')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="country">
              País
            </label>
            <input
              className="form-control profile-placeholder"
              /* onChange={event => this.handleUserInput(event)} */
              disabled
              id="country"
              name="country"
              aria-describedby="countryHelp"
              value={this.getState('formData.country')}
              placeholder="País"
            />
            {this.getError('country') && (
              <small id="countryHelp" className="form-text text-muted">
                {this.getError('country')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountableName">
              Nome do Responsável
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="accountableName"
              name="accountableName"
              aria-describedby="accountableNameHelp"
              value={this.getState('formData.accountableName')}
              placeholder="Nome do Responsável"
            />
            {this.getError('accountableName') && (
              <small id="accountableNameHelp" className="form-text text-muted">
                {this.getError('accountableName')}
              </small>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountablePosition">
              Cargo
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="accountablePosition"
              name="accountablePosition"
              aria-describedby="accountablePositionHelp"
              value={this.getState('formData.accountablePosition')}
              placeholder="Cargo"
            />
            {this.getError('accountablePosition') && (
              <small
                id="accountablePositionHelp"
                className="form-text text-muted"
              >
                {this.getError('accountablePosition')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountableEmail">
              E-mail
            </label>
            <input
              className="form-control profile-placeholder"
              type="email"
              onChange={(event) => this.handleUserInput(event)}
              id="accountableEmail"
              name="accountableEmail"
              aria-describedby="accountableEmailHelp"
              value={this.getState('formData.accountableEmail')}
              placeholder="E-mail"
            />
            {this.getError('accountableEmail') && (
              <small id="accountableEmailHelp" className="form-text text-muted">
                {this.getError('accountableEmail')}
              </small>
            )}
          </div>
        </div>
        {/* <div className="row">
					<div className="form-group col-md-12">
						<label className="profile-label" htmlFor="emailDomain">
							URL
						</label>
						<input
							className="form-control profile-placeholder"
							onChange={event => {
								this.handleUserInput(event);
							}}
							id="emailDomain"
							name="emailDomain"
							aria-describedby="emailDomainHelp"
							value={this.getState("formData.emailDomain")}
							placeholder="@suaempresa.com.br"
						/>
						{this.getError("emailDomain") && (
							<small id="emailDomainHelp" className="form-text text-muted">
								{this.getError("emailDomain")}
							</small>
						)}
					</div>
				</div> */}
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="phoneNumber">
              Telefone
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                mask.maskTEL(input.value).then((masked) => {
                  this.updateFormData(input.name, masked);
                });
              }}
              maxLength={15}
              minLength={15}
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumberHelp"
              value={this.getState('formData.phoneNumber')}
              placeholder="Telefone"
            />
            {this.getError('phoneNumber') && (
              <small id="phoneNumberHelp" className="form-text text-muted">
                {this.getError('phoneNumber')}
              </small>
            )}
          </div>
        </div>
        {/* <div className="row">
					<div className="form-group col-md-12">
						<label className="profile-label" htmlFor="coinName">
							Nome da moeda
						</label>
						<input
							className="form-control profile-placeholder"
							onChange={event => {
								this.handleUserInput(event);
							}}
							id="coinName"
							name="coinName"
							aria-describedby="coinNameHelp"
							value={this.getState("formData.coinName")}
							placeholder="Nome da moeda"
						/>
						{this.getError("coinName") && (
							<small id="coinNameHelp" className="form-text text-muted">
								{this.getError("coinName")}
							</small>
						)}
					</div>
				</div> */}
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="regional">
              Regiões
            </label>
            <TagsInput
              inputProps={{ placeholder: 'Regiões' }}
              value={this.getState('formData.regional')}
              onChange={this.handleTagsChange.bind(this)}
            />
            {this.getError('regional') && (
              <small id="regionalHelp" className="form-text text-muted">
                {this.getError('regional')}
              </small>
            )}
          </div>
          <div className="form-group col-md-4">
            <label
              htmlFor="file-upload"
              type="button"
              className="btn btn-oq-black"
              disabled={parsingRegionals}
            >
              <i className="fa fa-file-excel-o" aria-hidden="true" />
              &nbsp;{' '}
              {parsingRegionals
                ? 'Importando regionais...'
                : 'Importar regionais'}
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => this.fileChanged(e.target.files, 'regional')}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="targetPublic">
              Tipo de público
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="targetPublic"
              name="targetPublic"
              aria-describedby="targetPublicHelp"
              value={this.getState('formData.targetPublic')}
            >
              <option value="">Selecione a opção...</option>
              <option value="generalPublic">Público em geral</option>
              <option value="myCollaborators">Meus colaboradores</option>
            </select>
            {this.getError('targetPublic') && (
              <small id="targetPublicHelp" className="form-text text-muted">
                {this.getError('targetPublic')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="pass">
              Senha
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              id="pass"
              name="pass"
              aria-describedby="passHelp"
              value={this.getState('formData.pass')}
              placeholder="********"
            />
            {this.getError('pass') && (
              <small id="passHelp" className="form-text text-muted">
                {this.getError('pass')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="passConfirm">
              Confirmar Senha
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              id="passConfirm"
              name="passConfirm"
              aria-describedby="passConfirmHelp"
              value={this.getState('formData.passConfirm')}
              placeholder="********"
            />
            {this.getError('passConfirm') && (
              <small id="passConfirmHelp" className="form-text text-muted">
                {this.getError('passConfirm')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="primaryColor">
              Cor Primaria
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              type="color"
              id="primaryColor"
              name="primaryColor"
              aria-describedby="primaryColorHelp"
              value={this.getState('formData.primaryColor')}
              placeholder="Escolha uma cor"
            />
            {this.getError('primaryColor') && (
              <small id="primaryColorHelp" className="form-text text-muted">
                {this.getError('primaryColor')}
              </small>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="secondaryColor">
              Cor secundária - Cor da sua barra superior
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              type="color"
              id="secondaryColor"
              name="secondaryColor"
              aria-describedby="secondaryColorHelp"
              value={this.getState('formData.secondaryColor')}
              placeholder="Escolha uma cor"
            />
            {this.getError('secondaryColor') && (
              <small id="secondaryColorHelp" className="form-text text-muted">
                {this.getError('secondaryColor')}
              </small>
            )}
          </div>
        </div>
        <div className="row">{this.renderAuthorizedUsersButton()}</div>
        <div className="row">{this.renderUpdateButton()}</div>
      </div>
    );
  }

  renderAuthorizedUsersButton() {
    const { loadingAuthorizedUsers } = this.state;
    return (
      <div className="col-md-6 form-group">
        <button
          type="button"
          htmlFor="authorizedUsers"
          className="btn btn-oq-black"
          disabled={loadingAuthorizedUsers}
          onClick={() => this.fileSelector.click()}
        >
          Cadastrar Usuários Autorizados
        </button>
      </div>
    );
  }

  renderUpdateButton() {
    return (
      <div className="col-md-5 col-md-offset-4 form-group">
        <button
          type="button"
          onClick={() => this.uploadImageToFirebase()}
          className="btn btn-oq btn-block btn-oq-lg"
          disabled={!this.state.formValid}
        >
          CADASTRAR MINHA EMPRESA
        </button>
      </div>
    );
  }

  render() {
    return (
      <div id="content">
        <div
          className="container-fluid"
          style={{ marginLeft: '2%', marginRight: '2%', marginTop: '1%' }}
        >
          <h1 className="oq-filter-title" style={{ marginBottom: '5%' }}>
            <span>&nbsp; Bem-vindo ao OQ Digital</span>
          </h1>
          <div className="row">
            <div className="col-md-12">{this.renderForm()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RegisterClientPage);
