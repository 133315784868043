import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export function LoadingDashboard(props) {
  return (
    <div className="d-flex justify-content-center vertical-center">
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin
        className="oq-primary-color"
        size={props.size ? props.size : ''}
      />
    </div>
  );
}
