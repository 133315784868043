import { Markup } from 'interweave';
import React, { useEffect, useState } from 'react';
import { auth } from '../../../firebase';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';

// Posters dos videos tutoriais

import { api } from '../../../Config';
import coverNone from './img/cover-none.png';

// Fim Posters dos videos tutoriais

import './style.css';

export default function Tutorials() {
  const [initialIndexTutorial, setInitialIndexTutorial] = useState(0);
  const [videosTutorials, setVideosTutorials] = useState([]);

  useEffect(() => {
    getVideos();
  }, []);

  function getVideos() {
    const parameters = {
      searchFunctionality: 'getVideoTutorials',
      userType: 'client',
    };

    api
      .post(`/search`, parameters)
      .then(response => {
        if (response.data.success) {
          const videosTutorials = response.data.data;
          const newArrayVideoData = videosTutorials.map(
            async (video, index) => {
              return {
                title: video.title,
                shortDescription: video.description.substr(0, 49),
                description: video.description,
                url: video.url,
                active: true,
              };
            }
          );

          Promise.all(newArrayVideoData).then(videos => {
            setVideosTutorials(videos);
          });
        }
      })
      .catch(err => {
        console.log('Error from get Videos tutorials: ', err);
      });
  }

  function activeTutorial(e) {
    let cardElement = document.querySelectorAll('.oq-item-tutorial');
    cardElement.forEach(element => {
      element.classList.remove('active');
    });
    e.currentTarget.classList.add('active');
    setInitialIndexTutorial(e.currentTarget.dataset.index);
  }

  return (
    <>
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className="ob-how-to-section">
              <div className="row">
                <div className="col-sm-12 col-md-8">
                  <div className="ob-how-to-card-group video">
                    <div className="ob-how-to-responsive-video">
                      <video
                        src={
                          videosTutorials.length > 0 &&
                          videosTutorials[initialIndexTutorial].url
                        }
                        poster={coverNone}
                        controls
                        preload="auto"
                      ></video>
                    </div>
                    <div className="ob-how-to-card video-header">
                      <h2>
                        {videosTutorials.length > 0 &&
                          videosTutorials[initialIndexTutorial].title}
                      </h2>
                      <p>
                        <Markup
                          content={
                            videosTutorials.length > 0 &&
                            videosTutorials[initialIndexTutorial].description
                          }
                        />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="ob-how-to-card-group">
                    <div className="ob-how-to-card header">
                      <h2>Videos tutorias</h2>
                      <p className="">
                        A lista abaixo ajudará você a utilizar nossa plataforma
                        de maneira simples. São videos curtos e objetivos.
                        Acesse quando quiser.
                      </p>
                    </div>
                    {videosTutorials.length > 0 &&
                      videosTutorials.map((video, index) => {
                        if (video.active) {
                          return (
                            <div
                              className={
                                index === 0
                                  ? 'ob-how-to-card oq-item-tutorial active'
                                  : 'ob-how-to-card oq-item-tutorial'
                              }
                              data-index={index}
                              onClick={e => {
                                activeTutorial(e);
                              }}
                            >
                              <span className="ob-how-to-card-title">
                                <i className="material-icons-outlined">
                                  play_arrow
                                </i>{' '}
                                {video.title}
                              </span>
                              <p className="ob-how-to-content-item-description">
                                {video.shortDescription}
                              </p>
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
