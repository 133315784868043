import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactTable from 'react-table';
import _ from 'underscore';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import * as routes from '../../routes';
import { Notify } from '../../components/Notify';
import PlayerDetails from '../Modals/PlayerDetails';
import { maskCPF } from '../../utils/Mascaras';
import { api } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

class PlayersPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      players: {},
      modalIsOpen: false,
      countPlayers: 0,
      isLoading: false,
      loadingStateList: false,
      loadingCityList: false,
      stateList: [],
      cityList: [],
      player: {},
      searchObject: {
        name: null,
        email: null,
        cpf: null,
        clientName: null,
      },
    };
    return initialState;
  }

  componentDidMount() {
    this.render();
  }

  getPlayers() {
    const parameters = {
      searchFunctionality: 'allPlayers',
      userType: 'administrator',
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          this.setState({ isLoading: false }, () => {
            Notify(result.message, result.success ? 'success' : 'error');
          });
          return;
        }

        this.setState(
          {
            isLoading: false,
            countPlayers: result.data.playersCount,
            players: result.data.players,
          },
          Notify(result.message, result.success ? 'success' : 'error')
        );
      })
      .catch((error) => {
        console.log('getPlayers error:', error);
      });
  }

  filterPlayers() {
    const { searchObject } = this.state;
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: 'filterPlayers',
        userType: 'administrator',
        searchObject,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          let result = res.data;
          if (!result.success) {
            this.setState(
              {
                isLoading: false,
                countPlayers: 0,
                players: [],
              },
              () => {
                Notify(result.message, result.success ? 'success' : 'error');
              }
            );
            return;
          }

          result.data = result.data.map((player) => {
            player.name = !player.name ? 'Não informado' : player.name;
            player.cpf = !player.cpf ? 'Não informado' : player.cpf;
            player.clientName = !player.clientName
              ? 'Não informado'
              : player.clientName;
            player.email = !player.email ? 'Não informado' : player.email;
            return player;
          });

          this.setState(
            {
              isLoading: false,
              countPlayers: result.data.lenght,
              players: result.data,
            },
            Notify(result.message, result.success ? 'success' : 'error')
          );
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log('filterPlayers error front:', error);
        });
    });
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  exportPlayers() {
    const { players } = this.state;
    const ws = XLSX.utils.json_to_sheet(
      _.map(players, (player) => {
        return {
          Nome: player.name && player.name,
          Cliente: player.clientName && player.clientName,
          'E-mail': player.email && player.email,
          CPF: player.cpf && player.cpf,
        };
      })
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Players');

    const buf = XLSX.write(wb, {
      type: 'buffer',
      bookType: 'xlsx',
    });

    FileSaver.saveAs(
      new Blob([this.s2ab(buf)], {
        type: 'application/octet-stream',
      }),
      'Players.xlsx'
    );
  }

  handleSearchChange(name, value) {
    // const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObject: {
        ...prevState.searchObject,
        [name]: value,
      },
    }));
  }

  renderUpdateButton() {
    if (!this.state.isLoading) {
      return (
        <div>
          <div className="col-md-4 text-center p-2-top p-2-bottom">
            <button
              className="btn-oq btn-block text-center"
              onClick={() => this.filterPlayers()}
            >
              <i className="fa fa-refresh fa-padding" aria-hidden="true" />
              <span>Atualizar</span>
            </button>
          </div>
        </div>
      );
    }
  }

  renderSearchButton() {
    return (
      <div>
        <div className="col-md-4 text-center p-2-top p-2-bottom">
          <button
            className="btn btn-block btn-oq"
            disabled={this.state.isLoading}
            onClick={() => this.filterPlayers()}
          >
            <i className="fa fa-search fa-padding" aria-hidden="true" />
            <span>{this.state.isLoading ? 'Pesquisando...' : 'Pesquisar'}</span>
          </button>
        </div>
      </div>
    );
  }

  renderSearchFields() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; Filtros Avançados
            </span>
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  disabled={this.state.isLoading}
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="name"
                  placeholder="Nome"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  name="cpf"
                  disabled={this.state.isLoading}
                  onChange={(event) => {
                    let { name, value } = event.target;
                    value = maskCPF(value);
                    this.handleSearchChange(name, value);
                  }}
                  placeholder="CPF"
                />
              </div>

              <div className="form-group col-md-4">
                <input
                  type="text"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="email"
                  disabled={this.state.isLoading}
                  className="form-control"
                  placeholder="E-mail"
                />
              </div>
              <div className="form-group col-md-4">
                <input
                  type="text"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    this.handleSearchChange(name, value);
                  }}
                  name="clientName"
                  disabled={this.state.isLoading}
                  className="form-control"
                  placeholder="URL do cliente sem '/_/'"
                />
              </div>
              <div className="form-group col-md-4"></div>
              {this.renderSearchButton()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  // maskCpf(event) {
  //   let cpf = event.target.value.replace(/\D/g, '')
  //   if(cpf.length > 11) {
  //     cpf = cpf.slice(0, -1)
  //   }

  //   cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  //   cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
  //   cpf = cpf.replace(/(\d{3})(\d)/, "$1-$2")
  //   event.target.value = cpf
  //   this.handleSearchChange(event)
  //   return event
  // }

  renderPlayersTable() {
    const { players } = this.state;

    const columns = [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'Cliente',
        accessor: 'clientName',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
      },
      {
        Header: 'CPF',
        accessor: 'cpf',
      },
      {
        id: 'editPlayer',
        Header: '',
        accessor: 'uid',
        Cell: (props) => (
          <span className="text-center clickable">
            <div
              className="btn btn-oq btn-sm btn-block"
              onClick={() => this.handlePlayerModal(props.original)}
            >
              Detalhes
            </div>
          </span>
        ),
      },
    ];

    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-user" />
            &nbsp; Lista de players
          </span>

          <button
            type="button"
            className="btn btn-oq pull-right"
            onClick={() => this.exportPlayers()}
            disabled={_.size(this.state.players) === 0}
          >
            <i className="fa fa-file-excel-o" aria-hidden="true" />
            {this.translate('Exportar')}
          </button>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={_.values(players)}
              defaultPageSize={5}
              loading={this.state.isLoading}
              columns={columns}
              previousText="prev"
              nextText="next"
              noDataText="Nenhum player encontrado"
              pageText="page"
              ofText="of"
              rowsText="lines"
            />
          </div>
        </div>
      </div>
    );
  }

  handlePlayerModal(player) {
    this.setState({ player }, () => this.toggleModal());
  }

  toggleModal() {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  }

  render() {
    return (
      <div className="wrapper">
        <SideBar />
        <div id="content">
          <NavBar />
          <ToastContainer />
          <PlayerDetails
            closeModal={() => this.toggleModal()}
            player={this.state.player}
            modalIsOpen={this.state.modalIsOpen}
            filterPlayers={() => this.filterPlayers()}
          />
          {this.renderSearchFields()}
          <hr />
          <hr />
          {this.renderPlayersTable()}
        </div>
      </div>
    );
  }
}

const PlayersPageLink = () => (
  <Link className="menu-link" to={routes.PLAYERS_ADMIN}>
    <FontAwesomeIcon icon={faUsers} />
    <span>Players</span>
  </Link>
);

export default PlayersPage;

export { PlayersPageLink };
