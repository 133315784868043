import Color from 'color';
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import _ from 'underscore';
import { api } from '../../../Config';

import { Notify } from '../../../components/Notify';
import { NavBar } from '../../components/NavBar';
import { SideBar } from '../../components/SideBar';
import { UserDataContext } from '../../../hooks/useUserData';

class PontuationRules extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = {
      loadingRules: false,
      rules: [
        {
          minPoints: 0,
          maxPoints: 999,
          icon: 'fa-trophy',
          boost: '100',
          editable: true,
          color: 'rgb(251, 229, 39)',
        },
        {
          minPoints: 1000,
          maxPoints: 4999,
          icon: 'fa-trophy',
          boost: '110',
          editable: true,
          color: 'rgb(251, 153, 16)',
        },
        {
          minPoints: 5000,
          maxPoints: 9999,
          icon: 'fa-trophy',
          boost: '120',
          editable: true,
          color: 'rgb(12, 180, 251)',
        },
        {
          minPoints: 10000,
          maxPoints: 19999,
          icon: 'fa-trophy',
          boost: '130',
          editable: true,
          color: 'rgb(92, 213, 3)',
        },
        {
          minPoints: 20000,
          maxPoints: 49999,
          icon: 'fa-trophy',
          boost: '150',
          editable: true,
          color: 'rgb(155, 10, 39)',
        },
        {
          minPoints: 50000,
          maxPoints: 'Infinito',
          icon: 'fa-trophy',
          boost: '200',
          editable: false,
          color: Color('#002363').hex(),
        },
      ],
    };
  }

  componentDidMount() {
    this.getRules();
  }

  getRules() {
    this.setState({ loadingRules: true }, () => {
      const parameters = {
        userType: 'client',
        searchFunctionality: 'getRules',
      };

      api
        .post(`/search`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({ loadingRules: false });
          if (result.data && result.data.rules) {
            this.setState({ rules: result.data.rules });
          }
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateRules error:', error);
          this.setState({ loadingRules: false });
        });
    });
  }

  updateRules() {
    const { rules } = this.state;
    this.setState({ loadingRules: true }, () => {
      const parameters = {
        userType: 'client',
        updateFunctionality: 'updateRules',
        rules,
      };

      api
        .post(`/update`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({ loadingRules: false });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          console.log('updateRules error:', error);
          this.setState({ loadingRules: false });
        });
    });
  }

  handleRuleChange(e, ruleIndex) {
    const { name, value } = e.target;
    this.handleRuleDataChange(name, value, ruleIndex);
  }

  handleRuleDataChange(name, value, ruleIndex) {
    const { rules } = this.state;
    const rule = rules[ruleIndex];
    rule[name] = value;
    rules[ruleIndex] = rule;
    this.setState({ rules });
  }

  renderRule(rule, ruleIndex) {
    const { loadingRules, rules } = this.state;
    const previousRulesMaxPoints =
      ruleIndex === 0 ? 0 : rules[ruleIndex - 1].maxPoints;

    return (
      <div className="row">
        <div className="form-group col-md-1">
          {/* <input className="form-control profile-placeholder" type="color" disabled={true} value={rule.color} /> */}
          <div
            style={{ backgroundColor: rule.color, height: 34, borderRadius: 3 }}
          />
        </div>
        <div className="form-group col-md-3">
          <input
            className="form-control profile-placeholder"
            type="number"
            name="minPoints"
            onChange={(e) => this.handleRuleChange(e, ruleIndex)}
            value={Number(previousRulesMaxPoints) + 1}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <input
            className="form-control profile-placeholder"
            type={rule.maxPoints === 'Infinito' ? 'text' : 'number'}
            name="maxPoints"
            onChange={(e) => this.handleRuleChange(e, ruleIndex)}
            value={rule.maxPoints}
            disabled={!rule.editable || loadingRules}
          />
        </div>

        <div className="form-group col-md-3">
          <div className="input-group">
            <input
              className="form-control profile-placeholder"
              type="number"
              name="boost"
              onChange={(e) => this.handleRuleChange(e, ruleIndex)}
              value={rule.boost}
              disabled={loadingRules}
            />
            <div className="input-group-addon profile-add-on">%</div>
          </div>
        </div>
        <div className="col-md-12">
          <hr />
        </div>
      </div>
    );
  }

  renderRuleHeader() {
    return (
      <div className="row">
        <div className="form-group col-md-1">
          <label className="profile-label">Nível</label>
        </div>
        <div className="form-group col-md-3">
          <label className="profile-label">De (pontos)</label>
        </div>
        <div className="form-group col-md-3">
          <label className="profile-label">Até (pontos)</label>
        </div>
        <div className="form-group col-md-3">
          <label className="profile-label">
            Regra de Pontuação (adicional)
          </label>
        </div>
      </div>
    );
  }

  render() {
    const { rules, loadingRules } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <h1 className="oq-filter-title">
                <i className="fa fa-book" aria-hidden="true" />
                &nbsp;Regras de Pontuação
              </h1>
              <div className="oq-padding-vertical">
                {this.renderRuleHeader()}
                {_.map(rules, (rule, ruleIndex) =>
                  this.renderRule(rule, ruleIndex)
                )}
              </div>
              <div className="col-md-offset-8 col-md-4">
                <button
                  className="btn btn-oq btn-oq-lg btn-block"
                  disabled={loadingRules}
                  onClick={() => this.updateRules()}
                >
                  {loadingRules ? 'Atualizando regras...' : 'Atualizar regras'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PontuationRules;
