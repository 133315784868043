import styles from './style.module.css';
import React from 'react';
import { Link } from 'react-router-dom';
import SideBar from '../../../components/SideBar';
import NavBar from '../../../components/NavBar';

export function BusinessResults() {
  return (
    <>
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <div className={styles.bgHeader}>
              <br />
              <br />
              <h2 className="text-center text-white">
                Resultados da Publicação
              </h2>
              <p className="text-center text-white">
                Lorem ipsum, dolor sit amet consectetur.
              </p>
              <br />
              <br />
            </div>
            <div className="container">
              <div
                className="row"
                style={{ position: 'relative', top: '-30px' }}
              >
                <div className="col-md-3">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Visualizações</p>
                      <h1 className="text-center color-text-primary">1.000</h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Leads</p>
                      <h1 className="text-center color-text-primary">10</h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Conversões</p>
                      <h1 className="text-center color-text-primary">3</h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Compartilhamentos</p>
                      <h1 className="text-center color-text-primary">50</h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Grande interesse</p>
                      <h1 className="text-center color-text-primary">55</h1>
                      <div className="text-center">
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Médio interesse</p>
                      <h1 className="text-center color-text-primary">30</h1>
                      <div className="text-center">
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                        <i className="material-icons">star_rate</i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={styles.card}>
                    <div className={styles.cardBody}>
                      <p className="text-center">Baixo interesse</p>
                      <h1 className="text-center color-text-primary">7</h1>
                      <div className="text-center">
                        <i className="material-icons">star_rate</i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>Detalhes</h4>
              <br />
              <p style={{ fontWeight: 'bold' }}>Grande interesse</p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Cadastros</th>
                    <th>Favoritos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                </tbody>
              </table>
              <p style={{ fontWeight: 'bold' }}>Médio interesse</p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Cadastros</th>
                    <th>Favoritos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                </tbody>
              </table>
              <p style={{ fontWeight: 'bold' }}>Baixo interesse</p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Cadastros</th>
                    <th>Favoritos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>10/04/2023 - 14 cadastros</td>
                    <td>10/04/2023 - 14 favoritos</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
