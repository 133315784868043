import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';

import { Notify } from '../../../../components/Notify';
import { api, getAPIURL } from '../../../../Config';
import * as routes from '../../../../routes';
import { normalizeLowerCase } from '../../../../utils/normalizeLowerCase';
import { NavBar } from '../../../components/NavBar';
import { SideBar } from '../../../components/SideBar';
import { UserDataContext } from '../../../../hooks/useUserData';

const SelectOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span>{props.label}</span>
      </components.Option>
    </div>
  );
};

class NewPremium extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    const {
      match: { params },
    } = props;

    this.state = this.getInitialState(params);
    this.translate = props.t;
  }

  getInitialState(params) {
    const initialState = {
      authUser: {},
      formValid: false,
      formErrors: {},
      creatingPremium: false,
      isUploading: false,
      progress: 0,
      seasonId: params.seasonId,
      premium: [],
      formData: {
        scope: '',
        scopeValues: [],
        premiumName: '',
        premiumPrice: '',
        premiumQuantity: '',
        premiumImage: '',
        premiumDescription: '',
        premiumStatus: '',
        premiumType: '',
        paymentValue: '',
        paymentValueMinimun: '',
        valueType: '',
        firstRescue: '',
        // brand: '',
      },
    };

    return initialState;
  }

  componentDidMount() {
    const { clientData } = this.context;

    if (
      clientData.clientUrl === 'careplusclinic' ||
      clientData.clientUrl === 'primeclinic'
    ) {
      this.searchPremium();
    }
  }

  searchPremium() {
    this.setState({ searching: true }, () => {
      const parameters = {
        searchFunctionality: 'searchPremium',
        userType: 'client',
        seasonId: '',
        search: {},
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;

          if (!result.success) {
            this.setState({
              premium: [],
              searching: false,
            });

            return;
          }

          this.setState({
            premium: result.data,
            searching: false,
          });
        })
        .catch((error) => {
          this.setState({ searching: false });
          console.log('searchPremium error:', error);
        });
    });
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }
    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (this.inputIsNotRequired(inputKey) && !input) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  inputIsNotRequired(inputKey) {
    return (
      inputKey !== 'paymentValue' &&
      inputKey !== 'paymentValueMinimun' &&
      inputKey !== 'valueType' &&
      inputKey !== 'premiumPrice' &&
      inputKey !== 'firstRescue'
    );
  }

  handleUserInput(e) {
    const { name, value } = e.target;

    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  async createPremium() {
    await this.validateForm();
    const { formValid, formData, seasonId } = this.state;
    const { clientData } = this.context;

    if (!formValid) {
      Notify(
        'Verifique os campos antes de continuar a criação do prêmio',
        'warn'
      );
      return;
    }
    if (
      formData.premiumType &&
      formData.premiumType.value === this.translate('digital') &&
      !formData.brand
    ) {
      Notify('Você precisa preencher a marca do seu prêmio', 'warn');
      return;
    }
    if (
      formData.premiumType &&
      formData.premiumType.value === this.translate('BS2') &&
      !formData.paymentValue
    ) {
      if (formData.valueType.value === 'fixed') {
        Notify(
          'Você precisa preencher o valor do pagamento do seu prêmio',
          'warn'
        );
        return;
      }
    }

    const premium = formData;
    if (
      clientData.isGamified === 'true' &&
      clientData.gamificationType === 'points' &&
      seasonId
    ) {
      premium.seasonId = seasonId;
    } else premium.seasonId = false;

    const active = !!(
      formData.premiumStatus &&
      formData.premiumStatus.value === this.translate('active')
    );

    premium.active = active;

    // const type =
    //   formData.premiumType &&
    //   formData.premiumType.value === this.translate('digital')
    //     ? 'digital'
    //     : 'physical';

    // premium.type = type;

    this.setState({ createPremium: true }, () => {
      const parameters = {
        userType: 'client',
        searchFunctionality: 'createPremium',
        premium,
      };

      api
        .post(`/create`, parameters)
        .then((response) => {
          const result = response.data;
          this.setState({ createPremium: false });
          Notify(result.message, result.success ? 'success' : 'error');
          setTimeout(() => {
            if (premium.seasonId) {
              this.props.history.push(`${routes.PREMIUMS}/${premium.seasonId}`);
            } else {
              this.props.history.push(`${routes.PREMIUMS}`);
            }
          }, 3000);
        })
        .catch((error) => {
          console.log('createPremium error:', error);
          this.setState({ createPremium: false });
        });
    });
  }

  handleFormDataChange(name, value) {
    //premiumPrice, paymentValue
    if (value.value === 'variable') {
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: value,
            premiumPrice: '',
            paymentValue: '',
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    } else {
      this.setState(
        (prevState) => ({
          formData: {
            ...prevState.formData,
            [name]: value,
          },
        }),
        () => {
          this.validateField(name, value);
        }
      );
    }
  }

  async handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      // this.handleFormDataChangeImageLogin(name, value);
      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref) {
    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleFormDataChange(fileIndex, data.data);
    this.setState({ isUploading: false });
  }

  verifyIsShowSelectFirstPremium() {
    let isShowSelectFirstPremium = false;
    if (this.state.premium) {
      const { premium } = this.state;

      const filterFirstRescue = premium.filter(
        (award) => award.firstRescue && award.firstRescue.value === 'sim'
      );

      if (filterFirstRescue && filterFirstRescue.length === 0) {
        isShowSelectFirstPremium = true;
      }
    }

    return isShowSelectFirstPremium;
  }

  renderImageLogo() {
    const { formData } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group">
            {this.state.isUploading ? (
              <span className="small-oq text-center">
                {this.translate('sending')}
              </span>
            ) : (
              formData.premiumImage && (
                <img
                  src={formData.premiumImage}
                  className="img-responsive img-thumbnail"
                  alt="premium"
                />
              )
            )}
          </div>
          <div className="form-group">
            <label className="profile-label" htmlFor="premiumImage">
              Foto do Prêmio
            </label>
            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="premiumImage"
              onChange={async (event) => {
                await this.handleUserImage(
                  event,
                  `Premium/${this.state.authUser.uid}`,
                  'premiumImage'
                );
              }}
            />
          </div>
          <div className="col-md-12 form-group">
            <label
              className="btn btn-oq-back btn-block text-center"
              htmlFor="premiumImage"
              disabled={this.state.isUploading || this.state.creatingPremium}
            >
              <span className="small-oq">
                {!formData.premiumImage
                  ? 'ESCOLHA UMA IMAGEM'
                  : 'TROCAR IMAGEM'}
              </span>
            </label>
          </div>
        </div>
      </div>
    );
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          className="form-control profile-placeholder"
          name="scope"
          required={clientData.segmentationChoice === 'true'}
          onChange={(e) => this.handleScopeChange(e)}
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          <option value="regional">Segmentada</option>
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isMulti
            required
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            defaultValue={this.state.formData.scopeValues}
            onChange={(e) => this.handleFormDataChange('scopeValues', e)}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  renderPremiumForm() {
    const { clientData } = this.context;

    let isShowSelectFirstPremium;
    if (
      clientData.clientUrl === 'careplusclinic' ||
      clientData.clientUrl === 'primeclinic'
    ) {
      isShowSelectFirstPremium = this.verifyIsShowSelectFirstPremium();
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumName">
              Nome do Prêmio
            </label>
            <input
              className="form-control profile-placeholder"
              id="premiumName"
              maxLength={90}
              name="premiumName"
              placeholder="Nome do Prêmio"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.premiumName}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumPrice">
              Valor do Prêmio &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="max-prem-val"
              />
              <ReactTooltip
                id="max-prem-val"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('maximum-premium-value')} &nbsp;</span>
              </ReactTooltip>
            </label>
            <input
              disabled={this.state.formData.valueType.value === 'variable'}
              readOnly={this.state.formData.valueType.value === 'variable'}
              type="number"
              className="form-control profile-placeholder"
              id="premiumPrice"
              name="premiumPrice"
              placeholder="Valor do Prêmio"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.premiumPrice}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="premiumQuantity">
              Quantidade
            </label>
            <input
              type="number"
              className="form-control profile-placeholder"
              id="premiumQuantity"
              name="premiumQuantity"
              placeholder="Quantidade"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.premiumQuantity}
            />
          </div>
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="premiumDescription">
              {this.translate('Descrição')} &nbsp;
            </label>
            <textarea
              className="form-control profile-placeholder"
              placeholder={this.translate('Descrição')}
              rows="5"
              name="premiumDescription"
              onChange={(e) => this.handleUserInput(e)}
              value={this.state.formData.premiumDescription}
            />
          </div>
          {/* {segmentar aqui} */}
          {this.renderScope()}
          {clientData.isGamified === 'false' &&
            clientData.segmentationChoice === 'true' &&
            this.renderScopeForm()}
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="bannerName">
              {this.translate('status')}
            </label>
            <Select
              closeMenuOnSelect
              required
              value={this.state.formData.premiumStatus}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_status')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('premiumStatus', selectedOption)
              }
              options={[
                {
                  value: this.translate('active'),
                  label: this.translate('active'),
                },
                {
                  value: this.translate('inactive'),
                  label: this.translate('inactive'),
                },
              ]}
            />
          </div>

          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="premiumType">
              {this.translate('Premium type')}
            </label>
            <Select
              closeMenuOnSelect
              required
              value={this.state.formData.premiumType}
              components={{ SelectOption }}
              placeholder={this.translate('choose_premium_type')}
              onChange={(selectedOption) =>
                this.handleFormDataChange('premiumType', selectedOption)
              }
              options={[
                {
                  value: this.translate('digital'),
                  label: this.translate('Voucher'),
                },
                {
                  value: this.translate('physical'),
                  label: this.translate('physical'),
                },
                {
                  value: this.translate('BS2'),
                  label: this.translate('BS2'),
                },
              ]}
            />
          </div>

          {this.state.formData.premiumType.value === 'Físico' &&
            (clientData.clientUrl === 'careplusclinic' ||
              clientData.clientUrl === 'primeclinic') &&
            isShowSelectFirstPremium && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="premiumType">
                  Prêmio para primeiro resgate ?
                </label>
                <Select
                  closeMenuOnSelect
                  required
                  value={this.state.formData.firstRescue}
                  components={{ SelectOption }}
                  placeholder="Primeiro resgate"
                  onChange={(selectedOption) =>
                    this.handleFormDataChange('firstRescue', selectedOption)
                  }
                  options={[
                    {
                      value: 'sim',
                      label: 'Sim',
                    },
                    {
                      value: 'nao',
                      label: 'Não',
                    },
                  ]}
                />
              </div>
            )}

          {this.state.formData.premiumType.value === 'onlypay' && (
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="brand">
                Selecione o tipo do Prêmio
              </label>
              <Select
                closeMenuOnSelect
                required={true}
                name="awardType"
                value={this.state.formData.awardType}
                components={{ SelectOption }}
                placeholder="Selecione o tipo..."
                onChange={(selectedOption) =>
                  this.handleFormDataChange('awardType', selectedOption)
                }
                options={[
                  {
                    label: 'Selecione o tipo...',
                    value: '',
                  },
                  {
                    label: 'Voucher',
                    value: 1,
                  },
                  {
                    label: 'Cartão',
                    value: 2,
                  },
                  {
                    label: 'Cashback',
                    value: 3,
                  },
                ]}
              />
            </div>
          )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 1 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do Voucher
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="bodilyType"
                  value={this.state.formData.bodilyType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do voucher..."
                  onChange={(selectedOption) =>
                    this.handleFormDataChange('bodilyType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do voucher...',
                      value: '',
                    },
                    {
                      label: 'Voucher Fisico',
                      value: 1,
                    },
                    {
                      label: 'Voucher Virtual',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 1 &&
            this.state.formData.bodilyType &&
            this.state.formData.bodilyType.value === 2 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do Voucher Virtual
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="voucherType"
                  value={this.state.formData.voucherType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do voucher..."
                  onChange={(selectedOption) =>
                    this.handleFormDataChange('voucherType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do voucher...',
                      value: '',
                    },
                    {
                      label: 'Netflix',
                      value: 1,
                    },
                    {
                      label: 'Uber',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}
          {this.state.formData.awardType &&
            this.state.formData.awardType.value === 2 && (
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="brand">
                  Selecione o tipo do cartão
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="cardType"
                  value={this.state.formData.cardType}
                  components={{ SelectOption }}
                  placeholder="Selecione o tipo do cartão..."
                  onChange={(selectedOption) =>
                    this.handleFormDataChange('cardType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Selecione o tipo do cartão...',
                      value: '',
                    },
                    {
                      label: 'Cartão só compra',
                      value: 1,
                    },
                    {
                      label: 'Cartão compra e saque',
                      value: 2,
                    },
                  ]}
                />
              </div>
            )}
          {this.state.formData.premiumType.value === 'Digital' && (
            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="brand">
                {this.translate('Marca')}
              </label>
              <Select
                closeMenuOnSelect
                required={true}
                name="brand"
                value={this.state.formData.brand}
                components={{ SelectOption }}
                placeholder="Selecione a marca..."
                onChange={(selectedOption) =>
                  this.handleFormDataChange('brand', selectedOption)
                }
                options={[
                  {
                    label: 'Selecione a marca...',
                    value: '',
                  },
                  {
                    label: 'Americanas',
                    value: 'Americanas',
                  },
                  {
                    label: 'Shoptime',
                    value: 'Shoptime',
                  },
                  {
                    label: 'Submarino',
                    value: 'Submarino',
                  },
                ]}
              />
            </div>
          )}

          {this.state.formData.premiumType.value === 'BS2' && (
            <>
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="valueType">
                  Selecione o tipo do valor
                </label>
                <Select
                  closeMenuOnSelect
                  required={true}
                  name="valueType"
                  value={this.state.formData.valueType}
                  components={{ SelectOption }}
                  placeholder="Tipo do valor do prêmio e pagamento..."
                  onChange={(selectedOption) =>
                    this.handleFormDataChange('valueType', selectedOption)
                  }
                  options={[
                    {
                      label: 'Fixo',
                      value: 'fixed',
                    },
                    {
                      label: 'Variável',
                      value: 'variable',
                    },
                  ]}
                />
              </div>
              {this.state.formData.valueType.value === 'fixed' && (
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="paymentValue">
                    Valor do Pagamento &nbsp;
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      data-tip="React-tooltip"
                      data-for="paymentValue"
                    />
                    <ReactTooltip
                      id="paymentValue"
                      type="dark"
                      effect="solid"
                      className="tool-tip"
                      multiline
                    >
                      <span>
                        {' '}
                        Valor a ser depositado na conta do usuário. &nbsp;
                      </span>
                    </ReactTooltip>
                  </label>
                  <input
                    // disabled
                    // readOnly
                    type="number"
                    className="form-control profile-placeholder"
                    id="paymentValue"
                    name="paymentValue"
                    placeholder="Valor do Pagamento"
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </div>
              )}
              {this.state.formData.valueType.value === 'variable' && (
                <div className="form-group col-md-4">
                  <label className="profile-label" htmlFor="paymentValue">
                    Valor mínimo
                  </label>
                  <input
                    type="number"
                    className="form-control profile-placeholder"
                    id="paymentValueMinimun"
                    name="paymentValueMinimun"
                    placeholder="Valor mínimo"
                    onChange={(e) => this.handleUserInput(e)}
                  />
                </div>
              )}
            </>
          )}

          <div className="oq-padding-vertical">
            <div className="col-md-offset-7 col-md-5 text-center">
              <button
                type="button"
                className="btn btn-oq btn-oq-lg btn-block"
                disabled={
                  // !this.state.formValid ||
                  this.state.isUploading || this.state.createPremium
                }
                onClick={() => this.createPremium()}
              >
                <span>
                  {this.state.createPremium
                    ? 'Gravando prêmio...'
                    : 'Gravar Prêmio'}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12 col-md-3">{this.renderImageLogo()}</div>
          <div className="col-xs-12 col-md-9">{this.renderPremiumForm()}</div>
        </div>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; Voltar
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              {this.renderBackButton()}
              {this.renderForm()}
            </div>
          </div>
        </div>
      </div>

      // <div className="wrapper">
      //   <SideBar />
      //   <div id="content">
      //     <NavBar />
      //     <ToastContainer />
      //     <div className="container-fluid">
      //       {this.renderBackButton()}
      //       {this.renderForm()}
      //     </div>
      //   </div>
      // </div>
    );
  }
}

export default NewPremium;
