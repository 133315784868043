import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import { api } from '../../../../../Config';

import ActionAwards from '../../../../components/Modals/ActionAwards';
import ExtraPoints from '../../../../components/Modals/ExtraPoints';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

import { normalizeLowerCase } from '../../../../../utils/normalizeLowerCase';

class DetailsActionOmnichannel extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      actionId: '',
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      progress: 0,
      pointsList: [],
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      formData: {
        redirectWhatsapp: 'false',
        actionTitle: '',
        callToAction: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        season: {},
        videoURL: '',
        countDownVisible: true,
        shareButtonsVisible: true,
        // startDateVisible: true,
        description: '',
        pitchVerb: '',
        rulesOfAction: '',
        landingPagePreviewPoint: '',
        leadRegistration: '',
        salesConversion: '',
        conversionType: '',
        conversionFieldName: '',
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        extraFieldName1: '',
        hasAwards: 'false',
        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        phase1Uncompleted: false,
        phase2Uncompleted: false,
        phase3Uncompleted: false,
      },
    };

    return initialState;
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.getInitialState();

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
  }

  getActionById(actionId) {
    const parameters = {
      searchFunctionality: 'getAction',
      userType: 'client',
      actionId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const formData = res.data.data.action;

        if (formData.scope === 'regional') {
          this.getClientRegionalList();
        } else if (formData.scope === 'state') {
          this.getStateList();
        }

        this.setState({
          isLoading: false,
          formData,
        });
      })
      .catch((error) => {
        console.log('getActionById error:', error);
      });
  }

  getClientRegionalList() {
    const { userData, groupPermissions, clientData } = this.context;

    const clientId = userData.clientId;

    this.setState({ loadingRegionalList: true }, () => {
      const parameters = {
        searchFunctionality: 'getClient',
        userType: 'administrator',
        clientId,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const clientData = res.data.data;
          if (clientData) {
            this.setState({
              loadingRegionalList: false,
              regionalList: clientData.regional,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingRegionalList: false });
          console.log('getClientByUid error:', error);
        });
    });
  }

  handleScopeChange(e) {
    this.handleUserInput(e);
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { formData } = this.state;
    const { selectedStates } = formData;

    if (clientData.citySegmentationChoice !== 'true') return <></>;
    if (!selectedStates || !selectedStates.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          className="basic-multi-select"
          value={selectedStates}
          classNamePrefix="select"
        />
        {!selectedStates.length && (
          <small id="selectedStatesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )}
      </div>
    );
  }

  renderCitySelection() {
    const { clientData } = this.context;
    const { formData } = this.state;
    const { selectedCities } = formData;

    if (clientData.citySegmentationChoice !== 'true') return <></>;
    if (!selectedCities || !selectedCities.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedCities">
          Cidades que serão impactadas: <code>*</code>
        </label>
        <Select
          isDisabled
          isOptionDisabled={(option) => option.value}
          isMulti
          name="selectedCities"
          placeholder="Selecione quais cidades deseja atingir ..."
          className="basic-multi-select"
          value={selectedCities}
          classNamePrefix="select"
        />
        {!selectedCities.length && (
          <small id="selectedCitiesHelp" className="form-text text-muted">
            {this.translate('Este campo é obrigatório')}
          </small>
        )}
      </div>
    );
  }

  renderScope() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="form-group col-md-6">
        <label className="profile-label" htmlFor="scope">
          {this.translate('comprehensiveness')} <code>*</code>
        </label>
        <select
          disabled
          readOnly
          className="form-control profile-placeholder"
          name="scope"
          value={formData.scope}
        >
          <option value="">{this.translate('select')}...</option>
          <option value="national">{this.translate('national')}</option>
          {clientData.regional && clientData.regional.length && (
            <option value="regional">
              {clientData && clientData.scopeFieldName
                ? clientData.scopeFieldName
                : this.translate('Unidade')}
            </option>
          )}
        </select>
      </div>
    );
  }

  renderScopeForm() {
    const { scopeFieldName, regional } = this.context.clientData;
    const { scope, scopeValues } = this.state.formData;
    return (
      scope === 'regional' && (
        <div className="form-group col-md-12">
          <label className="profile-label" htmlFor="scopeValue">
            {scopeFieldName} <code>*</code>
          </label>
          <Select
            isDisabled
            isOptionDisabled={(option) => option.value}
            isMulti
            name="scopeValue"
            placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
            options={_.map(regional, (regional) => {
              return {
                value: normalizeLowerCase(regional),
                label: regional,
              };
            })}
            className="basic-multi-select"
            value={this.state.formData.scopeValues}
            classNamePrefix="select"
          />
          {scope === 'regional' && !scopeValues.length && (
            <small id="companyNameHelp" className="form-text text-muted">
              {this.translate('Este campo é obrigatório')}
            </small>
          )}
        </div>
      )
    );
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  renderPhase1() {
    const { formData } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="false"
        >
          Copywriting
        </h4>
        <div id="phase1" aria-expanded="false" className="collapse">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                {this.translate('Título da copy')} <code>*</code>
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 72 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="call-action"
                />
                <ReactTooltip
                  id="call-action"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Texto que seu público-alvo vê junto com o link compartilhado
                    pelo usuário
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('title')}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="callToAction">
                {this.translate(
                  'Call to action para participação da publicação'
                )}{' '}
                &nbsp;
                <span style={{ fontSize: '10px', display: 'contents' }}>
                  (Até 38 caracteres)
                </span>
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="callToAction"
                />
                <ReactTooltip
                  id="callToAction"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>
                    {this.translate('call to action ex Compartilhar agora')}
                  </span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="callToAction"
                value={this.state.formData.callToAction}
                placeholder="Ex: 'Participar', 'Jogar Agora'... "
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="videoURL">
                {this.translate('Link do Vídeo')}&nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="videoLink"
                />
                <ReactTooltip
                  id="videoLink"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('video-link-text1')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="url"
                className="form-control profile-placeholder"
                name="videoURL"
                placeholder={this.translate('Link do Vídeo')}
                value={formData.videoURL}
              />
            </div>
            {clientData.isGamified === 'true' && (
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="season">
                  {this.translate('Season')} <code>*</code>
                </label>
                <select
                  className="form-control profile-placeholder"
                  name="season"
                  required
                  disabled
                >
                  <option value="regional" selected>
                    {formData.season
                      ? formData.season.seasonName
                      : 'Não definido'}
                  </option>
                </select>
              </div>
            )}
            {clientData.isGamified === 'false' && this.renderScope()}
            {clientData.isGamified === 'false' && this.renderScopeForm()}
            {clientData.isActiveRegisterStateAndCity &&
              clientData.isActiveRegisterStateAndCity === 'true' && (
                <>
                  {this.state.formData.scope !== 'national' &&
                    this.renderStateSelection()}
                  {this.state.formData.scope !== 'national' &&
                    this.renderCitySelection()}
                </>
              )}
            {/* {this.state.formData.scope !== 'national' &&
              this.renderStateSelection()}
            {this.state.formData.scope !== 'national' &&
              this.renderCitySelection()} */}
          </div>

          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')} 1 <code>*</code>
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span className="span-oq-create-action">
                  Imagens com até 72 K são as mais indicadas para
                  recompartilhamentos. Acima desta medida, a publicação das
                  mesmas nas redes sociais não é garantida.
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>
            {formData.files &&
              formData.files.image1 &&
              this.renderImagePreview(formData.files.image1)}
            <input
              disabled
              readOnly
              type="file"
              accept="image/*"
              id="profilePic"
            />
            <span className="span-oq-create-action">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>

          <div className="col-md-12 row p-0-right">
            <div className="daterow">
              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="startDate">
                  {this.translate('start-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="startDate"
                  placeholder={this.translate('start-date')}
                  value={formData.startDate}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="profile-label" htmlFor="startDateTime">
                  {this.translate('start-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="startDateTime"
                  placeholder={this.translate('start-time')}
                  value={formData.startDateTime}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-1"
                >
                  {this.state.msg1}
                </span>
              </div>

              <div className="form-group col-md-4">
                <label className="profile-label" htmlFor="endDate">
                  {this.translate('end-date')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="date"
                  className="form-control profile-placeholder"
                  name="endDate"
                  placeholder={this.translate('end-date')}
                  value={formData.endDate}
                />
                <span
                  className="help-block hidden"
                  id="handleDateChangeValid-3"
                >
                  {this.state.msg3}
                </span>
              </div>

              <div className="form-group col-md-2 ">
                <label className="profile-label" htmlFor="endDateTime">
                  {this.translate('end-time')} <code>*</code>
                </label>
                <input
                  disabled
                  readOnly
                  type="time"
                  className="form-control profile-placeholder"
                  name="endDateTime"
                  placeholder={this.translate('end-time')}
                  value={formData.endDateTime}
                />
                {this.state.errorTime1 && (
                  <span className="help-block" id="handleTimeChangeValid-1">
                    {this.state.errorTimeMsg1}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderPhase2() {
    const { formData } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase2"
          aria-expanded="false"
        >
          Detalhes da publicação
        </h4>
        <div id="phase2" aria-expanded="false" className="collapse">
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="countDownVisible">
              {this.translate(
                'Exibir cronômetro regressivo informando o tempo para o término do desafio?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="countDownVisible"
              />
            </label>
            <ReactTooltip
              id="countDownVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('countDownVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="countDownVisible"
                    checked={this.state.formData.countDownVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="countDownVisible"
                    checked={this.state.formData.countDownVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>
          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="shareButtonsVisible">
              {this.translate(
                'Exibir botões de compartilhamento com terceiros?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="shareButtonsVisible"
              />
            </label>
            <ReactTooltip
              id="shareButtonsVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('shareButtonsVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="shareButtonsVisible"
                    checked={
                      this.state.formData.shareButtonsVisible !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="shareButtonsVisible"
                    checked={
                      this.state.formData.shareButtonsVisible === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="startDateVisible">
              {this.translate(
                'Data de início visível para o usuário no card da ação?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="startDateVisible"
              />
            </label>
            <ReactTooltip
              id="startDateVisible"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('startDateVisible-info')}</span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value
                    name="startDateVisible"
                    checked={this.state.formData.startDateVisible !== 'false'}
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    disabled
                    readOnly
                    type="radio"
                    value={false}
                    name="startDateVisible"
                    checked={this.state.formData.startDateVisible === 'false'}
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div
            className="form-group points-by-share col-md-12"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <label className="profile-label" htmlFor="redirectWhatsapp">
              {this.translate(
                'Habilitar o contato direto do lead com o vendedor?'
              )}
              &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="call-action"
              />
            </label>
            <ReactTooltip id="call-action" type="dark" effect="solid" multiline>
              <span>
                {this.translate(
                  'Ao habilitar esta função, o lead terá a opção de falar, via WhatsApp, direto com o vendedor que compartilhou o conteúdo, agilizando o primeiro contato.'
                )}
              </span>
            </ReactTooltip>
            <div className="form-group col-md-3">
              <div className="profile-placeholder d-flex justify-content-between">
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value
                    disabled
                    readOnly
                    name="redirectWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp !== 'false'
                    }
                  />
                  &nbsp;{this.translate('yes')}
                </label>
                <label className="checkbox-inline">
                  <input
                    type="radio"
                    value={false}
                    disabled
                    readOnly
                    name="redirectWhatsapp"
                    onChange={(e) => this.handleUserInput(e)}
                    checked={
                      this.state.formData &&
                      this.state.formData.redirectWhatsapp &&
                      this.state.formData.redirectWhatsapp === 'false'
                    }
                  />
                  &nbsp;{this.translate('no')}
                </label>
              </div>
            </div>
          </div>

          <div className="form-group col-md-12">
            <div className="pitch-verb w-100">
              <label className="profile-label" htmlFor="pitch">
                Por que... (para o público-alvo) <code>*</code> &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="description"
                  style={{ marginRight: 10 }}
                />
                <ReactTooltip
                  id="description"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  Coloque aqui um verbo para complementar. Ex.: Por que...
                  comprar?; Por que... contratar?; Por que... se associar?
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                className="input-pitch"
                placeholder="Verbo"
                name="pitchVerb"
                value={formData.pitchVerb}
                style={{ marginLeft: 10 }}
              />
            </div>
            <textarea
              disabled
              readOnly
              className="form-control profile-placeholder"
              placeholder={this.translate('Texto com argumento de venda')}
              rows="5"
              name="description"
              value={formData.description}
            />
          </div>

          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="rulesOfAction">
              {this.translate('Regras da Ação')} <code>*</code> &nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="RulesOfAction"
              />
            </label>
            <ReactTooltip
              id="RulesOfAction"
              type="dark"
              effect="solid"
              className="tool-tip"
              multiline
            >
              <span>{this.translate('rules-of-action-text1')} &nbsp;</span>
            </ReactTooltip>
            <textarea
              disabled
              readOnly
              className="form-control profile-placeholder"
              placeholder={this.translate('Regras da Ação')}
              rows="5"
              name="rulesOfAction"
              value={formData.rulesOfAction}
            />
          </div>
          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="dataSheet">
                {this.translate('Tipo da Ficha Técnica')} &nbsp;
                <i
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="dataSheet"
                />
                <ReactTooltip
                  id="dataSheet"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('dataSheet-text1')} &nbsp;</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="conversionFieldName"
                placeholder="Ex: Regulamento"
                value={formData.conversionFieldName}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="dataSheet">
              {formData.conversionFieldName
                ? formData.conversionFieldName
                : this.translate('Ficha Técnica')}{' '}
              <code>*</code>&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="technicalFix"
              />
              <ReactTooltip
                id="technicalFix"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('technical-fix-text1')} &nbsp;</span>
              </ReactTooltip>
            </label>
          </div>

          <div>
            <div className="form-group col-md-6">
              <label className="profile-label" htmlFor="extraField1">
                {this.translate('Nome do Campo Extra')}&nbsp;
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="fa fa-info-circle"
                  aria-hidden="true"
                  data-tip="React-tooltip"
                  data-for="extraField1"
                />
                <ReactTooltip
                  id="extraField1"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>{this.translate('extraFieldName1-omni')}</span>
                </ReactTooltip>
              </label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control profile-placeholder"
                name="extraFieldName1"
                placeholder="Ex: Loja, Região, Bairro..."
                value={this.state.formData.extraFieldName1}
              />
            </div>
          </div>

          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="extraFieldSheet1">
              {this.state.formData.extraFieldName1
                ? this.state.formData.extraFieldName1
                : this.translate('Campo Extra')}{' '}
              &nbsp;
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="extraField1"
              />
              <ReactTooltip
                id="extraFieldSheet1"
                type="dark"
                effect="solid"
                className="tool-tip"
                multiline
              >
                <span>{this.translate('extraFieldSheet1-omni')}</span>
              </ReactTooltip>
            </label>
            <input
              disabled
              readOnly
              type="file"
              id="extraFieldSheet1"
              className="form-control profile-placeholder"
              name="extraFieldSheet1"
            />
          </div>
        </div>

        <hr />
      </div>
    );
  }

  renderPhase3() {
    const { formData } = this.state;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable collapsed"
          data-toggle="collapse"
          data-target="#phase3"
          aria-expanded="false"
        >
          Regras de Pontuação
        </h4>
        <div id="phase3" aria-expanded="false" className="collapse">
          <div className="col-md-12 row p-0-right">
            <div className="form-group col-md-6 p-0-right">
              <label className="profile-label" htmlFor="validationPoints">
                {this.translate('Pontos por validação')} <code>*</code>
              </label>
              <input
                disabled
                readOnly
                type="number"
                className="form-control profile-placeholder"
                name="validationPoints"
                value={formData.validationPoints}
                placeholder={this.translate('Pontos por validação')}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return (
      <div className="container-fluid">
        <div className="col-md-12">
          {this.renderPhase1()}
          {this.renderPhase2()}
          {this.renderPhase3()}
        </div>
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </div>
    );
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive form-group actim"
        alt="ation banner"
      />
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    const { formData, awardsModalIsOpen, extraPointsModalIsOpen } = this.state;
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span style={{ fontWeight: 'bold' }}>
                  &nbsp; Detalhes da publicação
                </span>
                <code>*</code>
                <span className="filds-mandatory">Campos obrigatórios</span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
          <ActionAwards
            modalIsOpen={awardsModalIsOpen}
            awardsList={formData.awardsList}
            updateAwardsList={(awardsList) =>
              this.handleFormDataChange('awardsList', awardsList)
            }
            closeModal={() => this.handleModalToggle('awardsModalIsOpen')}
          />
          <ExtraPoints
            modalIsOpen={extraPointsModalIsOpen}
            extraPointsList={formData.extraPointsList}
            updateAwardsList={(extraPointsList) =>
              this.handleFormDataChange('extraPointsList', extraPointsList)
            }
            closeModal={() => this.handleModalToggle('extraPointsModalIsOpen')}
          />
        </div>
      </div>
    );
  }
}

export default DetailsActionOmnichannel;
