import React, { Component } from 'react';
import NavBar from '../NavBar';
import { ToastContainer, toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import _ from 'lodash';
import __ from 'underscore';
import axios from 'axios';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import XLSX from 'xlsx';
import { SketchPicker, SwatchesPicker } from 'react-color';
import Color from 'color';
import Select, { components } from 'react-select';
import Nestable from 'react-nestable';
import SideBar from '../SideBar';

import * as mask from '../../utils/Mascaras';
import { Notify } from '../../components/Notify';
import { api, getAPIURL } from '../../Config';
import { UserDataContext } from '../../hooks/useUserData';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <span style={{ fontFamily: `${props.value} Regular` }}>
          <b>({props.label})</b> - Seu texto ficará assim!
        </span>
      </components.Option>
    </div>
  );
};

class CreateClientPage extends Component {
  static contextType = UserDataContext;
  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;
    this.fileSelector = null;
    this.imgEl = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      imageFormData: null,
      imageSelected: false,
      formErrors: {},
      clientId: null,
      formValid: false,
      isLoading: true,
      cityList: [],
      stateList: [],
      loadingStateList: false,
      loadingCityList: false,
      clientData: {},
      parsingRegionals: false,
      loadingAuthorizedUsers: false,
      isUploading: false,
      progress: 0,
      authorizedUsers: [],
      grayScale: [],
      formData: {
        companyName: '',
        companyInscription: '',
        stateInscription: '',
        cityInscription: '',
        address: '',
        country: 'Brasil',
        state: '',
        city: '',
        accountableName: '',
        accountablePosition: '',
        accountableEmail: '',
        phoneNumber: '',
        contactEmail: '',
        password: '',
        confirmPassword: '',
        active: true,
        targetPublic: '',
        primaryColor: '#000000',
        secondaryColor: '#ffffff',
        scopeFieldName: 'Unidades',
        regional: [],
        clientUrl: '',
        steps: [
          {
            name: '',
            value: 0,
          },
          {
            name: '',
            value: 0,
          },
          {
            name: '',
            value: 0,
          },
          {
            name: '',
            value: 0,
          },
        ],
        sendEmail: 'true',
        // coinName: "",
        moduleBenefits: 'false',
        moduleIndications: 'false',
        moduleEngagements: 'false',
        blockchainStatus: '',
        useIntegration: '',
        segmentationChoice: 'false',
        secondarySegmentationChoice: 'false',
        citySegmentationChoice: 'true',
        nameSecondarySegmentation: '',
        secondarySegmentation: [],
      },
      nameItemSecondarySegmentation: '',
    };
    return initialState;
  }

  componentDidMount() {
    this.getStateList();
    this.fileSelector = this.buildFileSelector();
    this.generateGrayScaleColors();
  }

  getCityList(estado = 'MG') {
    console.log('estado: ', estado);
    this.setState({ loadingCityList: true }, () => {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estado}/municipios`
        )
        .then((city) => {
          this.setState({ cityList: city.data, loadingCityList: false });
        })
        .catch((error) => {
          this.setState({ loadingCityList: false });
          console.log('getCityList error:', error);
        });
    });
  }

  getStateList() {
    this.setState({ loadingStateList: true }, () => {
      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then((states) => {
          let sortedStates = _.sortBy(states.data, 'nome');
          this.setState({ stateList: sortedStates, loadingStateList: false });
        })
        .catch((error) => {
          this.setState({ loadingStateList: false });
          console.log('getStateList error:', error);
        });
    });
  }

  handleFormDataChange(name, value) {
    const { city } = this.state.formData;
    const { stateList, client } = this.state;

    let chosenState = __.findWhere(stateList, { sigla: value });
    if (name === 'state' && value) {
      this.getCityList(chosenState.sigla);
    } else if (name === 'state' && !value) {
      this.setState({ cityList: [] });
    }
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUserInput(e) {
    const { name, value } = e.target;
    this.handleFormDataChange(name, value);
  }

  // getState(item) {
  // 	return _.get(this.state, item, "");
  // }

  renderItem = ({ item }) => (
    <div>
      <div>- {item.text} </div>
    </div>
  );

  addItem = () => {
    const { secondarySegmentation } = this.state.formData;
    const data = this.state.nameItemSecondarySegmentation;

    secondarySegmentation.push({
      id: Math.floor(Math.random() * 10000000000),
      text: data,
    });

    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        secondarySegmentation,
      },
      nameItemSecondarySegmentation: '',
    }));
  };

  getError(item) {
    return _.get(this.state.formErrors, item, ' ');
  }

  validateField(fieldName, value) {
    const fieldValidationErrors = this.state.formErrors;

    if (value.length <= 0) {
      fieldValidationErrors[fieldName] = this.translate(
        'Você precisa preencher esse campo.'
      );
    } else {
      fieldValidationErrors[fieldName] = '';
    }

    this.setState({ formErrors: fieldValidationErrors }, () => {
      this.validateForm();
    });
  }

  validateForm() {
    let emptyFieldsCount = 0;
    _.each(this.state.formData, (input, inputKey) => {
      if (
        inputKey !== 'regional' &&
        inputKey !== 'favIcon' &&
        inputKey !== 'actionToPoint' &&
        inputKey !== 'nameSecondarySegmentation' &&
        inputKey != 'sendEmailToAdmins' &&
        !input
      ) {
        emptyFieldsCount++;
      }
    });
    this.setState({ formValid: emptyFieldsCount === 0 });
  }

  createClient() {
    if (!this.state.formValid) {
      Notify(
        'Você precisa preencher todos os dados antes de continuar.',
        'warn'
      );
      return;
    }

    this.toastId = toast.info('Criando cliente...', { autoClose: false });

    const newClient = this.state.formData;
    const { authorizedUsers } = this.state;
    newClient.sendEmailToAdmins = false;

    // if (newClient.regional.length) {
    // 	newClient.segmentationChoice = 'true'
    // } else {
    // 	newClient.segmentationChoice = 'false'
    // }

    const parameters = {
      clientData: newClient,
      authorizedUsers,
      userType: 'administrator',
      searchFunctionality: 'createClient',
    };

    api
      .post(`/createClient`, parameters)
      .then((response) => {
        const result = response.data;
        toast.update(this.toastId, {
          render: result.message,
          type: result.success ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
          autoClose: 5000,
        });
      })
      .catch((error) => {
        console.log('createClient error:', error);
      });
  }

  updateFormData(name, value) {
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleUploadFile(event) {
    const data = new FormData();
    const file = event.target.files[0];
    data.append('logoImage', file);

    this.setState({ imageFormData: data, imageSelected: true });
  }

  handleDateChange(e) {
    const { name, value } = e.target;
    const isValidDate = moment(value).isValid();
    if (!isValidDate) {
      return;
    }

    const expirationDate = moment(value).valueOf();
    this.setState(
      (prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
          expirationDateEpoch: expirationDate,
        },
      }),
      () => {
        this.validateField(name, value);
      }
    );
  }

  handleTagsChange(tags) {
    this.updateFormData('regional', tags);
  }

  fileChanged = (file, field) => {
    if (file && file[0]) {
      const _file = file[0];
      const ext = _file.name.split('.').pop();
      if (ext === 'xls' || ext === 'xlsx') {
        this.parseFile(_file, field);
      }
    }
  };

  parseFile(file, field) {
    if (field === 'regional') {
      this.setState({ parsingRegionals: true });
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: true });
    }

    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const result = [];

      let binary = '';
      const bytes = new Uint8Array(e.target.result);
      const length = bytes.byteLength;
      for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }

      const oFile = XLSX.read(binary, {
        type: 'binary',
        cellDates: true,
        cellStyles: true,
      });
      oFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(oFile.Sheets[sheetName], {
          header: 1,
        });
        if (roa.length) {
          result.push(roa);
        }
      });

      this.parseData(result, field);
    };

    fileReader.readAsArrayBuffer(file);
  }

  parseData(xlsxParsed, field) {
    let items = [];

    xlsxParsed.forEach((sheet) => {
      const _items = _.map(sheet, (item) => {
        if (field === 'authorizedUsers') {
          return {
            name: item[0],
            cpf: item[1],
          };
        }

        return item[0];
      });

      items = _.concat(items, _items);
    });

    if (field === 'regional') {
      this.setState({ parsingRegionals: false });
      this.handleFormDataChange(field, items);
    } else if (field === 'authorizedUsers') {
      this.setState({ loadingAuthorizedUsers: false, authorizedUsers: items });
    }
  }

  buildFileSelector() {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('name', 'authorizedUsers');
    fileSelector.onchange = (e) =>
      this.fileChanged(e.target.files, 'authorizedUsers');
    return fileSelector;
  }

  async handleUserImage(e, ref, fileIndex) {
    const { name, size } = e.target.files[0];
    const randomizeName =
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '') +
      '.' +
      name.split('.')[1];
    this.setState({ isUploading: true });
    if (size < 1000000) {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          fileIndex: randomizeName,
        },
      }));

      await this.handleUploadSuccess(
        e.target.files[0],
        randomizeName,
        fileIndex,
        ref
      );
    } else {
      this.setState({ isUploading: false });
      return alert('TAMANHO DO ARQUIVO EXCEDE O PERMITIDO (1MB)!');
    }
  }

  async handleUploadSuccess(file, filename, fileIndex, ref) {
    this.setState({ avatar: filename, progress: 100, isUploading: false });

    const fileFormData = new FormData();
    fileFormData.append('userType', 'client');
    fileFormData.append('uploadFunctionality', 'uploadImage');
    fileFormData.append('ref', ref);
    fileFormData.append('file', file);
    fileFormData.set('Content-Type', 'multipart/form-data');

    const requestOptions = {
      method: 'POST',
      body: fileFormData,
    };

    const response = await fetch(`${getAPIURL()}/upload`, requestOptions);
    const data = await response.json();

    this.handleFormDataChange(fileIndex, data.data);
  }

  handleProgress(progress) {
    this.setState({ progress });
  }

  handleUploadError(error) {
    // NotifyUpdate("Tivemos um problema ao salvar sua foto, tente novamente ...", "error", this.nId, true);
    this.setState({ isUploading: false });
    console.log('upload handleUploadError:', error);
  }

  handleUploadStart() {
    // this.nId = Notify("Carregando foto aguarde ...", "loading", false);
    this.setState({ isUploading: true, progress: 0 });
  }

  renderImagePreview(img, id) {
    return (
      <img
        src={img}
        id={id}
        ref={(el) => {
          this.imgEl = el;
        }}
        onLoad={async () => {
          const res = await this.imageIsSquare(id);

          if (!res && id === 'favIcon')
            Notify('O ícone da aba não está quadrado.', 'warn');
        }}
        className="col-xs-12 img-thumbnail img-responsive form-group"
        alt="client favicon"
      />
    );
  }

  async imageIsSquare(id) {
    if (
      id !== 'favIcon' ||
      !this.imgEl ||
      !this.imgEl.naturalHeight ||
      !this.imgEl.naturalWidth
    ) {
      return false;
    }

    return this.imgEl.naturalHeight === this.imgEl.naturalWidth;
  }

  // renderScopeForm() {
  //   const { scopeFieldName, regional } = this.state.client;
  //   const { scope, scopeValues } = this.state.formData;

  //   return (
  //     scope === 'regional' && (
  //       <div className="form-group col-md-12">
  //         <label className="profile-label" htmlFor="scopeValue">
  //           {scopeFieldName} <code>*</code>
  //         </label>
  //         <Select
  //           isMulti
  //           name="scopeValue"
  //           placeholder={`Selecione quais ${scopeFieldName} deseja atingir ...`}
  //           options={_.map(regional, regional => {
  //             return {
  //               value: this.normalizeString(regional),
  //               label: regional,
  //             };
  //           })}
  //           className="basic-multi-select"
  //           defaultValue={this.state.formData.scopeValues}
  //           onChange={e => this.handleFormDataChange('scopeValues', e)}
  //           classNamePrefix="select"
  //         />
  //         {scope === 'regional' && !scopeValues.length && (
  //           <small id="companyNameHelp" className="form-text text-muted">
  //             {this.translate('Este campo é obrigatório')}
  //           </small>
  //         )}
  //       </div>
  //     )
  //   );
  // }

  renderForm() {
    const { parsingRegionals, formData } = this.state;

    return (
      <div className="col-md-10">
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="logo">
              Logomarca
            </label>
            <p className="tipText">Recomendado: 680x240px </p>
            {formData &&
              formData.logoURL &&
              this.renderImagePreview(formData.logoURL, 'logoURL')}

            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="logoURL"
              onChange={async (event) => {
                await this.handleUserImage(
                  event,
                  `ClientsLogo/default/`,
                  'logoURL'
                );
              }}
            />

            {this.getError('logo') && (
              <small id="logoHelp" className="form-text text-muted">
                {this.getError('logo')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="logo">
              Ícone da aba
            </label>
            <p className="tipText">Recomendado: 512x512px </p>
            {formData &&
              formData.favIcon &&
              this.renderImagePreview(formData.favIcon, 'favIcon')}

            <input
              className="hidden"
              type="file"
              accept="image/*"
              id="favIcon"
              onChange={async (event) => {
                await this.handleUserImage(
                  event,
                  `ClientsFavIcon/default/`,
                  'favIcon'
                );
              }}
            />

            {this.getError('favIcon') && (
              <small id="favIconHelp" className="form-text text-muted">
                {this.getError('favIcon')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="companyName">
              Nome da empresa
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="companyName"
              name="companyName"
              aria-describedby="companyNameHelp"
              value={this.getState('formData.companyName')}
              placeholder="Nome da Empresa"
            />
            {this.getError('companyInscription') && (
              <small id="companyNameHelp" className="form-text text-muted">
                {this.getError('companyName')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="companyInscription">
              CNPJ
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                let maskCNPJ = mask.maskCNPJ(input.value);
                this.updateFormData(input.name, maskCNPJ);
              }}
              maxLength={18}
              minLength={18}
              id="companyInscription"
              name="companyInscription"
              aria-describedby="companyInscriptionHelp"
              value={this.getState('formData.companyInscription')}
              placeholder="CNPJ"
            />
            {this.getError('companyInscription') && (
              <small
                id="companyInscriptionHelp"
                className="form-text text-muted"
              >
                {this.getError('companyInscription')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="stateInscription">
              Inscrição Estadual
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="stateInscription"
              name="stateInscription"
              aria-describedby="stateInscriptionHelp"
              value={this.getState('formData.stateInscription')}
              placeholder="Inscrição Estadual"
            />

            <small id="stateInscriptionHelp" className="form-text text-muted">
              {this.getError('stateInscription')}
            </small>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="cityInscription">
              Inscrição Municipal
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="cityInscription"
              name="cityInscription"
              aria-describedby="cityInscriptionHelp"
              value={this.getState('formData.cityInscription')}
              placeholder="Inscrição Municipal"
            />
            {this.getError('cityInscription') && (
              <small id="cityInscriptionHelp" className="form-text text-muted">
                {this.getError('cityInscription')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="cep">
              CEP
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                mask.maskCEP(input.value).then((masked) => {
                  this.updateFormData(input.name, masked);
                });
              }}
              maxLength={9}
              minLength={9}
              id="cep"
              name="cep"
              aria-describedby="cepHelp"
              value={this.getState('formData.cep')}
              placeholder="00000-000"
            />
            {this.getError('cep') && (
              <small id="cepHelp" className="form-text text-muted">
                {this.getError('cep')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="address">
              Endereço
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="address"
              name="address"
              aria-describedby="addressHelp"
              value={this.getState('formData.address')}
              placeholder="Endereço"
            />
            {this.getError('address') && (
              <small id="addressHelp" className="form-text text-muted">
                {this.getError('address')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="neighborhood">
              Bairro
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="neighborhood"
              name="neighborhood"
              aria-describedby="neighborhoodHelp"
              value={this.getState('formData.neighborhood')}
              placeholder="Bairro"
            />
            {this.getError('counneighborhoodtry') && (
              <small id="neighborhoodHelp" className="form-text text-muted">
                {this.getError('neighborhood')}
              </small>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="number">
              Nº
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="number"
              name="number"
              aria-describedby="numberHelp"
              value={this.getState('formData.number')}
              placeholder="Nº"
            />
            {this.getError('number') && (
              <small id="numberHelp" className="form-text text-muted">
                {this.getError('number')}
              </small>
            )}
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="complement">
              Complemento
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="complement"
              name="complement"
              aria-describedby="complementHelp"
              value={this.getState('formData.complement')}
              placeholder="Complemento"
            />
            {this.getError('complement') && (
              <small id="complementHelp" className="form-text text-muted">
                {this.getError('complement')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="state">
              Estado
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              disabled={
                this.state.loadingData ||
                this.state.loadingCityList ||
                this.state.alreadySetCityAndState
              }
              id="state"
              name="state"
              aria-describedby="stateHelp"
              value={this.getState('formData.state')}
            >
              <option value="">
                {' '}
                {this.state.loadingStateList
                  ? 'Carregando estados...'
                  : 'Escolha um estado...'}
              </option>
              {this.state.stateList &&
                _.map(this.state.stateList, (state) => {
                  return (
                    <option key={state.id} value={state.sigla}>
                      {' '}
                      {state.nome}
                    </option>
                  );
                })}
            </select>
            {this.getError('state') && (
              <small id="stateHelp" className="form-text text-muted">
                {this.getError('state')}
              </small>
            )}
          </div>
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="city">
              Cidade
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="city"
              name="city"
              disabled={
                this.state.loadingData ||
                this.state.loadingCityList ||
                this.state.alreadySetCityAndState
              }
              aria-describedby="cityHelp"
              value={this.getState('formData.city')}
            >
              <option value="">
                {' '}
                {this.state.loadingCityList
                  ? 'Carregando cidades...'
                  : 'Escolha uma cidade...'}
              </option>
              {this.state.cityList &&
                _.map(this.state.cityList, (city) => {
                  return (
                    <option key={city.nome} value={city.nome}>
                      {city.nome}
                    </option>
                  );
                })}
            </select>
            {this.getError('city') && (
              <small id="cityHelp" className="form-text text-muted">
                {this.getError('city')}
              </small>
            )}
          </div>
          <div className="form-group col-md-4">
            <label className="profile-label" htmlFor="country">
              País
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              disabled
              id="country"
              name="country"
              aria-describedby="countryHelp"
              value={this.getState('formData.country')}
              placeholder="País"
            />
            {this.getError('country') && (
              <small id="countryHelp" className="form-text text-muted">
                {this.getError('country')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountableName">
              Nome do Responsável
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="accountableName"
              name="accountableName"
              aria-describedby="accountableNameHelp"
              value={this.getState('formData.accountableName')}
              placeholder="Nome do Responsável"
            />
            {this.getError('accountableName') && (
              <small id="accountableNameHelp" className="form-text text-muted">
                {this.getError('accountableName')}
              </small>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="contactEmail">
              E-mail para contato&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="contactEmail-i"
              />
            </label>
            <ReactTooltip
              id="contactEmail-i"
              type="dark"
              effect="solid"
              multiline
            >
              <span>{this.translate('contact-email-info')}</span>
            </ReactTooltip>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              type="email"
              id="contactEmail"
              name="contactEmail"
              aria-describedby="contactEmailHelp"
              value={this.getState('formData.contactEmail')}
              placeholder="faleconosco@empresa.com.br"
            />
            {this.getError('contactEmail') && (
              <small id="contactEmailHelp" className="form-text text-muted">
                {this.getError('contactEmail')}
              </small>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountablePosition">
              Cargo
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="accountablePosition"
              name="accountablePosition"
              aria-describedby="accountablePositionHelp"
              value={this.getState('formData.accountablePosition')}
              placeholder="Cargo"
            />
            {this.getError('accountablePosition') && (
              <small
                id="accountablePositionHelp"
                className="form-text text-muted"
              >
                {this.getError('accountablePosition')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="profile-label" htmlFor="accountableEmail">
              E-mail de acesso:
            </label>
            <input
              className="form-control profile-placeholder"
              type="email"
              onChange={(event) => this.handleUserInput(event)}
              id="accountableEmail"
              name="accountableEmail"
              aria-describedby="accountableEmailHelp"
              value={this.getState('formData.accountableEmail')}
              placeholder="E-mail"
            />
            {this.getError('accountableEmail') && (
              <small id="accountableEmailHelp" className="form-text text-muted">
                {this.getError('accountableEmail')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          {/* <div className="form-group col-md-6">
						<label className="profile-label" htmlFor="emailDomain">
							Dominio de E-mail
						</label>
						<input
							className="form-control profile-placeholder"
							onChange={event => {
								this.handleUserInput(event);
							}}
							id="emailDomain"
							name="emailDomain"
							aria-describedby="emailDomainHelp"
							value={this.getState("formData.emailDomain")}
							placeholder="@suaempresa.com.br"
						/>
						{this.getError("emailDomain") && (
							<small id="emailDomainHelp" className="form-text text-muted">
								{this.getError("emailDomain")}
							</small>
						)}
					</div> */}
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="clientUrl">
              URL (sem o /)
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                this.handleUserInput(event);
              }}
              id="clientUrl"
              name="clientUrl"
              aria-describedby="clientUrlHelp"
              value={this.getState('formData.clientUrl')}
              placeholder="/suaempresa"
            />
            {this.getError('clientUrl') && (
              <small id="clientUrlHelp" className="form-text text-muted">
                {this.getError('clientUrl')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="phoneNumber">
              Telefone
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => {
                const input = event.target;
                mask.maskTEL(input.value).then((masked) => {
                  this.updateFormData(input.name, masked);
                });
              }}
              maxLength={15}
              minLength={15}
              id="phoneNumber"
              name="phoneNumber"
              aria-describedby="phoneNumberHelp"
              value={this.getState('formData.phoneNumber')}
              placeholder="Telefone"
            />
            {this.getError('phoneNumber') && (
              <small id="phoneNumberHelp" className="form-text text-muted">
                {this.getError('phoneNumber')}
              </small>
            )}
          </div>
        </div>
        {/* <div className="row">
					<div className="form-group col-md-12">
						<label className="profile-label" htmlFor="coinName">
							Nome da moeda
						</label>
						<input
							className="form-control profile-placeholder"
							onChange={event => {
								this.handleUserInput(event);
							}}
							id="coinName"
							name="coinName"
							aria-describedby="coinNameHelp"
							value={this.getState("formData.coinName")}
							placeholder="Nome da moeda"
						/>
						{this.getError("coinName") && (
							<small id="coinNameHelp" className="form-text text-muted">
								{this.getError("coinName")}
							</small>
						)}
					</div>
				</div> */}

        <div className="row points-by-share col-md-12">
          <label className="profile-label" htmlFor="segmentationChoice">
            {this.translate('Deseja segmentar os usuários?')}
            &nbsp;
            <i
              className="fa fa-info-circle"
              aria-hidden="true"
              data-tip="React-tooltip"
              data-for="segmentationInfo"
            />
          </label>
          <ReactTooltip
            id="segmentationInfo"
            type="dark"
            effect="solid"
            multiline
            className="tool-tip"
          >
            <span>{this.translate('segmentation-info')}</span>
          </ReactTooltip>
          <div className="form-group col-md-4">
            <div className="profile-placeholder d-flex justify-content-between">
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value={false}
                  name="segmentationChoice"
                  onChange={(e) => this.handleUserInput(e)}
                  checked={this.state.formData.segmentationChoice !== 'true'}
                />
                &nbsp;{this.translate('no')}
              </label>
              <label className="checkbox-inline">
                <input
                  type="radio"
                  value
                  name="segmentationChoice"
                  onChange={(e) => this.handleUserInput(e)}
                  checked={this.state.formData.segmentationChoice === 'true'}
                />
                &nbsp;{this.translate('yes')}
              </label>
            </div>
          </div>
        </div>

        {this.state.formData.segmentationChoice === 'true' && (
          <div>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="profile-label" htmlFor="scopeFieldName">
                  {this.translate('scope-field-name')}&nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="regionalInfo"
                  />
                </label>
                <ReactTooltip
                  id="regionalInfo"
                  type="dark"
                  effect="solid"
                  multiline
                  className="tool-tip"
                >
                  <span>{this.translate('scope-field-name-info')}</span>
                </ReactTooltip>
                <input
                  className="form-control profile-placeholder"
                  onChange={(event) => {
                    this.handleUserInput(event);
                  }}
                  id="scopeFieldName"
                  name="scopeFieldName"
                  aria-describedby="scopeFieldNameHelp"
                  value={this.getState('formData.scopeFieldName')}
                  placeholder={this.translate('scope-field-name')}
                />
                {this.getError('scopeFieldName') && (
                  <small
                    id="scopeFieldNameHelp"
                    className="form-text text-muted"
                  >
                    {this.getError('scopeFieldName')}
                  </small>
                )}
              </div>
            </div>
            <div className="row align-items-flex-end">
              <div className="form-group col-md-8">
                <label className="profile-label" htmlFor="regional">
                  {this.getState('formData.scopeFieldName') || 'Unidades'}
                  &nbsp;
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="scopeFieldNameInfo"
                  />
                </label>
                <ReactTooltip
                  id="scopeFieldNameInfo"
                  type="dark"
                  effect="solid"
                  className="tool-tip"
                  multiline
                >
                  <span>
                    Você pode colocar cada unidade escrevendo e pressionando
                    enter ou importar uma planilha de excel com todos os nomes
                    das unidades.
                  </span>
                </ReactTooltip>
                <TagsInput
                  inputProps={{
                    placeholder: formData.scopeFieldName || 'Unidades',
                  }}
                  value={this.getState('formData.regional')}
                  onChange={this.handleTagsChange.bind(this)}
                />
                {this.getError('regional') && (
                  <small id="regionalHelp" className="form-text text-muted">
                    {this.getError('regional')}
                  </small>
                )}
              </div>

              <div className="form-group w-auto">
                <label
                  htmlFor="file-upload"
                  type="button"
                  className="btn btn-oq-black"
                  disabled={parsingRegionals}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true" />
                  &nbsp;{' '}
                  {parsingRegionals
                    ? `${this.translate('importing')} ${
                        this.getState('formData.scopeFieldName') || 'regionais'
                      }...`
                    : `${this.translate('import')} ${
                        this.getState('formData.scopeFieldName') || 'regionais'
                      }`}
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => this.fileChanged(e.target.files, 'regional')}
                />
              </div>
            </div>
          </div>
        )}

        {/* Pontos para cadastro do player */}

        {/* <div className="row">
 					<div className="form-group col-md-6">
 						<label className="profile-label" htmlFor="targetPublic">
 							Pontos para o cadastro do player &nbsp;
 							<i
 								className="fa fa-info-circle"
 								aria-hidden="true"
 								data-tip="React-tooltip"
 								data-for="leadRegistration"
 							/>
 							<ReactTooltip
 								id="leadRegistration"
 								type="dark"
 								effect="solid"
 								multiline>
 								<span>
 									Defina quantos pontos cada player irá ganhar para se cadastrar
 									na sua plataforma
 								</span>
 							</ReactTooltip>
 						</label>
 						<input
 							required={true}
 							type="number"
 							className="form-control profile-placeholder"
 							name="playerRegisterPoints"
 							onChange={e => this.handleUserInput(e)}
 							value={formData.playerRegisterPoints}
 						/>
 					</div>
				</div> */}

        {/* <INÍCIO> Seção para definir módulo de benefícios */}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="moduleBenefits">
              Habilitar módulo de benefícios?
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="moduleBenefits"
              name="moduleBenefits"
              value={this.getState('formData.moduleBenefits')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        {/* </FIM> Seção para definir módulo de benefícios */}

        {/* <INÍCIO> Seção para definir módulo de indicações */}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="moduleIndications">
              Habilitar módulo de indicações?
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="moduleIndications"
              name="moduleIndications"
              value={this.getState('formData.moduleIndications')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        {/* </FIM> Seção para definir módulo de indicações */}

        {/* <INÍCIO> Seção para definir módulo de engajamentos */}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="moduleEngagements">
              Habilitar módulo de engajamentos?
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="moduleEngagements"
              name="moduleEngagements"
              value={this.getState('formData.moduleEngagements')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        {/* </FIM> Seção para definir módulo de engajamentos */}

        {/* <INÍCIO> Seção para definir a segmentação por cidades */}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="citySegmentationChoice">
              Habilitar segmentação por cidades?
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="citySegmentationChoice"
              name="citySegmentationChoice"
              value={this.getState('formData.citySegmentationChoice')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        {/* </FIM> Seção para definir a segmentação por cidades */}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="targetPublic">
              Tipo de público
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="targetPublic"
              name="targetPublic"
              aria-describedby="targetPublicHelp"
              value={this.getState('formData.targetPublic')}
            >
              <option value="">Selecione a opção...</option>
              <option value="generalPublic">Público em geral</option>
              <option value="myCollaborators">Meus colaboradores</option>
            </select>
            {this.getError('targetPublic') && (
              <small id="targetPublicHelp" className="form-text text-muted">
                {this.getError('targetPublic')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="blockchainStatus">
              Usar blockchain
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="blockchainStatus"
              name="blockchainStatus"
              aria-describedby="blockchainStatusHelp"
              value={this.getState('formData.blockchainStatus')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="enabled">Sim</option>
              <option value="disabled">Não</option>
            </select>
            {this.getError('blockchainStatus') && (
              <small id="blockchainStatusHelp" className="form-text text-muted">
                {this.getError('blockchainStatus')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="useIntegration">
              Integrar Sistemas
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="useIntegration"
              name="useIntegration"
              aria-describedby="useIntegrationHelp"
              value={this.getState('formData.useIntegration')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="enabled">Sim</option>
              <option value="disabled">Não</option>
            </select>
            {this.getError('useIntegration') && (
              <small id="useIntegrationHelp" className="form-text text-muted">
                {this.getError('useIntegration')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="useIntegration">
              Habilitar segunda segmentação?
            </label>
            <select
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="secondarySegmentationChoice"
              name="secondarySegmentationChoice"
              value={this.getState('formData.secondarySegmentationChoice')}
            >
              <option value="">Selecione uma opção...</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </div>
        </div>

        {this.state.formData.secondarySegmentationChoice === 'true' && (
          <>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="useIntegration">
                  Nome da segunda segmentação:
                </label>
                <input
                  className="form-control profile-placeholder"
                  onChange={(event) => {
                    this.handleUserInput(event);
                  }}
                  id="nameSecondarySegmentation"
                  name="nameSecondarySegmentation"
                  value={this.getState('formData.nameSecondarySegmentation')}
                  placeholder="Nome"
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-md-6">
                <label className="profile-label" htmlFor="useIntegration">
                  {this.state.formData.nameSecondarySegmentation}
                </label>
                <Nestable
                  items={this.state.formData.secondarySegmentation}
                  renderItem={this.renderItem}
                  onChange={(e) => {
                    this.setState({
                      formData: {
                        ...this.state.formData,
                        secondarySegmentation: e,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-3">
                <label
                  className="profile-label"
                  htmlFor="nameSecondarySegmentation"
                >
                  Inserir novo(a){' '}
                  {this.state.formData.nameSecondarySegmentation}
                  <i
                    className="fa fa-info-circle"
                    aria-hidden="true"
                    data-tip="React-tooltip"
                    data-for="regionalInfo"
                  />
                </label>
                <input
                  className="form-control profile-placeholder"
                  onChange={(e) => {
                    this.setState({
                      nameItemSecondarySegmentation: e.target.value,
                    });
                  }}
                  id="nameItemSecondarySegmentation"
                  name="nameItemSecondarySegmentation"
                  aria-describedby="scopeFieldNameHelp"
                  value={this.getState('nameItemSecondarySegmentation')}
                  placeholder={this.translate('name')}
                />
              </div>
              <div className="form-group col-md-2">
                <button
                  className="btn btn-oq-black col-md-12 col-12 col-sm-2 oq-margin-right"
                  onClick={() => this.addItem()}
                >
                  <i className="fa fa-files-o" aria-hidden="true" />
                  <span>
                    &nbsp; Adicionar{' '}
                    {this.state.formData.nameSecondarySegmentation}
                  </span>
                </button>
              </div>
            </div>
          </>
        )}

        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="pass">
              Senha
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="password"
              name="password"
              type="password"
              aria-describedby="passwordHelp"
              value={this.getState('formData.password')}
              placeholder="********"
            />
            {this.getError('password') && (
              <small id="passwordHelp" className="form-text text-muted">
                {this.getError('password')}
              </small>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="profile-label" htmlFor="confirmPassword">
              Confirmar Senha
            </label>
            <input
              className="form-control profile-placeholder"
              onChange={(event) => this.handleUserInput(event)}
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              aria-describedby="confirmPasswordHelp"
              value={this.getState('formData.confirmPassword')}
              placeholder="********"
            />
            {this.getError('passConfirm') && (
              <small id="passConfirmHelp" className="form-text text-muted">
                {this.getError('passConfirm')}
              </small>
            )}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-xs-6">
            <label className="profile-label">
              Cor primária - Cor principal dos botões
            </label>
            <SketchPicker
              color={this.getState('formData.primaryColor')}
              width="100%"
              onChangeComplete={(color) =>
                this.handleColorChangeComplete(color, 'primaryColor')
              }
            />
          </div>
          <div className="form-group col-xs-6">
            <label className="profile-label">
              Cor secundária - Cor da sua barra superior
            </label>
            <SwatchesPicker
              width="100%"
              height="100%"
              onChangeComplete={(color) =>
                this.handleColorChangeComplete(color, 'secondaryColor')
              }
              color={this.getState('formData.secondaryColor')}
              colors={this.state.grayScale}
            />
          </div>
          <div className="form-group col-xs-6">
            <label>Escolha uma fonte</label>
            <Select
              closeMenuOnSelect
              value={this.getState('formData.selectedFont')}
              required
              components={{ Option }}
              placeholder="Escolha a fonte do seu sistema..."
              onChange={(selectedOption) =>
                this.handleFontChange(selectedOption)
              }
              options={[
                {
                  value: 'Gotham Rounded',
                  label: 'Gotham Rounded',
                },
                {
                  value: 'Lato',
                  label: 'Lato',
                },
                {
                  value: 'Roboto',
                  label: 'Roboto',
                },
                {
                  value: 'OpenSans',
                  label: 'OpenSans',
                },
              ]}
            />
          </div>
        </div>

        <div className="row d-flex copy-button">
          <div className="form-group col-md-5">
            <label className="profile-label" htmlFor="phoneNumber">
              URL
            </label>
            <input
              className="form-control profile-placeholder"
              disabled
              id="productionURL"
              name="productionURL"
              aria-describedby="productionURLHelp"
              value={`https://app.oqdigital.com/_/${this.getState(
                'formData.clientUrl'
              )}`}
              placeholder="link"
            />
          </div>
          <div className="form-group col-md-3">
            <label className="profile-label" htmlFor="phoneNumber" />
            <CopyToClipboard
              text={`https://app.oqdigital.com/_/${this.getState(
                'formData.clientUrl'
              )}`}
              onCopy={() => Notify('Link copiado com sucesso!', 'success')}
            >
              <button
                className="btn btn-oq-black col-md-12 col-12 col-sm-2 oq-margin-right"
                style={{ marginBottom: '-8px' }}
              >
                <i className="fa fa-files-o" aria-hidden="true" />
                <span>&nbsp; Copiar link</span>
              </button>
            </CopyToClipboard>
          </div>
        </div>

        <div className="row">{this.renderAuthorizedUsersButton()}</div>
        <div className="row">{this.renderUpdateButton()}</div>
      </div>
    );
  }

  generateGrayScaleColors() {
    const totalColors = 255;
    const lines = 10;
    const columns = 7;
    const colorsToGen = lines * columns;
    const colorFactor = totalColors / (colorsToGen - 1);
    let colors = [];
    for (let index = 0; index < colorsToGen; index++) {
      const currrentColorIndex = parseInt(index * colorFactor);
      const currentHex = this.rgbToHex(currrentColorIndex);
      colors.push(currentHex);
    }

    // colors.push(this.rgbToHex(totalColors));
    colors = _.chunk(colors, lines);
    this.setState({ grayScale: colors });
  }

  rgbToHex(color) {
    return `#${((1 << 24) + (color << 16) + (color << 8) + color)
      .toString(16)
      .slice(1)}`;
  }

  handleColorChangeComplete = (color, field) => {
    const colorHex = color.hex;
    if (field === 'secondaryColor') {
      const grayscale = Color(colorHex).grayscale().hex();
      this.updateFormData(field, grayscale);
      return;
    }

    this.updateFormData(field, colorHex);
  };

  getState(item) {
    return _.get(this.state, item, '');
  }

  handleFontChange = (selectedOption) => {
    this.setState({ selectedOption, selectedFont: selectedOption });
    this.updateFormData('systemFont', selectedOption.value);
    this.updateFormData('selectedFont', selectedOption);
  };

  renderAuthorizedUsersButton() {
    const { loadingAuthorizedUsers } = this.state;
    return (
      <div className="col-md-6 form-group">
        <button
          type="button"
          htmlFor="authorizedUsers"
          className="btn btn-oq-black"
          disabled={loadingAuthorizedUsers}
          onClick={() => this.fileSelector.click()}
        >
          Cadastrar Usuários Autorizados
        </button>
      </div>
    );
  }

  renderUpdateButton() {
    return (
      <div className="col-md-5 col-md-offset-4 form-group">
        <button
          type="button"
          onClick={() => this.createClient()}
          className="btn btn-oq btn-block btn-oq-lg"
          // disabled={!this.state.formValid}
        >
          CADASTRAR CLIENTE
        </button>
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <SideBar />
        <div id="content">
          <NavBar />
          <div className="container-fluid">
            <div className="col-md-12">{this.renderBackButton()}</div>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-12">
                  <h1 className="oq-filter-title">
                    <i className="fa fa-user-plus" aria-hidden="true" />
                    <span>&nbsp; Novo Cliente</span>
                  </h1>
                </div>
                {this.renderForm()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateClientPage;
