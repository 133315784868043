import _ from 'lodash';
import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { Notify } from '../../../../../components/Notify';
import { api } from '../../../../../Config';
import * as translate from '../../../../../Locales/br/translation.json';
import * as routes from '../../../../../routes';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';

function ActionsInvites(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingSeasons, setIsLodingSeasons] = useState(false);
  const [actions, setActions] = useState(false);
  const [countActions, setCountActions] = useState(0);
  const [searchObj, setSearchObj] = useState({});

  function renderActionsTable() {
    let data = _.values(actions);
    data = _.filter(data, (action) => action.deleted !== true);

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {translate.title}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{translate.OrderBy + translate.title}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'actionTitle',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {translate.subTitle}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{translate.OrderBy + translate.subTitle}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'actionSubtitle',
      },
      // {
      //   Header: (
      //     <span data-tip='React-tooltip' data-for='number-of-awards'>
      //       {this.translate('number-of-awards')}
      //       <ReactTooltip
      //         id='number-of-awards'
      //         type='dark'
      //         effect='solid'
      //         multiline={true}
      //         className='tool-tip'
      //       >
      //         <span>
      //           {this.translate('OrderBy') + this.translate('number-of-awards')}
      //         </span>
      //       </ReactTooltip>
      //     </span>
      //   ),
      //   id: 'physicalAwards',
      //   accessor: d =>
      //     this.actionDoesntHaveAwards(d) ? '0' : _.size(d.awardsList),
      //   className: 'center-cell',
      //   minWidth: 150
      // },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {translate.status}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{translate.OrderBy + translate.status}</span>
            </ReactTooltip>
          </span>
        ),
        width: 100,
        id: 'status',
        accessor: (d) => {
          if (d.active === true || d.active === 'true') {
            return 'Ativo';
          }
          return 'Inativo ';
        },
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: (props) => (
          <Link to={`${routes.EDIT_ACTION_INVITE}/${props.value}/`}>
            <button className="btn btn-oq">Editar</button>
          </Link>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: (props) => (
          <Link to={`${routes.DETAILS_ACTION_INVITE}/${props.value}/`}>
            <button className="btn btn-oq">Visualizar</button>
          </Link>
        ),
      },
      // {
      //   Header: '',
      //   accessor: 'id',
      //   className: 'center-cell',
      //   Cell: props => (
      //     <button
      //       className="btn btn-oq"
      //       onClick={() => {
      //         this.openPreviewPage(props.original);
      //       }}
      //     >
      //       {this.translate('visualizar')}
      //     </button>
      //   ),
      // },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            {translate.actionList}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data.reverse()}
              defaultPageSize={5}
              columns={columns}
              previousText={translate.prev}
              nextText={translate.next}
              loadingText={translate.loading}
              noDataText="Nenhum post para ser exibido"
              pageText={translate.next}
              ofText={translate.of}
              rowsText={translate.lines}
            />
          </div>
        </div>
      </div>
    );
  }

  function handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }
  function goToNewActionPage() {
    props.history.push(routes.CREATE_ACTION_INVITE);
  }

  function renderNewActionBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={loadingSeasons || isLoading}
            onClick={() => goToNewActionPage()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp; {loadingSeasons ? translate.loading : 'Nova publicação'}
          </button>
        </div>
      </div>
    );
  }

  function filterActions() {
    return searchObj.name || searchObj.startDate || searchObj.endDate;
  }

  function searchActions() {
    setIsLoading(true);

    const parameters = {
      searchFunctionality: filterActions() ? 'filterActions' : 'allActions',
      userType: 'client',
      actionType: 'invite',
      searchObj,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const result = res.data;
        if (!result.success) {
          setActions([]);
          setIsLoading(false);
          setCountActions(0);
          Notify(result.message, result.success ? 'success' : 'error');
          return;
        }
        setActions(result.data.actions);
        setIsLoading(false);
        setCountActions(result.data.actionsCount);

        Notify(result.message, result.success ? 'success' : 'error');
      })
      .catch((error) => {
        this.setState({ isLoading: true });
        console.log('searchQuiz error:', error);
      });
  }

  function renderSearchForm() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              {translate['advanced-filters']}
            </span>
            <div className="row">
              <div className="col-md-4 form-group">
                <input
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Nome"
                  onChange={(e) => this.handleSearchInputChange(e)}
                />
              </div>

              <div className="col-md-3 col-md-offset-1 form-group">
                <button
                  className="btn btn-block btn-oq"
                  disabled={isLoading}
                  onClick={() => searchActions()}
                >
                  <i className="fa fa-search" aria-hidden="true" />
                  &nbsp;
                  {isLoading
                    ? translate['Pesquisando...']
                    : translate.Pesquisar}
                </button>
              </div>
            </div>
          </div>
        </div>
        {renderNewActionBtn()}
      </div>
    );
  }

  return (
    <div className="oq-dash-wrapper">
      <SideBar />
      <div className="oq-content-area">
        <NavBar />
        <div className="oq-content-work">
          <ToastContainer />
          {renderSearchForm()}
          {renderActionsTable()}
        </div>
      </div>
    </div>
  );
}

export default withRouter(ActionsInvites);
