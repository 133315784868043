import _ from 'lodash';
import moment from 'moment';
import 'rc-checkbox/assets/index.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { api } from '../../../../../Config';

import ModalGamificationRule from '../../../../components/Modals/ActionsModals/GamificationRuleModal';
import ModalSeasonDefine from '../../../../components/Modals/ActionsModals/SeasonDefineModal';

import { Notify } from '../../../../../components/Notify';
import * as routes from '../../../../../routes';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

class ActionsQuiz extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      authUser: {},
      users: [],
      name: '',
      email: '',
      city: '',
      modalGamificationRuleIsOpen: false,
      modalSeasonDefineIsOpen: false,
      countUsers: 0,
      countSeasons: 0,
      search: {},
      isLoading: false,
      loadingSeasons: false,
      searchObj: {},
      dataUser: [],
      isExporting: false,
    };

    return initialState;
  }

  openPreviewPage(d) {
    localStorage.setItem('actionPreview', JSON.stringify(d));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  async componentDidMount() {
    const { clientData } = this.context;

    this.setState({ dataUser: clientData && clientData });
    if (clientData && clientData.isGamified !== 'false') this.searchSeasons();
    this.searchActions();
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      const parameters = {
        searchFunctionality: 'filterSeasons',
        userType: 'client',
        search,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.data.seasons && result.data.countSeasons) {
            this.setState({
              seasons: result.data.seasons,
              countSeasons: result.data.countSeasons,
              loadingSeasons: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  goToNewQuizPage() {
    const { history } = this.props;
    history.push(routes.CREATE_ACTION_QUIZ);
  }

  handleSearchInputChange(e) {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      searchObj: {
        ...prevState.searchObj,
        [name]: value,
      },
    }));
  }

  renderModalsAndGo() {
    const { countSeasons, dataUser } = this.state;

    if (!dataUser.gamificationType) {
      this.toggleModalGamificationRule();
    } else if (!countSeasons && dataUser.isGamified !== 'false') {
      this.toggleSeasonDefineModal();
    } else this.goToNewQuizPage();
  }

  async exportQuiz() {
    const { clientData, userData } = this.context;

    this.setState({ isExporting: true });

    const parameters = {
      searchFunctionality: 'getQuizExportation',
      userType: 'client',
      searchQuizExportation: true,
      clientId: clientData.uid,
      userEmail: userData.email,
    };

    try {
      const res = await api.post(`/exportation`, parameters);

      const result = res.data;

      if (result.success) {
        this.setState({
          isExporting: false,
        });
        Notify(result.message, 'success');
      } else {
        Notify(result.message, 'warn');

        this.setState({
          isExporting: false,
        });
      }
    } catch (error) {
      this.setState({
        isExporting: false,
      });
      console.log('exporLeads error:', error);
      return;
    }
  }

  renderSearchForm() {
    return (
      <div>
        <div className="oq-filter-bg">
          <div className="container-fluid">
            <span className="form-group oq-filter-title">
              <i className="fa fa-sliders" aria-hidden="true" />
              &nbsp; {this.translate('advanced-filters')}
            </span>
            <div className="row">
              <div className="col-md-8">
                <div className="col-md-6 form-group">
                  <input
                    className="form-control"
                    id="name"
                    name="name"
                    placeholder="Nome"
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label
                    style={{
                      position: 'absolute',
                      margin: '-18px',
                      left: '38px',
                    }}
                    className="profile-label"
                    htmlFor="startDate"
                  >
                    {this.translate(`Data de início`)}
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    id="startDate"
                    name="startDate"
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
                <div className="col-md-6 form-group">
                  <label
                    style={{
                      position: 'absolute',
                      margin: '-18px',
                      left: '38px',
                    }}
                    className="profile-label"
                    htmlFor="endDate"
                  >
                    {this.translate(`Data de fim`)}
                  </label>
                  <input
                    className="form-control"
                    type="date"
                    id="endDate"
                    name="endDate"
                    onChange={(e) => this.handleSearchInputChange(e)}
                  />
                </div>
              </div>
              <div className="row col-md-4">
                <div className="col-md-12 col-md-offset-1 form-group">
                  <button
                    className="btn btn-block btn-oq"
                    disabled={this.state.isExporting || this.state.isLoading}
                    onClick={() => this.searchActions()}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                    &nbsp;
                    {this.state.isLoading
                      ? this.translate('searching')
                      : this.translate('search')}
                  </button>
                </div>
                <div className="col-md-12 col-md-offset-1 form-group">
                  <button
                    className="btn btn-oq pull-right"
                    disabled={this.state.isExporting || this.state.isLoading}
                    onClick={() => this.exportQuiz()}
                  >
                    <i className="fa fa-search" aria-hidden="true" />
                    &nbsp;
                    {this.state.isExporting
                      ? this.translate('Exportando...')
                      : this.translate('Exportar')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.renderNewActionBtn()}
        {this.renderGamificationRuleModal()}
        {this.renderSeasonDefineModal()}
      </div>
    );
  }

  toggleModalGamificationRule() {
    this.setState({
      modalGamificationRuleIsOpen: !this.state.modalGamificationRuleIsOpen,
    });
  }

  toggleSeasonDefineModal() {
    this.setState({
      modalSeasonDefineIsOpen: !this.state.modalSeasonDefineIsOpen,
    });
  }

  redirectUrlGamificationRule() {
    this.props.history.push(routes.GAMIFICATION_CLIENT);
  }

  redirectUrlSeasonDefine() {
    this.props.history.push(routes.SEASONS);
  }

  renderGamificationRuleModal() {
    return (
      <ModalGamificationRule
        open={this.state.modalGamificationRuleIsOpen}
        close={this.toggleModalGamificationRule.bind(this)}
        redirectUrlGamificationRule={this.redirectUrlGamificationRule.bind(
          this
        )}
      />
    );
  }

  renderSeasonDefineModal() {
    return (
      <ModalSeasonDefine
        open={this.state.modalSeasonDefineIsOpen}
        close={this.toggleSeasonDefineModal.bind(this)}
        redirectUrlSeasonDefine={this.redirectUrlSeasonDefine.bind(this)}
      />
    );
  }

  renderNewActionBtn() {
    return (
      <div className="col-md-12 oq-padding-top">
        <div className="form-group col-md-4">
          <button
            disabled={this.state.isLoading}
            onClick={() => this.renderModalsAndGo()}
            type="button"
            className="btn btn-block btn-oq btn-oq-lg"
          >
            <i className="fa fa-plus icon-small" aria-hidden="true" />
            &nbsp;{' '}
            {this.state.isLoading
              ? this.translate('loading')
              : this.translate('NOVA PUBLICAÇÃO')}
          </button>
        </div>
      </div>
    );
  }

  actionDoesntHaveAwards = (d) => {
    return (
      !d.awardsList ||
      (_.size(d.awardsList) === 1 && !d.awardsList.premiumDescription)
    );
  };

  renderActionsTable() {
    let data = _.values(this.state.actions);
    data = _.filter(data, (action) => {
      return action.deleted !== true;
    });

    const columns = [
      {
        Header: (
          <span data-tip="React-tooltip" data-for="name">
            {this.translate('name')}
            <ReactTooltip
              id="name"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('name')}</span>
            </ReactTooltip>
          </span>
        ),
        accessor: 'actionTitle',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="start">
            {this.translate('start')}
            <ReactTooltip
              id="start"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('start')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'inicio',
        accessor: (d) =>
          d.startDateEpoch
            ? moment(parseInt(d.startDateEpoch)).format('DD/MM/YYYY HH:mm')
            : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="end">
            {this.translate('end')}
            <ReactTooltip
              id="end"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>{this.translate('OrderBy') + this.translate('end')}</span>
            </ReactTooltip>
          </span>
        ),
        id: 'fim',
        accessor: (d) =>
          d.endDateEpoch
            ? moment(parseInt(d.endDateEpoch)).format('DD/MM/YYYY HH:mm')
            : '--',
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="amount-of-user">
            {this.translate('amount-of-user')}
            <ReactTooltip
              id="amount-of-user"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('amount-of-user')}
              </span>
            </ReactTooltip>
          </span>
        ),
        id: 'players',
        accessor: (d) => (d.players ? _.size(d.players) : '0'),
        className: 'center-cell',
        minWidth: 120,
      },
      {
        Header: (
          <span data-tip="React-tooltip" data-for="status">
            {this.translate('status')}
            <ReactTooltip
              id="status"
              type="dark"
              effect="solid"
              multiline
              className="tool-tip"
            >
              <span>
                {this.translate('OrderBy') + this.translate('status')}
              </span>
            </ReactTooltip>
          </span>
        ),
        width: 100,
        id: 'status',
        accessor: (d) => {
          const now = moment().valueOf();
          const startAt = moment(d.startDateEpoch).valueOf();
          const endAt = moment(d.endDateEpoch).valueOf();
          if (now <= startAt) return 'Futura';
          if (now >= startAt && now <= endAt)
            return this.translate('not-ended');
          if (now >= endAt) return this.translate('ended');
          return '--';
        },
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: (props) => (
          <>
            <Link to={`${routes.EDIT_ACTION_QUIZ}/${props.value}/`}>
              <button className="btn btn-oq">{this.translate('edit')}</button>
            </Link>
          </>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        className: 'center-cell',
        Cell: (props) => (
          <>
            <Link to={`${routes.DETAILS_ACTION_QUIZ}/${props.value}/`}>
              <button className="btn btn-oq">
                {this.translate('Visualizar')}
              </button>
            </Link>
          </>
        ),
      },
    ];
    return (
      <div className="col-md-12">
        <div className="oq-card">
          <span className="oq-card-title-action">
            <i className="fa fa-rocket" />
            &nbsp; {this.translate('Lista de quiz')}
          </span>
          <div className="oq-line" />
          <div style={{ paddingTop: 10 }}>
            <ReactTable
              data={data}
              defaultPageSize={5}
              columns={columns}
              previousText={this.translate('prev')}
              nextText={this.translate('next')}
              loadingText={this.translate('loading')}
              noDataText={this.translate('Nenhum quiz para ser exibido')}
              pageText={this.translate('next')}
              ofText={this.translate('of')}
              rowsText={this.translate('lines')}
            />
          </div>
        </div>
      </div>
    );
  }

  searchActions() {
    this.setState({ isLoading: true }, () => {
      const parameters = {
        searchFunctionality: this.filterActions()
          ? 'filterActions'
          : 'allActions',
        userType: 'client',
        actionType: 'quiz',
        searchObj: this.state.searchObj,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (!result.success) {
            this.setState({
              isLoading: false,
              countActions: 0,
              actions: [],
            });
            Notify(result.message, result.success ? 'success' : 'error');
            return;
          }
          this.setState({
            isLoading: false,
            countActions: result.data.actionsCount,
            actions: result.data.actions,
          });
          Notify(result.message, result.success ? 'success' : 'error');
        })
        .catch((error) => {
          this.setState({ isLoading: true });
          console.log('searchQuiz error:', error);
        });
    });
  }

  filterActions() {
    const { searchObj } = this.state;

    return searchObj.name || searchObj.startDate || searchObj.endDate;
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            {this.renderSearchForm()}
            {this.renderActionsTable()}
            <div className="col-md-12 row-observation">
              <p className="oq-obersvation">
                * O número de participantes dos desafios também leva em
                consideração os players que foram excluídos do sistema.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ActionsQuiz;
