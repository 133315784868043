import React, { Component } from 'react';

import axios from 'axios';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import _ from 'underscore';
import { api } from '../../../../../Config';

import * as routes from '../../../../../routes';
import { NavBar } from '../../../../components/NavBar';
import { SideBar } from '../../../../components/SideBar';
import { UserDataContext } from '../../../../../hooks/useUserData';

class DetailsActionInvite extends Component {
  static contextType = UserDataContext;

  constructor(props) {
    super(props);

    this.state = this.getInitialState();
    this.toastId = null;

    this.translate = props.t;
  }

  getInitialState() {
    const initialState = {
      flag: false,
      authUser: {},
      actionId: {},
      formValid: false,
      formErrors: {},
      isLoading: false,
      loadingStateList: false,
      loadingSeasons: false,
      search: {},
      countSeasons: 0,
      loadingRegionalList: false,
      creatingAction: false,
      stateList: [],
      cityList: [],
      regionalList: [],
      imageFormData: new FormData(),
      isUploading: false,
      progress: 0,
      awardsModalIsOpen: false,
      extraPointsModalIsOpen: false,
      regionalIsEmpty: false,
      errorForm1: false,
      errorTime1: false,
      errorTimeMsg1: '',
      msg1: '',
      formData: {
        actionTitle: '',
        actionSubtitle: '',
        callToAction: '',
        pointsAward: '',
        physicalAwards: '',
        numberOfWinners: '',
        startDate: '',
        startDateTime: '',
        startDateEpoch: '',
        endDate: '',
        endDateTime: '',
        endDateEpoch: '',
        externalId: '',
        marcaId: '',
        pageURL: '',
        season: {},
        files: {},
        images: [],
        videoURL: '',
        countDownVisible: true,
        qrCodeButtonsVisible: false,
        shareButtonsVisible: true,
        // startDateVisible: true,
        rulesOfAction: '',
        redirectDashCallToAction: '/',
        membergetmemberTotalSignUp: '0',
        membergetmemberMax: '0',
        // landingPagePreviewPoint: 0,
        scope: '',
        scopeFieldName: 'Unidade',
        scopeValues: [],
        selectedStates: [],
        selectedCities: [],
        leadRegistration: '',
        salesConversion: '',
        membergetmember: '',
        conversionType: '',
        conversionFieldName: '',
        extraFieldName1: '',
        hasAwards: 'false',
        iconsShare: {
          whatsapp: 'true',
          facebook: 'true',
          twitter: 'true',
          pinterest: 'true',
          copyLink: 'true',
          telegram: 'true',
          linkedin: 'true',
          instagram: 'true',
          email: 'true',
          messenger: 'true',
        },

        awardsList: [
          {
            startAt: 1,
            endAt: 2,
            premiumDescription: '',
            imageURL: '',
            isUploading: false,
          },
        ],
        extraPointsList: [
          {
            startAt: 1,
            endAt: 2,
            points: 0,
          },
        ],
        active: true,
      },
      formButtonPressed: false,
      phase1Uncompleted: false,
      phase2Uncompleted: false,
      phase3Uncompleted: false,
    };

    return initialState;
  }

  async componentDidMount() {
    await this.getStateList();
    const {
      match: { params },
    } = this.props;

    this.setState({ actionId: params.actionId });
    this.getActionById(params.actionId);
  }

  async getStateList() {
    return axios
      .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((states) => {
        const sortedStates = _.sortBy(states.data, 'nome');
        this.setState({ stateList: sortedStates });
      })
      .catch((error) => {
        console.log('getStateList error:', error);
      });
  }

  async getCityList(statesArr) {
    if (!statesArr || !statesArr.length) return;

    const { stateList } = this.state;

    const newCitiesarr = [];

    const newCitesPromises = statesArr.map(async (stateObj) => {
      const chosenState = _.find(
        stateList,
        (estado) => estado.sigla === stateObj.value
      );

      const response = await axios.get(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateObj.value}/municipios`
      );

      const stateCities = response.data;

      _.forEach(stateCities, (cityObject) => {
        const data = {
          city: `${cityObject.nome} - ${chosenState.sigla}`,
          state: chosenState.sigla,
        };
        newCitiesarr.push(data);
      });
    });

    Promise.all(newCitesPromises).then(() =>
      this.setState({ cityList: newCitiesarr })
    );
  }

  getActionById(actionId) {
    const parameters = {
      searchFunctionality: 'getAction',
      userType: 'client',
      actionId,
    };

    api
      .post(`/search`, parameters)
      .then((res) => {
        const formData = res.data.data.action;

        if (formData.scope === 'regional') {
          this.getClientRegionalList();
        }

        this.setState({
          isLoading: false,
          formData: formData,
          questions: formData.questions,
        });
      })
      .catch((error) => {
        console.log('getActionById error:', error);
      });
  }

  searchSeasons() {
    const { search } = this.state;

    this.setState({ loadingSeasons: true }, () => {
      const parameters = {
        searchFunctionality: 'filterSeasons',
        userType: 'client',
        search,
      };

      api
        .post(`/search`, parameters)
        .then((res) => {
          const result = res.data;
          if (result.data.seasons && result.data.countSeasons) {
            this.setState({
              seasons: result.data.seasons,
              countSeasons: result.data.countSeasons,
              loadingSeasons: false,
            });
          }
          this.setState({ loadingSeasons: false });
        })
        .catch((error) => {
          this.setState({ loadingSeasons: false });
          console.log('searchSeasons error:', error);
        });
    });
  }

  goToEditPage(actionId) {
    const { history } = this.props;

    history.push(`${routes.EDIT_ACTION_INVITE}/${actionId}`);
  }

  openPreviewPage() {
    const { formData } = this.state;
    localStorage.setItem('actionPreview', JSON.stringify(formData));
    const win = window.open('/#/client/actionPreview', '_blank');
    win.focus();
  }

  renderPhase1() {
    const { formData, seasons } = this.state;
    const { clientData } = this.context;

    return (
      <div className="col-md-12">
        <h4
          className="arrow-toggle clickable "
          data-toggle="collapse"
          data-target="#phase1"
          aria-expanded="true"
        >
          Informações básicas
        </h4>
        <div id="phase1" aria-expanded="true" className="collapse show">
          <div className="col-md-7">
            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionTitle">
                Título do convite <code>*</code>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionTitle"
                placeholder={this.translate('Product Name')}
                value={formData.actionTitle}
              />
            </div>

            <div className="form-group col-md-12">
              <label className="profile-label" htmlFor="actionSubtitle">
                Subtítulo do convite <code>*</code>
              </label>
              <input
                disabled
                readOnly
                className="form-control profile-placeholder"
                name="actionSubtitle"
                placeholder="Compartilhe, compre e ganhe pontos."
                value={formData.actionSubtitle}
              />
            </div>

            <div className="form-group col-md-4">
              <label className="profile-label" htmlFor="startDate">
                {this.translate('status')} <code>*</code>
              </label>
              <select
                disabled
                className="form-control profile-placeholder"
                name="active"
                value={formData.active}
              >
                <option value>{this.translate('active')}</option>
                <option value={false}>{this.translate('inactive')}</option>
              </select>
            </div>
          </div>
          <div className="form-group col-md-5 imgpv">
            <label className="profile-label" htmlFor="image1">
              {this.translate('image')}
              {formData.videoURL ? '' : <code>*</code>}
              (680 x 340)&nbsp;
              <i
                className="fa fa-info-circle"
                aria-hidden="true"
                data-tip="React-tooltip"
                data-for="imagem1Info"
              />
              <ReactTooltip
                id="imagem1Info"
                type="dark"
                effect="solid"
                multiline
              >
                <span>
                  {this.translate('ideal-resolution')} 680 píxeis x 340 píxeis.
                  <br />
                  {this.translate('keeping-proportion')}
                </span>
              </ReactTooltip>
              {this.state.isUploading && <span>{this.state.progress}%</span>}
            </label>

            {formData.images
              ? formData.images.map((image) =>
                  this.renderImagePreview(image.url)
                )
              : null}
            <input
              disabled
              readOnly
              type="file"
              accept="image/*"
              id="profilePic"
            />

            <span className="span-oq">
              Imagens com até 72 K são as mais indicadas para
              recompartilhamentos. Acima desta medida, a publicação das mesmas
              nas redes sociais não é garantida.
            </span>
          </div>
        </div>
        <hr />
      </div>
    );
  }

  renderForm() {
    return <div className="container-fluid ">{this.renderPhase1()}</div>;
  }

  renderImagePreview(img) {
    return (
      <img
        src={img}
        className="img-thumbnail img-responsive oq-margin-bottom"
        alt="action banner"
      />
    );
  }

  renderStateSelection() {
    const { clientData } = this.context;
    const { stateList, formData } = this.state;
    const { selectedStates } = formData;

    if (formData.scope !== 'regional') return <></>;
    if (!stateList || !stateList.length) return <></>;

    return (
      <div className="form-group col-md-12">
        <label className="profile-label" htmlFor="selectedStates">
          Estados que serão impactados: <code>*</code>
        </label>
        <Select
          isMulti
          name="selectedStates"
          placeholder="Selecione quais estados deseja atingir ..."
          options={_.map(stateList, (estado) => ({
            value: estado.sigla,
            label: estado.nome,
          }))}
          className="basic-multi-select"
          value={selectedStates}
          onChange={(e) => this.handleStateChange(e)}
          classNamePrefix="select"
        />
      </div>
    );
  }

  renderBackButton() {
    return (
      <div className="oq-padding-vertical">
        <button
          type="button"
          className="btn btn-oq-back"
          onClick={() => this.props.history.goBack()}
        >
          <i className="fa fa-arrow-left" aria-hidden="true" />
          &nbsp; {this.translate('back')}
        </button>
      </div>
    );
  }

  handleModalToggle(modal) {
    this.setState((prevState) => ({
      [modal]: !prevState[modal],
    }));
  }

  render() {
    return (
      <div className="oq-dash-wrapper">
        <SideBar />
        <div className="oq-content-area">
          <NavBar />
          <div className="oq-content-work">
            <ToastContainer />
            <div className="container-fluid">
              <div className="col-md-12">{this.renderBackButton()}</div>
              <h1 className="oq-filter-title">
                <i className="fa fa-rocket" />
                <span>&nbsp; Visualizar Publicação</span>
              </h1>
            </div>
            <form>{this.renderForm()}</form>
          </div>
        </div>
      </div>
    );
  }
}

export default DetailsActionInvite;
