import React from 'react';
import { Switch, withRouter } from 'react-router-dom';

import CreateClientPage from '../../../AdminMaster/CreateClient';
import EditClientPage from '../../../AdminMaster/EditClient';
import ProspectLeads from '../../../AdminMaster/ProspectLeads';
import RegisterClientPage from '../../../AdminMaster/RegisterClient';
import AdministratorsPage from '../../../AdminMaster/Administrators';
import EditAdminPage from '../../../AdminMaster/EditAdministrator';

import PlayersPage from '../../../AdminMaster/Players';

import DashboardAdminPage from '../../../AdminMaster/Dashboard';
import EmailsAdminPage from '../../../AdminMaster/Emails';
import NewEmailAdminPage from '../../../AdminMaster/NewEmail';

import ClientsPage from '../../../AdminMaster/Clients';
import TermsAdminPage from '../../../AdminMaster/Terms';
import PreviewTextAdminPage from '../../../AdminMaster/PreviewText';
import LeadsPage from '../../../AdminMaster/Leads';
import EditTextAdminPage from '../../../AdminMaster/EditText';
import Wallets from '../../../AdminMaster/Wallets';
import EditWallet from '../../../AdminMaster/EditWallet';
import DetailsBankTransferRequest from '../../../AdminMaster/DetailsBankTransferRequest';
import HistoricalWallet from '../../../AdminMaster/HistoricalWallet';
import Users from '../../../AdminMaster/Users';
import NotFoundPage from '../../NotFound';
import PrivateRoute from './PrivateRoute';
import JobsPage from '../../../AdminMaster/Jobs';

import * as routes from '../../../routes';

function WithAuth(props) {
  return (
    <Switch>
      {/** INÍCIO Admin Master */}
      <PrivateRoute
        isPermission
        exact
        path={routes.CREATE_CLIENT}
        component={(propsPage) => (
          <CreateClientPage {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.REGISTER_CLIENT}
        component={(propsPage) => (
          <RegisterClientPage {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.EDIT_CLIENT}/:clientId`}
        component={(propsPage) => <EditClientPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.EDIT_ADMIN}/:adminId`}
        component={(propsPage) => <EditAdminPage {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.PROSPECT_LEADS_ADMIN}
        component={(propsPage) => <ProspectLeads {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.ADMINS}
        component={(propsPage) => (
          <AdministratorsPage {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.DASHBOARD_ADMIN}
        component={(propsPage) => (
          <DashboardAdminPage {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.EMAILS_ADMIN}
        component={(propsPage) => <EmailsAdminPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.NEW_EMAIL_ADMIN}
        component={(propsPage) => (
          <NewEmailAdminPage {...props} {...propsPage} />
        )}
      />

      <PrivateRoute
        isPermission
        exact
        path={routes.CLIENTS_ADMIN}
        component={(propsPage) => <ClientsPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.PLAYERS_ADMIN}
        component={(propsPage) => <PlayersPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.TERMS_ADMIN}
        component={(propsPage) => <TermsAdminPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.PREVIEW_TEXT_ADMIN}/:adminText`}
        component={(propsPage) => (
          <PreviewTextAdminPage {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={routes.LEADS_ADMIN}
        component={(propsPage) => <LeadsPage {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.EDIT_TEXT_ADMIN}/:adminText`}
        component={(propsPage) => (
          <EditTextAdminPage {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.WALLETS}/`}
        component={(propsPage) => <Wallets {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.EDIT_WALLET}/:clientId`}
        component={(propsPage) => <EditWallet {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.HISTORICAL_WALLET_ADMIN}/:clientId`}
        component={(propsPage) => (
          <HistoricalWallet {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.DETAILS_BANK_TRANSFER_REQUEST}/:clientId/:rescueId`}
        component={(propsPage) => (
          <DetailsBankTransferRequest {...props} {...propsPage} />
        )}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.USERS_ADMIN}`}
        component={(propsPage) => <Users {...props} {...propsPage} />}
      />
      <PrivateRoute
        isPermission
        exact
        path={`${routes.JOBS}/`}
        component={(propsPage) => <JobsPage {...props} {...propsPage} />}
      />

      <PrivateRoute
        isPermission
        component={(propsPage) => <NotFoundPage {...props} {...propsPage} />}
      />
      {/** FIM Admin Master */}
    </Switch>
  );
}

export default withRouter(WithAuth);
